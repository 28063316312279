import { Flex, Text, Tooltip } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import React from 'react'
import { Icons } from 'ui'

import { StepLogo } from '@/modules/workflow/components/StepLogo'
import { TEXT_ENUM } from '@/styles/components/text'
import { TOOLTIP_ENUM } from '@/styles/components/tooltip'

import { CanvasNodeHeaderMenu } from './CanvasNodeHeaderMenu'

type Props = {
	selected: boolean
	label: string
	icon?: Icons
	logo?: string
	logoBg?: string
	nodeId?: string
	integrationId?: string
}

export const CanvasNodeHeader = React.memo(
	({ selected, label, icon, logo, logoBg, nodeId, integrationId }: Props) => {
		const ref = useRef<HTMLDivElement>(null)
		const [currentRefEl, setCurrentRefEl] = useState<HTMLDivElement | null>(
			ref.current,
		)

		useEffect(() => {
			setCurrentRefEl(ref.current)
		}, [ref])

		const isTextOverflowing = currentRefEl
			? currentRefEl.scrollHeight > currentRefEl.clientHeight
			: false

		return (
			<Flex alignItems="center" justifyContent="space-between" w="full" p={2}>
				<Flex alignItems="center" gap={3}>
					<StepLogo icon={icon} logo={logo} bg={logoBg} />
					<Tooltip
						label={label}
						variant={TOOLTIP_ENUM.workflows_side_panel}
						isDisabled={!isTextOverflowing}
						placement="top"
					>
						<Text ref={ref} noOfLines={2} variant={TEXT_ENUM.semibold16}>
							{label}
						</Text>
					</Tooltip>
				</Flex>
				<Flex flexShrink={0}>
					<CanvasNodeHeaderMenu
						nodeId={nodeId}
						integrationId={integrationId}
						selected={selected}
					/>
				</Flex>
			</Flex>
		)
	},
)
