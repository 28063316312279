import type { ComponentStyleConfig } from '@chakra-ui/react'

import { RADII } from '../../../tokens/borders'
import { ColorTokens } from '../../../tokens/colors'

const Modal: ComponentStyleConfig = {
	baseStyle: {
		header: {
			bg: ColorTokens.white,
			borderRadius: '8px 8px 0 0',
			borderBottomWidth: 'medium',
			p: 5,
			display: 'flex',
			align: 'center',
			borderColor: ColorTokens.border_primary,
			fontSize: '16px',
		},
		body: {
			bg: ColorTokens.white,
			borderRadius: RADII.md,
			p: 5,
		},
		closeButton: {
			top: 4,
			right: 5,
		},
		footer: {
			bg: ColorTokens.white,
			borderRadius: '0 0 8px 8px',
			borderTopWidth: 'medium',
			display: 'flex',
			alignContent: 'center',
			borderColor: ColorTokens.border_primary,
			p: 5,
		},
	},
}

export default Modal
