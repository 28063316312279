import { Flex, HStack, Image, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { FormType } from '@/modules/forms/types'
import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

import { TRIGGERS_CONTENT } from '../../constants/triggers-content'

type Props = {
	type: FormType
	setSelectedTrigger: (form: FormType) => void
	isSelected: boolean
}

export const TriggerCard = ({
	type,
	setSelectedTrigger,
	isSelected,
}: Props) => {
	const content = TRIGGERS_CONTENT[type]

	if (!content || !type) return null

	return (
		<HStack
			bg={isSelected ? COLORS.purple[2] : COLORS.whiteAlpha[12]}
			alignItems="flex-start"
			border={Borders.primary}
			borderColor={isSelected ? COLORS.purple[7] : 'inherit'}
			p={4}
			borderRadius="lg"
			gap={2}
			cursor="pointer"
			_hover={{
				bg: COLORS.purple[2],
				borderColor: COLORS.purple[7],
			}}
			onClick={() => setSelectedTrigger(type)}
		>
			<Image src={content.logo} w="40px" h="40px" />
			<Flex flexDir="column" gap={1}>
				<Text variant={TEXT_ENUM.semibold14}>{content.name}</Text>
				<Text variant={TEXT_ENUM.regular14} color={COLORS.background[4]}>
					{content.description}
				</Text>
			</Flex>
		</HStack>
	)
}
