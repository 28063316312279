import { Box, Flex } from '@chakra-ui/react'
import { GetIcon, Icons } from '@ds/Icons'
import { capitalize } from 'lodash'
import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import {
	useSelectedNode,
	useUpdateWorkflowNode,
} from '@/modules/workflow/hooks'
import type { Default_TimeDelay } from '@/modules/workflow/types/actions'
import { TimeUnit, TimeUnitConfig } from '@/modules/workflow/types/time-units'
import { TEXT_ENUM } from '@/styles/components/text'

import { PanelInput } from '../../../../../panel-variants/PanelInput'
import { PanelInputLabel } from '../../../../../panel-variants/PanelInputLabel'
import { handleTimeChange } from './time-util'

const DEFAULT_STATE = {
	[TimeUnit.DAYS]: 0,
	[TimeUnit.HOURS]: 0,
	[TimeUnit.MINUTES]: 1,
}

export const DefaultTimeDelay = () => {
	const updateNode = useUpdateWorkflowNode()
	const selectedNode = useSelectedNode()
	const stepDetails = selectedNode?.data?.stepDetails as Default_TimeDelay
	const [timesInput, setTimesInput] = useState<TimeUnitConfig>(
		stepDetails?.units || DEFAULT_STATE,
	)
	useEffect(() => {
		if (!stepDetails?.units) {
			setTimesInput(DEFAULT_STATE)
		}
	}, [stepDetails])
	const debouncedUpdate = useDebouncedCallback(() => {
		onAddTimeUnit()
	}, 500)
	const handleKeyDown = (event: React.KeyboardEvent, unit: TimeUnit) => {
		const { key } = event
		if (key === 'ArrowUp') {
			setTimesInput(handleTimeChange(timesInput, unit, timesInput[unit] + 1))
		} else if (key === 'ArrowDown') {
			setTimesInput(handleTimeChange(timesInput, unit, timesInput[unit] - 1))
		}
		debouncedUpdate()
	}
	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		unit: TimeUnit,
	) => {
		const { value } = event.target
		let numberValue = Number(value)
		if (!numberValue) {
			numberValue = 0
		}
		setTimesInput(handleTimeChange(timesInput, unit, numberValue))
		debouncedUpdate()
	}
	const calculateTimeDelayInSeconds = () => {
		const days = timesInput[TimeUnit.DAYS] * 86400
		const hours = timesInput[TimeUnit.HOURS] * 3600
		const minutes = timesInput[TimeUnit.MINUTES] * 60
		return days + hours + minutes
	}
	const onAddTimeUnit = () => {
		if (selectedNode) {
			updateNode({
				...selectedNode,
				data: {
					...selectedNode.data,
					stepDetails: {
						units: timesInput,
						delaySeconds: {
							value: calculateTimeDelayInSeconds(),
						},
					},
				},
			})
		}
	}
	return (
		<Flex direction="column">
			<PanelInputLabel
				label="Time delay"
				tooltipText="Use arrow keys to navigate"
				marginBottom="8px"
			/>
			<Flex width="75%" align="flex-start">
				{Object.keys(timesInput).map((time, i) => (
					<Flex key={i}>
						<Flex direction="column">
							<PanelInputLabel label={capitalize(time)} />
							<PanelInput
								onKeyDown={(event) => handleKeyDown(event, time as TimeUnit)}
								onChange={(event) => handleInputChange(event, time as TimeUnit)}
								fontSize={TEXT_ENUM.xs}
								initialValue={String(timesInput[time])}
								width="80%"
							/>
						</Flex>
					</Flex>
				))}
				<Box position="relative" top="50%">
					<GetIcon verticalAlign="center" icon={Icons.clock_edit} />
				</Box>
			</Flex>
		</Flex>
	)
}
