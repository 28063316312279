import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { useNavigate } from 'react-router-dom'
import { GetIcon, Icons } from 'ui'

import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

export type PageNavStepsType = {
	title: string
	icon?: Icons
	route?: string
}

interface Props extends FlexProps {
	steps: PageNavStepsType[]
	children?: React.ReactNode
	variant?: 'default' | 'secondary'
}

export const PageNav = ({
	steps,
	children,
	variant = 'default',
	...props
}: Props) => {
	const nav = useNavigate()

	const handleNav = (step: PageNavStepsType) => {
		if (step?.route) {
			nav(step.route)
		}
	}

	return (
		<Flex
			h={variant === 'default' ? '32px' : '56px'}
			borderBottom={Borders.primary}
			bg={COLORS.whiteAlpha[12]}
			alignItems="center"
			px={6}
			flexShrink={0}
			gap={4}
			{...props}
		>
			<Flex gap={2} alignItems="center" flexShrink={0}>
				{steps.map((step, index) => (
					<Flex
						key={index}
						cursor={step.route ? 'pointer' : 'default'}
						alignItems="center"
						onClick={() => handleNav(step)}
						gap={2}
						color={
							index === 0 && variant === 'secondary'
								? ColorTokens.text_primary
								: COLORS.background[5]
						}
					>
						{index > 0 && (
							<GetIcon icon={Icons.chevron_left} transform="rotate(180deg)" />
						)}
						{step.icon && <GetIcon icon={step.icon} fontSize="16px" />}
						<Text
							variant={
								variant === 'default' ? TEXT_ENUM.medium12 : TEXT_ENUM.medium14
							}
						>
							{step.title}
						</Text>
					</Flex>
				))}
			</Flex>
			{children}
		</Flex>
	)
}
