import { Flex, Text } from '@chakra-ui/react'

import { useGetIntegrationConnection } from '@/modules/workflow/hooks/useGetIntegrationConnection'
import { TEXT_ENUM } from '@/styles/components/text'

import { WorkflowIntegrationsMapType as IntegrationsMapType } from '../../../../types/integrations'
import { IntegrationListItem } from './IntegrationListItem'

interface IntegrationListSectionProps {
	title: string
	integrations: IntegrationsMapType
}

export const IntegrationListSection = ({
	title,
	integrations,
}: IntegrationListSectionProps) => {
	const { getIntegrationConnection } = useGetIntegrationConnection()

	return (
		<Flex direction="column">
			<Text variant={TEXT_ENUM.md} mb={2} ml={1}>
				{title}
			</Text>
			{Object.keys(integrations).map((integration, idx) => {
				const {
					integrationAction,
					logo,
					nodeType,
					stepType,
					integrationId,
					integrationCategory,
				} = integrations[integration]

				const integrationConnection = getIntegrationConnection(integrationId)

				return (
					<IntegrationListItem
						key={`${integrationAction}-${idx}}`}
						action={integrationAction}
						logo={logo}
						nodeType={nodeType}
						stepType={stepType}
						integrationId={integrationId}
						integrationCategory={integrationCategory}
						integrationConnection={integrationConnection}
					/>
				)
			})}
		</Flex>
	)
}
