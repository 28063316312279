import { PartialExcept } from 'shared-utils'

export interface Tag {
	id: number
	label: string
	color: string
	team_id: number
	deleted_at: string | null
	created_at: string
	updated_at: string
	archived_at: string | null
	created_by: string | null
	instances?: number
}

export interface GetTagsPayload {
	teamId?: number | string | null
	instances?: boolean
}

export interface CreateTagPayload {
	tag: Partial<Tag>
}

export interface UpdateTagPayload {
	tag: PartialExcept<Tag, 'id'>
	instances?: boolean
}

export interface DeleteTagPayload {
	teamId: number
	ids: string[]
}

export const fallbackTag = {
	id: 0,
	label: '',
	color: '',
	team_id: 0,
	deleted_at: null,
	created_at: '',
	updated_at: '',
	archived_at: null,
	created_by: null,
	instances: 0,
}
