import { useCallback } from 'react'

import { AsyncDrpOptionGenerator } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker'
import { settleAllOptionGenerators } from '@/modules/workflow/utils/settleAllOptionGenerators'

import { useGetSlackResourcesDrpOptions } from '../generators/useGetSlackResourcesDrpOptions'
import { useGetOwnerDrpOptions } from './useGetOwnerDrpOptions'

export const useGetSlackRecipientsDrpOptions = (): AsyncDrpOptionGenerator => {
	const getOwnerDrpOptions = useGetOwnerDrpOptions()
	const getSlackResourcesDrpOptions = useGetSlackResourcesDrpOptions()

	return useCallback(
		async ({ onSelect }: { onSelect: (value: any) => void }) => {
			const optionGenerators = [getOwnerDrpOptions, getSlackResourcesDrpOptions]

			return settleAllOptionGenerators(optionGenerators, onSelect)
		},
		[getSlackResourcesDrpOptions, getOwnerDrpOptions],
	)
}
