import { Flex, Link, Text, Tooltip } from '@chakra-ui/react'
import { GetIcon, Icons } from '@ds/Icons'
import { Cell } from '@tanstack/react-table'
import { useState } from 'react'
import { useMount } from 'react-use'

import { CRM_TYPES_BY_TRIGGER } from '@/modules/workflow/constants/crm-types'
import { useGetCrmInstanceUrl } from '@/modules/workflow-logs/hooks/useGetCrmInstanceUrl'
import { TOOLTIP_ENUM } from '@/styles/components/tooltip'

import { WorkflowLog } from '../../../types'

type Props = {
	cell: Cell<WorkflowLog, unknown>
	triggerType: string
}

export const LogsCrmRecordLinkCellRender = ({ cell, triggerType }: Props) => {
	const row = cell.row.original
	const id = row.crmRecord?.id || row.crmRecord?.Id
	const getCrmInstanceUrl = useGetCrmInstanceUrl()
	const [instanceUrl, setInstanceUrl] = useState<string | null>(null)

	const crmType = Object.keys(CRM_TYPES_BY_TRIGGER).find((key) =>
		CRM_TYPES_BY_TRIGGER[key].includes(triggerType),
	)

	useMount(() => {
		getCrmInstanceUrl(crmType).then((url) => {
			setInstanceUrl(url)
		})
	})

	if (!id) {
		return null
	}

	if (!instanceUrl) {
		return <Text>{id}</Text>
	}

	return (
		<Tooltip
			variant={TOOLTIP_ENUM.workflows_side_panel}
			label={`Open this record in ${crmType}`}
			aria-label={`Open this record in ${crmType}`}
		>
			<Link
				href={`${instanceUrl}/${id}`}
				target="_blank"
				_hover={{ textDecoration: 'none' }}
				onClick={(e) => e.stopPropagation()}
			>
				<Flex align="center" gap={2}>
					<Text>{id}</Text>
					<GetIcon icon={Icons.open_link} boxSize={4} />
				</Flex>
			</Link>
		</Tooltip>
	)
}
