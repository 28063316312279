import { chakra } from '@chakra-ui/react'

import { ReactComponent as DFLogo } from '@/assets/logo/default-logo.svg'
import { ReactComponent as Google } from '@/assets/logo/google.svg'
import { ReactComponent as Hubspot } from '@/assets/logo/hubspot.svg'
import { ReactComponent as Salesforce } from '@/assets/logo/salesforce.svg'

import { ReactComponent as GoogleCalendar } from '../../assets/logo/google-calendar.svg'
import { ReactComponent as DarkLogo } from '../../assets/logo/logo-dark.svg'
import { ReactComponent as LightLogo } from '../../assets/logo/logo-light.svg'
import { ReactComponent as Outlook } from '../../assets/logo/outlook.svg'
import { ReactComponent as Slack } from '../../assets/logo/slack.svg'
import { ReactComponent as Zoom } from '../../assets/logo/zoom.svg'

const DefaultLightLogo = chakra(LightLogo)
const DefaultDarkLogo = chakra(DarkLogo)
const DefaultLogoWithText = chakra(DFLogo)
const GoogleLogo = chakra(Google)
const SalesforceLogo = chakra(Salesforce)
const HubspotLogo = chakra(Hubspot)
const GoogleCalendarLogo = chakra(GoogleCalendar)
const SlackLogo = chakra(Slack)
const ZoomLogo = chakra(Zoom)
const OutlookLogo = chakra(Outlook)

export {
	DefaultDarkLogo,
	DefaultLightLogo,
	DefaultLogoWithText,
	GoogleCalendarLogo,
	GoogleLogo,
	HubspotLogo,
	OutlookLogo,
	SalesforceLogo,
	SlackLogo,
	ZoomLogo,
}
