import { Flex, IconButton, Input } from '@chakra-ui/react'
import { GetIcon, Icons } from 'ui'

import { Borders } from '@/styles/borders'
import { BTN } from '@/styles/components/button'

type Props = {
	dataKey: string
	dataValue: string
	onDataKeyChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	onDataValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	onDelete: () => void
}

export const EditRow = ({
	dataKey,
	dataValue,
	onDataKeyChange,
	onDataValueChange,
	onDelete,
}: Props) => {
	return (
		<Flex
			border={Borders.primary}
			p={2}
			borderRadius="12px"
			gap={2}
			alignItems="center"
		>
			<Input placeholder="key" value={dataKey} onChange={onDataKeyChange} />
			<Input
				placeholder="value"
				value={dataValue}
				onChange={onDataValueChange}
			/>
			<IconButton
				variant={BTN.primary_icon}
				aria-label="Delete"
				onClick={onDelete}
				icon={<GetIcon icon={Icons.close} />}
			/>
		</Flex>
	)
}
