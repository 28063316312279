import { useSelectedWorkflow } from '@/modules/workflow/hooks/workflow'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataTag } from '../MetaDataTag'
import { MetaDataTitle } from '../MetaDataTitle'
import { MetaDataWarningTag } from '../MetaDataWarningTag'

export const SalesforceDetails = () => {
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	const recordType = selectedWorkflow?.meta?.trigger_config?.object

	if (!recordType) {
		return (
			<MetaDataRowLayout>
				<MetaDataTitle title="Record Type:" />
				<MetaDataWarningTag warning="Select an option" />
			</MetaDataRowLayout>
		)
	}

	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="Record Type:" />
			<MetaDataTag label={recordType} />
		</MetaDataRowLayout>
	)
}
