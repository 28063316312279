import { useSelectedWorkflow } from '@/modules/workflow/hooks/workflow'
import { getHSRecordType } from '@/modules/workflow/utils/getHSRecordType'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataTag } from '../MetaDataTag'
import { MetaDataTitle } from '../MetaDataTitle'
import { MetaDataWarningTag } from '../MetaDataWarningTag'

export const HubspotDetails = () => {
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	const subscriptionType =
		selectedWorkflow?.meta?.trigger_config?.subscriptionType

	const recordType = getHSRecordType(subscriptionType)

	if (!recordType) {
		return (
			<MetaDataRowLayout>
				<MetaDataTitle title="Record Type:" />
				<MetaDataWarningTag warning="Select an option" />
			</MetaDataRowLayout>
		)
	}

	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="Record Type:" />
			<MetaDataTag label={recordType} />
		</MetaDataRowLayout>
	)
}
