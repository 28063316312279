import { COLORS } from '@ds/tokens/colors'
import React, { CSSProperties, useMemo } from 'react'
import {
	BaseEdge,
	EdgeLabelRenderer,
	EdgeProps,
	getSmoothStepPath,
} from 'reactflow'

import { EdgeDeleteButton } from './EdgeDeleteButton'

// this is a little helper component to render the actual edge label
export const EdgeLabel = ({
	targetX,
	targetY,
	label,
	type,
}: {
	targetX: number
	targetY: number
	label: string
	type: string
}) => {
	const typeStyle: CSSProperties = useMemo(() => {
		return type === 'true'
			? {
					position: 'absolute',
					borderRadius: '6px',
					padding: '4px 8px',
					fontSize: '12px',
					fontWeight: 500,
					transform: `translate(-50%, -100%) translate(${targetX}px,${
						targetY - 30
					}px)`,
					color: COLORS.green[9],
					backgroundColor: COLORS.green[2],
					border: `1px solid ${COLORS.green[4]}`,
				}
			: {
					position: 'absolute',
					borderRadius: '6px',
					padding: '4px 8px',
					fontSize: '12px',
					fontWeight: 500,
					transform: `translate(-50%, -100%) translate(${targetX}px,${
						targetY - 30
					}px)`,
					color: COLORS.red[9],
					backgroundColor: COLORS.red[2],
					border: `1px solid ${COLORS.red[4]}`,
				}
	}, [targetX, targetY, type])

	return (
		<div style={typeStyle} className="nodrag nopan">
			{label}
		</div>
	)
}

export const CustomEdge = React.memo(
	({
		source,
		target,
		sourceHandleId,
		sourceX,
		sourceY,
		targetX,
		targetY,
		sourcePosition,
		targetPosition,
		data,
		style,
	}: EdgeProps) => {
		const [edgePath, labelX, labelY] = getSmoothStepPath({
			sourceX,
			sourceY,
			sourcePosition,
			targetX,
			targetY,
			targetPosition,
			borderRadius: 20,
		})

		return (
			<>
				<BaseEdge
					path={edgePath}
					style={{ stroke: COLORS.background[9], strokeWidth: '2px', ...style }}
				/>
				<EdgeLabelRenderer>
					{data.label && (
						<EdgeLabel
							targetX={targetX}
							targetY={targetY}
							label={data.label}
							type={data.labelType}
						/>
					)}
					{data.isHovered && (
						<EdgeDeleteButton
							labelX={labelX}
							labelY={labelY}
							source={source}
							target={target}
							sourceHandleId={sourceHandleId}
						/>
					)}
				</EdgeLabelRenderer>
			</>
		)
	},
)

CustomEdge.displayName = 'CustomEdge'
