import { useGetParameterInfo } from '@/modules/workflow/hooks'
import { useGetConditionDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetConditionDrpOptions'
import { useGetParameterLabel } from '@/modules/workflow/hooks/useGetParameterLabel'
import { FieldRefNodeValue } from '@/modules/workflow/types/actions'

import { DataReferencePicker } from '../../../../DataReferencePicker'
import { ConditionInputProps } from '../../conditional/Condition'

export const ConditionParameterInput = ({
	condition,
	handleUpdateInput,
}: ConditionInputProps) => {
	const getDrpOptions = useGetConditionDrpOptions()
	const parameter = condition.parameter as FieldRefNodeValue | null

	const parameterInfo = useGetParameterInfo({ parameter })
	const label = useGetParameterLabel(
		parameter,
		parameterInfo.parameterAttribute,
	)

	return (
		<DataReferencePicker
			getInitialOptions={getDrpOptions}
			initialOption={
				parameter
					? {
							label,
							value: parameter,
						}
					: null
			}
			controlStyles={{
				minHeight: 'fit-content',
				maxWidth: '220px',
			}}
			onSelect={handleUpdateInput}
		/>
	)
}
