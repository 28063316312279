import {
	Button,
	useMultiStyleConfig,
	useTab,
	UseTabProps,
} from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import React from 'react'

import { Radii } from '@/styles/borders'
import { BTN } from '@/styles/components/button'
import { SHADOWS } from '@/styles/constants'

interface CustomTabProps extends UseTabProps {
	isFirst?: boolean
	isLast?: boolean
}

export const SecondaryButtonTab = React.forwardRef<HTMLElement, CustomTabProps>(
	({ isFirst, isLast, ...props }, ref) => {
		const tabProps = useTab({ ...props, ref })
		const isSelected = !!tabProps['aria-selected']
		const styles = useMultiStyleConfig('Tabs', tabProps)

		return (
			<Button
				__css={styles.tab}
				_selected={{ __css: styles.selected }}
				{...tabProps}
				bg={ColorTokens.white}
				variant={BTN.secondary_v2}
				color={isSelected ? COLORS.purple[9] : COLORS.gray[10]}
				height="36px"
				border="none"
				boxShadow={SHADOWS.secondary_button_v2}
				borderLeftRadius={isFirst ? Radii.lg : 0}
				borderRightRadius={isLast ? Radii.lg : 0}
				_focus={{
					__css: styles.focus,
					outline: 'none',
					color: COLORS.purple[9],
				}}
			>
				{tabProps.children}
			</Button>
		)
	},
)

SecondaryButtonTab.displayName = 'SecondaryButtonTab'
