import { Icons } from '@ds/Icons'
import pluralize from 'pluralize'

import { Default_TimeDelay } from '@/modules/workflow/types/actions'
import { WorkflowNode } from '@/modules/workflow/types/nodes'
import { TimeUnit } from '@/modules/workflow/types/time-units'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataTag } from '../MetaDataTag'
import { MetaDataTitle } from '../MetaDataTitle'
import { MetaDataWarningTag } from '../MetaDataWarningTag'

export const TimeDelayDetails = ({ data }: { data: WorkflowNode }) => {
	const stepDetails = data?.stepDetails as Default_TimeDelay
	const formattedTimeDetails = () => {
		if (!stepDetails?.units) {
			return ''
		}
		return `${stepDetails.units.days} ${pluralize(
			TimeUnit.DAYS,
			stepDetails.units.days,
		)}, ${stepDetails.units.hours} ${pluralize(
			TimeUnit.HOURS,
			stepDetails.units.hours,
		)}, ${stepDetails.units.minutes} ${pluralize(
			TimeUnit.MINUTES,
			stepDetails.units.minutes,
		)}`
	}
	if (!stepDetails?.units) {
		return (
			<MetaDataRowLayout>
				<MetaDataTitle title="Delay:" />
				<MetaDataWarningTag warning="Configure delay" icon={Icons.clock} />
			</MetaDataRowLayout>
		)
	}
	if (stepDetails?.delaySeconds.value === 0) {
		return (
			<MetaDataRowLayout>
				<MetaDataTitle title="Delay:" />
				<MetaDataWarningTag
					warning="Minimum delay required"
					icon={Icons.clock}
				/>
			</MetaDataRowLayout>
		)
	}
	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="Delay:" />
			<MetaDataTag label={formattedTimeDetails()} />
		</MetaDataRowLayout>
	)
}
