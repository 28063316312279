import { useFlags } from 'launchdarkly-react-client-sdk'

import { FeatureFlags } from '@/common/constants/feature-flags'

export const useIsDisconnectedIntegrationsAllowed = () => {
	const flags = useFlags()

	return flags[FeatureFlags['disconnected-integrations-allowed']] === undefined
		? false
		: flags[FeatureFlags['disconnected-integrations-allowed']]
}
