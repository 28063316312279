import { Icons } from '@ds/Icons'
import { useParams } from 'react-router-dom'

import { Card } from '@/common/components/Card'
import { FormType } from '@/modules/forms/types'
import { useSelectedWorkflow } from '@/modules/workflow/hooks'

import {
	useGetWorkflowLogsAnalyticsQuery,
	useGetWorkflowLogsMetaDataQuery,
} from '../api'
import { LogsOverviewCardHeader } from '../components/LogsOverview/LogsOverviewCardHeader'
import { LogsOverviewLoading } from '../components/LogsOverview/LogsOverviewLoading'
import { LogsOverviewPublished } from '../components/LogsOverview/LogsOverviewPublished'
import { LogsAnalyticsPartial } from './LogsAnalyticsPartial'

export const LogsOverviewPartial = () => {
	const { selectedWorkflow } = useSelectedWorkflow()
	const triggerType = selectedWorkflow?.type

	const { formId, workflowId } = useParams()

	const { data, isLoading: analyticsLoading } =
		useGetWorkflowLogsAnalyticsQuery(workflowId || formId, {
			skip: !formId && !workflowId,
		})

	const { data: meta, isLoading: metaDataLoading } =
		useGetWorkflowLogsMetaDataQuery(workflowId || formId, {
			skip: !formId && !workflowId,
		})

	if (analyticsLoading || metaDataLoading) return <LogsOverviewLoading />

	const isFormSubmissionType =
		triggerType === FormType.regular || triggerType === FormType.imported

	const title = isFormSubmissionType
		? data?.formName
		: selectedWorkflow?.workflow_name
	const icon = isFormSubmissionType ? Icons.route : Icons.workflow

	return (
		<Card flex="0 0 400px">
			<LogsOverviewCardHeader title={title} icon={icon} />
			<LogsOverviewPublished meta={meta} />
			<LogsAnalyticsPartial data={data} />
		</Card>
	)
}
