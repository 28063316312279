import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

export enum HarmonicEndpoints {
	getHarmonic = 'getHarmonic',
	updateHarmonic = 'updateHarmonic',
	deleteHarmonicApiKey = 'deleteHarmonicApiKey',
}

const harmonicApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[HarmonicEndpoints.getHarmonic]: builder.query<
			{ api_key: string | null },
			void
		>({
			query: () => ({
				url: `/harmonic`,
				responseHandler: responseTransformer,
			}),
		}),
		[HarmonicEndpoints.updateHarmonic]: builder.mutation<void, string>({
			query: (payload) => ({
				url: `/harmonic`,
				method: 'POST',
				body: { apiKey: payload },
			}),
			onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					harmonicApi.util.updateQueryData(
						HarmonicEndpoints.getHarmonic,
						undefined,
						() => {
							return {
								api_key: args,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),
		[HarmonicEndpoints.deleteHarmonicApiKey]: builder.mutation<void, void>({
			query: () => ({
				url: `/harmonic`,
				method: 'DELETE',
			}),
			onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					harmonicApi.util.updateQueryData(
						HarmonicEndpoints.getHarmonic,
						undefined,
						() => {
							return {
								api_key: null,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),
	}),
})

export const {
	useGetHarmonicQuery,
	useUpdateHarmonicMutation,
	useDeleteHarmonicApiKeyMutation,
} = harmonicApi

export { harmonicApi }
