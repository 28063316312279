import { Flex } from '@chakra-ui/react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'
import { Icons } from 'ui'

import { useIsAmplemarketIntegration } from '@/common/hooks/feature-flags/useIsAmplemarketIntegration'
import { useIsReminders2 } from '@/common/hooks/feature-flags/useIsReminders2'
import { useSelectedWorkflow } from '@/modules/workflow/hooks'
import { filterIntegrations } from '@/modules/workflow/utils/filterIntegrations'
import { filterIntegrationsBySearch } from '@/modules/workflow/utils/filterIntegrationsBySearch'

import { WorkflowIntegrationsMapType as IntegrationsMapType } from '../../../../types/integrations'
import {
	WorkflowIntegrationCategory as IntegrationCategory,
	WorkflowIntegrationsMap as Integrations,
	WorkflowIntegrationType,
} from '../../../../utils/mappings'
import { PanelSearchInput } from '../../panel-variants/PanelSearchInput'
import { PanelContentHeader } from '../PanelContentHeader'
import { IntegrationListSection } from './IntegrationListSection'

const LIST_SECTIONS: {
	title: string
	integrationCategory: IntegrationCategory
}[] = [
	{
		title: 'Logic',
		integrationCategory: IntegrationCategory.Logic,
	},
	{
		title: 'Actions',
		integrationCategory: IntegrationCategory.Action,
	},
	{
		title: 'AI Actions',
		integrationCategory: IntegrationCategory.AiAction,
	},
	{
		title: 'Scheduling',
		integrationCategory: IntegrationCategory.Scheduling,
	},
	{
		title: 'Salesforce',
		integrationCategory: IntegrationCategory.Salesforce,
	},
	{
		title: 'HubSpot',
		integrationCategory: IntegrationCategory.HubSpot,
	},
	{
		title: 'Loops',
		integrationCategory: IntegrationCategory.Loops,
	},
	{
		title: 'Notifications',
		integrationCategory: IntegrationCategory.Notification,
	},
	{
		title: 'Sequences',
		integrationCategory: IntegrationCategory.Sequence,
	},
	{
		title: 'Enrichment',
		integrationCategory: IntegrationCategory.Enrichment,
	},
	{
		title: 'Zoom',
		integrationCategory: IntegrationCategory.Zoom,
	},
]

export const IntegrationList = () => {
	const isReminders2 = useIsReminders2()

	const { selectedWorkflow } = useSelectedWorkflow()
	const formType = selectedWorkflow?.type
	const [listedIntegrations, setListedIntegrations] =
		useState<IntegrationsMapType>(Integrations)

	useEffect(() => {
		const integrationsFilteredByFormType = Object.keys(Integrations)
			.filter((integration) =>
				filterIntegrations({ formType, integration, isReminders2 }),
			)
			.reduce((obj, key) => {
				obj[key] = Integrations[key]
				return obj
			}, {} as IntegrationsMapType)

		setListedIntegrations(integrationsFilteredByFormType)
	}, [formType])

	const flags = useFlags()
	const isAmplemarketIntegration = useIsAmplemarketIntegration()
	const showZoomWebinar =
		flags['zoom-webinar'] === undefined ? false : flags['zoom-webinar']

	const handleSearch = (e: { target: { value: string } }) => {
		const filteredIntegrations = Object.keys(listedIntegrations)
			.filter((integration) =>
				filterIntegrationsBySearch({ value: e.target.value, integration }),
			)
			.reduce((obj, key) => {
				obj[key] = Integrations[key]
				return obj
			}, {} as IntegrationsMapType)

		setListedIntegrations(filteredIntegrations)
	}

	return (
		<Flex direction="column" pb={6}>
			<PanelContentHeader title="Objects" titleIcon={Icons.route2} />

			<Flex mb={8}>
				<PanelSearchInput
					w="100%"
					onChange={handleSearch}
					placeholder="Search for objects"
				/>
			</Flex>

			<Flex direction="column" gap={4}>
				{LIST_SECTIONS.filter((section) => {
					if (
						section.integrationCategory === IntegrationCategory.Zoom &&
						!showZoomWebinar
					) {
						return false
					}
					return true
				})
					.filter((section) =>
						Object.keys(listedIntegrations).some(
							(integration) =>
								listedIntegrations[integration].integrationCategory ===
								section.integrationCategory,
						),
					)
					.map(({ title, integrationCategory }, sectionIdx) => {
						const sectionIntegrations: IntegrationsMapType = Object.entries(
							listedIntegrations,
						)
							.filter(
								([, integrationItem]) =>
									integrationItem.integrationCategory === integrationCategory,
							)
							.filter(([, integrationItem]) => {
								if (
									integrationItem.integrationName ===
										WorkflowIntegrationType.Amplemarket &&
									!isAmplemarketIntegration
								) {
									return false
								}
								return true
							})
							.reduce((obj, [key]) => {
								obj[key] = listedIntegrations[key]
								return obj
							}, {} as IntegrationsMapType)

						return (
							<IntegrationListSection
								key={`${title}-${sectionIdx}`}
								title={title}
								integrations={sectionIntegrations}
							/>
						)
					})}
			</Flex>
		</Flex>
	)
}
