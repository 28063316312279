import { Flex, HStack, Image, Text, VStack } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import Hubspot from '@/assets/logo/hubspot.svg'
import DefaultLogo from '@/assets/logo/logo-dark.svg'
import Salesforce from '@/assets/logo/salesforce.svg'
import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

import { TriggersCategoryFilterType } from '../../utils/getTriggersByCategory'

const LEFT_PANEL_WIDTH = '250px'

const OPTIONS = [
	{
		label: 'All',
		value: TriggersCategoryFilterType.all,
		logo: null,
	},
	{
		label: 'Default App',
		value: TriggersCategoryFilterType.default_app,
		logo: DefaultLogo,
	},
	{
		label: 'Salesforce',
		value: TriggersCategoryFilterType.salesforce,
		logo: Salesforce,
	},
	{
		label: 'Hubspot',
		value: TriggersCategoryFilterType.hubspot,
		logo: Hubspot,
	},
]

type Props = {
	setFilter: (filter: TriggersCategoryFilterType) => void
	filter: TriggersCategoryFilterType
}

export const EmptyModalLeftPanel = ({ setFilter, filter }: Props) => {
	return (
		<Flex
			h="full"
			p={4}
			w={LEFT_PANEL_WIDTH}
			flex="0 0 auto"
			borderRight={Borders.primary}
		>
			<VStack w="full" alignItems="flex-start">
				{OPTIONS.map((option) => (
					<HStack
						key={option.value}
						p={2}
						borderRadius="md"
						w="full"
						cursor="pointer"
						bg={filter === option.value ? COLORS.purple[3] : 'transparent'}
						_hover={{ bg: COLORS.purple[3] }}
						onClick={() => setFilter(option.value)}
					>
						{option.logo && <Image src={option.logo} w="22px" />}
						<Text variant={TEXT_ENUM.medium14}>{option.label}</Text>
					</HStack>
				))}
			</VStack>
		</Flex>
	)
}
