import { EventTypeEnum, SchedulerEvent } from '@/app/scheduler/api/events/type'

import { SelectGroupOptionType } from '../components/Select/type'

export const getEventOptions = (
	allEvents: SchedulerEvent[],
	memberId?: string,
) => {
	return allEvents.reduce(
		(options: SelectGroupOptionType[], event: SchedulerEvent) => {
			if (
				event.type === EventTypeEnum.personal &&
				event.created_by === memberId
			) {
				options[0].options.push({ label: event.name, value: event.id })
			} else if (event.type === EventTypeEnum.global) {
				options[1].options.push({ label: event.name, value: event.id })
			} else if (event.type === EventTypeEnum.group) {
				options[2].options.push({ label: event.name, value: event.id })
			}
			return options
		},
		[
			{ label: 'Personal', options: [] },
			{ label: 'Global', options: [] },
			{ label: 'Group', options: [] },
		],
	)
}
