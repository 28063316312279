import { useCallback } from 'react'
import { Icons } from 'ui'

import { useAppSelector } from '@/app/hooks'
import { useLazyGetQuestionsByFormIdQuery } from '@/modules/forms/api/questions'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { selectSelectedWorkflowId } from '../../../slice/selectors'
import { LabeledRefNodeValue } from '../../../types/actions'

export const useGetFormQuestionDrpOptions = (): AsyncDrpOptionGenerator => {
	const selectedWorkflowId = useAppSelector(selectSelectedWorkflowId)
	const [getQuestionsByFormId] = useLazyGetQuestionsByFormIdQuery()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const questions = [
				...(await getQuestionsByFormId(String(selectedWorkflowId))
					.unwrap()
					.then((data) => data)),
			]

			const questionOptions = questions
				.sort(
					(a, b) =>
						a.page_number - b.page_number ||
						a.question_order - b.question_order,
				)
				.map((q) => ({
					label: q.title,
					value: {
						id: q.id,
						type: q.question_type,
						attribute: q.lead_attribute,
					},
					onSelect: (value: any) => {
						const newRfn: LabeledRefNodeValue = {
							refNodeId: '0',
							variable: `$.responses.${value.id}.value`,
							value: null,
							label: null,
							icon: Icons.form_questions,
						}

						onSelect(newRfn)
					},
				}))

			return [
				{
					label: 'Form questions',
					icon: Icons.form_questions,
					value: questionOptions,
				},
			]
		},
		[getQuestionsByFormId, selectedWorkflowId],
	)
}
