import { Flex, Spinner, Text } from '@chakra-ui/react'
import { GetIcon, Icons } from '@ds/Icons'
import { COLORS } from '@ds/tokens/colors'

import { StepStatus } from '@/modules/workflow/hooks/useTestSteps'
import { TEXT_ENUM } from '@/styles/components/text'

export const TestStep = ({
	step,
	status,
	hideIdle = false,
}: {
	step: string
	status: StepStatus
	hideIdle?: boolean
}) => {
	const statusColors = {
		idle: hideIdle ? 'transparent' : COLORS.gray[9],
		running: COLORS.gray[9],
		success: COLORS.green[9],
		error: COLORS.red[8],
		skipped: COLORS.gray[8],
	}

	if (status === 'idle' && hideIdle) return null

	return (
		<Flex alignItems="center" mb={2}>
			{status === 'running' && (
				<Spinner
					size="xs"
					speed="0.6s"
					color={statusColors[status]}
					mr={2.5}
					ml={0.5}
				/>
			)}
			{status === 'success' && (
				<GetIcon
					icon={Icons.check_circle}
					color={statusColors[status]}
					mr={2}
				/>
			)}
			{status === 'error' && (
				<GetIcon
					icon={Icons.exclamation_mark}
					color={statusColors[status]}
					mr={2}
				/>
			)}
			{status === 'skipped' && (
				<GetIcon icon={Icons.restricted} color={statusColors[status]} mr={2} />
			)}
			{status === 'idle' && (
				<GetIcon
					icon={Icons.arrow_left}
					transform="rotate(180deg)"
					color={statusColors[status]}
					mr={2}
				/>
			)}
			<Flex>
				<Text
					variant={TEXT_ENUM.medium16}
					color={statusColors[status]}
					textDecoration={status === 'skipped' ? 'line-through' : 'none'}
				>
					{step}
				</Text>
			</Flex>
		</Flex>
	)
}
