import { useCallback, useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { useAppDispatch } from '@/app/hooks'
import { userActions } from '@/app/user/slice'

export const useRouteMatch = () => {
	const location = useLocation()
	const params = useParams()

	const isRouteMatch = useCallback(
		(route: string) => {
			const paths = location.pathname.split('/')
			return (
				paths.includes(route) ||
				route === location.pathname ||
				route === params['*']
			)
		},
		[location.pathname, params],
	)

	return isRouteMatch
}

/**
 * This hook is used to track the previous route.
 * We call this hook in the root component of the app and it will track the previous path in redux store.
 */
export const useTrackPreviousRoute = () => {
	const location = useLocation()

	const previousPathRef = useRef<string>(location.pathname || '/')
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(userActions.setPreviousPath(previousPathRef.current || '/'))
		previousPathRef.current = location.pathname || '/'
	}, [location, dispatch])
}
