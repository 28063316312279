import { Flex, Text } from '@chakra-ui/react'

import { useUpdateWorkflowNode } from '@/modules/workflow/hooks'
import { WorkflowStep } from '@/modules/workflow-logs/components/WorkflowStep'
import { TEXT_ENUM } from '@/styles/components/text'

import { DescriptionTextarea } from '../components/side-panel/panel-content/DescriptionTextarea'
import { PanelContentHeader } from '../components/side-panel/panel-content/PanelContentHeader'
import { IntegrationSelect } from '../components/side-panel/panel-content/step-details/IntegrationSelect'
import { StepDetailsFooter } from '../components/side-panel/panel-content/step-details/StepDetailsFooter'
import { StepNameInput } from '../components/side-panel/panel-content/step-details/StepNameInput'
import { useCanvasWorkflowLog } from '../hooks/workflow'
import { setStepDetails } from '../presenters/setStepDetails'
import { CanvasNode, StepType } from '../types/nodes'
import { WorkflowIntegrationIds } from '../utils/mappings'
import { WorkflowTriggerStepPartial } from './WorkflowTriggerStepPartial'

interface StepDetailsProps {
	selectedNode: CanvasNode
}

export const StepDetailsPartial = ({ selectedNode }: StepDetailsProps) => {
	const updateNode = useUpdateWorkflowNode()
	const integrationId = selectedNode.data
		.integrationId as WorkflowIntegrationIds
	const { workflowLog } = useCanvasWorkflowLog()

	if (selectedNode?.data?.stepType === StepType.WorkflowStart) {
		return <WorkflowTriggerStepPartial />
	}

	const handleUpdateDescription = (value: string) => {
		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				description: value,
			},
		})
	}

	const nodeLog = workflowLog?.steps.find(
		(step) => step.key === selectedNode.id,
	)

	return (
		<>
			<PanelContentHeader enableNavigation>
				<IntegrationSelect selectedNode={selectedNode} />
				<DescriptionTextarea
					initialValue={selectedNode.data.description || ''}
					onBlur={handleUpdateDescription}
				/>
			</PanelContentHeader>

			<Flex direction="column" mb="200px">
				<StepNameInput
					key={selectedNode.data.id}
					initialValue={selectedNode.data.editableName || ''}
					onBlur={(e) => {
						updateNode({
							...selectedNode,
							data: {
								...selectedNode.data,
								editableName: e.target.value,
							},
						})
					}}
				/>

				{setStepDetails(integrationId)}

				{/* Workflow Log Section */}
				{nodeLog && (
					<Flex direction="column" gap={4} mt={6}>
						<Text variant={TEXT_ENUM.semibold14}>Logs</Text>
						<WorkflowStep workflowStep={nodeLog} />
					</Flex>
				)}
			</Flex>

			<StepDetailsFooter
				selectedNode={selectedNode}
				handleResetStepDetails={(newDetails) =>
					updateNode({
						...selectedNode,
						data: {
							...selectedNode.data,
							stepDetails: newDetails,
						},
					})
				}
			/>
		</>
	)
}
