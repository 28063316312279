import { Logo } from '@/common/components/ListGridLayoutComponents/Logo'
import { FormType } from '@/modules/forms/types'

import { getWorkflowLogo } from '../utils/getWorkflowLogo'

type Props = {
	type?: FormType | null
}

export const WorkflowLogo = ({ type }: Props) => {
	const { logo, icon } = getWorkflowLogo(type)

	return <Logo logo={logo} icon={icon} />
}
