export const validateEmail = (email: string) => {
	const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	return re.test(email)
}

export const validatePhone = (phone: string) => {
	// Basic check for numbers, spaces, hyphens, and leading +
	// TODO: Incorporate a true validation library
	const re = /^\+?[0-9]+(?:[-\s][0-9]+)*$/
	return re.test(phone)
}

export const validateURL = (url: string) => {
	const re =
		// eslint-disable-next-line no-useless-escape
		/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g
	return re.test(url.toLowerCase())
}

export const validateUrlWithConstructor = (string: string) => {
	try {
		const url = new URL(string)
		return url.protocol === 'http:' || url.protocol === 'https:'
	} catch {
		return false
	}
}

export const isPositiveWholeNumeric = (value: string) => {
	return /^\d+$/.test(value)
}
