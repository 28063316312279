import { FormType } from '@/modules/forms/types'

export enum TriggersCategoryFilterType {
	all = 'all',
	default_app = 'default_app',
	salesforce = 'salesforce',
	hubspot = 'hubspot',
}

export const getTriggersByCategory = (filter: TriggersCategoryFilterType) => {
	const possibleTriggers = [
		FormType.regular,
		FormType.meeting_booked_trigger,
		FormType.meeting_cancelled_trigger,
		FormType.meeting_rescheduled_trigger,
		FormType.meeting_reassigned_trigger,
		FormType.tag_assigned_trigger,
		FormType.webhook_trigger,
		FormType.salesforce_object_create_trigger,
		FormType.salesforce_object_update_trigger,
		FormType.hubspot_trigger,
	]

	return possibleTriggers.filter((trigger) => {
		switch (filter) {
			case TriggersCategoryFilterType.default_app:
				return (
					trigger === FormType.regular ||
					trigger === FormType.imported ||
					trigger === FormType.meeting_booked_trigger ||
					trigger === FormType.meeting_cancelled_trigger ||
					trigger === FormType.meeting_rescheduled_trigger ||
					trigger === FormType.meeting_reassigned_trigger ||
					trigger === FormType.meeting_no_show_trigger ||
					trigger === FormType.tag_assigned_trigger ||
					trigger === FormType.webhook_trigger
				)
			case TriggersCategoryFilterType.salesforce:
				return (
					trigger === FormType.salesforce_object_create_trigger ||
					trigger === FormType.salesforce_object_update_trigger
				)
			case TriggersCategoryFilterType.hubspot:
				return trigger === FormType.hubspot_trigger
			default:
				return true
		}
	})
}
