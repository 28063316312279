import { unionBy } from 'lodash'

import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import {
	DeleteQuestionPayload,
	DeleteQuestionsPayload,
	QuestionTable,
	UpdateQuestionPayload,
	UpsertQuestionsPayload,
} from '../../types/questions'
import { formsApi, FormsEndPoints } from '..'

export enum QuestionsEndPoints {
	/** Query */
	getQuestionsByFormId = 'getQuestionsByFormId',
	/** Mutation */
	createQuestion = 'createQuestion',
	updateQuestion = 'updateQuestion',
	upsertQuestions = 'upsertQuestions',
	deleteQuestion = 'deleteQuestion',
	deleteQuestions = 'deleteQuestions',
}

const questionsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[QuestionsEndPoints.getQuestionsByFormId]: builder.query<
			QuestionTable[],
			string | null | undefined
		>({
			query: (formId) => ({
				url: `/forms/${formId}/questions`,
				responseHandler: responseTransformer,
			}),
			providesTags: ['GET_FORM_QUESTIONS'],
		}),

		[QuestionsEndPoints.updateQuestion]: builder.mutation<
			QuestionTable,
			UpdateQuestionPayload
		>({
			query: (payload) => ({
				url: `/forms/questions/${payload.question.id}`,
				responseHandler: responseTransformer,
				method: 'PATCH',
				body: payload,
			}),

			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchQuestions = dispatch(
					questionsApi.util.updateQueryData(
						QuestionsEndPoints.getQuestionsByFormId,
						arg.formId.toString(),
						(draft) => {
							const newQuestions = arg.question as QuestionTable
							const index = draft.findIndex((q) => q.id === newQuestions.id)
							if (index === -1) return draft
							draft[index] = {
								...draft[index],
								...newQuestions,
							}
							return draft
						},
					),
				)

				const patchFormById = dispatch(
					formsApi.util.updateQueryData(
						FormsEndPoints.getFormById,
						arg.formId.toString(),
						(oldForm) => {
							return {
								...oldForm,
								published: false,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchQuestions.undo()
					patchFormById.undo()
				}
			},
		}),

		[QuestionsEndPoints.createQuestion]: builder.mutation<
			QuestionTable[],
			UpsertQuestionsPayload
		>({
			query: (payload) => ({
				url: `/forms/${payload.formId}/questions`,
				responseHandler: responseTransformer,
				method: 'PATCH',
				body: payload,
			}),
			invalidatesTags: ['GET_FORM_QUESTIONS'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchQuestions = dispatch(
					questionsApi.util.updateQueryData(
						QuestionsEndPoints.getQuestionsByFormId,
						arg.formId.toString(),
						(draft) => {
							const newQuestions = arg.questions as QuestionTable[] // This might casue error
							draft.push(...newQuestions)
							return draft
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchQuestions.undo()
				}
			},
		}),

		[QuestionsEndPoints.upsertQuestions]: builder.mutation<
			QuestionTable[],
			UpsertQuestionsPayload
		>({
			query: (payload) => ({
				url: `/forms/${payload.formId}/questions`,
				responseHandler: responseTransformer,
				method: 'PATCH',
				body: payload,
			}),
			invalidatesTags: ['GET_FORM_QUESTIONS'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchQuestions = dispatch(
					questionsApi.util.updateQueryData(
						QuestionsEndPoints.getQuestionsByFormId,
						arg.formId.toString(),
						(draft) => {
							const newQuestions = unionBy(
								arg.questions,
								draft,
								'id',
							) as QuestionTable[]
							return newQuestions
						},
					),
				)

				const patchFormById = dispatch(
					formsApi.util.updateQueryData(
						FormsEndPoints.getFormById,
						arg.formId.toString(),
						(oldForm) => {
							return {
								...oldForm,
								published: false,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchQuestions.undo()
					patchFormById.undo()
				}
			},
		}),

		[QuestionsEndPoints.deleteQuestion]: builder.mutation<
			QuestionTable,
			DeleteQuestionPayload
		>({
			query: (payload) => ({
				url: `/forms/questions/${payload.questionId}`,
				responseHandler: responseTransformer,
				method: 'DELETE',
			}),

			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patch = dispatch(
					questionsApi.util.updateQueryData(
						QuestionsEndPoints.getQuestionsByFormId,
						arg.formId.toString(),
						(draft) => {
							return draft.filter((question) => question.id !== arg.questionId)
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patch.undo()
				}
			},
		}),
		[QuestionsEndPoints.deleteQuestions]: builder.mutation<
			QuestionTable,
			DeleteQuestionsPayload
		>({
			query: (payload) => ({
				url: `/forms/${payload.formId}/questions`,
				responseHandler: responseTransformer,
				method: 'DELETE',
				body: { questionIds: payload.questionIds },
				invalidateTags: ['GET_FORM_BY_ID'],
			}),

			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patch = dispatch(
					questionsApi.util.updateQueryData(
						QuestionsEndPoints.getQuestionsByFormId,
						arg.formId.toString(),
						(draft) => {
							return draft.filter((question) =>
								arg.questionIds.includes(question.id),
							)
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patch.undo()
				}
			},
		}),
	}),
})

export const {
	useGetQuestionsByFormIdQuery,
	useLazyGetQuestionsByFormIdQuery,
	useUpdateQuestionMutation,
	useDeleteQuestionMutation,
	useUpsertQuestionsMutation,
	useDeleteQuestionsMutation,
	useCreateQuestionMutation, // TODO: This is not used anywhere
} = questionsApi

export { questionsApi }
