import { Flex } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { useEffect, useState } from 'react'

import { useGetParameterInfo } from '@/modules/workflow/hooks'
import { useGetDefaultDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetDefaultDrpOptions'
import { useGetPicklistValuesDrpOptions } from '@/modules/workflow/hooks/drp-options/generators/useGetPicklistValuesDrpOptions'
import { useGetParameterLabel } from '@/modules/workflow/hooks/useGetParameterLabel'
import { FieldRefNodeValue } from '@/modules/workflow/types/actions'

import { PanelCloseButton } from '../../../panel-variants/PanelCloseButton'
import { PanelSelect } from '../../../panel-variants/PanelSelect'
import {
	CUSTOM_VALUE_LABEL,
	DataReferencePicker,
} from '../../DataReferencePicker'

interface FieldMappingProps {
	fieldId: string
	fieldKey: FieldRefNodeValue
	fieldValue: any
	fieldOptions: any[]
	isDataLoading?: boolean
	order: number
	integrationName: string
	handleSetFieldKey: (value: any, fieldId: string, order: number) => void
	handleSetFieldValue: (value: any, fieldId: string, field: any) => void
	handleDeleteMapping: (fieldId: string, order: number) => void
}

export const FieldMapping = ({
	fieldId,
	fieldKey,
	fieldValue,
	fieldOptions,
	isDataLoading,
	order,
	integrationName,
	handleSetFieldKey,
	handleSetFieldValue,
	handleDeleteMapping,
}: FieldMappingProps) => {
	const getDefaultDrpOptions = useGetDefaultDrpOptions()

	const [getInitialDrpOptions, setGetInitialDrpOptions] = useState(
		() => getDefaultDrpOptions,
	)
	const [triggerOptionsUpdate, setTriggerOptionsUpdate] =
		useState<boolean>(false)

	const fieldKeyAttribute = useGetParameterInfo({ parameter: fieldKey })

	const isLegacyFieldMapping = !fieldKey.icon
	const isCustomValue = fieldKey.label === CUSTOM_VALUE_LABEL

	const fieldKeyLabel = useGetParameterLabel(
		isLegacyFieldMapping || isCustomValue
			? { ...fieldKey, label: isCustomValue ? CUSTOM_VALUE_LABEL : null }
			: fieldKey,
		fieldKeyAttribute.parameterAttribute,
	)

	// TODO: https://linear.app/withdefault/issue/DEF-4006/improvement-extract-picklist-support-in-fieldmapping-to-appropriate-sf
	const getPicklistValuesDrpOptions = useGetPicklistValuesDrpOptions({
		picklistField: fieldValue || {},
		remainingDrpOptions: [getDefaultDrpOptions],
	})

	useEffect(() => {
		if (fieldValue?.type === 'picklist') {
			setGetInitialDrpOptions(() => getPicklistValuesDrpOptions)
			setTriggerOptionsUpdate(!triggerOptionsUpdate)
		} else {
			setGetInitialDrpOptions(() => getDefaultDrpOptions)
			setTriggerOptionsUpdate(!triggerOptionsUpdate)
		}
	}, [fieldValue?.type])

	return (
		<Flex
			mb={2}
			p={2}
			border="1px solid #E6E6E6"
			borderRadius="12px"
			bg={COLORS.background[12]}
		>
			<Flex direction="column" flexGrow={1} gap={1.5} mr={1.5}>
				<DataReferencePicker
					getInitialOptions={getInitialDrpOptions}
					initialOption={{
						label: fieldKeyLabel,
						value: fieldKey,
					}}
					enableCustomValue
					triggerOptionsUpdate={triggerOptionsUpdate}
					onSelect={(rfnValue) => handleSetFieldKey(rfnValue, fieldId, order)}
				/>
				<PanelSelect
					options={fieldOptions}
					value={
						fieldValue ? { label: fieldValue.label, value: fieldValue } : null
					}
					placeholder={`Select ${integrationName} field`}
					isLoading={isDataLoading}
					isSearchable
					onChange={(op) =>
						op ? handleSetFieldValue(op.value, fieldId, fieldKey) : undefined
					}
				/>
			</Flex>
			<PanelCloseButton
				size="sm"
				onClick={() => handleDeleteMapping(fieldId, order)}
			/>
		</Flex>
	)
}
