export const CLEARBIT_STATES = [
	'Alberta',
	'Alaska',
	'Alabama',
	'Arkansas',
	'American Samoa',
	'Arizona',
	'British Columbia',
	'California',
	'Colorado',
	'Connecticut',
	'Washington D.C.',
	'Delaware',
	'Florida',
	'Georgia',
	'Guam',
	'Hawaii',
	'Iowa',
	'Idaho',
	'Illinois',
	'Indiana',
	'Kansas',
	'Kentucky',
	'Louisiana',
	'Massachusetts',
	'Manitoba',
	'Maryland',
	'Maine',
	'Michigan',
	'Minnesota',
	'Missouri',
	'Northern Mariana Islands',
	'Mississippi',
	'Montana',
	'New Brunswick',
	'North Carolina',
	'North Dakota',
	'Nebraska',
	'New Hampshire',
	'New Jersey',
	'Newfoundland and Labrador',
	'New Mexico',
	'Nouvelle-Écosse',
	'Northwest Territories',
	'Nunavut',
	'Nevada',
	'New York',
	'Ohio',
	'Oklahoma',
	'Ontario',
	'Oregon',
	'Pennsylvania',
	'Prince Edward Island',
	'Puerto Rico',
	'Palau',
	'Quebec',
	'Rhode Island',
	'South Carolina',
	'South Dakota',
	'Saskatchewan',
	'Tennessee',
	'Texas',
	'Utah',
	'Virginia',
	'Virgin Islands',
	'Vermont',
	'Washington',
	'Wisconsin',
	'West Virginia',
	'Wyoming',
	'Yukon',
]
