import { Icons } from 'ui'

import { TriggersMapType } from '../../types/integrations'
import { RFNodeType, StepType } from '../../types/nodes'
import { WorkflowIntegrationType as IntegrationType } from './integrations'

export enum TriggerIds {
	formSubmission = 'formSubmission',
}

export const TriggersMap: TriggersMapType = {
	formSubmission: {
		integrationId: null,
		integrationName: IntegrationType.Default,
		logo: Icons.no_icon,
		integrationAction: 'Form',
		stepDetails: {},
		nodeType: RFNodeType.flowStart,
		stepType: StepType.WorkflowStart,
		isIcon: true,
	},
}
