import { Icons } from 'ui'

import { useGetTagsQuery } from '@/app/tags/api'
import { useTeamId } from '@/app/user/api/teams/selectors'

import { MetaDataRowLayout } from '../../../layouts/MetaDataRowLayout'
import { MetaDataLoading } from './MetaDataLoading'
import { MetaDataTagList } from './MetaDataTagList'
import { MetaDataTitle } from './MetaDataTitle'
import { MetaDataWarningTag } from './MetaDataWarningTag'

type Props = {
	selectedTagIds: number[]
}

export const TagAssignRow = ({ selectedTagIds }: Props) => {
	const teamId = useTeamId()

	const { data, isLoading } = useGetTagsQuery({ teamId })

	if (selectedTagIds?.length === 0) {
		return (
			<MetaDataRowLayout>
				<MetaDataTitle title="Tags:" />
				<MetaDataWarningTag warning="Add tags" icon={Icons.tag} />
			</MetaDataRowLayout>
		)
	}

	if (isLoading) return <MetaDataLoading title="Tags:" />

	const tags = selectedTagIds.map((tag: number) => ({
		label: data?.[tag]?.label || '',
		icon: Icons.tag,
	}))

	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="Tags:" />
			<MetaDataTagList tags={tags} />
		</MetaDataRowLayout>
	)
}
