import { Flex } from '@chakra-ui/react'

import { Canvas } from '../components/canvas/Canvas'
import { SidePanelPartial } from '../partials'

export const WorkflowBuilderPage = () => {
	return (
		<Flex w="100%" h="100%" position="relative">
			<Canvas />
			<SidePanelPartial />
		</Flex>
	)
}
