import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { AllWorkflowlogs, WorkflowLog } from '../types'
import { WorkflowLogsAnalytics } from '../types/analytics'
import { WorkflowLogsMeta } from '../types/meta'
import { WorkflowLogsQueryVariables } from '../types/query-variables'

export enum WorkflowLogsEndpoints {
	getAllWorkflowLogs = 'getAllWorkflowLogs',
	getWorkflowLog = 'getWorkflowLog',
	getWorkflowLogsAnalytics = 'getWorkflowLogsAnalytics',
	getWorkflowLogsMetaData = 'getWorkflowLogsMetaData',
}

const workflowLogsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[WorkflowLogsEndpoints.getAllWorkflowLogs]: builder.query<
			AllWorkflowlogs,
			WorkflowLogsQueryVariables
		>({
			query: (payload) => ({
				url: `/workflow-logs/form`,
				method: 'POST',
				body: payload,
				responseHandler: responseTransformer,
				skip: !payload.payload.formId,
			}),
		}),
		[WorkflowLogsEndpoints.getWorkflowLog]: builder.query<
			WorkflowLog,
			string | number | undefined
		>({
			query: (submissionId) => ({
				url: `/workflow-logs/submission/${submissionId}`,
				method: 'GET',
				responseHandler: responseTransformer,
			}),
		}),
		[WorkflowLogsEndpoints.getWorkflowLogsAnalytics]: builder.query<
			WorkflowLogsAnalytics,
			string | number | undefined
		>({
			query: (formId) => ({
				url: `/workflow-logs/analytics/${formId}`,
				method: 'GET',
				responseHandler: responseTransformer,
			}),
		}),
		[WorkflowLogsEndpoints.getWorkflowLogsMetaData]: builder.query<
			WorkflowLogsMeta,
			string | number | undefined
		>({
			query: (formId) => ({
				url: `/workflow-logs/meta/${formId}`,
				method: 'GET',
				responseHandler: responseTransformer,
			}),
		}),
	}),
})

export const {
	useGetAllWorkflowLogsQuery,
	useGetWorkflowLogQuery,
	useLazyGetWorkflowLogQuery,
	useGetWorkflowLogsAnalyticsQuery,
	useGetWorkflowLogsMetaDataQuery,
} = workflowLogsApi
