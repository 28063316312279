import { Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import React from 'react'

import { TEXT_ENUM } from '@/styles/components/text'

type Props = {
	description?: string | null
}

export const NodeDescription = React.memo(({ description }: Props) => {
	if (!description) return null

	return (
		<Text variant={TEXT_ENUM.medium14} color={COLORS.background[6]}>
			{description}
		</Text>
	)
})

NodeDescription.displayName = 'NodeDescription'
