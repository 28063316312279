import { useCallback } from 'react'

import SalesforceLogo from '@/assets/logo/salesforce-step.svg'
import { AsyncDrpOptionGenerator } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker/components/Option'
import { LabeledRefNodeValue } from '@/modules/workflow/types/actions'
import { settleAllOptionGenerators } from '@/modules/workflow/utils/settleAllOptionGenerators'

export const useGetPicklistValuesDrpOptions = ({
	picklistField,
	remainingDrpOptions,
}: {
	picklistField: Record<string, any>
	remainingDrpOptions: AsyncDrpOptionGenerator[]
}): AsyncDrpOptionGenerator => {
	const fieldName = picklistField.label
	const picklistValues = picklistField.picklistValues

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const picklistValueOptions = picklistValues.map((value) => ({
				label: value.label,
				value: value,
				onSelect: () => {
					const newRfn: LabeledRefNodeValue = {
						refNodeId: null,
						variable: null,
						label: `${value.label} (from Picklist options)`,
						value: value.value,
						icon: SalesforceLogo,
					}

					onSelect(newRfn)
				},
			}))

			const remainingOptions = await settleAllOptionGenerators(
				remainingDrpOptions,
				onSelect,
			)

			return [
				{
					label: `${fieldName} picklist values`,
					value: picklistValueOptions,
					icon: SalesforceLogo,
				},
				...remainingOptions,
			]
		},
		[fieldName, picklistValues],
	)
}
