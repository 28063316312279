import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { SlackChannelType, SlackIntegration, SlackUserType } from '../type'

export enum SlackEndPoints {
	/** Query */
	getSlack = 'getSlack',
	getSlackSignInUrl = 'getSlackSignInUrl',
	getSlackUsers = 'getSlackUsers',
	getSlackChannels = 'getSlackChannels',
	/** Mutation */
	deleteSlack = 'deleteSlack',
}

const slackApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[SlackEndPoints.getSlack]: builder.query<SlackIntegration, void>({
			query: () => ({
				url: `/integrations?integration=slack`,
				responseHandler: responseTransformer,
			}),
		}),

		[SlackEndPoints.getSlackSignInUrl]: builder.query<string, string>({
			query: (route) => ({
				url: `/slack/oauth/url?route=${route}`,
				responseHandler: (response) => response.json().then((data) => data.url),
			}),
		}),
		[SlackEndPoints.getSlackUsers]: builder.query<SlackUserType[], void>({
			query: () => ({
				url: `/slack/users`,
				responseHandler: (res) => res.json().then((data) => data?.members),
			}),
		}),
		[SlackEndPoints.getSlackChannels]: builder.query<SlackChannelType[], void>({
			query: () => ({
				url: `/slack/channels`,
				responseHandler: (res) =>
					res.json().then((data) => data?.channels.flat()),
			}),
		}),
		[SlackEndPoints.deleteSlack]: builder.mutation<{ status: boolean }, void>({
			query: () => ({
				url: `/integrations?integration=slack`,
				method: 'DELETE',
			}),
			onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					slackApi.util.updateQueryData(
						SlackEndPoints.getSlack,
						undefined,
						() => {
							return {
								id: null,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),
	}),
})

export const {
	useDeleteSlackMutation,
	useGetSlackQuery,
	useGetSlackSignInUrlQuery,
	useLazyGetSlackSignInUrlQuery,
	useGetSlackUsersQuery,
	useGetSlackChannelsQuery,
	useLazyGetSlackChannelsQuery,
	useLazyGetSlackUsersQuery,
	useLazyGetSlackQuery,
} = slackApi

export { slackApi }
