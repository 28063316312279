import { Button, Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import { useMemo, useState } from 'react'
import { GetIcon, Icons } from 'ui'

import { useSelectedWorkflow } from '@/modules/workflow/hooks'
import {
	SalesforceCreateTriggerConfig,
	useUpdateSalesforceCreateTriggerConfig,
} from '@/modules/workflow/hooks/triggers-config'
import { ConditionBranch } from '@/modules/workflow/types/logic'
import { CanvasNode } from '@/modules/workflow/types/nodes'
import { branchesToSalesforceFormula } from '@/modules/workflow/utils/matching'
import { BTN } from '@/styles/components/button'

import { PanelInputLabel } from '../../../../../panel-variants/PanelInputLabel'
import { WarningModal } from '../../../WarningModal'
import { ConditionalModal } from '../../conditional/ConditionalModal'
import { ConditionDetails } from '../../conditional/ConditionDetails'
import { ConditionComparatorInput } from './ConditionComparatorInput'
import { ConditionParameterInput } from './ConditionParameterInput'
import { ConditionValueInput } from './ConditionValueInput'

export const SalesforceFormula = () => {
	const [isConditionModalOpen, setIsConditionModalOpen] = useState(false)
	const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)

	const updateSalesforceCreateTrigger = useUpdateSalesforceCreateTriggerConfig()
	const triggerConfig: SalesforceCreateTriggerConfig =
		useSelectedWorkflow()?.selectedWorkflow?.meta?.trigger_config

	const branches = triggerConfig?.branches

	const conditionComponents = {
		ConditionParameterInput,
		ConditionComparatorInput,
		ConditionValueInput,
	}

	const conditionalObject = useMemo(() => {
		return branches
			? { branches }
			: {
					branches: [
						{
							id: '1234',
							name: '1234',
							resultChildId: null,
							elseChildId: null,
							conditionBlocks: [],
						},
					],
				}
	}, [branches])

	const conditionBlocks = conditionalObject.branches[0]?.conditionBlocks

	const updateSalesforceFormula = (conditionalObject: {
		branches: ConditionBranch[]
	}) => {
		const newSalesforceFormula = branchesToSalesforceFormula(
			conditionalObject.branches,
		)

		updateSalesforceCreateTrigger({
			...triggerConfig,
			formula: newSalesforceFormula,
			branches: conditionalObject.branches,
		})
	}

	const handleModalOpen = () => {
		setIsConditionModalOpen(true)
	}

	const handleModalClose = () => {
		setIsConditionModalOpen(false)
	}

	const handleOpenConfirmation = () => {
		setIsConditionModalOpen(false)
		setIsWarningModalOpen(true)
	}

	const handleCancelConfirmation = () => {
		setIsWarningModalOpen(false)
		setIsConditionModalOpen(true)
	}

	const handleRemoveAllConditions = () => {
		const newConditionalObject = cloneDeep(conditionalObject)
		newConditionalObject.branches[0].conditionBlocks = []

		updateSalesforceFormula(newConditionalObject)
	}

	const handleRemoveConfirm = () => {
		handleRemoveAllConditions()
		handleCancelConfirmation()
	}

	return (
		<Flex direction="column">
			<PanelInputLabel
				label="Formula"
				description="Only run this workflow when the following conditions are met for the Salesforce object"
			/>
			{conditionBlocks?.length > 0 && (
				<ConditionDetails conditionBlocks={conditionBlocks} isMatchCriteria />
			)}
			<Button
				variant={BTN.workflows_side_panel}
				leftIcon={<GetIcon icon={Icons.adjustments} />}
				onClick={handleModalOpen}
			>
				{`${conditionBlocks?.length > 0 ? 'Edit' : 'Add'}`} conditions
			</Button>
			<ConditionalModal
				isOpen={isConditionModalOpen}
				branchIndex={0} // there will always only be one branch with Match criteria
				selectedNode={{} as CanvasNode} // ConditionalModal only checks for this being truthy; empty and asserted b/c it's not relevant to the trigger
				conditionComponents={conditionComponents}
				conditionalObject={conditionalObject}
				handleOpenConfirmation={handleOpenConfirmation}
				updateNode={updateSalesforceFormula}
				onClose={handleModalClose}
			/>
			<WarningModal
				isOpen={isWarningModalOpen}
				icon={Icons.trash}
				warningMessage="Do you really want to remove all conditions?"
				warningSubtext="You can’t undo this action and will need to add all conditions from scratch."
				handleConfirm={handleRemoveConfirm}
				handleCancel={handleCancelConfirmation}
			/>
		</Flex>
	)
}
