import { ChevronRightIcon } from '@chakra-ui/icons'
import {
	Button,
	Flex,
	MenuItem,
	MenuItemProps,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Portal,
} from '@chakra-ui/react'
import { ColorTokens } from '@ds/tokens/colors'
import { cloneElement } from 'react'
import { GetIcon } from 'ui'

interface Item {
	icon?: any
	// the label displayed and the value used onClick might be different
	label: any
	value: any
}

export interface SubmenuProps extends MenuItemProps {
	title: string
	options: Array<Item>
	index: number
	selectedIndex: number
	setSelectedIndex: (arg: number) => void
	subIndex: number
	onClick: (value: any) => void
	icon?: any
	placement?: 'left' | 'right'
	popoverWidth?: number
}

export const Submenu = ({
	title,
	options,
	index,
	selectedIndex,
	setSelectedIndex,
	subIndex,
	onClick,
	icon,
	placement,
	popoverWidth,
	...props
}: SubmenuProps) => {
	return (
		<MenuItem
			bg={selectedIndex === index ? ColorTokens.bg_hover : 'transparent'}
			py="0"
			pl={3}
			pr={2}
			{...props}
		>
			<Popover
				trigger="hover"
				placement={placement == undefined ? 'right' : placement}
				gutter={18}
				isOpen={index > -1 && selectedIndex === index}
				closeOnEsc
			>
				{/* @ts-ignore */}
				<PopoverTrigger>
					<Button
						w="100%"
						p={0}
						lineHeight="1"
						bg="rgba(0,0,0,0)"
						fontWeight={500}
						fontSize="sm"
						_hover={{
							bg: 'rgba(0,0,0,0)',
						}}
						textAlign="start"
						justifyContent="flex-start"
						onMouseEnter={() => setSelectedIndex(index)}
					>
						<Flex w="100%" align="center" justify="space-between">
							<Flex align="center">
								{icon != undefined && cloneElement(icon, { marginRight: 2 })}
								{title}
							</Flex>
							<ChevronRightIcon boxSize="18px" />
						</Flex>
					</Button>
				</PopoverTrigger>
				<Portal>
					<PopoverContent
						w={popoverWidth != undefined ? popoverWidth : 'fit-content'}
					>
						<PopoverBody p={1}>
							<Flex direction="column">
								{options.map((option, j) => {
									return (
										<Flex
											key={option.label}
											as="button"
											p={0}
											w="100%"
											lineHeight="1.3"
											h={10}
											bg={
												subIndex === j ? ColorTokens.bg_hover : 'rgba(0,0,0,0)'
											}
											fontWeight={500}
											fontSize="sm"
											_hover={{
												bg: ColorTokens.bg_hover,
											}}
											textAlign="start"
											justifyContent="flex-start"
											px={4}
											borderRadius="md"
											align="center"
											onClick={() => {
												onClick(option.value)
											}}
										>
											{option.icon && (
												<GetIcon icon={option.icon} boxSize={4} mr={2} />
											)}
											{option.label}
										</Flex>
									)
								})}
							</Flex>
						</PopoverBody>
					</PopoverContent>
				</Portal>
			</Popover>
		</MenuItem>
	)
}
