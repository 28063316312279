export const formatJsonLog = (
	json?: Record<string, any> | null,
): { key: string; value: any }[] => {
	const result: { key: string; value: any }[] = []

	if (!json) return result

	const keys = Object.keys(json)

	for (let i = 0; i < keys.length; i++) {
		const key = keys[i]
		let value = json[key]

		if (typeof value === 'object') {
			value = JSON.stringify(value)
		}
		result.push({ key, value })
	}

	return result
}
