import { Flex, Switch } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import { SingleValue } from 'react-select'

import { PanelInputLabel } from '@/modules/workflow/components/side-panel/panel-variants/PanelInputLabel'
import { PanelSelect } from '@/modules/workflow/components/side-panel/panel-variants/PanelSelect'
import { EMPTY_LABELED_REF_NODE } from '@/modules/workflow/constants/step-details'
import {
	useCurrentNodeData,
	useGetParameterInfo,
	useSelectedNode,
	useUpdateWorkflowNode,
} from '@/modules/workflow/hooks'
import { useGetDefaultDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetDefaultDrpOptions'
import { useGetOwnerDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetOwnerDrpOptions'
import { useGetParameterLabel } from '@/modules/workflow/hooks/useGetParameterLabel'
import {
	SalesforceRecordOption,
	useSalesforceRecordOptionsByType,
} from '@/modules/workflow/hooks/useSalesforceRecordOptionsByType'
import {
	FieldRefNodeValue,
	LabeledRefNodeValue,
} from '@/modules/workflow/types/actions'
import {
	Salesforce_ConvertLead,
	SalesforceEntities,
} from '@/modules/workflow/types/salesforce'

import { DataReferencePicker } from '../../../../DataReferencePicker'
import { DEFAULT_SALESFORCE_CONVERT_LEAD_WF_STEP_DETAILS } from './step-detail'

const TOOLTIP_MESSAGES = {
	leadSource:
		'Specifies whether to overwrite the LeadSource field on the target Contact object with the contents of the LeadSource field in the source Lead object. To enable this field a Contact must be selected.',
	owner:
		'Specifies the person to own any newly created Account, Contact, and Opportunity. If not specified, the owner of the Lead is used.',
	notificationEmail:
		'Specifies whether to send a notification email to the specified owner. This is email is sent by Salesforce.',
}

export const SalesforceConvertLead = () => {
	const updateNode = useUpdateWorkflowNode()
	const getOpportunityDrpOptions = useGetDefaultDrpOptions()
	const getOwnerDrpOptions = useGetOwnerDrpOptions()
	const selectedNode = useSelectedNode()
	const { getSalesforceRecordOptions } = useSalesforceRecordOptionsByType()

	const { getCurrentNodeData } = useCurrentNodeData(selectedNode)

	const stepDetails = (selectedNode?.data?.stepDetails ||
		cloneDeep(
			DEFAULT_SALESFORCE_CONVERT_LEAD_WF_STEP_DETAILS,
		)) as Salesforce_ConvertLead

	const { leadId, accountId, contactId } = stepDetails

	const leadRecordOptions = getSalesforceRecordOptions(SalesforceEntities.Lead)

	const selectedLeadRecordOption = leadRecordOptions.find(
		(op) => op.value === leadId.refNodeId && op.variable === leadId.variable,
	)

	const accountRecordOptions = getSalesforceRecordOptions(
		SalesforceEntities.Account,
	)

	const selectedAccountRecordOption = accountRecordOptions.find(
		(op) =>
			op.value === accountId.refNodeId && op.variable === accountId.variable,
	)

	const contactRecordOptions = getSalesforceRecordOptions(
		SalesforceEntities.Contact,
	)

	const selectedContactRecordOption = contactRecordOptions.find(
		(op) =>
			op.value === contactId.refNodeId && op.variable === contactId.variable,
	)

	// TODO: The below process should and will be refactored to use within the DRP component itself.
	// Ticket: https://linear.app/withdefault/issue/DEF-3668/refactor-getting-label-for-drp-option-using-usegetparameterlabel
	const selectedOpportunityAttribute = useGetParameterInfo({
		parameter: stepDetails.opportunityName as FieldRefNodeValue,
	})

	const selectedOpportunityLabel = useGetParameterLabel(
		stepDetails.opportunityName,
		selectedOpportunityAttribute.parameterAttribute,
	)

	const selectedOpportunityOption = selectedOpportunityLabel
		? {
				label: selectedOpportunityLabel,
				value: stepDetails.opportunityName,
			}
		: null

	const selectedOwnerOption = stepDetails.ownerId.label
		? { label: stepDetails.ownerId.label, value: stepDetails.ownerId }
		: null

	const updateNodeStepDetails = (newStepDetails: Salesforce_ConvertLead) => {
		if (!selectedNode) return

		const currentNodeData = getCurrentNodeData()

		updateNode({
			...currentNodeData,
			data: {
				...currentNodeData.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const initializeNewStepDetails = (stepDetails) => {
		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Salesforce_ConvertLead

		return currentStepDetails || cloneDeep(stepDetails)
	}

	const handleSelectLeadRecord = (
		recordOp: SingleValue<SalesforceRecordOption>,
	) => {
		if (!recordOp) return

		const newStepDetails = initializeNewStepDetails(stepDetails)

		newStepDetails.leadId = {
			refNodeId: recordOp.value,
			variable: recordOp.variable,
			value: null,
			label: recordOp.label,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleSelectAccountRecord = (
		recordOp: SingleValue<SalesforceRecordOption>,
	) => {
		if (!recordOp) return

		const newStepDetails = initializeNewStepDetails(stepDetails)

		newStepDetails.accountId = {
			refNodeId: recordOp.value,
			variable: recordOp.variable,
			value: null,
			label: recordOp.label,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleClearAccountRecord = () => {
		const newStepDetails = initializeNewStepDetails(stepDetails)

		newStepDetails.accountId = {
			...EMPTY_LABELED_REF_NODE,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleSelectContactRecord = (
		recordOp: SingleValue<SalesforceRecordOption>,
	) => {
		if (!recordOp) return

		const newStepDetails = initializeNewStepDetails(stepDetails)

		newStepDetails.contactId = {
			refNodeId: recordOp.value,
			variable: recordOp.variable,
			value: null,
			label: recordOp.label,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleClearContactRecord = () => {
		const newStepDetails = initializeNewStepDetails(stepDetails)

		newStepDetails.contactId = {
			...EMPTY_LABELED_REF_NODE,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleCreateOpportunityToggle = () => {
		const newStepDetails = initializeNewStepDetails(stepDetails)

		newStepDetails.doNotCreateOpportunity = {
			...newStepDetails.doNotCreateOpportunity,
			value: !newStepDetails.doNotCreateOpportunity.value,
		}

		if (newStepDetails.doNotCreateOpportunity.value) {
			newStepDetails.opportunityName = {
				...EMPTY_LABELED_REF_NODE,
			}
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleSelectOpportunityName = (rfn: LabeledRefNodeValue) => {
		if (!rfn) return

		const newStepDetails = initializeNewStepDetails(stepDetails)

		newStepDetails.opportunityName = rfn

		updateNodeStepDetails(newStepDetails)
	}

	const handleOverwriteLeadSourceToggle = () => {
		const newStepDetails = initializeNewStepDetails(stepDetails)

		newStepDetails.overwriteLeadSource = {
			...newStepDetails.overwriteLeadSource,
			value: !newStepDetails.overwriteLeadSource.value,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleSendNotificationEmailToggle = () => {
		const newStepDetails = initializeNewStepDetails(stepDetails)

		newStepDetails.sendNotificationEmail = {
			...newStepDetails.sendNotificationEmail,
			value: !newStepDetails.sendNotificationEmail.value,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleSelectOwnerId = (rfn: LabeledRefNodeValue) => {
		if (!rfn) return

		const newStepDetails = initializeNewStepDetails(stepDetails)

		newStepDetails.ownerId = rfn

		updateNodeStepDetails(newStepDetails)
	}

	const handleClearOwnerId = () => {
		const newStepDetails = initializeNewStepDetails(stepDetails)

		newStepDetails.ownerId = {
			...EMPTY_LABELED_REF_NODE,
		}

		updateNodeStepDetails(newStepDetails)
	}

	return (
		<Flex w="100%" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel label="Lead to convert" />
				<PanelSelect
					placeholder="Select a lead"
					value={selectedLeadRecordOption || null}
					options={leadRecordOptions}
					onChange={handleSelectLeadRecord}
				/>
			</Flex>

			<Flex direction="column">
				<PanelInputLabel label="Account to merge into (optional)" />
				<PanelSelect
					placeholder="Select an account"
					value={selectedAccountRecordOption || null}
					options={accountRecordOptions}
					isClearable
					clearValue={handleClearAccountRecord}
					onChange={handleSelectAccountRecord}
				/>
			</Flex>

			<Flex direction="column">
				<PanelInputLabel label="Contact to merge into (optional)" />
				<PanelSelect
					placeholder="Select a contact"
					value={selectedContactRecordOption || null}
					options={contactRecordOptions}
					isClearable
					clearValue={handleClearContactRecord}
					onChange={handleSelectContactRecord}
				/>
			</Flex>

			<Flex gap={2}>
				<PanelInputLabel label="Create Opportunity on conversion" />
				<Switch
					isChecked={!stepDetails.doNotCreateOpportunity.value}
					onChange={handleCreateOpportunityToggle}
				/>
			</Flex>

			{!stepDetails.doNotCreateOpportunity.value && (
				<Flex direction="column">
					<PanelInputLabel label="Opportunity name" />
					<DataReferencePicker
						getInitialOptions={getOpportunityDrpOptions}
						initialOption={selectedOpportunityOption}
						enableCustomValue
						onSelect={handleSelectOpportunityName}
					/>
				</Flex>
			)}

			<Flex gap={2}>
				<PanelInputLabel
					label="Overwrite LeadSource field"
					tooltipText={TOOLTIP_MESSAGES.leadSource}
				/>
				<Switch
					disabled={!stepDetails.contactId.refNodeId}
					isChecked={stepDetails.overwriteLeadSource.value}
					onChange={handleOverwriteLeadSourceToggle}
				/>
			</Flex>

			<Flex direction="column">
				<PanelInputLabel
					label="Owner (optional)"
					tooltipText={TOOLTIP_MESSAGES.owner}
				/>
				<DataReferencePicker
					getInitialOptions={getOwnerDrpOptions}
					initialOption={selectedOwnerOption}
					isClearable
					clearValue={handleClearOwnerId}
					onSelect={handleSelectOwnerId}
				/>
			</Flex>

			<Flex gap={2}>
				<PanelInputLabel
					label="Send notification email"
					tooltipText={TOOLTIP_MESSAGES.notificationEmail}
				/>
				<Switch
					isChecked={stepDetails.sendNotificationEmail.value}
					onChange={handleSendNotificationEmailToggle}
				/>
			</Flex>
		</Flex>
	)
}
