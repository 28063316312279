import { Flex, FlexProps } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { Icons } from 'ui'

import { Borders } from '@/styles/borders'

import { EditableTextInput } from '../../EditableTextInput'
import { PageLogo } from './PageLogo'
import { StatusBar } from './StatusBar'

interface Props extends FlexProps {
	title: string
	icon?: Icons | null
	logo?: string | null
	avatar?: string | null
	avatarName?: string | null
	edit?: boolean
	onTitleInputBlur?: (title: string) => void
	children?: React.ReactNode
	noBorder?: boolean
}

export const PageHeading = ({
	title,
	logo,
	icon,
	avatarName,
	avatar,
	edit,
	onTitleInputBlur,
	children,
	...props
}: Props) => {
	return (
		<Flex flexDir="column">
			<Flex
				alignItems="center"
				gap={4}
				h="56px"
				borderBottom={Borders.primary}
				bg={COLORS.whiteAlpha[12]}
				px={6}
				flexShrink={0}
				{...props}
			>
				<Flex gap={2} alignItems="center">
					<PageLogo
						logo={logo}
						icon={icon}
						avatar={avatar}
						avatarName={avatarName}
					/>
					<Flex w="max-content">
						<EditableTextInput
							edit={edit}
							title={title}
							onBlur={onTitleInputBlur}
						/>
					</Flex>
				</Flex>
				{children}
			</Flex>
			<StatusBar />
		</Flex>
	)
}
