import { Button, Center, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { ReactComponent as NoWorkflow } from '@/assets/illustations/no-workflow.svg'
import { BTN } from '@/styles/components/button'
import { TEXT_ENUM } from '@/styles/components/text'

type Props = {
	createNewWorkflow: () => void
}

const CONTENT_MAX_WIDTH = '370px'

export const EmptyPageContent = ({ createNewWorkflow }: Props) => {
	return (
		<Center flex={1} flexDir="column">
			<Flex maxW={CONTENT_MAX_WIDTH} textAlign="center" flexDir="column">
				<NoWorkflow />
				<Text variant={TEXT_ENUM.semibold18} mt={6} mb={2}>
					Improve your process with Automations
				</Text>
				<Text variant={TEXT_ENUM.regular14} color={COLORS.background[4]} mb={6}>
					Explore beyond form submissions with our versatile workflow triggers.
					Effortlessly manage your integrations, complex processes, and more,
					freeing your team for creative and strategic tasks.
				</Text>
				<Button
					variant={BTN.outline}
					leftIcon={<GetIcon icon={Icons.plus} />}
					onClick={createNewWorkflow}
				>
					Create new workflow
				</Button>
			</Flex>
		</Center>
	)
}
