import { Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import { SingleValue } from 'react-select'

import { useAppSelector } from '@/app/hooks'
import { leadAttributesInfo as leadAttributes } from '@/app/leads/helper'
import { useIsSlateRichTextEditor } from '@/common/hooks/feature-flags/useIsSlateRichTextEditor'
import { RichTextEditor } from '@/components/RichTextEditor'
import SlateRichTextEditor from '@/components/SlateRichTextEditor'
import { useGetQuestionsByFormIdQuery } from '@/modules/forms/api/questions'
import {
	createMentionsFromDynamicFields,
	createMentionsFromLeadAttributes,
	createMentionsFromQuestions,
} from '@/modules/forms/utils/text-editor-helpers'
import { MEETING_DYNAMIC_FIELDS } from '@/modules/workflow/constants/meeting-dynamic-fields'
import { useUpdateWorkflowNode } from '@/modules/workflow/hooks'
import { useGetSlackMessageDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetSlackMessageDrpOptions'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import { selectSelectedWorkflowId } from '@/modules/workflow/slice/selectors'
import { Ai_Prompt } from '@/modules/workflow/types/actions'
import { AiActionModels } from '@/modules/workflow/types/ai-actions'

import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../../../panel-variants/PanelSelect'
import { DataReferencePicker } from '../../../DataReferencePicker'

export const AiPrompt = () => {
	const updateNode = useUpdateWorkflowNode()
	const selectedNode = useSelectedNode()

	const isSlateRichTextEditorEnabled = useIsSlateRichTextEditor()

	const stepDetails = selectedNode?.data.stepDetails as Ai_Prompt

	const selectedWorkflowId = useAppSelector(selectSelectedWorkflowId)
	const { data: questions } = useGetQuestionsByFormIdQuery(
		String(selectedWorkflowId),
		{
			skip: !selectedWorkflowId,
		},
	)

	// TODO: don't use Slack DRP options here
	const getInitialDrpOptions = useGetSlackMessageDrpOptions()

	const suggestionOptions = [
		...createMentionsFromQuestions(questions),
		...createMentionsFromLeadAttributes(leadAttributes()),
		...createMentionsFromDynamicFields(MEETING_DYNAMIC_FIELDS),
	]

	const AiPromptDataReferencePicker = (props) => (
		<DataReferencePicker {...props} getInitialOptions={getInitialDrpOptions} />
	)

	const handleSelectModel = (
		modelOp: SingleValue<{
			label: AiActionModels
			value: AiActionModels
		}>,
	) => {
		if (!selectedNode || !modelOp) return

		const newStepDetails = cloneDeep({
			...(stepDetails || {
				model: {
					refNodeId: null,
					variable: null,
					value: null,
				},
				prompt: {
					refNodeId: null,
					variable: null,
					value: null,
				},
			}),
		} as Ai_Prompt)

		newStepDetails.model = {
			refNodeId: null,
			variable: null,
			value: modelOp.value,
		}

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const handleUpdatePrompt = (value: string) => {
		if (!selectedNode) return
		const newStepDetails = cloneDeep({
			...(stepDetails || {
				model: {
					refNodeId: null,
					variable: null,
					value: null,
				},
				prompt: {
					refNodeId: null,
					variable: null,
					value: null,
				},
			}),
		} as Ai_Prompt)

		newStepDetails.prompt = {
			refNodeId: null,
			variable: null,
			value,
		}

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const modelOptions = Object.values(AiActionModels).map((model) => ({
		label: model,
		value: model,
	}))

	const modelValue = modelOptions.find(
		(op) => op.value === stepDetails?.model?.value,
	)

	return (
		<Flex w="100%" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel label="Model" />
				<PanelSelect
					placeholder="Select a model"
					value={modelValue || null}
					options={modelOptions}
					onChange={handleSelectModel}
				/>
			</Flex>

			<Flex direction="column">
				<PanelInputLabel label="Dynamic prompt" />
				<Flex w="100%" h="fit-content">
					{isSlateRichTextEditorEnabled ? (
						<SlateRichTextEditor
							key={`ai-prompt-${selectedNode?.id}`}
							disabledEditingBarButtons={[
								'bold',
								'italic',
								'strikethrough',
								'link',
								'bulleted-list',
								'numbered-list',
								'blockquote',
							]}
							onChange={() => {}}
							onBlur={handleUpdatePrompt}
							bodyText={stepDetails?.prompt?.value || ''}
							Picker={AiPromptDataReferencePicker}
						/>
					) : (
						<RichTextEditor
							suggestionOptions={suggestionOptions}
							disabledEditingBarButtons={[
								'bold',
								'italic',
								'strikethrough',
								'link',
								'bulleted-list',
								'numbered-list',
								'blockquote',
							]}
							onChange={() => {}}
							onBlur={handleUpdatePrompt}
							bodyText={stepDetails?.prompt?.value || ''}
							hasChanged={false}
							useDrp
						/>
					)}
				</Flex>
			</Flex>
		</Flex>
	)
}
