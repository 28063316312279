import { useAppSelector } from '@/app/hooks'
import { useGetSalesforceUsersQuery } from '@/app/integrations/api/salesforce'
import { LeadAttributes, leadAttributesInfo } from '@/app/leads/helper'
import { useGetQuestionsByFormIdQuery } from '@/modules/forms/api/questions'
import { DefaultAttribute } from '@/modules/workflow/types/logic'
import {
	defaultAttributes,
	WorkflowIntegrationIds,
} from '@/modules/workflow/utils/mappings'

import {
	selectSalesforceFields,
	selectSelectedWorkflowId,
} from '../slice/selectors'
import { FieldRefNodeValue, RefNodeValue } from '../types/actions'
import {
	Salesforce_CreateRecord,
	Salesforce_MatchRecord,
} from '../types/salesforce'
import { useCanvasNodes } from './workflow'

const GENERIC_ANY_ATTRIBUTE = defaultAttributes['generic.any']

// TODO: This hook needs to be rewritten. The conditionals are too patched together.
export const useGetParameterInfo = ({
	parameter,
	currentValue,
}: {
	parameter: FieldRefNodeValue | null
	currentValue?: RefNodeValue | null
}): {
	parameterAttribute: DefaultAttribute | null
	values: { label: string; value: string }[]
} => {
	const nodes = useCanvasNodes()?.canvasNodes
	const selectedWorkflowId = useAppSelector(selectSelectedWorkflowId)

	const { data: questions } = useGetQuestionsByFormIdQuery(
		String(selectedWorkflowId),
		{
			skip: !selectedWorkflowId,
		},
	)

	let parameterNode = Object.values(nodes).find(
		(n) => n.data.id === parameter?.refNodeId && n.data.id !== '0',
	)

	const { data: salesforceUsers } = useGetSalesforceUsersQuery(undefined, {
		skip:
			parameterNode?.data.integrationId !==
			WorkflowIntegrationIds.salesforceMatchRecord,
	})

	const salesforceFields = useAppSelector(selectSalesforceFields)

	let parameterAttribute: DefaultAttribute | null = null
	let values: { label: string; value: string }[] = []

	// question
	if (parameter?.variable?.includes('$.responses.')) {
		const questionId = parameter.variable.split('.')[2]

		const parameterQuestion =
			questions?.find((q) => String(q.id) === String(questionId)) || null

		parameterAttribute =
			defaultAttributes[parameterQuestion?.question_type ?? '_'] ||
			defaultAttributes[
				leadAttributesInfo()[
					(parameterQuestion?.lead_attribute ?? '_') as LeadAttributes
				]?.isTextType
					? 'Short Text'
					: 'Single Select'
			]

		// Change the name of the parameter to the question title to be used for option labels.
		parameterAttribute.name = parameterQuestion?.title || 'Unknown question'

		const question = questions?.find((q) => String(q.id) === String(questionId))
		values = [
			...(question?.question_options?.map((v) => {
				const label = question.question_options_labels?.[v] ?? v
				return {
					label,
					value: v,
				}
			}) ?? []),
		]

		// TODO: This is for deleted options that are still used in the condition.
		// Ideally we should cover this case with validation in the future.
		if (currentValue?.value && Array.isArray(currentValue.value)) {
			currentValue.value.forEach((v) => {
				if (!values.find((v2) => v2.value === v)) {
					values.push(v)
				}
			})
		}
	}

	// clearbit
	if (parameter?.variable?.includes('$.enrichment.')) {
		const key = parameter.variable.split('.enrichment.')[1]
		parameterAttribute =
			Object.values(defaultAttributes).find((value) => {
				return (
					value.key === key &&
					(value.type === 'company' || value.type === 'person')
				)
			}) || null

		values = parameterAttribute?.values ?? []
	}

	// harmonic & amplemarket company data
	if (parameter?.variable?.includes('$.company')) {
		const key = parameter.variable.substring(2)
		parameterAttribute =
			Object.values(defaultAttributes).find(
				(value) =>
					value.key === key &&
					(value.type === 'harmonic.company' ||
						value.type === 'amplemarket.company'),
			) || null
		values = parameterAttribute?.values ?? []
	}

	// apollo & amplemarket person data (note that this identifier is very fragile and only tolerated because this component will soon be deprecated)
	if (parameter?.variable?.includes('$.person')) {
		const key = parameter.variable.substring(2)
		parameterAttribute = defaultAttributes[key] || null
		values = parameterAttribute?.values ?? []
	}

	// emaildomain check
	if (parameter?.variable?.includes('emailDomain')) {
		parameterAttribute = defaultAttributes['generic.string']
		values = parameterAttribute?.values ?? []
	}

	// reset parameter node to include 0 node here
	parameterNode = Object.values(nodes).find(
		(n) => n.data.id === parameter?.refNodeId,
	)

	// salesforce picklist support
	if (
		!parameterAttribute &&
		parameter?.dataType === 'picklist' &&
		parameter?.variable
	) {
		const parameterVariableParts = parameter.variable.split('.')

		let salesforceObjectType
		if (parameterNode) {
			salesforceObjectType = (
				parameterNode.data.stepDetails as
					| Salesforce_MatchRecord
					| Salesforce_CreateRecord
			)?.type?.value
		} else {
			salesforceObjectType =
				parameterVariableParts[parameterVariableParts.length - 2]
		}

		const objectFields = salesforceFields?.[salesforceObjectType]
		const parameterField =
			parameterVariableParts?.[parameterVariableParts.length - 1]
		const salesforceField = objectFields?.find((f) => f.name === parameterField)

		parameterAttribute = defaultAttributes.Picklist
		values = salesforceField?.picklistValues || []
	}

	// owner nodes (only support sfdc owner for now)
	if (!parameterAttribute && parameterNode) {
		if (
			parameterNode.data.integrationId ===
				WorkflowIntegrationIds.salesforceMatchRecord &&
			parameter?.variable?.includes('OwnerId')
		) {
			// sfdc match record owner ref
			parameterAttribute =
				Object.values(defaultAttributes).find(
					(value) => value.type === 'owner',
				) || null

			values = (salesforceUsers || []).map((user) => ({
				label: `${user.Name || 'Unknown name'} (${
					user.Email || 'Unknown email'
				})`,
				value: user.Id,
			}))
		} else if (
			!parameter?.variable?.includes('$.enrichment.') &&
			!parameter?.variable?.includes('$.company') &&
			!parameter?.variable?.includes('$.person')
		) {
			// note: the above check is very bad and only tolerated as a short-term solution ahead of deprecation
			if (parameter?.dataType) {
				const dataType = parameter.dataType
				parameterAttribute =
					Object.values(defaultAttributes).find(
						(value) => value.key === `generic.${dataType}`,
					) || GENERIC_ANY_ATTRIBUTE
			} else {
				parameterAttribute = GENERIC_ANY_ATTRIBUTE
			}
		}
	}

	if (!parameterAttribute) {
		parameterAttribute = GENERIC_ANY_ATTRIBUTE
	}

	return { parameterAttribute, values }
}
