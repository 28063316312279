import { Text } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { components, SingleValueProps } from 'react-select'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { Select } from '@/common/components/Select'
import { SelectVariant } from '@/common/components/Select/type'
import { TEXT_ENUM } from '@/styles/components/text'

import { workflowsHomeActions } from '../slice'
import { selectWorkflowsFilter } from '../slice/selectors'

export enum WorkflowsFilters {
	form_submission = 'form_submission',
	meeting_booked = 'meeting_booked',
	meeting_cancelled = 'meeting_cancelled',
	meeting_rescheduled = 'meeting_rescheduled',
	meeting_reassigned = 'meeting_reassigned',
	meeting_no_show = 'meeting_no_show',
	tag_assigned = 'tag_assigned',
	salesforce_object_created = 'salesforce_object_created',
	salesforce_object_updated = 'salesforce_object_updated',
	hubspot_object_created = 'hubspot_object_created',
	webhook_trigger = 'webhook_trigger',
}

export const OPTIONS = [
	{
		label: 'All',
		value: null,
	},
	{
		label: 'Form submission',
		value: WorkflowsFilters.form_submission,
	},
	{
		label: 'Salesforce object created',
		value: WorkflowsFilters.salesforce_object_created,
	},
	{
		label: 'Salesforce object updated',
		value: WorkflowsFilters.salesforce_object_updated,
	},
	{
		label: 'HubSpot object created',
		value: WorkflowsFilters.hubspot_object_created,
	},
	{
		label: 'New meeting booked',
		value: WorkflowsFilters.meeting_booked,
	},
	{
		label: 'Meeting cancelled',
		value: WorkflowsFilters.meeting_cancelled,
	},
	{
		label: 'Meeting rescheduled',
		value: WorkflowsFilters.meeting_rescheduled,
	},
	{
		label: 'Meeting reassigned',
		value: WorkflowsFilters.meeting_reassigned,
	},
	{
		label: 'Meeting marked as no-show',
		value: WorkflowsFilters.meeting_no_show,
	},
	{
		label: 'Tag assigned',
		value: WorkflowsFilters.tag_assigned,
	},
	{
		label: 'Webhook received',
		value: WorkflowsFilters.webhook_trigger,
	},
]

export const WorkflowsFilterBy = React.memo(() => {
	const dispatch = useAppDispatch()
	const filter = useAppSelector(selectWorkflowsFilter)

	const selecctedOption = useMemo(
		() => OPTIONS.find((op) => op.value === filter) || OPTIONS[0],
		[filter],
	)

	return (
		<Select
			options={OPTIONS}
			value={selecctedOption}
			onChange={(option) =>
				dispatch(workflowsHomeActions.setWorkflowsFilter(option?.value || null))
			}
			variant={SelectVariant.secondary}
			components={{
				SingleValue,
			}}
		/>
	)
})

WorkflowsFilterBy.displayName = 'WorkflowsFilterBy'

const SingleValue = (
	props: SingleValueProps<
		{ label: string; value: WorkflowsFilters | null },
		false
	>,
) => {
	return (
		<components.SingleValue {...props}>
			<Text>
				<Text as="span" variant={TEXT_ENUM.medium14}>
					Filter by:{' '}
				</Text>
				{props.children}
			</Text>
		</components.SingleValue>
	)
}
