import { Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { flexRender, Table } from '@tanstack/react-table'

import { WorkflowLog } from '../../types'

type Props = {
	table: Table<WorkflowLog>
}

export const LogsTableHeaders = ({ table }: Props) => {
	const headers = table.getFlatHeaders()
	return (
		<Flex
			w="100%"
			h={12}
			bg={COLORS.background[12]}
			borderBottomWidth="medium"
			borderRadius="5px 5px 0 0"
			flexShrink={0}
			align="center"
			fontSize="12px"
			fontWeight={500}
			px={4}
			color={COLORS.gray[9]}
		>
			{headers.map((header) => {
				return (
					<Flex key={header.id} flex="1">
						<Text>
							{flexRender(header.column.columnDef.header, header.getContext())}
						</Text>
					</Flex>
				)
			})}
		</Flex>
	)
}
