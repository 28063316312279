export enum SHADOWS {
	small = 'small',
	medium = 'medium',
	elevated = 'elevated',
	focus = 'focus',
	depth0 = 'depth0',
	depth1 = 'depth1',
	success = 'success',
	error = 'error',
	warning = 'warning',
}
