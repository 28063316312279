import { COLORS } from '@ds/tokens/colors'
import { Icons } from 'ui'

import { FormType } from '@/modules/forms/types'
import { TRIGGERS_CONTENT } from '@/modules/workflows-home/constants/triggers-content'

import { MembershipTypes } from './membership-plans'

export type TriggerOptionType = {
	value: FormType
	label: string
	logo?: string
	icon?: Icons
	stepLogoBgColor?: string
	membershipType: MembershipTypes
}

export const TRIGGERS_OPTIONS: TriggerOptionType[] = [
	{
		label: 'New form submission',
		value: FormType.regular,
		icon: Icons.form_submission_trigger,
		stepLogoBgColor: COLORS.green[8],
		membershipType: MembershipTypes.Free,
	},
	{
		label: 'Meeting booked',
		value: FormType.meeting_booked_trigger,
		icon: TRIGGERS_CONTENT[FormType.meeting_booked_trigger].icon,
		stepLogoBgColor:
			TRIGGERS_CONTENT[FormType.meeting_booked_trigger].stepLogoBgColor,
		membershipType: MembershipTypes.Free,
	},
	{
		label: 'Meeting cancelled',
		value: FormType.meeting_cancelled_trigger,
		icon: TRIGGERS_CONTENT[FormType.meeting_cancelled_trigger].icon,
		stepLogoBgColor:
			TRIGGERS_CONTENT[FormType.meeting_cancelled_trigger].stepLogoBgColor,
		membershipType: MembershipTypes.Premium,
	},
	{
		label: 'Meeting rescheduled',
		value: FormType.meeting_rescheduled_trigger,
		icon: TRIGGERS_CONTENT[FormType.meeting_rescheduled_trigger].icon,
		stepLogoBgColor:
			TRIGGERS_CONTENT[FormType.meeting_rescheduled_trigger].stepLogoBgColor,
		membershipType: MembershipTypes.Premium,
	},
	{
		label: 'Meeting reassigned',
		value: FormType.meeting_reassigned_trigger,
		icon: TRIGGERS_CONTENT[FormType.meeting_reassigned_trigger].icon,
		stepLogoBgColor:
			TRIGGERS_CONTENT[FormType.meeting_reassigned_trigger].stepLogoBgColor,
		membershipType: MembershipTypes.Premium,
	},
	{
		label: 'Meeting marked as no-show',
		value: FormType.meeting_no_show_trigger,
		icon: TRIGGERS_CONTENT[FormType.meeting_no_show_trigger].icon,
		stepLogoBgColor:
			TRIGGERS_CONTENT[FormType.meeting_no_show_trigger].stepLogoBgColor,
		membershipType: MembershipTypes.Premium,
	},
	{
		label: 'Tag assigned',
		value: FormType.tag_assigned_trigger,
		icon: TRIGGERS_CONTENT[FormType.tag_assigned_trigger].icon,
		stepLogoBgColor:
			TRIGGERS_CONTENT[FormType.meeting_booked_trigger].stepLogoBgColor,
		membershipType: MembershipTypes.Premium,
	},
	{
		label: 'Webhook received',
		value: FormType.webhook_trigger,
		icon: Icons.webhook,
		stepLogoBgColor: COLORS.green[8],
		membershipType: MembershipTypes.Premium,
	},
	{
		label: 'Salesforce object created',
		value: FormType.salesforce_object_create_trigger,
		logo: TRIGGERS_CONTENT[FormType.salesforce_object_create_trigger].logo,
		membershipType: MembershipTypes.Premium,
	},
	{
		label: 'Salesforce object updated',
		value: FormType.salesforce_object_update_trigger,
		logo: TRIGGERS_CONTENT[FormType.salesforce_object_update_trigger].logo,
		membershipType: MembershipTypes.Premium,
	},

	{
		label: 'HubSpot object created',
		value: FormType.hubspot_trigger,
		logo: TRIGGERS_CONTENT[FormType.hubspot_trigger].logo,
		membershipType: MembershipTypes.Premium,
	},
]
