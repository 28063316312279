import { Flex, Text } from '@chakra-ui/react'

import { NumberInput } from '../NumberInput'
import { PaginationArrowButton } from './PaginationArrowButton'

type Props = {
	onPreviousPage: () => void
	onNextPage: () => void
	totalPages: string | number
	currentPage: number
	onPageChange: (page: string) => void
	defaultPage?: number
	isPreviousPageDisabled?: boolean
	isNextPageDisabled?: boolean
	max?: number
}

export const Pagination = ({
	onNextPage,
	onPreviousPage,
	totalPages,
	currentPage,
	defaultPage,
	onPageChange,
	isNextPageDisabled,
	isPreviousPageDisabled,
	max,
}: Props) => {
	return (
		<Flex gap={3} alignItems="center">
			<PaginationArrowButton
				variant="left"
				onClick={onPreviousPage}
				disabled={isPreviousPageDisabled}
			/>
			<Flex alignItems="center" gap={2}>
				<Text fontSize="12px" userSelect="none">
					Page
				</Text>
				<NumberInput
					value={currentPage}
					defaultValue={defaultPage}
					onChange={onPageChange}
					max={max}
				/>
				<Text fontSize="12px">{`of ${totalPages || '-'}`}</Text>
			</Flex>
			<PaginationArrowButton
				variant="right"
				onClick={onNextPage}
				disabled={isNextPageDisabled}
			/>
		</Flex>
	)
}
