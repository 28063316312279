import { useCallback } from 'react'
import { Icons } from 'ui'

import { AsyncDrpOptionGenerator } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker/components/Option'
import { LabeledRefNodeValue } from '@/modules/workflow/types/actions'

import { useSelectedWorkflow } from '../../../workflow'

export const useGetTagAssignedTriggerDrpOptions =
	(): AsyncDrpOptionGenerator => {
		const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
		const triggerName = selectedWorkflow?.workflow?.steps[0].editableName

		return useCallback(
			async ({
				onSelect,
			}: {
				onSelect: (value: any) => void
			}): Promise<DataReferencePickerOption[]> => {
				const label = `Tag assigned in ${
					triggerName ? `"${triggerName}"` : 'trigger node'
				}`

				return [
					{
						label,
						icon: Icons.tag,
						value: 'tag_name',
						onSelect: () => {
							const newRfn: LabeledRefNodeValue = {
								refNodeId: '0',
								variable: `$.tag_name`,
								value: null,
								label,
								icon: Icons.tag,
							}

							onSelect(newRfn)
						},
					},
				]
			},
			[triggerName],
		)
	}
