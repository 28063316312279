import { LeadType, UpdateLeadTablePayload } from '../api/core/type'

type FlattedLeadType = {
	payload: UpdateLeadTablePayload
	lead: LeadType
}

export const flatLead = ({ payload, lead }: FlattedLeadType): LeadType => {
	return {
		id: lead.id,
		team_id: lead.team_id,
		stage_id: payload.lead.stage_id || lead.stage_id,
		custom: payload.lead.custom || lead.custom,
		last_submission_at: lead.last_submission_at,
		created_at: lead.created_at,
		updated_at: lead.updated_at,
		tags: lead.tags,

		// To be overriden by custom
		email: payload.lead.custom?.email || lead.email || '-',
		first_name: payload.lead.custom?.first_name || lead.first_name || '-',
		last_name: payload.lead.custom?.last_name || lead.last_name || '-',
		profile: payload.lead.custom?.extra?.avatar || lead.profile || '-',
		phone: payload.lead.custom?.phone || lead.phone || '-',
		linkedin_handle:
			payload.lead.custom?.extra?.linkedin?.handle ||
			lead.linkedin_handle ||
			null,
		twitter_handle:
			payload.lead.custom?.extra?.twitter?.handle ||
			lead.twitter_handle ||
			null,
		facebook_handle:
			payload.lead.custom?.extra?.facebook?.handle ||
			lead.facebook_handle ||
			null,
		title: payload.lead.custom?.extra?.employment?.title || lead.title || null,
		role: payload.lead.custom?.extra?.employment?.role || lead.role || null,
		seniority:
			payload.lead.custom?.extra?.employment?.seniority ||
			lead.seniority ||
			null,
		company: payload.lead.custom?.company?.name || lead.company || null,
		website: payload.lead.custom?.company?.domain || lead.website || null,
		logo: payload.lead.custom?.company?.logo || lead.logo || null,
		location:
			payload.lead.custom?.company?.extra?.location || lead.location || null,
		city:
			payload.lead.custom?.company?.extra?.location || lead.location || null,
		industry_group:
			payload.lead.custom?.company?.extra?.category?.industryGroup ||
			lead.industry_group ||
			null,
		arr:
			payload.lead.custom?.company?.extra?.metrics?.estimatedAnnualRevenue ||
			lead.arr ||
			null,
		head_count:
			payload.lead.custom?.company?.extra?.metrics?.employeesRange || null,
		funding:
			payload.lead.custom?.company?.extra?.metrics?.raised ||
			lead.funding ||
			null,
		company_tags: lead.custom?.company?.extra?.tags?.join(', ') || null,
		original_utm_source: lead.original_utm_source || null,
		latest_utm_source: lead.latest_utm_source || null,
		original_utm_medium: lead.original_utm_medium || null,
		latest_utm_medium: lead.latest_utm_medium || null,
		original_utm_campaign: lead.original_utm_campaign || null,
		latest_utm_campaign: lead.latest_utm_campaign || null,
		original_utm_term: lead.original_utm_term || null,
		latest_utm_term: lead.latest_utm_term || null,
		original_utm_content: lead.original_utm_content || null,
		latest_utm_content: lead.latest_utm_content || null,
		original_referrer: lead.original_referrer || null,
		latest_referrer: lead.latest_referrer || null,
		original_submission_url: lead.original_submission_url || null,
		latest_submission_url: lead.latest_submission_url || null,
		original_landing_url: lead.original_landing_url || null,
		latest_landing_url: lead.latest_landing_url || null,
		original_landing_referrer: lead.original_landing_referrer || null,
		latest_landing_referrer: lead.latest_landing_referrer || null,
	}
}
