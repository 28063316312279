import { SingleValue } from 'react-select'

import { useGetParameterInfo } from '@/modules/workflow/hooks'
import { FieldRefNodeValue } from '@/modules/workflow/types/actions'

import { PanelSelect } from '../../../../../panel-variants/PanelSelect'
import { ConditionInputProps } from '../../conditional/Condition'

export const ConditionComparatorInput = ({
	condition,
	handleUpdateInput,
}: ConditionInputProps) => {
	const parameter = condition.parameter as FieldRefNodeValue | null
	const { comparator } = condition

	const parameterInfo = useGetParameterInfo({
		parameter,
	})
	const parameterAttribute = parameterInfo.parameterAttribute
	const comparators = parameterAttribute?.comparators || []

	const handleOnChange: (
		newValue: SingleValue<{
			label: string
			value: string
		}>,
	) => void = (option) => {
		if (!option) return
		handleUpdateInput(option.value)
	}

	return (
		<PanelSelect
			options={comparators}
			value={comparators.find((c) => c.value === comparator) || null}
			controlStyles={{
				minHeight: 'fit-content',
			}}
			onChange={handleOnChange}
		/>
	)
}
