import { Center, Image } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { Borders } from '@/styles/borders'

type Props = {
	logo?: string | null
	isIcon?: boolean
}

export const WorkflowStepLogo = ({ logo = Icons.flag, isIcon }: Props) => {
	return (
		<Center
			flex="0 0 auto"
			borderRadius="full"
			border={Borders.primary}
			bg={ColorTokens.white}
			w={8}
			h={8}
		>
			{isIcon && logo && logo in Icons && (
				<GetIcon
					icon={logo as Icons}
					color={COLORS.background[5]}
					maxW="16px"
				/>
			)}
			{!isIcon && logo && <Image src={logo || ''} alt="logo" maxW="16px" />}
		</Center>
	)
}
