import { useCallback } from 'react'
import { Icons } from 'ui'

import { AsyncDrpOptionGenerator } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker/components/Option'
import { LabeledRefNodeValue } from '@/modules/workflow/types/actions'

import { useGetUtmParameterDrpOptions } from '../useGetUtmParameterDrpOptions'

export const useGetFormSubmissionDrpOptions = (): AsyncDrpOptionGenerator => {
	const getUtmParameterDrpOptions = useGetUtmParameterDrpOptions()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const formSubmissionOptions = await getUtmParameterDrpOptions({
				onSelect,
			})

			formSubmissionOptions.push({
				label: 'Domain from email',
				value: 'emailDomain',
				icon: Icons.world,
				onSelect: () => {
					const newRfn: LabeledRefNodeValue = {
						refNodeId: '0',
						variable: `$.emailDomain`,
						value: null,
						label: 'Domain from email',
						icon: Icons.world,
					}

					onSelect(newRfn)
				},
			})

			return [
				{
					label: 'Form submission data',
					icon: Icons.world,
					value: formSubmissionOptions,
				},
			]
		},
		[getUtmParameterDrpOptions],
	)
}
