import { FormType } from '@/modules/forms/types'

export enum CrmTypes {
	Salesforce = 'Salesforce',
	HubSpot = 'HubSpot',
}

export const CRM_TYPES_BY_TRIGGER: Record<CrmTypes, FormType[]> = {
	[CrmTypes.Salesforce]: [
		FormType.salesforce_object_create_trigger,
		FormType.salesforce_object_update_trigger,
	],
	[CrmTypes.HubSpot]: [FormType.hubspot_trigger],
}
