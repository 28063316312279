import { Button, Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'
import { GetIcon, Icons } from 'ui'

import {
	useCurrentNodeData,
	useUpdateWorkflowNode,
} from '@/modules/workflow/hooks'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import {
	FieldRefNodeValue,
	StepDetails,
} from '@/modules/workflow/types/actions'
import { BTN } from '@/styles/components/button'

import { PanelInputLabel } from '../../../panel-variants/PanelInputLabel'
import { FieldMapping } from './FieldMapping'

interface Props {
	integration:
		| 'Salesforce'
		| 'HubSpot'
		| 'Salesloft'
		| 'Outreach'
		| 'Apollo'
		| 'Zoom'
		| 'Amplemarket'
	fields: any[]
	description?: string
	isDataLoading?: boolean
}

export const FieldMapper = ({
	integration,
	fields,
	description,
	isDataLoading,
}: Props) => {
	const selectedNode = useSelectedNode()
	const stepDetails = selectedNode?.data?.stepDetails
	const updateNode = useUpdateWorkflowNode()

	const { getCurrentNodeData } = useCurrentNodeData(selectedNode)

	const integrationKey =
		integration === 'Salesforce'
			? 'insertSalesforceFields'
			: integration === 'HubSpot'
				? 'insertHubspotProperties'
				: integration === 'Salesloft'
					? 'insertPersonFields'
					: integration === 'Outreach'
						? 'insertProspectFields'
						: integration === 'Apollo'
							? 'insertContactFields'
							: integration === 'Zoom'
								? 'insertRegistrantFields'
								: integration === 'Amplemarket'
									? 'insertActionFields'
									: ''

	const isSalesforceOrHubspot =
		isSalesforce(integration) || isHubspot(integration)

	const isOther =
		isOutreach(integration) ||
		isSalesloft(integration) ||
		isApollo(integration) ||
		isZoom(integration) ||
		isAmplemarket(integration)

	const updateNodeStepDetails = (newDetails) => {
		if (!selectedNode) return

		const currentNodeData = getCurrentNodeData()

		updateNode({
			...currentNodeData,
			data: {
				...currentNodeData.data,
				stepDetails: newDetails as StepDetails,
			},
		})
	}

	const handleSetFieldKey = (rfn, currFieldId, i) => {
		if (!stepDetails || !selectedNode) return

		const newStepDetails = getCurrentNodeData().data.stepDetails || {}

		const updated = { ...rfn, i, icon: rfn.icon || null }

		const existingFieldId = Object.keys(newStepDetails[integrationKey]).find(
			(k) => newStepDetails[integrationKey][k].i === i,
		)

		const fieldId = existingFieldId || currFieldId

		const existingField = newStepDetails[integrationKey][fieldId]

		if (existingField.dataType) {
			updated.dataType = existingField.dataType
		}

		newStepDetails[integrationKey][fieldId] = updated

		updateNodeStepDetails(newStepDetails)
	}

	const handleSetFieldValue = (
		newValue: any,
		currFieldId: string,
		currField: FieldRefNodeValue,
	) => {
		if (!stepDetails || !selectedNode) return

		const newStepDetails = getCurrentNodeData().data.stepDetails || {}

		let id = ''
		let type = null

		if (isSalesforceOrHubspot) {
			id = newValue.name
			type = newValue.type
		}

		if (isOther) {
			id = newValue.id
		}

		const val = cloneDeep(currField)
		if (type) {
			val.dataType = type
		}

		if (currFieldId !== id) {
			delete newStepDetails[integrationKey][currFieldId]
		}

		newStepDetails[integrationKey][id] = val

		updateNodeStepDetails(newStepDetails)
	}

	const handleDeleteMapping = (id, i) => {
		if (!stepDetails || !selectedNode) return
		const newStepDetails = getCurrentNodeData().data.stepDetails || {}

		delete newStepDetails?.[integrationKey][id]

		Object.entries<FieldRefNodeValue>(
			newStepDetails?.[integrationKey] ?? {},
		).map(([k, ref]) => {
			const order = ref?.i
			if (order && order > i) {
				const newRef = { ...ref, i: order - 1 }
				newStepDetails[integrationKey][k] = newRef
			}
		})

		updateNodeStepDetails(newStepDetails)
	}

	const handleAddCondition = () => {
		if (!selectedNode) return

		const newStepDetails = getCurrentNodeData().data.stepDetails || {}

		const hasEmpty = Object.keys(
			newStepDetails?.[integrationKey] || {},
		).includes('')

		if (hasEmpty) return

		const empty = nanoid(3)
		newStepDetails[integrationKey] = {
			...newStepDetails[integrationKey],
			[`${empty}`]: {
				refNodeId: null,
				variable: null,
				value: null,
				dataType: null,
				label: null,
				i: Object.keys(newStepDetails[integrationKey] || {}).length,
			},
		}

		updateNodeStepDetails(newStepDetails)
	}

	// Filter out fields that are already mapped, since they can't be mapped twice.
	const filteredFields = fields.filter((f) => {
		const currentStepDetails = getCurrentNodeData().data?.stepDetails || {}
		const currentFields = Object.keys(currentStepDetails[integrationKey] || {})
		return !currentFields.includes(isSalesforceOrHubspot ? f.name : f.id)
	})

	const fieldOptions = filteredFields.map((field) => ({
		label: field.label,
		value: { id: field.label, ...field },
	}))

	return (
		<Flex direction="column">
			<PanelInputLabel label="Field mappings" description={description} />

			{Object.entries<FieldRefNodeValue>(stepDetails?.[integrationKey] || {})
				.sort((a, b) => a[1]?.i - b[1]?.i)
				.map((fieldEntry, i) => {
					const fieldId = fieldEntry[0]
					const fieldKey = fieldEntry[1] as FieldRefNodeValue

					const fieldValue = isSalesforceOrHubspot
						? fields.find((field) => field.name === fieldId)
						: fields.find((field) => field.id === fieldId)

					return (
						<FieldMapping
							key={`${fieldKey.refNodeId}-${i}`}
							fieldId={fieldId}
							fieldKey={fieldKey}
							fieldValue={fieldValue}
							fieldOptions={fieldOptions}
							isDataLoading={isDataLoading}
							order={i}
							integrationName={integration}
							handleSetFieldKey={handleSetFieldKey}
							handleSetFieldValue={handleSetFieldValue}
							handleDeleteMapping={handleDeleteMapping}
						/>
					)
				})}
			<Button
				variant={BTN.workflows_side_panel}
				leftIcon={<GetIcon icon={Icons.plus} />}
				onClick={handleAddCondition}
			>
				Add field
			</Button>
		</Flex>
	)
}

const isHubspot = (s: string) => {
	return s.toString().toLowerCase().includes('hubspot')
}

const isSalesforce = (s: string) => {
	return s.toString().toLowerCase().includes('salesforce')
}

const isOutreach = (s: string) => {
	return s.toString().toLowerCase().includes('outreach')
}

const isSalesloft = (s: string) => {
	return s.toString().toLowerCase().includes('salesloft')
}

const isApollo = (s: string) => {
	return s.toString().toLowerCase().includes('apollo')
}

const isZoom = (s: string) => {
	return s.toString().toLowerCase().includes('zoom')
}

const isAmplemarket = (s: string) => {
	return s.toString().toLowerCase().includes('amplemarket')
}
