import { Flex } from '@chakra-ui/react'
import { Icons } from '@ds/Icons'
import { cloneDeep } from 'lodash'
import { isEqual } from 'lodash'

import { useAppSelector } from '@/app/hooks'
import {
	useGetSlackChannelsQuery,
	useGetSlackUsersQuery,
} from '@/app/integrations/api/slack'
import { leadAttributesInfo as leadAttributes } from '@/app/leads/helper'
import { useIsSlateRichTextEditor } from '@/common/hooks/feature-flags/useIsSlateRichTextEditor'
import { RichTextEditor } from '@/components/RichTextEditor'
import SlateRichTextEditor from '@/components/SlateRichTextEditor'
import { useGetQuestionsByFormIdQuery } from '@/modules/forms/api/questions'
import {
	createMentionsFromDynamicFields,
	createMentionsFromLeadAttributes,
	createMentionsFromQuestions,
} from '@/modules/forms/utils/text-editor-helpers'
import { MEETING_DYNAMIC_FIELDS } from '@/modules/workflow/constants/meeting-dynamic-fields'
import {
	useCurrentNodeData,
	useUpdateWorkflowNode,
} from '@/modules/workflow/hooks'
import { useGetSlackMessageDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetSlackMessageDrpOptions'
import { useGetSlackRecipientsDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetSlackRecipientsDrpOptions'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import { selectSelectedWorkflowId } from '@/modules/workflow/slice/selectors'
import {
	LabeledRefNodeValue,
	Slack_SendMessage,
} from '@/modules/workflow/types/actions'

import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { DataReferencePicker } from '../../../DataReferencePicker'
import { DEFAULT_SLACK_SEND_MESSAGE_STEP_DETAILS } from './step-detail'

export const SlackSendMessage = () => {
	const updateNode = useUpdateWorkflowNode()
	const selectedNode = useSelectedNode()

	const isSlateRichTextEditorEnabled = useIsSlateRichTextEditor()

	const stepDetails = selectedNode?.data.stepDetails as Slack_SendMessage
	const { getCurrentNodeData } = useCurrentNodeData(selectedNode)

	const selectedWorkflowId = useAppSelector(selectSelectedWorkflowId)
	const { data: questions } = useGetQuestionsByFormIdQuery(
		String(selectedWorkflowId),
		{
			skip: !selectedWorkflowId,
		},
	)

	// Legacy support for recipients
	const { data: slackUsers } = useGetSlackUsersQuery()
	const { data: slackChannels } = useGetSlackChannelsQuery()

	const recipientsValue: { label: string; value: LabeledRefNodeValue }[] = (
		stepDetails?.recipients || []
	).map((r) => {
		let label
		let icon
		let updatedRefNode: LabeledRefNodeValue
		if (!r.label) {
			const channelName = slackChannels?.find((c) => c.id === r.value)?.name
			if (channelName) {
				label = `#${channelName}`
				icon = Icons.hashtag
			} else {
				label = slackUsers?.flat().find((u) => u.id === r.value)
					?.profile?.real_name
				icon = Icons.person
			}

			updatedRefNode = {
				label,
				refNodeId: r.refNodeId,
				variable: r.variable,
				value: r.value,
				icon,
			}
		} else {
			label = r.label
			updatedRefNode = r
		}

		return {
			label,
			value: updatedRefNode,
		}
	})

	const suggestionOptions = [
		...createMentionsFromQuestions(questions),
		...createMentionsFromLeadAttributes(leadAttributes()),
		...createMentionsFromDynamicFields(MEETING_DYNAMIC_FIELDS),
	]

	useGetQuestionsByFormIdQuery(String(selectedWorkflowId), {
		skip: !selectedWorkflowId,
	})

	const getInitialRecipientsDrpOptions = useGetSlackRecipientsDrpOptions()

	const updateNodeStepDetails = (newStepDetails: Slack_SendMessage) => {
		if (!selectedNode) return

		const currentNodeData = getCurrentNodeData()

		updateNode({
			...currentNodeData,
			data: {
				...currentNodeData.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const handleAddRecipient = (selected) => {
		if (!selectedNode) return

		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Slack_SendMessage
		const newStepDetails =
			currentStepDetails || cloneDeep(DEFAULT_SLACK_SEND_MESSAGE_STEP_DETAILS)

		const isRecipientSelected = newStepDetails.recipients.some((r) =>
			isEqual(r, selected),
		)

		if (isRecipientSelected) return

		newStepDetails.recipients.push(selected)

		updateNodeStepDetails(newStepDetails)
	}

	const handleRemoveRecipient = (selected) => {
		if (!selectedNode) return

		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Slack_SendMessage
		const newStepDetails =
			currentStepDetails || cloneDeep(DEFAULT_SLACK_SEND_MESSAGE_STEP_DETAILS)

		newStepDetails.recipients = newStepDetails.recipients.filter((r) => {
			// Legacy support for removing recipients
			if (!r.label) {
				return !isEqual(
					{ refNodeId: r.refNodeId, value: r.value, variable: r.variable },
					{
						refNodeId: selected.refNodeId,
						value: selected.value,
						variable: selected.variable,
					},
				)
			}
			return !isEqual(r, selected)
		})

		updateNodeStepDetails(newStepDetails)
	}

	const handleUpdateMessage = (value: string) => {
		if (!selectedNode) return
		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Slack_SendMessage
		const newStepDetails =
			currentStepDetails || cloneDeep(DEFAULT_SLACK_SEND_MESSAGE_STEP_DETAILS)

		newStepDetails.message = {
			refNodeId: null,
			variable: null,
			value,
		}

		updateNodeStepDetails(newStepDetails)
	}

	return (
		<Flex w="100%" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel
					label="Details"
					description="Receiving users and channels"
				/>
				<DataReferencePicker
					getInitialOptions={getInitialRecipientsDrpOptions}
					initialOption={recipientsValue || []}
					searchPlaceholder="Search for a recipient"
					isMulti
					onSelect={handleAddRecipient}
					removeValue={handleRemoveRecipient}
				/>
			</Flex>

			<Flex w="100%" h="fit-content">
				{isSlateRichTextEditorEnabled ? (
					<SlateRichTextEditor
						key={`slack-message-${selectedNode?.id}`}
						disabledEditingBarButtons={[
							'bulleted-list', // unsupported by mrkdwn
							'numbered-list', // unsupported by mrkdwn
							'blockquote', // supported by mrkdwn, but visually unclear in RichTextEditor; TODO
						]}
						onChange={() => {}}
						onBlur={handleUpdateMessage}
						bodyText={stepDetails?.message?.value || ''}
						Picker={SlackMessageDataReferencePicker}
					/>
				) : (
					<RichTextEditor
						suggestionOptions={suggestionOptions}
						disabledEditingBarButtons={[
							'bulleted-list', // unsupported by mrkdwn
							'numbered-list', // unsupported by mrkdwn
							'blockquote', // supported by mrkdwn, but visually unclear in RichTextEditor; TODO
						]}
						onChange={() => {}}
						onBlur={handleUpdateMessage}
						bodyText={stepDetails?.message?.value || ''}
						hasChanged={false}
						useDrp
					/>
				)}
			</Flex>
		</Flex>
	)
}

const SlackMessageDataReferencePicker = (props) => {
	const getInitialMessageDrpOptions = useGetSlackMessageDrpOptions()

	return (
		<DataReferencePicker
			{...props}
			getInitialOptions={getInitialMessageDrpOptions}
		/>
	)
}
