import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SchedulerEventTable } from '../api/events/type'
import { Queue } from '../types'

export interface SchedulerState {
	selectedEvent: SchedulerEventTable | null
	selectedQueue: Queue | null
}

const initialState: SchedulerState = {
	selectedEvent: null,
	selectedQueue: null,
}

export const schedulerSlice = createSlice({
	name: 'scheduler',
	initialState,
	reducers: {
		updateSelectedEvent: (
			state,
			{ payload }: PayloadAction<SchedulerEventTable | null>,
		) => {
			state.selectedEvent = payload
		},
		selectQueue: (state, { payload }: PayloadAction<Queue | null>) => {
			state.selectedQueue = payload
		},
	},
})

export const schedulerReducer = schedulerSlice.reducer
export const schedulerActions = schedulerSlice.actions
