import { useToast, UseToastOptions } from '@chakra-ui/react'

import ToastBody from './Body'

export interface CustomToastOptions extends Omit<UseToastOptions, 'title'> {
	title: React.ReactNode
}

/**
 * It is custom wrapper. We pre-configured some options for toast here.
 * Ideally, all toasts in our app should have same configurations like position, animation, style
 * but if we must need to pass some other options, we can do that as well.
 */
export const useCustomToast = () => {
	const toast = useToast()
	return ({ title, description, status, ...rest }: CustomToastOptions) => {
		toast({
			position: 'bottom',
			duration: 4000,
			isClosable: true,
			render: ({ id, onClose }) => (
				<ToastBody
					id={id}
					onClose={onClose}
					title={title}
					description={description}
					status={status}
				/>
			),
			...rest,
		})
	}
}
