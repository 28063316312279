import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'
import { setToken } from '@/utils/helpers/token'

import {
	CreateTeamPayload,
	InviteTeamMembersPayload,
	Team,
	UpdateTeamPayload,
} from './type'

export enum TeamEndPoints {
	/** Query */
	getTeam = 'getTeam',
	getTeamsByInvites = 'getTeamsByInvites',

	/** Mutations */
	createTeam = 'createTeam',
	updateTeam = 'updateTeam',
	inviteTeamMembers = 'inviteTeamMembers',
}

const teamsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[TeamEndPoints.getTeam]: builder.query<Team, number | null | undefined>({
			query: (teamId) => ({
				url: `/teams/team/${teamId}`,
				skip: !teamId,
				responseHandler: responseTransformer,
			}),
		}),
		/** Will return all team that a member's email is invited to */
		[TeamEndPoints.getTeamsByInvites]: builder.query<Team[], void>({
			query: () => ({
				url: `/teams/invites`,
				responseHandler: responseTransformer,
			}),
		}),

		/** Mutations */
		[TeamEndPoints.createTeam]: builder.mutation<Team, CreateTeamPayload>({
			query: (payload) => ({
				url: '/teams',
				method: 'POST',
				body: payload,
			}),
			invalidatesTags: ['GET_MEMBER'],
			transformResponse: (response: {
				data: { accessToken: string | null; team: Team }
			}) => {
				const token = response?.data?.accessToken
				const team = response?.data?.team
				if (token) {
					setToken(token)
				}
				return team
			},
		}),
		[TeamEndPoints.updateTeam]: builder.mutation<Team, UpdateTeamPayload>({
			query: (payload) => ({
				url: `/teams/team/${payload.team?.id}`,
				method: 'PATCH',
				body: payload,
				responseHandler: responseTransformer,
			}),
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchTeam = dispatch(
					teamsApi.util.updateQueryData(
						TeamEndPoints.getTeam,
						arg.team.id,
						(draft) => {
							return {
								...draft,
								...arg.team,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchTeam.undo()
				}
			},
		}),
		[TeamEndPoints.inviteTeamMembers]: builder.mutation<
			Team,
			InviteTeamMembersPayload
		>({
			query: (payload) => ({
				url: `/teams/team/${payload.team?.id}/invites`,
				method: 'PATCH',
				body: payload,
				responseHandler: responseTransformer,
			}),
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchTeam = dispatch(
					teamsApi.util.updateQueryData(
						TeamEndPoints.getTeam,
						arg.team.id,
						(draft) => {
							return {
								...draft,
								...arg.team,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchTeam.undo()
				}
			},
		}),
	}),
})

export const {
	useGetTeamQuery,
	useGetTeamsByInvitesQuery,
	useCreateTeamMutation,
	useUpdateTeamMutation,
	useInviteTeamMembersMutation,
} = teamsApi

export { teamsApi }
