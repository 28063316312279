import { Flex } from '@chakra-ui/react'
import { ColorTokens } from '@ds/tokens/colors'

import { useAppSelector } from '@/app/hooks'
import { SHADOWS } from '@/styles/constants'
import { HIDE_SCROLLBAR_CLASS } from '@/utils/constants'

import { useStoreCurrentCanvasNodes } from '../hooks'
import { useDroppableBranch, useSelectedNode } from '../hooks/workflow'
import { SetPanelContent } from '../presenters/SetPanelContent'
import {
	selectIsSidePanelContentOpen,
	selectSelectedSidePanelNavigationId,
} from '../slice/selectors'

export const SidePanelContentPartial = () => {
	const selectedNode = useSelectedNode()
	const droppableBranch = useDroppableBranch()
	const selectedNavId = useAppSelector(selectSelectedSidePanelNavigationId)
	const isPanelContentOpen = useAppSelector(selectIsSidePanelContentOpen)

	useStoreCurrentCanvasNodes()

	return (
		<Flex
			display={isPanelContentOpen ? 'flex' : 'none'}
			direction="column"
			bg={ColorTokens.bg_card}
			w="380px"
			h="calc(100vh - 152px)"
			borderWidth="medium"
			borderRadius="12px"
			borderColor={ColorTokens.border_primary}
			boxShadow={SHADOWS.elevated}
			userSelect="none"
			overflowY="auto"
			overflowX="hidden"
			px={5}
			className={HIDE_SCROLLBAR_CLASS}
		>
			<Flex direction="column" width="100%">
				<SetPanelContent
					selectedNode={selectedNode}
					droppableBranch={droppableBranch}
					menuId={selectedNavId}
				/>
			</Flex>
		</Flex>
	)
}
