import type { ComponentStyleConfig } from '@chakra-ui/react'

import { ColorTokens } from '../../../tokens/colors'
import {
	FONT_SIZES,
	LETTER_SPACING,
	TextVariants,
	WEIGHT,
} from '../../../tokens/typography'

const Text: ComponentStyleConfig = {
	baseStyle: {
		fontWeight: WEIGHT.medium,
	},
	variants: {
		[TextVariants.hyperlink]: {
			fontSize: FONT_SIZES['xs'],
			fontWeight: WEIGHT.medium,
			color: ColorTokens.accent,
			_hover: { cursor: 'pointer' },
		},
		[TextVariants.regular24]: {
			fontSize: FONT_SIZES['2xl'],
			fontWeight: WEIGHT.regular,
		},
		[TextVariants.regular20]: {
			fontSize: FONT_SIZES['xl'],
			fontWeight: WEIGHT.regular,
		},
		[TextVariants.regular18]: {
			fontSize: FONT_SIZES['lg'],
			fontWeight: WEIGHT.regular,
		},
		[TextVariants.regular16]: {
			fontSize: FONT_SIZES['md'],
			fontWeight: WEIGHT.regular,
		},
		[TextVariants.regular14]: {
			fontSize: FONT_SIZES['sm'],
			fontWeight: WEIGHT.regular,
		},
		[TextVariants.regular12]: {
			fontSize: FONT_SIZES['xs'],
			fontWeight: WEIGHT.regular,
		},
		// medium
		[TextVariants.medium24]: {
			fontSize: FONT_SIZES['2xl'],
			fontWeight: WEIGHT.medium,
		},
		[TextVariants.medium20]: {
			fontSize: FONT_SIZES['xl'],
			fontWeight: WEIGHT.medium,
		},
		[TextVariants.medium18]: {
			fontSize: FONT_SIZES['lg'],
			fontWeight: WEIGHT.medium,
		},
		[TextVariants.medium16]: {
			fontSize: FONT_SIZES['md'],
			fontWeight: WEIGHT.medium,
		},
		[TextVariants.medium14]: {
			fontSize: FONT_SIZES['sm'],
			fontWeight: WEIGHT.medium,
		},
		[TextVariants.medium12]: {
			fontSize: FONT_SIZES['xs'],
			fontWeight: WEIGHT.medium,
		},
		// 	semibold
		[TextVariants.semibold24]: {
			fontSize: FONT_SIZES['2xl'],
			fontWeight: WEIGHT.semibold,
		},
		[TextVariants.semibold20]: {
			fontSize: FONT_SIZES['xl'],
			fontWeight: WEIGHT.semibold,
		},
		[TextVariants.semibold18]: {
			fontSize: FONT_SIZES['lg'],
			fontWeight: WEIGHT.semibold,
		},
		[TextVariants.semibold16]: {
			fontSize: FONT_SIZES['md'],
			fontWeight: WEIGHT.semibold,
		},
		[TextVariants.semibold14]: {
			fontSize: FONT_SIZES['sm'],
			fontWeight: WEIGHT.semibold,
		},
		[TextVariants.semibold12]: {
			fontSize: FONT_SIZES['xs'],
			fontWeight: WEIGHT.semibold,
		},
		// bold
		[TextVariants.bold24]: {
			fontSize: FONT_SIZES['2xl'],
			fontWeight: WEIGHT.bold,
		},
		[TextVariants.bold20]: {
			fontSize: FONT_SIZES['xl'],
			fontWeight: WEIGHT.bold,
		},
		[TextVariants.bold18]: {
			fontSize: FONT_SIZES['lg'],
			fontWeight: WEIGHT.bold,
		},
		[TextVariants.bold16]: {
			fontSize: FONT_SIZES['md'],
			fontWeight: WEIGHT.bold,
		},
		[TextVariants.bold14]: {
			fontSize: FONT_SIZES['sm'],
			fontWeight: WEIGHT.bold,
		},
		[TextVariants.bold12]: {
			fontSize: FONT_SIZES['xs'],
			fontWeight: WEIGHT.bold,
		},
		// old	------
		[TextVariants.h1]: {
			fontSize: FONT_SIZES['2xl'],
			fontWeight: WEIGHT.semibold,
			letterSpacing: LETTER_SPACING.tighter,
		},
		[TextVariants.h2]: {
			fontSize: FONT_SIZES.lg,
			fontWeight: WEIGHT.semibold,
		},
		[TextVariants.h3]: {
			fontSize: FONT_SIZES.lg,
		},
		[TextVariants.h4]: {
			fontSize: FONT_SIZES.md,
			fontWeight: WEIGHT.semibold,
		},
		[TextVariants.h5]: {
			fontSize: FONT_SIZES.sm,
			color: ColorTokens.text_secondary,
		},
		[TextVariants.h6]: {
			fontSize: FONT_SIZES.xs,
			color: ColorTokens.text_secondary,
		},
		[TextVariants.xs]: {
			fontSize: FONT_SIZES.xs,
		},
		[TextVariants.sm]: {
			fontSize: FONT_SIZES.sm,
		},
		[TextVariants.md]: {
			fontSize: FONT_SIZES.md,
		},
		[TextVariants.lg]: {
			fontSize: FONT_SIZES.lg,
		},
		[TextVariants.xl]: {
			fontSize: FONT_SIZES.xl,
		},
		[TextVariants.input_label]: {
			fontSize: FONT_SIZES.xs,
			color: ColorTokens.text_secondary,
			letterSpacing: LETTER_SPACING.normal,
			marginLeft: 1,
			marginBottom: 1.5,
		},
		[TextVariants.table_header]: {
			fontSize: FONT_SIZES.xs,
			fontWeight: WEIGHT.medium,
			color: ColorTokens.text_primary,
		},
		[TextVariants.cell_value]: {
			fontSize: FONT_SIZES.sm,
			color: ColorTokens.text_primary,
			fontWeight: WEIGHT.regular,
		},
		[TextVariants.cell_value_md]: {
			fontSize: FONT_SIZES.sm,
			color: ColorTokens.text_primary,
			fontWeight: WEIGHT.medium,
		},
	},
}

export default Text
