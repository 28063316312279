import { Button } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { Borders } from '@/styles/borders'

type Props = {
	onClick: () => void
	icon: Icons
}

export const ActionButton = ({ icon, onClick }: Props) => {
	return (
		<Button
			bg="white"
			w="32px"
			h="32px"
			color={COLORS.background[2]}
			borderRadius="6px"
			border={Borders.primary}
			_hover={{
				bg: ColorTokens.bg_hover,
			}}
			onClick={(e) => {
				e.stopPropagation()
				onClick()
			}}
		>
			<GetIcon icon={icon} />
		</Button>
	)
}
