import { WorkflowNode } from '@/modules/workflow/types/nodes'
import { Salesforce_ConvertLead } from '@/modules/workflow/types/salesforce'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataTag } from '../MetaDataTag'
import { MetaDataTitle } from '../MetaDataTitle'
import { MetaDataWarningTag } from '../MetaDataWarningTag'

export const SalesforceConvertLeadDetails = ({
	data,
}: {
	data: WorkflowNode
}) => {
	const stepDetails = data?.stepDetails as Salesforce_ConvertLead

	const leadRecord = stepDetails?.leadId?.label

	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="Lead:" />
			{leadRecord ? (
				<MetaDataTag label={leadRecord} />
			) : (
				<MetaDataWarningTag warning="Select an option" />
			)}
		</MetaDataRowLayout>
	)
}
