// @ts-nocheck
import { Box, Button, GridItem } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { CalendarDate, isSameDay, isSameMonth } from '@internationalized/date'
import { useCalendarCell } from '@react-aria/calendar'
import { RangeCalendarState } from '@react-stately/calendar'
import { createRef } from 'react'
import { CalendarState } from 'react-stately'

export const CalendarCell = ({
	state,
	date,
	currentMonth,
}: {
	state: CalendarState
	date: CalendarDate
	currentMonth: CalendarDate
}) => {
	const ref = createRef<HTMLButtonElement>()
	const { cellProps, buttonProps, isSelected } = useCalendarCell(
		{ date },
		state,
		ref,
	)

	const isCellUnavailable = state.isCellUnavailable(date)
	const isInThisMonth = isSameMonth(currentMonth, date)
	const disabled = isCellUnavailable || !isInThisMonth
	const accent = ColorTokens.accent

	return (
		<GridItem
			{...cellProps}
			width={10}
			minW={10}
			h={10}
			bg={isSelected ? accent : 'none'}
			color={
				!isInThisMonth
					? COLORS.background[6]
					: isSelected
						? ColorTokens.white
						: ColorTokens.text
			}
			display="flex"
			justifyContent="center"
			alignItems="center"
			borderRadius="full"
			_hover={{
				cursor: 'pointer',
				color: isSelected && !disabled ? 'white' : accent,
			}}
			{...buttonProps}
			fontSize="14px"
			fontWeight={600}
		>
			{date.day}
		</GridItem>
	)
}

export const RangeCalendarCell = ({
	state,
	date,
	currentMonth,
}: {
	state: RangeCalendarState
	date: CalendarDate
	currentMonth: CalendarDate
}) => {
	const ref = createRef<HTMLButtonElement>()
	const { cellProps, buttonProps, isSelected, isInvalid, formattedDate } =
		useCalendarCell({ date }, state, ref)

	const isOutsideMonth = !isSameMonth(currentMonth, date)

	const accent = '#6363F2'
	const lightAccent = '#F5F5FF'

	const isStart = state.highlightedRange
		? isSameDay(date, state.highlightedRange.start)
		: isSelected
	const isEnd = state.highlightedRange
		? isSameDay(date, state.highlightedRange.end)
		: isSelected

	return (
		<Box h={10} w="40px" px={0} as="td" mx={0} {...cellProps}>
			<Box
				textAlign="center"
				/* bg={
					isStart || isEnd
						? 'transparent'
						: isSelected
						? '#999' //'#F5F5FF'
						: 'transparent'
				} */
				boxSize={10}
				borderRadius={
					isStart || isEnd ? '0px' : isSelected ? '#F5F5FF' : 'transparent'
				}
				display="flex"
				justifyContent="center"
				alignItems="center"
				position="relative"
			>
				<Box
					zIndex={2}
					position="absolute"
					top={0}
					left={0}
					h={10}
					w={5}
					bg={
						isStart ? 'transparent' : isSelected ? lightAccent : 'transparent'
					}
				/>
				<Box
					zIndex={2}
					position="absolute"
					top={0}
					right={0}
					h={10}
					w={5}
					bg={isEnd ? 'transparent' : isSelected ? lightAccent : 'transparent'}
				/>
				<Button
					{...buttonProps}
					position="absolute"
					zIndex={3}
					ref={ref}
					hidden={isOutsideMonth}
					size="sm"
					colorScheme={isInvalid ? 'red' : 'blue'}
					color={
						isSelected ? (isStart || isEnd ? 'white' : '#222B48') : '#222B48'
					}
					bg={
						isSelected
							? isStart || isEnd
								? accent
								: 'transparent'
							: 'transparent'
					}
					borderRadius="full"
					transition="background .2s ease-out, color 0.1"
					width={10}
					minW={10}
					h={10}
					px={0}
					_hover={{
						color: isStart || isEnd ? 'white' : accent,
					}}
					/* _focus={{
						outlineColor: accent,
						boxShadow: 'none',
					}} */
					boxShadow={
						isStart && isSelected
							? '0px 0px 0px 0px #F5F5FF'
							: isEnd && isSelected
								? '-0px 0px 0px 0px #F5F5FF'
								: ''
					}
					_active={{ bg: accent }}
				>
					{formattedDate}
				</Button>
			</Box>
		</Box>
	)
}
