import Meet from '@/assets/logo/google-meet.png'
import Teams from '@/assets/logo/ms-teams.png'
import Zoom from '@/assets/logo/zoom.png'
import { QueueMember } from '@/modules/queues/types'

import { TimeSlot } from '../../types'

export const LocationsMap: { [id: string]: { label: string; icon: string } } = {
	'google-meet': {
		label: 'Google Meet',
		icon: Meet,
	},
	zoom: {
		label: 'Zoom',
		icon: Zoom,
	},
	'ms-teams': {
		label: 'Microsoft Teams',
		icon: Teams,
	},
}
/** Range of JavaScript Dates */
export interface DateRange {
	start: Date
	end: Date
}

export type MeetingStatus =
	| 'confirmed'
	| 'rescheduled'
	| 'no-show'
	| 'cancelled'
	| null

export interface SimpleMember {
	member_id: string
	email: string
	name: string
	avatar?: string | null
	personal_room_link?: string | null
}

export interface Queue {
	id: number
	name: string
	queues_members: Array<QueueMember>
	type: 'roundrobin' | 'other'
	team_id: number
	maximize_by: 'fairness' | 'availability'
	updated_at: string
	active: boolean
}

export interface SupabaseQueue {
	id: number
	name: string
	type: string
	team_id: number
}

export interface Availability {
	active_time: Array<TimeSlot> | null
	pre_meeting_buffer: number | null
	post_meeting_buffer: number | null
	personal_room_link: string | null
	notice_period: number | null
}

export enum CalendarProviders {
	iCloud = 'iCloud',
	Google = 'gmail',
	Office365 = 'office365',
	Exchange = 'exchange',
	IMAP = 'IMAP',
}

export enum QueueTypes {
	availability = 'availability',
	fairness = 'fairness',
	group = 'group',
}
