import { Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import { SingleValue } from 'react-select'

import { useAppSelector } from '@/app/hooks'
import {
	WorkflowSelect,
	WorkflowSelectOption,
} from '@/modules/workflow/components/WorkflowSelect'
import {
	useSelectedWorkflow,
	useUpdateWorkflowNode,
	useUpdateWorkflowNodes,
} from '@/modules/workflow/hooks'
import { selectCurrentCanvasNodes } from '@/modules/workflow/slice/selectors'
import {
	WorkflowIntegrationIds as IntegrationIds,
	WorkflowIntegrationsMap as Integrations,
} from '@/modules/workflow/utils/mappings'
import { integrationCategoryBackground } from '@/styles/colors'

interface IntegrationSelectProps {
	selectedNode: any
}

export const IntegrationSelect = ({ selectedNode }: IntegrationSelectProps) => {
	const integrationId = selectedNode.data.integrationId
	const { selectedWorkflow } = useSelectedWorkflow()
	const formType = selectedWorkflow?.type

	const INTEGRATION_OPTIONS: WorkflowSelectOption[] = Object.keys(Integrations)
		.filter((integration) => {
			const validFormTypes = Integrations[integration].validFormTypes
			return validFormTypes ? validFormTypes.includes(formType) : true
		})
		.map((integrationKey) => ({
			label: Integrations[integrationKey].integrationAction,
			value: integrationKey,
			subLabel: Integrations[integrationKey].integrationName,
			logo: Integrations[integrationKey].isIcon
				? null
				: Integrations[integrationKey].logo,
			icon: Integrations[integrationKey].isIcon
				? Integrations[integrationKey].logo
				: null,

			integrationName: Integrations[integrationKey].integrationName,
			iconBg:
				integrationCategoryBackground[
					Integrations[integrationKey].integrationCategory
				],
			enabled: true,
		}))

	const selectedOption = INTEGRATION_OPTIONS.find(
		(int) => int.value === integrationId,
	)

	const updateNode = useUpdateWorkflowNode()
	const updateNodes = useUpdateWorkflowNodes()
	const canvasNodes = useAppSelector(selectCurrentCanvasNodes)

	const removeConnections = () => {
		const childrenIds = selectedNode.data.children

		const newNodes = cloneDeep(canvasNodes)

		for (const childId of childrenIds) {
			const child = newNodes[childId]

			if (child) {
				child.data.parentIds = []
			}
		}

		updateNodes(Object.values(newNodes))
	}

	const handleOptionChange = (op: SingleValue<WorkflowSelectOption>) => {
		if (!op) return

		const integrationKey = op.value

		if (integrationKey === selectedNode.data.integrationId) return

		removeConnections()

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				editableName: Integrations[integrationKey].integrationAction,
				integrationId: integrationKey as IntegrationIds,
				stepType: Integrations[integrationKey].stepType,
				stepDetails: Integrations[integrationKey].stepDetails,
				children: [],
				nextStep: null,
			},
		})
	}

	return (
		<Flex mb={3} w="full">
			<WorkflowSelect
				value={selectedOption}
				options={INTEGRATION_OPTIONS}
				onChange={handleOptionChange}
			/>
		</Flex>
	)
}
