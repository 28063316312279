export enum SchedulerTranslationsTokens {
	confirm = 'confirm',
	reschedule_meeting = 'reschedule_meeting',
	cancel_meeting = 'cancel_meeting',
	confirm_meeting_header = 'confirm_meeting_header',
	confirm_meeting_description = 'confirm_meeting_description',
	confirm_meeting_has_assigned = 'confirm_meeting_has_assigned',
	confirm_meeting_invite_teammates = 'confirm_meeting_invite_teammates',
	confirm_meeting_send_invites = 'confirm_meeting_send_invites',
	confirm_meeting_email_error = 'confirm_meeting_email_error',
	confirm_meeting_error_sending_invites = 'confirm_meeting_error_sending_invites',
	cancel_meeting_reason = 'cancel_meeting_reason',
	cancel_meeting_reason_details = 'cancel_meeting_reason_details',

	cancel_meeting_header = 'cancel_meeting_header',
	cancel_meeting_description = 'cancel_meeting_description',
	cancel_by_mistake = 'cancel_by_mistake',
	cancel_reschedule_msg = 'cancel_reschedule_msg',

	rescheduling_header = 'rescheduling_header',
	former_time = 'former_time',
	keep_original_time = 'keep_original_time',

	powered_by = 'powered_by',

	no_event = 'no_event',
	no_event_active = 'no_event_active',
	no_member = 'no_member',
	no_queue = 'no_queue',
	no_queue_active = 'no_queue_active',
	no_meeting = 'no_meeting',
	no_time_slots = 'no_time_slots',

	minutes_meeting = 'minutes_meeting',

	weekdays_short = 'weekdays_short',
	time_slots = 'time_slots',

	morning = 'morning',
	daytime = 'daytime',
	evening = 'evening',

	timeslot_loading = 'timeslot_loading',
	timer_warning = 'timer_warning',
}
