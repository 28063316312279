import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Flex,
	HStack,
	Text,
} from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

import { ActionButton } from '../components/ActionButton'

type Props = {
	isExpanded: boolean
	children: React.ReactNode
	title: string
	onEdit: () => void
	edit?: boolean
}

export const WebhookConfigModalAccordionLayout = ({
	children,
	isExpanded,
	title,
	onEdit,
	edit,
}: Props) => {
	return (
		<AccordionItem
			borderTop="none"
			border={Borders.primary}
			bg={COLORS.background[12]}
			borderRadius="6px"
			mb={4}
			style={{
				borderColor: isExpanded ? COLORS.purple[9] : COLORS.background[10],
			}}
		>
			<AccordionButton
				outline="none"
				boxShadow="none"
				_focus={{
					boxShadow: 'none',
				}}
				_hover={{
					bg: 'none',
				}}
				_expanded={{
					color: COLORS.purple[9],
				}}
			>
				<HStack w="full" justifyContent="space-between">
					<HStack>
						<AccordionIcon />
						<Text variant={TEXT_ENUM.semibold14}>{title}</Text>
					</HStack>

					{edit ? (
						<Flex
							alignItems="center"
							borderRadius="6px"
							border={Borders.primary}
							borderColor={COLORS.orange[4]}
							bg={COLORS.orange[2]}
							px={2}
							py={1}
							gap={1}
						>
							<GetIcon icon={Icons.edit} color={COLORS.orange[9]} />
							<Text variant={TEXT_ENUM.medium12} color={COLORS.orange[9]}>
								Edit Mode
							</Text>
						</Flex>
					) : (
						<ActionButton icon={Icons.edit} onClick={onEdit} />
					)}
				</HStack>
			</AccordionButton>
			<AccordionPanel>{children}</AccordionPanel>
		</AccordionItem>
	)
}
