import {
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'
import { useDispatch } from 'react-redux'
import { GetIcon, Icons } from 'ui'

import { workflowsActions } from '@/modules/workflow/slice'
import {
	ConditionBlock as ConditionBlockType,
	ConditionBranch,
	DefaultIfElseCondition,
} from '@/modules/workflow/types/logic'
import { CanvasNode } from '@/modules/workflow/types/nodes'
import { Radii } from '@/styles/borders'
import { BTN } from '@/styles/components/button'
import { TEXT_ENUM } from '@/styles/components/text'

import { PanelCloseButton } from '../../../../panel-variants/PanelCloseButton'
import { ConditionComponents } from './Condition'
import { ConditionBlock } from './ConditionBlock'

interface ConditionalModalProps {
	isOpen: boolean
	branchIndex: number
	selectedNode: CanvasNode | null
	conditionComponents: ConditionComponents
	conditionalObject: { branches: ConditionBranch[] }
	updateNode: (value: any) => void
	handleOpenConfirmation: () => void
	onClose: () => void
}

export const ConditionalModal = ({
	isOpen,
	branchIndex,
	selectedNode,
	conditionComponents,
	conditionalObject,
	updateNode,
	handleOpenConfirmation,
	onClose,
}: ConditionalModalProps) => {
	const dispatch = useDispatch()

	const conditionBlocks =
		conditionalObject.branches[branchIndex]?.conditionBlocks

	const handleAddConditionBlock = ({ addGroup }: { addGroup: boolean }) => {
		if (!selectedNode) return
		const newConditionalObject = cloneDeep(conditionalObject)

		const conditions = [
			{
				...DefaultIfElseCondition,
				id: nanoid(12),
			},
		]

		if (addGroup) {
			conditions.push({
				...DefaultIfElseCondition,
				id: nanoid(12),
			})
		}

		const newConditionBlock: ConditionBlockType = {
			id: nanoid(12),
			conditions,
		}

		newConditionalObject.branches[branchIndex].conditionBlocks = [
			...newConditionalObject.branches[branchIndex].conditionBlocks,
			newConditionBlock,
		]

		dispatch(workflowsActions.setConditionalObject(newConditionalObject))
		updateNode(newConditionalObject)
	}

	const handleAddCondition = (blockIndex: number) => {
		if (!selectedNode) return

		const newConditionalObject = cloneDeep(conditionalObject)

		newConditionalObject.branches[branchIndex].conditionBlocks[
			blockIndex
		].conditions.push({
			...DefaultIfElseCondition,
			id: nanoid(12),
		})

		dispatch(workflowsActions.setConditionalObject(newConditionalObject))
		updateNode(newConditionalObject)
	}

	const handleDeleteConditionBlock = (blockIndex: number) => {
		if (!selectedNode) return

		const newConditionalObject = cloneDeep(conditionalObject)

		newConditionalObject.branches[branchIndex].conditionBlocks.splice(
			blockIndex,
			1,
		)

		dispatch(workflowsActions.setConditionalObject(newConditionalObject))
		updateNode(newConditionalObject)
	}

	const handleDeleteCondition = (
		blockIndex: number,
		conditionIndex: number,
	) => {
		if (!selectedNode) return
		const newConditionalObject = cloneDeep(conditionalObject)

		newConditionalObject.branches[branchIndex].conditionBlocks[
			blockIndex
		].conditions.splice(conditionIndex, 1)

		dispatch(workflowsActions.setConditionalObject(newConditionalObject))
		updateNode(newConditionalObject)
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent borderRadius="12px" minWidth="820px">
				<ModalHeader justifyContent="space-between" alignItems="center" p={4}>
					<Text variant={TEXT_ENUM.semibold16}>Advanced condition</Text>
					<PanelCloseButton onClick={onClose} />
				</ModalHeader>
				<ModalBody p={4}>
					{conditionBlocks && conditionBlocks.length > 0 ? (
						<Flex direction="column" gap={4}>
							{conditionBlocks.map(
								(conditionBlock: ConditionBlockType, i: number) => {
									return (
										<ConditionBlock
											key={conditionBlock.id}
											branchIndex={0} // because there's only one branch at the moment
											blockIndex={i}
											conditionalObject={conditionalObject}
											conditionComponents={conditionComponents}
											updateNode={updateNode}
											handleAddCondition={() => handleAddCondition(i)}
											handleDeleteConditionBlock={() =>
												handleDeleteConditionBlock(i)
											}
											handleDeleteCondition={(conditionIndex) =>
												handleDeleteCondition(i, conditionIndex)
											}
										/>
									)
								},
							)}
						</Flex>
					) : (
						<Text
							variant={TEXT_ENUM.medium16}
							color={COLORS.gray[9]}
							textAlign="center"
						>
							No conditions found
						</Text>
					)}
				</ModalBody>
				<ModalFooter justifyContent="space-between">
					<Flex gap={2}>
						<Button
							leftIcon={<GetIcon icon={Icons.plus} />}
							variant={BTN.outline}
							borderRadius={Radii.lg}
							py={1.5}
							px={3}
							onClick={() => handleAddConditionBlock({ addGroup: false })}
						>
							Add condition
						</Button>
						<Button
							leftIcon={<GetIcon icon={Icons.plus} />}
							variant={BTN.tertiary}
							borderRadius={Radii.lg}
							_hover={{
								backgroundColor: COLORS.background[11],
							}}
							_active={{
								backgroundColor: COLORS.background[10],
							}}
							onClick={() => handleAddConditionBlock({ addGroup: true })}
						>
							Add group
						</Button>
					</Flex>
					<Button
						variant={BTN.workflows_side_panel}
						height={9}
						onClick={handleOpenConfirmation}
					>
						Remove all conditions
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
