import { Flex, Link as ChakraLink, Text, VStack } from '@chakra-ui/react'
import { GetIcon, Icons } from '@ds/Icons'
import { COLORS } from '@ds/tokens/colors'
import { Link as ReactRouterLink } from 'react-router-dom'

import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

type BaseProps = {
	icon?: Icons
	title: string
	description: string
	linkText?: string
	externalLink?: boolean
}

type CalloutBoxProps =
	| (BaseProps & {
			linkText: string
			externalLink: true
			linkHref: string
			to?: never
	  })
	| (BaseProps & {
			linkText: string
			externalLink?: false
			to: string
			linkHref?: never
	  })
	| (BaseProps & {
			linkText?: never
			externalLink?: never
			to?: never
			linkHref?: never
	  })

export const CalloutBox = ({
	icon,
	title,
	description,
	linkText,
	externalLink,
	to,
	linkHref,
}: CalloutBoxProps) => {
	return (
		<Flex
			w="full"
			p={3}
			pr={4}
			borderRadius="6px"
			border={Borders.primary}
			borderColor={COLORS.purple[4]}
			bg={COLORS.purple[2]}
			color={COLORS.purple[9]}
			alignItems="flex-start"
			gap={2}
			my={5}
		>
			{icon && <GetIcon icon={icon} boxSize={5} />}
			<VStack alignItems="flex-start">
				<Text variant={TEXT_ENUM.bold14}>{title}</Text>
				<Text variant={TEXT_ENUM.medium14} whiteSpace="pre-wrap">
					{description}
				</Text>
				{externalLink && linkText && (
					<ChakraLink
						href={linkHref}
						target="_blank"
						variant={TEXT_ENUM.semibold14}
					>
						<Text variant={TEXT_ENUM.semibold14} textDecoration="underline">
							{linkText}
						</Text>
					</ChakraLink>
				)}
				{!externalLink && linkText && (
					<ReactRouterLink to={to}>
						<Text variant={TEXT_ENUM.semibold14} textDecoration="underline">
							{linkText}
						</Text>
					</ReactRouterLink>
				)}
			</VStack>
		</Flex>
	)
}
