import { nanoid } from 'nanoid/non-secure'

import { Default_DisplayScheduler } from '../types/actions'
import { Hubspot_MatchRecord } from '../types/hubspot'
import { IntegrationId } from '../types/integrations'
import { LogicStepDetails } from '../types/logic'
import {
	CanvasNodes,
	FormWorkflowType,
	RFNodeType,
	StepType,
	WorkflowNode,
	WorkflowNodes,
} from '../types/nodes'
import { Salesforce_MatchRecord } from '../types/salesforce'
import { WorkflowIntegrationIds as IntegrationIds } from './mappings'
import { Default_SFDC_MatchRecord } from './matching'

/** This method converts workflow steps/nodes saved in db to canvas nodes that renders. */
export const workflowNodesToCanvasNodes = (
	workflow?: FormWorkflowType | null,
): CanvasNodes => {
	if (!workflow) {
		return {}
	}
	const canvasNodes: CanvasNodes = {}

	Object.entries(workflow.steps).map((e) => {
		const data: WorkflowNode = e[1]
		const id = e[0]

		canvasNodes[id] = {
			connectable: true,
			draggable: true,
			id: data.id,
			position: { x: data.x, y: data.y },
			type: transformRFNodeType(data.stepType, data.integrationId),
			data: {
				...data,
				children: checkChildrenExistence(data.children, workflow.steps),
				stepType: getStepType(data.integrationId, data.stepType, false),
			},
		}
	})
	return canvasNodes
}

/** This method converts the rendering canvas nodes into workflow saved in db */
export const canvasNodesToWorkflow = (nodes: CanvasNodes): FormWorkflowType => {
	const steps: WorkflowNodes = {}

	let start: string | null = null

	Object.entries(nodes || {}).forEach((e) => {
		const [nodeId, node] = e
		const data = node.data
		const children = checkChildrenExistence(data.children, nodes)

		steps[nodeId] = {
			id: data.id,
			stepType: getStepType(data.integrationId, data.stepType, true),
			integrationId: data.integrationId,
			editableName: data.editableName,
			description: data.description,
			parentIds: data.parentIds,
			children: children,
			stepDetails: data.stepDetails,
			nextStep:
				data.stepType === 'logic' || children.length == 0 ? null : children[0],
			x: Math.round(e[1].position.x),
			y: Math.round(e[1].position.y),
		}

		if (data.parentIds.includes('0')) {
			start = data.id
		}
	})
	return { startNode: start, steps }
}

export const getAllAncestors = (id: string, nodes: CanvasNodes) => {
	const ancestors: string[] = []
	if (nodes[id] != undefined && nodes[id].data != undefined) {
		const parents = nodes[id].data.parentIds
		parents.forEach((parentId) => {
			const ids = getAllAncestors(parentId, nodes)
			ancestors.push(parentId, ...ids)
		})
	}
	return ancestors
}

export const isNodeChild = (id: string, nodes: CanvasNodes) => {
	const parentIds = nodes[id]?.data?.parentIds
	return parentIds && parentIds.length > 0
}

export const createLogicStepDetails = (
	integrationId: IntegrationId,
):
	| LogicStepDetails
	| Default_DisplayScheduler
	| Salesforce_MatchRecord
	| Hubspot_MatchRecord => {
	switch (integrationId) {
		case IntegrationIds.defaultDisplayScheduler:
			return {
				eventId: { refNodeId: null, variable: null, value: null },
				objectId: { refNodeId: null, variable: null, value: null, label: null },
				type: { refNodeId: null, variable: null, value: null },
				resultChildId: null,
				elseChildId: null,
				redirectUrl: null,
				redirectUrlOnMeetingNotBooked: null,
				redirectUrlParams: null,
				redirectUrlOnMeetingNotBookedParams: null,
				redirectUrlDisplay: null,
				language: null,
				delaySeconds: null,
			}

		case IntegrationIds.salesforceMatchRecord:
		case IntegrationIds.hubspotMatchRecord:
			return Default_SFDC_MatchRecord

		default:
			return {
				branches: [
					{
						id: nanoid(4),
						name: 'branch',
						conditionBlocks: [],
						resultChildId: null,
						elseChildId: null,
					},
				],
			}
	}
}

/* Private */
const transformRFNodeType = (type: StepType, id: string | null) => {
	if (
		type === 'logic' ||
		id === IntegrationIds.defaultDisplayScheduler ||
		id === IntegrationIds.salesforceMatchRecord ||
		id === IntegrationIds.hubspotMatchRecord
	) {
		return RFNodeType.logicNode
	} else if (type == StepType.WorkflowStart) {
		return RFNodeType.flowStart
	}
	return RFNodeType.actionNode
}

/** Making sure that all the ids present in children array exist as nodes */
const checkChildrenExistence = (
	children: string[],
	nodes: CanvasNodes | WorkflowNodes,
) => {
	return children.filter((id) => !!nodes[id])
}

const getStepType = (id: string | null, type: StepType, toDB: boolean) => {
	switch (id) {
		case IntegrationIds.defaultDisplayScheduler:
		case IntegrationIds.salesforceMatchRecord:
		case IntegrationIds.hubspotMatchRecord:
			return toDB ? StepType.Action : StepType.Logic
		default:
			return type
	}
}
