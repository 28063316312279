import {
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react'
import { GetIcon, Icons } from '@ds/Icons'
import { Fragment } from 'react/jsx-runtime'

import { TestStepType } from '@/modules/workflow/hooks/useTestSteps'
import { BTN } from '@/styles/components/button'

import { PanelCloseButton } from '../../panel-variants/PanelCloseButton'
import { TestStep } from './TestStep'

interface TestStepModalProps {
	isOpen: boolean
	steps: TestStepType[]
	workflowId: number | undefined
	submissionId: string | null
	onClose: () => void
}

export const TestStepModal = ({
	isOpen,
	steps,
	workflowId,
	submissionId,
	onClose,
}: TestStepModalProps) => {
	const handleViewTestRunLog = () => {
		if (!workflowId || !submissionId) return

		window.open(`/workflows/workflow-log/details/${submissionId}`, '_blank')
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
			<ModalOverlay />
			<ModalContent my="auto" borderRadius="12px" minH="226px" p={5}>
				<PanelCloseButton
					onClick={onClose}
					position="absolute"
					top={3}
					right={3}
				/>
				<Flex direction="column">
					{steps.map((step) => (
						<Fragment key={step.id}>
							<TestStep
								key={step.id}
								step={step.label}
								status={step.status}
								hideIdle
							/>
							{step.id === 'complete' && step.status === 'success' && (
								<Button
									variant={BTN.primary}
									onClick={handleViewTestRunLog}
									mt={4}
								>
									See test results
									<GetIcon
										icon={Icons.arrow_left}
										boxSize={4}
										ml={2}
										transform="rotate(180deg)"
									/>
								</Button>
							)}
						</Fragment>
					))}
				</Flex>
			</ModalContent>
		</Modal>
	)
}
