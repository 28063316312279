import { Icons } from 'ui'

import { Hubspot_MatchRecord } from '@/modules/workflow/types/hubspot'
import { WorkflowNode } from '@/modules/workflow/types/nodes'
import { Salesforce_MatchRecord } from '@/modules/workflow/types/salesforce'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataTag } from '../MetaDataTag'
import { MetaDataTitle } from '../MetaDataTitle'
import { MetaDataWarningTag } from '../MetaDataWarningTag'

export const MatchCriteriaDetails = ({ data }: { data: WorkflowNode }) => {
	const stepDetails = data?.stepDetails as
		| Salesforce_MatchRecord
		| Hubspot_MatchRecord

	const conditions = stepDetails?.matchCriteria?.conditions.length || 0

	const recordType = stepDetails?.type?.value

	return (
		<>
			<MetaDataRowLayout>
				<MetaDataTitle title="Record Type:" />
				{recordType ? (
					<MetaDataTag label={recordType} />
				) : (
					<MetaDataWarningTag warning="Select an option" />
				)}
			</MetaDataRowLayout>
			<MetaDataRowLayout>
				<MetaDataTitle title="Conditions:" />
				{conditions ? (
					<MetaDataTag
						label={`${conditions} ${
							conditions > 1 ? 'conditions' : 'condition'
						}`}
					/>
				) : (
					<MetaDataWarningTag
						warning="Add conditions"
						icon={Icons.alert_triangle}
					/>
				)}
			</MetaDataRowLayout>
		</>
	)
}
