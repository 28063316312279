import { Button, Flex } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { useLocation } from 'react-router-dom'
import { useCopyToClipboard } from 'react-use'
import { GetIcon, Icons } from 'ui'

import { PageNav } from '@/common/components/PageHeader'
import { useCustomToast } from '@/components/Toast'
import { BTN } from '@/styles/components/button'

import { useWorkflowLogDetailsNavSteps } from '../hooks/useWorkflowLogDetailsNavSteps'

type Props = {
	title?: string | null
	workflowId: number
}

export const WorkflowLogDetailsHeader = ({ title, workflowId }: Props) => {
	const [, copy] = useCopyToClipboard()

	const toast = useCustomToast()
	const location = useLocation()

	const steps = useWorkflowLogDetailsNavSteps({
		currentPageName: title || '-',
		workflowId,
	})

	const handleCopyLink = () => {
		copy(`${window.location.origin}${location.pathname}`)
		toast({
			title: 'Link copied to clipboard',
			status: 'success',
			isClosable: true,
		})
	}

	return (
		<PageNav steps={steps} variant="secondary">
			<Flex w="full" justifyContent="flex-end">
				<Button
					variant={BTN.secondary_v2}
					rightIcon={
						<GetIcon
							icon={Icons.share2}
							color={COLORS.background[5]}
							boxSize={5}
						/>
					}
					fontWeight={500}
					onClick={handleCopyLink}
				>
					Share
				</Button>
			</Flex>
		</PageNav>
	)
}
