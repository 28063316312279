import {
	Hubspot_CreateEngagement,
	HubspotEngagements,
} from '@/modules/workflow/types/hubspot'
import { WorkflowNode } from '@/modules/workflow/types/nodes'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataTag } from '../MetaDataTag'
import { MetaDataTitle } from '../MetaDataTitle'
import { MetaDataWarningTag } from '../MetaDataWarningTag'

export const HubspotCreateEngagementDetails = ({
	data,
}: {
	data: WorkflowNode
}) => {
	const stepDetails = data?.stepDetails as Hubspot_CreateEngagement

	const engagementType = Object.keys(HubspotEngagements).find(
		(key) => HubspotEngagements[key] === stepDetails?.type?.value,
	)

	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="Engagement Type:" />
			{engagementType ? (
				<MetaDataTag label={engagementType} />
			) : (
				<MetaDataWarningTag warning="Select an option" />
			)}
		</MetaDataRowLayout>
	)
}
