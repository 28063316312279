import { Button, Flex } from '@chakra-ui/react'
import { ColorTokens } from '@ds/tokens/colors'
import { useState } from 'react'
import { GetIcon, Icons } from 'ui'

import {
	useDeleteActionNode,
	useDeleteLogicNode,
} from '@/modules/workflow/hooks'
import { createLogicStepDetails } from '@/modules/workflow/utils/graph-operations'
import { BTN } from '@/styles/components/button'

import { WarningModal } from './WarningModal'

interface StepDetailsFooterProps {
	selectedNode: any
	handleResetStepDetails: (newDetails: any) => void
}

export const StepDetailsFooter = ({
	selectedNode,
	handleResetStepDetails,
}: StepDetailsFooterProps) => {
	const deleteActionNode = useDeleteActionNode()
	const deleteLogicNode = useDeleteLogicNode()
	const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
	const [isResetModalOpen, setIsResetModalOpen] = useState(false)

	const handleReset = () => {
		let stepDetails: any = null

		if (selectedNode.type === 'logicNode') {
			stepDetails = createLogicStepDetails(selectedNode.data.integrationId)
		}

		handleResetStepDetails(stepDetails)
		setIsResetModalOpen(false)
	}

	const handleRemove = () => {
		if (selectedNode.type === 'actionNode') {
			deleteActionNode(selectedNode.id)
		} else {
			deleteLogicNode(selectedNode.id)
		}

		setIsRemoveModalOpen(false)
	}

	return (
		<>
			<Flex
				position="absolute"
				w="378px"
				bottom="1px"
				ml={-5}
				py={5}
				px={4}
				// using 998 because the dropdown for selects is 999
				zIndex={998}
				borderTop={`1px solid ${ColorTokens.border_primary}`}
				borderRadius="0 0 12px 12px"
				bg={ColorTokens.bg_card}
				justifyContent="flex-end"
			>
				<Button
					leftIcon={<GetIcon icon={Icons.refresh} />}
					variant={BTN.workflows_side_panel}
					mr={2}
					onClick={() => setIsResetModalOpen(true)}
				>
					Reset block
				</Button>
				<Button
					variant={BTN.workflows_side_panel_danger}
					onClick={() => setIsRemoveModalOpen(true)}
				>
					Remove block
				</Button>
			</Flex>
			<WarningModal
				isOpen={isResetModalOpen}
				icon={Icons.warning_circle}
				warningMessage="Are you sure you want to reset this block?"
				warningSubtext="This action will reset all of the configuration currently defined in the block."
				handleConfirm={handleReset}
				handleCancel={() => setIsResetModalOpen(false)}
			/>
			<WarningModal
				isOpen={isRemoveModalOpen}
				icon={Icons.warning_circle}
				warningMessage="Are you sure you want to remove this block?"
				warningSubtext="This action will remove the block from the workflow and erase all of its containing configuration."
				handleConfirm={handleRemove}
				handleCancel={() => setIsRemoveModalOpen(false)}
			/>
		</>
	)
}
