import { useCallback } from 'react'
import { Edge } from 'reactflow'

import { useIsDeprecateParentIdsChildren } from '@/common/hooks/feature-flags/useIsDeprecateParentIdsChildren'
import { CanvasNodes, StepType } from '@/modules/workflow/types/nodes'

import { Default_DisplayScheduler } from '../types/actions'
import { LogicStepDetails } from '../types/logic'
import { Salesforce_MatchRecord } from '../types/salesforce'
import { createEdge } from '../utils/createEdge'
import { FlowStart } from '../utils/FlowStart'

export const useReconstructedEdges = () => {
	const isDeprecateParentIdsChildren = useIsDeprecateParentIdsChildren()

	return useCallback(
		(nodesObj: CanvasNodes): Edge[] => {
			const nodes = [FlowStart, ...Object.values(nodesObj)]
			const recreatedEdges: Edge[] = []

			nodes.forEach((parent) => {
				if (isDeprecateParentIdsChildren) {
					const { stepType, integrationId, id: parentId } = parent.data

					if (
						stepType === StepType.Action ||
						stepType === StepType.WorkflowStart
					) {
						if (parent.data.nextStep) {
							const edge = createEdge({
								parentId,
								childId: parent.data.nextStep,
								stepType,
								integrationId,
							})
							recreatedEdges.push({ ...edge, style: undefined })
						}
					} else if (stepType === StepType.Logic) {
						const stepDetails = parent.data.stepDetails as
							| LogicStepDetails
							| Default_DisplayScheduler
							| Salesforce_MatchRecord

						if ('branches' in stepDetails) {
							// Handle IfElse logic node
							const branch = stepDetails.branches[0]
							if (branch.resultChildId) {
								const edge = createEdge({
									parentId,
									childId: branch.resultChildId,
									stepType,
									sourceHandle: `${parentId}&true`,
									integrationId,
								})
								recreatedEdges.push({ ...edge, style: undefined })
							}
							if (branch.elseChildId) {
								const edge = createEdge({
									parentId,
									childId: branch.elseChildId,
									stepType,
									sourceHandle: `${parentId}&false`,
									integrationId,
								})
								recreatedEdges.push({ ...edge, style: undefined })
							}
						} else {
							// Handle other logic nodes (e.g., DisplayScheduler, MatchRecord)
							if (stepDetails.resultChildId) {
								const edge = createEdge({
									parentId,
									childId: stepDetails.resultChildId,
									stepType,
									sourceHandle: `${parentId}&true`,
									integrationId,
								})
								recreatedEdges.push({ ...edge, style: undefined })
							}
							if (stepDetails.elseChildId) {
								const edge = createEdge({
									parentId,
									childId: stepDetails.elseChildId,
									stepType,
									sourceHandle: `${parentId}&false`,
									integrationId,
								})
								recreatedEdges.push({ ...edge, style: undefined })
							}
						}
					}
				} else {
					const { stepType, integrationId, children } = parent.data
					children.forEach((childId) => {
						nodesObj[childId]?.data.parentIds
							.filter((id) => id.includes(parent.id))
							.forEach((fullParent) => {
								const includesHandle = fullParent.includes('&')
								const parentId = fullParent.split('&')[0]
								const sourceHandle = includesHandle ? fullParent : undefined
								const edge = createEdge({
									parentId,
									childId,
									stepType,
									sourceHandle,
									integrationId,
								})
								if (
									!recreatedEdges.find(
										(recreatedEdge) => recreatedEdge.id === edge.id,
									)
								) {
									recreatedEdges.push({ ...edge, style: undefined })
								}
							})
					})
				}
			})

			return recreatedEdges
		},
		[isDeprecateParentIdsChildren],
	)
}
