import { Button, Flex, HStack, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { Tag } from '@/common/components/Tag'
import { Borders } from '@/styles/borders'
import { BTN } from '@/styles/components/button'
import { TEXT_ENUM } from '@/styles/components/text'

type Props = {
	headers?: Record<string, string> | null
	handleViewMore?: () => void
}

export const WebhookHeaders = ({ headers, handleViewMore }: Props) => {
	if (!headers) return null
	const headersArr = Object.entries(headers)
	const displayedHeaders = headersArr.splice(0, 5)
	const shouldShowMore = headersArr.length > displayedHeaders.length

	return (
		<Flex
			flexDir="column"
			p={3}
			borderRadius="12px"
			border={Borders.primary}
			mt={4}
		>
			<HStack justifyContent="space-between" mb={4}>
				<Text variant={TEXT_ENUM.medium14} color={COLORS.background[6]}>
					Headers
				</Text>
			</HStack>

			<Flex flexDir="column" gap={2}>
				{displayedHeaders.map(([key, value]) => (
					<Flex key={key} justify="space-between">
						<Text variant={TEXT_ENUM.medium14}>{key}</Text>
						<Tag
							label={value}
							borderColor={COLORS.purple[4]}
							bg={COLORS.purple[2]}
							color={COLORS.purple[9]}
						/>
					</Flex>
				))}

				{shouldShowMore && (
					<Flex justifyContent="flex-end">
						<Button
							onClick={handleViewMore}
							variant={BTN.text}
							fontSize="14px"
							fontWeight={500}
							padding={0}
							color={COLORS.purple[9]}
						>
							View more
						</Button>
					</Flex>
				)}
			</Flex>
		</Flex>
	)
}
