import type { ModalProps } from '@chakra-ui/react'
import {
	Modal as ChakraModal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react'

interface Props extends ModalProps {
	isOpen: boolean
	headerText: string
	footerContent: React.ReactNode
	children: React.ReactNode
	onClose: () => void
}

export const Modal = ({
	children,
	isOpen,
	onClose,
	headerText,
	footerContent,
	...props
}: Props) => {
	return (
		<ChakraModal isOpen={isOpen} onClose={onClose} {...props}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{headerText}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>{children}</ModalBody>
				<ModalFooter>{footerContent}</ModalFooter>
			</ModalContent>
		</ChakraModal>
	)
}
