import { HStack, Image, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import React from 'react'
import { components, SingleValueProps } from 'react-select'
import { GetIcon, Icons } from 'ui'

import { DEFAULT_SEARCH_PLACEHOLDER } from '../index'

interface CustomSingleValueProps {
	label: string
	icon: string | null
}

export const SingleValue = React.forwardRef(
	(
		props: CustomSingleValueProps & SingleValueProps<any, boolean, any>,
		ref: React.Ref<HTMLDivElement>,
	) => {
		const { label, icon } = props
		const showSearch = props.selectProps.menuIsOpen

		return (
			<components.SingleValue {...props}>
				<HStack>
					{icon && !showSearch ? (
						Object.values(Icons).includes(icon as Icons) ? (
							<GetIcon
								icon={icon as Icons}
								boxSize={4}
								color={COLORS.gray[12]}
							/>
						) : (
							<Image src={icon} boxSize={4} />
						)
					) : null}
					<Text
						color={showSearch ? COLORS.background[6] : 'inherit'}
						textOverflow="ellipsis"
						whiteSpace="nowrap"
						overflow="hidden"
						ref={ref}
					>
						{showSearch ? DEFAULT_SEARCH_PLACEHOLDER : label}
					</Text>
				</HStack>
			</components.SingleValue>
		)
	},
)

SingleValue.displayName = 'SingleValue'
