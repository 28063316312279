import { Button as ChakraButton } from '@chakra-ui/react'
import { useButton } from '@react-aria/button'
import { createRef } from 'react'

import { BTN } from '@/styles/components/button'

export const CalendarButton = (props: any) => {
	const ref = createRef<HTMLButtonElement>()
	const { buttonProps } = useButton(props, ref)
	return (
		<ChakraButton
			variant={BTN.ghost}
			width={4}
			height={8}
			minHeight={8}
			borderRadius="lg"
			{...buttonProps}
			ref={ref}
			color="blue.950"
		>
			{props.children}
		</ChakraButton>
	)
}
