import { Center, Flex, Text } from '@chakra-ui/react'

import { ReactComponent as WebhookListeningImage } from '@/assets/illustations/webhook-listening.svg'
import { TEXT_ENUM } from '@/styles/components/text'

type Props = {
	setIsListening: (isListening: boolean) => void
}

export const ListeningStep = ({ setIsListening }: Props) => {
	return (
		<Flex flexDir="column" w="full" p={5} minH="400px" h="full">
			<Center w="full" h="full" flexDir="column" textAlign="center">
				<WebhookListeningImage />
				<Text mt={4} variant={TEXT_ENUM.medium14}>
					Listening...
				</Text>
				<Text
					mt={2}
					mr={2}
					variant={TEXT_ENUM.hyperlink}
					cursor="pointer"
					onClick={() => {
						setIsListening(false)
					}}
				>
					Cancel
				</Text>
			</Center>
		</Flex>
	)
}
