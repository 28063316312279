import { Center, CenterProps, Spinner } from '@chakra-ui/react'

type Props = CenterProps

const LoadingContainer: React.FC<Props> = (props) => {
	return (
		<Center h="full" w="full" {...props}>
			<Spinner />
		</Center>
	)
}

export default LoadingContainer
