import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { Integration } from '../type'
import {
	OutreachFieldType,
	OutreachMailboxType,
	OutreachSequenceType,
} from './type'

export enum OutreachEndPoints {
	/** Query */
	getOutreach = 'getOutreach',
	getOutreachSignInUrl = 'getOutreachSignInUrl',
	getOutreachUsers = 'getOutreachUsers',
	getOutreachSequences = 'getOutreachSequences',
	getOutreachProspectsFields = 'useOutreachProspectsFields',
	/** Mutation */
	deleteOutreach = 'deleteOutreach',
	startOutreachSequenceBulk = 'startOutreachSequenceBulk',
}

const outreachApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[OutreachEndPoints.getOutreach]: builder.query<Integration, void>({
			query: () => ({
				url: `/integrations?integration=outreach`,
				responseHandler: responseTransformer,
			}),
		}),
		[OutreachEndPoints.getOutreachSignInUrl]: builder.query<
			string,
			string | void
		>({
			query: (route) => ({
				url: `/outreach/oauth/url?route=${route || window.location.pathname}`,
				responseHandler: (response) => response.json().then((data) => data.url),
			}),
		}),
		[OutreachEndPoints.getOutreachUsers]: builder.query<
			OutreachMailboxType[],
			void
		>({
			query: () => ({
				url: `/outreach/mailboxes`,
			}),
		}),

		[OutreachEndPoints.getOutreachSequences]: builder.query<
			OutreachSequenceType[],
			void
		>({
			query: () => ({
				url: `/outreach/sequences`,
			}),
		}),

		[OutreachEndPoints.getOutreachProspectsFields]: builder.query<
			OutreachFieldType[],
			void
		>({
			query: () => ({
				url: `/outreach/prospect/fields`,
				responseHandler: (response) =>
					response
						.json()
						.then((data) => [{ id: 'emails', label: 'Email' }, ...data]),
			}),
		}),

		/** Mutations */
		[OutreachEndPoints.deleteOutreach]: builder.mutation<
			{
				status: true
				error: any
			},
			void
		>({
			query: () => ({
				url: `/integrations?integration=outreach`,
				method: 'DELETE',
			}),
			onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					outreachApi.util.updateQueryData(
						OutreachEndPoints.getOutreach,
						undefined,
						() => {
							return {
								id: null,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),
		[OutreachEndPoints.startOutreachSequenceBulk]: builder.mutation<
			void,
			{ emails: string[]; sequenceId: number; mailboxId: number }
		>({
			query: ({ emails, sequenceId, mailboxId }) => ({
				url: '/outreach/sequences/bulk',
				method: 'POST',
				body: {
					emails,
					sequenceId,
					mailboxId,
				},
			}),
		}),
	}),
})

export const {
	useDeleteOutreachMutation,
	useGetOutreachQuery,
	useGetOutreachSignInUrlQuery,
	useGetOutreachUsersQuery,
	useLazyGetOutreachUsersQuery,
	useGetOutreachSequencesQuery,
	useUseOutreachProspectsFieldsQuery,
	useStartOutreachSequenceBulkMutation,
} = outreachApi

export { outreachApi }
