import { cloneDeep } from 'lodash'
import { useCallback } from 'react'
import { useStore } from 'react-redux'
import { useDeepCompareEffect } from 'react-use'

import { workflowsActions } from '../slice'
import { CanvasNode } from '../types/nodes'

/** This hook manages the node data stored in the redux store and
 * returns a function to retrieve a copy the current state of the cavnas node. */
export const useCurrentNodeData = (nodeData: CanvasNode | null) => {
	const store = useStore()
	const dispatch = store.dispatch

	useDeepCompareEffect(() => {
		dispatch(workflowsActions.setCurrentNodeData(nodeData))
	}, [nodeData])

	return {
		getCurrentNodeData: useCallback((): CanvasNode => {
			return cloneDeep(store.getState().workflows.currentNodeData || {})
		}, [store]),
	}
}
