import { Default_AddTag } from '@/modules/workflow/types/actions'
import { WorkflowNode } from '@/modules/workflow/types/nodes'

import { TagAssignRow } from '../TagAssignRow'

export const AddTagDetails = ({ data }: { data: WorkflowNode }) => {
	const stepDetails = data?.stepDetails as Default_AddTag

	const tags = stepDetails?.tagId?.value ? [stepDetails.tagId.value] : []

	return <TagAssignRow selectedTagIds={tags} />
}
