import { useCallback } from 'react'
import { Icons } from 'ui'

import { FormType } from '@/modules/forms/types'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { useSelectedWorkflow } from '../../workflow'
import { useGetFormSubmissionDrpOptions } from '../generators/trigger-drp-options/useGetFormSubmissionDrpOptions'
import { useGetHubspotTriggerDrpOptions } from '../generators/trigger-drp-options/useGetHubspotTriggerDrpOptions'
import { useGetSalesforceTriggerDrpOptions } from '../generators/trigger-drp-options/useGetSalesforceTriggerDrpOptions'
import { useGetTagAssignedTriggerDrpOptions } from '../generators/trigger-drp-options/useGetTagAssignedTriggerDrpOptions'
import { useGetWebhookTriggerDrpOptions } from '../generators/trigger-drp-options/useGetWebhookTriggerDrpOptions'
import { useGetLeadDrpOptions } from '../generators/useGetLeadDrpOptions'
import { useGetWorkflowExecutionDataDrpOptions } from '../generators/useGetWorkflowExecutionDataDrpOptions'
import { useGetOwnerDrpOptions } from './useGetOwnerDrpOptions'

export const useGetWorkflowAttrDrpOptions = (): AsyncDrpOptionGenerator => {
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
	const triggerType = selectedWorkflow?.type

	const getFormSubmissionDrpOptions = useGetFormSubmissionDrpOptions()
	const getSalesforceTriggerDrpOptions = useGetSalesforceTriggerDrpOptions()
	const getHubspotTriggerDrpOptions = useGetHubspotTriggerDrpOptions()
	const getTagAssignedTriggerDrpOptions = useGetTagAssignedTriggerDrpOptions()
	const getWebhookTriggerDrpOptions = useGetWebhookTriggerDrpOptions()
	const getOwnerDrpOptions = useGetOwnerDrpOptions()
	const getLeadDrpOptions = useGetLeadDrpOptions()
	const getWorkflowExecutionDataDrpOptions =
		useGetWorkflowExecutionDataDrpOptions()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			let workflowOptions: DataReferencePickerOption[]
			switch (triggerType) {
				case FormType.regular:
				case FormType.imported:
					workflowOptions = await getFormSubmissionDrpOptions({ onSelect })
					workflowOptions.push(...(await getLeadDrpOptions({ onSelect })))
					break
				case FormType.salesforce_object_create_trigger:
				case FormType.salesforce_object_update_trigger:
					workflowOptions = await getSalesforceTriggerDrpOptions({ onSelect })
					break
				case FormType.hubspot_trigger:
					workflowOptions = await getHubspotTriggerDrpOptions({ onSelect })
					break
				case FormType.meeting_booked_trigger:
				case FormType.meeting_cancelled_trigger:
				case FormType.meeting_reassigned_trigger:
				case FormType.meeting_rescheduled_trigger:
				case FormType.meeting_no_show_trigger:
					workflowOptions = await getLeadDrpOptions({ onSelect })
					break
				case FormType.tag_assigned_trigger:
					workflowOptions = await getTagAssignedTriggerDrpOptions({ onSelect })
					break
				case FormType.webhook_trigger:
					workflowOptions = await getWebhookTriggerDrpOptions({ onSelect })
					break
				default:
					workflowOptions = []
					break
			}

			workflowOptions.push(
				...(await getOwnerDrpOptions({ onSelect })),
				...(await getWorkflowExecutionDataDrpOptions({ onSelect })),
			)

			return [
				{
					label: 'Workflow attributes',
					icon: Icons.git_fork,
					value: workflowOptions,
				},
			]
		},
		[
			triggerType,
			getOwnerDrpOptions,
			getLeadDrpOptions,
			getFormSubmissionDrpOptions,
			getSalesforceTriggerDrpOptions,
			getHubspotTriggerDrpOptions,
			getTagAssignedTriggerDrpOptions,
			getWebhookTriggerDrpOptions,
		],
	)
}
