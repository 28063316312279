import { Button, Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import { useMemo, useState } from 'react'
import { GetIcon, Icons } from 'ui'

import { ConditionBranch } from '@/modules/workflow/types/logic'
import { MatchRecord } from '@/modules/workflow/types/matching'
import { CanvasNode } from '@/modules/workflow/types/nodes'
import {
	branchesToPriorityCriteria,
	priorityCriteriaToBranches,
} from '@/modules/workflow/utils/matching'
import { BTN } from '@/styles/components/button'

import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { WarningModal } from '../../WarningModal'
import { ConditionComponents } from '../conditional/Condition'
import { ConditionalModal } from '../conditional/ConditionalModal'
import { ConditionDetails } from '../conditional/ConditionDetails'

interface PriorityCriteriaProps {
	selectedNode: CanvasNode | null
	updateNode: (node: CanvasNode, ignoreSync?: boolean | undefined) => void
	conditionComponents: ConditionComponents
}

export const PriorityCriteria = ({
	selectedNode,
	updateNode,
	conditionComponents,
}: PriorityCriteriaProps) => {
	const [isConditionModalOpen, setIsConditionModalOpen] = useState(false)
	const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)

	const stepDetails = selectedNode?.data.stepDetails as MatchRecord
	const priorityCriteria = stepDetails?.priorityCriteria

	const conditionalObject = useMemo(
		() => priorityCriteriaToBranches(priorityCriteria),
		[priorityCriteria],
	)

	const conditionBlocks = conditionalObject.branches[0]?.conditionBlocks

	const updateNodePriorityCriteria = (conditionalObject: {
		branches: ConditionBranch[]
	}) => {
		if (!selectedNode) return

		const newPriorityCriteria = branchesToPriorityCriteria(
			conditionalObject.branches,
		)

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: {
					...stepDetails,
					priorityCriteria: newPriorityCriteria,
				},
			},
		})
	}

	const handleModalOpen = () => {
		setIsConditionModalOpen(true)
	}

	const handleModalClose = () => {
		setIsConditionModalOpen(false)
	}

	const handleOpenConfirmation = () => {
		setIsConditionModalOpen(false)
		setIsWarningModalOpen(true)
	}

	const handleCancelConfirmation = () => {
		setIsWarningModalOpen(false)
		setIsConditionModalOpen(true)
	}

	const handleRemoveAllConditions = () => {
		if (!selectedNode) return

		const newConditionalObject = cloneDeep(conditionalObject)
		newConditionalObject.branches[0].conditionBlocks = []

		updateNodePriorityCriteria(newConditionalObject)
	}

	const handleRemoveConfirm = () => {
		handleRemoveAllConditions()
		handleCancelConfirmation()
	}

	return (
		<Flex direction="column">
			<PanelInputLabel
				label="Prioritize matched records"
				description="These conditions are evaluated in order to determine a single match if multiple are found."
				tooltipText='Note that "Max" and "Min" comparators may not be used in grouped conditions.'
			/>
			{conditionBlocks?.length > 0 && (
				<ConditionDetails conditionBlocks={conditionBlocks} isMatchCriteria />
			)}
			<Button
				variant={BTN.workflows_side_panel}
				leftIcon={<GetIcon icon={Icons.adjustments} />}
				onClick={handleModalOpen}
			>
				{`${conditionBlocks?.length > 0 ? 'Edit' : 'Add'}`} conditions
			</Button>
			<ConditionalModal
				isOpen={isConditionModalOpen}
				branchIndex={0}
				selectedNode={selectedNode}
				conditionComponents={conditionComponents}
				conditionalObject={conditionalObject}
				handleOpenConfirmation={handleOpenConfirmation}
				updateNode={updateNodePriorityCriteria}
				onClose={handleModalClose}
			/>
			<WarningModal
				isOpen={isWarningModalOpen}
				icon={Icons.trash}
				warningMessage="Do you really want to remove all conditions?"
				warningSubtext="You can’t undo this action and will need to add all conditions from scratch."
				handleConfirm={handleRemoveConfirm}
				handleCancel={handleCancelConfirmation}
			/>
		</Flex>
	)
}
