// @ts-nocheck
import {
	CalendarDate,
	createCalendar,
	getLocalTimeZone,
	getWeeksInMonth,
	now,
	parseAbsolute,
} from '@internationalized/date'
import { useCalendar } from '@react-aria/calendar'
import { FocusableElement } from '@react-types/shared'
import { uniq } from 'lodash'
import React, { DOMAttributes, useEffect, useState } from 'react'
import { useMemo } from 'react'
import { AriaButtonProps } from 'react-aria'
import { CalendarState, useCalendarState } from 'react-stately'
import { initializeDateRange } from 'shared-utils'

import { StylesType } from '../../styles'
import { CalendarProps } from '.'
import { DateRange } from './types'

interface CalendarContextType {
	calendarState: CalendarState
	title: string
	prevButtonProps: AriaButtonProps<'button'>
	nextButtonProps: AriaButtonProps<'button'>
	calendarProps: DOMAttributes<FocusableElement>
	weeks: CalendarDate[][]
	startDate: CalendarDate
	activeWeekIndex: number
	setActiveWeekIndex: React.Dispatch<React.SetStateAction<number>>
	onClose: () => void
	activeWeek: CalendarDate[]
	timeslot: string
	setTimeslot: React.Dispatch<React.SetStateAction<string>>
	handleNextWeek: () => void
	handlePrevWeek: () => void

	// Receieved as Props from Calendar
	styles: StylesType
	availableTimes: string[]
	isScheduling: boolean
	handleScheduleMeeting?: (timestamp: string) => void
	timezone: string
	setTimezone?: (timezone: string) => void
	duration?: number
	locale: string
	isAvailabilityLoading?: boolean
	errorSlots?: string[]
}

const CalendarContext = React.createContext<CalendarContextType>(null)

interface Props extends CalendarProps {
	children: React.ReactNode
}

export const CalendarProvider: React.FC<Props> = (props) => {
	const [loadedRange, setLoadedRange] = useState<DateRange>(
		props.range || { ...initializeDateRange() },
	)

	const availableSlotsInPresent =
		useMemo(() => {
			const currentDateTime = now(props.timezone)
			return props.availableTimes.filter((at) => {
				const availableDateTime = parseAbsolute(at, props.timezone)
				if (availableDateTime.compare(currentDateTime) < 0) return false
				return true
			})
		}, [props.availableTimes, props.timezone]) || []

	const firstAvailableSlot = availableSlotsInPresent.find((d) => Boolean(d))
	const dateStrings = useTimeToDate(availableSlotsInPresent, props.timezone)

	const state = useCalendarState({
		locale: 'en-US',
		createCalendar,
		visibleDuration: { months: 1 },
		defaultValue: firstAvailableSlot
			? parseAbsolute(firstAvailableSlot, props.timezone)
			: now(props.timezone),
		isDateUnavailable: (d) => {
			const date = d.toDate(props.timezone)
			return isDateNotAvailable(dateStrings, date)
		},
	})

	useEffect(() => {
		if (
			firstAvailableSlot &&
			new Date(firstAvailableSlot).setHours(0, 0, 0, 0) >
				state.value.toDate().getTime()
		) {
			state.setValue(parseAbsolute(firstAvailableSlot, props.timezone))
		}
	}, [firstAvailableSlot, state, props.timezone])

	const { calendarProps, prevButtonProps, nextButtonProps, title } =
		useCalendar({}, state)

	const startDate = state.visibleRange.start

	const weeksInMonth = getWeeksInMonth(state.visibleRange.start, props.locale)

	const weeks = [...new Array(weeksInMonth).keys()].map((weekIndex) =>
		state.getDatesInWeek(weekIndex, startDate).map((date) => {
			if (date !== null) {
				return date
			}
		}),
	)

	const [activeWeekIndex, setActiveWeekIndex] = useState(0)
	const [timeslot, setTimeslot] = useState('')

	const activeWeek =
		state.getDatesInWeek(activeWeekIndex, state.value) || weeks[activeWeekIndex]

	useEffect(() => {
		const start = state.visibleRange.start.toDate(getLocalTimeZone()).getTime()

		const end = state.visibleRange.end.toDate(getLocalTimeZone()).getTime()

		if (start > loadedRange.start || end > loadedRange.end) {
			const newStart = props.range.end
			const newEnd = props.range.end + getMillisecondsOfDaya(30) // fetch next 30 days available times

			if (props?.setRange) {
				props.setRange({ start: newStart, end: newEnd })
			}
			setLoadedRange({
				start: Math.max(start, loadedRange.start),
				end: Math.max(end, loadedRange.end),
			})
		}
	}, [state.visibleRange])

	const handleOnClose = () => {
		/** Reset focused state on popover close */
		const date = state.value
		state.setFocusedDate(date)
	}

	const handleNextWeek = () => {
		setActiveWeekIndex((prev) => prev + 1)
		/** Prefetch 1 week slots */
		props.setRange({
			start: loadedRange.end,
			end: loadedRange.end + getMillisecondsOfDaya(7),
		})
		setLoadedRange({
			start: loadedRange.end,
			end: loadedRange.end + getMillisecondsOfDaya(7),
		})
	}

	const handlePrevWeek = () => {
		setActiveWeekIndex((prev) => prev - 1)
	}

	return (
		<CalendarContext.Provider
			value={{
				calendarState: state,
				title,
				nextButtonProps,
				prevButtonProps,
				calendarProps,
				weeks: weeks || [],
				startDate,
				activeWeekIndex,
				setActiveWeekIndex,
				onClose: handleOnClose,
				activeWeek,
				timeslot: timeslot,
				setTimeslot: setTimeslot,

				handleScheduleMeeting: props.handleScheduleMeeting,
				availableTimes: availableSlotsInPresent,
				styles: props.styles,
				timezone: props.timezone || getLocalTimeZone(),
				setTimezone: props.setTimezone,
				isScheduling: props.isScheduling,
				duration: props.duration,
				handleNextWeek,
				handlePrevWeek,
				locale: props.locale,
				isAvailabilityLoading: props.isAvailabilityLoading,
				errorSlots: props.errorSlots,
			}}
		>
			{props.children}
		</CalendarContext.Provider>
	)
}

export const useCalendarContext = () => {
	const context = React.useContext(CalendarContext)
	if (context === undefined) {
		throw new Error('useCalendarContext must be used within a CalendarProvider')
	}

	return context
}

export const useTimeToDate = (availableTimes: string[], timezone: string) => {
	return useMemo(() => {
		const dateStrings = availableTimes.map((at) => {
			const atd = parseAbsolute(at, timezone).toDate()
			return `${atd.getMonth() + 1}-${atd.getDate()}-${atd.getFullYear()}`
		})

		return uniq(dateStrings)
	}, [availableTimes, timezone])
}

/**
 * It checks only date not time
 * e.g 31-12-2021 === 31-12-2021
 */
const isDateNotAvailable = (availableTimes: string[], date: Date) => {
	return !availableTimes.includes(
		`${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
	)
}

export const getMillisecondsOfDaya = (day: number) => {
	return day * 1000 * 60 * 60 * 24
}
