import { Box, Flex, Text } from '@chakra-ui/react'
import { GetIcon, Icons } from '@ds/Icons'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'

import {
	Expandable,
	ExpandableContent,
	ExpandableTrigger,
} from '@/components/Expandable'
import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'
import { HIDE_SCROLLBAR_CLASS } from '@/utils/constants'

import { formatJsonLog } from '../presenters/formatJsonLog'

type Props = {
	json: Record<string, any> | null
}

type LogTableColumnType = ColumnDef<{ key: string; value: any }>

export const JsonLogTable = ({ json }: Props) => {
	const [isExpanded, setIsExpanded] = useState(false)
	const columns: LogTableColumnType[] = useMemo(
		() => [
			{
				header: 'Key',
				accessorKey: 'key',
				size: 200,
			},
			{
				header: 'Value',
				accessorKey: 'value',
				size: 400,
			},
		],
		[],
	)

	const data = useMemo(() => formatJsonLog(json), [])

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
	})

	return (
		<Box>
			<Expandable
				expandable
				onExpand={(expandValue) => {
					setIsExpanded(expandValue)
				}}
			>
				<ExpandableTrigger>
					<Flex gap={1}>
						<GetIcon
							icon={Icons.chevron_down}
							transform={`rotate(${isExpanded ? '0deg' : '270deg'})`}
						/>
						<Text variant={TEXT_ENUM.table_header}>
							{`${isExpanded ? 'Hide' : 'Show'} data`}
						</Text>
					</Flex>
				</ExpandableTrigger>
				<ExpandableContent>
					<Box
						w="full"
						maxW="fit-content"
						borderWidth="medium"
						borderRadius="md"
						borderColor={ColorTokens.border_primary}
						bg={ColorTokens.white}
						overflow="hidden"
						mt={2}
					>
						<Flex
							w="fit-content"
							h="full"
							direction="column"
							className={HIDE_SCROLLBAR_CLASS}
							overflowX="scroll"
							fontSize="14px"
						>
							<Flex borderBottom={Borders.primary} bg={COLORS.background[12]}>
								{table.getFlatHeaders().map((header, index) => (
									<Flex
										key={header.id}
										p={3}
										w={header.column.getSize()}
										borderLeft={index === 0 ? 'none' : Borders.primary}
									>
										<Text>
											{flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
										</Text>
									</Flex>
								))}
							</Flex>

							<Flex flexDir="column">
								{table.getRowModel().rows.map((row, index) => (
									<Flex
										key={row.id}
										borderTop={index === 0 ? 'none' : Borders.primary}
									>
										{row.getVisibleCells().map((cell, index) => (
											<Flex
												key={cell.id}
												p={3}
												w={cell.column.getSize()}
												borderLeft={index === 0 ? 'none' : Borders.primary}
												overflowX="scroll"
												className={HIDE_SCROLLBAR_CLASS}
											>
												<Text>
													{flexRender(
														cell.column.columnDef.cell,
														cell.getContext(),
													)}
												</Text>
											</Flex>
										))}
									</Flex>
								))}
							</Flex>
						</Flex>
					</Box>
				</ExpandableContent>
			</Expandable>
		</Box>
	)
}
