import React from 'react'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { Select } from '@/common/components/Select'
import { SelectVariant } from '@/common/components/Select/type'

import { workflowsHomeActions } from '../slice'
import { selectWorkflowsSortBy } from '../slice/selectors'

export enum WorkflowsSortByOptions {
	DateCreated = 'created_at',
	Name = 'name',
}

export const OPTIONS = [
	{
		label: 'Last updated',
		value: null,
	},
	{
		label: 'Date created',
		value: WorkflowsSortByOptions.DateCreated,
	},
	{
		label: 'Alphabetical',
		value: WorkflowsSortByOptions.Name,
	},
]

export const WorkflowsSortBy = React.memo(() => {
	const dispatch = useAppDispatch()
	const selectedSortBy = useAppSelector(selectWorkflowsSortBy)

	const selectedOption = OPTIONS.find(
		(option) => option.value === selectedSortBy,
	)

	return (
		<Select
			options={OPTIONS}
			value={selectedOption}
			variant={SelectVariant.secondary}
			onChange={(option) =>
				dispatch(workflowsHomeActions.setWorkflowsSortBy(option?.value || null))
			}
		/>
	)
})

WorkflowsSortBy.displayName = 'WorkflowsSortBy'
