import { Flex, Text } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'

import { TEXT_ENUM } from '@/styles/components/text'

type Props = {
	success: number | string
	error: number | string
}

export const LogsAnalyticsSuccessError = ({ success, error }: Props) => {
	return (
		<Flex
			bg={ColorTokens.card_secondary}
			px={6}
			py={4}
			borderRadius="12px"
			mt={4}
			justifyContent="space-evenly"
		>
			<Flex flexDir="column" gap={2}>
				<Text variant={TEXT_ENUM.regular12} color={COLORS.background[5]}>
					Success
				</Text>
				<Text variant={TEXT_ENUM.medium20} color={COLORS.purple[9]}>
					{success}
				</Text>
			</Flex>
			<Flex flexDir="column" gap={2}>
				<Text variant={TEXT_ENUM.regular12} color={COLORS.background[5]}>
					Error
				</Text>
				<Text variant={TEXT_ENUM.medium20} color={ColorTokens.text_error}>
					{error}
				</Text>
			</Flex>
		</Flex>
	)
}
