import { Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'
import { useStore } from 'react-redux'
import { useDeepCompareEffect } from 'react-use'
import { Icons } from 'ui'

import { workflowsActions } from '@/modules/workflow/slice'
import { RefNodeValue } from '@/modules/workflow/types/actions'
import {
	Condition as ConditionType,
	DefaultIfElseCondition,
} from '@/modules/workflow/types/logic'

import { ConditionPrefix } from '../ConditionPrefix'
import {
	ConditionMenu,
	ConditionMenuOption,
	ConditionMenuOptionType,
} from './ConditionMenu'

const COMPARATORS_WITHOUT_VALUES = [
	'GENERIC',
	'NOT_GENERIC',
	'EXISTS',
	'DOES_NOT_EXIST',
	'EMPTY',
	'NOT_EMPTY',
	'ASC',
	'DESC',
	'= NULL',
	'!= NULL',
	'ISCHANGED',
]

type UpdateConditionArgs = {
	newParameter?: RefNodeValue | null
	newComparator?: string | null
	newValue?: RefNodeValue | null
}

export type ConditionInputProps = {
	condition: ConditionType
	handleUpdateInput: (newValue: any) => void
}

type ConditionComponent = (props: ConditionInputProps) => JSX.Element

export type ConditionComponents = {
	ConditionParameterInput: ConditionComponent
	ConditionComparatorInput: ConditionComponent
	ConditionValueInput: ConditionComponent
}

interface ConditionProps {
	conditionIndex: number
	branchIndex: number
	blockIndex: number
	components: ConditionComponents
	conditionalObject: { branches: any[] }
	updateNode: (value: any) => void
	handleDeleteCondition: () => void
	handleDeleteConditionBlock: () => void
}

export const Condition = ({
	conditionIndex,
	branchIndex,
	blockIndex,
	components,
	conditionalObject,
	updateNode,
	handleDeleteCondition,
	handleDeleteConditionBlock,
}: ConditionProps) => {
	// The use of the store here is necessary to avoid using stale references to the conditionalObject.
	const store = useStore()
	const dispatch = store.dispatch

	useDeepCompareEffect(() => {
		dispatch(workflowsActions.setConditionalObject(conditionalObject))
	}, [conditionalObject])

	const condition =
		conditionalObject.branches[branchIndex].conditionBlocks[blockIndex]
			.conditions[conditionIndex]

	const { parameter, comparator } = condition

	const isOrderByComparator = comparator === 'ASC' || comparator === 'DESC'

	const handleUpdateCondition = ({
		newParameter,
		newComparator,
		newValue,
	}: UpdateConditionArgs) => {
		const currConditionalObject = store.getState().workflows.conditionalObject
		const newConditionalObject = cloneDeep(currConditionalObject)

		const currentCondition =
			newConditionalObject.branches[branchIndex].conditionBlocks[blockIndex]
				.conditions[conditionIndex]

		// updating parameter
		if (newParameter !== undefined) {
			currentCondition.parameter = newParameter
			currentCondition.comparator = null
			currentCondition.value = null
		}

		// updating comparator
		if (newComparator !== undefined) {
			currentCondition.comparator = newComparator
		}

		// updating value
		if (newValue !== undefined) {
			currentCondition.value = newValue
		}

		dispatch(workflowsActions.setConditionalObject(newConditionalObject))
		updateNode(newConditionalObject)
	}

	const handleUpdateParameter = (newParameter: RefNodeValue) => {
		handleUpdateCondition({
			newParameter,
		})
	}

	const handleUpdateComparator = (newComparator: string) => {
		handleUpdateCondition({
			newComparator,
		})
	}

	const handleUpdateValue = (newValue: RefNodeValue) => {
		handleUpdateCondition({
			newValue,
		})
	}

	const handleGroupCondition = () => {
		const currConditionalObject = store.getState().workflows.conditionalObject
		const newConditionalObject = cloneDeep(currConditionalObject)

		newConditionalObject.branches[branchIndex].conditionBlocks[
			blockIndex
		].conditions.push({
			...DefaultIfElseCondition,
			id: nanoid(12),
		})

		dispatch(workflowsActions.setConditionalObject(newConditionalObject))
		updateNode(newConditionalObject)
	}

	const isBlockGrouped =
		conditionalObject.branches[branchIndex].conditionBlocks[blockIndex]
			.conditions.length > 1

	const menuOptions: ConditionMenuOption[] = [
		{
			label: 'Group condition',
			type: ConditionMenuOptionType.Default,
			onClick: handleGroupCondition,
			icon: Icons.bullet_list_2,
		},
		{
			label: 'Remove condition',
			type: ConditionMenuOptionType.Danger,
			onClick: isBlockGrouped
				? handleDeleteCondition
				: handleDeleteConditionBlock,
			icon: Icons.trash,
		},
	]

	const comparatorHasValue = (comparator: string) =>
		!COMPARATORS_WITHOUT_VALUES.includes(comparator || '')

	return (
		<Flex w="100%" gap={2} align="flex-start">
			{isBlockGrouped ? (
				<ConditionPrefix index={conditionIndex} conditionalOperator="and" />
			) : null}
			<Flex w="100%" gap={2} flexWrap="wrap">
				<components.ConditionParameterInput
					condition={condition}
					handleUpdateInput={handleUpdateParameter}
				/>
				{parameter && (
					<components.ConditionComparatorInput
						condition={condition}
						handleUpdateInput={handleUpdateComparator}
					/>
				)}
				{parameter && comparator && comparatorHasValue(comparator) && (
					<components.ConditionValueInput
						condition={condition}
						handleUpdateInput={handleUpdateValue}
					/>
				)}
			</Flex>
			<ConditionMenu
				options={
					// "order by" comparators are not allowed to be grouped with other conditions
					isBlockGrouped || isOrderByComparator
						? [
								...(menuOptions.filter((o) => o.label === 'Remove condition') ||
									[]),
							]
						: menuOptions
				}
			/>
		</Flex>
	)
}
