import { Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import { SingleValue } from 'react-select'

import {
	useGetSalesloftCadencesQuery,
	useGetSalesloftCadenceStepsQuery,
	useGetSalesloftPersonFieldsQuery,
	useGetSalesloftUsersQuery,
} from '@/app/integrations/api/salesloft'
import { useUpdateWorkflowNode } from '@/modules/workflow/hooks'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import { Salesloft_StartCadence } from '@/modules/workflow/types/actions'

import { PanelInputLabel } from '../../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../../panel-variants/PanelSelect'
import { FieldMapper } from '../FieldMapper'

const blankStepDetails: Salesloft_StartCadence = {
	cadence: {
		refNodeId: null,
		variable: null,
		value: null,
		label: null,
	},
	user: {
		refNodeId: null,
		variable: null,
		value: null,
		label: null,
	},
	startStep: {
		refNodeId: null,
		variable: null,
		value: null,
		label: null,
	},
	insertPersonFields: {},
}

export const SalesloftStartCadence = () => {
	const updateNode = useUpdateWorkflowNode()
	const selectedNode = useSelectedNode()

	const stepDetails = selectedNode?.data.stepDetails as Salesloft_StartCadence
	const cadence = stepDetails?.cadence

	const { data: cadences, isLoading: isCadenceDataLoading } =
		useGetSalesloftCadencesQuery()
	const { data: users, isLoading: isUserDataLoading } =
		useGetSalesloftUsersQuery()
	const { data: steps, isLoading: isStepsDataLoading } =
		useGetSalesloftCadenceStepsQuery(cadence?.value)

	const { data: fieldsData, isLoading: isFieldDataLoading } =
		useGetSalesloftPersonFieldsQuery()
	const fields = Array.isArray(fieldsData) ? fieldsData : []

	const updateNodeStepDetails = (newStepDetails: Salesloft_StartCadence) => {
		if (!selectedNode) return
		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const cadenceOptions =
		cadences && cadences.length
			? cadences.map((cadence) => ({
					label: cadence.name,
					value: cadence.id,
				}))
			: []

	const handleCadenceChange = (
		option: SingleValue<{ label: string; value: number }>,
	) => {
		if (!option) return

		const { label, value } = option
		const newStepDetails = cloneDeep(stepDetails || blankStepDetails)
		newStepDetails.cadence = {
			refNodeId: null,
			variable: null,
			value,
			label,
		}
		newStepDetails.startStep = {
			refNodeId: null,
			variable: null,
			value: null,
			label: null,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const nullUserOption = { label: 'Cadence owner (default)', value: null }
	const userOptions =
		users && users.length
			? [
					nullUserOption,
					...users.map((user) => ({ label: user.name, value: user.id })),
				]
			: [nullUserOption]

	const handeUserChange = (
		option: SingleValue<{ label: string; value: number | null }>,
	) => {
		if (!option) return

		const { label, value } = option
		const newStepDetails = cloneDeep(stepDetails || blankStepDetails)
		newStepDetails.user = {
			refNodeId: null,
			variable: null,
			value,
			label: value === null ? null : label,
		}
		updateNodeStepDetails(newStepDetails)
	}

	const nullStartStepOption = { label: 'Step 1 (default)', value: null }
	const startStepOptions =
		steps && steps.length
			? [
					nullStartStepOption,
					...steps.map((step) => ({ label: step.name, value: step.id })),
				]
			: [nullStartStepOption]

	const handleStartStepChange = (
		option: SingleValue<{ label: string; value: number | null }>,
	) => {
		if (!option) return

		const { label, value } = option
		const newStepDetails = cloneDeep(stepDetails || blankStepDetails)
		newStepDetails.startStep = {
			refNodeId: null,
			variable: null,
			value,
			label: value === null ? null : label,
		}
		updateNodeStepDetails(newStepDetails)
	}

	const cadenceValue = cadenceOptions.find((op) => op.value === cadence?.value)
	const userValue = userOptions.find(
		(op) => op.value === stepDetails?.user?.value,
	)
	const startStepValue = startStepOptions.find(
		(op) => op.value === stepDetails?.startStep?.value,
	)

	return (
		<Flex w="100%" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel label="Cadence" />
				<PanelSelect
					options={cadenceOptions}
					value={cadenceValue || null}
					placeholder="Select a Cadence"
					isLoading={isCadenceDataLoading}
					onChange={handleCadenceChange}
				/>
			</Flex>

			<Flex direction="column">
				<PanelInputLabel label="User (optional)" />
				<PanelSelect
					options={userOptions}
					value={userValue || null}
					placeholder="Select a user"
					isLoading={isUserDataLoading}
					onChange={handeUserChange}
				/>
			</Flex>

			<Flex direction="column">
				<PanelInputLabel label="Start step (optional)" />
				<PanelSelect
					options={startStepOptions}
					value={startStepValue || null}
					placeholder="Select a start step"
					isLoading={isStepsDataLoading}
					onChange={handleStartStepChange}
				/>
			</Flex>

			<FieldMapper
				integration="Salesloft"
				fields={fields}
				isDataLoading={isFieldDataLoading}
			/>
		</Flex>
	)
}
