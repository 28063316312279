import { WorkflowIntegrationType as IntegrationType } from './mappings'

export const getIntegrationDataType = (
	integrationType: IntegrationType,
	dataType: string,
) => {
	switch (integrationType) {
		case IntegrationType.Salesforce:
			return SALESFORCE_DATATYPES[dataType]
		case IntegrationType.HubSpot:
			return HUBSPOT_DATA_TYPES[dataType]
		default:
			return dataType
	}
}

const SALESFORCE_DATATYPES = {
	id: 'string',
	string: 'string',
	int: 'number',
	double: 'number',
	boolean: 'boolean',
}

const HUBSPOT_DATA_TYPES = {
	string: 'string',
	number: 'number',
	bool: 'boolean',
	enumeration: 'array',
}
