import { Flex } from '@chakra-ui/react'
import { WEIGHT } from 'ui'

import { PanelInput, PanelInputProps } from '../../panel-variants/PanelInput'
import { PanelInputLabel } from '../../panel-variants/PanelInputLabel'

interface Props extends PanelInputProps {
	stepLabel?: string
}

export const StepNameInput = ({ stepLabel, ...props }: Props) => {
	return (
		<Flex direction="column" mb={4}>
			<PanelInputLabel
				label={stepLabel || 'Step Name'}
				fontWeight={WEIGHT.medium}
			/>
			<PanelInput {...props} placeholder="Type the step name here" />
		</Flex>
	)
}
