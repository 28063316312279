import { useCallback } from 'react'
import { Icons } from 'ui'

import { AsyncDrpOptionGenerator } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker/components/Option'
import { LabeledRefNodeValue } from '@/modules/workflow/types/actions'

const UTM_PARAMETER_FIELDS = {
	utm_source: 'UTM Source',
	utm_medium: 'UTM Medium',
	utm_campaign: 'UTM Campaign',
	utm_term: 'UTM Term',
	utm_content: 'UTM Content',
	submission_url: 'Submission URL',
	referrer: 'Referrer',
	landing_url: 'Landing URL',
	landing_referrer: 'Landing Referrer',
}

export const useGetUtmParameterDrpOptions = (): AsyncDrpOptionGenerator => {
	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const utmParameterOptions = Object.entries(UTM_PARAMETER_FIELDS).map(
				([key, label]) => ({
					label,
					value: key,
					onSelect: () => {
						const newRfn: LabeledRefNodeValue = {
							refNodeId: '0',
							variable: `$.utmData.${key}`,
							value: null,
							label,
							icon: Icons.world,
						}

						onSelect(newRfn)
					},
				}),
			)

			return [
				{
					label: 'UTM parameter data',
					icon: Icons.world,
					value: utmParameterOptions,
				},
			]
		},
		[],
	)
}
