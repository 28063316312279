export const HOME_SIDEBAR_WIDTH_UNIT = 72
export const HOME_SIDEBAR_WIDTH = `${HOME_SIDEBAR_WIDTH_UNIT}px`

export const LEADS_SIDEBAR_WIDTH_EXPAND_UNIT = 348
export const LEADS_SIDEBAR_WIDTH_EXPAND = `${LEADS_SIDEBAR_WIDTH_EXPAND_UNIT}px`
export const TOOLBAR_WITH_TABS_HEIGHT = 120
export const PAGE_HEADER_HEIGHT_UNIT = 80
export const PAGE_HEADER_HEIGHT = `${PAGE_HEADER_HEIGHT_UNIT}px`

export const ALL_LEADS_VIEW_KEY = 'all_leads'

export const HIDE_SCROLLBAR_CLASS = 'hide_scrollbar'

export const LEADS_VIEW_PREFERENCES_KEY = 'default/leads_view_preferences'
export const LEADS_VIEW_SELECTED_KEY = 'default/leads_view_selected_key'

export const FORM_CONTENT_MAX_WIDTH = 540

export const DEFAULT_ACCESS_TOKEN_KEY = 'default/access_token'

export const WORKFLOW_SIDE_PANEL_ID = '__WORKFLOW_SIDE_PANEL'
export const WORKFLOW_NODE_DROPPABLE_AREA_ID = '__DROPPABLE_AREA'
export const WORKFLOW_NODE_PLUS_BUTTON_ID = '__PLUS_BUTTON'
export const WORKFLOW_NODE_PLUS_BUTTON_SEPARATOR = '%'
