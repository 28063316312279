import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import React from 'react'
import { Link } from 'react-router-dom'

import { useRouteMatch } from '@/hooks/routes'
import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

interface Props extends FlexProps {
	tabs: { title: string; route: string }[]
}

export const PageTabs = React.memo(({ tabs, ...props }: Props) => {
	const isRouteMatch = useRouteMatch()

	if (!tabs || !tabs.length) return null

	return (
		<Flex
			align="center"
			h={9}
			gap={2}
			w="full"
			px={6}
			fontSize="sm"
			flexShrink={0}
			borderBottom={Borders.primary}
			bg={COLORS.whiteAlpha[12]}
			{...props}
		>
			{tabs.map((t, i) => (
				<Link to={t.route} key={i}>
					<Flex
						h={9}
						borderBottomWidth="medium"
						borderColor={
							isRouteMatch(t.route) ? COLORS.blackAlpha[12] : 'transparent'
						}
						py={1.5}
					>
						<Text
							px={2}
							variant={TEXT_ENUM.medium14}
							color={
								isRouteMatch(t.route)
									? COLORS.background[2]
									: COLORS.background[5]
							}
							css={{ userSelect: 'none' }}
						>
							{t.title}
						</Text>
					</Flex>
				</Link>
			))}
		</Flex>
	)
})

PageTabs.displayName = 'PageTabs'
