import { WorkflowIntegrationsMap } from './mappings'

export const filterIntegrationsBySearch = ({
	integration,
	value,
}: {
	integration: string
	value: string
}) => {
	return WorkflowIntegrationsMap[integration].integrationAction
		.toLowerCase()
		.includes(value.toLowerCase())
}
