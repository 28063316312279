import { Flex, Text } from '@chakra-ui/react'
import { GetIcon, Icons } from '@ds/Icons'
import { COLORS } from '@ds/tokens/colors'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAppSelector } from '@/app/hooks'
import { IframePreviewTooltip } from '@/components/IframePreviewTooltip'
import { FormTable } from '@/modules/forms/types'
import { selectIsSidePanelContentOpen } from '@/modules/workflow/slice/selectors'
import { TEXT_ENUM } from '@/styles/components/text'

export const WorkflowVersionViewerCallout = ({
	workflow,
}: {
	workflow: FormTable
}) => {
	const isPanelContentOpen = useAppSelector(selectIsSidePanelContentOpen)
	const navigate = useNavigate()

	const [searchParams] = useSearchParams()
	const logId = searchParams.get('log')

	const handleCtaClick = () => {
		if (logId) {
			navigate(`/workflows/workflow-log/details/${logId}`)
		} else {
			window.close()
		}
	}

	const workflowLogCanvasUrl = `/workflows/${workflow.id}/versions/${workflow.form_version}`

	return (
		<Flex p={4} w={`calc(100% - ${isPanelContentOpen ? '448px' : '64px'})`}>
			<Flex
				bg={COLORS.purple[2]}
				color={COLORS.purple[9]}
				borderRadius="md"
				border={`1px solid ${COLORS.purple[7]}`}
				boxShadow="md"
				px={4}
				py={3}
				zIndex={9}
				w="full"
				alignItems="center"
				justifyContent="space-between"
			>
				<Flex alignItems="center" gap={2}>
					<GetIcon icon={Icons.info} boxSize={5} />
					<Text variant={TEXT_ENUM.semibold14}>
						This is a read-only canvas. Changes will not be saved.
					</Text>
				</Flex>
				<IframePreviewTooltip
					url={`${workflowLogCanvasUrl}?__preview=true`}
					tooltipHeight="250px"
					tooltipWidth="280px"
					iframeStyle={{ marginTop: '-32px' }}
				>
					<Flex
						alignItems="center"
						gap={2}
						cursor="pointer"
						onClick={handleCtaClick}
					>
						<Text variant={TEXT_ENUM.semibold14} textDecor="underline">
							{logId ? 'Return to log details' : 'Close window'}
						</Text>
						{logId && <GetIcon icon={Icons.arrow_up_right} boxSize={2.5} />}
					</Flex>
				</IframePreviewTooltip>
			</Flex>
		</Flex>
	)
}
