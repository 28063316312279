export enum BtnVariants {
	primary = 'primary',
	secondary = 'secondary',
	outline = 'outline',
	tertiary = 'tertiary',
	default = 'default',
	integrations = 'integrations',
	link = 'link',
	text = 'text',
	ghost = 'ghost',
	success = 'success',
	danger_primary = 'danger_primary',
	danger_secondary = 'danger_secondary',
	danger_tertiary = 'danger_tertiary',
	form = 'form',
	active_tab = 'active_tab',
	inactive_tab = 'inactive_tab',
	secondary_active_tab = 'secondary_active_tab',
	secondary_inactive_tab = 'secondary_inactive_tab',
	outlined_secondary = 'outlined_secondary',
}
