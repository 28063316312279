import { TimeUnit, TimeUnitConfig } from '@/modules/workflow/types/time-units'

export const handleTimeChange = (
	timesInput: TimeUnitConfig,
	unit: TimeUnit,
	value: number,
) => {
	const newTimesInput = { ...timesInput, [unit]: value }

	// Normalize minutes to hours
	if (newTimesInput[TimeUnit.MINUTES] >= 60) {
		newTimesInput[TimeUnit.HOURS] += Math.floor(
			newTimesInput[TimeUnit.MINUTES] / 60,
		)
		newTimesInput[TimeUnit.MINUTES] %= 60
	} else if (newTimesInput[TimeUnit.MINUTES] < 0) {
		const hoursBorrowed = Math.ceil(
			Math.abs(newTimesInput[TimeUnit.MINUTES]) / 60,
		)
		newTimesInput[TimeUnit.HOURS] -= hoursBorrowed
		newTimesInput[TimeUnit.MINUTES] += hoursBorrowed * 60
	}

	// Normalize hours to days
	if (newTimesInput[TimeUnit.HOURS] >= 24) {
		newTimesInput[TimeUnit.DAYS] += Math.floor(
			newTimesInput[TimeUnit.HOURS] / 24,
		)
		newTimesInput[TimeUnit.HOURS] %= 24
	} else if (newTimesInput[TimeUnit.HOURS] < 0) {
		const daysBorrowed = Math.ceil(Math.abs(newTimesInput[TimeUnit.HOURS]) / 24)
		newTimesInput[TimeUnit.DAYS] -= daysBorrowed
		newTimesInput[TimeUnit.HOURS] += daysBorrowed * 24
	}

	// Ensure no negative values for days, hours, and minutes
	newTimesInput[TimeUnit.DAYS] = Math.max(0, newTimesInput[TimeUnit.DAYS])
	newTimesInput[TimeUnit.HOURS] = Math.max(0, newTimesInput[TimeUnit.HOURS])
	newTimesInput[TimeUnit.MINUTES] = Math.max(0, newTimesInput[TimeUnit.MINUTES])
	return newTimesInput
}
