import { Flex, Text } from '@chakra-ui/react'

import { useCalendarContext } from '../context'

export const TimeSlotSectionLoading = () => {
	return (
		<Flex w="full" h="full" justifyContent="space-evenly" gap="24px">
			<TimeSlotColumnLoading noOfSlots={4} name="Morning" />
			<TimeSlotColumnLoading noOfSlots={3} name="Daytime" />
			<TimeSlotColumnLoading noOfSlots={4} name="Evening" />
		</Flex>
	)
}

const TimeSlotColumnLoading = ({
	noOfSlots,
	name,
}: {
	noOfSlots: number
	name: string
}) => {
	const { styles } = useCalendarContext()

	return (
		<Flex w="full" direction="column" gap="8px" pos="relative">
			<Flex
				direction="column"
				gap="8px"
				w="full"
				minH="40vh"
				maxH="40vh"
				overflowY="scroll"
				className="hide_scrollbar"
				pb="125px"
			>
				<Flex
					w="full"
					minH="38px"
					h="38px"
					mt="8px"
					bg={styles.is_translucent ? 'translucent' : styles.bg_primary}
					zIndex={20}
					alignItems="center"
				>
					<Text
						color={styles.text_tertiary}
						fontSize="13px"
						fontWeight={600}
						mt="-8px"
					>
						{name}
					</Text>
				</Flex>

				{Array.from({ length: noOfSlots }).map((_, index) => (
					<Slot key={index} />
				))}

				{!styles.is_translucent && (
					<Flex
						pos="absolute"
						bottom="0"
						w="full"
						h="24px"
						zIndex={99}
						bgImage={`linear-gradient(to bottom, ${styles.bg_primary}00 0%, ${styles.bg_primary} 100%);`}
					/>
				)}
			</Flex>
		</Flex>
	)
}

const Slot = () => {
	const { styles } = useCalendarContext()

	return (
		<Flex
			rounded={6}
			w="full"
			h="40px"
			minH="40px"
			bg={`linear-gradient(90deg, ${styles.loading_gradient_1} 0%, ${styles.loading_gradient_2} 52%, ${styles.loading_gradient_3} 100%);`}
		/>
	)
}
