import { Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import { SingleValue } from 'react-select'

import {
	useGetOutreachSequencesQuery,
	useUseOutreachProspectsFieldsQuery,
} from '@/app/integrations/api/outreach'
import { OutreachSequenceType } from '@/app/integrations/api/outreach/type'
import {
	useCurrentNodeData,
	useUpdateWorkflowNode,
} from '@/modules/workflow/hooks'
import { useGetOutreachDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetOutreachDrpOptions'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import {
	LabeledRefNodeValue,
	Outreach_StartSequence,
} from '@/modules/workflow/types/actions'

import { PanelInputLabel } from '../../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../../panel-variants/PanelSelect'
import { DataReferencePicker } from '../../DataReferencePicker'
import { FieldMapper } from '../FieldMapper'

const emptyDefault: Outreach_StartSequence = {
	sequence: {
		refNodeId: null,
		variable: null,
		value: null,
		label: null,
	},
	mailbox: {
		refNodeId: null,
		variable: null,
		value: null,
		label: null,
	},
	insertProspectFields: {},
}

export const OutreachStartSequence = () => {
	const updateNode = useUpdateWorkflowNode()
	const selectedNode = useSelectedNode()
	const getDrpOptions = useGetOutreachDrpOptions()

	const stepDetails =
		(selectedNode?.data.stepDetails as Outreach_StartSequence) || {}

	const { getCurrentNodeData } = useCurrentNodeData(selectedNode)

	const { data: sequences, isLoading: isSequenceDataLoading } =
		useGetOutreachSequencesQuery()

	const { data: fieldsData, isLoading: isFieldDataLoading } =
		useUseOutreachProspectsFieldsQuery()
	const fields = Array.isArray(fieldsData) ? fieldsData : []

	const updateNodeStepDetails = (newStepDetails: Outreach_StartSequence) => {
		if (!selectedNode) return

		const currentNodeData = getCurrentNodeData()

		updateNode({
			...currentNodeData,
			data: {
				...currentNodeData.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const sequenceOptions =
		sequences?.map((s: OutreachSequenceType) => ({
			label: s.attributes.name,
			value: s.id,
		})) || []

	const handleSequenceChange = (
		option: SingleValue<{ label: string; value: number }>,
	) => {
		if (!option) return

		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Outreach_StartSequence
		const newStepDetails = currentStepDetails || cloneDeep(emptyDefault)

		const { label, value } = option
		newStepDetails.sequence = {
			refNodeId: null,
			variable: null,
			value,
			label,
		}
		updateNodeStepDetails(newStepDetails)
	}

	const sequenceValue = sequenceOptions.find(
		(op) => op.value === stepDetails.sequence?.value,
	)

	const handleMailboxSelect = (rfn: LabeledRefNodeValue) => {
		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Outreach_StartSequence
		const newStepDetails = currentStepDetails || cloneDeep(emptyDefault)

		newStepDetails.mailbox = rfn

		if (rfn.fetchMappedUserIntegration) {
			newStepDetails.mailbox.fetchMappedUserIntegration =
				rfn.fetchMappedUserIntegration
		}

		updateNodeStepDetails(newStepDetails)
	}

	const mailbox = stepDetails?.mailbox
	const selectedMailboxOption = mailbox?.label
		? { label: mailbox.label, value: mailbox }
		: null

	return (
		<Flex w="100%" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel label="Sequence" />
				<PanelSelect
					options={sequenceOptions}
					value={sequenceValue || null}
					placeholder="Select a Sequence"
					isLoading={isSequenceDataLoading}
					onChange={handleSequenceChange}
				/>
			</Flex>

			<Flex direction="column">
				<PanelInputLabel label="Mailbox" />
				<DataReferencePicker
					getInitialOptions={getDrpOptions}
					initialOption={selectedMailboxOption}
					onSelect={handleMailboxSelect}
				/>
			</Flex>

			<FieldMapper
				integration="Outreach"
				fields={fields}
				isDataLoading={isFieldDataLoading}
			/>
		</Flex>
	)
}
