import { Center, Flex, Text } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { useNavigate } from 'react-router-dom'

import {
	useGetAllGoogleCaledarIntegrationsQuery,
	useGetGoogleCalendarHealthCheckQuery,
} from '@/app/integrations/api/calendar'
import { HealthCheckStatus } from '@/app/integrations/api/calendar/type'
import { TEXT_ENUM } from '@/styles/components/text'

export const StatusBar = () => {
	const { data: googleCalendarIntegrations } =
		useGetAllGoogleCaledarIntegrationsQuery()
	const { data: googleCalendarHealthCheckStatus } =
		useGetGoogleCalendarHealthCheckQuery()

	const navigate = useNavigate()

	return (
		<Flex w="full">
			{googleCalendarIntegrations &&
				googleCalendarIntegrations.length > 0 &&
				googleCalendarHealthCheckStatus === HealthCheckStatus.Unhealthy && (
					<Center
						w="full"
						h="56px"
						flexDir="column"
						background={`repeating-linear-gradient(
  45deg,
${COLORS.red[3]},
${COLORS.red[3]} 20px,
${COLORS.red[4]} 20px,
${COLORS.red[4]} 40px
)`}
					>
						<Text variant={TEXT_ENUM.semibold14} textAlign="center">
							Warning: Your account&apos;s Google Calendar connection did not
							grant Default access to your calendar.
						</Text>
						<Text variant={TEXT_ENUM.regular14} textAlign="center">
							Please go to{' '}
							<Text
								as="span"
								color={ColorTokens.accent}
								cursor="pointer"
								onClick={() => navigate('/settings/user/calendars')}
							>
								{'Settings > Calendar'}
							</Text>{' '}
							to reconnect and grant all permissions.
						</Text>
					</Center>
				)}
		</Flex>
	)
}
