import React from 'react'

import { FormType } from '@/modules/forms/types'
import { CanvasNodeMetaDetailsLayout } from '@/modules/workflow/layouts/CanvasNodeMetaDetailsLayout'

import { NodeDescription } from '../NodeDescription'
import { FormSubmissionDetails } from './FormSubmissionDetails'
import { HubspotDetails } from './HubspotDetails'
import { MeetingTriggerDetails } from './MeetingTriggerDetails'
import { SalesforceDetails } from './SalesforceDetails'
import { TagAssignedDetails } from './TagAssignedDetails'
import { WebhookDetails } from './WebhookDetails'

type Props = {
	description?: string | null
	type: FormType
}

export const GetTriggerNodeDetails = React.memo(
	({ description, type }: Props) => {
		return (
			<CanvasNodeMetaDetailsLayout>
				<NodeDescription description={description} />
				{getTriggerDetails(type)}
			</CanvasNodeMetaDetailsLayout>
		)
	},
)

GetTriggerNodeDetails.displayName = 'GetTriggerNodeDetails'

const getTriggerDetails = (type: FormType) => {
	switch (type) {
		case FormType.regular:
		case FormType.imported:
			return <FormSubmissionDetails />

		case FormType.meeting_booked_trigger:
		case FormType.meeting_cancelled_trigger:
		case FormType.meeting_reassigned_trigger:
		case FormType.meeting_rescheduled_trigger:
		case FormType.meeting_no_show_trigger:
			return <MeetingTriggerDetails />

		case FormType.tag_assigned_trigger:
			return <TagAssignedDetails />

		case FormType.salesforce_object_create_trigger:
		case FormType.salesforce_object_update_trigger:
			return <SalesforceDetails />

		case FormType.hubspot_trigger:
			return <HubspotDetails />

		case FormType.webhook_trigger:
			return <WebhookDetails />

		default:
			return null
	}
}
