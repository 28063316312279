import { useCallback } from 'react'
import { Icons } from 'ui'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { WorkflowIntegrationIds } from '../../../utils/mappings'
import { useGetUpstreamNodes } from '../../useGetUpstreamNodes'

enum RoundRobinAssigneePropsKey {
	MemberId = 'member_id',
	MemberName = 'member_name',
}

type RoundRobinAssigneePropsType = {
	label?: string
	fetchMappedUserIntegration?: string
}

const ROUND_ROBIN_ASSIGNEE_PROPS: Record<
	RoundRobinAssigneePropsKey,
	RoundRobinAssigneePropsType
> = {
	[RoundRobinAssigneePropsKey.MemberId]: {
		fetchMappedUserIntegration: 'default',
	},
	[RoundRobinAssigneePropsKey.MemberName]: {
		label: 'name',
	},
}

export const useGetRoundRobinAssigneeDrpOptions =
	(): AsyncDrpOptionGenerator => {
		const { getUpstreamNodes } = useGetUpstreamNodes()

		return useCallback(
			async ({
				onSelect,
			}: {
				onSelect: (value) => void
			}): Promise<DataReferencePickerOption[]> => {
				const upstreamNodes = getUpstreamNodes()

				const allRoundRobinNodes = upstreamNodes.filter(
					(n) => n.data.integrationId === WorkflowIntegrationIds.defaultRoute,
				)

				const icon = Icons.default_logo

				const roundRobinOwnerNodes: DataReferencePickerOption[] =
					allRoundRobinNodes.map((n) => {
						return {
							label: `Round-Robin assignee (from "${n.data.editableName}" step)`,
							icon,
							value: [
								...Object.entries(ROUND_ROBIN_ASSIGNEE_PROPS).map(
									([key, prop]) => {
										const { label: propLabel, fetchMappedUserIntegration } =
											prop
										const label = `Round-Robin assignee${propLabel ? ` ${propLabel}` : ''}`

										const newRfn = {
											refNodeId: n.data.id,
											variable: `$.${key}`,
											value: null,
											fetchMappedUserIntegration,
											type: 'member',
											label,
											icon,
										}

										return {
											label,
											icon,
											value: newRfn,
											onSelect: (value) => {
												onSelect(value)
											},
										}
									},
								),
							],
						}
					})

				const roundRobinNode = upstreamNodes.find((n) => {
					return n.data.integrationId === WorkflowIntegrationIds.defaultRoute
				})

				if (roundRobinNode) {
					roundRobinOwnerNodes.unshift({
						label: 'Latest Round-Robin assignee',
						icon,
						value: [
							...Object.entries(ROUND_ROBIN_ASSIGNEE_PROPS).map(
								([key, prop]) => {
									const { label: propLabel, fetchMappedUserIntegration } = prop
									const label = `Latest Round-Robin assignee${propLabel ? ` ${propLabel}` : ''}`

									const newRfn = {
										refNodeId: 'meeting_booked',
										variable: `$.${key}`,
										value: null,
										fetchMappedUserIntegration,
										type: 'member',
										label,
										icon,
									}

									return {
										label,
										icon,
										value: newRfn,
										onSelect: (value) => {
											onSelect(value)
										},
									}
								},
							),
						],
					})
				}

				return [
					{
						label: 'All Round-Robin assignees',
						icon: Icons.default_logo,
						value: roundRobinOwnerNodes,
					},
				]
			},
			[getUpstreamNodes],
		)
	}
