export const CLEARBIT_COUNTRIES: string[] = [
	'Andorra',
	'United Arab Emirates',
	'Afghanistan',
	'Antigua and Barbuda',
	'Anguilla',
	'Albania',
	'Armenia',
	'Angola',
	'Antarctica',
	'Argentina',
	'American Samoa',
	'Austria',
	'Australia',
	'Aruba',
	'Aland Islands',
	'Azerbaijan',
	'Bosnia and Herzegovina',
	'Barbados',
	'Bangladesh',
	'Belgium',
	'Burkina Faso',
	'Bulgaria',
	'Bahrain',
	'Burundi',
	'Benin',
	'Saint Barthélemy',
	'Bermuda',
	'Brunei Durussalam',
	'Bolivia, Plurinational State of',
	'Bonaire, Sint Eustatius and Saba',
	'Brazil',
	'Bahamas',
	'Bhutan',
	'Botswana',
	'Belarus',
	'Belize',
	'Canada',
	'Cocos (Keeling) Islands',
	'Congo, the Democratic Republic of the',
	'Central African Republic',
	'Congo',
	'Switzerland',
	"Cote d'Ivoire",
	'Cook Islands',
	'Chile',
	'Cameroon',
	'China',
	'Colombia',
	'Costa Rica',
	'Cuba',
	'Cape Verde',
	'Curaçao',
	'Cyprus',
	'Czech Republic',
	'Germany',
	'Djibouti',
	'Denmark',
	'Dominica',
	'Dominican Republic',
	'Algeria',
	'Ecuador',
	'Estonia',
	'Egypt',
	'Western Sahara',
	'Eritrea',
	'Spain',
	'Ethiopia',
	'Finland',
	'Fiji',
	'Falkland Islands (Malvinas)',
	'Micronesia (Federated States of)',
	'Faroe Islands',
	'France',
	'Gabon',
	'United Kingdom',
	'Grenada',
	'Georgia',
	'French Guiana',
	'Guernsey',
	'Ghana',
	'Gibraltar',
	'Greenland',
	'Gambia',
	'Guinea',
	'Guadeloupe',
	'Equatorial Guinea',
	'Greece',
	'Guatemala',
	'Guam',
	'Guinea-Bissau',
	'Guyana',
	'Hong Kong',
	'Honduras',
	'Croatia',
	'Haiti',
	'Hungary',
	'Indonesia',
	'Ireland',
	'Israel',
	'Isle of Man',
	'India',
	'British Indian Ocean Territory',
	'Iraq',
	'Iran, Islamic Republic of',
	'Iceland',
	'Italy',
	'Jersey',
	'Jamaica',
	'Jordan',
	'Japan',
	'Kenya',
	'Kyrgyzstan',
	'Cambodia',
	'Kiribati',
	'Comoros',
	'Saint Kitts and Nevis',
	"Korea, Democratic People's Republic of",
	'Korea, Republic of',
	'Kuwait',
	'Cayman Islands',
	'Kazakhstan',
	"Lao People's Democratic Republic",
	'Lebanon',
	'Saint Lucia',
	'Liechtenstein',
	'Sri Lanka',
	'Liberia',
	'Lesotho',
	'Lithuania',
	'Luxembourg',
	'Latvia',
	'Libya',
	'Morocco',
	'Monaco',
	'Moldova, Republic of',
	'Montenegro',
	'Saint Martin (French part)',
	'Madagascar',
	'Marshall Islands',
	'Macedonia, the former Yugoslav Republic of',
	'Mali',
	'Myanmar',
	'Mongolia',
	'Macao',
	'Northern Mariana Islands',
	'Martinique',
	'Mauritania',
	'Montserrat',
	'Malta',
	'Mauritius',
	'Maldives',
	'Malawi',
	'Mexico',
	'Malaysia',
	'Mozambique',
	'Namibia',
	'New Caledonia',
	'Niger',
	'Norfolk Island',
	'Nigeria',
	'Nicaragua',
	'Netherlands',
	'Norway',
	'Nepal',
	'Nauru',
	'Niue',
	'New Zealand',
	'Oman',
	'Panama',
	'Peru',
	'French Polynesia',
	'Papua New Guinea',
	'Philippines',
	'Pakistan',
	'Poland',
	'Saint Pierre and Miquelon',
	'Pitcairn',
	'Puerto Rico',
	'Palestine',
	'Portugal',
	'Palau',
	'Paraguay',
	'Qatar',
	'Reunion',
	'Romania',
	'Serbia',
	'Russia',
	'Rwanda',
	'Saudi Arabia',
	'Solomon Islands',
	'Seychelles',
	'Sudan',
	'Sweden',
	'Singapore',
	'Saint Helena, Ascension and Tristan da Cunha',
	'Slovenia',
	'Svalbard and Jan Mayen',
	'Slovakia',
	'Sierra Leone',
	'San Marino',
	'Senegal',
	'Somalia',
	'Suriname',
	'South Sudan',
	'Sao Tome and Principe',
	'El Salvador',
	'Sint Maarten',
	'Syrian Arab Republic',
	'Eswatini',
	'Turks and Caicos Islands',
	'Chad',
	'French Southern Territories',
	'Togo',
	'Thailand',
	'Tajikistan',
	'Timor-Leste',
	'Turkmenistan',
	'Tunisia',
	'Tonga',
	'Turkey',
	'Trinidad and Tobago',
	'Tuvalu',
	'Taiwan',
	'Tanzania, the United Republic of',
	'Ukraine',
	'Uganda',
	'United States',
	'Uruguay',
	'Uzbekistan',
	'Holy See',
	'Saint Vincent and the Grenadines',
	'Venezuela, Bolivarian Republic of',
	'Virgin Islands, British',
	'Virgin Islands (U.S.)',
	'Vietnam',
	'Vanuatu',
	'Wallis and Futuna',
	'Samoa',
	'Yemen',
	'Mayotte',
	'South Africa',
	'Zambia',
	'Zimbabwe',
]
