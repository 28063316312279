import { Flex } from '@chakra-ui/react'
import { SingleValue } from 'react-select'

import { useGetHubspotPropertiesQuery } from '@/app/integrations/api/hubspot'
import { useUpdateWorkflowNode } from '@/modules/workflow/hooks'
import {
	HubspotRecordOption,
	useHubspotRecordOptionsByType,
} from '@/modules/workflow/hooks/useHubspotRecordOptionsByType'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import {
	Hubspot_MergeRecord,
	HubspotEntities,
} from '@/modules/workflow/types/hubspot'

import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../../../panel-variants/PanelSelect'
import { FieldMapper } from '../../FieldMapper'

export const HubSpotMergeRecord = () => {
	const updateNode = useUpdateWorkflowNode()
	const selectedNode = useSelectedNode()
	const stepDetails = (selectedNode?.data?.stepDetails ||
		{}) as Hubspot_MergeRecord
	const { duplicateRecordId, type } = stepDetails

	const { getHubspotRecordOptions } = useHubspotRecordOptionsByType()
	const recordOptions: HubspotRecordOption[] = getHubspotRecordOptions(
		type?.value || '',
	)

	const { data: propertiesData, isLoading: isPropertiesDataLoading } =
		useGetHubspotPropertiesQuery(
			{ type: type?.value || '', filterOutReadOnlyProperties: true },
			{
				skip: !type?.value,
			},
		)
	const properties = Array.isArray(propertiesData) ? propertiesData : []

	const updateNodeStepDetails = (newStepDetails: Hubspot_MergeRecord) => {
		if (!selectedNode) return
		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const recordTypeOptions = Object.values(HubspotEntities).map((type) => ({
		label: type,
		value: type,
	}))

	const handleSelectRecordType = (
		entityTypeOp: SingleValue<{
			label: HubspotEntities
			value: HubspotEntities
		}>,
	) => {
		if (!entityTypeOp) return

		const newStepDetails: Hubspot_MergeRecord = {
			type: {
				refNodeId: null,
				variable: null,
				value: entityTypeOp.value,
			},
			insertHubspotProperties: {},
			duplicateRecordId: {
				refNodeId: null,
				variable: null,
				value: null,
				label: null,
			},
			overwrite: false,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleSelectRecord = (recordOp: SingleValue<HubspotRecordOption>) => {
		if (!recordOp) return

		const newStepDetails: Hubspot_MergeRecord = {
			...stepDetails,
			duplicateRecordId: {
				refNodeId: recordOp.value,
				variable: recordOp.variable, // this refers to { ... Id }.id
				value: null,
				label: recordOp.label,
			},
		}

		updateNodeStepDetails(newStepDetails)
	}

	const recordTypeValue = recordTypeOptions.find(
		(op) => op.value === type?.value,
	)

	const recordValue = recordOptions.find(
		(op) =>
			op.value === duplicateRecordId?.refNodeId &&
			op.variable === duplicateRecordId?.variable,
	)

	return (
		<Flex w="100%" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel label="Type" />
				<PanelSelect
					placeholder="Select a record type"
					value={recordTypeValue || null}
					options={recordTypeOptions}
					onChange={handleSelectRecordType}
				/>
			</Flex>

			{type && (
				<>
					<Flex direction="column">
						<PanelInputLabel label="Record to update" />
						<PanelSelect
							placeholder="Select a record"
							value={recordValue || null}
							options={recordOptions}
							onChange={handleSelectRecord}
						/>
					</Flex>
					<FieldMapper
						integration="HubSpot"
						fields={properties}
						isDataLoading={isPropertiesDataLoading}
					/>
				</>
			)}
		</Flex>
	)
}
