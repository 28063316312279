import { useFlags } from 'launchdarkly-react-client-sdk'

import { FeatureFlags } from '@/common/constants/feature-flags'

export const useIsDeprecateParentIdsChildren = () => {
	const flags = useFlags()

	return flags[FeatureFlags['deprecate-parent-ids-children']] === undefined
		? false
		: flags[FeatureFlags['deprecate-parent-ids-children']]
}
