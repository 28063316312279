import { Flex } from '@chakra-ui/react'

type Props = {
	children: React.ReactNode
}
export const HomeLayout = ({ children }: Props) => {
	return (
		<Flex w="100vw" h="100vh" position="relative" direction="row">
			{children}
		</Flex>
	)
}
