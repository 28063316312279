import '@/styles/components/RichTextEditor.css'

import { Descendant, Element as SlateElement, Text as SlateText } from 'slate'

export const deserialize = (html: string): Descendant[] => {
	if (!html) {
		return [{ type: 'paragraph', children: [{ text: '' }] }]
	}
	const parser = new DOMParser()
	const doc = parser.parseFromString(html, 'text/html')
	const result = deserializeNodes(doc.body)

	// Ensure there's always at least one paragraph with a text node
	if (result.length === 0) {
		return [{ type: 'paragraph', children: [{ text: '' }] }]
	}

	return result.map((node) => {
		if (SlateElement.isElement(node)) {
			if (node.children.length === 0) {
				return { ...node, children: [{ text: '' }] }
			}
			const lastChild = node.children[node.children.length - 1]
			if (lastChild.type === 'template-variable') {
				return { ...node, children: [...node.children, { text: ' ' }] }
			}
		}
		return node
	})
}

export const deserializeNodes = (node: globalThis.Node): Descendant[] => {
	if (node.nodeType === globalThis.Node.TEXT_NODE) {
		return node.textContent ? [{ text: node.textContent }] : []
	} else if (node.nodeType !== globalThis.Node.ELEMENT_NODE) {
		return []
	}

	const element = node as unknown as globalThis.Element
	const children = Array.from(element.childNodes).flatMap(deserializeNodes)

	switch (element.nodeName.toLowerCase()) {
		case 'body':
		case 'div':
			return children.map((child) => {
				if (!SlateElement.isElement(child)) {
					return { type: 'paragraph', children: [child] }
				}
				return child
			})
		case 'p':
			return [{ type: 'paragraph', children }]
		case 'br':
			return [{ text: '' }]
		case 'blockquote':
			return [{ type: 'blockquote', children }]
		case 'ul':
			return [{ type: 'bulleted-list', children }]
		case 'ol':
			return [{ type: 'numbered-list', children }]
		case 'li':
			return [{ type: 'list-item', children }]
		case 'strong':
			return children.map((child) => ({ ...child, bold: true }))
		case 'em':
			return children.map((child) => ({ ...child, italic: true }))
		case 'u':
			return children.map((child) => ({ ...child, underline: true }))
		case 'del':
			return children.map((child) => ({ ...child, strikethrough: true }))
		case 'span':
			if (
				element.getAttribute('data-type') === 'mention' ||
				element.getAttribute('data-type') === 'template-variable'
			) {
				const data = element.getAttribute('data-id')
				const label: string = element.textContent || '{{ Click to pick data }}'

				return [
					{
						type: 'template-variable',
						label,
						data,
						children: [{ text: '' }],
					},
				]
			}
			return children
		default:
			return children
	}
}

export const serialize = (nodes: Descendant[]): string => {
	return nodes.map(serializeNode).join('')
}

export const serializeNode = (node: Descendant): string => {
	if (SlateText.isText(node)) {
		let text = node.text
		if (node.bold) {
			text = `<strong>${text}</strong>`
		}
		if (node.italic) {
			text = `<em>${text}</em>`
		}
		if (node.strikethrough) {
			text = `<del>${text}</del>`
		}

		return text
			.replaceAll(/<(?!\/?(strong|em|del))/g, '&lt;')
			.replaceAll(/(?<!(strong|em|del))>/g, '&gt;')
	}

	const children = node.children.map(serializeNode).join('')

	switch (node.type) {
		case 'blockquote':
			return `<blockquote>${children}</blockquote>`
		case 'paragraph':
			return `<p>${children}</p>`
		case 'bulleted-list':
			return `<ul>${children}</ul>`
		case 'numbered-list':
			return `<ol>${children}</ol>`
		case 'list-item':
			return `<li>${children}</li>`
		case 'template-variable': {
			const dataId: string =
				typeof node.data === 'string' ? node.data : JSON.stringify(node.data)
			return `<span data-type="template-variable" class="mention" data-id="${dataId.replaceAll('"', '&quot;')}">${node.label}</span>`
		}
		default:
			return children
	}
}
