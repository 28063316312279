import { GenericDataTypes } from '../../types/generic-data-types'
import { SalesforceDataTypes } from '../../types/salesforce'

export const GenericToCrmDataTypesMap: Record<GenericDataTypes, string[]> = {
	[GenericDataTypes.String]: [
		SalesforceDataTypes.String,
		SalesforceDataTypes.Textarea,
		SalesforceDataTypes.Email,
		SalesforceDataTypes.Phone,
		SalesforceDataTypes.EncryptedString,
		SalesforceDataTypes.Url,
		SalesforceDataTypes.Combobox,
		SalesforceDataTypes.Id,
		SalesforceDataTypes.MasterRecord,
		SalesforceDataTypes.Reference,
	],
	[GenericDataTypes.Number]: [
		SalesforceDataTypes.Double,
		SalesforceDataTypes.Int,
		SalesforceDataTypes.Percent,
		SalesforceDataTypes.Currency,
		SalesforceDataTypes.Long,
	],
	[GenericDataTypes.Boolean]: [SalesforceDataTypes.Boolean],
	[GenericDataTypes.Array]: [
		SalesforceDataTypes.Floatarray,
		SalesforceDataTypes.JunctionIdList,
		SalesforceDataTypes.MultiPicklist,
		SalesforceDataTypes.Picklist,
	],
	[GenericDataTypes.Object]: [
		SalesforceDataTypes.Address,
		SalesforceDataTypes.Location,
	],
	[GenericDataTypes.Datetime]: [
		SalesforceDataTypes.Datetime,
		SalesforceDataTypes.Date,
		SalesforceDataTypes.Time,
	],
}
