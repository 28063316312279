import { Flex, Text, Tooltip } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'

import { FULL_BAR_HEIGHT } from '../../constant'

export const LogsAnalyticsGraphBar = ({
	label,
	error,
	success,
	weekTotal,
}: {
	label: string
	error: number
	success: number
	weekTotal: number
}) => {
	const total = error + success
	const barHeight = (total / weekTotal) * FULL_BAR_HEIGHT
	const successBlockHeight = total !== 0 ? (success / total) * barHeight : 0
	const errorBlockHeight = barHeight - successBlockHeight

	return (
		<Tooltip
			label={
				total === 0 ? (
					<Text fontSize="12px">No executions</Text>
				) : (
					<Flex flexDir="column">
						<Text fontSize="12px">{`Success: ${success}`}</Text>
						<Text fontSize="12px">{`Error: ${error}`}</Text>
					</Flex>
				)
			}
		>
			<Flex
				flexDir="column"
				gap={2}
				mr={2}
				align="center"
				h={`${FULL_BAR_HEIGHT + 26}px`}
			>
				<Flex
					flexDir="column"
					justifyContent="flex-end"
					h={`${FULL_BAR_HEIGHT}px`}
					w="45px"
					bg={ColorTokens.card_secondary}
					borderRadius="4px"
					overflow="hidden"
				>
					<Flex
						h={`${errorBlockHeight}px`}
						bg={ColorTokens.error_background_primary}
					/>
					<Flex
						h={`${successBlockHeight}px`}
						bg={COLORS.purple[9]}
						borderBottomRadius="4px"
					/>
				</Flex>
				<Text fontSize="12px" color={COLORS.background[5]} fontWeight={400}>
					{label}
				</Text>
			</Flex>
		</Tooltip>
	)
}
