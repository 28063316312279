type Props = {
	hideConnector?: boolean
}

export const ActiveStepIcon = ({ hideConnector = false }: Props) => {
	return (
		<svg
			width="24"
			height="46"
			viewBox="0 0 24 46"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_2645_15224)">
				<rect width="24" height="24" rx="12" fill="#4747EB" />
				<circle cx="12" cy="12" r="4" fill="#FCFCFC" />
			</g>
			{hideConnector ? null : (
				<rect x="11" y="28" width="2" height="14" rx="1" fill="#E6E6E6" />
			)}
			<defs>
				<clipPath id="clip0_2645_15224">
					<rect width="24" height="24" rx="12" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}
