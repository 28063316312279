import { Route, Routes } from 'react-router-dom'
import { useTitle } from 'react-use'

import { FeatureFlagGuard } from '@/common/components/FeatureFlagGuard/FeatureFlagGuard'
import { useIsWorkflowVersionViewer } from '@/common/hooks/feature-flags/useIsWorkflowVersionViewer'
import { WorkflowEditorRouter } from '@/modules/workflow'
import { WorkflowVerisonViewer } from '@/modules/workflow/components/canvas/WorkflowVersionViewer'
import { WorkflowLogDetailsPage } from '@/modules/workflow-logs'

import { WorkflowsHomeLayout } from '../layout/WorkflowsHomeLayout'
import { WorkflowsHomePage } from '../pages/WorkflowsHomePage'

export const WorkflowsRouter = () => {
	useTitle('Default - Workflows')
	const isWorkflowVersionViewer = useIsWorkflowVersionViewer()

	return (
		<Routes>
			<Route
				element={<FeatureFlagGuard flag="workflows-3-0" fallback={true} />}
			>
				<Route path="/">
					<Route element={<WorkflowsHomeLayout />}>
						<Route index element={<WorkflowsHomePage />} />
					</Route>

					<Route path="/:workflowId/*" element={<WorkflowEditorRouter />} />

					<Route
						path="/workflow-log/details/:submissionId"
						element={<WorkflowLogDetailsPage />}
					/>

					{/* New route for viewing a specific workflow version */}
					{isWorkflowVersionViewer && (
						<Route
							path="/:workflowId/versions/:workflowVersion"
							element={<WorkflowVerisonViewer />}
						/>
					)}
				</Route>
			</Route>
		</Routes>
	)
}
