import { useCallback, useState } from 'react'

export type StepStatus = 'idle' | 'running' | 'success' | 'error' | 'skipped'

export interface TestStepType {
	id: string
	label: string
	status: StepStatus
	rightElement?: React.ReactNode
}

export const useTestSteps = (initialSteps: { id: string; label: string }[]) => {
	const [steps, setSteps] = useState<TestStepType[]>(
		initialSteps.map((step) => ({ ...step, status: 'idle' })),
	)

	const startTest = useCallback(() => {
		setSteps((prevSteps) =>
			prevSteps.map((step, index) =>
				index === 0 ? { ...step, status: 'running' } : step,
			),
		)
	}, [])

	const updateStepStatus = useCallback((id: string, status: StepStatus) => {
		setSteps((prevSteps) =>
			prevSteps.map((step) => (step.id === id ? { ...step, status } : step)),
		)
	}, [])

	const updateStep = useCallback(
		(
			id: string,
			partialStep: Partial<TestStepType>,
			callback?: (newSteps: TestStepType[]) => void,
		) => {
			setSteps((prevSteps) => {
				const newSteps = prevSteps.map((step) =>
					step.id === id ? { ...step, ...partialStep } : step,
				)
				if (callback) {
					callback(newSteps)
				}
				return newSteps
			})
		},
		[],
	)

	const updateRunningStep = useCallback(
		(partialStep: Partial<TestStepType>) => {
			setSteps((prevSteps) =>
				prevSteps.map((step) =>
					step.status === 'running' ? { ...step, ...partialStep } : step,
				),
			)
		},
		[],
	)

	const addStep = useCallback(
		(
			newStep: { id: string; label: string; status?: StepStatus },
			afterId?: string,
		) => {
			setSteps((prevSteps) => {
				const newStepWithStatus: TestStepType = { status: 'idle', ...newStep }
				if (!afterId) {
					return [...prevSteps, newStepWithStatus]
				}
				const index = prevSteps.findIndex((step) => step.id === afterId)
				if (index === -1) {
					return [...prevSteps, newStepWithStatus]
				}
				return [
					...prevSteps.slice(0, index + 1),
					newStepWithStatus,
					...prevSteps.slice(index + 1),
				]
			})
		},
		[],
	)

	const resetSteps = useCallback(() => {
		setSteps(initialSteps.map((step) => ({ ...step, status: 'idle' })))
	}, [initialSteps])

	return {
		steps,
		startTest,
		updateStepStatus,
		updateStep,
		updateRunningStep,
		addStep,
		resetSteps,
	}
}
