import { Box, BoxProps, Center, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { TEXT_ENUM } from '@/styles/components/text'
import { capitalize } from '@/utils/helpers'

interface ConditionPrefixProps extends BoxProps {
	index: number
	conditionalOperator: 'and' | 'or'
}

export const ConditionPrefix = ({
	index,
	conditionalOperator,
	...props
}: ConditionPrefixProps) => {
	return (
		<Box w="80px" h="34px" {...props}>
			<Center
				py={1.5}
				px={3}
				color={COLORS.background[6]}
				border="1px solid #D9D9D9"
				bg={COLORS.background[12]}
				borderRadius="8px"
				w="74px"
				h="100%"
				justifyContent="space-evenly"
			>
				{index !== 0 && (
					<GetIcon color={COLORS.background[2]} icon={Icons.switch_arrow} />
				)}
				<Text
					variant={TEXT_ENUM.medium14}
					color={COLORS.background[5]}
					whiteSpace="nowrap"
				>
					{index === 0 ? 'If value' : capitalize(conditionalOperator)}
				</Text>
			</Center>
		</Box>
	)
}
