import { Tabs } from '@chakra-ui/react'
import React from 'react'
import { GetIcon, Icons } from 'ui'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { SecondaryButtonTab } from '@/components/Tabs/SecondaryButtonTab'

import { workflowsHomeActions } from '../slice'
import { selectWorkflowsViewPreferences } from '../slice/selectors'
import { WorkflowsViewTypes } from '../types/WorkflowsViewTypes'

export const WorkflowsViewToggle = React.memo(() => {
	const dispatch = useAppDispatch()
	const viewPreference = useAppSelector(selectWorkflowsViewPreferences)

	return (
		<Tabs defaultIndex={viewPreference === WorkflowsViewTypes.card ? 1 : 0}>
			<SecondaryButtonTab
				isFirst
				onClick={() => {
					dispatch(
						workflowsHomeActions.setWorkflowsViewPreferences(
							WorkflowsViewTypes.list,
						),
					)
				}}
			>
				<GetIcon icon={Icons.list} fontSize="18px" />
			</SecondaryButtonTab>
			<SecondaryButtonTab
				isLast
				onClick={() => {
					dispatch(
						workflowsHomeActions.setWorkflowsViewPreferences(
							WorkflowsViewTypes.card,
						),
					)
				}}
			>
				<GetIcon icon={Icons.card_view} fontSize="18px" />
			</SecondaryButtonTab>
		</Tabs>
	)
})

WorkflowsViewToggle.displayName = 'WorkflowsViewToggle'
