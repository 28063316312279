import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { Integration } from '../type'
import {
	SalesloftCadenceType,
	SalesloftFieldType,
	SalesloftUserType,
} from './type'

export enum SalesloftEndPoints {
	/** Query */
	getSalesloft = 'getSalesloft',
	getSalesloftManualToken = 'getSalesloftManualToken',
	getSalesloftSignInUrl = 'getSalesloftSignInUrl',
	getSalesloftUsers = 'getSalesloftUsers',
	getSalesloftCadences = 'getSalesloftCadences',
	getSalesloftCadenceSteps = 'getSalesloftCadenceSteps',
	getSalesloftPersonFields = 'getSalesloftPersonFields',
	/** Mutation */
	deleteSalesloft = 'deleteSalesloft',
	updateSalesloftToken = 'updateSalesloftToken',
}

const salesloftApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[SalesloftEndPoints.getSalesloft]: builder.query<Integration, void>({
			query: () => ({
				url: `/integrations?integration=salesloft`,
				responseHandler: responseTransformer,
			}),
		}),

		[SalesloftEndPoints.getSalesloftManualToken]: builder.query<
			{ token: string | null },
			void
		>({
			query: () => ({
				url: `/salesloft/oauth/token`,
				// responseHandler: responseTransformer,
			}),
		}),

		[SalesloftEndPoints.getSalesloftSignInUrl]: builder.query<
			string,
			string | void
		>({
			query: (route) => ({
				url: `/salesloft/oauth/url?route=${route || window.location.pathname}`,
				responseHandler: (response) => response.json().then((data) => data.url),
			}),
		}),
		[SalesloftEndPoints.getSalesloftUsers]: builder.query<
			SalesloftUserType[],
			void
		>({
			query: () => ({
				url: `/salesloft/users`,
			}),
		}),
		[SalesloftEndPoints.getSalesloftCadences]: builder.query<
			SalesloftCadenceType[],
			void
		>({
			query: () => ({
				url: `/salesloft/cadences`,
			}),
		}),
		[SalesloftEndPoints.getSalesloftCadenceSteps]: builder.query<
			SalesloftCadenceType[],
			string | null | undefined
		>({
			query: (cadenceId) => ({
				url: `/salesloft/cadences/${cadenceId}/steps`,
			}),
		}),

		[SalesloftEndPoints.getSalesloftPersonFields]: builder.query<
			SalesloftFieldType[],
			void
		>({
			query: () => ({
				url: `/salesloft/people/fields`,
			}),
		}),

		/** Mutations */
		[SalesloftEndPoints.deleteSalesloft]: builder.mutation<
			{ status: boolean },
			void
		>({
			query: () => ({
				url: `/integrations?integration=salesloft`,
				method: 'DELETE',
			}),
			onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					salesloftApi.util.updateQueryData(
						SalesloftEndPoints.getSalesloft,
						undefined,
						() => {
							return {
								id: null,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),

		[SalesloftEndPoints.updateSalesloftToken]: builder.mutation<void, string>({
			query: (payload) => ({
				url: `/salesloft/oauth/token`,
				method: 'POST',
				body: { token: payload },
			}),
			onQueryStarted: async (token, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					salesloftApi.util.updateQueryData(
						SalesloftEndPoints.getSalesloftManualToken,
						undefined,
						() => {
							return {
								token,
							}
						},
					),
				)
				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),
	}),
})

export const {
	useDeleteSalesloftMutation,
	useGetSalesloftQuery,
	useGetSalesloftSignInUrlQuery,
	useGetSalesloftUsersQuery,
	useGetSalesloftCadencesQuery,
	useGetSalesloftCadenceStepsQuery,
	useGetSalesloftPersonFieldsQuery,
	useGetSalesloftManualTokenQuery,
	useUpdateSalesloftTokenMutation,
} = salesloftApi

export { salesloftApi }
