import {
	ApolloAttributes,
	getApolloAttributesLabelValue,
	getAttributesLabelValue,
	LeadAttributes,
} from '@/app/leads/helper'
import { getStateOptions } from '@/common/utils/getStateOptions'

import { DefaultAttribute } from '../../types/logic'
import {
	allComparators,
	numberComparators,
	stringComparators,
} from './comparators'

export const defaultAttributes: { [attributeId: string]: DefaultAttribute } = {
	// Question type attributes
	'Single Select': {
		key: 'Single Select',
		name: 'Single Select',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
		],
		type: 'question',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: [],
	},
	'Multi Select': {
		key: 'Multi Select',
		name: 'Multi Select',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
		],
		type: 'question',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: [],
	},
	'Email Input': {
		key: 'Email Input',
		name: 'Email Input',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is work email', value: 'NOT_GENERIC' },
			{ label: 'is not work email', value: 'GENERIC' },
		],
		type: 'question',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'Short Text': {
		key: 'Short Text',
		name: 'Short Text',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is empty', value: 'EMPTY' },
			{ label: 'is not empty', value: 'NOT_EMPTY' },
		],
		type: 'question',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},

	Phone: {
		key: 'Phone',
		name: 'Phone',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
		],
		type: 'question',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},

	URL: {
		key: 'URL',
		name: 'URL',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
		],
		type: 'question',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'Long Text': {
		key: 'Long Text',
		name: 'Long Text',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
		],
		type: 'question',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	Number: {
		key: 'Number',
		name: 'Number',
		comparators: numberComparators,
		type: 'question',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},

	// Person data, Clearbit
	'extra.location': {
		key: 'extra.location',
		name: 'Location',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
		],
		type: 'person',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: getAttributesLabelValue(LeadAttributes.person_location),
	},
	'extra.geo.city': {
		key: 'extra.geo.city',
		name: 'City',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
		],
		type: 'person',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: getAttributesLabelValue(LeadAttributes.person_city),
	},
	'extra.geo.state': {
		key: 'extra.geo.state',
		name: 'State',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
		],
		type: 'person',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getAttributesLabelValue(LeadAttributes.person_state),
	},
	'extra.geo.country': {
		key: 'extra.geo.country',
		name: 'Country',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'person',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getAttributesLabelValue(LeadAttributes.person_country),
	},
	'extra.employment.title': {
		key: 'extra.employment.title',
		name: 'Title',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
		],
		type: 'person',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: getAttributesLabelValue(LeadAttributes.title),
	},
	'extra.employment.role': {
		key: 'extra.employment.role',
		name: 'Role',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
		],
		type: 'person',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getAttributesLabelValue(LeadAttributes.role),
	},
	'extra.employment.seniority': {
		key: 'extra.employment.seniority',
		name: 'Seniority',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
		],
		type: 'person',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getAttributesLabelValue(LeadAttributes.seniority),
	},

	// Company data, Clearbit
	'company.name (clearbit)': {
		// TODO: Change this key and improve this in object in general
		key: 'company.name',
		name: 'Company Name',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'company.domain': {
		key: 'company.domain',
		name: 'Website',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
		],
		type: 'company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'company.extra.metrics.employeesRange': {
		key: 'company.extra.metrics.employeesRange',
		name: 'Headcount',
		comparators: [
			{ label: 'greater than', value: '>' },
			{ label: 'greater than or equal to', value: '>=' },
			{ label: 'equal to', value: '=' },
			{ label: 'less than or equal to', value: '<=' },
			{ label: 'less than', value: '<' },
		],
		type: 'company',
		valueIsText: false,
		valueIsSingleSelect: true,
		values: getAttributesLabelValue(LeadAttributes.head_count),
	},
	'company.extra.metrics.employees': {
		key: 'company.extra.metrics.employees',
		name: 'Headcount (Numerical)',
		comparators: [
			{ label: 'greater than', value: '>' },
			{ label: 'greater than or equal to', value: '>=' },
			{ label: 'equal to', value: '=' },
			{ label: 'less than or equal to', value: '<=' },
			{ label: 'less than', value: '<' },
		],
		type: 'company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'company.extra.metrics.raised': {
		key: 'company.extra.metrics.raised',
		name: 'Funding',
		comparators: [
			{ label: 'greater than', value: '>' },
			{ label: 'greater than or equal to', value: '>=' },
			{ label: 'equal to', value: '=' },
			{ label: 'less than or equal to', value: '<=' },
			{ label: 'less than', value: '<' },
		],
		type: 'company',
		valueIsText: false,
		valueIsSingleSelect: true,
		values: getAttributesLabelValue(LeadAttributes.funding),
	},
	'company.extra.metrics.estimatedAnnualRevenue': {
		key: 'company.extra.metrics.estimatedAnnualRevenue',
		name: 'ARR',
		comparators: [
			{ label: 'greater than', value: '>' },
			{ label: 'greater than or equal to', value: '>=' },
			{ label: 'equal to', value: '=' },
			{ label: 'less than or equal to', value: '<=' },
			{ label: 'less than', value: '<' },
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'company',
		valueIsText: false,
		valueIsSingleSelect: true,
		values: getAttributesLabelValue(LeadAttributes.arr),
	},
	'company.extra.category.sector': {
		key: 'company.extra.category.sector',
		name: 'Sector',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
		],
		type: 'company',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getAttributesLabelValue(LeadAttributes.sector),
	},
	'company.extra.category.industryGroup': {
		key: 'company.extra.category.industryGroup',
		name: 'Industry Group',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
		],
		type: 'company',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getAttributesLabelValue(LeadAttributes.industry_group),
	},
	'company.extra.category.industry': {
		key: 'company.extra.category.industry',
		name: 'Industry',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
		],
		type: 'company',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getAttributesLabelValue(LeadAttributes.industry),
	},
	'company.extra.location': {
		key: 'company.extra.location',
		name: 'Location',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
		],
		type: 'company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: getAttributesLabelValue(LeadAttributes.location),
	},
	'company.extra.geo.city': {
		key: 'company.extra.geo.city',
		name: 'City',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
		],
		type: 'company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: getAttributesLabelValue(LeadAttributes.company_city),
	},
	'company.extra.geo.state': {
		key: 'company.extra.geo.state',
		name: 'State',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
		],
		type: 'company',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getAttributesLabelValue(LeadAttributes.company_state),
	},
	'company.extra.geo.country': {
		key: 'company.extra.geo.country',
		name: 'Country',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'company',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getAttributesLabelValue(LeadAttributes.company_country),
	},
	'company.extra.metrics.trafficRank': {
		key: 'company.extra.metrics.trafficRank',
		name: 'Traffic Rank',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
		],
		type: 'company',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getAttributesLabelValue(LeadAttributes.traffic_rank),
	},

	// Company data, Harmonic
	'company.headcount': {
		key: 'company.headcount',
		name: 'Headcount',
		comparators: [
			{ label: 'greater than', value: '>' },
			{ label: 'greater than or equal to', value: '>=' },
			{ label: 'equal to', value: '=' },
			{ label: 'less than or equal to', value: '<=' },
			{ label: 'less than', value: '<' },
		],
		type: 'harmonic.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'company.funding.fundingTotal': {
		key: 'company.funding.fundingTotal',
		name: 'Funding Total',
		comparators: [
			{ label: 'greater than', value: '>' },
			{ label: 'greater than or equal to', value: '>=' },
			{ label: 'equal to', value: '=' },
			{ label: 'less than or equal to', value: '<=' },
			{ label: 'less than', value: '<' },
		],
		type: 'harmonic.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'company.location.country': {
		key: 'company.location.country',
		name: 'Country',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'harmonic.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'company.name': {
		key: 'company.name',
		name: 'Company Name',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'harmonic.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},

	// Apollo company data
	'person.organization.name': {
		key: 'person.organization.name',
		name: 'Company Name',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'apollo.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.organization.primary_domain': {
		key: 'person.organization.primary_domain',
		name: 'Primary Domain',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
		],
		type: 'apollo.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.organization.estimated_num_employees': {
		key: 'person.organization.estimated_num_employees',
		name: 'Headcount (Numerical)',
		comparators: [
			{ label: 'greater than', value: '>' },
			{ label: 'greater than or equal to', value: '>=' },
			{ label: 'equal to', value: '=' },
			{ label: 'less than or equal to', value: '<=' },
			{ label: 'less than', value: '<' },
		],
		type: 'apollo.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.organization.total_funding': {
		key: 'person.organization.total_funding',
		name: 'Funding',
		comparators: [
			{ label: 'greater than', value: '>' },
			{ label: 'greater than or equal to', value: '>=' },
			{ label: 'equal to', value: '=' },
			{ label: 'less than or equal to', value: '<=' },
			{ label: 'less than', value: '<' },
		],
		type: 'apollo.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.organization.annual_revenue': {
		key: 'person.organization.annual_revenue',
		name: 'ARR',
		comparators: [
			{ label: 'greater than', value: '>' },
			{ label: 'greater than or equal to', value: '>=' },
			{ label: 'equal to', value: '=' },
			{ label: 'less than or equal to', value: '<=' },
			{ label: 'less than', value: '<' },
		],
		type: 'apollo.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.organization.industry': {
		key: 'person.organization.industry',
		name: 'Industry',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
		],
		type: 'apollo.company',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getApolloAttributesLabelValue(ApolloAttributes.industry),
	},
	'person.organization.raw_address': {
		key: 'person.organization.raw_address',
		name: 'Raw Address',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
		],
		type: 'apollo.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.organization.city': {
		key: 'person.organization.city',
		name: 'City',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
		],
		type: 'apollo.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.organization.state': {
		key: 'person.organization.state',
		name: 'State',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
		],
		type: 'apollo.company',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getApolloAttributesLabelValue(ApolloAttributes.state),
	},
	'person.organization.country': {
		key: 'person.organization.country',
		name: 'Country',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'apollo.company',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getApolloAttributesLabelValue(ApolloAttributes.country),
	},
	// Apollo person data
	'person.first_name': {
		key: 'person.first_name',
		name: 'First name',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'apollo.person',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.last_name': {
		key: 'person.last_name',
		name: 'Last name',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'apollo.person',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.city': {
		key: 'person.city',
		name: 'City',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
		],
		type: 'apollo.person',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.state': {
		key: 'person.state',
		name: 'State',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
		],
		type: 'apollo.person',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getApolloAttributesLabelValue(ApolloAttributes.state),
	},
	'person.country': {
		key: 'person.country',
		name: 'Country',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'apollo.person',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getApolloAttributesLabelValue(ApolloAttributes.country),
	},
	'person.title': {
		key: 'person.title',
		name: 'Title',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
		],
		type: 'apollo.person',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.seniority': {
		key: 'person.seniority',
		name: 'Seniority',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			// TODO: Get all Apollo values from hidden documentation and make this is any of
		],
		type: 'apollo.person',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	// Amplemarket person data
	'person.first_name (amplemarket)': {
		key: 'person.first_name',
		name: 'First Name',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'amplemarket.person',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.last_name (amplemarket)': {
		key: 'person.last_name',
		name: 'Last Name',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'amplemarket.person',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.name': {
		key: 'person.name',
		name: 'Full Name',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'amplemarket.person',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.title (amplemarket)': {
		key: 'person.title',
		name: 'Job Title',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'amplemarket.person',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.company.name': {
		key: 'person.company.name',
		name: 'Company Name',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'amplemarket.person',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.location_details.city': {
		key: 'person.location_details.city',
		name: 'City',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'amplemarket.person',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'person.location_details.state': {
		key: 'person.location_details.state',
		name: 'State',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'amplemarket.person',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getStateOptions(),
	},
	'person.location_details.country': {
		key: 'person.location_details.country',
		name: 'Country',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'amplemarket.person',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},

	// Amplemarket company data
	'company.department_headcount.sales': {
		key: 'company.department_headcount.sales',
		name: 'Sales Headcount',
		comparators: [
			{ label: 'greater than', value: '>' },
			{ label: 'greater than or equal to', value: '>=' },
			{ label: 'equal to', value: '=' },
			{ label: 'less than or equal to', value: '<=' },
			{ label: 'less than', value: '<' },
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'amplemarket.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'company.website': {
		key: 'company.website',
		name: 'Website',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'amplemarket.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'company.estimated_number_of_employees': {
		key: 'company.estimated_number_of_employees',
		name: 'Total Headcount',
		comparators: [
			{ label: 'greater than', value: '>' },
			{ label: 'greater than or equal to', value: '>=' },
			{ label: 'equal to', value: '=' },
			{ label: 'less than or equal to', value: '<=' },
			{ label: 'less than', value: '<' },
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'amplemarket.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'company.name (amplemarket)': {
		key: 'company.name',
		name: 'Company Name',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'amplemarket.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'company.location_details.city': {
		key: 'company.location_details.city',
		name: 'City',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'amplemarket.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'company.location_details.state': {
		key: 'company.location_details.state',
		name: 'State',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'amplemarket.company',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: getStateOptions(),
	},
	'company.location_details.country': {
		key: 'company.location_details.country',
		name: 'Country',
		comparators: [
			{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
			{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
			{
				label: 'contains any (comma-separated)',
				value: 'CONTAINS_SUBSTRING_CSV',
			},
			{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
			{ label: 'is defined', value: 'EXISTS' },
		],
		type: 'amplemarket.company',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},

	// Owner data
	OwnerId: {
		key: 'OwnerId',
		name: 'Owner',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
		],
		type: 'owner',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: [],
	},
	// Picklist data
	Picklist: {
		key: 'Picklist',
		name: 'Picklist',
		comparators: [
			{ label: 'is any of', value: 'CONTAINS' },
			{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
		],
		type: 'picklist',
		valueIsText: false,
		valueIsSingleSelect: false,
		values: [],
	},
	// Generic attributes (mainly used for dynamically fetched fields)
	'generic.any': {
		key: 'generic.any',
		name: 'Any',
		comparators: allComparators,
		type: 'generic',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'generic.string': {
		key: 'generic.string',
		name: 'String',
		comparators: stringComparators,
		type: 'generic',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
	'generic.number': {
		key: 'generic.number',
		name: 'Number',
		comparators: numberComparators,
		type: 'generic',
		valueIsText: true,
		valueIsSingleSelect: false,
		values: [],
	},
}
