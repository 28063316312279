import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { SubmissionTable, TestSubmissionResponse } from '../types'

export enum SubmissionEndPoints {
	/** Query */
	getSubmissionById = 'getSubmissionById',
	getTestSubmissionResponses = 'getTestSubmissionResponses',
}

const submissionApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[SubmissionEndPoints.getSubmissionById]: builder.query<
			SubmissionTable,
			string | null | undefined
		>({
			query: (submissionId) => ({
				url: `/forms/submissions/${submissionId}`,
				responseHandler: responseTransformer,
			}),
		}),
		[SubmissionEndPoints.getTestSubmissionResponses]: builder.query<
			TestSubmissionResponse[],
			string | null | undefined
		>({
			query: (formId) => ({
				url: `/forms/submissions/test/fetch/${formId}`,
				responseHandler: (response) =>
					response.json().then(({ data }) => data.responses),
			}),
		}),
	}),
})

export const { useGetSubmissionByIdQuery, useGetTestSubmissionResponsesQuery } =
	submissionApi
