import { Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { useState } from 'react'
import { useDeepCompareEffect } from 'react-use'

import { useAppDispatch } from '@/app/hooks'
import {
	useGetAllFormVersionsByIdQuery,
	useGetFormByIdQuery,
} from '@/modules/forms/api'
import { FormTable } from '@/modules/forms/types'
import { useSelectedWorkflow } from '@/modules/workflow/hooks'
import { workflowsActions } from '@/modules/workflow/slice'
import { NavigationId } from '@/modules/workflow/types/navigation'
import { TEXT_ENUM } from '@/styles/components/text'

import { formatVersions, VersionHistoryItem } from '../version-history'

export const OverviewVersionHistory = () => {
	const dispatch = useAppDispatch()
	const workflow = useSelectedWorkflow()?.selectedWorkflow

	const { data: formVersions } = useGetAllFormVersionsByIdQuery(
		String(workflow?.id),
	)
	const { data: draftForm } = useGetFormByIdQuery(String(workflow?.id), {
		skip: !workflow?.id,
	})

	const [allVersions, setAllVersions] = useState<FormTable[]>([])

	useDeepCompareEffect(() => {
		if (formVersions && draftForm) {
			setAllVersions([...formVersions, draftForm])
		}
	}, [formVersions, draftForm])

	const formattedVersions = formatVersions(allVersions)

	const handleViewVersion = (workflowVersion: string) => {
		window.open(
			`/workflows/${workflow?.id}/versions/${workflowVersion}`,
			'_blank',
		)
	}

	return (
		<Flex direction="column" gap={2}>
			<Flex justify="space-between" align="center">
				<Text variant={TEXT_ENUM.semibold14}>Versions</Text>
				<Flex
					color={COLORS.purple[9]}
					align="center"
					gap={2}
					cursor="pointer"
					onClick={() =>
						dispatch(
							workflowsActions.setSelectedSidePanelNavigationId(
								NavigationId.VersionsHistory,
							),
						)
					}
				>
					<Text variant={TEXT_ENUM.medium12} textDecor="underline">
						All Version History
					</Text>
				</Flex>
			</Flex>

			<Flex direction="column" gap={6} mt={2} mb={10}>
				{formattedVersions.slice(0, 3).map((version, idx) => {
					return (
						<VersionHistoryItem
							key={`version-item-${idx}`}
							version={version}
							onViewVersion={handleViewVersion}
						/>
					)
				})}
			</Flex>
		</Flex>
	)
}
