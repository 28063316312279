import { useCallback } from 'react'

import { useLazyGetOutreachUsersQuery } from '@/app/integrations/api/outreach'
import OutreachLogo from '@/assets/logo/outreach.png'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'

export const useGetOutreachMailboxDrpOptions = (): AsyncDrpOptionGenerator => {
	const [getOutreachUsers] = useLazyGetOutreachUsersQuery()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const mailboxes = [
				...(await getOutreachUsers()
					.unwrap()
					.then((data) => data)),
			]

			const mailboxOptions = mailboxes.map((mailbox) => ({
				label: mailbox.attributes.email,
				value: mailbox.id,
				onSelect: (value: any) => {
					const newRfn = {
						refNodeId: null,
						variable: null,
						value,
						label: mailbox.attributes.email,
						icon: OutreachLogo,
					}
					onSelect(newRfn)
				},
			}))

			return [
				{
					label: 'Mailboxes',
					value: mailboxOptions,
					icon: OutreachLogo,
				},
			]
		},
		[getOutreachUsers],
	)
}
