import { useStore } from 'react-redux'

import { CanvasNode, CanvasNodes } from '../types/nodes'
import { useSelectedNode } from './workflow'

export const useGetUpstreamNodes = () => {
	const store = useStore()
	const selectedNode = useSelectedNode()
	const canvasNodes = store.getState().workflows
		.currentCanvasNodes as CanvasNodes
	const nodes = Object.values(canvasNodes || {})
	return {
		getUpstreamNodes: (startNode?: CanvasNode | undefined) =>
			getUpstreamNodes(startNode || selectedNode, nodes),
	}
}

const getUpstreamNodes = (
	startNode: CanvasNode | null,
	nodes: CanvasNode[],
): CanvasNode[] => {
	const upstreamNodeIds = getUpstreamNodeIds(startNode, nodes, [])
	return nodes.filter((n) => upstreamNodeIds.includes(n.id))
}

const getUpstreamNodeIds = (
	startNode: CanvasNode | null,
	nodes: CanvasNode[],
	upstreamNodes: string[],
): string[] => {
	if (!startNode) return upstreamNodes
	const { parentIds } = startNode.data
	const dedupedParentIds = [...new Set(parentIds)]
	dedupedParentIds
		.filter((parentId) => !upstreamNodes.includes(parentId))
		.forEach((_parentId) => {
			const parentId = _parentId.split('&')[0] // removes &true/&false
			upstreamNodes.push(parentId)
			const parentNode = nodes.find((n) => n.id === parentId)
			if (parentNode) {
				upstreamNodes.push(
					...getUpstreamNodeIds(parentNode, nodes, upstreamNodes),
				)
			}
		})
	return [...new Set(upstreamNodes)]
}
