import { Button, Flex } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { useState } from 'react'
import { FONT_SIZES, WEIGHT } from 'ui'

import { PanelInput } from '../../../../../../panel-variants/PanelInput'
import { PanelInputLabel } from '../../../../../../panel-variants/PanelInputLabel'
import { WebhookSectionProps } from '../index'
import { AuthType, AuthTypeButton } from './AuthTypeButton'

export const AuthorizationSection = ({
	selectedNode,
	requestData,
	updateNode,
}: WebhookSectionProps) => {
	const [showPassword, setShowPassword] = useState<boolean>(false)
	const [authType, setAuthType] = useState<AuthType>(
		(requestData.authType as AuthType) || AuthType.None,
	)

	const setNodeAuthType = (authType: AuthType) => {
		if (!selectedNode) return

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: {
					...requestData,
					authType: authType.toLowerCase() as AuthType,
					authorization: {
						refNodeId: null,
						variable: null,
						value: [],
					},
				},
			},
		})
	}

	const updateNodeAuthorization = (newAuth) => {
		if (!selectedNode) return

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: {
					...requestData,
					authorization: newAuth,
				},
			},
		})
	}

	const setAuthToken = (token) => {
		const updated = { ...requestData.authorization }
		updated.value = [token, updated.value[1]]

		updateNodeAuthorization(updated)
	}

	const setAuthUsername = (username) => {
		const updated = { ...requestData.authorization }
		updated.value = [username, updated.value[1]]

		updateNodeAuthorization(updated)
	}

	const setAuthPassword = (password) => {
		const updated = { ...requestData.authorization }
		updated.value = [updated.value[0], password]

		updateNodeAuthorization(updated)
	}

	return (
		<Flex w="100%" direction="column" zIndex={4}>
			<Flex width="100%">
				<AuthTypeButton
					authType={AuthType.None}
					borderRightRadius="none"
					selected={authType === AuthType.None}
					onClick={() => {
						setAuthType(AuthType.None)
						setNodeAuthType(AuthType.None)
					}}
				/>
				<AuthTypeButton
					authType={AuthType.Token}
					borderRadius="none"
					selected={authType === AuthType.Token}
					onClick={() => {
						setAuthType(AuthType.Token)
						setNodeAuthType(AuthType.Token)
					}}
				/>
				<AuthTypeButton
					authType={AuthType.Password}
					borderLeftRadius="none"
					selected={authType === AuthType.Password}
					onClick={() => {
						setAuthType(AuthType.Password)
						setNodeAuthType(AuthType.Password)
					}}
				/>
			</Flex>

			<Flex direction="column" mt={4}>
				{authType === 'token' && (
					<Flex direction="column" mb={4}>
						<PanelInputLabel label="Token value" />
						<PanelInput
							initialValue={requestData.authorization.value[0] || ''}
							placeholder="Token"
							onBlur={(e) => setAuthToken(e.target.value)}
						/>
					</Flex>
				)}

				{authType === 'password' && (
					<Flex direction="column" gap={4}>
						<Flex direction="column">
							<PanelInputLabel label="Login" />
							<PanelInput
								initialValue={requestData.authorization.value[0] || ''}
								placeholder="Username"
								onBlur={(e) => setAuthUsername(e.target.value)}
							/>
						</Flex>
						<Flex direction="column">
							<Flex justify="space-between" align="center">
								<PanelInputLabel label="Password" />
								<Button
									fontWeight={WEIGHT.medium}
									fontSize={FONT_SIZES.xs}
									color={COLORS.purple[9]}
									bg="transparent"
									p={0}
									h="auto"
									_hover={{ bg: 'transparent' }}
									_focus={{ bg: 'transparent' }}
									_active={{ bg: 'transparent' }}
									onClick={() => setShowPassword(!showPassword)}
								>
									{showPassword ? 'Hide' : 'Show'} password
								</Button>
							</Flex>

							<PanelInput
								initialValue={requestData.authorization.value[1] || ''}
								placeholder="Password"
								type={showPassword ? 'text' : 'password'}
								onBlur={(e) => setAuthPassword(e.target.value)}
							/>
						</Flex>
					</Flex>
				)}
			</Flex>
		</Flex>
	)
}
