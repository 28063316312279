import { Flex } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import { GetIcon, Icons } from 'ui'
import { FONT_SIZES } from 'ui'

import { RefNodeValue } from '@/modules/workflow/types/actions'
import { BTN } from '@/styles/components/button'

import { PanelInputLabel } from '../../../panel-variants/PanelInputLabel'
import { AsyncDrpOptionGenerator } from '../../DataReferencePicker'
import { ParameterMapping } from './ParameterMapping'

export type ParametersType = { [id: string]: RefNodeValue }

interface ParameterMapperProps {
	sectionLabel: string
	params: ParametersType
	handleSetParameterKey: (id: string, key: string) => void
	handleSetParameterValue: (id: string, value: RefNodeValue | string) => void
	handleAddParameter: () => void
	handleDeleteParameter: (id: string) => void
}

type Props =
	| (ParameterMapperProps & {
			getInitialDrpOptions: AsyncDrpOptionGenerator
			useDataReferencePicker: true
	  })
	| (ParameterMapperProps & {
			getInitialDrpOptions?: never
			useDataReferencePicker?: false
	  })

export const ParameterMapper = ({
	sectionLabel,
	params,
	useDataReferencePicker,
	getInitialDrpOptions,
	handleSetParameterKey,
	handleSetParameterValue,
	handleAddParameter,
	handleDeleteParameter,
}: Props) => {
	return (
		<Flex direction="column">
			<PanelInputLabel label={sectionLabel} fontSize={FONT_SIZES.xs} />

			{Object.entries(params).map(([id, rfn]) => {
				return (
					<ParameterMapping
						key={id}
						refNodeValue={rfn}
						paramId={id}
						useDataReferencePicker={useDataReferencePicker}
						getInitialDrpOptions={getInitialDrpOptions}
						handleSetParameterKey={handleSetParameterKey}
						handleSetParameterValue={handleSetParameterValue}
						handleDeleteParameter={handleDeleteParameter}
					/>
				)
			})}

			<Button
				variant={BTN.workflows_side_panel}
				leftIcon={<GetIcon icon={Icons.plus} />}
				onClick={handleAddParameter}
			>
				Add Parameter
			</Button>
		</Flex>
	)
}
