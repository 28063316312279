import {
	Button,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
} from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { DateRange } from '@/app/scheduler/types'
import { DateRangePicker as DateRangePickerCalendar } from '@/components/DatePicker/DateRangePicker'
import { BTN } from '@/styles/components/button'

type Props = {
	range?: DateRange | null
	onRangeChange?: (range: DateRange) => void
	onClear?: () => void
	variant?: 'primary' | 'secondary'
	defaultRange?: any
}

// Todo - Copied the older version. Need to refactor this.
export const DateRangePickerPopover = ({
	range,
	onRangeChange,
	onClear,
	variant = 'primary',
	defaultRange,
}: Props) => {
	let label = 'Date Range'

	if (range?.start && range?.end) {
		label = `${range?.start.toLocaleDateString()} - ${range?.end.toLocaleDateString()}`
	}

	return (
		<Popover>
			<PopoverTrigger>
				{variant === 'primary' ? (
					<Button
						border="1px solid"
						borderWidth={range ? '2px' : '1px'}
						borderColor={range ? COLORS.purple[7] : COLORS.background[10]}
						variant={BTN.secondary}
						fontSize="14px"
						boxShadow="none"
						fontWeight={500}
						leftIcon={
							<GetIcon
								icon={Icons.calendar}
								boxSize={4}
								color={COLORS.background[7]}
							/>
						}
						rightIcon={
							range ? (
								<GetIcon
									icon={Icons.close}
									boxSize={4}
									color={COLORS.background[7]}
									onClick={(e) => {
										e.stopPropagation()
										onClear && onClear()
									}}
								/>
							) : (
								<></>
							)
						}
						_hover={{
							borderColor: range ? COLORS.purple[7] : COLORS.background[9],
						}}
					>
						{label}
					</Button>
				) : (
					<Button
						variant={BTN.secondary_v2}
						leftIcon={
							<GetIcon
								icon={Icons.calendar}
								boxSize={3}
								color={COLORS.background[5]}
							/>
						}
						rightIcon={
							range ? (
								<GetIcon
									icon={Icons.close}
									color={COLORS.background[7]}
									onClick={(e) => {
										e.stopPropagation()
										onClear && onClear()
									}}
								/>
							) : (
								<></>
							)
						}
					>
						{label}
					</Button>
				)}
			</PopoverTrigger>
			<PopoverContent p={0}>
				<PopoverBody p={0}>
					<DateRangePickerCalendar
						defaultValue={defaultRange}
						showStartAndEnd={true}
						closeOnSelect={true}
						setter={(range) => onRangeChange && onRangeChange(range)}
					/>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
