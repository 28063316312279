import { Flex } from '@chakra-ui/react'
import { debounce } from 'lodash'
import { ChangeEvent } from 'react'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { DateRange } from '@/app/scheduler/types'
import { DateRangePickerPopover } from '@/common/components/DateRangePicker'
import { SearchInput } from '@/common/components/SearchInput'
import { lastOneWeekRange } from '@/common/utils/dateRange'
import { FormType } from '@/modules/forms/types'

import { workflowLogsActions } from '../slice'
import { selectDateRange } from '../slice/selectors'

interface LogsFiltersPartialProps {
	triggerType: FormType | null
}

export const LogsFiltersPartial = ({
	triggerType,
}: LogsFiltersPartialProps) => {
	const dispatch = useAppDispatch()
	const range = useAppSelector(selectDateRange)
	const placeholder = getSearchPlaceholder(triggerType)

	const handleSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
		dispatch(workflowLogsActions.setSearchQuery(e.target.value))
	}, 500)

	const handleDateRangeChange = (range: DateRange) => {
		dispatch(workflowLogsActions.setDateRange(range))
	}

	const handleClear = () => {
		dispatch(workflowLogsActions.setDateRange(null))
	}

	return (
		<Flex alignItems="center" justifyContent="space-between">
			{triggerType !== FormType.webhook_trigger && (
				<SearchInput placeholder={placeholder} onChange={handleSearch} />
			)}
			<DateRangePickerPopover
				variant="secondary"
				onRangeChange={handleDateRangeChange}
				onClear={handleClear}
				range={range}
				defaultRange={lastOneWeekRange}
			/>
		</Flex>
	)
}

const getSearchPlaceholder = (triggerType: FormType | null): string => {
	switch (triggerType) {
		case FormType.regular:
		case FormType.imported:
		case FormType.meeting_booked_trigger:
		case FormType.tag_assigned_trigger:
			return 'Search lead email...'
		case FormType.hubspot_trigger:
		case FormType.salesforce_object_create_trigger:
		case FormType.salesforce_object_update_trigger:
			return 'Search record name...'
		default:
			return 'Search...'
	}
}
