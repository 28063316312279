import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { Integration } from '../type'
import { CreateSObjectPayload, SalesforceUserType } from './type'

export enum SalesforceEndPoints {
	/** Query */
	getSalesforce = 'getSalesforce',
	getSalesforceSignInUrl = 'getSalesforceSignInUrl',
	getSalesforceUsers = 'getSalesforceUsers',
	getSalesforceFields = 'getSalesforceFields',
	getSalesforceInstanceUrl = 'getSalesforceInstanceUrl',
	/** Mutation */
	createSObject = 'createSObject',
	deleteSalesforce = 'deleteSalesforce',
}

const salesforceApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[SalesforceEndPoints.getSalesforce]: builder.query<Integration, void>({
			query: () => ({
				url: `/integrations?integration=salesforce`,
				responseHandler: responseTransformer,
			}),
		}),

		[SalesforceEndPoints.getSalesforceSignInUrl]: builder.query<
			string,
			{ route: string; sandbox: boolean }
		>({
			query: ({ route, sandbox }) => ({
				url: `/sfdc-auth/url?route=${route}${sandbox ? '&sandbox=true' : ''}`,
				responseHandler: (response) => response.json().then((data) => data.url),
			}),
		}),
		[SalesforceEndPoints.getSalesforceUsers]: builder.query<
			SalesforceUserType[],
			void
		>({
			query: () => ({
				url: `/sfdc-sobjects/users/list`,
			}),
		}),
		[SalesforceEndPoints.getSalesforceFields]: builder.query<
			any[],
			{ type: string; filterOutReadOnlyFields?: boolean }
		>({
			query: ({ type, filterOutReadOnlyFields = false }) => ({
				url: `sfdc-sobjects/${type}/fields`,
				responseHandler: (response) =>
					response.json().then((data) => {
						if (filterOutReadOnlyFields) {
							return data.fields.filter((field) => field.updateable)
						}

						return data.fields
					}),
			}),
		}),
		[SalesforceEndPoints.deleteSalesforce]: builder.mutation<
			{
				status: boolean
				error: any
			},
			void
		>({
			query: () => ({
				url: `/integrations?integration=salesforce`,
				method: 'DELETE',
			}),
			onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					salesforceApi.util.updateQueryData(
						SalesforceEndPoints.getSalesforce,
						undefined,
						() => {
							return {
								id: null,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),
		[SalesforceEndPoints.createSObject]: builder.mutation<
			any,
			{ type: string; payload: CreateSObjectPayload[] }
		>({
			query: ({ type, payload }) => ({
				url: `/sfdc-sobjects/${type}`,
				method: 'POST',
				body: payload,
			}),
		}),
		[SalesforceEndPoints.getSalesforceInstanceUrl]: builder.query<string, void>(
			{
				query: () => ({
					url: `/sfdc-sobjects/instance-url`,
					responseHandler: responseTransformer,
				}),
			},
		),
	}),
})

export const {
	useGetSalesforceQuery,
	useDeleteSalesforceMutation,
	useGetSalesforceSignInUrlQuery,
	useGetSalesforceUsersQuery,
	useGetSalesforceFieldsQuery,
	useLazyGetSalesforceInstanceUrlQuery,
	useLazyGetSalesforceSignInUrlQuery,
	useLazyGetSalesforceQuery, // TODO: Not being used
	useLazyGetSalesforceFieldsQuery,
	useCreateSObjectMutation,
} = salesforceApi

export { salesforceApi }
