import { Center, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { TEXT_ENUM } from '@/styles/components/text'

export const NoWorkflowDueToFilters = () => {
	return (
		<Center flexDir="column" w="full" h="full" justifyContent="center">
			<GetIcon
				icon={Icons.warning_circle}
				color={COLORS.purple[9]}
				boxSize="32px"
				mb={4}
			/>
			<Text variant={TEXT_ENUM.medium14} color={COLORS.background[5]}>
				No workflows found for the selected filters
			</Text>
		</Center>
	)
}
