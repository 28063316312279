import { createSlice } from '@reduxjs/toolkit'

export interface LoginState {
	isSignup: boolean
}

const initialState: LoginState = {
	isSignup: false,
}

export const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		setIsSignup: (state, action) => {
			state.isSignup = action.payload
		},
	},
})

export const loginReducer = loginSlice.reducer
export const loginActions = loginSlice.actions
