import { Flex } from '@chakra-ui/react'
import { Cell } from '@tanstack/react-table'

import { StatusBadge } from '@/common/components/Badge/StatusBadge'

import { WorkflowLog } from '../../../types'

type Props = {
	cell: Cell<WorkflowLog, unknown>
}

export const LogsStatusCellRender = ({ cell }: Props) => {
	const row = cell.row.original

	return (
		<Flex alignItems="center">
			{row.errorsCount > 0 ? (
				<StatusBadge variant="error" text={`Error (${row.errorsCount})`} />
			) : (
				<StatusBadge variant="success" text="Success" />
			)}
		</Flex>
	)
}
