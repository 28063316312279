import { Button, ButtonProps } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { WEIGHT } from 'ui'

import { BTN } from '@/styles/components/button'
import { SHADOWS } from '@/styles/constants'

export enum AuthType {
	None = 'none',
	Token = 'token',
	Password = 'password',
}

interface AuthTypeButtonProps extends ButtonProps {
	authType: AuthType
	onClick: () => void
	selected?: boolean
}

export const AuthTypeButton = ({
	authType,
	onClick,
	selected,
	...props
}: AuthTypeButtonProps) => {
	const authTypeLabel = authType.charAt(0).toUpperCase() + authType.slice(1)

	return (
		<Button
			variant={BTN.workflows_side_panel}
			flexGrow={1}
			fontWeight={WEIGHT.medium}
			bg={selected ? COLORS.background[12] : COLORS.whiteAlpha[12]}
			border={selected ? '1px solid #E6E6E6' : 'none'}
			boxShadow={selected ? 'none' : SHADOWS.button_secondary}
			_hover={{
				border: selected ? '1px solid #E6E6E6' : 'transparent',
			}}
			_active={{
				bg: COLORS.background[12],
				boxShadow: 'none',
				border: '1px solid #E6E6E6',
			}}
			_focus={{
				boxShadow: selected ? 'none' : SHADOWS.button_secondary,
			}}
			onClick={onClick}
			{...props}
		>
			{authTypeLabel}
		</Button>
	)
}
