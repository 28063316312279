import { ClearbitCompany, ClearbitPerson } from '../../clearbit/type'

export enum SystemDefaultStage {
	new = 'new',
	meeting_not_booked = 'meeting_not_booked',
	meeting_booked = 'meeting_booked',
	no_show = 'no_show',
	cancelled = 'cancelled',
}

// Clearbit
interface CompanyType {
	id?: string
	name?: string | null
	domain?: string | null
	logo?: string | null
	founded_year?: string | null
	clearbit_id?: string | null
	extra?: ClearbitCompany | null
}

interface PersonType {
	email: string
	first_name: string | null
	last_name: string | null
	phone: string | null
	clearbit_id: string | null
	company_id: string | null
	created_at: string
	updated_at: string
	twitter_handle: string | null
	linkedin_handle: string | null
	facebook_handle: string | null
	extra?: ClearbitPerson | null
	company: CompanyType
}

/**
 * This is the shape of custom data for a lead.
 * We are saving this data in 'custom' column of type jsonb of leads table.
 */
export type CustomLeadDataType = Partial<PersonType>
