import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'
import { HIDE_SCROLLBAR_CLASS } from '@/utils/constants'

type Props = {
	errors: string[]
}

export const WorkflowErrors = ({ errors }: Props) => {
	return (
		<Flex p={4} flexDir="column" borderTop={Borders.primary}>
			<Text variant={TEXT_ENUM.semibold14}>Errors</Text>
			<VStack
				align="flex-start"
				mt={4}
				maxH="55vh"
				overflowX="scroll"
				gap={2}
				className={HIDE_SCROLLBAR_CLASS}
			>
				{errors.map((error) => (
					<HStack key={error} gap={2}>
						<GetIcon icon={Icons.alert} color={COLORS.orange[9]} />
						<Text variant={TEXT_ENUM.regular14}>{error}</Text>
					</HStack>
				))}
			</VStack>
		</Flex>
	)
}
