import { Icons } from 'ui'

export enum NavigationId {
	Overview = 'overview',
	AddObjects = 'add-objects',
	AiCopilot = 'ai-copilot',
	VersionsHistory = 'versions-history',
	TestWorkflow = 'test-workflow',
}

export type NavItem = {
	id: NavigationId
	title: string
	icon: Icons
}
