import { HStack, Text, Tooltip, VStack } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import dayjs from 'dayjs'
import { GetIcon, Icons } from 'ui'

import { Card } from '@/common/components/Card'
import { TOOLTIP_ENUM } from '@/styles/components/tooltip'

import { LogLevel, WorkflowStepLogContent } from '../types'
import { JsonLogTable } from './JsonLogTable'

type Props = {
	workflowStepLog: WorkflowStepLogContent
}

export const WorkflowStepLog = ({ workflowStepLog }: Props) => {
	const showStepLog =
		workflowStepLog?.logLevel === LogLevel.INFO ||
		workflowStepLog?.logLevel === LogLevel.ERROR

	if (!showStepLog) return null

	return (
		<Card
			w="full"
			gap={1}
			borderColor={
				workflowStepLog?.logLevel === LogLevel.ERROR
					? ColorTokens.log_error_border
					: ''
			}
			bg={
				workflowStepLog?.logLevel === LogLevel.ERROR
					? ColorTokens.log_error_bg
					: 'white'
			}
			color={
				workflowStepLog?.logLevel === LogLevel.ERROR
					? ColorTokens.log_error_text
					: COLORS.background[2]
			}
		>
			<Tooltip
				label={`Timestamp: ${dayjs(workflowStepLog?.timestamp).format(
					'YYYY-MM-DD HH:mm:ss.SSS',
				)}`}
				variant={TOOLTIP_ENUM.workflows_side_panel}
				placement="right-start"
				openDelay={300}
				isDisabled={!workflowStepLog?.timestamp}
				closeOnScroll={true}
			>
				<HStack p={4}>
					{workflowStepLog?.logLevel === LogLevel.ERROR && (
						<GetIcon icon={Icons.warning_circle} color="currentcolor" />
					)}
					<VStack alignItems="flex-start" gap={2} w="full">
						<Text
							fontSize="14px"
							whiteSpace="pre-wrap"
							overflowWrap="break-word"
							wordBreak="break-word"
							sx={{
								hyphens: 'auto',
								WebkitHyphens: 'auto',
								msHyphens: 'auto',
							}}
						>
							{workflowStepLog?.message}
						</Text>
						{workflowStepLog?.json && (
							<JsonLogTable json={workflowStepLog?.json} />
						)}
					</VStack>
				</HStack>
			</Tooltip>
		</Card>
	)
}
