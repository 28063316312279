import { useAppSelector } from '@/app/hooks'
import { FormType } from '@/modules/forms/types'

import { LOGS_COUNT_PER_PAGE } from '../constant'
import {
	selectCurrentPage,
	selectDateRange,
	selectSearchQuery,
} from '../slice/selectors'
import { WorkflowLogsQueryVariables } from '../types/query-variables'

export const useBuildWorkflowLogsVariables = (
	formId?: string,
	triggerType?: FormType,
): WorkflowLogsQueryVariables => {
	const savedCurrentPage = useAppSelector(selectCurrentPage)
	const currentPage = savedCurrentPage || 1
	const searchQuery = useAppSelector(selectSearchQuery)
	const dateRange = useAppSelector(selectDateRange)
	const start = dateRange?.start
		? new Date(dateRange?.start).toISOString()
		: null

	const end = dateRange?.end ? new Date(dateRange?.end).toISOString() : null

	const from = (currentPage - 1) * LOGS_COUNT_PER_PAGE
	const to = currentPage * LOGS_COUNT_PER_PAGE - 1
	return {
		payload: {
			formId,
			searchQuery,
			triggerType,
			dateRange: start && end ? { start, end } : undefined,
			pagination: {
				from,
				to,
			},
		},
	}
}
