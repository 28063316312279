import {
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { Radii } from '@/styles/borders'
import { BTN } from '@/styles/components/button'
import { TEXT_ENUM } from '@/styles/components/text'

import { PanelCloseButton } from '../../panel-variants/PanelCloseButton'

interface WarningModalProps {
	isOpen: boolean
	warningMessage?: string
	warningSubtext?: string
	icon?: Icons
	handleConfirm: () => void
	handleCancel: () => void
}

export const WarningModal = ({
	isOpen,
	warningMessage,
	warningSubtext,
	icon,
	handleConfirm,
	handleCancel,
}: WarningModalProps) => {
	return (
		<Modal isOpen={isOpen} onClose={handleCancel}>
			<ModalOverlay />
			<ModalContent borderRadius="12px">
				<ModalHeader
					height="max-content"
					border="none"
					justifyContent="flex-end"
				>
					<PanelCloseButton onClick={handleCancel} />
				</ModalHeader>
				<ModalBody padding="0 20px" mb={8}>
					<Flex direction="column" gap={4} align="center">
						{icon && (
							<GetIcon
								icon={icon}
								bgColor={COLORS.red[4]}
								color={ColorTokens.text_error}
								boxSize={10}
								borderRadius={Radii.lg}
								padding={1}
							/>
						)}
						<Flex direction="column" gap={1} align="center">
							<Text variant={TEXT_ENUM.semibold16} color={COLORS.background[2]}>
								{warningMessage || 'Are you sure?'}
							</Text>
							<Text
								variant={TEXT_ENUM.regular14}
								color={COLORS.background[4]}
								textAlign="center"
							>
								{warningSubtext || 'You can’t undo this action.'}
							</Text>
						</Flex>
					</Flex>
				</ModalBody>
				<ModalFooter gap={2} justifyContent="flex-end">
					<Button
						variant={BTN.workflows_side_panel}
						height="40px"
						onClick={handleCancel}
					>
						Cancel
					</Button>
					<Button
						variant={BTN.danger_secondary}
						borderRadius={Radii.lg}
						py={2}
						px={2.5}
						onClick={handleConfirm}
					>
						Confirm
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
