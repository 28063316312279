import { Flex } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'

import { HOME_SIDEBAR_TRANSITION } from '@/common/constants/sidebar'
import { useSidebarWidth } from '@/common/hooks/useSidebarWidth'

type Props = {
	children: React.ReactNode
}

export const SidebarLayout = ({ children }: Props) => {
	const sidebarWidth = useSidebarWidth()
	return (
		<Flex
			flexDir="column"
			bg={ColorTokens.white}
			h="100vh"
			w={sidebarWidth}
			flexShrink={0}
			justifyContent="space-between"
			borderRightWidth="1px"
			borderColor={COLORS.gray[4]}
			transition={HOME_SIDEBAR_TRANSITION}
		>
			{children}
		</Flex>
	)
}
