import { Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { getFullUrl } from 'shared-utils'

import { TEXT_ENUM } from '@/styles/components/text'

import { WorkflowLogLead } from '../types'

type Props = {
	lead?: WorkflowLogLead | null
}

export const LeadTitle = ({ lead }: Props) => {
	if (lead?.title && lead?.company) {
		return (
			<Text variant={TEXT_ENUM.regular14} color={COLORS.background[4]}>
				{lead?.title} at <LeadCompany lead={lead} />
			</Text>
		)
	}

	if (!lead?.title && !lead?.company) {
		return (
			<Text variant={TEXT_ENUM.regular14} color={COLORS.background[4]}>
				{lead?.email}
			</Text>
		)
	}

	if (lead?.title && !lead?.company) {
		return (
			<Text variant={TEXT_ENUM.regular14} color={COLORS.background[4]}>
				{lead?.title}
			</Text>
		)
	}

	if (!lead?.title && lead?.company) {
		return (
			<Text variant={TEXT_ENUM.regular14} color={COLORS.background[4]}>
				<LeadCompany lead={lead} />
			</Text>
		)
	}

	return null
}

const LeadCompany = ({ lead }: Props) => {
	if (lead?.website) {
		return (
			<a href={getFullUrl(lead.website)} target="_blank" rel="noreferrer">
				<Text as="span" color={COLORS.purple[9]}>
					{lead?.company}
				</Text>
			</a>
		)
	}

	return <>{lead?.company || '-'}</>
}
