import { Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { components, SingleValueProps } from 'react-select'

import { TEXT_ENUM } from '@/styles/components/text'

import { StepLogo } from '../../StepLogo'
import { WorkflowSelectOption } from '..'

export const SingleValue = (props: SingleValueProps<any, boolean, any>) => {
	const data: WorkflowSelectOption = props.data

	return (
		<components.SingleValue {...props}>
			<Flex justify="flex-start" align="center">
				<StepLogo logo={data?.logo} icon={data?.icon} bg={data?.iconBg} />
				<Flex direction="column" ml={4}>
					<Text
						variant={TEXT_ENUM.xs}
						fontWeight="semibold"
						css={{ userSelect: 'none' }}
						color={COLORS.gray[9]}
					>
						{data.subLabel}
					</Text>
					<Text
						variant={TEXT_ENUM.sm}
						fontWeight="semibold"
						css={{ userSelect: 'none' }}
						color={COLORS.gray[12]}
					>
						{data.label}
					</Text>
				</Flex>
			</Flex>
		</components.SingleValue>
	)
}
