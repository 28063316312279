import { useCallback } from 'react'

import { useAppSelector } from '@/app/hooks'
import SalesforceLogo from '@/assets/logo/salesforce-step.svg'
import { FormType } from '@/modules/forms/types'
import { AsyncDrpOptionGenerator } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker/components/Option'
import { selectSalesforceFields } from '@/modules/workflow/slice/selectors'
import { FieldRefNodeValue } from '@/modules/workflow/types/actions'

import { useAllSalesforceFields } from '../../../useAllSalesforceFields'
import { useSelectedWorkflow } from '../../../workflow'

export const useGetSalesforceTriggerDrpOptions =
	(): AsyncDrpOptionGenerator => {
		const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
		const triggerType = selectedWorkflow?.type
		const objectType = selectedWorkflow?.meta?.trigger_config?.object

		const getAllSalesforceFields = useAllSalesforceFields()
		const fieldsInStore = useAppSelector(selectSalesforceFields)

		return useCallback(
			async ({
				onSelect,
			}: {
				onSelect: (value: any) => void
			}): Promise<DataReferencePickerOption[]> => {
				const fields = fieldsInStore || (await getAllSalesforceFields())

				const fieldOptions =
					fields[objectType]?.map((field, idx) => ({
						label: field.label,
						value: field,
						onSelect: (value: any) => {
							const refNodeId = '0'
							const variable = `$.triggerRecord.${field.name}`

							let fetchMappedUserIntegration: 'salesforce' | null = null
							if (field.name === 'OwnerId') {
								fetchMappedUserIntegration = 'salesforce'
							}

							const newRfn: FieldRefNodeValue = {
								refNodeId,
								variable,
								value: null,
								label: value.label,
								dataType: value.type,
								i: idx,
								...(fetchMappedUserIntegration
									? { fetchMappedUserIntegration }
									: {}),
								icon: SalesforceLogo,
							}

							onSelect(newRfn)
						},
					})) || []

				return [
					{
						label: `${
							triggerType === FormType.salesforce_object_create_trigger
								? 'Created'
								: 'Updated'
						} ${objectType}`,
						icon: SalesforceLogo,
						value: fieldOptions,
					},
				]
			},
			[fieldsInStore, getAllSalesforceFields, objectType, triggerType],
		)
	}
