import { useCallback } from 'react'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { settleAllOptionGenerators } from '../../../utils/settleAllOptionGenerators'
import { useGetMemberDrpOptions } from '../generators/useGetMemberOptions'
import { useGetQueueDrpOptions } from '../generators/useGetQueueDrpOptions'
import { useGetOwnerDrpOptions } from './useGetOwnerDrpOptions'

export const useGetDisplaySchedulerDrpOptions = (): AsyncDrpOptionGenerator => {
	const getOwnerDrpOptions = useGetOwnerDrpOptions()
	const getQueueDrpOptions = useGetQueueDrpOptions()
	const getMemberDrpOptions = useGetMemberDrpOptions()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const optionGenerators = [
				getOwnerDrpOptions,
				getQueueDrpOptions,
				getMemberDrpOptions,
			]

			return await settleAllOptionGenerators(optionGenerators, onSelect)
		},
		[getMemberDrpOptions, getQueueDrpOptions, getOwnerDrpOptions],
	)
}
