import { RefNodeValue } from './actions'

export interface ConditionBranch {
	id: string
	name: string
	conditionBlocks: Array<ConditionBlock>
	resultChildId: string | null
	elseChildId: string | null
}

export type ResultOrElse = Extract<
	keyof ConditionBranch,
	'resultChildId' | 'elseChildId'
>

export interface ConditionBlock {
	// chained between them by OR
	id: string
	conditions: Array<Condition>
}

export interface Condition {
	// chained between them by AND
	id: string | null
	parameter: RefNodeValue | null
	comparator: string | null
	value: RefNodeValue | null
}

export const DefaultIfElseCondition = {
	id: null,
	parameter: null,
	comparator: null,
	value: null,
}

export interface LogicStepDetails {
	branches: Array<ConditionBranch>
}

export interface DefaultAttribute {
	key: string
	name: string
	comparators: { label: string; value: string }[]
	type:
		| 'person'
		| 'company'
		| 'question'
		| 'harmonic.company'
		| 'apollo.company'
		| 'apollo.person'
		| 'amplemarket.company'
		| 'amplemarket.person'
		| 'owner'
		| 'picklist'
		| 'generic'
	valueIsText: boolean
	valueIsSingleSelect: boolean
	values: { label: string; value: string }[]
}
