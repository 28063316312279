import { Icons } from '@ds/Icons'
import { useCallback } from 'react'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { settleAllOptionGenerators } from '../../../utils/settleAllOptionGenerators'
import { useGetCrmOwnerDrpOptions } from '../generators/useGetCrmOwnerDrpOptions'
import { useGetMeetingOwnerDrpOptions } from '../generators/useGetMeetingOwnerDrpOptions'
import { useGetRoundRobinAssigneeDrpOptions } from '../generators/useGetRoundRobinAssigneeDrpOptions'

export const useGetOwnerDrpOptions = (): AsyncDrpOptionGenerator => {
	const getMeetingOwnerDrpOptions = useGetMeetingOwnerDrpOptions()
	const getRoundRobinAssigneeDrpOptions = useGetRoundRobinAssigneeDrpOptions()
	const getCrmOwnerDrpOptions = useGetCrmOwnerDrpOptions()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const optionGenerators = [
				getMeetingOwnerDrpOptions,
				getRoundRobinAssigneeDrpOptions,
				getCrmOwnerDrpOptions,
			]

			return [
				{
					label: 'Owners from Workflow steps',
					value: await settleAllOptionGenerators(optionGenerators, onSelect),
					icon: Icons.workflow_action,
				},
			]
		},
		[
			getCrmOwnerDrpOptions,
			getMeetingOwnerDrpOptions,
			getRoundRobinAssigneeDrpOptions,
		],
	)
}
