import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/app/store'

export const selectSelectedViewId = (state: RootState) =>
	state.leads.selectedViewId

export const selectViewName = (state: RootState) => {
	return state.leads.drafts[state.leads.selectedViewId]?.name || 'All Leads'
}

export const selectDraftViews = createSelector(
	(state: RootState) => state.leads.drafts,
	(views) => Object.values(views),
)

export const selectDraftView = (state: RootState) => {
	return state.leads.drafts[selectSelectedViewId(state)]
}

export const selectDraftDateFilters = (state: RootState) =>
	selectDraftView(state)?.date_filter

export const selectPage = (state: RootState) => {
	return state.leads.page
}

export const selectDraftAttributesFilters = (state: RootState) =>
	selectDraftView(state)?.attribute_filters

export const selectDraftsStageFilters = () =>
	createSelector(
		(state: RootState) => selectDraftView(state)?.stage_filters,
		(filters) => filters,
	)

export const selectDraftHiddenFields = (state: RootState) =>
	selectDraftView(state)?.hidden_fields

export const selectDraftGroupBy = (state: RootState) =>
	selectDraftView(state)?.group_by

export const selectSearchQuery = (state: RootState) => {
	return state.leads.searchQuery
}

export const selectDraftSorting = (state: RootState) =>
	selectDraftView(state)?.sortingState

export const selectTableLeadsPayload = createSelector(
	(state: RootState) => state.leads.tableLeads,
	(payload) => payload,
)

export const selectTableLeadsCount = (state: RootState) =>
	state.leads.tableLeadsCount

//<----------------- Kanban Board ----------------->//

export const selectLeadsByStage = (state: RootState, stageId: string) => {
	return state.leads.stagesLeads[stageId]?.leads || []
}

export const selectLeadsCountByStage = (state: RootState, stageId: string) => {
	return state.leads.stagesLeads[stageId]?.count
}

export const selectLeadsViewPreferences = (state: RootState) => {
	return state.leads.leadsViewPreferences
}

export const selectPagesForStage = (state: RootState, stage: string) => {
	return state.leads.stagesPage[stage]
}
