import { Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'

import { PanelInput } from '@/modules/workflow/components/side-panel/panel-variants/PanelInput'
import { PanelInputLabel } from '@/modules/workflow/components/side-panel/panel-variants/PanelInputLabel'
import {
	useCurrentNodeData,
	useUpdateWorkflowNode,
} from '@/modules/workflow/hooks'
import { useGetDefaultDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetDefaultDrpOptions'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import {
	Default_DisplayScheduler,
	RefNodeValue,
} from '@/modules/workflow/types/actions'

import { RedirectUrlParamsSection } from './RedirectUrlParamsSection'
import { DEFAULT_SCHEDULER_WF_STEP_DETAILS } from './step-detail'

export const RedirectOnSchedulerClose = () => {
	const getInitialDrpOptions = useGetDefaultDrpOptions()
	const updateNode = useUpdateWorkflowNode()

	const selectedNode = useSelectedNode()
	const stepDetails = selectedNode?.data
		?.stepDetails as Default_DisplayScheduler

	const { getCurrentNodeData } = useCurrentNodeData(selectedNode)

	const redirectUrlParams = stepDetails?.redirectUrlParams || {}
	const redirectUrlOnMeetingNotBookedParams =
		stepDetails?.redirectUrlOnMeetingNotBookedParams || {}

	const updateNodeStepDetails = (newStepDetails: Default_DisplayScheduler) => {
		if (!selectedNode) return

		const currentNodeData = getCurrentNodeData()

		updateNode({
			...currentNodeData,
			data: {
				...currentNodeData.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const handleOnBlurUrlMeetingBooked = (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		const currentStepDetails = getCurrentNodeData().data.stepDetails

		const newStepDetails = (currentStepDetails ||
			cloneDeep(DEFAULT_SCHEDULER_WF_STEP_DETAILS)) as Default_DisplayScheduler

		newStepDetails.redirectUrl = {
			refNodeId: null,
			variable: null,
			value: e.target.value,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleOnBlurUrlMeetingNotBooked = (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		const currentStepDetails = getCurrentNodeData().data.stepDetails

		const newStepDetails = (currentStepDetails ||
			cloneDeep(DEFAULT_SCHEDULER_WF_STEP_DETAILS)) as Default_DisplayScheduler

		newStepDetails.redirectUrlOnMeetingNotBooked = {
			refNodeId: null,
			variable: null,
			value: e.target.value,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const updateNodeMeetingBookedParams = (newUrlParams: {
		[key: string]: RefNodeValue
	}) => {
		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Default_DisplayScheduler

		updateNodeStepDetails({
			...currentStepDetails,
			redirectUrlParams: newUrlParams,
		})
	}

	const updateNodeMeetingNotBookedParams = (newUrlParams: {
		[key: string]: RefNodeValue
	}) => {
		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Default_DisplayScheduler

		updateNodeStepDetails({
			...currentStepDetails,
			redirectUrlOnMeetingNotBookedParams: newUrlParams,
		})
	}

	return (
		<Flex w="100%" direction="column" gap={8}>
			<Flex direction="column" gap={4}>
				<Flex direction="column">
					<PanelInputLabel label="Redirect URL when meeting is booked" />
					<PanelInput
						key={selectedNode?.id + 'Meeting booked'}
						initialValue={stepDetails?.redirectUrl?.value ?? ''}
						placeholder="URL"
						onBlur={handleOnBlurUrlMeetingBooked}
					/>
				</Flex>
			</Flex>
			<RedirectUrlParamsSection
				getInitialDrpOptions={getInitialDrpOptions}
				params={redirectUrlParams}
				updateKey="redirectUrlParams"
				getCurrentNodeData={getCurrentNodeData}
				updateNodeParams={updateNodeMeetingBookedParams}
			/>

			<Flex direction="column" gap={4}>
				<Flex direction="column">
					<PanelInputLabel label="Redirect URL when meeting is not booked" />
					<PanelInput
						key={selectedNode?.id + 'Meeting not booked'}
						initialValue={
							stepDetails?.redirectUrlOnMeetingNotBooked?.value ?? ''
						}
						placeholder="URL"
						onBlur={handleOnBlurUrlMeetingNotBooked}
					/>
				</Flex>
				<RedirectUrlParamsSection
					getInitialDrpOptions={getInitialDrpOptions}
					params={redirectUrlOnMeetingNotBookedParams}
					updateKey="redirectUrlOnMeetingNotBookedParams"
					getCurrentNodeData={getCurrentNodeData}
					updateNodeParams={updateNodeMeetingNotBookedParams}
				/>
			</Flex>
		</Flex>
	)
}
