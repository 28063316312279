import { Flex } from '@chakra-ui/react'
import { SingleValue } from 'react-select'

import { useGetHubspotPropertiesQuery } from '@/app/integrations/api/hubspot'
import { useUpdateWorkflowNode } from '@/modules/workflow/hooks'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import {
	Hubspot_CreateRecord,
	HubspotEntities,
} from '@/modules/workflow/types/hubspot'

import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../../../panel-variants/PanelSelect'
import { FieldMapper } from '../../FieldMapper'

export const HubSpotCreateRecord = () => {
	const updateNode = useUpdateWorkflowNode()

	const selectedNode = useSelectedNode()
	const stepDetails = selectedNode?.data.stepDetails as Hubspot_CreateRecord

	const { data: propertiesData, isLoading: isPropertiesDataLoading } =
		useGetHubspotPropertiesQuery(
			{ type: stepDetails?.type?.value || '' },
			{
				skip: !stepDetails?.type?.value,
			},
		)
	const properties = Array.isArray(propertiesData) ? propertiesData : []

	const recordTypeOptions = Object.values(HubspotEntities).map((type) => ({
		label: type,
		value: type,
	}))

	const handleSelectRecordType = (
		entityTypeOp: SingleValue<{
			label: HubspotEntities
			value: HubspotEntities
		}>,
	) => {
		if (!selectedNode || !entityTypeOp) return

		const newStepDetails: Hubspot_CreateRecord = {
			type: {
				refNodeId: null,
				variable: null,
				value: entityTypeOp.value,
			},
			insertHubspotProperties: {},
		}

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const recordTypeValue = recordTypeOptions.find(
		(op) => op.value === stepDetails?.type?.value,
	)

	return (
		<Flex w="100%" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel label="Type" />
				<PanelSelect
					placeholder="Select a record type"
					value={recordTypeValue || null}
					options={recordTypeOptions}
					onChange={handleSelectRecordType}
				/>
			</Flex>

			{stepDetails?.type && (
				<FieldMapper
					integration="HubSpot"
					fields={properties}
					isDataLoading={isPropertiesDataLoading}
				/>
			)}
		</Flex>
	)
}
