import {
	Hubspot_CreateRecord,
	Hubspot_MatchRecord,
	Hubspot_MergeRecord,
} from './hubspot'
import { LogicStepDetails } from './logic'
import {
	Salesforce_ConvertLead,
	Salesforce_CreateRecord,
	Salesforce_MatchRecord,
	Salesforce_MergeRecord,
} from './salesforce'
import { TimeUnitConfig } from './time-units'

export enum RefNodeModes {
	custom = 'custom',
	advanced = 'advanced',
}

export interface RefNodeValue {
	refNodeId: string | null // the node/data being referenced
	variable: string | null // used to reference a value of refNodeId, e.g. $.id
	value: any | null // a raw value if ref is unused; typically string but can be any
	mode?: RefNodeModes | null
}

export interface Default_AddToSequence {
	sequenceId: RefNodeValue
}
export interface Default_AddTag {
	tagId: RefNodeValue
}

export interface Default_RedirectToUrl {
	url: RefNodeValue
	urlParams: { [id: string]: RefNodeValue }
}

export interface Default_DisplayCalendly {
	url: RefNodeValue
	inline: boolean | null
}

export interface Default_DisplayScheduler {
	eventId: RefNodeValue
	objectId: LabeledRefNodeValue
	type: RefNodeValue
	fallbackObjectId?: LabeledRefNodeValue | null
	fallbackType?: RefNodeValue | null
	resultChildId: string | null
	elseChildId: string | null
	redirectUrl: RefNodeValue | null
	redirectUrlOnMeetingNotBooked: RefNodeValue | null
	redirectUrlParams: { [id: string]: RefNodeValue } | null
	redirectUrlOnMeetingNotBookedParams: { [id: string]: RefNodeValue } | null
	redirectUrlDisplay: RefNodeValue | null
	language: RefNodeValue | null
	delaySeconds: RefNodeValue | null
	additionalAttendees?: LabeledRefNodeValue[] | null
}

export interface Default_Route {
	queueId: RefNodeValue
}

export interface Default_TimeDelay {
	delaySeconds: RefNodeValue
	units: TimeUnitConfig
}

export interface Zoom_AddToWebinar {
	webinar: LabeledRefNodeValue
	insertRegistrantFields: {
		[registrantFieldId: string]: FieldRefNodeValue
	}
}

export interface FetchRefNodeValue extends RefNodeValue {
	fetchEmailFromIntegration?: 'salesforce' | 'hubspot' // Deprecated
	fetchEmailFromIntegrationField?: string // Deprecated
	fetchMappedUserIntegration?: string
}

export interface LabeledRefNodeValue extends FetchRefNodeValue {
	label: string | null
	icon?: string | null
}

export interface FieldRefNodeValue extends LabeledRefNodeValue {
	dataType: string | null
	i: number
}

export interface Outreach_StartSequence {
	sequence: LabeledRefNodeValue
	mailbox: LabeledRefNodeValue
	insertProspectFields: {
		[prospectFieldId: string]: FieldRefNodeValue
	}
}

export interface Salesloft_StartCadence {
	cadence: LabeledRefNodeValue
	user: LabeledRefNodeValue | null
	startStep: LabeledRefNodeValue | null
	insertPersonFields: {
		[personFieldId: string]: FieldRefNodeValue
	}
}

export interface Apollo_StartSequence {
	sequenceId: LabeledRefNodeValue
	sendEmailId: LabeledRefNodeValue
	insertContactFields: {
		[contactFieldId: string]: FieldRefNodeValue
	}
}

export interface Clearbit_Enrich {
	email: LabeledRefNodeValue
}

export interface Apollo_Enrich {
	email: LabeledRefNodeValue
}

export interface Slack_SendMessage {
	recipients: LabeledRefNodeValue[]
	message: RefNodeValue
}

export interface Ai_Prompt {
	model: RefNodeValue
	prompt: RefNodeValue
}

export interface Amplemarket_Enrich {
	email: LabeledRefNodeValue
}

export interface Amplemarket_StartInboundSmartAction {
	id: RefNodeValue
	userEmail: LabeledRefNodeValue
	insertActionFields: {
		[actionFieldId: string]: FieldRefNodeValue
	}
}

export interface Default_HTTPPostRequest {
	url: RefNodeValue
	urlParams: { [id: string]: RefNodeValue }
	headers: { [id: string]: RefNodeValue }
	body: { [id: string]: RefNodeValue }
	authType: 'none' | 'token' | 'password' // UI thing
	authorization: RefNodeValue // just value, string for type token, string[] for type password
	method: RefNodeValue
	testRequestResponse: Record<string, unknown> | null
}

export interface Default_Reminder {
	reminderId: RefNodeValue
}

export type StepDetails =
	| Default_AddTag
	| Default_DisplayScheduler
	| Default_AddTag
	| Default_RedirectToUrl
	| Default_DisplayCalendly
	| Default_Route
	| Default_AddToSequence
	| Default_TimeDelay
	| Salesforce_CreateRecord
	| Salesforce_MergeRecord
	| Salesforce_MatchRecord
	| Salesforce_ConvertLead
	| Hubspot_CreateRecord
	| Hubspot_MergeRecord
	| Hubspot_MatchRecord
	| Outreach_StartSequence
	| Salesloft_StartCadence
	| Slack_SendMessage
	| Clearbit_Enrich
	| LogicStepDetails
	| Default_HTTPPostRequest
	| Zoom_AddToWebinar
	| Ai_Prompt
	| Apollo_Enrich
	| Amplemarket_Enrich
	| Amplemarket_StartInboundSmartAction
	| Record<string, unknown> // for default value
	| Default_Reminder
	| null
