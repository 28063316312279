export const MEETING_DYNAMIC_FIELDS = [
	{
		label: 'meeting owner name',
		value: 'member_name',
		type: 'meeting_fields',
	},
	{
		label: 'meeting owner email',
		value: 'member_email',
		type: 'meeting_fields',
	},
	{
		label: 'meeting date',
		value: 'startDateTime_date',
		type: 'meeting_fields',
	},
	{
		label: 'meeting time',
		value: 'startDateTime_time',
		type: 'meeting_fields',
	},
]
