import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { Integration, ZoomClient } from '../type'
import { ZoomWebinarData } from './type'

export enum ZoomEndpoints {
	getZoom = 'getZoom',
	getZoomSignInUrl = 'getZoomSignInUrl',
	getZoomWebinars = 'getZoomWebinars',
	deleteZoom = 'deleteZoom',

	// Old
	_getZoom = '_getZoom',
	updateZoom = 'updateZoom',
	deleteZoomApiKey = 'deleteZoomApiKey',
}

const zoomApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[ZoomEndpoints.getZoom]: builder.query<Integration, void>({
			query: () => ({
				url: `/integrations?integration=zoom`,
				responseHandler: responseTransformer,
			}),
		}),
		[ZoomEndpoints.getZoomSignInUrl]: builder.query<string, string>({
			query: (route) => ({
				url: `/zoom/auth/url?route=${route}`,
				responseHandler: (response) => response.json().then((data) => data.url),
			}),
		}),
		[ZoomEndpoints.getZoomWebinars]: builder.query<ZoomWebinarData[], void>({
			query: () => ({
				url: `/zoom/meeting/webinars`,
				responseHandler: responseTransformer,
			}),
		}),
		/** Mutation */
		[ZoomEndpoints.deleteZoom]: builder.mutation<{ status: boolean }, void>({
			query: () => ({
				url: `/integrations?integration=zoom`,
				method: 'DELETE',
			}),
			onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					zoomApi.util.updateQueryData(ZoomEndpoints.getZoom, undefined, () => {
						return {
							id: null,
						}
					}),
				)

				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),

		// Old
		[ZoomEndpoints._getZoom]: builder.query<
			{
				client_id: string | null
				client_secret: string | null
				app_account_id: string | null
			},
			void
		>({
			query: () => ({
				url: `/zoom/client/details`,
				responseHandler: responseTransformer,
			}),
		}),
		[ZoomEndpoints.updateZoom]: builder.mutation<
			void,
			{ zoomClient: ZoomClient }
		>({
			query: (payload) => ({
				url: `/zoom/client/details`,
				method: 'POST',
				body: {
					...payload,
				},
			}),
			onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					zoomApi.util.updateQueryData(
						ZoomEndpoints._getZoom,
						undefined,
						() => {
							return {
								client_id: args.zoomClient.clientId,
								client_secret: args.zoomClient.clientSecret,
								app_account_id: args.zoomClient.appAccountId,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),
	}),
})

export const {
	useGetZoomQuery,
	useGetZoomSignInUrlQuery,
	useLazyGetZoomSignInUrlQuery,
	useGetZoomWebinarsQuery,
	useDeleteZoomMutation,
	useUpdateZoomMutation,
	use_getZoomQuery,
} = zoomApi

export { zoomApi }
