import { ReactRenderer } from '@tiptap/react'
import { SuggestionOptions } from '@tiptap/suggestion'
import tippy from 'tippy.js'

import MentionList from './MentionList'

const suggestion = (
	items: { label: string; value: string }[],
	useDrp: boolean,
): Omit<SuggestionOptions<any>, 'editor'> => {
	return {
		char: '{{',
		items: ({ query }: { query: any }) => {
			return items.filter((item) =>
				item.label.toLowerCase().startsWith(query.toLowerCase()),
			)
			// .slice(0, 5)
		},

		render: () => {
			let reactRenderer: ReactRenderer<any, any>
			let popup: any

			return {
				onStart: (props: any) => {
					reactRenderer = new ReactRenderer(MentionList, {
						props: { ...props, useDrp },
						editor: props.editor,
					})

					if (!props.clientRect) {
						return
					}

					popup = tippy('body', {
						getReferenceClientRect: props.clientRect,
						appendTo: () => document.body,
						content: reactRenderer?.element,
						showOnCreate: true,
						interactive: true,
						trigger: 'manual',
						placement: 'bottom-start',
					})
				},

				onUpdate: (props: any) => {
					reactRenderer?.updateProps(props)

					if (!props.clientRect) {
						return
					}

					popup?.[0].setProps({
						getReferenceClientRect: props.clientRect,
					})
				},

				onKeyDown: (props: any) => {
					if (props.event.key === 'Escape') {
						popup?.[0]?.hide()

						return true
					}

					return reactRenderer?.ref?.onKeyDown(props)
				},

				onExit: () => {
					popup?.[0]?.destroy()
					reactRenderer?.destroy()
				},
			}
		},
	}
}

export default suggestion
