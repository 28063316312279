import { EMPTY_LABELED_REF_NODE } from '@/modules/workflow/constants/step-details'
import { Salesforce_ConvertLead } from '@/modules/workflow/types/salesforce'

export const DEFAULT_SALESFORCE_CONVERT_LEAD_WF_STEP_DETAILS: Salesforce_ConvertLead =
	{
		leadId: { ...EMPTY_LABELED_REF_NODE },
		accountId: { ...EMPTY_LABELED_REF_NODE },
		contactId: { ...EMPTY_LABELED_REF_NODE },
		doNotCreateOpportunity: {
			...EMPTY_LABELED_REF_NODE,
			value: false,
		},
		opportunityName: {
			...EMPTY_LABELED_REF_NODE,
		},
		overwriteLeadSource: {
			...EMPTY_LABELED_REF_NODE,
			value: false,
		},
		ownerId: {
			...EMPTY_LABELED_REF_NODE,
		},
		sendNotificationEmail: {
			...EMPTY_LABELED_REF_NODE,
			value: true,
		},
	}
