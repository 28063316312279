import { useCallback } from 'react'
import { Icons } from 'ui'

import { AsyncDrpOptionGenerator } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker/components/Option'
import { LabeledRefNodeValue } from '@/modules/workflow/types/actions'

export const useGetWorkflowExecutionDataDrpOptions =
	(): AsyncDrpOptionGenerator => {
		return useCallback(
			async ({
				onSelect,
			}: {
				onSelect: (value: any) => void
			}): Promise<DataReferencePickerOption[]> => {
				const workflowExecutionDataOptions: DataReferencePickerOption[] = [
					{
						label: 'Workflow execution date',
						value: 'createdAtDate',
						icon: Icons.calendar,
						onSelect: () => {
							const newRfn: LabeledRefNodeValue = {
								refNodeId: '0',
								variable: `$.createdAtDate`,
								value: null,
								label: 'Workflow execution date',
								icon: Icons.calendar,
							}

							onSelect(newRfn)
						},
					},
					{
						label: 'Workflow execution date/time',
						value: 'createdAtDateTime',
						icon: Icons.clock,
						onSelect: () => {
							const newRfn: LabeledRefNodeValue = {
								refNodeId: '0',
								variable: `$.createdAtDateTime`,
								value: null,
								label: 'Workflow execution date/time',
								icon: Icons.clock,
							}

							onSelect(newRfn)
						},
					},
				]

				return [
					{
						label: 'Workflow execution data',
						icon: Icons.workflow,
						value: workflowExecutionDataOptions,
					},
				]
			},
			[],
		)
	}
