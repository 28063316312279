import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { GetGoogleIntegrations, HealthCheckStatus } from './type'

export enum GoogleCalendarEndPoints {
	/** Query */
	getGoogleCalendarAuthUrl = 'getGoogleCalendarAuthUrl',
	getAllGoogleCaledarIntegrations = 'getAllGoogleCaledarIntegrations',
	getGoogleCalendarHealthCheck = 'getGoogleCalendarHealthCheck',
	getGoogleCalendarHealthCheckForTeam = 'getGoogleCalendarHealthCheckForTeam',

	/** Mutation */
	deleteIntegration = 'deleteIntegration',
}

const googleCalendarApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[GoogleCalendarEndPoints.getGoogleCalendarAuthUrl]: builder.query<
			string,
			string
		>({
			query: (route) => ({
				url: `/calendar/auth/url?route=${route}`,
				responseHandler: (response) => response.json().then((data) => data.url),
			}),
		}),
		[GoogleCalendarEndPoints.getAllGoogleCaledarIntegrations]: builder.query<
			GetGoogleIntegrations[],
			void
		>({
			query: () => ({
				url: `/calendar/integrations`,
				responseHandler: (response) =>
					response.json().then((data) => {
						return data?.data.sort((cal) => (cal.isPrimary ? 1 : -1))
					}),
			}),
		}),
		[GoogleCalendarEndPoints.getGoogleCalendarHealthCheck]: builder.query<
			HealthCheckStatus,
			void
		>({
			query: () => ({
				url: `/health-check/google-calendar`,
				responseHandler: (response) =>
					response.json().then((data) => {
						return data?.status
					}),
			}),
		}),
		[GoogleCalendarEndPoints.getGoogleCalendarHealthCheckForTeam]:
			builder.query<{ member_id: string; status: HealthCheckStatus }[], void>({
				query: () => ({
					url: `/health-check/google-calendar/team`,
					responseHandler: (response) =>
						response.json().then((data) => {
							return data
						}),
				}),
			}),
		/** Mutations */
		[GoogleCalendarEndPoints.deleteIntegration]: builder.mutation<
			string,
			string
		>({
			query: (id) => ({
				url: `/calendar/integrations/${id}`,
				responseHandler: responseTransformer,
			}),

			onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					googleCalendarApi.util.updateQueryData(
						GoogleCalendarEndPoints.getAllGoogleCaledarIntegrations,
						undefined,
						(draft) => {
							return draft.filter((item) => item.id !== args)
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),
	}),
})

export const {
	useGetGoogleCalendarAuthUrlQuery,
	useLazyGetGoogleCalendarAuthUrlQuery,
	useDeleteIntegrationMutation,
	useGetAllGoogleCaledarIntegrationsQuery,
	useGetGoogleCalendarHealthCheckQuery,
	useGetGoogleCalendarHealthCheckForTeamQuery,
} = googleCalendarApi

export { googleCalendarApi }
