import { Icons } from 'ui'

import { useGetQueueByIdQuery } from '@/modules/queues/api'
import { Default_Route } from '@/modules/workflow/types/actions'
import { WorkflowNode } from '@/modules/workflow/types/nodes'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataLoading } from '../MetaDataLoading'
import { MetaDataTag } from '../MetaDataTag'
import { MetaDataTitle } from '../MetaDataTitle'
import { MetaDataWarningTag } from '../MetaDataWarningTag'

export const RoundRobinDetails = ({ data }: { data: WorkflowNode }) => {
	const stepDetails = data?.stepDetails as Default_Route

	const selectedQueueId = stepDetails?.queueId?.value

	const { data: queueData, isLoading } = useGetQueueByIdQuery(selectedQueueId, {
		skip: !selectedQueueId,
	})

	if (!selectedQueueId) {
		return (
			<MetaDataRowLayout>
				<MetaDataTitle title="Queue:" />
				<MetaDataWarningTag warning="Add queue" icon={Icons.queues_arrows} />
			</MetaDataRowLayout>
		)
	}

	if (isLoading) return <MetaDataLoading title="Queue:" />

	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="Queue:" />
			<MetaDataTag
				label={queueData?.name || 'Unnamed Queue'}
				icon={Icons.queues_arrows}
			/>
		</MetaDataRowLayout>
	)
}
