import { HStack, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { FormType } from '@/modules/forms/types'
import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

import { getTriggerBy } from '../utils/getTriggerBy'

type Props = {
	type?: FormType
}

export const WorkflowTriggerBy = ({ type }: Props) => {
	const triggerBy = getTriggerBy(type)

	if (!triggerBy) return null

	return (
		<HStack
			color={COLORS.purple[9]}
			px={2}
			py={1}
			border={Borders.primary}
			borderRadius="md"
			borderColor={COLORS.purple[4]}
			bg={COLORS.purple[2]}
		>
			<GetIcon icon={Icons.trigger_by} />
			<Text variant={TEXT_ENUM.medium12}>
				Is triggered by
				<Text as="span" fontWeight={600}>
					{' '}
					{triggerBy}
				</Text>
			</Text>
		</HStack>
	)
}
