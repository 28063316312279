import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { ExecuteWorkflowDto } from '../types/execution'

export enum WorkflowEndPoints {
	/** Mutation */
	executeWorkflowStep = 'executeWorkflowStep',
	executeWorkflow = 'executeWorkflow',
}

const workflowApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[WorkflowEndPoints.executeWorkflow]: builder.mutation<
			any,
			ExecuteWorkflowDto
		>({
			query: (payload) => ({
				url: `/workflows/execute`,
				method: 'POST',
				body: payload,
			}),
		}),
		[WorkflowEndPoints.executeWorkflowStep]: builder.mutation<any, any>({
			query: (payload) => ({
				url: `/workflows/execute/single`,
				method: 'POST',
				body: payload,
				responseHandler: responseTransformer,
			}),
		}),
	}),
})

export const { useExecuteWorkflowMutation, useExecuteWorkflowStepMutation } =
	workflowApi
