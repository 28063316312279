import { Flex, SkeletonText } from '@chakra-ui/react'
import { GetIcon, Icons } from 'ui'

import { Card } from '@/common/components/Card'
import { Borders } from '@/styles/borders'

export const LogsOverviewLoading = () => {
	return (
		<Card maxW="400px">
			<Flex
				w="full"
				px="16px"
				py="16px"
				alignItems="center"
				borderBottom={Borders.primary}
			>
				<GetIcon icon={Icons.route} />
				<SkeletonText noOfLines={1} width="50%" ml={2} />
			</Flex>
			<Flex p={5}>
				<SkeletonText
					width="100%"
					noOfLines={5}
					skeletonHeight="3"
					spacing={3.5}
				/>
			</Flex>
		</Card>
	)
}
