import { CloseButton, CloseButtonProps } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

interface PanelCloseButtonProps extends CloseButtonProps {
	onClick: () => void
}

export const PanelCloseButton = ({
	onClick,
	...props
}: PanelCloseButtonProps) => {
	return (
		<CloseButton
			color={COLORS.gray[9]}
			_hover={{ bg: 'transparent' }}
			_focus={{ border: 'none' }}
			_active={{ bg: 'transparent' }}
			onClick={onClick}
			{...props}
		/>
	)
}
