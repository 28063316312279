import { useCallback } from 'react'
import { Icons } from 'ui'

import { AsyncDrpOptionGenerator } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker/components/Option'
import { Default_HTTPPostRequest } from '@/modules/workflow/types/actions'
import { buildNestedDrpOptions } from '@/modules/workflow/utils/buildNestedDrpOptions'
import { WorkflowIntegrationIds } from '@/modules/workflow/utils/mappings'

import { useGetUpstreamNodes } from '../../useGetUpstreamNodes'

export const useGetWebhookNodeDrpOptions = (): AsyncDrpOptionGenerator => {
	const { getUpstreamNodes } = useGetUpstreamNodes()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const upstreamNodes = getUpstreamNodes()
			const webhookNodes = upstreamNodes.filter(
				(n) =>
					n.data.integrationId === WorkflowIntegrationIds.defaultSendToWebhook,
			)
			const webhookOptions = webhookNodes
				.map((webhookNode) => {
					const payload = (
						webhookNode.data?.stepDetails as Default_HTTPPostRequest
					)?.testRequestResponse
					if (payload) {
						const statusCodeOption = {
							label: 'Status code',
							icon: Icons.webhook,
							value: 'status_code',
							onSelect: () => {
								onSelect({
									refNodeId: webhookNode.data.id,
									variable: '$.statusCode',
									value: null,
									label: 'Status code',
									icon: Icons.webhook,
								})
							},
						}
						const payloadOptions = buildNestedDrpOptions({
							optionsObj: payload,
							newRfnProps: {
								refNodeId: webhookNode.data.id,
								icon: Icons.webhook,
							},
							onSelect: (refNodeProps) => {
								const { label, icon, refNodeId, variable } = refNodeProps

								const newRfn = {
									refNodeId,
									variable: `$.data.${variable}`,
									value: null,
									label,
									icon,
								}

								onSelect(newRfn)
							},
						})
						return {
							label: `Response from ${webhookNode.data.editableName}`,
							icon: Icons.webhook,
							value: [statusCodeOption, ...payloadOptions],
						}
					}
					return null
				})
				.filter((op) => !!op)

			return [
				{
					label: 'Webhook responses',
					icon: Icons.webhook,
					value: webhookOptions,
				},
			]
		},
		[getUpstreamNodes],
	)
}
