import { useCallback } from 'react'
import { Icons } from 'ui'

import { useLazyGetAllQueuesQuery } from '@/modules/queues/api'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'

export const useGetQueueDrpOptions = (): AsyncDrpOptionGenerator => {
	const [getAllQueues] = useLazyGetAllQueuesQuery()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const queues = [
				...(await getAllQueues()
					.unwrap()
					.then((data) => data)),
			]

			const queueOptions =
				queues.map((queue) => {
					const label = queue.name
					const newRfn = {
						refNodeId: null,
						variable: null,
						value: queue.id,
						type: 'queue',
						label,
						icon: Icons.queues_arrows,
					}
					return {
						label,
						value: newRfn,
						onSelect: (value: any) => {
							onSelect(value)
						},
					}
				}) || []

			return [
				{
					label: 'Queues',
					value: queueOptions,
					icon: Icons.queues_arrows,
				},
			]
		},
		[getAllQueues],
	)
}
