import { Button, Center, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { FormType } from '@/modules/forms/types'
import { TRIGGERS_CONTENT } from '@/modules/workflows-home/constants/triggers-content'
import { BTN } from '@/styles/components/button'
import { TEXT_ENUM } from '@/styles/components/text'

import { useWebhookUrl } from '../../hooks/workflow'
import { CopyWebhookUrl } from '../CopyWebhookUrl'
import { StepLogo } from '../StepLogo'

type Props = {
	initiateTriggerCheck: () => void
}

export const IntroStep = ({ initiateTriggerCheck }: Props) => {
	const content = TRIGGERS_CONTENT[FormType.webhook_trigger]
	const webhookUrl = useWebhookUrl({ test: true })

	return (
		<Flex flexDir="column" w="full" p={5} minH="400px" h="full">
			<Center w="full" h="full" flexDir="column" textAlign="center">
				<StepLogo icon={content.icon} bg={content.stepLogoBgColor} />
				<Text mt={4} variant={TEXT_ENUM.semibold16}>
					Connection Awaits!
				</Text>

				<Text
					variant={TEXT_ENUM.regular14}
					color={COLORS.background[6]}
					mt={2}
					mb="20px"
				>
					{`Your Default account is all set to receive requests. We're on the
					lookout for the latest one to ensure your trigger is perfectly tuned.
					Make sure to copy this code and paste it to your platform`}
				</Text>

				<CopyWebhookUrl url={webhookUrl} />

				<Button
					variant={BTN.outline}
					rightIcon={<GetIcon icon={Icons.play} />}
					mt="20px"
					onClick={initiateTriggerCheck}
				>
					Initiate Trigger Check
				</Button>
			</Center>
		</Flex>
	)
}
