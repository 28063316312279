import { Flex, FlexProps } from '@chakra-ui/react'
import React from 'react'

import { Borders } from '@/styles/borders'

interface Props extends FlexProps {
	children: React.ReactNode
}

export const Card = ({ children, ...props }: Props) => {
	return (
		<Flex
			w="full"
			flexDir="column"
			border={Borders.primary}
			borderRadius="8px"
			h="fit-content"
			bg="white"
			{...props}
		>
			{children}
		</Flex>
	)
}
