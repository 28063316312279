import {
	Flex,
	HStack,
	ModalCloseButton,
	ModalHeader,
	Text,
} from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { TEXT_ENUM } from '@/styles/components/text'

export const EmptyModalHeader = () => {
	return (
		<ModalHeader p={0}>
			<HStack p={5} justifyContent="space-between">
				<Flex flexDir="column" gap={2} alignItems="flex-start">
					<Text variant={TEXT_ENUM.semibold16}>
						Select your automations starting point
					</Text>
					<Text variant={TEXT_ENUM.medium14} color={COLORS.background[5]}>
						Select a trigger to get your workflow automation started
					</Text>
				</Flex>
				<ModalCloseButton />
			</HStack>
		</ModalHeader>
	)
}
