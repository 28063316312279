import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useLazyGetHubspotPropertiesQuery } from '@/app/integrations/api/hubspot'

import { workflowsActions } from '../slice'
import { HubspotEntities } from '../types/hubspot'

export const useAllHubspotProperties = () => {
	const dispatch = useDispatch()
	const [fetchProperties] = useLazyGetHubspotPropertiesQuery()

	const objectTypes = Object.values(HubspotEntities)

	return useCallback(async () => {
		const fetchedProperties = {}
		const propertyPromises: Promise<unknown>[] = []
		for (const objectType of objectTypes) {
			propertyPromises.push(
				new Promise((resolve, reject) => {
					fetchProperties({ type: objectType })
						.unwrap()
						.then((data) => {
							resolve(data)
						})
						.catch((error) => {
							reject(error)
						})
				}),
			)
		}

		await Promise.allSettled(propertyPromises).then((results) => {
			results.forEach((result, index) => {
				if (result.status === 'fulfilled') {
					fetchedProperties[objectTypes[index]] = result.value
				}
			})
		})

		dispatch(workflowsActions.setHubspotProperties(fetchedProperties))

		return fetchedProperties
	}, [dispatch, fetchProperties, objectTypes])
}
