import { Flex, FlexProps } from '@chakra-ui/react'
import { isValidMotionProp, motion, MotionProps } from 'framer-motion'
import React from 'react'

export type MotionFlexProps = Omit<FlexProps, keyof MotionProps> & MotionProps

export const MotionFlex = motion(
	React.forwardRef<HTMLDivElement, MotionFlexProps>((props, ref) => {
		const chakraProps = Object.fromEntries(
			Object.entries(props).filter(([key]) => !isValidMotionProp(key)),
		)
		return <Flex ref={ref} {...chakraProps} />
	}),
)
