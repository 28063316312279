import { useCallback } from 'react'

import { useAppSelector } from '@/app/hooks'
import HubSpotLogo from '@/assets/logo/hubspot-step.svg'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { selectHubspotProperties } from '../../../slice/selectors'
import { FieldRefNodeValue } from '../../../types/actions'
import {
	Hubspot_CreateRecord,
	Hubspot_MatchRecord,
} from '../../../types/hubspot'
import { getIntegrationDataType } from '../../../utils/getIntegrationDataType'
import {
	WorkflowIntegrationIds,
	WorkflowIntegrationType as IntegrationType,
} from '../../../utils/mappings'
import { useAllHubspotProperties } from '../../useAllHubspotProperties'
import { useGetUpstreamNodes } from '../../useGetUpstreamNodes'

export const useGetHubspotDrpOptions = (): AsyncDrpOptionGenerator => {
	const getAllHubspotProperties = useAllHubspotProperties()
	const propertiesInStore = useAppSelector(selectHubspotProperties)
	const { getUpstreamNodes } = useGetUpstreamNodes()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const properties = propertiesInStore || (await getAllHubspotProperties())

			const upstreamNodes = getUpstreamNodes()
			const hubspotNodes = upstreamNodes.filter(
				(n) =>
					n.data.integrationId === WorkflowIntegrationIds.hubspotMatchRecord ||
					n.data.integrationId === WorkflowIntegrationIds.hubspotCreateRecord,
			)

			const hubspotNodesWithObjectTypes = hubspotNodes?.filter(
				(n) =>
					(n.data?.stepDetails as Hubspot_MatchRecord | Hubspot_CreateRecord)
						?.type?.value,
			)

			const objectTypesCovered: string[] = []

			const previousHubspotRecordOptions: DataReferencePickerOption[] = []
			const allHubspotRecordOptions: DataReferencePickerOption[] = []

			hubspotNodesWithObjectTypes.forEach((n) => {
				const objectType = (
					n.data?.stepDetails as Hubspot_MatchRecord | Hubspot_CreateRecord
				)?.type?.value

				if (!objectTypesCovered.includes(objectType)) {
					objectTypesCovered.push(objectType)

					previousHubspotRecordOptions.push({
						label: `Previous ${objectType} record`,
						value:
							properties[objectType]?.map((property, idx) => ({
								label: property.label,
								value: property,
								onSelect: (value: any) => {
									const refNodeId = 'records'
									const variable = `$.hubspotRecords.${objectType}.${property.name}`

									const newRfn: FieldRefNodeValue = {
										refNodeId,
										variable,
										value: null,
										label: `${value.label} (from "Previous ${objectType} record")`,
										i: idx,
										dataType: getIntegrationDataType(
											IntegrationType.HubSpot,
											value.type,
										),
										icon: HubSpotLogo,
									}

									onSelect(newRfn)
								},
							})) || [],
					})
				}

				allHubspotRecordOptions.push({
					label: `${objectType} from "${n.data.editableName}"`,
					value:
						properties[objectType]?.map((property, idx) => ({
							label: property.label,
							value: property,
							onSelect: (value: any) => {
								const refNodeId = n.data.id
								const variable = `$.${property.name}`

								const newRfn: FieldRefNodeValue = {
									refNodeId,
									variable,
									value: null,
									label: value.label,
									i: idx,
									dataType: getIntegrationDataType(
										IntegrationType.HubSpot,
										value.type,
									),
									icon: HubSpotLogo,
								}

								onSelect(newRfn)
							},
						})) || [],
				})
			})

			return [
				{
					label: 'HubSpot records',
					icon: HubSpotLogo,
					value: [
						...previousHubspotRecordOptions,
						{
							label: 'All HubSpot records',
							value: [...allHubspotRecordOptions],
						},
					],
				},
			]
		},
		[propertiesInStore, getAllHubspotProperties, getUpstreamNodes],
	)
}
