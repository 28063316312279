import { Center, HStack, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { Link } from 'react-router-dom'
import { GetIcon, Icons } from 'ui'

import { FormType } from '@/modules/forms/types'
import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

type Props = {
	type?: FormType
	formName?: string | null
	formId: number | null
}

export const WorkflowConnectedTo = ({ type, formName, formId }: Props) => {
	if (type !== FormType.regular && type !== FormType.imported) return null

	return (
		<Center onClick={(e) => e.stopPropagation()}>
			<Link to={`/forms/${formId}/editor`}>
				<HStack
					px={2}
					py={1}
					border={Borders.primary}
					borderRadius="md"
					bg={COLORS.background[12]}
					color={COLORS.background[5]}
				>
					<GetIcon icon={Icons.link_form} />
					<Text variant={TEXT_ENUM.medium12}>
						{formName || 'Form Connected'}
					</Text>
				</HStack>
			</Link>
		</Center>
	)
}
