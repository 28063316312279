import { FormType } from '@/modules/forms/types'

import { WorkflowCrmRecordPartial } from '../partials/WorkflowCrmRecordPartial'
import { WorkflowLeadPartial } from '../partials/WorkflowLeadPartial'
import { WorkflowLog } from '../types'

export const getWorkflowLogObjectInfo = ({
	workflowLog,
	triggerType,
}: {
	workflowLog: WorkflowLog | null
	triggerType: FormType | undefined
}) => {
	if (!workflowLog || !triggerType) return null

	switch (triggerType) {
		case FormType.regular:
		case FormType.imported:
		case FormType.meeting_booked_trigger:
		case FormType.meeting_cancelled_trigger:
		case FormType.meeting_rescheduled_trigger:
		case FormType.meeting_reassigned_trigger:
		case FormType.meeting_no_show_trigger:
		case FormType.tag_assigned_trigger:
		case FormType.webhook_trigger:
		case FormType.hubspot_trigger:
			return <WorkflowLeadPartial workflowLog={workflowLog} />
		case FormType.salesforce_object_create_trigger:
		case FormType.salesforce_object_update_trigger:
			return (
				<WorkflowCrmRecordPartial
					workflowLog={workflowLog}
					triggerType={triggerType}
				/>
			)
		default:
			return null
	}
}
