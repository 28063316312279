import { Flex } from '@chakra-ui/react'
import { ColorTokens } from '@ds/tokens/colors'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { useAppSelector } from '@/app/hooks'
import { selectIssHomeSidebarExpanded } from '@/app/user/slice/selectors'
import { useSelectedWorkflow } from '@/modules/workflow/hooks'

import { LogsTableFooter } from '../components/LogsTable/LogsTableFooter'
import { LogsTableHeaders } from '../components/LogsTable/LogsTableHeader'
import { LogsTableRows } from '../components/LogsTable/LogsTableRow'
import { getLogsTableColumns } from '../components/LogsTable/LogsTableSetup'
import { WorkflowLog } from '../types'
import { LogsFiltersPartial } from './LogsFiltersPartial'

type Props = {
	workflowLogs: WorkflowLog[]
	count?: number | null
	isFetching?: boolean
	isError?: boolean
	isLoading?: boolean
}

export const LogsTablePartials = ({
	workflowLogs,
	count,
	isFetching,
	isError,
	isLoading,
}: Props) => {
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
	const triggerType = selectedWorkflow?.type || null
	const logTableColumns = getLogsTableColumns({ formType: triggerType })

	const table = useReactTable({
		data: workflowLogs,
		columns: logTableColumns,
		getCoreRowModel: getCoreRowModel(),
	})

	const isSidebarExpanded = useAppSelector(selectIssHomeSidebarExpanded)
	const maxW = isSidebarExpanded ? '65%' : '70%'

	return (
		<Flex flexDir="column" gap={4} minW={maxW}>
			<LogsFiltersPartial triggerType={triggerType} />
			<Flex
				w="full"
				flexDir="column"
				borderWidth="medium"
				borderRadius="md"
				borderColor={ColorTokens.border_primary}
				bg={ColorTokens.white}
				overflowY="hidden"
				overflowX="scroll"
				position="relative"
			>
				<Flex w="fit-content" minW="100%" h="full" direction="column">
					<LogsTableHeaders table={table} />
					<LogsTableRows
						table={table}
						isError={isError}
						isLoading={isLoading}
					/>
					<LogsTableFooter
						count={count}
						isFetching={isFetching}
						isError={isError}
						isLoading={isLoading}
					/>
				</Flex>
			</Flex>
		</Flex>
	)
}
