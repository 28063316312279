import { Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useDeepCompareEffect } from 'react-use'
import { Icons } from 'ui'

import {
	useGetAllFormVersionsByIdQuery,
	useGetFormByIdQuery,
} from '@/modules/forms/api'
import { FormTable } from '@/modules/forms/types'

import { PanelContentHeader } from '../components/side-panel/panel-content/PanelContentHeader'
import {
	formatVersions,
	VersionHistoryItem,
} from '../components/side-panel/panel-content/version-history'
import { useSelectedWorkflow } from '../hooks'
import { workflowsActions } from '../slice'
import { NavigationId } from '../types/navigation'

export const VersionHistoryPartial = () => {
	const { selectedWorkflow } = useSelectedWorkflow()
	const dispatch = useDispatch()
	const { data: formVersions } = useGetAllFormVersionsByIdQuery(
		String(selectedWorkflow?.id),
	)
	const { data: draftForm } = useGetFormByIdQuery(
		String(selectedWorkflow?.id),
		{
			skip: !selectedWorkflow?.id,
		},
	)

	const [allVersions, setAllVersions] = useState<FormTable[]>([])

	useDeepCompareEffect(() => {
		if (formVersions && draftForm) {
			setAllVersions([...formVersions, draftForm])
		}
	}, [formVersions, draftForm])

	const formattedVersions = formatVersions(allVersions)

	const handleViewVersion = (workflowVersion: string) => {
		window.open(
			`/workflows/${selectedWorkflow?.id}/versions/${workflowVersion}`,
			'_blank',
		)
	}

	return (
		<Flex direction="column">
			<PanelContentHeader
				title="Version History"
				titleIcon={Icons.time_reload}
				// Note: Experimental in terms of UI - may or may not be a good idea to keep
				enableNavigation
				onNavigationBackClick={() => {
					dispatch(
						workflowsActions.setSelectedSidePanelNavigationId(
							NavigationId.Overview,
						),
					)
				}}
			/>

			<Flex direction="column" gap={6} mt={2} mb={10}>
				{formattedVersions.map((version, idx) => (
					<VersionHistoryItem
						key={`version-item-${idx}`}
						version={version}
						onViewVersion={handleViewVersion}
					/>
				))}
			</Flex>
		</Flex>
	)
}
