export const LANGUAGES = [
	{
		language: 'English',
		isoCode1: 'en',
	},
	{
		language: 'Spanish',
		isoCode1: 'es',
	},
	{
		language: 'German',
		isoCode1: 'de',
	},
]

export const SUPPORTED_LANGUAGES = [
	{
		language: 'English',
		isoCode1: 'en',
	},
	{
		language: 'Spanish',
		isoCode1: 'es',
	},
	{
		language: 'German',
		isoCode1: 'de',
	},
]
