const DEFAULT_INITIAL_AVAILABILITY = 30

/** Initial availability window is next 30 days */
export const initializeDateRange = (days?: number) => {
	return {
		start: new Date(Date.now() - (Date.now() % 100000)).getTime(),
		end:
			new Date(Date.now() - (Date.now() % 100000)).getTime() +
			(days || DEFAULT_INITIAL_AVAILABILITY) * 24 * 60 * 60 * 1000,
	}
}
