import { Flex, Tooltip } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { ReactNode, useMemo } from 'react'
import React from 'react'

import { useAppSelector } from '@/app/hooks'
import { useIsWFPlusButtonTrueFalseConnection } from '@/common/hooks/feature-flags/useIsWFPlusButtonTrueFalseConnection'
import { TOOLTIP_ENUM } from '@/styles/components/tooltip'
import { SHADOWS } from '@/styles/constants'

import { CanvasNodeWarningBadge } from '../components/canvas/node-types/CanvasNodeWarningBadge'
import { TopConnector } from '../components/canvas/node-types/TopConnector'
import { useGetUpstreamNodes } from '../hooks/useGetUpstreamNodes'
import { useCanvasWorkflowLog } from '../hooks/workflow'
import {
	selectClickToConnectInteraction,
	selectDroppableBranchId,
} from '../slice/selectors'
import { WorkflowNode } from '../types/nodes'

type Props = {
	children: ReactNode
	data: WorkflowNode
	isSelected?: boolean
}

export const CanvasNodeLayout = React.memo(
	({ isSelected, data, children }: Props) => {
		const isTriggerNode = data.id === '0'
		const clickToConnect = useAppSelector(selectClickToConnectInteraction)
		const droppableBranchId = useAppSelector(selectDroppableBranchId)
		const { getUpstreamNodes } = useGetUpstreamNodes()
		const upstreamNodes = getUpstreamNodes()
		const { workflowLog } = useCanvasWorkflowLog()
		const isWFPlusButtonTrueFalseConnection =
			useIsWFPlusButtonTrueFalseConnection()

		const isExecutedTriggerNode = workflowLog && data.id === '0'
		const executedStep = useMemo(
			() => workflowLog?.steps?.find((s) => s.key === data.id),
			[workflowLog, data.id],
		)

		const color = useMemo(() => {
			if (isExecutedTriggerNode) {
				return COLORS.green[7]
			}
			if (executedStep) {
				return executedStep.error ? COLORS.red[7] : COLORS.green[7]
			}
			return ColorTokens.border_primary
		}, [executedStep, isSelected])

		const parsedBranchId = droppableBranchId?.split('&')[0]
		const isTooltipEnabled =
			clickToConnect &&
			data.id !== parsedBranchId &&
			(isWFPlusButtonTrueFalseConnection ||
				!data.parentIds.some((p) => p.split('&')[0] === parsedBranchId)) &&
			!upstreamNodes.some((n) => n.id === data.id)

		const isUnexecutedStep =
			workflowLog && !executedStep && !isExecutedTriggerNode

		return (
			<Tooltip
				label="Click to connect node to selected branch"
				variant={TOOLTIP_ENUM.workflows_side_panel}
				isDisabled={!isTooltipEnabled}
			>
				<Flex
					bg={ColorTokens.bg_card}
					w={80}
					h="fit-content"
					borderWidth="medium"
					borderRadius="12px"
					borderColor={isSelected ? ColorTokens.accent : color}
					filter={`grayscale(${isUnexecutedStep ? 100 : 0}%)`}
					opacity={isUnexecutedStep ? 0.6 : 1}
					boxShadow={
						isUnexecutedStep
							? SHADOWS.small
							: `${SHADOWS.workflows_executed_step} ${color}40`
					}
					cursor="pointer"
					flexDir="column"
				>
					{!isTriggerNode && (
						<>
							<CanvasNodeWarningBadge data={data} />
							<TopConnector
								data={data}
								color={executedStep ? COLORS.green[7] : undefined}
							/>
						</>
					)}
					{children}
				</Flex>
			</Tooltip>
		)
	},
)
