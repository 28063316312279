import { Flex, HStack, Text } from '@chakra-ui/react'
import { GetIcon, Icons } from '@ds/Icons'
import { COLORS } from '@ds/tokens/colors'
import { cloneDeep } from 'lodash'
import { NavLink } from 'react-router-dom'

import { useGetRemindersQuery } from '@/modules/reminders/api'
import { useUpdateWorkflowNode } from '@/modules/workflow/hooks'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import { Default_Reminder } from '@/modules/workflow/types/actions'
import { TEXT_ENUM } from '@/styles/components/text'

import { PanelInputLabel } from '../../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../../panel-variants/PanelSelect'

export const SendMeetingReminder = () => {
	const { data, isLoading } = useGetRemindersQuery()

	const updateNode = useUpdateWorkflowNode()
	const selectedNode = useSelectedNode()

	const stepDetails = selectedNode?.data.stepDetails as Default_Reminder

	const options = data?.map((reminder) => ({
		label: reminder.name,
		value: reminder.id,
	}))

	const selectedOption = options?.find(
		(op) => op.value === stepDetails?.reminderId?.value,
	)

	const handleReminderChange = (value?: string) => {
		if (!selectedNode || !value) return

		const newStepDetails: Default_Reminder = cloneDeep(
			stepDetails || {
				reminderId: {
					value: null,
					refNodeId: null,
					variable: null,
				},
			},
		)

		newStepDetails.reminderId = {
			value: value,
			refNodeId: null,
			variable: null,
		}

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: newStepDetails,
			},
		})
	}

	return (
		<Flex w="100%" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel label="Reminder" />
				<PanelSelect
					placeholder="Select a reminder template..."
					options={options}
					isSearchable
					isLoading={isLoading}
					value={selectedOption}
					onChange={(option) => handleReminderChange(option?.value)}
				/>
				<HStack mt={2} ml={0.5} spacing={1}>
					<GetIcon
						icon={Icons.info}
						fontSize="16px"
						color={COLORS.background[5]}
					/>
					<Text variant={TEXT_ENUM.medium12} color={COLORS.background[5]}>
						Your reminder templates are managed{' '}
						<NavLink to="/scheduler/reminders">
							<Text as="span" color={COLORS.purple[9]}>
								here
							</Text>
						</NavLink>
					</Text>
				</HStack>
			</Flex>
		</Flex>
	)
}
