import { createContext, useContext, useState } from 'react'

type ContextType = {
	isExpanded: boolean
	toggleExpand: () => void
	expandable: boolean
	onExpand?: (isExpanded: boolean) => void
	expandableId?: string
}

export const ExpandableContext = createContext<ContextType>({
	isExpanded: false,
	expandable: true,
	toggleExpand: () => {},
})

type Props = {
	children: React.ReactNode
	expandable: boolean
	onExpand?: (isExpanded: boolean) => void
	expandableId?: string
}

export const ExpandableContextProvider = ({
	children,
	expandable,
	onExpand,
	expandableId,
}: Props) => {
	const [isExpanded, setIsExpanded] = useState(false)

	const toggleExpand = () => {
		setIsExpanded((prevState) => {
			const newState = !prevState
			if (newState !== prevState && onExpand) {
				onExpand(newState)
			}
			return newState
		})
	}

	return (
		<ExpandableContext.Provider
			value={{
				isExpanded,
				toggleExpand: toggleExpand,
				expandable,
				onExpand,
				expandableId: expandableId,
			}}
		>
			{children}
		</ExpandableContext.Provider>
	)
}

export const useExpandableContext = () => {
	const context = useContext(ExpandableContext)
	if (context === undefined) {
		throw new Error(
			'useExpandableContext must be used within a ExpandableContextProvider. Seems you forgot to wrap all popover components within `<Expandable />',
		)
	}
	return context
}
