import { useCallback } from 'react'
import { getFullNameOrEmail } from 'shared-utils'
import { Icons } from 'ui'

import { useLazyGetTeamMembersQuery } from '@/app/user/api/members'
import { useTeamId } from '@/app/user/api/teams/selectors'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'

export const useGetMemberDrpOptions = (): AsyncDrpOptionGenerator => {
	const teamId = useTeamId()
	const [getTeamMembers] = useLazyGetTeamMembersQuery()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const members = [
				...(await getTeamMembers(teamId)
					.unwrap()
					.then((data) => data)),
			]

			const memberOptions =
				members.map((member) => {
					const label = getFullNameOrEmail({
						firstName: member.first_name,
						lastName: member.last_name,
						email: member.email,
					})
					const newRfn = {
						refNodeId: null,
						variable: null,
						value: member.id,
						type: 'member',
						label,
						icon: Icons.person,
					}
					return {
						label,
						value: newRfn,
						onSelect: (value: any) => {
							onSelect(value)
						},
					}
				}) || []

			return [
				{
					label: 'Members',
					value: memberOptions,
					icon: Icons.person,
				},
			]
		},
		[getTeamMembers, teamId],
	)
}
