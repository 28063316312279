import { Flex, Spinner } from '@chakra-ui/react'
import { Icons } from 'ui'

import { useUpdateFormMutation } from '@/modules/forms/api'
import { FormType } from '@/modules/forms/types'

import { DescriptionTextarea } from '../components/side-panel/panel-content/DescriptionTextarea'
import {
	OverviewConnectedForms,
	OverviewGeneralInfo,
	OverviewStatus,
	OverviewVersionHistory,
} from '../components/side-panel/panel-content/overview'
import { PanelContentHeader } from '../components/side-panel/panel-content/PanelContentHeader'
import { PanelSectionDivider } from '../components/side-panel/panel-variants/PanelSectionDivider'
import { useSelectedWorkflow, useWorkflowReadOnly } from '../hooks/workflow'

export const OverviewPartial = () => {
	const { selectedWorkflow } = useSelectedWorkflow()
	const workflowName =
		selectedWorkflow?.workflow_name || selectedWorkflow?.name || 'New workflow'
	const workflowDescription = selectedWorkflow?.workflow_description || ''

	const [updateForm] = useUpdateFormMutation()
	const isReadOnly = useWorkflowReadOnly()

	const handleUpdateDescription = (value: string) => {
		if (isReadOnly || !selectedWorkflow) return

		updateForm({
			form: {
				id: selectedWorkflow.id,
				workflow_description: value,
			},
		})
	}

	const formType = selectedWorkflow?.type
	const hasConnectedForms =
		formType === FormType.regular || formType === FormType.imported

	return (
		<>
			<PanelContentHeader
				editable
				selectedWorkflow={selectedWorkflow}
				updateForm={updateForm}
				title={workflowName}
				titleIcon={Icons.git_fork}
			>
				<DescriptionTextarea
					initialValue={workflowDescription}
					onBlur={handleUpdateDescription}
				/>
			</PanelContentHeader>

			{selectedWorkflow ? (
				<>
					<OverviewStatus published={selectedWorkflow.published} />
					<PanelSectionDivider my={6} />
					<OverviewGeneralInfo workflow={selectedWorkflow} />
					<PanelSectionDivider my={6} />
					{hasConnectedForms && (
						<>
							<OverviewConnectedForms forms={[selectedWorkflow]} />
							<PanelSectionDivider my={6} />
						</>
					)}
					<OverviewVersionHistory />
				</>
			) : (
				<Flex justify="center" my="50%">
					<Spinner />
				</Flex>
			)}
		</>
	)
}
