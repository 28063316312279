import { Center, Image } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { Borders } from '@/styles/borders'

type Props = {
	logo?: string
	icon?: Icons
}

export const Logo = ({ logo, icon }: Props) => {
	return (
		<Center
			w="44px"
			h="44px"
			flexShrink={0}
			bg={COLORS.background[12]}
			borderRadius="lg"
			border={Borders.primary}
			color={COLORS.background[4]}
		>
			{logo && <Image src={logo} w="24px" />}
			{icon && <GetIcon icon={icon} boxSize="24px" />}
		</Center>
	)
}
