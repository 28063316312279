import { Flex } from '@chakra-ui/react'
import { FocusEventHandler } from 'react'
import { MultiValue, SingleValue } from 'react-select'

import { useGetParameterInfo } from '@/modules/workflow/hooks'
import { FieldRefNodeValue } from '@/modules/workflow/types/actions'

import { PanelInput } from '../../../../../panel-variants/PanelInput'
import { PanelSelect } from '../../../../../panel-variants/PanelSelect'
import { ConditionInputProps } from '../../conditional/Condition'

export const ConditionValueInput = ({
	condition,
	handleUpdateInput,
}: ConditionInputProps) => {
	const parameter = condition.parameter as FieldRefNodeValue | null
	const { value: conditionValue } = condition

	const parameterInfo = useGetParameterInfo({
		parameter,
		currentValue: conditionValue,
	})
	const parameterAttribute = parameterInfo.parameterAttribute
	// TODO: Improve type handling in condition building
	const isNumber =
		parameterAttribute?.key.toLowerCase().includes('number') ||
		parameterAttribute?.key === 'company.department_headcount.sales'
	const values = parameterInfo?.values || parameterAttribute?.values || []

	const value = !conditionValue?.value
		? undefined
		: Array.isArray(conditionValue.value)
			? conditionValue.value.map((v) => {
					const label =
						values.find((val) => val.value === v)?.label || 'Unknown'
					return { label, value: v }
				})
			: { label: conditionValue.value, value: conditionValue.value }

	const handleInputOnBlur: FocusEventHandler<HTMLInputElement> = (e) => {
		const value = isNumber ? Number(e.target.value) : e.target.value

		const rfn = {
			refNodeId: null,
			variable: null,
			value,
		}
		handleUpdateInput(rfn)
	}

	const handleSelectOnChange: (
		newValue:
			| MultiValue<{
					label: any
					value: any
			  }>
			| SingleValue<{
					label: any
					value: any
			  }>,
	) => void = (option) => {
		if (!option) return
		const values: { label: string; value: string }[] = [option]
			.flat()
			.map((op) => op.value)

		const value =
			parameterAttribute?.valueIsSingleSelect === true ? values[0] : values

		const rfn = {
			refNodeId: null,
			variable: null,
			value,
		}
		handleUpdateInput(rfn)
	}

	return (
		<Flex>
			{parameterAttribute?.valueIsText ? (
				<PanelInput
					initialValue={
						conditionValue?.value === null ||
						conditionValue?.value === undefined
							? ''
							: conditionValue?.value
					}
					h="34px"
					type={isNumber ? 'number' : 'text'}
					onBlur={handleInputOnBlur}
				/>
			) : (
				<PanelSelect
					options={values}
					value={value}
					isMulti={parameterAttribute?.valueIsSingleSelect === false}
					controlStyles={{
						minHeight: 'fit-content',
					}}
					isSearchable
					onChange={handleSelectOnChange}
				/>
			)}
		</Flex>
	)
}
