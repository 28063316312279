import { SingleValue } from 'react-select'

import { PanelSelect } from '@/modules/workflow/components/side-panel/panel-variants/PanelSelect'
import { Operators } from '@/modules/workflow/utils/matching'

import { ConditionInputProps } from '../../conditional/Condition'

export const ConditionComparatorInput = ({
	condition,
	handleUpdateInput,
}: ConditionInputProps) => {
	const filteredOperators: Record<
		string,
		{ label: string; priority?: boolean }
	> = Object.entries(Operators).reduce((acc, [key, value]) => {
		if (!value.priority) {
			acc[key] = value
		}
		return acc
	}, {})

	const options = Object.entries(filteredOperators).map(([key, value]) => ({
		label: value.label,
		value: key,
	}))

	const currentValue = options.find(
		(option) => option.value === condition.comparator,
	)

	const handleOnChange: (
		newValue: SingleValue<{
			label: string
			value: string
		}>,
	) => void = (option) => {
		if (!option) return
		handleUpdateInput(option.value)
	}

	return (
		<PanelSelect
			options={options}
			value={currentValue || null}
			controlStyles={{
				minHeight: 'fit-content',
			}}
			onChange={handleOnChange}
		/>
	)
}
