import { Flex, HStack, Text } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { components, MenuProps } from 'react-select'

import { TEXT_ENUM } from '@/styles/components/text'

import { BackButton } from '../../BackButton'

interface CustomMenuProps {
	handleBackClick: () => void
	selectedCategory: string | null
}

export const Menu = ({
	handleBackClick,
	selectedCategory,
	...props
}: CustomMenuProps & MenuProps<any, boolean, any>) => {
	return (
		<components.Menu {...props}>
			{selectedCategory && (
				<Flex w="100%" borderBottom={`1px solid ${ColorTokens.border_primary}`}>
					<HStack
						ml={2}
						gap={1}
						cursor="pointer"
						w="fit-content"
						onClick={handleBackClick}
					>
						<BackButton aria-label="Back" />
						<Text
							w="220px"
							variant={TEXT_ENUM.medium12}
							color={COLORS.gray[12]}
							overflow="hidden"
							whiteSpace="nowrap"
							textOverflow="ellipsis"
						>
							{selectedCategory}
						</Text>
					</HStack>
				</Flex>
			)}
			{props.children}
		</components.Menu>
	)
}
