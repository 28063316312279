import { Flex } from '@chakra-ui/react'
import { Icons } from 'ui'

import { MetaDataTag } from './MetaDataTag'

type Props = {
	tags: { label: string; icon?: Icons }[]
}

export const MetaDataTagList = ({ tags }: Props) => {
	if (!tags || !tags.length) return null

	return (
		<Flex flexWrap="wrap" gap={2}>
			{tags.map(({ label, icon }) => {
				return <MetaDataTag key={label} label={label} icon={icon} />
			})}
		</Flex>
	)
}
