import { Flex } from '@chakra-ui/react'
import { Icons } from '@ds/Icons'
import { FONT_SIZES } from '@ds/index'
import { useDispatch } from 'react-redux'

import { workflowsActions } from '@/modules/workflow/slice'

import { PanelInputLabel } from '../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../panel-variants/PanelSelect'

interface TestSettingsProps {
	settings: {
		meetingBooked: {
			value: string
			label: string
		}
	} | null
}

export const TestSettings = ({ settings }: TestSettingsProps) => {
	const dispatch = useDispatch()

	return (
		<Flex direction="column">
			<PanelInputLabel label="Settings" leftIcon={Icons.parameters} ml={-0.5} />
			<Flex direction="column" gap={4} mt={2}>
				<Flex direction="column">
					<PanelInputLabel
						label="Display Scheduler branch"
						description="Upon reaching a 'Display Scheduler' node during testing, the following branch will be executed"
						fontSize={FONT_SIZES.xs}
					/>
					<PanelSelect
						value={
							settings?.meetingBooked || {
								value: 'true',
								label: 'Meeting booked',
							}
						}
						options={[
							{ value: 'true', label: 'Meeting booked' },
							{ value: 'false', label: 'Meeting not booked' },
						]}
						onChange={(option) => {
							if (!option) return
							dispatch(
								workflowsActions.setTestWorkflowSettings({
									meetingBooked: option,
								}),
							)
						}}
					/>
				</Flex>
			</Flex>
		</Flex>
	)
}
