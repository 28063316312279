import { MotionFlex } from '@/components/MotionFlex'

import { ExpandableContextProvider } from './ExpandableContext'

type Props = {
	children: React.ReactNode
	expandable?: boolean
	onExpand?: (isExpanded: boolean) => void
	expanableId?: string
}

export const Expandable = ({
	children,
	expandable = false,
	onExpand,
	expanableId,
}: Props) => {
	return (
		<ExpandableContextProvider
			expandable={expandable}
			onExpand={onExpand}
			expandableId={expanableId}
		>
			<MotionFlex w="full" h="full" direction="column">
				{children}
			</MotionFlex>
		</ExpandableContextProvider>
	)
}
