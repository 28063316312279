import { CLEARBIT_STATES } from '../constants/clearbit-states'

export const getClearbitStateOptions = (): {
	label: string
	value: string
}[] => {
	return CLEARBIT_STATES.map((state: string) => ({
		label: state,
		value: state,
	}))
}
