import { useFlags } from 'launchdarkly-react-client-sdk'
import { Navigate, Outlet } from 'react-router-dom'

export const FeatureFlagGuard = ({
	flag,
	fallback,
}: {
	flag: string
	fallback: boolean
}) => {
	const flags = useFlags()
	const value = flags[flag] === undefined ? fallback : flags[flag]

	if (value) {
		return <Outlet />
	}

	return <Navigate to="/403" />
}
