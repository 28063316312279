import { Button, Flex, Text } from '@chakra-ui/react'
import { ColorTokens } from '@ds/tokens/colors'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDeepCompareEffect } from 'react-use'
import { prettifyDateISOString } from 'shared-utils'
import { GetIcon, Icons } from 'ui'

import { useMember } from '@/app/user/api/members/selector'
import { useCreateTestSubmissionMutation } from '@/modules/forms/api'
import { useGetQuestionsByFormIdQuery } from '@/modules/forms/api/questions'
import { ResponseEntity } from '@/modules/forms/types'
import { useGetTestSubmissionResponsesQuery } from '@/modules/submission/api'
import { BTN } from '@/styles/components/button'
import { TEXT_ENUM } from '@/styles/components/text'

import { useExecuteWorkflowMutation } from '../api'
import { PanelContentHeader } from '../components/side-panel/panel-content/PanelContentHeader'
import { TestQuestions } from '../components/side-panel/panel-content/test-workflow/TestQuestions'
import { TestSettings } from '../components/side-panel/panel-content/test-workflow/TestSettings'
import { TestStepModal } from '../components/side-panel/panel-content/test-workflow/TestStepModal'
import { PanelSectionDivider } from '../components/side-panel/panel-variants/PanelSectionDivider'
import { useCanvasNodes, useSelectedWorkflow } from '../hooks'
import { useTestSteps } from '../hooks/useTestSteps'
import { useTestWorkflowAvailable } from '../hooks/workflow'
import { workflowsActions } from '../slice'
import {
	selectTestWorkflowFormData,
	selectTestWorkflowSettings,
	selectTestWorkflowSteps,
} from '../slice/selectors'
import { NavigationId } from '../types/navigation'
import { WorkflowIntegrationIds } from '../utils/mappings/integrations'

export const TestWorkflowPartial = () => {
	const member = useMember()?.member
	const { selectedWorkflow } = useSelectedWorkflow()
	const [executeWorkflow, { isLoading: isExecuteWorkflowLoading }] =
		useExecuteWorkflowMutation()
	const [createTestSubmission, { isLoading: isCreateSubmissionLoading }] =
		useCreateTestSubmissionMutation()

	const canvasNodes = useCanvasNodes()?.canvasNodes
	const nodes = Object.values(canvasNodes || {})
	const hasDisplaySchedulerNode = nodes.some(
		(node) =>
			node.data.integrationId ===
			WorkflowIntegrationIds.defaultDisplayScheduler,
	)

	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleModalOpen = () => {
		setIsModalOpen(true)
	}

	const handleModalClose = () => {
		setIsModalOpen(false)
	}

	const [testSubmissionId, setTestSubmissionId] = useState<string | null>(null)

	const { data: questions } = useGetQuestionsByFormIdQuery(
		String(selectedWorkflow?.id || ''),
		{
			skip: !selectedWorkflow?.id,
		},
	)

	const {
		data: testSubmissionResponses,
		isLoading: isTestSubmissionResponsesLoading,
	} = useGetTestSubmissionResponsesQuery(selectedWorkflow?.id.toString())

	const dispatch = useDispatch()
	const formData = useSelector(selectTestWorkflowFormData)
	const settings = useSelector(selectTestWorkflowSettings)
	const testWorkflowSteps = useSelector(selectTestWorkflowSteps)
	const isTestWorkflowAvailable = useTestWorkflowAvailable()

	const initialSteps = [
		{ id: 'create', label: 'Creating test submission' },
		{ id: 'run', label: 'Running test workflow' },
		{ id: 'complete', label: 'Test complete' },
	]

	const {
		steps,
		startTest,
		updateStepStatus,
		updateStep,
		updateRunningStep,
		addStep,
		resetSteps,
	} = useTestSteps(initialSteps)

	useDeepCompareEffect(() => {
		if (
			questions &&
			questions.length > 0 &&
			!isTestSubmissionResponsesLoading
		) {
			const initialFormData = testSubmissionResponses
				? testSubmissionResponses.reduce((acc, response) => {
						acc[response.question_id] =
							formData[response.question_id] || response.value
						return acc
					}, {})
				: questions.reduce((acc, question) => {
						acc[question.id] = formData[question.id] || []
						return acc
					}, {})

			// Filter out any non-string values from legacy responses
			Object.keys(initialFormData).forEach((key) => {
				initialFormData[key] = initialFormData[key].filter(
					(value) => typeof value === 'string',
				)
			})
			dispatch(workflowsActions.setTestWorkflowFormData(initialFormData))
		}
	}, [
		questions,
		testSubmissionResponses,
		formData,
		isTestSubmissionResponsesLoading,
	])

	const handleExecute = async () => {
		if (!selectedWorkflow || !settings) return
		handleModalOpen()
		dispatch(workflowsActions.setTestWorkflowSteps([]))
		resetSteps()
		startTest()

		try {
			// Creating test submission
			updateStepStatus('create', 'running')
			const responses: ResponseEntity[] = Object.entries(formData).map(
				([key, value]) => ({
					id: key,
					question_id: Number(key),
					value: [value].flat(),
					form_version: -1,
				}),
			)
			const email = [
				Object.entries(formData).find(
					([key]) =>
						questions?.find((question) => question.id === Number(key))
							?.question_type === 'Email Input',
				)?.[1],
			].flat()[0]

			if (!email) return

			let meeting_booked: any = undefined
			if (settings.meetingBooked.value === 'true') {
				const meeting = {
					id: -1,
					date: new Date().toISOString(),
					title: 'Test Meeting',
					queue: {
						id: -1,
						name: 'Test Queue',
					},
				}
				const prettyDate = prettifyDateISOString(
					meeting.date,
					member?.timezone || 'America/Los_Angeles',
				)
				meeting_booked = {
					id: meeting.id,
					member_name: `${member?.first_name} ${member?.last_name}`,
					member_id: member?.id,
					member_email: member?.email,
					durationInMinutes: 30,
					startDateTime: meeting.date,
					startDate: meeting.date.split('T')[0],
					prettyStartDateTime: prettyDate.prettyString || meeting.date,
					title: meeting.title,
					queue_id: meeting.queue.id,
					queue_name: meeting.queue.name,
				}
			}

			const submission = await createTestSubmission({
				submission: {
					form_id: selectedWorkflow.id,
					team_id: selectedWorkflow.team_id,
					email,
					responses,
					completed: false,
					form_version: -1,
					...(settings.meetingBooked.value === 'true' && {
						past_data: {
							meeting_booked,
						},
					}),
				},
			})
			if (!submission.data) return

			updateStepStatus('create', 'success')

			// Running test workflow
			updateStepStatus('run', 'running')
			const submissionId = submission.data.id.toString()
			setTestSubmissionId(submissionId)
			const result = await executeWorkflow({
				submissionId,
				isTestWorkflow: true,
			})
			if (!result.data) {
				throw new Error(
					result.error && 'data' in result.error
						? (result.error.data as any)?.message || 'Error executing workflow'
						: 'Error executing workflow',
				)
			}

			const { url, startNodeId, inline } = result.data
			const finalStartNodeId =
				startNodeId || url?.split(`${submissionId}/`)[1]?.split('?')[0]
			if (url && finalStartNodeId) {
				if (inline === true) {
					const resumeStep = `Running ${
						settings.meetingBooked.value === 'true'
							? 'meeting booked'
							: 'meeting not booked'
					} branch`
					updateStepStatus('run', 'success')
					addStep({ id: 'resume', label: resumeStep, status: 'running' }, 'run')
				}

				const resumeWorkflowExecutionPayload = {
					submissionId,
					isTestWorkflow: true,
					startNodeId: finalStartNodeId,
					...(inline === true && {
						isMeetingNotBooked: settings.meetingBooked.value === 'false',
					}),
				}

				await executeWorkflow(resumeWorkflowExecutionPayload)

				if (inline === true) {
					updateStepStatus('resume', 'success')
				}
			}

			updateStepStatus('run', 'success')

			if (!url || !finalStartNodeId || !inline) {
				const skipStep = 'No Display Scheduler branch needed'
				addStep({ id: 'skip', label: skipStep, status: 'skipped' }, 'run')
			}

			// Complete
			updateStep(
				'complete',
				{
					status: 'success',
				},
				(steps) => {
					dispatch(workflowsActions.setTestWorkflowSteps(steps))
				},
			)
		} catch (error) {
			updateRunningStep({
				status: 'error',
			})
			addStep(
				{
					id: 'error',
					label: `Error: ${(error as any)?.message || 'Unknown error'}`,
					status: 'error',
				},
				'complete',
			)
		}
	}

	const isExecuteButtonDisabled =
		!questions ||
		Object.entries(formData).length !== questions.length ||
		Object.entries(formData).some(
			([key, value]) =>
				questions?.find((question) => question.id === Number(key))?.required &&
				![value].flat()[0],
		)

	return (
		<Flex direction="column">
			<TestStepModal
				isOpen={isModalOpen}
				steps={testWorkflowSteps.length ? testWorkflowSteps : steps}
				workflowId={selectedWorkflow?.id}
				submissionId={testSubmissionId}
				onClose={handleModalClose}
			/>

			<PanelContentHeader
				title="Test Workflow"
				titleIcon={Icons.test_tube}
				enableNavigation
				onNavigationBackClick={() => {
					dispatch(
						workflowsActions.setSelectedSidePanelNavigationId(
							NavigationId.Overview,
						),
					)
				}}
			/>

			{isTestWorkflowAvailable && !questions && (
				<Flex direction="column" justify="center" align="center" my="50%">
					<GetIcon icon={Icons.warning_circle} boxSize={5} />
					<Text>No questions found</Text>
					<Text variant={TEXT_ENUM.regular12}>
						Please add questions to your form to test the workflow
					</Text>
				</Flex>
			)}

			{isTestWorkflowAvailable && (
				<>
					{!questions || questions.length === 0 ? (
						<Flex direction="column" justify="center" align="center" my="50%">
							<GetIcon icon={Icons.warning_circle} boxSize={5} />
							<Text>No questions found</Text>
							<Text variant={TEXT_ENUM.regular12}>
								Please add questions to your form to test the workflow
							</Text>
						</Flex>
					) : (
						<Flex direction="column" pb={32}>
							<TestQuestions questions={questions} formData={formData} />

							{hasDisplaySchedulerNode && (
								<>
									<PanelSectionDivider my={6} />
									<TestSettings settings={settings} />
								</>
							)}

							<Flex
								direction="column"
								gap={4}
								position="absolute"
								bottom="1px"
								px={4}
								pb={4}
								mx={-4}
								width="370px"
								borderRadius="md"
								bg={ColorTokens.bg_card}
							>
								<PanelSectionDivider />
								<Button
									variant={BTN.primary}
									onClick={handleExecute}
									isDisabled={isExecuteButtonDisabled}
									isLoading={
										isExecuteWorkflowLoading || isCreateSubmissionLoading
									}
								>
									Run Test
								</Button>
							</Flex>
						</Flex>
					)}
				</>
			)}
		</Flex>
	)
}
