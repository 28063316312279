import { HStack, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

type Props = {
	label: string
	icon?: Icons
}

export const MetaDataTag = ({ label, icon }: Props) => {
	if (!label) return null

	return (
		<HStack
			px={2}
			py={1}
			borderRadius="6px"
			border={Borders.primary}
			borderColor={COLORS.purple[4]}
			bg={COLORS.purple[2]}
			color={COLORS.purple[9]}
			maxW={250}
		>
			{icon && <GetIcon icon={icon} />}
			<Text w="full" maxW="225px" variant={TEXT_ENUM.medium14} noOfLines={2}>
				{label}
			</Text>
		</HStack>
	)
}
