import { RootState } from '@/app/store'

export const selectIsSidePanelContentOpen = (state: RootState) =>
	state.workflows.isSidePanelContentOpen

export const selectSelectedSidePanelNavigationId = (state: RootState) =>
	state.workflows.selectedSidePanelNavigationId

export const selectSelectedWorkflowId = (state: RootState) =>
	state.workflows.selectedWorkflowId

export const selectSelectedCanvasNodeId = (state: RootState) =>
	state.workflows.selectedCanvasNodeId

export const selectDroppableBranchId = (state: RootState) =>
	state.workflows.droppableBranchId

export const selectSyncWorkflow = (state: RootState) =>
	state.workflows.syncWorkflow

export const selectSalesforceFields = (state: RootState) =>
	state.workflows.salesforceFields

export const selectHubspotProperties = (state: RootState) =>
	state.workflows.hubspotProperties

export const selectCanvasNodeMenuOpenId = (state: RootState) =>
	state.workflows.canvasNodeMenuOpenId

export const selectConditionalObject = (state: RootState) =>
	state.workflows.conditionalObject

export const selectCurrentNodeData = (state: RootState) =>
	state.workflows.currentNodeData

export const selectCurrentCanvasNodes = (state: RootState) =>
	state.workflows.currentCanvasNodes

export const selectClickToConnectInteraction = (state: RootState) =>
	state.workflows.clickToConnectInteraction

export const selectIntegrationListItemToConnect = (state: RootState) =>
	state.workflows.integrationListItemToConnect

export const selectTestWorkflowFormData = (state: RootState) =>
	state.workflows.testWorkflowFormData

export const selectTestWorkflowSettings = (state: RootState) =>
	state.workflows.testWorkflowSettings

export const selectTestWorkflowSteps = (state: RootState) =>
	state.workflows.testWorkflowSteps
