import { Button, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { useState } from 'react'
import { GetIcon, Icons } from 'ui'

import { Tag } from '@/common/components/Tag'
import { useUpdateFormMutation } from '@/modules/forms/api'
import { BTN } from '@/styles/components/button'
import { TEXT_ENUM } from '@/styles/components/text'

import { useSelectedWorkflow } from '../../hooks'
import { WebhookConfigModalAccordionLayout } from '../../layouts/WebhookConfigModalAccordionLayout'
import { WebhookPanelContentLayout } from '../../layouts/WebhookPanelContentLayout'
import { EditRow } from './EditRow'

type Props = {
	headers?: Record<string, string> | null
	isExpanded: boolean
}

export const WebhookHeadersConfig = ({ headers, isExpanded }: Props) => {
	const [edit, setEdit] = useState(false)

	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	const [updateWorkflow] = useUpdateFormMutation()

	const [localHeaders, setLocalHeaders] = useState(
		Object.entries(headers || {}),
	)

	if (!headers) return null

	const handleDataKeyChange = (index: number, value: string) => {
		const newHeaders = [...localHeaders]
		newHeaders[index][0] = value
		setLocalHeaders(newHeaders)
	}

	const handleDataValueChange = (index: number, value: string) => {
		const newHeaders = [...localHeaders]
		newHeaders[index][1] = value
		setLocalHeaders(newHeaders)
	}

	const handleRemoveKey = (index: number) => {
		const newHeaders = [...localHeaders]
		newHeaders.splice(index, 1)
		setLocalHeaders(newHeaders)
	}

	const handleAddKey = () => {
		const newHeaders = [...localHeaders]
		newHeaders.push(['', ''])
		setLocalHeaders(newHeaders)
	}

	const handleSaveChanges = () => {
		if (!selectedWorkflow?.id) return
		const headers = localHeaders.reduce(
			(acc, [key, value]) => ({ ...acc, [key]: value }),
			{},
		)
		updateWorkflow({
			form: {
				id: selectedWorkflow?.id,
				meta: {
					...selectedWorkflow?.meta,
					trigger_config: {
						...selectedWorkflow?.meta?.trigger_config,
						test_request_payload: {
							...selectedWorkflow?.meta?.trigger_config?.test_request_payload,
							headers,
						},
					},
				},
			},
		})

		setEdit(false)
	}

	return (
		<WebhookConfigModalAccordionLayout
			title="Headers"
			isExpanded={isExpanded}
			onEdit={() => setEdit(true)}
			edit={edit}
		>
			{!edit && (
				<WebhookPanelContentLayout>
					{localHeaders.map(([key, value]) => (
						<Flex key={key} justify="space-between">
							<Text variant={TEXT_ENUM.medium14}>{key}</Text>
							<Tag
								label={value}
								borderColor={COLORS.purple[4]}
								bg={COLORS.purple[2]}
								color={COLORS.purple[9]}
							/>
						</Flex>
					))}
				</WebhookPanelContentLayout>
			)}

			{edit && (
				<Flex flexDir="column" gap={2}>
					<Flex flexDir="column" gap={2}>
						{localHeaders.map(([key, value], index) => (
							<EditRow
								key={index}
								dataKey={key}
								dataValue={value}
								onDelete={() => handleRemoveKey(index)}
								onDataKeyChange={(e) =>
									handleDataKeyChange(index, e.target.value)
								}
								onDataValueChange={(e) =>
									handleDataValueChange(index, e.target.value)
								}
							/>
						))}
					</Flex>

					<Button
						w="fit-content"
						variant={BTN.secondary}
						leftIcon={<GetIcon icon={Icons.plus} />}
						onClick={handleAddKey}
					>
						Add field
					</Button>

					<Flex w="full" justifyContent="flex-end">
						<Button
							justifySelf="flex-end"
							w="fit-content"
							variant={BTN.outline}
							onClick={handleSaveChanges}
						>
							Save changes
						</Button>
					</Flex>
				</Flex>
			)}
		</WebhookConfigModalAccordionLayout>
	)
}
