import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface FormsImportState {
	importFormId: number | null
}

export const initialState: FormsImportState = {
	importFormId: null,
}

export const formsImportSlice = createSlice({
	name: 'form-import',
	initialState,
	reducers: {
		setImportFormId: (state, action: PayloadAction<number | null>) => {
			state.importFormId = action.payload
		},
	},
})

export const formsImportActions = formsImportSlice.actions
export const formsImportReducer = formsImportSlice.reducer
