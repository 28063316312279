import { Button, Flex, HStack, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { useCopyToClipboard } from 'react-use'
import { GetIcon, Icons } from 'ui'

import { useCustomToast } from '@/components/Toast'
import { Borders } from '@/styles/borders'
import { BTN } from '@/styles/components/button'
import { TEXT_ENUM } from '@/styles/components/text'

type Props = {
	url: string
}
export const CopyWebhookUrl = ({ url }: Props) => {
	const [, copyToClipboard] = useCopyToClipboard()

	const toast = useCustomToast()

	const handleCopy = () => {
		copyToClipboard(url)
		toast({
			title: 'Copied to clipboard',
			status: 'success',
			isClosable: true,
		})
	}

	return (
		<HStack gap={2} h="36px">
			<Flex
				alignItems="center"
				h="full"
				px={2.5}
				border={Borders.primary}
				borderColor={COLORS.background[10]}
				borderRadius="8px"
				bg={COLORS.background[12]}
				flexShrink={1}
				maxW="70%"
			>
				<Text
					variant={TEXT_ENUM.medium14}
					color={COLORS.background[6]}
					noOfLines={1}
				>
					{url}
				</Text>
			</Flex>
			<Button
				variant={BTN.secondary}
				flexShrink={0}
				h="full"
				borderRadius="8px"
				px={3}
				py={2.5}
				rightIcon={<GetIcon icon={Icons.copy} />}
				onClick={handleCopy}
			>
				Copy
			</Button>
		</HStack>
	)
}
