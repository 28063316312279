import { Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { TEXT_ENUM } from '@/styles/components/text'

type Props = {
	title: string
}

export const MetaDataTitle = ({ title }: Props) => {
	return (
		<Text variant={TEXT_ENUM.medium14} color={COLORS.background[6]}>
			{title}
		</Text>
	)
}
