import { Center, Flex, HStack, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import React from 'react'

import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

import { WorkflowsFilterBy } from '../components/WorkflowsFilterBy'
import { WorkflowsSortBy } from '../components/WorkflowsSortBy'
import { WorkflowsViewToggle } from '../components/WorkflowsViewToggle'

export const WorkflowsActionsPartial = React.memo(() => {
	return (
		<Flex justifyContent="space-between" p={6}>
			<Center
				h="36px"
				px={4}
				border={Borders.primary}
				bg={COLORS.background[12]}
				borderRadius="8px"
			>
				<Text variant={TEXT_ENUM.medium14} color={COLORS.background[5]}>
					All Workflows
				</Text>
			</Center>
			<HStack>
				<WorkflowsFilterBy />
				<WorkflowsSortBy />
				<WorkflowsViewToggle />
			</HStack>
		</Flex>
	)
})

WorkflowsActionsPartial.displayName = 'WorkflowsActionsPartial'
