import '@/styles/components/RichTextEditor.css'

import { Flex, FlexProps } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import React, { useCallback, useMemo, useState } from 'react'
import {
	BaseElement,
	BaseText,
	createEditor,
	Descendant,
	Editor,
	Range,
	Transforms,
} from 'slate'
import { HistoryEditor, withHistory } from 'slate-history'
import { Editable, ReactEditor, Slate, withReact } from 'slate-react'
import { GetIcon, Icons } from 'ui'

import { EditingBar } from './EditingBar'
import { deserialize, serialize } from './serialization'
import { TemplateVariable } from './TemplateVariable'
import { EditingBarButtonType } from './types'

declare module 'slate' {
	interface CustomTypes {
		Element: BaseElement & {
			type?: string
			label?: string
			data?: string | null
		}
		Text: BaseText & {
			type?: string
			bold?: boolean
			italic?: boolean
			strikethrough?: boolean
			label?: string
			data?: string | null
		}
	}
}

interface EditorProps extends Omit<FlexProps, 'onBlur' | 'onChange'> {
	onChange: (value: string) => void
	onBlur?: (value: string) => void
	minH?: string
	onClickEnter?: (value: string) => void
	bodyText: string
	editorCss?: React.CSSProperties
	editorClassName?: string
	disableEditingBar?: boolean
	disabledEditingBarButtons?: EditingBarButtonType[]
	showSendButton?: boolean
	placeholder?: string
	Picker?: React.ComponentType<{
		onSelect: (value) => void
	}>
}

const withTemplateVariables = (editor: ReactEditor & HistoryEditor) => {
	const { insertText, isInline, isVoid } = editor

	editor.isInline = (element) => {
		return element.type === 'template-variable' ? true : isInline(element)
	}

	editor.isVoid = (element) => {
		return element.type === 'template-variable' ? true : isVoid(element)
	}

	editor.insertText = (text) => {
		const { selection } = editor

		if (text === '{' && selection && Range.isCollapsed(selection)) {
			const { anchor } = selection
			const before = Editor.before(editor, anchor, { unit: 'character' })
			const beforeRange = before && Editor.range(editor, before, anchor)
			const beforeText = beforeRange && Editor.string(editor, beforeRange)

			if (beforeText === '{') {
				// // Delete the two '{' characters
				Transforms.delete(editor, { at: beforeRange })

				const templateVariable = {
					type: 'template-variable',
					label: '{{ Click to pick data }}',
					data: '{}',
					children: [{ text: '' }],
				}

				// Insert the template variable and a space
				Transforms.insertNodes(editor, [templateVariable, { text: ' ' }])

				return
			}
		}

		insertText(text)
	}

	return editor
}

const SlateRichTextEditor: React.FC<EditorProps> = ({
	onChange,
	onBlur,
	onClickEnter,
	bodyText,
	minH,
	editorCss,
	editorClassName,
	disableEditingBar,
	disabledEditingBarButtons,
	showSendButton,
	Picker,
	...props
}) => {
	const editor = useMemo(
		() => withTemplateVariables(withHistory(withReact(createEditor()))),
		[],
	)

	const initialValue = useMemo(() => {
		if (!bodyText) {
			return [{ type: 'paragraph', children: [{ text: '' }] }]
		}
		const deserialized = deserialize(bodyText)
		return deserialized.length > 0
			? deserialized
			: [{ type: 'paragraph', children: [{ text: '' }] }]
	}, [bodyText])

	const [value, setValue] = useState<Descendant[]>(initialValue)

	const renderElement = useCallback((props) => {
		switch (props.element.type) {
			case 'blockquote':
				return <blockquote {...props.attributes}>{props.children}</blockquote>
			case 'bulleted-list':
				return <ul {...props.attributes}>{props.children}</ul>
			case 'numbered-list':
				return <ol {...props.attributes}>{props.children}</ol>
			case 'list-item':
				return <li {...props.attributes}>{props.children}</li>
			case 'template-variable':
				return <TemplateVariable {...props} editor={editor} Picker={Picker} />
			default:
				return (
					<p
						{...props.attributes}
						style={{
							margin: '0',
							minHeight: '1em',
						}}
					>
						{props.children}
					</p>
				)
		}
	}, [])

	const renderLeaf = useCallback((props) => {
		const { attributes, leaf } = props
		let { children } = props
		if (leaf.bold) {
			children = <strong>{children}</strong>
		}
		if (leaf.italic) {
			children = <em>{children}</em>
		}
		if (leaf.strikethrough) {
			children = <del>{children}</del>
		}
		return <span {...attributes}>{children}</span>
	}, [])

	const handleChange = (newValue: Descendant[]) => {
		setValue(newValue)
		onChange(serialize(newValue))
	}

	return (
		<Flex
			w="100%"
			direction="column"
			borderWidth="medium"
			borderColor={ColorTokens.border_primary}
			borderRadius="lg"
			bg={ColorTokens.white}
			{...props}
		>
			{!disableEditingBar && (
				<EditingBar
					editor={editor}
					disabledEditingBarButtons={disabledEditingBarButtons}
				/>
			)}
			<Slate editor={editor} initialValue={value} onChange={handleChange}>
				<Editable
					renderElement={renderElement}
					renderLeaf={renderLeaf}
					style={{
						padding: '8px 16px',
						fontFamily: 'Inter',
						fontSize: '14px',
						minHeight: minH || '240px',
						whiteSpace: 'pre-wrap',
						wordBreak: 'break-word',
						outline: 'none',
						...editorCss,
					}}
					className={editorClassName}
					onBlur={() => onBlur && onBlur(serialize(value))}
					onFocus={() => onBlur && onBlur(serialize(value))}
					onKeyDown={(event) => {
						if (event.key === 'Enter' && event.metaKey && onClickEnter) {
							event.preventDefault()
							onClickEnter(serialize(value))
						}
					}}
				/>
			</Slate>
			{showSendButton && (
				<Flex
					boxSize={5}
					position="absolute"
					zIndex={9}
					bottom={3}
					right={3}
					justify="center"
					align="center"
					onClick={() => onClickEnter && onClickEnter(serialize(value))}
					_hover={{ cursor: 'pointer' }}
				>
					<GetIcon icon={Icons.send} color={COLORS.background[4]} />
				</Flex>
			)}
		</Flex>
	)
}

export default SlateRichTextEditor
