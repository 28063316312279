import { COLORS } from '@ds/tokens/colors'
import { components, MultiValueRemoveProps } from 'react-select'
import { GetIcon, Icons } from 'ui'

export const MultiValueRemove = (
	props: MultiValueRemoveProps<any, boolean, any>,
) => {
	return (
		<components.MultiValueRemove {...props}>
			<GetIcon icon={Icons.close} fontSize="14px" color={COLORS.purple[9]} />
		</components.MultiValueRemove>
	)
}
