import { Button, Flex } from '@chakra-ui/react'
import { useCallback, useEffect, useMemo } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useCopyToClipboard } from 'react-use'
import { Icons } from 'ui'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { PageNav, PageTabs } from '@/common/components/PageHeader'
import { PageNavStepsType } from '@/common/components/PageHeader/PageNav'
import { LoadingPage } from '@/components/Loading'
import { useCustomToast } from '@/components/Toast'
import {
	useGetFormByIdQuery,
	usePublishFormMutation,
} from '@/modules/forms/api'
import { BTN } from '@/styles/components/button'

import { useTestWorkflowAvailable } from '../hooks/workflow'
import { workflowsActions } from '../slice'
import { selectSelectedWorkflowId } from '../slice/selectors'
import { NavigationId } from '../types/navigation'

const TABS = [
	{ title: 'Workflow', route: 'editor' },
	{ title: 'Logs', route: 'workflow-logs' },
]

export const WorkflowEditorLayout = () => {
	const { workflowId } = useParams()
	const dispatch = useAppDispatch()

	const [, copyToClipboard] = useCopyToClipboard()
	const isTestWorkflowAvailable = useTestWorkflowAvailable()

	const selectedWorkflowId = useAppSelector(selectSelectedWorkflowId)
	const [publishForm, { isLoading: isPublishing }] = usePublishFormMutation()

	const { data: selectedWorkflow, isLoading } = useGetFormByIdQuery(
		workflowId,
		{
			skip: !workflowId,
		},
	)

	useEffect(() => {
		if (selectedWorkflow?.id) {
			dispatch(workflowsActions.setSelectedWorkflowId(selectedWorkflow.id))
		}
	}, [dispatch, selectedWorkflow?.id, selectedWorkflow?.workflow])

	const toast = useCustomToast()

	const STEPS: PageNavStepsType[] = useMemo(() => {
		return [
			{
				title: 'Workflows',
				route: '/workflows',
				icon: Icons.workflow,
			},
			{
				title:
					selectedWorkflow?.workflow_name ||
					selectedWorkflow?.name ||
					'Untitled workflow',
			},
		]
	}, [selectedWorkflow?.workflow_name, selectedWorkflow?.name])

	const handlePublish = useCallback(() => {
		if (!selectedWorkflow) return

		publishForm({
			formId: selectedWorkflow.id.toString(),
		})
			.unwrap()
			.then(() => {
				toast({
					status: 'success',
					title: 'Workflow published!',
				})
			})
			.catch(() => {
				toast({
					status: 'error',
					title: 'Failed to publish workflow!',
				})
			})
	}, [publishForm, selectedWorkflow, toast])

	const handleTest = useCallback(() => {
		if (!isTestWorkflowAvailable) return

		dispatch(
			workflowsActions.setSelectedSidePanelNavigationId(
				NavigationId.TestWorkflow,
			),
		)
		dispatch(workflowsActions.setIsSidePanelContentOpen(true))
	}, [dispatch, isTestWorkflowAvailable])

	const handleShare = useCallback(() => {
		copyToClipboard(window.location.href)
		toast({
			title: 'Link copied to clipboard',
			status: 'success',
		})
	}, [copyToClipboard, toast])

	if (isLoading || selectedWorkflowId !== Number(workflowId))
		return <LoadingPage />

	if (!selectedWorkflow || !selectedWorkflowId) return null

	return (
		<Flex flexDir="column" flex="1">
			<PageNav steps={STEPS} variant="secondary">
				<Flex w="full" justifyContent="flex-end" gap={3}>
					<Button variant={BTN.secondary_v2} onClick={handleShare} size="sm">
						Share
					</Button>
					{isTestWorkflowAvailable && (
						<Button size="sm" variant={BTN.secondary_v2} onClick={handleTest}>
							Test
						</Button>
					)}
					<Button
						size="sm"
						variant={BTN.primary_v2}
						onClick={handlePublish}
						isLoading={isPublishing}
						disabled={selectedWorkflow?.published}
					>
						Publish
					</Button>
				</Flex>
			</PageNav>
			<PageTabs tabs={TABS} />
			<Outlet />
		</Flex>
	)
}
