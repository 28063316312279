import { Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { flexRender, Table } from '@tanstack/react-table'
import { useNavigate, useParams } from 'react-router-dom'

import { ErrorComponent } from '@/common/components/Error'
import { Borders } from '@/styles/borders'
import { HIDE_SCROLLBAR_CLASS } from '@/utils/constants'

import { WorkflowLog } from '../../types'
import { LogsTableBodyEmpty } from './LogsTableBodyEmpty'
import { LogsTableBodyLoading } from './LogsTableBodyLoading'
import { WorkflowLogsTableColumns } from './LogsTableSetup'

type Props = {
	table: Table<WorkflowLog>
	isError?: boolean
	isLoading?: boolean
}

export const LogsTableRows = ({ table, isError, isLoading }: Props) => {
	const navigate = useNavigate()
	const rows = table.getRowModel()?.rows || []

	const { formId } = useParams()
	const { workflowId } = useParams()

	if (isError)
		return (
			<ErrorComponent
				error="We could not serve the logs currently."
				minH="500px"
			/>
		)
	if (isLoading) return <LogsTableBodyLoading />
	if (rows.length === 0) return <LogsTableBodyEmpty />

	const handleRowClick = (row: WorkflowLog) => {
		if (workflowId) navigate(`/workflows/workflow-log/details/${row.id}`)
		if (formId) navigate(`/forms/workflow-log/details/${row.id}`)
	}

	return (
		<Flex
			flexDir="column"
			w="full"
			h="fit-content"
			overflowY="scroll"
			className={HIDE_SCROLLBAR_CLASS}
		>
			{rows.map((row, index) => {
				return (
					<Flex
						key={row.id}
						p={4}
						align="center"
						borderBottom={index < rows.length - 1 ? Borders.primary : 'none'}
						_hover={{
							bg: COLORS.background[12],
						}}
						cursor="pointer"
						onClick={() => handleRowClick(row.original)}
					>
						{row.getVisibleCells().map((cell) => {
							return (
								<Flex
									key={cell.id}
									className={HIDE_SCROLLBAR_CLASS}
									overflowX="scroll"
									w={cell.column.getSize()}
									flex="1"
									color={COLORS.background[2]}
									fontSize="14px"
									fontWeight={
										cell.column.id === WorkflowLogsTableColumns.email
											? 500
											: 400
									}
								>
									<Text>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</Text>
								</Flex>
							)
						})}
					</Flex>
				)
			})}
		</Flex>
	)
}
