import {
	Input,
	InputGroup,
	InputLeftElement,
	InputProps,
	LayoutProps,
} from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { SHADOWS } from '@/styles/constants'

interface Props extends InputProps, LayoutProps {
	showIcon?: boolean
}

export const SearchInput = ({ showIcon = true, ...props }: Props) => {
	return (
		<InputGroup w={props.w || '288px'} h="36px" borderRadius="8px">
			{showIcon && (
				<InputLeftElement
					pointerEvents="none"
					children={
						<GetIcon icon={Icons.search} color={COLORS.background[7]} />
					}
				/>
			)}

			<Input
				h="36px"
				borderColor={COLORS.background[9]}
				bg={COLORS.whiteAlpha[12]}
				fontSize="14px"
				fontWeight={500}
				_focus={{
					boxShadow: SHADOWS.focused,
					borderColor: 'transparent',
				}}
				_hover={{
					borderColor: COLORS.background[7],
					_disabled: {
						borderColor: COLORS.background[9],
					},
				}}
				_placeholder={{
					color: COLORS.background[6],
				}}
				{...props}
			/>
		</InputGroup>
	)
}
