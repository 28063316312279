import { useCallback } from 'react'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { settleAllOptionGenerators } from '../../../utils/settleAllOptionGenerators'
import { useGetDefaultDrpOptions } from './useGetDefaultDrpOptions'

export const useGetSlackMessageDrpOptions = (): AsyncDrpOptionGenerator => {
	const getDefaultDrpOptions = useGetDefaultDrpOptions()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const optionGenerators = [getDefaultDrpOptions]

			return settleAllOptionGenerators(optionGenerators, onSelect)
		},
		[getDefaultDrpOptions],
	)
}
