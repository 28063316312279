import React from 'react'

type RequiredSelectOptionType = {
	label: string
	value: any
	prefix?: React.ReactNode
	suffix?: React.ReactNode
}

export type SelectOptionType<T = any> = RequiredSelectOptionType & T

export type SelectGroupOptionType = {
	label: string
	options: SelectOptionType[]
}

export enum SelectVariant {
	primary = 'primary',
	secondary = 'secondary',
}
