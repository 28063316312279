import React, { createContext, ReactNode, useContext, useState } from 'react'

type ExpandableGroupContextType = {
	activeExpandableId: string | null
	setActiveExpandableId: (id: string | null | undefined) => void
}

export const ExpandableGroupContext = createContext<ExpandableGroupContextType>(
	{
		activeExpandableId: null,
		setActiveExpandableId: (id) => {
			console.error(
				'Not using ExpandableGroupProvider to set expanded ids.',
				id,
			)
		},
	},
)

type ExpandableGroupProviderProps = {
	children: ReactNode
}

export const ExpandableGroupProvider: React.FC<
	ExpandableGroupProviderProps
> = ({ children }) => {
	const [activeExpandableId, setActiveExpandableId] = useState<string | null>(
		null,
	)

	const handleSetExpandedId = (id) => {
		setActiveExpandableId((currentId) => (currentId === id ? null : id))
	}

	return (
		<ExpandableGroupContext.Provider
			value={{
				activeExpandableId,
				setActiveExpandableId: handleSetExpandedId,
			}}
		>
			{children}
		</ExpandableGroupContext.Provider>
	)
}

export const useExpandableGroupContext = () => {
	const context = useContext(ExpandableGroupContext)
	if (!context) {
		return {
			activeExpandableId: null,
			setActiveExpandableId: () => {
				console.error('Not using ExpandableGroupProvider to set expanded ids.')
			},
		}
	}
	return context
}
