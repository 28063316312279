import { cloneDeep } from 'lodash'

import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'
import { checklistApi, ChecklistEndpoints } from '@/modules/guide/api'
import { queuesApi } from '@/modules/queues/api'

import {
	AttachQueuePayload,
	CreateEventPayload,
	DetachQueuePayload,
	EventQueuesTable,
	EventReminderTable,
	LinkedForm,
	SchedulerEvent,
	SchedulerEventWithQueues,
	UpdateEventPayload,
	UpdateEventReminderPayload,
} from './type'

export enum EventEndPoints {
	/** Query */
	getAllEvents = 'getAllEvents',
	getEventById = 'getEventById',
	getWorkflowsThatReferenceEventById = 'getWorkflowsThatReferenceEventById',
	/** Mutation */
	createEvent = 'createEvent',
	updateEvent = 'updateEvent',
	duplicateEvent = 'duplicateEvent',
	deleteEvent = 'deleteEvent',

	// event remainders
	updateRemindersByEventId = 'updateRemindersByEventId',

	// event queues
	attachQueueToEvent = 'attachQueueToEvent',
	detachQueueFromEvent = 'detachQueueFromEvent',
}

const eventsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[EventEndPoints.getEventById]: builder.query<
			SchedulerEventWithQueues,
			string | undefined
		>({
			query: (eventId) => ({
				url: `/events/${eventId}`,
				responseHandler: (response) =>
					response.json().then((data) => {
						if (data?.data && Array.isArray(data?.data?.reminders)) {
							return {
								...data?.data,
								reminders: data?.data?.reminders[0],
							}
						}
						return { ...data.data }
					}),
			}),
		}),
		[EventEndPoints.getAllEvents]: builder.query<SchedulerEvent[], void>({
			query: () => ({
				url: '/events',
				responseHandler: (response: Response) => {
					return response.json().then((data) =>
						data.data?.map((event) => ({
							...event,
						})),
					)
				},
			}),
			providesTags: ['GET_ALL_EVENTS'],
		}),
		[EventEndPoints.getWorkflowsThatReferenceEventById]: builder.query<
			LinkedForm[],
			string
		>({
			query: (eventId) => ({
				url: `/events/${eventId}/linked/workflows`,
				responseHandler: responseTransformer,
			}),
		}),

		[EventEndPoints.createEvent]: builder.mutation<
			SchedulerEvent,
			CreateEventPayload
		>({
			query: (payload) => ({
				url: `/events`,
				method: 'POST',
				body: payload,
				responseHandler: responseTransformer,
			}),
			invalidatesTags: ['GET_ALL_EVENTS'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchAllEvents = dispatch(
					eventsApi.util.updateQueryData(
						EventEndPoints.getAllEvents,
						undefined,
						(draft) => {
							const newEvent = {
								...arg.event,
								id: new Date().getTime(),
								name: arg?.event?.name || 'New Event Type',
								title: arg?.event?.title || 'Untitled Event',
								type: arg?.event?.type || 'global',
								duration: arg?.event?.duration || 1800,
								enabled: true,
								active_time: null,
								description: '',
							} as SchedulerEvent

							draft.unshift(newEvent)

							return draft
						},
					),
				)

				const patchChecklist = dispatch(
					checklistApi.util.updateQueryData(
						ChecklistEndpoints.getChecklist,
						undefined,
						(draft) => {
							return {
								...draft,
								isEventCreated: true,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchAllEvents.undo()
					patchChecklist.undo()
				}
			},
		}),
		[EventEndPoints.updateEvent]: builder.mutation<
			SchedulerEvent,
			UpdateEventPayload
		>({
			query: (payload) => ({
				url: `/events/${payload.event.id}`,
				method: 'PATCH',
				body: payload,
				responseHandler: responseTransformer,
			}),

			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchEvent = dispatch(
					eventsApi.util.updateQueryData(
						EventEndPoints.getEventById,
						arg.event.id.toString(),
						(draft) => {
							return {
								...draft,
								...arg.event,
							}
						},
					),
				)

				const patchAllEvents = dispatch(
					eventsApi.util.updateQueryData(
						EventEndPoints.getAllEvents,
						undefined,
						(draft) => {
							const found = draft.find((e) => e.id === arg.event.id)

							if (found) {
								return draft.map((e) => {
									if (e.id === arg.event.id) {
										return {
											...e,
											...arg.event,
										}
									}
									return e
								})
							}

							return draft
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchEvent.undo()
					patchAllEvents.undo()
				}
			},
		}),
		[EventEndPoints.duplicateEvent]: builder.mutation<SchedulerEvent, number>({
			query: (eventId) => ({
				url: `/events/${eventId}`,
				method: 'POST',
				responseHandler: responseTransformer,
			}),
			invalidatesTags: ['GET_ALL_EVENTS'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchAllEvents = dispatch(
					eventsApi.util.updateQueryData(
						EventEndPoints.getAllEvents,
						undefined,
						(draft) => {
							const found = draft.find((e) => e.id === arg)

							if (found) {
								const copied = cloneDeep(found)
								copied.name = `${found.name} (Copy)`
								copied.title = `${found.title} (Copy)`
								copied.id = new Date().getTime()
								draft.unshift(copied)
							}

							return draft
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchAllEvents.undo()
				}
			},
		}),

		[EventEndPoints.deleteEvent]: builder.mutation<SchedulerEvent, number>({
			query: (eventId) => ({
				url: `/events/${eventId}`,
				method: 'DELETE',
				responseHandler: responseTransformer,
			}),
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchAllEvents = dispatch(
					eventsApi.util.updateQueryData(
						EventEndPoints.getAllEvents,
						undefined,
						(draft) => {
							return draft.filter((event) => event.id !== arg)
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchAllEvents.undo()
				}
			},
		}),

		[EventEndPoints.updateRemindersByEventId]: builder.mutation<
			EventReminderTable,
			UpdateEventReminderPayload
		>({
			query: (payload) => ({
				url: `/events/${payload.reminders.event_id}/reminders`,
				method: 'POST',
				body: payload,
				responseHandler: responseTransformer,
			}),
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchEvent = dispatch(
					eventsApi.util.updateQueryData(
						EventEndPoints.getEventById,
						arg.reminders.event_id.toString(),
						(draft) => {
							let newReminders = cloneDeep(arg.reminders)
							newReminders = {
								...newReminders,
								...arg.reminders,
							}
							draft.reminders = newReminders as EventReminderTable
							return draft
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchEvent.undo()
				}
			},
		}),

		[EventEndPoints.attachQueueToEvent]: builder.mutation<
			EventQueuesTable,
			AttachQueuePayload
		>({
			query: (payload) => ({
				url: `/events/${payload.eventId}/queues/${payload.queueId}`,
				method: 'POST',
			}),
			onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
				const state = getState()
				const allQueues = queuesApi.endpoints.getAllQueues.select()(state)?.data

				const queue =
					allQueues?.find(
						(q) => q.id?.toString() === arg.queueId?.toString(),
					) || {}

				const patchEvent = dispatch(
					eventsApi.util.updateQueryData(
						EventEndPoints.getEventById,
						arg.eventId.toString(),
						(draft) => {
							const newQueue = {
								queue: {
									...queue,
								},
							} as any

							draft.queues.push(newQueue)
							return draft
						},
					),
				)
				try {
					await queryFulfilled
				} catch {
					patchEvent.undo()
				}
			},
		}),

		[EventEndPoints.detachQueueFromEvent]: builder.mutation<
			EventQueuesTable,
			DetachQueuePayload
		>({
			query: (payload) => ({
				url: `/events/${payload.eventId}/queues/${payload.queueId}`,
				method: 'DELETE',
			}),
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchEvent = dispatch(
					eventsApi.util.updateQueryData(
						EventEndPoints.getEventById,
						arg.eventId.toString(),
						(draft) => {
							draft.queues = draft.queues.filter(
								(q) => q.queue.id !== arg.queueId,
							)
							return draft
						},
					),
				)
				try {
					await queryFulfilled
				} catch {
					patchEvent.undo()
				}
			},
		}),
	}),
})

export const {
	useGetEventByIdQuery,
	useDeleteEventMutation,
	useCreateEventMutation,
	useDuplicateEventMutation,
	useGetAllEventsQuery,
	useUpdateEventMutation,
	useUpdateRemindersByEventIdMutation,
	useLazyGetWorkflowsThatReferenceEventByIdQuery,
	useAttachQueueToEventMutation,
	useDetachQueueFromEventMutation,
	useLazyGetEventByIdQuery,
} = eventsApi

export { eventsApi }
