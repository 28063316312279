export const isServerSide = typeof window === 'undefined'

export const getFullNameOrEmail = ({
	firstName,
	lastName,
	email,
}: {
	firstName?: string | null
	lastName?: string | null
	email?: string | null
}) => {
	let fullNameOrEmail = ''

	if (firstName) {
		fullNameOrEmail = firstName + ' '
	}

	if (lastName) {
		fullNameOrEmail = fullNameOrEmail + lastName
	}

	if (!fullNameOrEmail && email) {
		fullNameOrEmail = email
	}

	return fullNameOrEmail || ''
}

export const getFullName = ({
	firstName,
	lastName,
}: {
	firstName?: string | null
	lastName?: string | null
}) => {
	let fullName = ''

	if (firstName) {
		fullName = firstName + ' '
	}

	if (lastName) {
		fullName = fullName + lastName
	}

	return fullName || ''
}

export const getFullUrl = (url: string) => {
	const fullUrl =
		url.startsWith('http://') || url.startsWith('https://')
			? url
			: 'https://' + url
	return fullUrl
}
