import { HStack, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { useNavigate } from 'react-router-dom'
import { GetIcon, Icons } from 'ui'

import { FormType } from '@/modules/forms/types'
import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

import { useSelectedWorkflow } from '../hooks'

type NavigateToFormCardProps =
	| { formId: number; formType: FormType }
	| { formId?: never; formType?: never }

export const NavigateToFormCard = ({
	formId,
	formType,
}: NavigateToFormCardProps) => {
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	const navigate = useNavigate()

	const handleNavigateToForm = () => {
		if (!selectedWorkflow) return
		navigate(
			`/forms/${formId || selectedWorkflow?.id}/${
				(formType || selectedWorkflow?.type) === FormType.regular
					? 'editor'
					: 'import-fields'
			}`,
		)
	}

	return (
		<HStack
			p={3}
			border={Borders.primary}
			borderRadius="lg"
			bg={COLORS.background[12]}
			justifyContent="space-between"
			cursor="pointer"
			onClick={handleNavigateToForm}
		>
			<Text variant={TEXT_ENUM.medium14}>{selectedWorkflow?.name}</Text>
			<GetIcon
				icon={Icons.chevron_right}
				boxSize={5}
				color={COLORS.background[6]}
			/>
		</HStack>
	)
}
