import { Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'

import {
	useCurrentNodeData,
	useGetParameterInfo,
	useSelectedNode,
	useUpdateWorkflowNode,
} from '@/modules/workflow/hooks'
import { useGetDefaultDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetDefaultDrpOptions'
import { useGetParameterLabel } from '@/modules/workflow/hooks/useGetParameterLabel'
import {
	Apollo_Enrich,
	FieldRefNodeValue,
	LabeledRefNodeValue,
} from '@/modules/workflow/types/actions'

import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { DataReferencePicker } from '../../../DataReferencePicker'

const emptyDefault: Apollo_Enrich = {
	email: {
		refNodeId: null,
		variable: null,
		value: null,
		label: null,
	},
}

export const ApolloEnrich = () => {
	const updateNode = useUpdateWorkflowNode()
	const selectedNode = useSelectedNode()
	const getDrpOptions = useGetDefaultDrpOptions()
	const { getCurrentNodeData } = useCurrentNodeData(selectedNode)
	const stepDetails = (selectedNode?.data.stepDetails as Apollo_Enrich) || {}

	const updateNodeStepDetails = (newStepDetails: Apollo_Enrich) => {
		if (!selectedNode) return

		const currentNodeData = getCurrentNodeData()

		updateNode({
			...currentNodeData,
			data: {
				...currentNodeData.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const handleEmailSelect = (rfn: LabeledRefNodeValue) => {
		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Apollo_Enrich
		const newStepDetails = currentStepDetails || cloneDeep(emptyDefault)

		newStepDetails.email = rfn

		updateNodeStepDetails(newStepDetails)
	}

	const email = stepDetails?.email
	const selectedEmailAttribute = useGetParameterInfo({
		parameter: email as FieldRefNodeValue,
	})

	const selectedEmailLabel = useGetParameterLabel(
		email,
		selectedEmailAttribute.parameterAttribute,
	)

	const selectedEmailOption = selectedEmailLabel
		? {
				label: selectedEmailLabel,
				value: email.value,
			}
		: null

	return (
		<Flex w="100%" direction="column">
			<Flex direction="column">
				<PanelInputLabel label="Email" />
				<DataReferencePicker
					getInitialOptions={getDrpOptions}
					initialOption={selectedEmailOption}
					onSelect={handleEmailSelect}
				/>
			</Flex>
		</Flex>
	)
}
