import { Box, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { ConditionBlock } from '@/modules/workflow/types/logic'
import { TEXT_ENUM } from '@/styles/components/text'

import { ConditionDetail } from './ConditionDetail'

interface ConditionDetailsProps {
	conditionBlocks: ConditionBlock[]
	isMatchCriteria?: boolean
}

export const ConditionDetails = ({
	conditionBlocks,
	isMatchCriteria,
}: ConditionDetailsProps) => {
	return (
		<Flex direction="column" gap={2} mb={4}>
			{conditionBlocks.map((conditionBlock, blockIdx) => {
				return (
					<Flex key={blockIdx} direction="column" gap={2}>
						<Box
							width="fit-content"
							borderRadius="6px"
							py={1}
							px={2}
							bgColor={COLORS.background[5]}
						>
							<Text color={COLORS.whiteAlpha[12]} variant={TEXT_ENUM.medium12}>
								{blockIdx === 0 ? 'If value' : 'Or'}
							</Text>
						</Box>
						<Flex
							direction="column"
							bgColor="#FCFCFC"
							borderRadius="8px"
							border={`1px solid ${COLORS.background[10]}`}
							p={2.5}
							gap={3}
						>
							{conditionBlock.conditions.map((condition, conditionIdx) => (
								<ConditionDetail
									key={`${blockIdx}-${conditionIdx}`}
									condition={condition}
									conditionIndex={conditionIdx}
									isMatchCriteria={isMatchCriteria}
								/>
							))}
						</Flex>
					</Flex>
				)
			})}
		</Flex>
	)
}
