import { normalize, schema } from 'normalizr'

import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import {
	CreateTagPayload,
	DeleteTagPayload,
	fallbackTag,
	GetTagsPayload,
	Tag,
	UpdateTagPayload,
} from '../types'

export enum TagsEndPoints {
	/** Query */
	getTags = 'getTags',
	/** Mutation */
	createTag = 'createTag',
	updateTag = 'updateTag',
	deleteTags = 'deleteTags',
	archiveTags = 'archiveTags',
}

const tagsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[TagsEndPoints.getTags]: builder.query<
			{ [tagId: string]: Tag },
			GetTagsPayload
		>({
			query: (payload) => ({
				url: `tags/teams/${payload.teamId}?instances=${Boolean(
					payload?.instances,
				)}`,
				responseHandler: responseTransformer,
			}),
			providesTags: ['GET_TAGS'],
			transformResponse: (views: Tag[]) => {
				const viewsSchema = [new schema.Entity('tags')]
				return (
					normalize<Tag>(
						views.filter((t) => !t.archived_at),
						viewsSchema,
					).entities.tags || {}
				)
			},
		}),
		[TagsEndPoints.createTag]: builder.mutation<Tag, CreateTagPayload>({
			query: (payload) => ({
				url: `tags/teams/${payload.tag.team_id}`,
				method: 'POST',
				body: payload,
				responseHandler: responseTransformer,
			}),
			onQueryStarted: async (arg, api) => {
				const patchTags = api.dispatch(
					tagsApi.util.updateQueryData(
						TagsEndPoints.getTags,
						{ teamId: arg.tag.team_id },
						(draft) => {
							const now = new Date()
							draft['0'] = {
								...fallbackTag,
								...arg.tag,
								created_at: now.toISOString(),
							}
							return draft
						},
					),
				)

				try {
					await api.queryFulfilled
				} catch {
					patchTags.undo()
				}
			},
			invalidatesTags: ['GET_TAGS'],
		}),
		[TagsEndPoints.updateTag]: builder.mutation<Tag, UpdateTagPayload>({
			query: (payload) => ({
				url: `tags/${payload.tag.id}`,
				method: 'PATCH',
				body: payload,
				responseHandler: responseTransformer,
			}),
			invalidatesTags: ['GET_TAGS'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchTags = dispatch(
					tagsApi.util.updateQueryData(
						TagsEndPoints.getTags,
						{ teamId: arg.tag.team_id, instances: !!arg?.instances },
						(draft) => {
							draft[arg.tag.id] = { ...fallbackTag, ...arg.tag }
							return draft
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchTags.undo()
				}
			},
		}),

		[TagsEndPoints.deleteTags]: builder.mutation<
			{ status: boolean },
			DeleteTagPayload
		>({
			query: (payload) => ({
				url: `tags`,
				method: 'DELETE',
				body: { tagIds: payload.ids },
				responseHandler: responseTransformer,
			}),
			invalidatesTags: ['GET_TAGS'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchTags = dispatch(
					tagsApi.util.updateQueryData(
						TagsEndPoints.getTags,
						{ teamId: arg.teamId },
						(draft) => {
							arg.ids.forEach((id) => delete draft[id])
							return draft
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchTags.undo()
				}
			},
		}),
		[TagsEndPoints.archiveTags]: builder.mutation<
			{ status: boolean },
			DeleteTagPayload
		>({
			query: (payload) => ({
				url: `tags`,
				method: 'PATCH',
				body: { tagIds: payload.ids },
				responseHandler: responseTransformer,
			}),
			invalidatesTags: ['GET_TAGS'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchTags = dispatch(
					tagsApi.util.updateQueryData(
						TagsEndPoints.getTags,
						{ teamId: arg.teamId },
						(draft) => {
							arg.ids.forEach((id) => delete draft[id])
							return draft
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchTags.undo()
				}
			},
		}),
	}),
})

export const {
	useGetTagsQuery,
	useUpdateTagMutation,
	useCreateTagMutation,
	useDeleteTagsMutation,
	useArchiveTagsMutation, //TODO: this is not being used
	useLazyGetTagsQuery,
} = tagsApi

export { tagsApi }
