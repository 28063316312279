import { Button, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { ConditionBranch } from '@/modules/workflow/types/logic'
import { Radii } from '@/styles/borders'
import { BTN } from '@/styles/components/button'
import { TEXT_ENUM } from '@/styles/components/text'

import { Condition, ConditionComponents } from './Condition'
import { ConditionPrefix } from './ConditionPrefix'

interface ConditionBlockProps {
	branchIndex: number
	blockIndex: number
	conditionComponents: ConditionComponents
	conditionalObject: { branches: ConditionBranch[] }
	updateNode: (value: any) => void
	handleAddCondition: () => void
	handleDeleteCondition: (conditionIndex: number) => void
	handleDeleteConditionBlock: () => void
}

export const ConditionBlock = ({
	branchIndex,
	blockIndex,
	conditionComponents,
	conditionalObject,
	updateNode,
	handleAddCondition,
	handleDeleteConditionBlock,
	handleDeleteCondition,
}: ConditionBlockProps) => {
	const conditions =
		conditionalObject.branches[branchIndex].conditionBlocks[blockIndex]
			.conditions
	const isGrouped = conditions.length > 1

	return (
		<Flex align="flex-start">
			<ConditionPrefix
				index={blockIndex}
				conditionalOperator="or"
				mr={2}
				mt={isGrouped ? 3 : 0}
			/>
			<Flex
				w="100%"
				gap={2}
				direction="column"
				bgColor={isGrouped ? COLORS.background[12] : 'inherit'}
				border={isGrouped ? '1px solid #E6E6E6' : 'none'}
				borderRadius={isGrouped ? '12px' : '0'}
				p={isGrouped ? 3 : 0}
			>
				{conditions && conditions.length > 0 ? (
					conditions.map((condition, i) => {
						return (
							<Condition
								key={condition.id}
								conditionIndex={i}
								branchIndex={branchIndex}
								blockIndex={blockIndex}
								components={conditionComponents}
								conditionalObject={conditionalObject}
								updateNode={updateNode}
								handleDeleteConditionBlock={handleDeleteConditionBlock}
								handleDeleteCondition={() => handleDeleteCondition(i)}
							/>
						)
					})
				) : (
					<Text
						variant={TEXT_ENUM.medium16}
						color={COLORS.gray[9]}
						textAlign="center"
					>
						No conditions found
					</Text>
				)}
				{isGrouped && (
					<Flex align="center" justify="space-between">
						<Button
							leftIcon={<GetIcon icon={Icons.plus} />}
							variant={BTN.outline}
							height="fit-content"
							py={1.5}
							px={3}
							mt={3}
							borderRadius={Radii.lg}
							onClick={handleAddCondition}
						>
							Add condition
						</Button>
						<Button
							variant={BTN.tertiary}
							borderRadius={Radii.lg}
							_hover={{
								backgroundColor: COLORS.background[11],
							}}
							_active={{
								backgroundColor: COLORS.background[10],
							}}
							onClick={handleDeleteConditionBlock}
						>
							Delete group
						</Button>
					</Flex>
				)}
			</Flex>
		</Flex>
	)
}
