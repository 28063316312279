import { Flex, GridItem, Skeleton } from '@chakra-ui/react'

import { Borders } from '@/styles/borders'

export const CardSkeleton = () => {
	return (
		<GridItem>
			<Flex
				h="full"
				border={Borders.primary}
				bg="white"
				borderRadius="12px"
				w="full"
				p={4}
				justifyContent="space-between"
				gap={3}
				flexDir="column"
			>
				<Skeleton flex={1} borderRadius="md" />
				<Skeleton h={8} borderRadius="md" />
			</Flex>
		</GridItem>
	)
}
