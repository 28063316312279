import { NUCLEUS_URL } from '@/app/config'

export const WEBHOOK_CONNECTION_URL = `${NUCLEUS_URL}/webhooks/:workflowId`
export const WEBHOOK_CONNECTION_MODAL_MAX_WIDTH = '900px'

export enum WebhookModalSteps {
	'intro' = 'intro',
	'webhook_configurations' = 'webhook_configurations',
}

export const WEBHOOK_MODAL_PROGRESS_STEPS = [
	{
		label: 'Listen for Event',
		value: WebhookModalSteps.intro,
	},
	{
		label: 'Webhook Configuration',
		value: WebhookModalSteps.webhook_configurations,
	},
]
