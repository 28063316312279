import { useWebhookUrl } from '@/modules/workflow/hooks/workflow'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataTag } from '../MetaDataTag'
import { MetaDataTitle } from '../MetaDataTitle'

export const WebhookDetails = () => {
	const webhookUrl = useWebhookUrl({ test: true })
	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="URL:" />
			<MetaDataTag label={webhookUrl} />
		</MetaDataRowLayout>
	)
}
