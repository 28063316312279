import { Icons } from 'ui'

import { Default_HTTPPostRequest } from '@/modules/workflow/types/actions'
import { WorkflowNode } from '@/modules/workflow/types/nodes'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataTag } from '../MetaDataTag'
import { MetaDataTitle } from '../MetaDataTitle'
import { MetaDataWarningTag } from '../MetaDataWarningTag'

export const WebhookDetails = ({ data }: { data: WorkflowNode }) => {
	const stepDetails = data?.stepDetails as Default_HTTPPostRequest

	const url = stepDetails?.url?.value

	if (!url) {
		return (
			<MetaDataRowLayout>
				<MetaDataTitle title="URL" />
				<MetaDataWarningTag
					warning="Add requested URL"
					icon={Icons.alert_triangle}
				/>
			</MetaDataRowLayout>
		)
	}

	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="URL:" />
			<MetaDataTag label={url} />
		</MetaDataRowLayout>
	)
}
