import { useCallback } from 'react'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { settleAllOptionGenerators } from '../../../utils/settleAllOptionGenerators'
import { useGetOutreachMailboxDrpOptions } from '../generators/useGetOutreachMailboxDrpOptions'
import { useGetOwnerDrpOptions } from './useGetOwnerDrpOptions'

export const useGetOutreachDrpOptions = (): AsyncDrpOptionGenerator => {
	const getOwnerDrpOptions = useGetOwnerDrpOptions()
	const getOutreachMailboxDrpOptions = useGetOutreachMailboxDrpOptions()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const optionGenerators = [
				getOwnerDrpOptions,
				getOutreachMailboxDrpOptions,
			]

			return settleAllOptionGenerators(optionGenerators, onSelect)
		},
		[getOutreachMailboxDrpOptions, getOwnerDrpOptions],
	)
}
