import type { NumberInputProps } from '@chakra-ui/react'
import {
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
} from '@chakra-ui/react'
import { RADII } from '@ds/tokens/borders'
import { COLORS } from '@ds/tokens/colors'

import { SHADOWS } from '@/styles/constants'

type Props = NumberInputProps

export const PanelNumberInput = ({ size = 'sm', ...props }: Props) => {
	return (
		<NumberInput
			value={props.value}
			defaultValue={props.defaultValue}
			onChange={props.onChange}
			min={props.min}
			max={props.max}
			size={size}
		>
			<NumberInputField
				_focus={{
					boxShadow: SHADOWS.focus,
					borderColor: 'transparent',
				}}
				borderColor={COLORS.gray[4]}
				background={COLORS.whiteAlpha[12]}
				borderRadius={RADII.lg}
				cursor="pointer"
			/>
			<NumberInputStepper>
				<NumberIncrementStepper />
				<NumberDecrementStepper />
			</NumberInputStepper>
		</NumberInput>
	)
}
