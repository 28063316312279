import { customAlphabet } from 'nanoid'

export const isValueEmpty = (value: any) => {
	return value === '' || value === null || value === undefined
}

export const numberId = () => {
	const nanoid = customAlphabet('1234567890', 6)
	return Number(nanoid())
}

export const capitalize = (s: string) => {
	return s[0].toUpperCase() + s.substring(1)
}
