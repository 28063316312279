import { nanoid } from 'nanoid'

import { useCurrentNodeData } from '@/modules/workflow/hooks'
import { useGetDefaultDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetDefaultDrpOptions'
import {
	Default_HTTPPostRequest,
	RefNodeValue,
} from '@/modules/workflow/types/actions'

import { ParameterMapper, ParametersType } from '../../../ParameterMapper'
import { WebhookSectionProps } from './index'

export const HeadersParamsSection = ({
	selectedNode,
	requestData,
	updateNode,
}: WebhookSectionProps) => {
	const { getCurrentNodeData } = useCurrentNodeData(selectedNode)

	const getInitialDrpOptions = useGetDefaultDrpOptions()

	const updateNodeHeadersParams = (newParams: ParametersType) => {
		if (!selectedNode) return

		const currentNodeData = getCurrentNodeData()

		updateNode({
			...currentNodeData,
			data: {
				...currentNodeData.data,
				stepDetails: {
					...requestData,
					headers: newParams,
				},
			},
		})
	}

	const handleSetParameterKey = (id: string, key: string) => {
		const currParams = (
			(getCurrentNodeData().data.stepDetails || {}) as Default_HTTPPostRequest
		).headers

		const newParams = {
			...currParams,
			[id]: {
				refNodeId: null,
				variable: null,
				value: [key, currParams[id].value ? currParams[id].value[1] : ''],
			},
		}

		updateNodeHeadersParams(newParams)
	}

	const handleSetParameterValue = (
		id: string,
		value: RefNodeValue | string,
	) => {
		const currParams = (
			(getCurrentNodeData().data.stepDetails || {}) as Default_HTTPPostRequest
		).headers

		const newParams = {
			...currParams,
			[id]: {
				refNodeId: null,
				variable: null,
				value: [currParams[id].value ? currParams[id].value[0] : '', value],
			},
		}

		updateNodeHeadersParams(newParams)
	}

	const handleDeleteParameter = (id: string) => {
		const currParams = (
			(getCurrentNodeData().data.stepDetails || {}) as Default_HTTPPostRequest
		).headers

		delete currParams[id]

		updateNodeHeadersParams(currParams)
	}

	const handleAddParameter = () => {
		const currParams = (
			(getCurrentNodeData().data.stepDetails || {}) as Default_HTTPPostRequest
		).headers

		const newParams = {
			...currParams,
			[nanoid(4)]: {
				refNodeId: null,
				variable: null,
				value: null,
			},
		}

		updateNodeHeadersParams(newParams)
	}

	return (
		<ParameterMapper
			sectionLabel="Headers"
			params={requestData.headers || {}}
			useDataReferencePicker
			getInitialDrpOptions={getInitialDrpOptions}
			handleSetParameterKey={handleSetParameterKey}
			handleSetParameterValue={handleSetParameterValue}
			handleDeleteParameter={handleDeleteParameter}
			handleAddParameter={handleAddParameter}
		/>
	)
}
