import { FormType } from '@/modules/forms/types'

import { WorkflowIntegrationIds, WorkflowIntegrationsMap } from './mappings'

export const filterIntegrations = ({
	formType,
	integration,
	isReminders2,
}: {
	formType?: FormType
	integration: string
	isReminders2?: boolean
}) => {
	let check = true

	const validFormTypes = WorkflowIntegrationsMap[integration].validFormTypes
	check = validFormTypes ? validFormTypes.includes(formType) : true

	if (
		//Reminders 2 are only available for meeting booked trigger
		integration === WorkflowIntegrationIds.reminders &&
		(!isReminders2 || formType !== FormType.meeting_booked_trigger)
	) {
		check = false
	}

	return check
}
