import { Flex, Text } from '@chakra-ui/react'

import { Card } from '@/common/components/Card'
import { useSelectedWorkflowType } from '@/modules/workflow/slice/api-selectors'
import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

import { WorkflowErrors } from '../components/WorkflowErrors'
import { SIDE_CARD_MAX_WIDTH } from '../constant'
import { useBuildWorkflowErrors } from '../hooks/useBuildWorkflowErrors'
import { getWorkflowLogObjectInfo } from '../presenters/getWorkflowLogObjectInfo'
import { WorkflowLog } from '../types'

type Props = {
	workflowLog?: WorkflowLog | null
}

export const WorkflowLogObjectPartial = ({ workflowLog }: Props) => {
	const selectedWorkflowType = useSelectedWorkflowType()?.type

	const errors = useBuildWorkflowErrors(workflowLog)

	if (!workflowLog) return null

	return (
		<Card maxW={SIDE_CARD_MAX_WIDTH}>
			<Flex w="full" p={4} alignItems="center" borderBottom={Borders.primary}>
				<Text variant={TEXT_ENUM.semibold16}>Information</Text>
			</Flex>
			<Flex p={4} gap={4} flexDir="column">
				{getWorkflowLogObjectInfo({
					workflowLog,
					triggerType: workflowLog?.type || selectedWorkflowType,
				})}
			</Flex>
			{errors.length > 0 && <WorkflowErrors errors={errors} />}
		</Card>
	)
}
