import { HStack, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { components, OptionProps } from 'react-select'

export const Option = (props: OptionProps<any, boolean, any>) => {
	const { label, integrationName, enabled } = props.data

	if (enabled === false) return null

	return (
		<components.Option {...props}>
			<HStack justify="space-between">
				<Text color={COLORS.gray[12]}>{label}</Text>
				<Text color={COLORS.gray[9]}>{integrationName}</Text>
			</HStack>
		</components.Option>
	)
}
