import { AsyncDrpOptionGenerator } from '../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../components/side-panel/panel-content/DataReferencePicker/components/Option'

export const settleAllOptionGenerators = async (
	optionGenerators: AsyncDrpOptionGenerator[],
	onSelect: (value: any) => void,
): Promise<DataReferencePickerOption[]> => {
	const results = await Promise.allSettled(
		optionGenerators.map((gen) => gen({ onSelect })),
	)
	const options: DataReferencePickerOption[] = []
	results.forEach((result) => {
		if (result.status === 'fulfilled') {
			options.push(...result.value)
		}
	})
	return options
}
