import { Flex, Text } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { Link, useNavigate } from 'react-router-dom'
import { GetIcon } from 'ui'

import { TEXT_ENUM } from '@/styles/components/text'

import {
	SidebarOptionType,
	SidebarSuboptionType,
} from '../hooks/useSidebarOptions'

interface Props extends SidebarOptionType {
	isExpanded: boolean
}

export const SidebarNavItem = ({
	title,
	isExpanded,
	navTo,
	icon,
	suboptions,
	isActive,
	isNewTab,
}: Props) => {
	const anyChildActive = suboptions?.some((suboption) => suboption.isActive)
	const showActiveStyle = isExpanded ? isActive && !anyChildActive : isActive

	return (
		<Flex w="full" direction="column">
			<Link
				to={navTo}
				target={isNewTab ? '_blank' : undefined}
				rel={isNewTab ? 'noopener noreferrer' : undefined}
			>
				<Flex
					w="full"
					h="36px"
					flexDir="row"
					alignItems="center"
					justifyContent={isExpanded ? 'flex-start' : 'center'}
					borderRadius="10px"
					px="8px"
					gap="8px"
					color={COLORS.background[2]}
					bg={showActiveStyle ? ColorTokens.card_secondary : 'transparent'}
					_hover={{
						bg: ColorTokens.card_secondary,
					}}
				>
					<GetIcon
						fontSize="16px"
						icon={icon}
						color={showActiveStyle ? 'currentcolor' : COLORS.background[5]}
					/>

					{isExpanded && <Text variant={TEXT_ENUM.medium14}>{title}</Text>}
				</Flex>
			</Link>

			<Suboptions
				suboptions={suboptions}
				isActive={isActive}
				isExpanded={isExpanded}
			/>
		</Flex>
	)
}

type SuboptionsProps = {
	suboptions?: SidebarSuboptionType[]
	isActive?: boolean
	isExpanded?: boolean
}

const Suboptions = ({ suboptions, isActive, isExpanded }: SuboptionsProps) => {
	const navigate = useNavigate()

	if (!suboptions?.length || !isActive || !isExpanded) return null

	return (
		<Flex direction="column" pl="22px" gap="4px" mt="4px" cursor="pointer">
			{suboptions.map((suboption) => (
				<Flex
					key={suboption.title}
					h="32px"
					alignItems="center"
					px="12px"
					borderRadius="10px"
					bg={suboption.isActive ? ColorTokens.card_secondary : 'transparent'}
					color={
						suboption.isActive ? COLORS.background[2] : COLORS.background[5]
					}
					onClick={() => navigate(suboption.navTo)}
					_hover={{
						bg: ColorTokens.card_secondary,
					}}
				>
					<Text variant={TEXT_ENUM.medium14} noOfLines={1}>
						{suboption.title}
					</Text>
				</Flex>
			))}
		</Flex>
	)
}
