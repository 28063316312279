import { useMemo } from 'react'

import { LogLevel, WorkflowLog } from '../types'

export const useBuildWorkflowErrors = (workflowLog?: WorkflowLog | null) => {
	return useMemo(() => {
		const allErros: string[] = []
		workflowLog?.steps?.forEach((step) => {
			step?._logs?.forEach((log) => {
				if (log.logLevel === LogLevel.ERROR) {
					allErros.push(log.message)
				}
			})
		})
		return allErros
	}, [workflowLog?.steps])
}
