import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { RADII } from '@ds/tokens/borders'
import { SHADOWS } from '@ds/tokens/shadows'
import { Icons } from 'ui'

import { TEXT_ENUM } from '@/styles/components/text'

import { CanvasControlBarButton } from './CanvasControlBarButton'

type Props = {
	zoom: number
	setZoom: (zoom: number) => void
}

const styleOverrides: FlexProps = {
	borderRadius: 'none',
	boxShadow: 'none',
}

export const CanvasControlBarZoom = ({ zoom, setZoom }: Props) => {
	const zoomToPercentage = (zoom: number) => {
		return Math.floor(zoom * 50)
	}

	const adjustZoom = (zoomPercentageAdjustment: number) => {
		const zoomPercentage = zoomToPercentage(zoom)
		let n = (zoomPercentage + zoomPercentageAdjustment) / 5
		if (zoomPercentageAdjustment > 0) {
			n = Math.floor(n)
		} else {
			n = Math.ceil(n)
		}
		setZoom(n / 10)
	}

	const zoomIn = () => {
		adjustZoom(10)
	}

	const zoomOut = () => {
		adjustZoom(-10)
	}

	return (
		<Flex
			h={9}
			alignItems="center"
			justifyContent="center"
			borderRadius={RADII.lg}
			boxShadow={SHADOWS.elevated}
			cursor="pointer"
		>
			<CanvasControlBarButton
				icon={Icons.minus}
				selected={false}
				px="12px"
				{...styleOverrides}
				onClick={zoomOut}
			/>
			<Text
				w={10}
				textAlign="center"
				overflow="visible"
				noOfLines={1}
				variant={TEXT_ENUM.medium14}
			>
				{zoomToPercentage(zoom).toFixed(0)}%
			</Text>
			<CanvasControlBarButton
				icon={Icons.plus}
				selected={false}
				{...styleOverrides}
				onClick={zoomIn}
			/>
		</Flex>
	)
}
