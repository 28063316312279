import { numberInputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'

import { SHADOWS } from '../constants'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
	numberInputAnatomy.keys,
)

export const numberInputTheme = defineMultiStyleConfig({
	baseStyle: () => ({
		field: {
			w: '100%',
			h: '36px',
			border: '1px solid',
			borderColor: COLORS.background[9],
			borderRadius: '8px',
			bg: ColorTokens.white,
			color: COLORS.background[2],
			fontWeight: 500,
			fontSize: '14px',
			outline: 'none',
			boxShadow: 'none',

			_focus: {
				outline: 'none',
				boxShadow: SHADOWS.focused,
				borderColor: 'transparent',
			},
			_focusVisible: {
				outline: 'none',
				boxShadow: SHADOWS.focused,
				borderColor: 'transparent',
			},
			_hover: {
				borderColor: COLORS.background[7],
				_focusVisible: {
					borderColor: 'transparent',
				},
			},
			_placeholder: {
				color: COLORS.background[6],
			},
		},
	}),
	defaultProps: {
		variant: 'default',
	},
})
