import { useCallback } from 'react'

import { useLazyGetApolloEmailAccountsQuery } from '@/app/integrations/api/apollo'
import ApolloLogo from '@/assets/logo/apollo.png'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'

export const useGetApolloEmailDrpOptions = (): AsyncDrpOptionGenerator => {
	const [getApolloEmailAccounts] = useLazyGetApolloEmailAccountsQuery()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const sendEmailIds = await getApolloEmailAccounts()
				.unwrap()
				.then((data) => data)

			const sendEmailIdOptions =
				sendEmailIds.map((email) => ({
					label: email.email,
					value: email.id,
					onSelect: () => {
						const newRfn = {
							refNodeId: null,
							variable: null,
							value: email.id,
							label: email.email,
							icon: ApolloLogo,
						}
						onSelect(newRfn)
					},
				})) || []

			return [
				{
					label: 'Apollo Email Accounts',
					value: sendEmailIdOptions,
				},
			]
		},
		[getApolloEmailAccounts],
	)
}
