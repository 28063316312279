import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { TChecklistDTO } from '../types/dto'

export enum ChecklistEndpoints {
	getChecklist = 'getChecklist',
}

const checklistApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[ChecklistEndpoints.getChecklist]: builder.query<TChecklistDTO, void>({
			query: () => ({
				url: `/checklist/items`,
				method: 'GET',
				responseHandler: responseTransformer,
			}),
			providesTags: ['GET_CHECKLIST_ITEMS'],
		}),
	}),
})

export const { useGetChecklistQuery } = checklistApi

export { checklistApi }
