import { Flex, Text, TextProps, Tooltip } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons, WEIGHT } from 'ui'

import { TEXT_ENUM } from '@/styles/components/text'
import { TOOLTIP_ENUM } from '@/styles/components/tooltip'

interface PanelInputLabelProps extends TextProps {
	label: string
	description?: string
	leftIcon?: Icons
	tooltipText?: string
}

export const PanelInputLabel = ({
	label,
	description,
	leftIcon,
	tooltipText,
	...props
}: PanelInputLabelProps) => {
	return (
		<Flex direction="column">
			<Text
				display="flex"
				alignItems="center"
				variant={TEXT_ENUM.input_label}
				color={COLORS.gray[12]}
				fontSize="sm"
				fontWeight={WEIGHT.semibold}
				{...props}
			>
				{leftIcon && (
					<GetIcon icon={leftIcon} color={COLORS.gray[12]} mr={2} boxSize={5} />
				)}
				{label}
				{tooltipText && (
					<Tooltip
						label={tooltipText}
						placement="top"
						variant={TOOLTIP_ENUM.workflows_side_panel}
					>
						<Flex p="2px" ml={1} borderRadius="6px">
							<GetIcon
								icon={Icons.info}
								boxSize="12px"
								color={COLORS.gray[9]}
							/>
						</Flex>
					</Tooltip>
				)}
			</Text>
			{description && (
				<Text
					variant={TEXT_ENUM.input_description}
					mb={4}
					fontSize={props.fontSize ? props.fontSize : 'sm'}
				>
					{description}
				</Text>
			)}
		</Flex>
	)
}
