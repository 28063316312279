import { Center, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

export const LogsTableBodyEmpty = () => {
	return (
		<Center h="full" minH="500px" flexDir="column">
			<Text fontSize="14px" color={COLORS.background[6]} mt="8px">
				There are no logs.
			</Text>
			<Text fontSize="14px" color={COLORS.background[6]} mt="4px">
				Try changing the filters or search if applied.
			</Text>
		</Center>
	)
}
