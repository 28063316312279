import { Icons } from 'ui'

import { useGetReminderQuery } from '@/modules/reminders/api'
import { Default_Reminder } from '@/modules/workflow/types/actions'
import { WorkflowNode } from '@/modules/workflow/types/nodes'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataLoading } from '../MetaDataLoading'
import { MetaDataTag } from '../MetaDataTag'
import { MetaDataTitle } from '../MetaDataTitle'
import { MetaDataWarningTag } from '../MetaDataWarningTag'

export const ReminderDetails = ({ data }: { data: WorkflowNode }) => {
	const stepDetails = data?.stepDetails as Default_Reminder
	const reminderId = stepDetails?.reminderId?.value

	const { data: reminder, isLoading } = useGetReminderQuery(reminderId, {
		skip: !reminderId,
	})

	if (isLoading) return <MetaDataLoading title="Reminder:" />

	if (!reminder) {
		return (
			<MetaDataRowLayout>
				<MetaDataTitle title="Reminder:" />
				<MetaDataWarningTag warning="Select reminder" icon={Icons.mail} />
			</MetaDataRowLayout>
		)
	}

	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="Reminder:" />
			<MetaDataTag label={reminder?.name} />
		</MetaDataRowLayout>
	)
}
