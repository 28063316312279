import { useFlags } from 'launchdarkly-react-client-sdk'

import { FeatureFlags } from '@/common/constants/feature-flags'

export const useIsAmplemarketIntegration = () => {
	const flags = useFlags()

	return flags[FeatureFlags['amplemarket-integration']] === undefined
		? false
		: flags[FeatureFlags['amplemarket-integration']]
}
