import { Flex } from '@chakra-ui/react'

import { Step } from './Step'

export type StepType = { value: string; label: string }

type Props = {
	activeStep: string
	steps: StepType[]
}

export const StepsProgression = ({ activeStep, steps }: Props) => {
	const handleVariant = (index: number) => {
		const currentStepIndex = steps.findIndex((cs) => cs.value === activeStep)
		return index < currentStepIndex
			? 'finished'
			: index === currentStepIndex
				? 'active'
				: 'inactive'
	}

	return (
		<Flex flexDir="column" gap={2}>
			{steps.map((step, i) => {
				return (
					<Step
						key={step.value}
						title={step.label}
						variant={handleVariant(i)}
						hideConnector={i === steps.length - 1}
					/>
				)
			})}
		</Flex>
	)
}
