import { Button, HStack } from '@chakra-ui/react'

import { Borders } from '@/styles/borders'
import { BTN } from '@/styles/components/button'

type Props = {
	handlePrimaryClick?: () => void
	handleSecondaryClick?: () => void
	disablePrimary?: boolean
	isPrimaryLoading?: boolean
}

export const WebhookModalFooter = ({
	handleSecondaryClick,
	isPrimaryLoading,
	disablePrimary,
	handlePrimaryClick,
}: Props) => {
	return (
		<HStack
			w="full"
			justify="flex-end"
			p={5}
			borderTop={Borders.primary}
			flexGrow={0}
			h="80px"
			bg="white"
		>
			<Button variant={BTN.secondary} onClick={handleSecondaryClick}>
				Close
			</Button>

			<Button
				variant={BTN.primary}
				onClick={handlePrimaryClick}
				disabled={disablePrimary}
				isLoading={isPrimaryLoading}
			>
				Save Webhook
			</Button>
		</HStack>
	)
}
