import { useCallback } from 'react'

import { useLazyGetSalesforceInstanceUrlQuery } from '@/app/integrations/api/salesforce'
import { CrmTypes } from '@/modules/workflow/constants/crm-types'

export const useGetCrmInstanceUrl = () => {
	const [getSalesforceInstanceUrlQuery] = useLazyGetSalesforceInstanceUrlQuery()

	return useCallback(
		async (crmType?: string) => {
			switch (crmType) {
				case CrmTypes.Salesforce:
					return await getSalesforceInstanceUrlQuery()
						.unwrap()
						.then((data) => data)
				default:
					return ''
			}
		},
		[getSalesforceInstanceUrlQuery],
	)
}
