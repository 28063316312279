import { SelectVariant } from '../type'
import { secondarySelectStyles } from '../variants/secondary'

type Args = {
	variant?: SelectVariant
	minHeight?: string
}

export const getVariantStyles = ({ variant, minHeight }: Args) => {
	switch (variant) {
		case SelectVariant.secondary:
			return secondarySelectStyles({ minHeight })
		default:
			return {}
	}
}
