import { useFlags } from 'launchdarkly-react-client-sdk'

import { FeatureFlags } from '@/common/constants/feature-flags'

export const useIsWorkflowAdvancedMode = () => {
	const flags = useFlags()

	return flags[FeatureFlags['workflow-advanced-mode']] === undefined
		? false
		: flags[FeatureFlags['workflow-advanced-mode']]
}
