// @ts-nocheck
import { IntlProvider } from 'react-intl'
import { EnglishTranslations } from 'shared-utils'

import { isDev } from '@/app/config'

const lang = 'en'

type Props = {
	children: React.ReactNode
}

export const LocalizationProvider = ({ children }: Props) => {
	return (
		<IntlProvider
			locale={lang}
			messages={EnglishTranslations}
			defaultLocale="en"
			onError={(err) => {
				if (isDev) {
					console.error(err.message)
				}
			}}
		>
			{children}
		</IntlProvider>
	)
}
