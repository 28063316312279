import { Box } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import React, { CSSProperties, useMemo } from 'react'
import { Handle, Position, useStore } from 'reactflow'

import { WorkflowNode } from '@/modules/workflow/types/nodes'
import { connectionNodeIdSelector } from '@/modules/workflow/utils/rf-selectors'

export const TopConnector = React.memo(
	({ data, color }: { data: WorkflowNode; color?: string }) => {
		const { id } = data
		const connectionNodeId = useStore(connectionNodeIdSelector)
		const isTarget = connectionNodeId && connectionNodeId !== id

		const hasParent = data.parentIds.length > 0

		const handleStyle: CSSProperties = useMemo(() => {
			return {
				background: 'transparent',
				width: '24px',
				height: '24px',
				border: 'none',
				position: 'absolute',
				top: '4px',
				zIndex: 5,
			}
		}, [])

		return (
			<>
				{hasParent && (
					<Box
						width="16px"
						height="16px"
						position="absolute"
						top="-15px"
						zIndex={isTarget ? 5 : 3}
						left="calc(50% - 10px)"
					>
						<svg
							width="20"
							height="20"
							viewBox="0 0 16 9"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M7.29289 8.70711C7.68342 9.09763 8.31658 9.09763 8.70711 8.70711L15.0711 2.34315C15.4616 1.95262 15.4616 1.31946 15.0711 0.928932C14.6805 0.538408 14.0474 0.538408 13.6569 0.928932L8 6.58579L2.34315 0.928932C1.95262 0.538408 1.31946 0.538408 0.928932 0.928933C0.538408 1.31946 0.538408 1.95262 0.928932 2.34315L7.29289 8.70711ZM7 7L7 8L9 8L9 7L7 7Z"
								fill={color || COLORS.background[9]}
							/>
						</svg>
					</Box>
				)}
				<Handle
					id={id}
					type="target"
					position={Position.Top}
					isConnectable={true}
					style={handleStyle}
				/>
			</>
		)
	},
)

TopConnector.displayName = 'TopConnector'
