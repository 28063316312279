import { Box, Flex, Image } from '@chakra-ui/react'

import LeftPath from '@/assets/illustations/left-path.svg'
import { createLabel } from '@/modules/workflow/utils/createEdge'

import { EdgeLabel } from '../../edges/CustomEdge'
import { PlusButton } from '../PlusButton'

type Props = {
	integrationId: string
	id: string
}

export const NoTrueChild = ({ integrationId, id }: Props) => {
	return (
		<Box
			pointerEvents="none"
			position="absolute"
			width="212px"
			height="212px"
			left="calc(-16% + 1px)"
			bottom="-206px"
		>
			<Image width="240px" height="184px" src={LeftPath} />
			<EdgeLabel
				targetX={1}
				targetY={8}
				label={createLabel('true', integrationId)}
				type="true"
			/>
			<Flex
				pointerEvents="all"
				w="12px"
				h="12px"
				pos="absolute"
				bottom="20px"
				left="-9px"
			>
				<PlusButton branchId={`${id}&true`} />
			</Flex>
		</Box>
	)
}
