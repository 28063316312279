import { Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import { SingleValue } from 'react-select'

import { useGetAllQueuesQuery } from '@/modules/queues/api'
import { QueueDistributionType } from '@/modules/queues/types'
import { useUpdateWorkflowNode } from '@/modules/workflow/hooks'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import { Default_Route } from '@/modules/workflow/types/actions'

import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../../../panel-variants/PanelSelect'

const emptyDefault = {
	queueId: {
		value: null,
		variable: null,
		refNodeId: null,
	},
}

export const DefaultRoute = () => {
	const selectedNode = useSelectedNode()
	const updateNode = useUpdateWorkflowNode()

	const stepDetails = selectedNode?.data?.stepDetails as Default_Route

	const { data: queues, isLoading: isQueueDataLoading } = useGetAllQueuesQuery()

	const queueOptionGroups = [
		{
			label: 'max fairness',
			options: (queues || [])
				.filter((op) => op.maximize_by === QueueDistributionType.fairness)
				.map(({ id, name }) => ({
					value: id,
					label: name,
				})),
		},
		{
			label: 'max availability',
			options: (queues || [])
				.filter((op) => op.maximize_by === QueueDistributionType.availability)
				.map(({ id, name }) => ({
					value: id,
					label: name,
				})),
		},
	]

	const handleOptionChange = (
		option: SingleValue<{ label: string; value: number }>,
	) => {
		if (!selectedNode) return
		const newStepDetails = cloneDeep({
			...(stepDetails || emptyDefault),
		} as Default_Route)
		newStepDetails.queueId = {
			refNodeId: null,
			variable: null,
			value: option?.value,
		}

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const queueValue = queueOptionGroups
		.map((group) => group.options)
		.flat()
		.find((op) => op.value === stepDetails?.queueId?.value)

	return (
		<Flex w="100%" direction="column">
			<PanelInputLabel label="Queue" />

			<PanelSelect
				placeholder="Select a queue..."
				value={queueValue || null}
				options={queueOptionGroups}
				isLoading={isQueueDataLoading}
				isSearchable
				onChange={handleOptionChange}
			/>
		</Flex>
	)
}
