import { LeadAttributes, LeadInfoType } from '@/app/leads/helper'
import { MentionListItem } from '@/components/RichTextEditor/MentionList'

import { QuestionTable } from '../types/questions'

export const createMentionsFromQuestions = (
	qs?: Array<QuestionTable>,
): Array<MentionListItem> =>
	(qs ?? []).map((q) => ({
		label: q?.title ?? '',
		type: 'response',
		value: JSON.stringify({
			refNodeId: '0',
			variable: `$.responses.${String(q?.id)}.value`,
			value: null,
		}),
	}))

export const createMentionsFromDynamicFields = (
	fields: Array<{ label: string; value: string; type: string }>,
): Array<MentionListItem> =>
	fields.map((f) => ({
		label: f?.label ?? '',
		type: f?.type ?? '',
		value: JSON.stringify({
			refNodeId: 'meeting_booked',
			variable: `$.${f?.value}`,
			value: null,
		}),
	}))

const mapMentionsFromLeadAttributes = (attributes: Array<LeadInfoType>) =>
	attributes.map((attrInfo) => ({
		label: `${attrInfo.label}`,
		value: JSON.stringify({
			refNodeId: '0',
			variable: `$.enrichment.${attrInfo.dbKey}`,
			value: null,
		}),
		type: attrInfo.dbKey?.includes('company.')
			? 'company_attribute'
			: 'person_attribute',
	}))

export const createMentionsFromLeadAttributes = (
	leadAttributesInfo: Record<LeadAttributes, LeadInfoType>,
) => {
	return mapMentionsFromLeadAttributes(
		Object.values(leadAttributesInfo).filter(
			(attrInfo) => attrInfo.dbKey !== null,
		),
	)
}
