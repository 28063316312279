import { Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { prettifyDateISOString } from 'shared-utils'
import { GetIcon, Icons } from 'ui'

import { useIsWorkflowVersionViewer } from '@/common/hooks/feature-flags/useIsWorkflowVersionViewer'
import { TEXT_ENUM } from '@/styles/components/text'

import { FormattedVersion, VersionEventType } from './utils'

const VERSION_ICONS = {
	[VersionEventType.Created]: Icons.created_version,
	[VersionEventType.Published]: Icons.published_version,
}

const ACTIVITY_PREFIX = {
	[VersionEventType.Created]: 'Created',
	[VersionEventType.Published]: 'Published',
}

interface VersionHistoryItemProps {
	version: FormattedVersion
	onViewVersion: (id: string) => void
}

export const VersionHistoryItem = ({
	version,
	onViewVersion,
}: VersionHistoryItemProps) => {
	const prettyDate = prettifyDateISOString(
		version.date,
		Intl.DateTimeFormat().resolvedOptions().timeZone,
	)
	const formattedDate = `${prettyDate.dateComponents.month} ${prettyDate.dateComponents.date}, ${prettyDate.dateComponents.year} at ${prettyDate.dateComponents.formattedTime}`

	const member = version.member

	const isCreatedItem = version.type === VersionEventType.Created

	const isWorkflowVersionViewer = useIsWorkflowVersionViewer()

	return (
		<Flex alignItems="center" gap={4}>
			<GetIcon icon={VERSION_ICONS[version.type]} boxSize={10} />
			<Flex flexDir="column" alignItems="flex-start" flex={1}>
				<Text variant={TEXT_ENUM.medium14}>
					{isCreatedItem
						? 'Initial Draft'
						: `Version ${version.formVersion.toString()}`}
				</Text>
				<Text variant={TEXT_ENUM.regular12}>{formattedDate}</Text>
				{member && (
					<Text
						variant={TEXT_ENUM.regular12}
						color={COLORS.background[5]}
						style={{ marginTop: 0 }}
					>
						{ACTIVITY_PREFIX[version.type]} by {member}
					</Text>
				)}
			</Flex>
			{!isCreatedItem && isWorkflowVersionViewer && (
				<Flex
					color={COLORS.purple[9]}
					alignItems="center"
					gap={1}
					cursor="pointer"
					onClick={() => onViewVersion(version.formVersion.toString())}
				>
					<Text variant={TEXT_ENUM.medium12} textDecor="underline">
						Open
					</Text>
					<GetIcon icon={Icons.open_link} boxSize={3} />
				</Flex>
			)}
		</Flex>
	)
}
