import { Button, Flex, HStack } from '@chakra-ui/react'
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Icons } from 'ui'

import { useAppDispatch } from '@/app/hooks'
import { useTeam } from '@/app/user/api/teams/selectors'
import { PageHeading } from '@/common/components/PageHeader'
import { useCustomToast } from '@/components/Toast'
import { useCreateFormMutation, useGetAllFormsQuery } from '@/modules/forms/api'
import { FormType } from '@/modules/forms/types'
import { getCreateTriggerPayload } from '@/modules/forms-home/utils/form-payload-creators'
import { BTN } from '@/styles/components/button'

import { workflowsHomeActions } from '../slice'

export const WorkflowsHomeLayout = React.memo(() => {
	const [createWorkflow, { isLoading }] = useCreateFormMutation()
	const { data, isLoading: fetchingForms } = useGetAllFormsQuery()

	const dispatch = useAppDispatch()
	const team = useTeam()?.team

	const navigate = useNavigate()

	const toast = useCustomToast()

	const handleCreateForm = () => {
		if (!team) return

		createWorkflow({
			form: getCreateTriggerPayload(team, FormType.draft_trigger),
		})
			.unwrap()
			.then((form) => {
				navigate(`${form.id}/editor`)
			})
			.catch((error) => {
				console.error(error)
				toast({
					title: 'Failed to create the workflow',
					status: 'error',
				})
			})
	}

	const handleNewWorkflow = () => {
		if (!data?.length) {
			if (fetchingForms) return
			dispatch(workflowsHomeActions.setShowEmptyStateModal(true))
			return
		}

		handleCreateForm()
	}

	return (
		<Flex flexDir="column" flex="1">
			<PageHeading title="Workflows" icon={Icons.workflow}>
				<HStack w="full" justifyContent="flex-end">
					<Button
						variant={BTN.primary_v2}
						onClick={handleNewWorkflow}
						isLoading={isLoading}
						size="sm"
					>
						Create new workflow
					</Button>
				</HStack>
			</PageHeading>
			<Outlet />
		</Flex>
	)
})

WorkflowsHomeLayout.displayName = 'WorkflowsHomeLayout'
