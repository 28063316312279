import { SchedulerTranslationsTokens } from '../tokens'

export const SchedulerEnglishTranslation: Record<
	SchedulerTranslationsTokens,
	string
> = {
	[SchedulerTranslationsTokens.confirm]: 'Confirm',
	[SchedulerTranslationsTokens.confirm_meeting_header]:
		'Your meeting has been scheduled!',
	[SchedulerTranslationsTokens.confirm_meeting_description]:
		'We’ve sent you meeting details, check your inbox.',
	[SchedulerTranslationsTokens.reschedule_meeting]: 'Reschedule meeting',
	[SchedulerTranslationsTokens.cancel_meeting]: 'Cancel meeting',
	[SchedulerTranslationsTokens.confirm_meeting_has_assigned]:
		'has been assigned to your meeting',
	[SchedulerTranslationsTokens.confirm_meeting_invite_teammates]:
		'Invite teammates',
	[SchedulerTranslationsTokens.confirm_meeting_send_invites]: 'Send invites',
	[SchedulerTranslationsTokens.confirm_meeting_email_error]:
		'Please enter valid emails',
	[SchedulerTranslationsTokens.confirm_meeting_error_sending_invites]:
		'Could not send invites!',
	[SchedulerTranslationsTokens.cancel_meeting_reason]:
		'Why are you cancelling this meeting? (Optional)',
	[SchedulerTranslationsTokens.cancel_meeting_reason_details]:
		'Share some details here',
	[SchedulerTranslationsTokens.cancel_meeting_header]:
		'Your meeting has been cancelled!',
	[SchedulerTranslationsTokens.cancel_meeting_description]:
		'We will inform all participants about the cancellation.',
	[SchedulerTranslationsTokens.cancel_by_mistake]: 'Cancelled by mistake?',
	[SchedulerTranslationsTokens.cancel_reschedule_msg]:
		'You can always reschedule this meeting with a new time slot.',
	[SchedulerTranslationsTokens.rescheduling_header]: 'Reschedule your meeting',
	[SchedulerTranslationsTokens.keep_original_time]: 'Keep existing time slot',
	[SchedulerTranslationsTokens.former_time]: 'Former Time',
	[SchedulerTranslationsTokens.powered_by]: 'Powered by',

	[SchedulerTranslationsTokens.no_event]: 'No event is found!',
	[SchedulerTranslationsTokens.no_event_active]:
		'This event is no longer active!',
	[SchedulerTranslationsTokens.no_member]: 'No member is found!',
	[SchedulerTranslationsTokens.no_queue]: 'No queue is found!',
	[SchedulerTranslationsTokens.no_queue_active]:
		'This queue is no longer active!',
	[SchedulerTranslationsTokens.no_meeting]: 'Meeting could not be found!',
	[SchedulerTranslationsTokens.no_time_slots]:
		'There are no available time slots!',
	[SchedulerTranslationsTokens.minutes_meeting]: 'min meeting',

	[SchedulerTranslationsTokens.weekdays_short]: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat',
	[SchedulerTranslationsTokens.time_slots]: 'Slots',

	[SchedulerTranslationsTokens.morning]: 'Morning',
	[SchedulerTranslationsTokens.daytime]: 'Daytime',
	[SchedulerTranslationsTokens.evening]: 'Evening',
	[SchedulerTranslationsTokens.timeslot_loading]:
		'Getting available time slots...',

	[SchedulerTranslationsTokens.timer_warning]:
		'Book within the next {delay} minutes to confirm your session.',
}
