import { Center, CenterProps, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { TEXT_ENUM } from '@/styles/components/text'

import { ErrorIcon } from '../ErrorIcon.tsx'

interface Props extends CenterProps {
	error?: string
	subError?: string
}

export const ErrorComponent = ({ error, subError, ...centerProps }: Props) => {
	return (
		<Center w="full" h="full" flexDir="column" {...centerProps}>
			<ErrorIcon />
			<Text
				variant={TEXT_ENUM.regular14}
				color={COLORS.background[6]}
				mt="20px"
			>
				{error}
			</Text>
			{subError && (
				<Text
					variant={TEXT_ENUM.regular14}
					color={COLORS.background[6]}
					mt="6px"
				>
					{subError}
				</Text>
			)}
		</Center>
	)
}
