import { COLORS } from '@ds/tokens/colors'
import { GroupBase, Props, StylesConfig } from 'react-select'
import { Icons } from 'ui'

import { Select } from '@/common/components/Select'
import { SHADOWS } from '@/styles/constants'

import { DropdownIndicator } from './components/DropDownIndicator'
import { Option } from './components/Option'
import { SingleValue } from './components/SingleValue'

type CustomProps = {
	width?: string
	styles?: StylesConfig
}

export type WorkflowSelectOption = {
	label: string
	subLabel?: string
	value: string
	logo?: string
	icon?: Icons
	iconBg?: string
	suffix?: React.ReactNode
	prefix?: React.ReactNode
	enabled?: boolean
}

export const WorkflowSelect = <
	Option,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>,
>({
	components,
	...props
}: Props<Option, IsMulti, Group> & CustomProps) => {
	return (
		<Select
			width="100%"
			matchWidth
			styles={{
				control: (baseStyles, state) => ({
					...baseStyles,
					background: '#FCFCFC',
					borderColor: '#E6E6E6',
					borderRadius: '12px',
					padding: '4px 8px',
					cursor: 'pointer',
					boxShadow: state.isFocused ? SHADOWS.focused : 'none',
					'&:hover': {
						borderColor: state.isFocused ? 'transparent' : COLORS.background[9],
					},
				}),
				valueContainer: (baseStyles) => ({
					...baseStyles,
					padding: '0',
				}),
				dropdownIndicator: (baseStyles) => ({
					...baseStyles,
					padding: '0',
				}),
				singleValue: (baseStyles) => ({
					...baseStyles,
					margin: '0',
				}),
			}}
			components={{
				SingleValue,
				DropdownIndicator,
				Option,
				...components,
			}}
			{...props}
		/>
	)
}
