import { CanvasNodeMetaDetailsLayout } from '@/modules/workflow/layouts/CanvasNodeMetaDetailsLayout'
import { WorkflowNode } from '@/modules/workflow/types/nodes'
import { WorkflowIntegrationIds } from '@/modules/workflow/utils/mappings'

import { NodeDescription } from '../NodeDescription'
import { IfElseDetails } from './IfElseDetails'
import { MatchCriteriaDetails } from './MatchCriteriaDetails'

type Props = {
	data: WorkflowNode
}

export const GetLogicNodeDetails = ({ data }: Props) => {
	const description = data?.description

	/** We don't have any details to show for the following nodes other than the description */
	const hideDetails =
		!data?.description &&
		data?.integrationId === WorkflowIntegrationIds.defaultDisplayScheduler

	return (
		<CanvasNodeMetaDetailsLayout hide={hideDetails}>
			<NodeDescription description={description} />
			{getLogicNodeDetails({ data })}
		</CanvasNodeMetaDetailsLayout>
	)
}

const getLogicNodeDetails = ({ data }: { data: WorkflowNode }) => {
	switch (data?.integrationId) {
		case WorkflowIntegrationIds.ifElse:
			return <IfElseDetails data={data} />
		case WorkflowIntegrationIds.hubspotMatchRecord:
		case WorkflowIntegrationIds.salesforceMatchRecord:
			return <MatchCriteriaDetails data={data} />
		default:
			return null
	}
}
