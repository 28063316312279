import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FormType } from '@/modules/forms/types'

import { SortByOptions } from '../../forms-home/components/FormsSortByProperties'
import { FORMS_VIEW_PREFERENCES_KEY } from '../constants/form-display'
import { FormsViewTypes } from '../types/FormsViewTypes'

const localFormsViewPreferences = localStorage.getItem(
	FORMS_VIEW_PREFERENCES_KEY,
) as FormsViewTypes

export interface FormsState {
	isFormImportModalOpen: boolean
	importedFormModalId: number | null
	filterByFormType: FormType | null
	sortByOptions: SortByOptions | null
	formsViewPreferences: FormsViewTypes | null
}

export const initialState: FormsState = {
	isFormImportModalOpen: false,
	importedFormModalId: null,
	filterByFormType: null,
	sortByOptions: null,
	formsViewPreferences: localFormsViewPreferences || FormsViewTypes?.card,
}

export const formsHomeSlice = createSlice({
	name: 'forms-home',
	initialState,
	reducers: {
		setIsFormImportModalOpen: (state, action: PayloadAction<boolean>) => {
			state.isFormImportModalOpen = action.payload
		},
		setImportedFormModalId: (state, action: PayloadAction<number | null>) => {
			state.importedFormModalId = action.payload
		},
		setFilterByFormType: (state, action: PayloadAction<FormType | null>) => {
			state.filterByFormType = action.payload
		},
		setSortByOptions: (state, action: PayloadAction<SortByOptions | null>) => {
			state.sortByOptions = action.payload
		},
		setFormsViewPreferences: (state, action: PayloadAction<FormsViewTypes>) => {
			state.formsViewPreferences = action.payload
			localStorage.setItem(FORMS_VIEW_PREFERENCES_KEY, action.payload)
		},
	},
})
export const formsHomeActions = formsHomeSlice.actions
export const formsHomeReducer = formsHomeSlice.reducer
