import { Center, Flex, GridItem, HStack, Switch, Text } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'

import WorkflowsCardBackground from '@/assets/images/form-card-background.png'
import { CardSkeleton } from '@/common/components/ListGridLayoutComponents/CardSkeleton'
import {
	MenuActionItemType,
	MenuActions,
} from '@/common/components/ListGridLayoutComponents/MenuActions'
import { ModifyBy } from '@/common/components/ListGridLayoutComponents/ModifyBy'
import { FormTable } from '@/modules/forms/types'
import { TEXT_ENUM } from '@/styles/components/text'
import { SHADOWS } from '@/styles/constants'

import { WorkflowLogo } from './WorkflowLogo'

type Props = {
	form?: FormTable
	handleWorkflowActiveState: (formId: number, active: boolean) => void
	handleNavigateToEditor: (formId: number) => void
	menuItems: MenuActionItemType[]
	isDuplicating?: boolean
}

export const WorkflowCardItem = ({
	form,
	handleWorkflowActiveState,
	handleNavigateToEditor,
	menuItems,
	isDuplicating,
}: Props) => {
	if (!form) return null

	if (isDuplicating) return <CardSkeleton />

	return (
		<GridItem>
			<Flex
				flexDir="column"
				position="relative"
				borderRadius="md"
				borderColor={ColorTokens.border_primary}
				borderWidth="1px"
				justify="space-between"
				height="100%"
				p={3}
				gap={4}
				bg={ColorTokens.bg_card}
				role="group"
				cursor="pointer"
				_hover={{
					shadow: SHADOWS.elevated,
				}}
				onClick={() => handleNavigateToEditor(form.id)}
			>
				<Flex
					h="180px"
					bg={ColorTokens.bg}
					borderColor={ColorTokens.border_primary}
					borderWidth="medium"
					borderRadius="md"
					direction="column"
					p={3}
					justifyContent="space-between"
					backgroundImage={'url("' + encodeURI(WorkflowsCardBackground) + '")'}
					backgroundSize="cover"
					_groupHover={{ backgroundColor: ColorTokens.bg_hover }}
				>
					<HStack justifyContent="space-between">
						<WorkflowLogo type={form.type} />
						<MenuActions
							variant="card"
							menuItems={menuItems}
							itemId={form.id}
						/>
					</HStack>
					<Text variant={TEXT_ENUM.medium24} noOfLines={2}>
						{form.workflow_name || form.name || 'Untitled workflow'}
					</Text>
				</Flex>

				<HStack
					w="full"
					justifyContent="space-between"
					color={COLORS.background[5]}
				>
					<ModifyBy
						updatedBy={form.updated_by || form.created_by}
						updatedAt={form.updated_at}
					/>
					<Center
						onClick={(e) => {
							e.stopPropagation()
						}}
					>
						<Switch
							isChecked={form?.active}
							onChange={(e) => {
								handleWorkflowActiveState(form.id, e.currentTarget.checked)
							}}
						/>
					</Center>
				</HStack>
			</Flex>
		</GridItem>
	)
}
