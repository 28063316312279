import { Flex } from '@chakra-ui/react'

import { useUpdateWorkflowNode } from '@/modules/workflow/hooks'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import {
	Default_HTTPPostRequest,
	RefNodeValue,
} from '@/modules/workflow/types/actions'

import { PanelInput } from '../../../panel-variants/PanelInput'
import { PanelInputLabel } from '../../../panel-variants/PanelInputLabel'

export const SendToLoops = () => {
	const selectedNode = useSelectedNode()
	const updateNode = useUpdateWorkflowNode()

	let requestData = selectedNode?.data.stepDetails as Default_HTTPPostRequest

	if (requestData === null && selectedNode !== null) {
		const body: { [id: string]: RefNodeValue } = {}
		body['email'] = {
			refNodeId: '0',
			variable: '$.enrichment.email',
			value: null,
		}
		body['firstName'] = {
			refNodeId: '0',
			variable: '$.enrichment.first_name',
			value: null,
		}
		body['lastName'] = {
			refNodeId: '0',
			variable: '$.enrichment.last_name',
			value: null,
		}
		requestData = {
			url: {
				refNodeId: null,
				variable: null,
				value: 'https://app.loops.so/api/v1/contacts/create',
			},
			urlParams: {},
			headers: {},
			body,
			authType: 'token',
			authorization: {
				refNodeId: null,
				variable: null,
				value: '',
			},
			method: { refNodeId: null, variable: null, value: 'post' },
			testRequestResponse: null,
		}

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: requestData,
			},
		})
	}

	const setAuthToken = (e) => {
		if (!selectedNode) return
		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: {
					...requestData,
					authorization: {
						refNodeId: null,
						variable: null,
						value: e.target.value,
					},
				},
			},
		})
	}

	return (
		<Flex w="100%" direction="column" mb={4}>
			<PanelInputLabel label="API Key" />
			<PanelInput
				placeholder="Token"
				initialValue={requestData.authorization?.value || ''}
				onBlur={setAuthToken}
			/>
		</Flex>
	)
}
