import { useCallback } from 'react'

import { useAppSelector } from '@/app/hooks'
import HubSpotLogo from '@/assets/logo/hubspot-step.svg'
import { AsyncDrpOptionGenerator } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker/components/Option'
import { selectHubspotProperties } from '@/modules/workflow/slice/selectors'
import { FieldRefNodeValue } from '@/modules/workflow/types/actions'
import { capitalize } from '@/utils/helpers'

import { useAllHubspotProperties } from '../../../useAllHubspotProperties'
import { useSelectedWorkflow } from '../../../workflow'

export const useGetHubspotTriggerDrpOptions = (): AsyncDrpOptionGenerator => {
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
	const objectType =
		selectedWorkflow?.meta?.trigger_config?.subscriptionType?.split('.')[0]
	const objectTypeLabel = objectType ? capitalize(objectType) : ''

	const getAllHubspotProperties = useAllHubspotProperties()
	const propertiesInStore = useAppSelector(selectHubspotProperties)

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const properties = propertiesInStore || (await getAllHubspotProperties())

			const propertyOptions =
				properties[objectTypeLabel]?.map((property, idx) => ({
					label: property.label,
					value: property,
					onSelect: (value: any) => {
						const refNodeId = '0'
						const variable = `$.triggerRecord.${property.name}`

						let fetchMappedUserIntegration: 'hubspot' | null = null
						if (property.name === 'hubspot_owner_id') {
							fetchMappedUserIntegration = 'hubspot'
						}

						const newRfn: FieldRefNodeValue = {
							refNodeId,
							variable,
							value: null,
							label: value.label,
							dataType: value.type,
							i: idx,
							...(fetchMappedUserIntegration
								? { fetchMappedUserIntegration }
								: {}),
							icon: HubSpotLogo,
						}

						onSelect(newRfn)
					},
				})) || []
			return [
				{
					label: `Created ${objectTypeLabel}`,
					icon: HubSpotLogo,
					value: propertyOptions,
				},
			]
		},
		[getAllHubspotProperties, objectTypeLabel, propertiesInStore],
	)
}
