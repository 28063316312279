import { Input, InputProps, Tooltip } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { useEffect, useRef, useState } from 'react'

import { TOOLTIP_ENUM } from '@/styles/components/tooltip'

export interface PanelInputProps extends InputProps {
	initialValue: string
}
export const PanelInput = ({ initialValue, ...props }: PanelInputProps) => {
	const [value, setValue] = useState<string>('')

	useEffect(() => {
		setValue(initialValue)
	}, [initialValue])

	const inputRef = useRef<HTMLInputElement>(null)

	const [isTextOverflowing, setIsTextOverflowing] = useState<boolean>(false)
	useEffect(() => {
		if (inputRef.current) {
			setIsTextOverflowing(
				// Using offsetWidth instead of clientWidth to account for small bug with scrollWidth
				// being one pixel off in some cases where width of input is adjusted.
				inputRef.current.scrollWidth > inputRef.current.offsetWidth,
			)
		}
	}, [value])

	return (
		<Tooltip
			label={value}
			variant={TOOLTIP_ENUM.workflows_side_panel}
			isDisabled={!isTextOverflowing}
		>
			<Input
				ref={inputRef}
				borderColor={COLORS.background[10]}
				bg={COLORS.background[12]}
				px={2.5}
				h="36px"
				placeholder={props.placeholder || 'Enter value'}
				_placeholder={{ color: COLORS.background[6] }}
				onChange={(e) => setValue(e.target.value)}
				value={value}
				textOverflow="ellipsis"
				{...props}
			/>
		</Tooltip>
	)
}
