import { HubspotEntities, HubspotSubscriptionType } from '../types/hubspot'

export const getHSSubscriptionType = (field?: HubspotEntities) => {
	if (!field) return ''

	switch (field) {
		case HubspotEntities.Contact:
			return HubspotSubscriptionType.CONTACT_CREATION
		case HubspotEntities.Company:
			return HubspotSubscriptionType.COMPANY_CREATION
		default:
			return ''
	}
}
