import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { TEXT_ENUM } from '@/styles/components/text'

interface BadgeProps extends FlexProps {
	text: string
	variant?: 'success' | 'error'
}

export const StatusBadge = ({
	text,
	variant = 'success',
	...props
}: BadgeProps): JSX.Element => {
	return (
		<Flex
			px={2}
			py={1}
			alignItems="center"
			justifyContent="center"
			borderRadius="6px"
			border="1px solid"
			bg={variant == 'error' ? COLORS.red[2] : COLORS.background[12]}
			color={variant === 'error' ? COLORS.red[9] : COLORS.background[4]}
			borderColor={variant === 'error' ? COLORS.red[5] : COLORS.background[10]}
			{...props}
		>
			<Text variant={TEXT_ENUM.medium12}>{text}</Text>
		</Flex>
	)
}
