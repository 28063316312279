import { useCallback } from 'react'

import ClearbitLogo from '@/assets/logo/clearbit-logo.svg'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { LabeledRefNodeValue } from '../../../types/actions'
import { defaultAttributes } from '../../../utils/mappings'

export const useGetCompnayAttrDrpOptions = (): AsyncDrpOptionGenerator => {
	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const companyOptions = Object.entries(defaultAttributes)
				.filter(([, v]) => v.type == 'company')
				.map(([, object]) => ({
					label: object.name,
					value: object.key,
					onSelect: (value: any) => {
						const newRfn: LabeledRefNodeValue = {
							refNodeId: '0',
							variable: `$.enrichment.${value}`,
							value: null,
							label: object.name,
							icon: ClearbitLogo,
						}
						onSelect(newRfn)
					},
				}))

			return [
				{
					label: 'Clearbit company attributes',
					icon: ClearbitLogo,
					value: companyOptions,
				},
			]
		},
		[],
	)
}
