import { Icons } from 'ui'

import { FormType } from '@/modules/forms/types'

import { TRIGGERS_CONTENT } from '../constants/triggers-content'

export const getWorkflowLogo = (
	type?: FormType | null,
): { logo?: string; icon?: Icons } => {
	const content = TRIGGERS_CONTENT[type || '']
	if (!content) return { icon: Icons.workflow }
	return { logo: content.logo, icon: content.icon }
}
