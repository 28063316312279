import { HStack } from '@chakra-ui/react'
import React from 'react'

export const MetaDataRowLayout = ({
	children,
}: {
	children: React.ReactNode
}) => {
	return <HStack gap={1}>{children}</HStack>
}
