import { Flex } from '@chakra-ui/react'

import { WorkflowStepLogo } from './WorkflowStepLogo'

type Props = {
	logo?: string | null
	isIcon?: boolean
}

export const WorkflowStepProgress = ({ logo, isIcon }: Props) => {
	return (
		<Flex h="full" flexDir="column" w="fit-content" align="center">
			<WorkflowStepLogo logo={logo} isIcon={isIcon} />
			<Flex h="full" w="1px" bg="#DADADA" />
		</Flex>
	)
}
