import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { Integration } from '../type'
import { AmplemarketType } from './type'

export enum AmplemarketEndPoints {
	/** Queries */
	getAmplemarket = 'getAmplemarket',
	getManualAmplemarketApiKey = 'getManualAmplemarketApiKey',

	/** Mutations */
	updateAmplemarketApiKey = 'updateAmplemarket',
	deleteAmplemarket = 'deleteAmplemarket',
}

const amplemarketApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[AmplemarketEndPoints.getAmplemarket]: builder.query<Integration, void>({
			query: () => ({
				url: `/integrations?integration=amplemarket`,
				responseHandler: responseTransformer,
				providesTags: ['GET_AMPLEMARKET_INTEGRATION'],
			}),
		}),

		[AmplemarketEndPoints.getManualAmplemarketApiKey]: builder.query<
			AmplemarketType | null | undefined,
			void
		>({
			query: () => ({
				url: `/amplemarket/api-key`,
				providesTags: ['GET_AMPLEMARKET_API_KEY'],
			}),
		}),

		[AmplemarketEndPoints.updateAmplemarketApiKey]: builder.mutation<
			AmplemarketType,
			string
		>({
			query: (apiKey) => ({
				url: `/amplemarket/api-key`,
				method: 'POST',
				body: { api_key: apiKey },
				responseHandler: responseTransformer,
				invalidatesTags: [
					'GET_AMPLEMARKET_API_KEY',
					'GET_AMPLEMARKET_INTEGRATION',
				],
			}),
			onQueryStarted: async (apiKey, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					amplemarketApi.util.updateQueryData(
						AmplemarketEndPoints.getManualAmplemarketApiKey,
						undefined,
						() => {
							return {
								id: Date.now().toString(),
								api_key: apiKey,
							} as AmplemarketType
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),
		[AmplemarketEndPoints.deleteAmplemarket]: builder.mutation<
			{ status: true; error: any },
			void
		>({
			query: () => ({
				url: `/integrations?integration=amplemarket`,
				method: 'DELETE',
				invalidatesTags: [
					'GET_AMPLEMARKET_API_KEY',
					'GET_AMPLEMARKET_INTEGRATION',
				],
			}),
			onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					amplemarketApi.util.updateQueryData(
						AmplemarketEndPoints.getManualAmplemarketApiKey,
						undefined,
						() => null,
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),
	}),
})

export const {
	useGetAmplemarketQuery,
	useGetManualAmplemarketApiKeyQuery,
	useUpdateAmplemarketMutation,
	useDeleteAmplemarketMutation,
} = amplemarketApi
