import { HStack, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { UpdatedBy } from '@/app/user/api/members/type'
import { Dot } from '@/common/components/Dot'
import { TEXT_ENUM } from '@/styles/components/text'
import { tsToTimeAgo } from '@/utils/helpers/date-time'

type Props = {
	updatedBy?: UpdatedBy | null
	updatedAt?: string | null
}

export const ModifyBy = ({ updatedBy, updatedAt }: Props) => {
	const modifiedAt = `Modified ${tsToTimeAgo(
		new Date(updatedAt || Date.now()).getTime(),
	)}`

	return (
		<HStack color={COLORS.background[5]}>
			{updatedBy && (
				<>
					<Text
						noOfLines={1}
						variant={TEXT_ENUM.regular12}
					>{`By ${updatedBy.first_name || updatedBy.last_name}`}</Text>
					<Dot />
				</>
			)}
			{updatedAt && (
				<Text variant={TEXT_ENUM.regular12} flexShrink={0}>
					{modifiedAt}
				</Text>
			)}
		</HStack>
	)
}
