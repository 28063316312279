import { Navigate } from 'react-router-dom'

import { useMember } from '@/app/user/api/members/selector'
import { useTeamId } from '@/app/user/api/teams/selectors'

type Props = {
	children: React.ReactNode
}

export const ProtectedPartial = ({ children }: Props) => {
	const member = useMember()?.member
	const isAuth = !!member
	const teamId = useTeamId()

	const shouldRedirectToLogin = !isAuth
	const shouldRedirectToOnboarding = !teamId

	if (shouldRedirectToLogin) return <Navigate to="/login" />
	if (shouldRedirectToOnboarding) return <Navigate to="/onboarding/profile" />

	return <>{children}</>
}
