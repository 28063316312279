import { Node } from 'reactflow'

import { FormType } from '@/modules/forms/types'

import { StepDetails } from './actions'
import { IntegrationId } from './integrations'

/** Workflow step saved in db */
export interface WorkflowNode {
	id: string
	stepType: StepType
	integrationId: IntegrationId
	editableName: string | null
	description?: string | null
	parentIds: string[]
	children: string[]
	stepDetails: StepDetails
	nextStep: string | null
	x: number
	y: number
}

export type WorkflowNodes = Record<string, WorkflowNode>

export interface FormWorkflowType {
	startNode: string | null
	steps: WorkflowNodes
}

/** Node that renders on canvas */
export type CanvasNode = Node<WorkflowNode>
export type CanvasNodes = Record<string, CanvasNode>

export enum StepType {
	Action = 'action',
	Logic = 'logic',
	Empty = 'empty',
	WorkflowStart = 'workflowStart',
}

/** Strictly for React Flow to know what custom node to render */
export enum RFNodeType {
	actionNode = 'actionNode',
	flowStart = 'flowStart',
	logicNode = 'logicNode',
}

export interface DnDNodeData {
	nodeType: RFNodeType
	stepType: StepType
	integrationId: IntegrationId
	editableName: string
	selectedTrigger?: FormType
}
