import { Flex } from '@chakra-ui/react'
import { SingleValue } from 'react-select'

import { MatchRecord } from '@/modules/workflow/types/matching'
import { CanvasNode } from '@/modules/workflow/types/nodes'

import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../../../panel-variants/PanelSelect'

interface RecordTypeSelectProps {
	selectedNode: CanvasNode | null
	updateNode: (node: CanvasNode, ignoreSync?: boolean | undefined) => void
	options: { label: string; value: string }[]
}

export const RecordTypeSelect = ({
	selectedNode,
	updateNode,
	options,
}: RecordTypeSelectProps) => {
	const stepDetails = selectedNode?.data.stepDetails as MatchRecord

	const recordTypeValue = options.find(
		(op) => op.value === stepDetails?.type?.value,
	)

	const handleSelectRecordType = (
		entityTypeOp: SingleValue<{ label: string; value: string }>,
	) => {
		if (!selectedNode || !entityTypeOp) return

		const newStepDetails: MatchRecord = {
			...stepDetails,
			type: {
				refNodeId: null,
				variable: null,
				value: entityTypeOp.value,
			},
			matchCriteria: {
				conditions: [],
				logic: '',
			},
			priorityCriteria: [],
		}

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: newStepDetails,
			},
		})
	}

	return (
		<Flex direction="column">
			<PanelInputLabel label="Type" />
			<PanelSelect
				placeholder="Select a record type"
				value={recordTypeValue || null}
				options={options}
				onChange={handleSelectRecordType}
			/>
		</Flex>
	)
}
