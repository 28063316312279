import { Avatar, Center, Image } from '@chakra-ui/react'
import { GetIcon, Icons } from 'ui'

type Props = {
	logo?: string | null
	icon?: Icons | null
	avatar?: string | null
	avatarName?: string | null
}

export const PageLogo = ({ logo, icon, avatar, avatarName }: Props) => {
	if (!logo && !icon && !avatar && !avatarName) return null

	return (
		<Center flexShrink={0}>
			{(avatar || avatarName) && (
				<Avatar src={avatar || ''} name={avatarName || 'Untitled'} size="sm" />
			)}
			{logo && (
				<Image src={logo} alt="logo" w="32px" h="32px" objectFit="scale-down" />
			)}
			{icon && <GetIcon icon={icon} fontSize="24px" />}
		</Center>
	)
}
