import { Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import React from 'react'
import { components, SingleValueProps } from 'react-select'

export const SingleValue = React.forwardRef(
	(
		props: SingleValueProps<any, boolean, any>,
		ref: React.Ref<HTMLDivElement>,
	) => {
		const showSearch =
			props.selectProps.isSearchable && props.selectProps.menuIsOpen

		if (props.selectProps.isLoading) return null

		return (
			<components.SingleValue {...props}>
				<Text
					ref={ref}
					color={showSearch ? COLORS.background[7] : 'inherit'}
					textOverflow="ellipsis"
					whiteSpace="nowrap"
					overflow="hidden"
				>
					{showSearch ? 'Search...' : props.children}
				</Text>
			</components.SingleValue>
		)
	},
)

SingleValue.displayName = 'SingleValue'
