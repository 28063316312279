import { useParams } from 'react-router-dom'

import { useAppSelector } from '@/app/hooks'
import {
	useGetAllFormVersionsByIdQuery,
	useGetFormByIdQuery,
} from '@/modules/forms/api'

import { useCanvasNodes } from '../../hooks'
import { selectSelectedWorkflowId } from '../selectors'

export const useSelectedWorkflowType = () => {
	const {
		workflowId: workflowIdFromParams,
		workflowVersion: workflowVersionFromParams,
	} = useParams()

	const { data: allVersions } = useGetAllFormVersionsByIdQuery(
		workflowIdFromParams,
		{
			skip: !workflowIdFromParams || !workflowVersionFromParams,
		},
	)

	const selectedWorkflowId = useAppSelector(selectSelectedWorkflowId)
	const form = useGetFormByIdQuery(selectedWorkflowId?.toString(), {
		skip: !selectedWorkflowId,
		selectFromResult: (result) => {
			return {
				type: result.data?.type,
			}
		},
	})

	if (workflowIdFromParams && workflowVersionFromParams) {
		const version = allVersions?.find(
			(version) =>
				version.form_version.toString() === workflowVersionFromParams,
		)
		return { type: version?.type }
	}

	return form
}

export const useCanvasNodeById = (nodeId: string) => {
	const canvasNodes = useCanvasNodes().canvasNodes
	return { canvasNode: canvasNodes[nodeId] }
}
