import {
	Button,
	Flex,
	HStack,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import dayjs from 'dayjs'
import { GetIcon, Icons } from 'ui'

import { Borders } from '@/styles/borders'
import { BTN } from '@/styles/components/button'
import { TEXT_ENUM } from '@/styles/components/text'
import { TOOLTIP_ENUM } from '@/styles/components/tooltip'

import { useSelectedWorkflow } from '../../hooks'
import { useWebhookUrl } from '../../hooks/workflow'
import { CopyWebhookUrl } from '../CopyWebhookUrl'
import { PanelInputLabel } from '../side-panel/panel-variants/PanelInputLabel'
import { WebhookConnectionModal } from '../WebhookConnectionModal'
import { WebhookHeaders } from '../WebhookHeaders'
import { WebhookPayload } from '../WebhookPayload'

export const WebhookTrigger = () => {
	const { isOpen, onClose, onOpen } = useDisclosure()
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	const testPayload =
		selectedWorkflow?.meta?.trigger_config?.test_request_payload

	const lastRequestedTime = testPayload?.timestamp
		? dayjs(testPayload.timestamp).format('MMMM DD, YYYY')
		: null

	const webhookUrl = useWebhookUrl({ test: true })

	return (
		<Flex w="full" flexDir="column">
			<PanelInputLabel label="Custom webhook URL" fontWeight={500} />
			<CopyWebhookUrl url={webhookUrl} />
			<HStack
				mt={4}
				px={3}
				py={4}
				border={Borders.primary}
				borderColor={COLORS.purple[7]}
				bg={COLORS.purple[2]}
				borderRadius="6px"
			>
				<Text variant={TEXT_ENUM.regular14} color={COLORS.purple[9]}>
					Paste this URL to your integration platform and get started
				</Text>
			</HStack>

			<Button
				variant={BTN.outline}
				mt={4}
				onClick={onOpen}
				rightIcon={<GetIcon icon={Icons.play} />}
			>
				Test Trigger
			</Button>

			{testPayload && (
				<Flex flexDir="column" mt={4}>
					<HStack justifyContent="space-between">
						<Tooltip
							isDisabled={!lastRequestedTime}
							label={`Captured on: ${lastRequestedTime}`}
							variant={TOOLTIP_ENUM.workflows_side_panel}
						>
							<HStack>
								<Text variant={TEXT_ENUM.semibold14}>Recent Request</Text>
								<GetIcon icon={Icons.timer} />
							</HStack>
						</Tooltip>
					</HStack>
					<WebhookHeaders
						headers={testPayload.headers}
						handleViewMore={onOpen}
					/>
					<WebhookPayload payload={testPayload.body} />
				</Flex>
			)}

			<WebhookConnectionModal isOpen={isOpen} onClose={onClose} />
		</Flex>
	)
}
