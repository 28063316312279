import { Button, Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import { useState } from 'react'
import { GetIcon, Icons } from 'ui'

import { useUpdateWorkflowNode } from '@/modules/workflow/hooks'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import { LogicStepDetails } from '@/modules/workflow/types/logic'
import { BTN } from '@/styles/components/button'

import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { WarningModal } from '../../WarningModal'
import { ConditionalModal } from '../conditional/ConditionalModal'
import { ConditionDetails } from '../conditional/ConditionDetails'
import {
	ConditionComparatorInput,
	ConditionParameterInput,
	ConditionValueInput,
} from './conditionInputs'

export const IfElse = () => {
	const selectedNode = useSelectedNode()
	const updateNode = useUpdateWorkflowNode()
	const [isConditionModalOpen, setIsConditionModalOpen] = useState(false)
	const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)

	const stepDetails = selectedNode?.data.stepDetails as LogicStepDetails
	const conditionBlocks = stepDetails?.branches[0]?.conditionBlocks || []

	const handleModalOpen = () => {
		setIsConditionModalOpen(true)
	}

	const handleModalClose = () => {
		setIsConditionModalOpen(false)
	}

	const handleOpenConfirmation = () => {
		setIsConditionModalOpen(false)
		setIsWarningModalOpen(true)
	}

	const handleCancelConfirmation = () => {
		setIsWarningModalOpen(false)
		setIsConditionModalOpen(true)
	}

	const handleRemoveAllConditions = (branchIndex: number) => {
		if (!selectedNode) return
		const details = cloneDeep(stepDetails)

		if (!details) return

		details.branches[branchIndex].conditionBlocks = []

		updateNodeStepDetails(details)
	}

	const handleRemoveConfirm = (branchIndex: number) => {
		handleRemoveAllConditions(branchIndex)
		handleCancelConfirmation()
	}

	const updateNodeStepDetails = (newDetails) => {
		if (!selectedNode) return

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: newDetails,
			},
		})
	}

	const conditionComponents = {
		ConditionParameterInput,
		ConditionComparatorInput,
		ConditionValueInput,
	}

	return (
		<Flex w="100%" direction="column">
			<PanelInputLabel label="Inputs" description="Conditions" />
			{conditionBlocks.length > 0 && (
				<ConditionDetails conditionBlocks={conditionBlocks} />
			)}
			<Button
				variant={BTN.workflows_side_panel}
				leftIcon={<GetIcon icon={Icons.adjustments} />}
				onClick={handleModalOpen}
			>
				{`${conditionBlocks?.length > 0 ? 'Edit' : 'Add'}`} conditions
			</Button>
			<ConditionalModal
				isOpen={isConditionModalOpen}
				branchIndex={0} // because there's only one branch for now
				selectedNode={selectedNode}
				conditionalObject={stepDetails}
				conditionComponents={conditionComponents}
				handleOpenConfirmation={handleOpenConfirmation}
				updateNode={updateNodeStepDetails}
				onClose={handleModalClose}
			/>
			<WarningModal
				isOpen={isWarningModalOpen}
				icon={Icons.trash}
				warningMessage="Do you really want to remove all conditions?"
				warningSubtext="You can’t undo this action and will need to add all conditions from scratch."
				handleConfirm={() => handleRemoveConfirm(0)}
				handleCancel={handleCancelConfirmation}
			/>
		</Flex>
	)
}
