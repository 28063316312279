import { Box, Center, Flex } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { PlusButton } from './PlusButton'

type Props = {
	id: string
}

export const NoChildren = ({ id }: Props) => {
	return (
		<Center left="calc(50% - 0px)" position="absolute">
			<Box backgroundColor={COLORS.background[9]} width="2px" height="68px" />
			<Flex w="12px" h="12px" pos="absolute" bottom="-12px" left="-9px">
				<PlusButton branchId={id} />
			</Flex>
		</Center>
	)
}
