import { Button, Center, Flex, Text } from '@chakra-ui/react'
import { FallbackProps } from 'react-error-boundary'
import { GetIcon, Icons } from 'ui'

import { BTN } from '@/styles/components/button'
import { TEXT_ENUM } from '@/styles/components/text'

export const Fallback = ({ resetErrorBoundary }: FallbackProps) => {
	return (
		<Center h="100vh" w="100vw">
			<Flex direction="column" align="center" justify="center">
				<GetIcon icon={Icons.default_logo} fontSize="52px" />
				<Flex direction="column" align="center" justify="center" mt={10}>
					<Text variant={TEXT_ENUM.h2}>Error loading this page</Text>
					<Text variant={TEXT_ENUM.md} mt={1} fontWeight={400}>
						Sorry, something is not right. Please try again later.
					</Text>
					<Button
						mt={6}
						variant={BTN.secondary_v2}
						onClick={resetErrorBoundary}
					>
						Try again
					</Button>
				</Flex>
			</Flex>
		</Center>
	)
}
