import {
	Editable,
	EditableInput,
	EditablePreview,
	Flex,
	Text,
	Tooltip,
	useEditableControls,
} from '@chakra-ui/react'
import { ColorTokens } from '@ds/tokens/colors'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { FONT_SIZES, GetIcon, Icons, WEIGHT } from 'ui'

import { useAppDispatch } from '@/app/hooks'
import { FormTable, UpdateFormTablePayload } from '@/modules/forms/types'
import { useWorkflowReadOnly } from '@/modules/workflow/hooks/workflow'
import { TEXT_ENUM } from '@/styles/components/text'
import { TOOLTIP_ENUM } from '@/styles/components/tooltip'

import { workflowsActions } from '../../../slice'
import { PanelCloseButton } from '../panel-variants/PanelCloseButton'
import { PanelSectionDivider } from '../panel-variants/PanelSectionDivider'
import { BackButton } from './BackButton'

interface ContentHeaderProps {
	enableNavigation?: boolean
	title?: string
	titleIcon?: Icons
	editable?: boolean
	selectedWorkflow?: FormTable | undefined
	updateForm?: (form: UpdateFormTablePayload) => void
	onNavigationBackClick?: () => void
	children?: ReactNode
}

type Props =
	| (ContentHeaderProps & {
			editable: true
			selectedWorkflow: FormTable | undefined
			updateForm: (form: UpdateFormTablePayload) => void
	  })
	| (ContentHeaderProps & {
			editable?: false
			selectedWorkflow?: never
			updateForm?: never
	  })

export const PanelContentHeader = ({
	enableNavigation,
	title,
	titleIcon,
	editable = false,
	selectedWorkflow,
	updateForm,
	onNavigationBackClick,
	children,
}: Props) => {
	const dispatch = useAppDispatch()
	const isReadOnly = useWorkflowReadOnly()

	const handleBackClick = () => {
		dispatch(workflowsActions.updateSelectedNode(null))
	}

	const handleClose = () => {
		dispatch(workflowsActions.setSelectedSidePanelNavigationId(null))
		dispatch(workflowsActions.setIsSidePanelContentOpen(false))
		dispatch(workflowsActions.updateSelectedNode(null))
	}

	const handleWorkflowNameChange = (newName: string) => {
		if (isReadOnly || !selectedWorkflow) return

		updateForm({
			form: {
				id: selectedWorkflow.id,
				workflow_name: newName,
			},
		})
	}

	const ref = useRef<HTMLDivElement>(null)
	const [currentRefEl, setCurrentRefEl] = useState<HTMLDivElement | null>(
		ref.current,
	)

	useEffect(() => {
		setCurrentRefEl(ref.current)
	}, [ref])

	const isTextOverflowing = currentRefEl
		? currentRefEl.scrollWidth > currentRefEl.clientWidth
		: false

	return (
		<Flex
			direction="column"
			position="sticky"
			mx={-3}
			px={3}
			top={0}
			pt={3}
			mb={4}
			gap={2}
			zIndex={999}
			bg={ColorTokens.bg_card}
		>
			<Flex justify="space-between">
				<Flex align="center">
					{enableNavigation && (
						<BackButton
							aria-label="back"
							boxSize={5}
							mr={3}
							onClick={onNavigationBackClick || handleBackClick}
						/>
					)}
					{titleIcon && <GetIcon icon={titleIcon} boxSize={5} />}
					{title ? (
						editable ? (
							<Editable
								display="flex"
								width="280px"
								alignItems="center"
								gap={2}
								defaultValue={title}
								ml={2}
								fontSize={FONT_SIZES.lg}
								fontWeight={WEIGHT.semibold}
								cursor="pointer"
							>
								<Tooltip
									label={title}
									isDisabled={!isTextOverflowing}
									variant={TOOLTIP_ENUM.workflows_side_panel}
								>
									<EditablePreview
										ref={ref}
										cursor="pointer"
										overflow="hidden"
										whiteSpace="nowrap"
										textOverflow="ellipsis"
									/>
								</Tooltip>
								<EditableControls />
								<EditableInput
									_focus={{ border: 'none' }}
									onBlur={(e) => handleWorkflowNameChange(e.target.value)}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											e.currentTarget.blur()
										}
									}}
								/>
							</Editable>
						) : (
							<Text variant={TEXT_ENUM.h2} ml={2}>
								{title}
							</Text>
						)
					) : null}
				</Flex>
				<PanelCloseButton mr={-2} onClick={handleClose} />
			</Flex>
			<Flex direction="column">{children}</Flex>
			<PanelSectionDivider />
		</Flex>
	)
}

const EditableControls = () => {
	const { isEditing, getEditButtonProps } = useEditableControls()
	return isEditing ? null : (
		<GetIcon
			icon={Icons.edit2}
			boxSize={4}
			{...getEditButtonProps()}
			ref={null} // Function components cannot be given refs.
		/>
	)
}
