import { Icons } from '@ds/Icons'
import { useCallback } from 'react'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { LabeledRefNodeValue } from '../../../types/actions'
import { WorkflowIntegrationIds } from '../../../utils/mappings'
import { useGetUpstreamNodes } from '../../useGetUpstreamNodes'

export const useGetAiActionDrpOptions = (): AsyncDrpOptionGenerator => {
	const { getUpstreamNodes } = useGetUpstreamNodes()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const upstreamNodes = getUpstreamNodes()
			const aiPromptNodes = upstreamNodes.filter(
				(n) => n.data.integrationId === WorkflowIntegrationIds.aiPrompt,
			)

			const aiPromptOptions: DataReferencePickerOption[] = aiPromptNodes.map(
				(n) => ({
					label: `Output from "${n.data.editableName}"`,
					value: null,
					onSelect: () => {
						const refNodeId = n.data.id
						const newRfn: LabeledRefNodeValue = {
							refNodeId,
							variable: '$.output',
							value: null,
							label: `Output from "${n.data.editableName}"`,
							icon: Icons.sparkles,
						}

						onSelect(newRfn)
					},
				}),
			)

			return [
				{
					label: 'AI Prompt outputs',
					icon: Icons.sparkles,
					value: aiPromptOptions,
				},
			]
		},
		[getUpstreamNodes],
	)
}
