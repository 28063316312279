import { Text } from '@chakra-ui/react'
import { formatUTCDate } from 'shared-utils'

import { TEXT_ENUM } from '@/styles/components/text'

import { WorkflowLogsMeta } from '../../types/meta'

type Props = {
	meta?: WorkflowLogsMeta | null
}

export const LogsOverviewPublished = ({ meta }: Props) => {
	if (!meta?.first_published_at) return null

	const formattedDate = formatUTCDate({
		date: meta?.first_published_at,
		format: 'MMMM D, YYYY',
	})
	const formattedTime = formatUTCDate({
		date: meta?.first_published_at,
		format: 'h:mm A',
	})

	return (
		<Text variant={TEXT_ENUM.regular14} mb={8} px={4}>
			{`Workflow first published on ${formattedDate} at ${formattedTime}, collecting data since that time.`}
		</Text>
	)
}
