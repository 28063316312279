import { IconButton, IconButtonProps } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

export const BackButton = ({ ...props }: IconButtonProps) => {
	return (
		<IconButton
			icon={<GetIcon icon={Icons.arrow_left} boxSize={props.boxSize} />}
			color={COLORS.gray[9]}
			bg="transparent"
			minWidth="max-content"
			className="back-button"
			_hover={{ bg: 'transparent' }}
			_focus={{ border: 'none' }}
			_active={{ bg: 'transparent' }}
			{...props}
		/>
	)
}
