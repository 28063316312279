import { FocusEventHandler } from 'react'

import { PanelInput } from '@/modules/workflow/components/side-panel/panel-variants/PanelInput'
import { FieldRefNodeValue } from '@/modules/workflow/types/actions'

import { ConditionInputProps } from '../../conditional/Condition'

export const ConditionValueInput = ({
	condition,
	handleUpdateInput,
}: ConditionInputProps) => {
	const value = condition.value as FieldRefNodeValue | null

	const handleOnBlur: FocusEventHandler<HTMLInputElement> = (e) => {
		const rfn = {
			refNodeId: null,
			variable: null,
			value: e.target.value,
		}
		handleUpdateInput(rfn)
	}

	return (
		<PanelInput
			initialValue={value ? value.value : null}
			h="34px"
			w="fit-content"
			minW="180px"
			onBlur={handleOnBlur}
		/>
	)
}
