import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

import { CALENDER_QUERIES } from '../Calendar/styles/responsive'

type ColoredDotProps = BoxProps

const ColoredDot: React.FC<ColoredDotProps> = React.memo((props) => {
	return (
		<Box
			display="inline-flex"
			boxSize="8px"
			sx={{
				[CALENDER_QUERIES.xs]: {
					boxSize: '6px',
				},
			}}
			rounded="full"
			bg="#22B56C"
			{...props}
		/>
	)
})

export default ColoredDot
ColoredDot.displayName = 'ColoredDot'
