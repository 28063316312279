import { FormType } from '@/modules/forms/types'

import { DescriptionTextarea } from '../components/side-panel/panel-content/DescriptionTextarea'
import { PanelContentHeader } from '../components/side-panel/panel-content/PanelContentHeader'
import { StepNameInput } from '../components/side-panel/panel-content/step-details/StepNameInput'
import { FormSubmissionTrigger } from '../components/triggers/FormSubmissionTrigger'
import { HubspotCreateTrigger } from '../components/triggers/HubspotCreateTrigger'
import { MeetingBookedTrigger } from '../components/triggers/MeetingBookedTrigger'
import { MeetingCancelledTrigger } from '../components/triggers/MeetingCancelledTrigger'
import { MeetingNoShowTrigger } from '../components/triggers/MeetingNoShowTrigger'
import { MeetingReassignedTrigger } from '../components/triggers/MeetingReassignedTrigger'
import { MeetingRescheduledTrigger } from '../components/triggers/MeetingRescheduledTrigger'
import { SalesforceCreateTrigger } from '../components/triggers/SalesforceCreateTrigger'
import { SalesforceUpdateTrigger } from '../components/triggers/SalesforceUpdateTrigger'
import { TagAssignedTrigger } from '../components/triggers/TagAssignedTrigger'
import { TriggersSelect } from '../components/triggers/TriggersSelect'
import { WebhookTrigger } from '../components/triggers/WebhookTrigger'
import { WorkflowTriggersList } from '../components/triggers/WorkflowTriggersList'
import { useUpdateWorkflowNode } from '../hooks'
import { useSelectedNode, useSelectedWorkflow } from '../hooks/workflow'

export const WorkflowTriggerStepPartial = () => {
	const updateNode = useUpdateWorkflowNode()

	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
	const type = selectedWorkflow?.type || FormType.draft_trigger

	const selectedNode = useSelectedNode()

	if (type === FormType.draft_trigger) {
		return <WorkflowTriggersList />
	}

	if (!selectedNode || !selectedWorkflow) return null

	const getTriggerConfig = () => {
		switch (type) {
			case FormType.regular:
			case FormType.imported:
				return <FormSubmissionTrigger />

			case FormType.meeting_booked_trigger:
				return <MeetingBookedTrigger />

			case FormType.meeting_cancelled_trigger:
				return <MeetingCancelledTrigger />

			case FormType.meeting_reassigned_trigger:
				return <MeetingReassignedTrigger />

			case FormType.meeting_rescheduled_trigger:
				return <MeetingRescheduledTrigger />

			case FormType.meeting_no_show_trigger:
				return <MeetingNoShowTrigger />

			case FormType.tag_assigned_trigger:
				return <TagAssignedTrigger />

			case FormType.webhook_trigger:
				return <WebhookTrigger />

			case FormType.salesforce_object_create_trigger:
				return <SalesforceCreateTrigger />

			case FormType.salesforce_object_update_trigger:
				return <SalesforceUpdateTrigger />

			case FormType.hubspot_trigger:
				return <HubspotCreateTrigger />

			default:
				return null
		}
	}

	const handleUpdateDescription = (value: string) => {
		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				description: value,
			},
		})
	}

	return (
		<>
			<PanelContentHeader enableNavigation>
				<TriggersSelect
					type={type}
					selectedNode={selectedNode}
					selectedWorkflow={selectedWorkflow}
				/>
				<DescriptionTextarea
					placeholder="Add a description"
					initialValue={selectedNode.data.description || ''}
					onBlur={handleUpdateDescription}
				/>
			</PanelContentHeader>
			<StepNameInput
				stepLabel="Trigger Name"
				key={selectedNode.data.id}
				initialValue={selectedNode.data.editableName || ''}
				onBlur={(e) => {
					updateNode({
						...selectedNode,
						data: {
							...selectedNode.data,
							editableName: e.target.value,
						},
					})
				}}
			/>
			{getTriggerConfig()}
		</>
	)
}
