import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { lazy } from 'react'

import { VITE_LAUNCHDARKLY_SDK_KEY } from '@/app/config'

export const LaunchDarklyProvider = lazy(async () => {
	const LDProvider = await asyncWithLDProvider({
		clientSideID: VITE_LAUNCHDARKLY_SDK_KEY,
		reactOptions: {
			useCamelCaseFlagKeys: false,
		},
	})

	return { default: LDProvider }
})
