import { useCallback } from 'react'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { settleAllOptionGenerators } from '../../../utils/settleAllOptionGenerators'
import { useGetApolloEmailDrpOptions } from '../generators/useGetApolloEmailDrpOptions'
import { useGetOwnerDrpOptions } from './useGetOwnerDrpOptions'

export const useGetApolloStartSequenceDrpOptions =
	(): AsyncDrpOptionGenerator => {
		const getOwnerDrpOptions = useGetOwnerDrpOptions()
		const getApolloEmailDrpOptions = useGetApolloEmailDrpOptions()

		return useCallback(
			async ({
				onSelect,
			}: {
				onSelect: (value: any) => void
			}): Promise<DataReferencePickerOption[]> => {
				const optionGenerators = [getOwnerDrpOptions, getApolloEmailDrpOptions]

				return settleAllOptionGenerators(optionGenerators, onSelect)
			},
			[getApolloEmailDrpOptions, getOwnerDrpOptions],
		)
	}
