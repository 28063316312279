import dayjs from 'dayjs'

import { WorkflowLogsAnalytics } from '../types/analytics'

const MAP_INDEX_TO_DAYS = {
	0: 'Sun',
	1: 'Mon',
	2: 'Tue',
	3: 'Wed',
	4: 'Thu',
	5: 'Fri',
	6: 'Sat',
}

export const buildGraphData = (
	data?: WorkflowLogsAnalytics | null,
): { label: string; error: number; success: number }[] => {
	try {
		if (!data?.analytics) return []
		let currentDayIndex = dayjs().day()

		const last7Days: string[] = []

		while (last7Days.length < 7) {
			last7Days.push(MAP_INDEX_TO_DAYS[currentDayIndex])
			currentDayIndex = currentDayIndex - 1
			if (currentDayIndex < 0) {
				currentDayIndex = 6
			}
		}

		return last7Days.reverse().map((day) => {
			return {
				label: day,
				error: data?.analytics?.[day]?.errorCount || 0,
				success: data?.analytics?.[day]?.successCount || 0,
			}
		})
	} catch (err) {
		console.error(err)
		return []
	}
}
