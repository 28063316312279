import {
	CanvasNode,
	RFNodeType,
	StepType,
} from '@/modules/workflow/types/nodes'
import { GenericIntegrationIds } from '@/modules/workflow/utils/mappings'

// TODO: Not sure exactly which folder to put this in but will update once we get to the Canvas phase.
export const FlowStart: CanvasNode = {
	id: '0',
	type: RFNodeType.flowStart,
	position: { x: 0, y: 0 },
	connectable: true,
	draggable: true,
	data: {
		id: '0',
		integrationId: GenericIntegrationIds.flowStart,
		editableName: '',
		stepType: StepType.WorkflowStart,
		description: '',
		children: [],
		parentIds: [],
		stepDetails: {},
		nextStep: null,
		x: 0,
		y: 0,
	},
}
