import { Flex } from '@chakra-ui/react'
import { SingleValue } from 'react-select'

import { useGetTagsQuery } from '@/app/tags/api'
import { Tag } from '@/app/tags/types'
import { useTeamId } from '@/app/user/api/teams/selectors'
import { useUpdateWorkflowNode } from '@/modules/workflow/hooks'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import { Default_AddTag } from '@/modules/workflow/types/actions'

import { PanelInputLabel } from '../../../../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../../../../panel-variants/PanelSelect'
import { TagOption, TagSingleValue } from './selectComponents'

export const DefaultAddTag = () => {
	const teamId = useTeamId()
	const { data: tags, isLoading: isTagsDataLoading } = useGetTagsQuery({
		teamId,
	})
	const updateNode = useUpdateWorkflowNode()
	const selectedNode = useSelectedNode()

	const stepDetails = selectedNode?.data?.stepDetails as Default_AddTag

	if (!tags) return null

	const handleTagChange = (
		tagOption: SingleValue<{ label: string; value: Tag }>,
	) => {
		if (!selectedNode || !tagOption) return

		const { value: tag } = tagOption

		const newStepDetails = {
			...stepDetails,
		}

		newStepDetails.tagId = {
			refNodeId: null,
			variable: null,
			value: tag.id,
		}

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const tagOptions = Object.values(tags).map((tag) => ({
		label: tag.label,
		value: tag,
	}))

	const selectedTagValue = tagOptions.find(
		(tag) => tag.value.id === stepDetails?.tagId?.value,
	)

	return (
		<Flex w="100%" direction="column">
			<PanelInputLabel label="Tag" />
			<PanelSelect
				placeholder="Select a tag"
				isLoading={isTagsDataLoading}
				value={selectedTagValue || null}
				options={tagOptions}
				components={{ Option: TagOption, SingleValue: TagSingleValue }}
				onChange={(tagOption) =>
					handleTagChange(
						tagOption as SingleValue<{ label: string; value: Tag }>,
					)
				}
			/>
		</Flex>
	)
}
