export const FONT_SIZES = {
	xs: '0.75rem', // 13px
	sm: '0.875rem', // 14px
	md: '1rem', // 16px
	lg: '1.125rem', //18px
	xl: '1.25rem', // 20px
	'2xl': '1.5rem', // 24px
	'3xl': '1.875rem', // 30px
	'4xl': '2.25rem', // 36px
	'5xl': '3rem', // 48px
	'6xl': '3.75rem', // 60px
	'7xl': '4.5rem', // 72px
	'8xl': '6rem', // 96px
	'9xl': '8rem', // 128px
}

export const LETTER_SPACING = {
	tighter: '-0.009em',
	tight: '-0.025em',
	normal: '0',
	wide: '0.003125em',
	wider: '0.00625em',
	widest: '0.1em',
}

export const LINE_HEIGHT = {
	normal: 'normal',
	none: '1',
	shorter: '1.25',
	short: '1.375',
	base: '1.5',
	tall: '1.625',
	taller: '2',
}

export const WEIGHT = {
	hairline: 100,
	thin: 200,
	light: 300,
	regular: 400,
	medium: 500,
	semibold: 600,
	bold: 700,
	extrabold: 800,
	black: 900,
}

export const FONTS = {
	boldHeading: `Inter, SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
	heading: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
	body: `Inter, CustomDynamicFont, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
	mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
}

export enum TextVariants {
	h1 = 'h1',
	h2 = 'h2',
	h3 = 'h3',
	h4 = 'h4',
	h5 = 'h5',
	h6 = 'h6',
	xs = 'xs',
	sm = 'sm',
	md = 'md',
	lg = 'lg',
	xl = 'xl',
	input_label = 'input_label',
	cell_value = 'cell_value',
	cell_value_md = 'cell_value_md',
	table_header = 'table_header',

	regular24 = 'regular24',
	regular20 = 'regular20',
	regular18 = 'regular18',
	regular16 = 'regular16',
	regular14 = 'regular14',
	regular12 = 'regular12',

	medium24 = 'medium24',
	medium20 = 'medium20',
	medium18 = 'medium18',
	medium16 = 'medium16',
	medium14 = 'medium14',
	medium12 = 'medium12',

	semibold24 = 'semibold24',
	semibold20 = 'semibold20',
	semibold18 = 'semibold18',
	semibold16 = 'semibold16',
	semibold14 = 'semibold14',
	semibold12 = 'semibold12',

	bold24 = 'bold24',
	bold20 = 'bold20',
	bold18 = 'bold18',
	bold16 = 'bold16',
	bold14 = 'bold14',
	bold12 = 'bold12',

	hyperlink = 'hyperlink',
}
