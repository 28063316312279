import { RootState } from '@/app/store'

export const selectWorkflowsViewPreferences = (state: RootState) =>
	state.workflowsHome.workflowsViewPreferences

export const selectWorkflowsFilter = (state: RootState) =>
	state.workflowsHome.workflowsFilter

export const selectWorkflowsSortBy = (state: RootState) =>
	state.workflowsHome.workflowsSortBy

export const selectShowEmptyStateModal = (state: RootState) =>
	state.workflowsHome.showEmptyStateModal
