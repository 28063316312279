import { FormTable } from '@/modules/forms/types'

export enum VersionEventType {
	Created = 'created',
	Published = 'published',
}

export type FormattedVersion = {
	formVersion: number
	member: string | null
	date: string
	type: VersionEventType
}

export const formatVersions = (versions: FormTable[]): FormattedVersion[] => {
	return versions.map((version, idx) => {
		const formattedVersion = {
			formVersion: version.form_version,
			member: version.created_by
				? `${version.created_by.first_name} ${version.created_by.last_name}`
				: null,
			date: version.created_at,
		}

		if (idx === versions.length - 1) {
			return {
				...formattedVersion,
				type: VersionEventType.Created,
			}
		}

		return {
			...formattedVersion,
			type: VersionEventType.Published,
		}
	})
}
