import { Flex, Text } from '@chakra-ui/react'

import { FormTable } from '@/modules/forms/types'
import { TEXT_ENUM } from '@/styles/components/text'

import { NavigateToFormCard } from '../../../NavigateToFormCard'

interface OverviewConnectedFormsProps {
	forms: FormTable[]
}

export const OverviewConnectedForms = ({
	forms,
}: OverviewConnectedFormsProps) => {
	return (
		<Flex direction="column" gap={3}>
			<Text variant={TEXT_ENUM.semibold14}>
				{forms.length > 1 ? 'Forms' : 'Form'} connected
			</Text>
			{forms.map((form, i) => (
				<NavigateToFormCard key={i} formId={form.id} formType={form.type} />
			))}
		</Flex>
	)
}
