import { useMemo } from 'react'

import { useAppSelector } from '@/app/hooks'
import { FormTable, FormType } from '@/modules/forms/types'

import { WorkflowsFilters } from '../components/WorkflowsFilterBy'
import { WorkflowsSortByOptions } from '../components/WorkflowsSortBy'
import {
	selectWorkflowsFilter,
	selectWorkflowsSortBy,
} from '../slice/selectors'

const workflowsFilterBy = (f: FormTable, filter: WorkflowsFilters | null) => {
	switch (filter) {
		case WorkflowsFilters.form_submission:
			return f.type === FormType.regular || f.type === FormType.imported
		case WorkflowsFilters.meeting_booked:
			return f.type === FormType.meeting_booked_trigger
		case WorkflowsFilters.meeting_cancelled:
			return f.type === FormType.meeting_cancelled_trigger
		case WorkflowsFilters.meeting_rescheduled:
			return f.type === FormType.meeting_rescheduled_trigger
		case WorkflowsFilters.meeting_reassigned:
			return f.type === FormType.meeting_reassigned_trigger
		case WorkflowsFilters.meeting_no_show:
			return f.type === FormType.meeting_no_show_trigger
		case WorkflowsFilters.tag_assigned:
			return f.type === FormType.tag_assigned_trigger
		case WorkflowsFilters.hubspot_object_created:
			return f.type === FormType.hubspot_trigger
		case WorkflowsFilters.salesforce_object_created:
			return f.type === FormType.salesforce_object_create_trigger
		case WorkflowsFilters.salesforce_object_updated:
			return f.type === FormType.salesforce_object_update_trigger
		case WorkflowsFilters.webhook_trigger:
			return f.type === FormType.webhook_trigger

		default:
			return true
	}
}

const sortByProperty = (
	a: FormTable,
	b: FormTable,
	property: WorkflowsSortByOptions | null,
) => {
	if (!property) return 0
	const aValue = a[property]
	const bValue = b[property]

	if (aValue == null || bValue == null) return 0

	if (property === WorkflowsSortByOptions.DateCreated) {
		const aDate = new Date(aValue).getTime()
		const bDate = new Date(bValue).getTime()
		return bDate - aDate
	}

	if (typeof aValue === 'string' && typeof bValue === 'string') {
		return aValue.localeCompare(bValue)
	}

	if (typeof aValue === 'number' && typeof bValue === 'number') {
		return aValue - bValue
	}
	return 0
}

export const useFilteredWorkflows = (workflows: FormTable[]) => {
	const filter = useAppSelector(selectWorkflowsFilter)
	const sortProperty = useAppSelector(selectWorkflowsSortBy)

	return useMemo(() => {
		return (
			workflows
				?.filter((f) => workflowsFilterBy(f, filter))
				.sort((a, b) => sortByProperty(a, b, sortProperty)) || []
		)
	}, [filter, sortProperty, workflows])
}
