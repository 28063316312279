import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'
import { useCallback } from 'react'
import { useStore } from 'react-redux'

import { useAppDispatch } from '@/app/hooks'

import { useUpdateFormMutation } from '../../forms/api'
import { workflowsActions } from '../slice'
import { LogicStepDetails, ResultOrElse } from '../types/logic'
import { CanvasNode, CanvasNodes, StepType } from '../types/nodes'
import { Salesforce_MatchRecord } from '../types/salesforce'
import { canvasNodesToWorkflow } from '../utils/graph-operations'
import { WorkflowIntegrationIds } from '../utils/mappings'
import {
	useCanvasNodes,
	useSelectedWorkflow,
	useWorkflowReadOnly,
} from './workflow'

/** This hook is use to update a specific step or node in workflow */
export const useUpdateWorkflowNode = () => {
	const store = useStore()
	const [updateForm] = useUpdateFormMutation()
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
	const dispatch = useAppDispatch()
	const isReadOnly = useWorkflowReadOnly()

	const updateNode = useCallback(
		(node: CanvasNode, ignoreSync?: boolean) => {
			if (isReadOnly || !selectedWorkflow) return

			const allCanvasNodes = store.getState().workflows.currentCanvasNodes
			const newCanvasNodes = cloneDeep(allCanvasNodes)

			// This is to update the current node data in the store
			dispatch(workflowsActions.setCurrentNodeData(node))

			/** Update node */
			newCanvasNodes[node.id] = node
			/** Convert canves node to workflow */
			const newWorkflow = canvasNodesToWorkflow(newCanvasNodes)

			updateForm({
				form: {
					id: selectedWorkflow.id,
					workflow: newWorkflow,
				},
			})

			if (!ignoreSync) {
				dispatch(workflowsActions.setSyncWorkflow(true))
			}
		},
		[store, selectedWorkflow, dispatch, updateForm],
	)

	return updateNode
}

export const useUpdateWorkflowNodes = () => {
	const isReadOnly = useWorkflowReadOnly()
	const [updateForm] = useUpdateFormMutation()
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	const dispatch = useAppDispatch()

	const updateWorkflowNodes = useCallback(
		(allCanvasNodes: CanvasNode[], ignoreSync?: boolean) => {
			if (isReadOnly || !selectedWorkflow) return

			const graph: CanvasNodes = {}
			allCanvasNodes.forEach((n) => (graph[n.id] = n))

			/** Convert canves node to workflow */
			const newWorkflow = canvasNodesToWorkflow(graph)

			// Update current canvas nodes in the store
			dispatch(workflowsActions.setCurrentCanvasNodes(graph))

			updateForm({
				form: {
					id: selectedWorkflow.id,
					workflow: newWorkflow,
				},
			})

			if (!ignoreSync) {
				dispatch(workflowsActions.setSyncWorkflow(true))
			}
		},
		[selectedWorkflow, updateForm, dispatch],
	)

	return updateWorkflowNodes
}

export const useDeleteActionNode = () => {
	const isReadOnly = useWorkflowReadOnly()
	const nodes = cloneDeep(useCanvasNodes()?.canvasNodes)

	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	const [updateForm] = useUpdateFormMutation()
	const dispatch = useAppDispatch()
	const deleteActionNode = useCallback(
		(nodeId: string) => {
			if (isReadOnly || !selectedWorkflow) return
			/*
			Steps:
			1) Remove node id from all the parents' .children array
			2) Connect child of node being deleted to its parents -- not necessary
			3) Remove node id from its child .parentsIds array
			4) Finally delete node
			*/
			const parentIds = nodes[nodeId]?.data.parentIds
			const childrenIds = nodes[nodeId]?.data.children

			parentIds.forEach((pid) => {
				const parentId = pid.includes('&') ? pid.substring(0, 8) : pid
				const parentNode = nodes[parentId]
				const i = parentNode?.data.children.findIndex((c) => c === nodeId)
				if (i < 0) return
				switch (parentNode?.data.stepType) {
					case StepType.Action:
					case StepType.WorkflowStart:
					case StepType.Empty:
						// Step 1
						nodes[parentId].data.children.splice(i, 1)
						nodes[parentId].data.nextStep = childrenIds[0]

						break
					case StepType.Logic:
						// Step 1
						nodes[parentId].data.children.splice(i, 1)
						nodes[parentId].data.nextStep = childrenIds[0]

						// Clearing either the resultChildId or elseChildId
						if (
							nodes[parentId].data.integrationId ===
							WorkflowIntegrationIds.ifElse
						) {
							const branch =
								nodeId ===
								(parentNode.data.stepDetails as LogicStepDetails).branches[0]
									.resultChildId
							const whichChild: ResultOrElse = branch
								? 'resultChildId'
								: 'elseChildId'
							;(parentNode.data.stepDetails as LogicStepDetails).branches[0][
								whichChild
							] = null
						}
						// Clearing in scheduler and sfdc-match
						if (
							nodes[parentId].data.integrationId ===
								WorkflowIntegrationIds.defaultDisplayScheduler ||
							nodes[parentId].data.integrationId ===
								WorkflowIntegrationIds.salesforceMatchRecord ||
							nodes[parentId].data.integrationId ===
								WorkflowIntegrationIds.hubspotMatchRecord
						) {
							const branch =
								nodeId ===
								(parentNode.data.stepDetails as Salesforce_MatchRecord)
									.resultChildId
							const whichChild: ResultOrElse = branch
								? 'resultChildId'
								: 'elseChildId'
							// for type checker, it will work also on type Default_DisplayScheduler
							;(nodes[parentId].data.stepDetails as Salesforce_MatchRecord)[
								whichChild
							] = null
						}
						break
					default:
						return
				}
			})

			// Step 3
			childrenIds.forEach((cid) => {
				const i = nodes[cid].data.parentIds.findIndex((p) => p === nodeId)
				if (i < 0) return
				nodes[cid].data.parentIds = []
			})

			// Step 4
			delete nodes[nodeId]
			updateForm({
				form: {
					id: selectedWorkflow.id,
					workflow: canvasNodesToWorkflow(nodes),
				},
			})

			dispatch(workflowsActions.setSyncWorkflow(true))
			dispatch(workflowsActions.updateSelectedNode(null))
		},
		[selectedWorkflow, nodes, updateForm, dispatch],
	)

	return deleteActionNode
}

export const useDeleteLogicNode = () => {
	const isReadOnly = useWorkflowReadOnly()
	const savedNodes = useCanvasNodes()?.canvasNodes
	const nodes = cloneDeep(savedNodes)

	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	const dispatch = useAppDispatch()

	const [updateForm] = useUpdateFormMutation()
	const deleteLogicNode = useCallback(
		(nodeId: string) => {
			if (isReadOnly || !selectedWorkflow) return

			const parentIds = nodes[nodeId]?.data.parentIds
			const childrenIds = nodes[nodeId]?.data.children

			parentIds.forEach((pid) => {
				const parentId = pid.includes('&') ? pid.substring(0, 8) : pid
				const i = nodes[parentId].data.children.findIndex((c) => c === nodeId)
				if (i < 0) return

				nodes[parentId].data.children.splice(i, 1)
				if (nodes[parentId].data.stepType === StepType.Logic) {
					if (
						nodes[parentId].data.integrationId === WorkflowIntegrationIds.ifElse
					) {
						const branch =
							nodeId ===
							(nodes[parentId].data.stepDetails as LogicStepDetails).branches[0]
								.resultChildId
						const whichChild: ResultOrElse = branch
							? 'resultChildId'
							: 'elseChildId'
						;(nodes[parentId].data.stepDetails as LogicStepDetails).branches[0][
							whichChild
						] = null
					}
					// Clearing in scheduler and sfdc-match
					if (
						nodes[parentId].data.integrationId ===
							WorkflowIntegrationIds.defaultDisplayScheduler ||
						nodes[parentId].data.integrationId ===
							WorkflowIntegrationIds.salesforceMatchRecord ||
						nodes[parentId].data.integrationId ===
							WorkflowIntegrationIds.hubspotMatchRecord
					) {
						const branch =
							nodeId ===
							(nodes[parentId].data.stepDetails as Salesforce_MatchRecord)
								.resultChildId
						const whichChild: ResultOrElse = branch
							? 'resultChildId'
							: 'elseChildId'
						;(nodes[parentId].data.stepDetails as Salesforce_MatchRecord)[
							whichChild
						] = null
					}
				}
			})

			childrenIds.forEach((cid) => {
				// Because in logic nodes' children the id of the parent node
				// is stored as xyz&true/xyz&false
				const i = nodes[cid].data.parentIds.findIndex(
					(p) => p.split('&')[0] === nodeId,
				)
				if (i < 0) return
				nodes[cid].data.parentIds = []
			})

			delete nodes[nodeId]
			updateForm({
				form: {
					id: selectedWorkflow.id,
					workflow: canvasNodesToWorkflow(nodes),
				},
			})

			dispatch(workflowsActions.setSyncWorkflow(true))
			dispatch(workflowsActions.updateSelectedNode(null))
		},
		[dispatch, nodes, selectedWorkflow, updateForm],
	)

	return deleteLogicNode
}

export const useDuplicateNode = () => {
	const isReadOnly = useWorkflowReadOnly()
	const savedNodes = useCanvasNodes()?.canvasNodes
	const canvasNodes = cloneDeep(savedNodes)

	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	const [updateForm] = useUpdateFormMutation()

	const dispatch = useAppDispatch()

	return useCallback(
		(nodeId: string) => {
			const selectedCanvasNode = canvasNodes[nodeId]
			if (isReadOnly || !selectedCanvasNode || !selectedWorkflow) return

			const oldStepDetails: any = selectedCanvasNode.data.stepDetails
			const newStepDetails: any = oldStepDetails
				? {
						...oldStepDetails,
						resultChildId: null,
						elseChildId: null,
					}
				: null

			// Check if branches exist and is an array
			if (Array.isArray(oldStepDetails?.branches)) {
				newStepDetails.branches = oldStepDetails.branches.map((branch) => ({
					...branch,
					resultChildId: null,
					elseChildId: null,
				}))
			}

			const id = nanoid(8)
			const newNode = {
				...selectedCanvasNode,
				id,
				data: {
					...selectedCanvasNode.data,
					id,
					nextStep: null,
					children: [],
					parentIds: [],
					stepDetails: { ...newStepDetails },
				},
				position: {
					x: selectedCanvasNode.position.x + 360,
					y: selectedCanvasNode.position.y,
				},
			}
			const allNewCanvasNodes = { ...canvasNodes, [id]: newNode }

			updateForm({
				form: {
					id: selectedWorkflow.id,
					workflow: canvasNodesToWorkflow(allNewCanvasNodes),
				},
			})

			dispatch(workflowsActions.setSyncWorkflow(true))
			dispatch(workflowsActions.setCanvasNodeMenuOpenId(null))
		},
		[canvasNodes, dispatch, selectedWorkflow, updateForm],
	)
}
