export const stringComparators: { label: string; value: string }[] = [
	{ label: 'contains string', value: 'CONTAINS_SUBSTRING' },
	{ label: 'does not contain string', value: 'DOES_NOT_CONTAIN_SUBSTRING' },
	{ label: 'is not defined', value: 'DOES_NOT_EXIST' },
	{ label: 'is defined', value: 'EXISTS' },
]

export const numberComparators: { label: string; value: string }[] = [
	{ label: 'greater than', value: '>' },
	{ label: 'greater than or equal to', value: '>=' },
	{ label: 'equal to', value: '=' },
	{ label: 'less than or equal to', value: '<=' },
	{ label: 'less than', value: '<' },
]

export const emailComparators: { label: string; value: string }[] = [
	{ label: 'is work email', value: 'NOT_GENERIC' },
	{ label: 'is not work email', value: 'GENERIC' },
]

export const selectComparators: { label: string; value: string }[] = [
	{ label: 'is any of', value: 'CONTAINS' },
	{ label: 'is not any of', value: 'DOES_NOT_CONTAIN' },
]

export const genericComparators: { label: string; value: string }[] = [
	{ label: 'is empty', value: 'EMPTY' },
	{ label: 'is not empty', value: 'NOT_EMPTY' },
]

export const allComparators: { label: string; value: string }[] = [
	...stringComparators,
	...numberComparators,
	...emailComparators,
	...selectComparators,
	...genericComparators,
]
