import { FormType } from '@/modules/forms/types'

export const EVENT_TYPE_OPTIONS = [
	{
		label: 'All event types',
		value: { all: true },
		validTriggerTypes: [
			FormType.meeting_booked_trigger,
			FormType.meeting_cancelled_trigger,
			FormType.meeting_rescheduled_trigger,
			FormType.meeting_reassigned_trigger,
			FormType.meeting_no_show_trigger,
		],
	},
	{
		label: 'All event types booked via links',
		value: { links: true },
		validTriggerTypes: [FormType.meeting_booked_trigger],
	},
	{
		label: 'All event types booked via workflows',
		value: { workflows: true },
		validTriggerTypes: [FormType.meeting_booked_trigger],
	},
	{
		label: 'Specific event types',
		value: { events: [] },
		validTriggerTypes: [
			FormType.meeting_booked_trigger,
			FormType.meeting_cancelled_trigger,
			FormType.meeting_rescheduled_trigger,
			FormType.meeting_reassigned_trigger,
			FormType.meeting_no_show_trigger,
		],
	},
]

export enum MeetingAction {
	Booked = 'booked',
	Cancelled = 'cancelled',
	Rescheduled = 'rescheduled',
	Reassigned = 'reassigned',
	NoShow = 'no-show',
}

export const getSpecificContextMeetingOptions = (
	meetingAction: MeetingAction,
) => {
	return [
		{
			label: `All meetings are ${meetingAction}`,
			value: {},
		},
		{
			label: `Meetings ${meetingAction} via link`,
			value: { links: true },
		},
		{
			label: `Meetings ${meetingAction} via workflow`,
			value: { workflows: true },
		},
	]
}
