import { cloneDeep } from 'lodash'

import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { CreateReminderDto, ReminderType, UpdateReminderDto } from '../types'

export enum RemindersEndpoints {
	getReminders = 'getReminders',
	getReminder = 'getReminder',

	/** Mutations */
	createReminder = 'createReminder',
	updateReminder = 'updateReminder',
	deleteReminder = 'deleteReminder',
	duplicateReminder = 'duplicateReminder',
}

const reminderstApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[RemindersEndpoints.getReminders]: builder.query<ReminderType[], void>({
			query: () => ({
				url: `/reminders`,
				method: 'GET',
				responseHandler: responseTransformer,
			}),
			providesTags: ['GET_REMINDERS'],
		}),

		[RemindersEndpoints.getReminder]: builder.query<ReminderType, string>({
			query: (reminderId) => ({
				url: `/reminders/${reminderId}`,
				method: 'GET',
				responseHandler: responseTransformer,
			}),
		}),

		[RemindersEndpoints.createReminder]: builder.mutation<
			ReminderType,
			CreateReminderDto
		>({
			query: (payload) => ({
				url: `/reminders`,
				method: 'POST',
				body: payload,
				responseHandler: responseTransformer,
			}),
			invalidatesTags: ['GET_REMINDERS'],
		}),

		[RemindersEndpoints.updateReminder]: builder.mutation<
			ReminderType,
			UpdateReminderDto
		>({
			query: (payload) => ({
				url: `/reminders/${payload.id}`,
				method: 'PATCH',
				body: payload,
				responseHandler: responseTransformer,
			}),
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchReminderById = dispatch(
					reminderstApi.util.updateQueryData(
						RemindersEndpoints.getReminder,
						arg.id,
						(oldReminder) => {
							return {
								...oldReminder,
								...arg,
								published: false,
							}
						},
					),
				)

				const patchAllReminders = dispatch(
					reminderstApi.util.updateQueryData(
						RemindersEndpoints.getReminders,
						undefined,
						(allReminders) => {
							const index = allReminders.findIndex(
								(reminder) => reminder.id === arg.id,
							)

							if (index === -1) return allReminders

							const oldReminder = allReminders[index]
							const newReminder = {
								...oldReminder,
								...arg,
							}
							allReminders[index] = newReminder
							return allReminders
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchAllReminders.undo()
					patchReminderById.undo()
				}
			},
		}),

		[RemindersEndpoints.duplicateReminder]: builder.mutation<
			ReminderType[],
			string
		>({
			query: (reminderId) => ({
				url: `/reminders/${reminderId}`,
				method: 'POST',
				responseHandler: responseTransformer,
			}),

			invalidatesTags: ['GET_REMINDERS'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchAllReminders = dispatch(
					reminderstApi.util.updateQueryData(
						RemindersEndpoints.getReminders,
						undefined,
						(draft) => {
							const foundReminder = draft.find((e) => e.id === arg)

							if (foundReminder) {
								const newReminder = cloneDeep(foundReminder)

								newReminder.name = `${foundReminder.name} Copy`
								newReminder.id = '1234'
								draft.unshift(newReminder)
							}

							return draft
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchAllReminders.undo()
				}
			},
		}),

		[RemindersEndpoints.deleteReminder]: builder.mutation<
			ReminderType[],
			string
		>({
			query: (reminderId) => ({
				url: `/reminders/${reminderId}`,
				method: 'DELETE',
				responseHandler: responseTransformer,
			}),
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					reminderstApi.util.updateQueryData(
						RemindersEndpoints.getReminders,
						undefined,
						(reminders) => {
							return reminders.filter((form) => form.id !== arg)
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),
	}),
})

export const {
	useGetRemindersQuery,
	useCreateReminderMutation,
	useGetReminderQuery,
	useDeleteReminderMutation,
	useUpdateReminderMutation,
	useDuplicateReminderMutation,
} = reminderstApi
