import { useCallback } from 'react'
import { Icons } from 'ui'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { WorkflowIntegrationIds } from '../../../utils/mappings'
import { useGetUpstreamNodes } from '../../useGetUpstreamNodes'

export const useGetMeetingOwnerDrpOptions = (): AsyncDrpOptionGenerator => {
	const { getUpstreamNodes } = useGetUpstreamNodes()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value) => void
		}): Promise<DataReferencePickerOption[]> => {
			const upstreamNodes = getUpstreamNodes()

			const meetingNode = upstreamNodes.find(
				(n) =>
					n.data.integrationId ===
					WorkflowIntegrationIds.defaultDisplayScheduler,
			)

			const meetingBookedOwnerNodes: DataReferencePickerOption[] = []

			if (meetingNode) {
				const label = 'Meeting booked owner'
				const icon = Icons.default_logo

				const newRfn = {
					refNodeId: 'meeting_booked',
					variable: '$.member_id',
					value: null,
					fetchMappedUserIntegration: 'default',
					type: 'member',
					label,
					icon,
				}

				meetingBookedOwnerNodes.push({
					label,
					icon,
					value: newRfn,
					onSelect: (value) => {
						onSelect(value)
					},
				})
			}

			return [...meetingBookedOwnerNodes]
		},
		[getUpstreamNodes],
	)
}
