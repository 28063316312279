import { Center, CenterProps } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

export const Dot = (props: CenterProps) => {
	return (
		<Center
			w="2px"
			h="2px"
			rounded="full"
			bg={COLORS.background[6]}
			{...props}
		/>
	)
}
