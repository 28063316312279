import { Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import { SingleValue } from 'react-select'

import { useGetHubspotPropertiesQuery } from '@/app/integrations/api/hubspot'
import {
	useCurrentNodeData,
	useUpdateWorkflowNode,
} from '@/modules/workflow/hooks'
import { useGetHubspotRecordsDrpOptions } from '@/modules/workflow/hooks/drp-options/generators/useGetHubspotRecordsDrpOptions'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import { FieldRefNodeValue } from '@/modules/workflow/types/actions'
import {
	Hubspot_CreateEngagement,
	HubspotEngagements,
} from '@/modules/workflow/types/hubspot'

import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../../../panel-variants/PanelSelect'
import { DataReferencePicker } from '../../../DataReferencePicker'
import { FieldMapper } from '../../FieldMapper'

const emptyDefault: Hubspot_CreateEngagement = {
	type: {
		refNodeId: null,
		variable: null,
		value: null,
	},
	insertHubspotAssociations: null,
	insertHubspotProperties: {},
}

export const HubSpotCreateEngagement = () => {
	const updateNode = useUpdateWorkflowNode()
	const getRecordDrpOptions = useGetHubspotRecordsDrpOptions()

	const selectedNode = useSelectedNode()
	const stepDetails = selectedNode?.data.stepDetails as Hubspot_CreateEngagement

	const { getCurrentNodeData } = useCurrentNodeData(selectedNode)

	const { data: propertiesData, isLoading: isPropertiesDataLoading } =
		useGetHubspotPropertiesQuery(
			{
				type: stepDetails?.type?.value || '',
				filterOutReadOnlyProperties: true,
			},
			{
				skip: !stepDetails?.type?.value,
			},
		)
	const properties = Array.isArray(propertiesData) ? propertiesData : []

	const engagmementTypeOptions = Object.entries(HubspotEngagements).map(
		([label, value]) => ({
			label,
			value,
		}),
	)

	const updateNodeStepDetails = (newStepDetails: Hubspot_CreateEngagement) => {
		if (!selectedNode) return

		const currentNodeData = getCurrentNodeData()

		updateNode({
			...currentNodeData,
			data: {
				...currentNodeData.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const handleSelectEngagmentType = (
		entityTypeOp: SingleValue<{
			label: string
			value: HubspotEngagements
		}>,
	) => {
		if (!selectedNode || !entityTypeOp) return

		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Hubspot_CreateEngagement

		const newStepDetails = currentStepDetails || cloneDeep(emptyDefault)

		newStepDetails.type = {
			refNodeId: null,
			variable: null,
			value: entityTypeOp.value,
		}

		newStepDetails.insertHubspotProperties = {}

		updateNodeStepDetails(newStepDetails)
	}

	const handleAssociatedRecordSelect = (rfn: FieldRefNodeValue) => {
		if (!selectedNode || !rfn.refNodeId) return

		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Hubspot_CreateEngagement

		const newStepDetails = currentStepDetails || cloneDeep(emptyDefault)

		newStepDetails.insertHubspotAssociations = {
			objectId: {
				refNodeId: rfn.refNodeId,
				variable: rfn.variable,
				value: null,
				label: rfn.label,
				icon: rfn.icon,
			},
			objectType: {
				refNodeId: null,
				variable: null,
				value: rfn.dataType,
			},
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleClearAssociatedRecord = () => {
		if (!selectedNode) return

		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Hubspot_CreateEngagement

		const newStepDetails = currentStepDetails || cloneDeep(emptyDefault)

		newStepDetails.insertHubspotAssociations = null

		updateNodeStepDetails(newStepDetails)
	}

	const engagementTypeValue = engagmementTypeOptions.find(
		(op) => op.value === stepDetails?.type?.value,
	)

	const { objectId } = stepDetails?.insertHubspotAssociations || {}
	const selectedAssociatedRecord = objectId?.label
		? {
				label: objectId.label,
				value: objectId,
			}
		: null

	return (
		<Flex w="100%" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel label="Type" />
				<PanelSelect
					placeholder="Select an engagement type"
					value={engagementTypeValue || null}
					options={engagmementTypeOptions}
					onChange={handleSelectEngagmentType}
				/>
			</Flex>

			{stepDetails?.type && (
				<>
					<Flex direction="column">
						<PanelInputLabel label="Associated record (optional)" />
						<DataReferencePicker
							getInitialOptions={getRecordDrpOptions}
							initialOption={selectedAssociatedRecord}
							isClearable
							clearValue={handleClearAssociatedRecord}
							onSelect={handleAssociatedRecordSelect}
						/>
					</Flex>
					<FieldMapper
						integration="HubSpot"
						fields={properties}
						description={
							stepDetails.type.value === HubspotEngagements.Meeting
								? "'Meeting start date/time' should be mapped to 'Activity Date', as it is a required field."
								: undefined
						}
						isDataLoading={isPropertiesDataLoading}
					/>
				</>
			)}
		</Flex>
	)
}
