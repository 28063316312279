import { useCallback } from 'react'

import ClearbitLogo from '@/assets/logo/clearbit-logo.svg'

import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import {
	defaultAttributes,
	WorkflowIntegrationIds,
} from '../../../utils/mappings'
import { useGetUpstreamNodes } from '../../useGetUpstreamNodes'

export const useGetClearbitDrpOptions = () => {
	// const store = useStore()
	const { getUpstreamNodes } = useGetUpstreamNodes()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const upstreamNodes = getUpstreamNodes()
			const clearbitNode = upstreamNodes.find(
				(n) => n.data.integrationId === WorkflowIntegrationIds.clearbitEnrich,
			)

			if (!clearbitNode) return []

			const clearbitPersonOptions = Object.entries(defaultAttributes)
				.filter(([, v]) => v.type === 'person')
				.map(([, object]) => ({
					label: object.name,
					value: object.key,
					onSelect: (value: any) => {
						const newRfn = {
							refNodeId: clearbitNode.data.id,
							variable: `$.enrichment.${value}`,
							value: null,
							label: object.name,
							icon: ClearbitLogo,
						}
						onSelect(newRfn)
					},
				}))

			const clearbitCompanyOptions = Object.entries(defaultAttributes)
				.filter(([, v]) => v.type === 'company')
				.map(([, object]) => ({
					label: object.name,
					value: object.key,
					onSelect: (value: any) => {
						const newRfn = {
							refNodeId: clearbitNode.data.id,
							variable: `$.enrichment.${value}`,
							value: null,
							label: object.name,
							icon: ClearbitLogo,
						}
						onSelect(newRfn)
					},
				}))

			return [
				{
					label: 'Clearbit person attributes',
					icon: ClearbitLogo,
					value: clearbitPersonOptions,
				},
				{
					label: 'Clearbit company attributes',
					icon: ClearbitLogo,
					value: clearbitCompanyOptions,
				},
			]
		},
		[getUpstreamNodes],
	)
}
