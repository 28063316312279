import { Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { useEffect, useRef, useState } from 'react'
import AutosizeInput from 'react-input-autosize'
import { GetIcon, Icons } from 'ui'

import { TEXT_ENUM } from '@/styles/components/text'

type Props = {
	title: string
	edit?: boolean
	onBlur?: (title: string) => void
}

export const EditableTextInput = ({ edit, title, onBlur }: Props) => {
	const [titleValue, setTitleValue] = useState(title)

	useEffect(() => {
		if (titleValue !== title) {
			setTitleValue(title)
		}
	}, [title])

	const inputRef = useRef<HTMLInputElement | null>(null)

	const handleOnBlur = () => {
		if (onBlur) {
			onBlur(titleValue)
		}
	}

	if (edit) {
		return (
			<Flex gap={2} alignItems="center">
				<AutosizeInput
					// @ts-ignore
					ref={inputRef}
					value={titleValue}
					onChange={(e) => setTitleValue(e.target.value)}
					onBlur={handleOnBlur}
					inputStyle={{
						background: 'transparent',
						fontWeight: 600,
						fontSize: '18px',
						border: 'none',
						boxShadow: 'none',
						maxWidth: '300px',
						borderWidth: '0px',
					}}
				/>
				<GetIcon
					icon={Icons.edit_pen}
					cursor="pointer"
					color={COLORS.background[6]}
					fontSize="20px"
					onClick={() => inputRef.current?.focus()}
				/>
			</Flex>
		)
	}

	return <Text variant={TEXT_ENUM.semibold18}>{title}</Text>
}
