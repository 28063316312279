import { Flex } from '@chakra-ui/react'
import { SingleValue } from 'react-select'

import { useUpdateHubspotCreateTriggerConfig } from '../../hooks/triggers-config'
import { useSelectedWorkflow } from '../../hooks/workflow'
import { HubspotEntities } from '../../types/hubspot'
import { getHSRecordType } from '../../utils/getHSRecordType'
import { getHSSubscriptionType } from '../../utils/getHSSubscriptionType'
import { PanelInputLabel } from '../side-panel/panel-variants/PanelInputLabel'
import { PanelSelect } from '../side-panel/panel-variants/PanelSelect'

const OPTIONS = [
	{
		label: 'Contact',
		value: HubspotEntities.Contact,
	},
	{
		label: 'Company',
		value: HubspotEntities.Company,
	},
]

export const HubspotCreateTrigger = () => {
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
	const updateHubspotCreateTrigger = useUpdateHubspotCreateTriggerConfig()

	const handleRecordTypeChange = (
		options: SingleValue<{
			label: string
			value: HubspotEntities
		}>,
	) => {
		if (!selectedWorkflow) return

		updateHubspotCreateTrigger({
			subscriptionType: getHSSubscriptionType(options?.value),
		})
	}

	return (
		<Flex w="full" flexDir="column" gap={3}>
			<Flex w="full" flexDir="column">
				<PanelInputLabel label="Record type" fontWeight={500} />
				<PanelSelect
					placeholder="Select a record type"
					onChange={handleRecordTypeChange}
					options={OPTIONS}
					value={OPTIONS.find(
						(option) =>
							option.value ===
							getHSRecordType(
								selectedWorkflow?.meta?.trigger_config?.subscriptionType,
							),
					)}
				/>
			</Flex>
		</Flex>
	)
}
