import { useCallback } from 'react'

import { FormType } from '@/modules/forms/types'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { settleAllOptionGenerators } from '../../../utils/settleAllOptionGenerators'
import { useSelectedWorkflow } from '../../workflow'
import { useGetAiActionDrpOptions } from '../generators/useGetAiActionDrpOptions'
import { useGetAmplemarketDrpOptions } from '../generators/useGetAmplemarketDrpOptions'
import { useGetApolloDrpOptions } from '../generators/useGetApolloDrpOptions'
import { useGetClearbitDrpOptions } from '../generators/useGetClearbitDrpOptions'
import { useGetCompnayAttrDrpOptions } from '../generators/useGetCompanyAttrDrpOptions'
import { useGetFormQuestionDrpOptions } from '../generators/useGetFormQuestionDrpOptions'
import { useGetHarmonicDrpOptions } from '../generators/useGetHarmonicDrpOptions'
import { useGetHubspotDrpOptions } from '../generators/useGetHubspotDrpOptions'
import { useGetMeetingBookedDrpOptions } from '../generators/useGetMeetingBookedDrpOptions'
import { useGetPersonAttrDrpOptions } from '../generators/useGetPersonAttrDrpOptions'
import { useGetSalesforceDrpOptions } from '../generators/useGetSalesforceDrpOptions'
import { useGetWebhookNodeDrpOptions } from '../generators/useGetWebhookNodeDrpOptions'
import { useGetWorkflowAttrDrpOptions } from './useGetWorkflowAttrDrpOptions'

export const useGetDefaultDrpOptions = (): AsyncDrpOptionGenerator => {
	const { selectedWorkflow } = useSelectedWorkflow()
	const triggerType = selectedWorkflow?.type
	const isFormTrigger =
		triggerType === FormType.regular || triggerType === FormType.imported

	const getQuestionDrpOptions = useGetFormQuestionDrpOptions()
	const getWorkflowDrpOptions = useGetWorkflowAttrDrpOptions()
	const getPersonDrpOptions = useGetPersonAttrDrpOptions()
	const getCompanyDrpOptions = useGetCompnayAttrDrpOptions()
	const getSalesforceDrpOptions = useGetSalesforceDrpOptions()
	const getHubspotDrpOptions = useGetHubspotDrpOptions()
	const getApolloDrpOptions = useGetApolloDrpOptions()
	const getClearbitDrpOptions = useGetClearbitDrpOptions()
	const getHarmonicDrpOptions = useGetHarmonicDrpOptions()
	const getAmplemarketDrpOptions = useGetAmplemarketDrpOptions()
	const getMeetingBookedDrpOptions = useGetMeetingBookedDrpOptions()
	const getAiActionDrpOptions = useGetAiActionDrpOptions()
	const getWebhookNodeDrpOptions = useGetWebhookNodeDrpOptions()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const optionGenerators = [
				getQuestionDrpOptions,
				getWorkflowDrpOptions,
				...(isFormTrigger ? [getPersonDrpOptions, getCompanyDrpOptions] : []),
				getSalesforceDrpOptions,
				getHubspotDrpOptions,
				getClearbitDrpOptions,
				getApolloDrpOptions,
				getHarmonicDrpOptions,
				getAmplemarketDrpOptions,
				getMeetingBookedDrpOptions,
				getAiActionDrpOptions,
				getWebhookNodeDrpOptions,
			]

			return settleAllOptionGenerators(optionGenerators, onSelect)
		},
		[
			getQuestionDrpOptions,
			getWorkflowDrpOptions,
			getPersonDrpOptions,
			getCompanyDrpOptions,
			getSalesforceDrpOptions,
			getHubspotDrpOptions,
			getClearbitDrpOptions,
			getApolloDrpOptions,
			getHarmonicDrpOptions,
			getAmplemarketDrpOptions,
			getMeetingBookedDrpOptions,
			getAiActionDrpOptions,
			getWebhookNodeDrpOptions,
			isFormTrigger,
		],
	)
}
