export enum SHADOWS {
	small = 'small',
	medium = 'medium',
	elevated = 'elevated',
	focus = 'focus',
	depth0 = 'depth0',
	depth1 = 'depth1',
	success = '0px 4px 12px -2px rgba(122, 207, 164, 0.12), 0px 1px 2px rgba(122, 207, 164, 0.16)',
	error = '0px 4px 12px -2px rgba(217, 127, 121, 0.12), 0px 1px 2px rgba(217, 127, 121, 0.16)',
	warning = '0px 4px 12px -2px rgba(235, 150, 113, 0.12), 0px 1px 2px rgba(235, 150, 113, 0.16)',
	focused = '0px 0px 0px 2px #ACACF2',
	popover = '0px 16px 24px 0px rgba(34, 43, 72, 0.12)',
	button_secondary = '0px 4px 4px -2px rgba(24, 39, 75, 0.06), 0px 1px 4px -2px rgba(24, 39, 75, 0.02), 0px 0px 2px 0px #E0E0E0',
	workflows_side_panel_danger = '0px 3px 6px -2px rgba(241, 123, 143, 0.08), 0px 2px 4px -2px rgba(241, 123, 143, 0.12), 0px 0px 1px 0px rgba(241, 123, 143, 0.10)',
	// workflows_executed_step = '0px 0px 6px 2px',
	workflows_executed_step = '0px 0px 10px 2px',
	primary_button_v2 = '0px 0px 1px 0px rgba(35, 37, 41, 0.10), 0px 2px 4px -2px rgba(38, 109, 240, 0.12), 0px 3px 6px -2px rgba(38, 109, 240, 0.08)',
	primary_button_focus_v2 = '0px 0px 0px 4px rgba(99, 99, 242, 0.32), 0px 0px 0px 1px #FFF',
	secondary_button_v2 = '0px 0px 2px 0px #E0E0E0, 0px 1px 4px -2px rgba(24, 39, 75, 0.02), 0px 4px 4px -2px rgba(24, 39, 75, 0.06)',
	danger_button_v2 = `0px 0px 1px 0px rgba(241, 123, 143, 0.10), 0px 2px 4px -2px rgba(241, 123, 143, 0.12), 0px 3px 6px -2px rgba(241, 123, 143, 0.08)`,
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	secondary_button_focus_v2 = '0px 0px 0px 4px rgba(99, 99, 242, 0.32), 0px 0px 0px 1px #FFF',
	card = '0px 0px 1px 0px rgba(34, 43, 72, 0.16), 0px 1px 4px 0px rgba(34, 43, 72, 0.08)',
	floating_banner = '0px 0px 4px 0px rgba(34, 43, 72, 0.04), 0px 8px 16px 0px rgba(34, 43, 72, 0.08);',

	section = `0px 1px 2px 0px rgba(34, 43, 72, 0.04), 0px 2px 8px 0px rgba(34, 43, 72, 0.08);`,
}

export enum VIEW_HEIGHT {
	TOOLBAR_WITH_TABS = 'calc(100vh - 120px)',
	TOOLBAR_WITHOUT_TABS = 'calc(100vh - 80px)',
}

export const MAX_WIDTH = '1920px'
