import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { ApolloEmailAccountType, ApolloSequenceType } from './type'

export enum ApolloEndPoints {
	/** Query */
	getApollo = 'getApollo',
	updateApollo = 'updateApollo',
	getApolloEmailAccounts = 'getApolloEmailAccounts',
	getApolloSequences = 'getApolloSequences',
	/** Mutation */
	deleteApollo = 'deleteApollo',
	addLeadsToSequence = 'addLeadsToSequence',
}

const apolloApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[ApolloEndPoints.getApollo]: builder.query<
			{ api_key: string | null },
			void
		>({
			query: () => ({
				url: `/apollo/api-key`,
				responseHandler: responseTransformer,
			}),
		}),
		[ApolloEndPoints.getApolloEmailAccounts]: builder.query<
			ApolloEmailAccountType[],
			void
		>({
			query: () => ({
				url: `/apollo/email-accounts`,
				responseHandler: (res) =>
					res.json().then((data) => data?.emailAccounts),
			}),
		}),

		[ApolloEndPoints.getApolloSequences]: builder.query<
			ApolloSequenceType[],
			void
		>({
			query: () => ({
				url: `/apollo/sequences`,
			}),
		}),

		/** Mutations */
		[ApolloEndPoints.deleteApollo]: builder.mutation<
			{
				status: true
				error: any
			},
			void
		>({
			query: () => ({
				url: `/integrations?integration=apolloio`,
				method: 'DELETE',
			}),
			onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					apolloApi.util.updateQueryData(
						ApolloEndPoints.getApollo,
						undefined,
						() => {
							return {
								api_key: null,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),
		[ApolloEndPoints.updateApollo]: builder.mutation<void, string>({
			query: (payload) => ({
				url: `/apollo/api-key`,
				method: 'POST',
				body: { apiKey: payload },
			}),
		}),
		[ApolloEndPoints.addLeadsToSequence]: builder.mutation<
			void,
			{ sequenceId: string; senderEmail: string; emails: string[] }
		>({
			query: ({ emails, senderEmail, sequenceId }) => ({
				url: `/apollo/sequences/${sequenceId}/add`,
				method: 'POST',
				body: {
					emails,
					sender: senderEmail,
				},
			}),
		}),
	}),
})

export const {
	useDeleteApolloMutation,
	useUpdateApolloMutation,
	useGetApolloQuery,
	useGetApolloEmailAccountsQuery,
	useLazyGetApolloEmailAccountsQuery,
	useGetApolloSequencesQuery,
	useAddLeadsToSequenceMutation,
} = apolloApi

export { apolloApi }
