import { Flex, Text } from '@chakra-ui/react'

import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

import { LogsAnalyticsGraphBar } from '../components/LogsOverview/LogsAnalyticsGraphBar'
import { LogsAnalyticsSuccessError } from '../components/LogsOverview/LogsAnalyticsSuccessError'
import { buildGraphData } from '../presenters/buildGraphData'
import { getTotalSuccessErrorCount } from '../presenters/getTotalSuccessErrorCount'
import { WorkflowLogsAnalytics } from '../types/analytics'

type Props = {
	data?: WorkflowLogsAnalytics | null
}

export const LogsAnalyticsPartial = ({ data }: Props) => {
	const { success, error } = getTotalSuccessErrorCount(data) || {}
	const graphData = buildGraphData(data)

	const weekTotal = success + error

	return (
		<Flex
			flexDir="column"
			borderTop={Borders.primary}
			px={4}
			pb="60px"
			pt="24px"
		>
			<Text variant={TEXT_ENUM.semibold14}>Activity in the last 7 days</Text>
			<LogsAnalyticsSuccessError success={success} error={error} />

			<Text variant={TEXT_ENUM.semibold18} mt={10} ml={3}>
				{weekTotal || '-'}
			</Text>
			<Flex mt={1}>
				{graphData.map((data) => {
					return (
						<LogsAnalyticsGraphBar
							key={data.label}
							label={data.label}
							success={data.success}
							error={data.error}
							weekTotal={weekTotal}
						/>
					)
				})}
			</Flex>
		</Flex>
	)
}
