import { MemberType } from '@/app/user/api/members/type'

export const setUpIntercomForUser = (profile: MemberType | null) => {
	if (!profile) return

	window.Intercom('boot', {
		api_base: 'https://api-iam.intercom.io',
		app_id: 'xt872vdo',
		name: `${profile.first_name} ${profile.last_name}`,
		email: profile.email,
		user_id: profile.id,
	})
}
