import { Flex, HStack, Text } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { formatUTCDate } from 'shared-utils'
import { GetIcon, Icons } from 'ui'

import { Dot } from '@/common/components/Dot'
import { WorkflowIntegrationItem } from '@/modules/workflow/types/integrations'
import { WorkflowIntegrationsMap } from '@/modules/workflow/utils/mappings'
import { formatWorkflowStepType } from '@/utils/helpers/format-step-type'

import { getWorkflowLogStepErrors } from '../presenters/getWorkflowLogStepErrors'
import { WorkflowLogStep } from '../types'
import { WorkflowStepLog } from './WorkflowStepLog'
import { WorkflowStepProgress } from './WorkflowStepProgress'

type Props = {
	workflowStep?: WorkflowLogStep | null
}

export const WorkflowStep = ({ workflowStep }: Props) => {
	const workflowStepDetails = WorkflowIntegrationsMap[
		workflowStep?.integrationId || workflowStep?.workflowIntegrationId || ''
	] as WorkflowIntegrationItem

	const workflowStepTitle =
		workflowStep?.label || workflowStepDetails?.integrationName || '-'

	const formattedDate = formatUTCDate({
		date: workflowStep?.executionStartedAt,
		showTodayYeserday: true,
		showSeconds: true,
	})

	const stepErrors = getWorkflowLogStepErrors(workflowStep)
	const type = formatWorkflowStepType(workflowStepDetails?.stepType)

	if (!type) return null

	return (
		<Flex gap={4} h="fit-content">
			<WorkflowStepProgress
				logo={workflowStepDetails?.logo}
				isIcon={workflowStepDetails?.isIcon}
			/>
			<Flex w="full" minW={0} flexDir="column" gap={3} mt={1}>
				<HStack color={COLORS.background[6]} fontSize="12px">
					{stepErrors.length > 0 && (
						<HStack>
							<GetIcon
								icon={Icons.warning_circle}
								color={ColorTokens.text_error}
								boxSize={4}
							/>
							<Text color={ColorTokens.text_error}>
								{stepErrors.length} {stepErrors.length > 1 ? 'issues' : 'issue'}
							</Text>
							<Dot />
						</HStack>
					)}

					<Text>
						{type ? `${type}: ` : ''}
						{workflowStepTitle}
					</Text>
					<Dot />
					<Text>{formattedDate}</Text>
				</HStack>

				{workflowStep?._logs?.map((log) => (
					<WorkflowStepLog
						key={log.message + log.timestamp}
						workflowStepLog={log}
					/>
				))}
				<Flex h={6} />
			</Flex>
		</Flex>
	)
}
