import { Flex } from '@chakra-ui/react'

import { useAppSelector } from '@/app/hooks'
import { LoadingPage } from '@/components/Loading'
import { useGetAllFormsQuery } from '@/modules/forms/api'
import { MAX_WIDTH } from '@/styles/constants'

import { NoWorkflowDueToFilters } from '../components/NoWorkflowDueToFilters'
import { WorkflowsActionsPartial } from '../partials/WorkflowsActionsPartial'
import { WorkflowsPartial } from '../partials/WorkflowsPartial'
import { useFilteredWorkflows } from '../presenters/useFilteredWorkflows'
import { selectWorkflowsFilter } from '../slice/selectors'
import { WorkflowsEmptyPageFlow } from './WorkflowsEmptyPageFlow'

export const WorkflowsHomePage = () => {
	const { data, isLoading } = useGetAllFormsQuery()

	const filteredWorkflows = useFilteredWorkflows(data || [])
	const workflowFilters = useAppSelector(selectWorkflowsFilter)

	if (isLoading) return <LoadingPage />

	const noWorkflowsDueToFilters =
		filteredWorkflows.length === 0 && workflowFilters

	if (data?.length === 0) return <WorkflowsEmptyPageFlow />

	return (
		<Flex direction="column" margin="0 auto" maxW={MAX_WIDTH} w="full" h="full">
			<WorkflowsActionsPartial />
			{noWorkflowsDueToFilters ? (
				<NoWorkflowDueToFilters />
			) : (
				<WorkflowsPartial filteredWorkflows={filteredWorkflows} />
			)}
		</Flex>
	)
}
