import { Flex, Text } from '@chakra-ui/react'
import { GetIcon, Icons } from '@ds/Icons'
import { COLORS } from '@ds/tokens/colors'
import { useNavigate } from 'react-router-dom'

import { useIsWorkflowVersionViewer } from '@/common/hooks/feature-flags/useIsWorkflowVersionViewer'
import { IframePreviewTooltip } from '@/components/IframePreviewTooltip'
import { TEXT_ENUM } from '@/styles/components/text'
import { HIDE_SCROLLBAR_CLASS } from '@/utils/constants'

import { WorkflowStep } from '../components/WorkflowStep'
import { WorkflowStepFinished } from '../components/WorkflowStepFinished'
import { WorkflowLog } from '../types'

type Props = {
	workflowLog?: WorkflowLog | null
}

export const WorkflowLogDetailsPartial = ({ workflowLog }: Props) => {
	const isWorkflowVersionViewer = useIsWorkflowVersionViewer()
	const navigate = useNavigate()

	const workflowLogCanvasUrl =
		workflowLog && isWorkflowVersionViewer
			? `/workflows/${workflowLog.form_id}/versions/${workflowLog.form_version}?log=${workflowLog.id}`
			: ''

	const handleViewVersion = () => {
		if (!workflowLog || !isWorkflowVersionViewer) return
		navigate(workflowLogCanvasUrl)
	}

	return (
		<Flex
			w="full"
			h="full"
			flexDir="column"
			overflowY="scroll"
			className={HIDE_SCROLLBAR_CLASS}
			pb="100px"
		>
			<Flex alignItems="center" mb={5} gap={3}>
				<Text variant={TEXT_ENUM.semibold16} mb={1} ml={1}>
					Workflow log
				</Text>
				{isWorkflowVersionViewer && (
					<IframePreviewTooltip
						url={`${workflowLogCanvasUrl}&__preview=true`}
						tooltipHeight="250px"
						tooltipWidth="280px"
						iframeStyle={{ marginTop: '-32px' }}
					>
						<Flex
							alignItems="center"
							gap={1}
							cursor="pointer"
							onClick={handleViewVersion}
						>
							<Text
								variant={TEXT_ENUM.medium12}
								textDecoration="underline"
								color={COLORS.purple[9]}
							>
								Open in Workflow Canvas
							</Text>
							<GetIcon
								color={COLORS.purple[9]}
								icon={Icons.arrow_up_right}
								boxSize={2}
							/>
						</Flex>
					</IframePreviewTooltip>
				)}
			</Flex>
			{workflowLog?.steps?.map((step) => (
				<WorkflowStep key={step.executionStartedAt} workflowStep={step} />
			))}
			<WorkflowStepFinished workflowLog={workflowLog} />
		</Flex>
	)
}
