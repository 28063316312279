import {
	Center,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useOutsideClick,
} from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import React, { useRef } from 'react'
import { GetIcon, Icons } from 'ui'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import {
	useDeleteActionNode,
	useDeleteLogicNode,
} from '@/modules/workflow/hooks'
import { useDuplicateNode } from '@/modules/workflow/hooks/nodes'
import { useWorkflowReadOnly } from '@/modules/workflow/hooks/workflow'
import { workflowsActions } from '@/modules/workflow/slice'
import { selectCanvasNodeMenuOpenId } from '@/modules/workflow/slice/selectors'
import { StepType } from '@/modules/workflow/types/nodes'
import { WorkflowIntegrationsMap as Integrations } from '@/modules/workflow/utils/mappings'

type Props = {
	nodeId?: string
	integrationId?: string
	selected: boolean
}

export const CanvasNodeHeaderMenu = React.memo(
	({ nodeId, integrationId, selected }: Props) => {
		const deleteActionNode = useDeleteActionNode()
		const deleteLogicNode = useDeleteLogicNode()
		const duplicateNodeMutation = useDuplicateNode()
		const isReadOnly = useWorkflowReadOnly()

		const dispatch = useAppDispatch()
		const canvasNodeMenuId = useAppSelector(selectCanvasNodeMenuOpenId)

		const ref = useRef(null)

		useOutsideClick({
			ref,
			handler: () => {
				dispatch(workflowsActions.setCanvasNodeMenuOpenId(null))
			},
		})

		if (!nodeId || !integrationId) return null

		const integrationContent = Integrations[integrationId]

		const handleDeleteNode = () => {
			if (integrationContent?.stepType === StepType.Logic) {
				deleteLogicNode(nodeId)
			} else if (integrationContent?.stepType === StepType.Action) {
				deleteActionNode(nodeId)
			}
		}

		const duplicateNode = () => {
			duplicateNodeMutation(nodeId)
		}

		return (
			<Center
				ref={ref}
				visibility={selected && !isReadOnly ? 'visible' : 'hidden'}
			>
				<Menu
					isLazy
					isOpen={nodeId === canvasNodeMenuId}
					onOpen={() => {
						dispatch(workflowsActions.setCanvasNodeMenuOpenId(nodeId))
					}}
				>
					<MenuButton id={nodeId} borderRadius="8px">
						<Center>
							<GetIcon
								icon={Icons.dots_horizontal}
								fontSize="24px"
								color={COLORS.background[6]}
							/>
						</Center>
					</MenuButton>
					<MenuList>
						<MenuItem fontSize="14px" gap={2} onClick={duplicateNode}>
							<GetIcon icon={Icons.copy} boxSize={5} />
							Duplicate
						</MenuItem>
						<MenuItem
							onClick={handleDeleteNode}
							color={COLORS.red[9]}
							fontSize="14px"
							gap={2}
						>
							<GetIcon icon={Icons.trash} boxSize={5} color={COLORS.red[9]} />
							Delete
						</MenuItem>
					</MenuList>
				</Menu>
			</Center>
		)
	},
)

CanvasNodeHeaderMenu.displayName = 'CanvasNodeHeaderMenu'
