import { Flex, FlexProps, Menu, useOutsideClick } from '@chakra-ui/react'
import { ColorTokens } from '@ds/tokens/colors'
import { SHADOWS } from '@ds/tokens/shadows'
import { createRef, forwardRef, useImperativeHandle, useState } from 'react'

import { useAppSelector } from '@/app/hooks'
import { selectIsRichTextEditorBackspacing } from '@/app/user/slice/selectors'

import { Submenu } from './Submenu'

export interface MentionListItem {
	label: string
	value: string
	type: string
}

const MentionList = forwardRef(
	(
		props: {
			items: MentionListItem[]
			command: any
			useDrp: boolean
		},
		ref,
	) => {
		const [selectedIndex, setSelectedIndex] = useState(0)
		const [subIndex, setSubIndex] = useState(-1)
		const [subNavigation, setSubNavigation] = useState(false)
		const containerRef = createRef<HTMLDivElement>()
		const itemGroupNames: { [itemGroup: string]: string } = {
			response: 'Form Questions',
			person_attribute: 'Person Attributes',
			company_attribute: 'Company Attributes',
			guest_fields: 'Guest Fields',
			host_fields: 'Host Fields',
			meeting_fields: 'Meeting Fields',
		}

		const itemGroups: {
			[itemGroup: string]: MentionListItem[]
		} = {}
		props.items.forEach((item) => {
			if (itemGroups[item.type]) {
				itemGroups[item.type].push(item)
				return
			}
			itemGroups[item.type] = [item]
		})

		const selectItem = (item: MentionListItem | undefined) => {
			if (item) {
				return props.command({ id: item.value })
			}
		}

		const isRichTextEditorBackspacing = useAppSelector(
			selectIsRichTextEditorBackspacing,
		)

		const upHandler = () => {
			if (subNavigation) {
				const last = Object.entries(itemGroups)[selectedIndex][1].length - 1
				if (subIndex === 0) {
					setSubIndex(last)
				} else {
					setSubIndex(subIndex - 1)
				}
			} else {
				const last = Object.entries(itemGroups)[selectedIndex].length
				if (selectedIndex === 0) {
					setSelectedIndex(last)
				} else {
					setSelectedIndex(
						(selectedIndex + props.items.length - 1) % props.items.length,
					)
				}
			}
		}

		const downHandler = () => {
			if (subNavigation) {
				const last = Object.entries(itemGroups)[selectedIndex][1].length - 1
				if (subIndex === last) {
					setSubIndex(0)
				} else {
					setSubIndex(subIndex + 1)
				}
			} else {
				const last = Object.entries(itemGroups)[selectedIndex].length
				if (selectedIndex === last) {
					setSelectedIndex(0)
				} else {
					setSelectedIndex(
						(selectedIndex + props.items.length + 1) % props.items.length,
					)
				}
			}
		}

		const rightHandler = () => {
			setSubNavigation(true)
			setSubIndex(0)
		}

		const leftHandler = () => {
			setSubNavigation(false)
			setSubIndex(-1)
		}

		const enterHandler = () => {
			if (Object.entries(itemGroups)[selectedIndex][1][subIndex]) {
				selectItem(Object.entries(itemGroups)[selectedIndex][1][subIndex])
				setSelectedIndex(-1)
				setSubNavigation(false)
				setSubIndex(-1)
			}
		}

		const blockScroll = () => {
			document.body.style.overflow = 'hidden'
		}

		const releaseScroll = () => {
			document.body.style.overflow = 'auto'
		}

		useImperativeHandle(ref, () => ({
			onKeyDown: ({ event }: { event: any }) => {
				if (event.key === 'ArrowUp') {
					upHandler()
					return true
				}

				if (event.key === 'ArrowDown') {
					downHandler()
					return true
				}

				if (event.key === 'ArrowRight') {
					rightHandler()
					return true
				}

				if (event.key === 'ArrowLeft') {
					leftHandler()
					return true
				}

				if (event.key === 'Enter') {
					enterHandler()
					return true
				}

				return false
			},
		}))

		useOutsideClick({
			ref: containerRef,
			handler: () => {
				setSelectedIndex(-1)
				setSubNavigation(false)
				setSubIndex(-1)
			},
		})

		const flexProps: FlexProps = {
			flexDir: 'column',
			bg: 'white',
			color: ColorTokens.text,
			p: 1,
			borderColor: ColorTokens.border_primary,
			borderRadius: 'md',
			boxShadow: SHADOWS.depth1,
		}

		return (
			<Flex {...(!props.useDrp ? flexProps : {})}>
				{isRichTextEditorBackspacing
					? null
					: props.useDrp
						? selectItem({
								label: 'Pick a value',
								value: JSON.stringify({
									refNodeId: null,
									variable: null,
									value: '',
									_id: Math.random().toString(),
								}),
								type: 'empty',
							})
						: null}
				{!props.useDrp && (
					<Menu
						autoSelect
						onOpen={blockScroll}
						onClose={() => {
							releaseScroll()
							setSelectedIndex(-1)
							setSubNavigation(false)
							setSubIndex(-1)
						}}
						closeOnSelect
					>
						{Object.entries(itemGroups).map((itemGroup, index) => (
							<Submenu
								key={index}
								index={index}
								selectedIndex={selectedIndex}
								setSelectedIndex={setSelectedIndex}
								subIndex={subIndex}
								title={itemGroupNames[itemGroup[0]]}
								options={itemGroup[1]}
								onClick={(value: any) =>
									selectItem(props.items.find((item) => item.value === value))
								}
							/>
						))}
					</Menu>
				)}
			</Flex>
		)
	},
)

MentionList.displayName = 'MentionList'

export default MentionList
