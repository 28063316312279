import { AnimatePresence } from 'framer-motion'

import { MotionFlex } from '../MotionFlex'
import { useExpandableContext } from './ExpandableContext'
import { useExpandableGroupContext } from './ExpandableGroupContext'

type Props = {
	children: React.ReactNode
}

export const ExpandableContent = ({ children }: Props) => {
	const { isExpanded, expandable, expandableId } = useExpandableContext()
	const { activeExpandableId } = useExpandableGroupContext()

	const shouldBeExpanded =
		expandableId !== undefined
			? activeExpandableId === expandableId
			: isExpanded

	return (
		<AnimatePresence>
			{expandable && shouldBeExpanded && (
				<MotionFlex
					width="100%"
					initial={{ height: 0 }}
					animate={{
						height: shouldBeExpanded ? 'auto' : 0,
					}}
					exit={{
						height: 0,
					}}
					transition={{
						duration: '0.25',
						ease: 'easeOut',
					}}
				>
					<MotionFlex
						width="100%"
						initial={{ opacity: 0 }}
						animate={{
							opacity: shouldBeExpanded ? 1 : 0,
						}}
						exit={{
							opacity: 0,
						}}
						transition={{
							duration: shouldBeExpanded ? '0.175' : '0.25',
							delay: shouldBeExpanded ? 0 : 0.05,
							ease: 'easeInOut',
						}}
					>
						{children}
					</MotionFlex>
				</MotionFlex>
			)}
		</AnimatePresence>
	)
}
