import { useParams, useSearchParams } from 'react-router-dom'

import { useAppSelector } from '@/app/hooks'
import { useIsWorkflowTestRuns } from '@/common/hooks/feature-flags/useIsWorkflowTestRuns'
import { usePreviewMode } from '@/common/hooks/usePreviewMode'
import {
	useGetAllFormVersionsByIdQuery,
	useGetFormByIdQuery,
} from '@/modules/forms/api'
import { FormTable, FormType } from '@/modules/forms/types'
import { workflowNodesToCanvasNodes } from '@/modules/workflow/utils/graph-operations'
import { useGetWorkflowLogQuery } from '@/modules/workflow-logs/api'

import { WEBHOOK_CONNECTION_URL } from '../constants/webhook'
import {
	selectDroppableBranchId,
	selectSelectedCanvasNodeId,
	selectSelectedWorkflowId,
} from '../slice/selectors'

export const useSelectedWorkflow = () => {
	const workflowId = useAppSelector(selectSelectedWorkflowId)
	const {
		workflowId: workflowIdFromParams,
		workflowVersion: workflowVersionFromParams,
	} = useParams()
	const isTestWorkflow = workflowVersionFromParams === '-1'

	const { data: allVersions } = useGetAllFormVersionsByIdQuery(
		workflowIdFromParams,
		{
			skip: !workflowIdFromParams || !workflowVersionFromParams,
		},
	)

	const form = useGetFormByIdQuery(
		isTestWorkflow ? workflowIdFromParams : workflowId?.toString(),
		{
			skip:
				(isTestWorkflow && !workflowIdFromParams) ||
				(!isTestWorkflow && !workflowId),
			selectFromResult: (result) => {
				return {
					selectedWorkflow: result.data,
				}
			},
		},
	)

	if (workflowIdFromParams && workflowVersionFromParams && !isTestWorkflow) {
		const version = allVersions?.find(
			(version) =>
				version.form_version.toString() === workflowVersionFromParams,
		)

		return {
			selectedWorkflow: {
				...version,
				// Overwriting id to match FormTable
				id: version?.form_id,
				// Casting as FormTable as it is effectively the same
			} as FormTable,
		}
	}

	return form
}

export const useCanvasNodes = () => {
	const workflowId = useAppSelector(selectSelectedWorkflowId)
	const {
		workflowId: workflowIdFromParams,
		workflowVersion: workflowVersionFromParams,
	} = useParams()
	const isTestWorkflow = workflowVersionFromParams === '-1'

	const { data: allVersions } =
		useGetAllFormVersionsByIdQuery(workflowIdFromParams)

	const form = useGetFormByIdQuery(
		isTestWorkflow ? workflowIdFromParams : workflowId?.toString(),
		{
			skip:
				(isTestWorkflow && !workflowIdFromParams) ||
				(!isTestWorkflow && !workflowId),
			selectFromResult: (result) => {
				return {
					canvasNodes: workflowNodesToCanvasNodes(result.data?.workflow),
				}
			},
		},
	)

	if (workflowIdFromParams && workflowVersionFromParams && !isTestWorkflow) {
		const version = allVersions?.find(
			(version) =>
				version.form_version.toString() === workflowVersionFromParams,
		)

		// This part varies
		return { canvasNodes: workflowNodesToCanvasNodes(version?.workflow) }
	}

	return form
}

export const useSelectedNode = () => {
	const selectedNodeId = useAppSelector(selectSelectedCanvasNodeId)
	const canvasNodes = useCanvasNodes()?.canvasNodes

	if (!selectedNodeId) return null
	return canvasNodes[selectedNodeId]
}

export const useDroppableBranch = () => {
	const droppableBranchId = useAppSelector(selectDroppableBranchId)
	return droppableBranchId
}

export const useWebhookUrl = ({ test }: { test: boolean }) => {
	const selectedWorkflowId = useSelectedWorkflow()?.selectedWorkflow?.id || ''
	return `${WEBHOOK_CONNECTION_URL.replace(
		':workflowId',
		String(selectedWorkflowId),
	)}${test ? '?mode=test' : ''}`
}

export const useWorkflowReadOnly = () => {
	const { workflowVersion } = useParams()
	const isPreview = usePreviewMode()

	// The workflow is read-only if it's a preview or if we're viewing a specific version
	const isReadOnly = isPreview || !!workflowVersion

	return isReadOnly
}

export const useCanvasWorkflowLog = () => {
	const [searchParams] = useSearchParams()
	const submissionId = searchParams.get('log')

	const {
		data: workflowLog,
		isLoading: logLoading,
		error: logError,
	} = useGetWorkflowLogQuery(submissionId || undefined, { skip: !submissionId })

	return { workflowLog, logLoading, logError }
}

export const useTestWorkflowAvailable = () => {
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
	const isReadOnly = useWorkflowReadOnly()
	const isWorkflowTestRunsEnabled = useIsWorkflowTestRuns()

	return (
		!isReadOnly &&
		isWorkflowTestRunsEnabled &&
		(selectedWorkflow?.type === FormType.regular ||
			selectedWorkflow?.type === FormType.imported)
	)
}
