import { createSlice } from '@reduxjs/toolkit'

interface MeetingDetails {}

const initialState: MeetingDetails = {}

export const meetingDetailsSlice = createSlice({
	name: 'meetings',
	initialState,
	reducers: {},
})

export const meetingDetailsReducer = meetingDetailsSlice.reducer
export const meetingDetailsActions = meetingDetailsSlice.actions
