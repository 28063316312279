import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RemindersSortByOptions } from '../components/actions/SortBy'
import { REMINDERS_VIEW_PREFERENCES_KEY } from '../constants/view'
import { ReminderType } from '../types'
import { ViewTypes } from '../types/ViewTypes'

const localViewPreferences = localStorage.getItem(
	REMINDERS_VIEW_PREFERENCES_KEY,
) as ViewTypes

interface RemindersState {
	remindersViewPreferences: ViewTypes | null
	sortBy: RemindersSortByOptions | null
	draftReminder: ReminderType | null
}

export const initialState: RemindersState = {
	remindersViewPreferences: localViewPreferences || ViewTypes.list,
	sortBy: null,
	draftReminder: null,
}

export const remindersSlice = createSlice({
	name: 'workflows-home',
	initialState,
	reducers: {
		setDraftReminder: (state, action: PayloadAction<ReminderType | null>) => {
			state.draftReminder = action.payload
		},
		setViewPreferences: (state, action: PayloadAction<ViewTypes>) => {
			state.remindersViewPreferences = action.payload
			localStorage.setItem(REMINDERS_VIEW_PREFERENCES_KEY, action.payload)
		},
		setSortBy: (
			state,
			action: PayloadAction<RemindersSortByOptions | null>,
		) => {
			state.sortBy = action.payload
		},
	},
})

export const remindersActions = remindersSlice.actions
export const remindersReducer = remindersSlice.reducer
