import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { WorkflowsFilters } from '../components/WorkflowsFilterBy'
import { WorkflowsSortByOptions } from '../components/WorkflowsSortBy'
import { WORKFLOWS_VIEW_PREFERENCES_KEY } from '../constants/workflows-display'
import { WorkflowsViewTypes } from '../types/WorkflowsViewTypes'

const localWorkflowsViewPreferences = localStorage.getItem(
	WORKFLOWS_VIEW_PREFERENCES_KEY,
) as WorkflowsViewTypes

export interface WorkflowsHomeState {
	workflowsViewPreferences: WorkflowsViewTypes | null
	workflowsFilter: WorkflowsFilters | null
	workflowsSortBy: WorkflowsSortByOptions | null
	showEmptyStateModal: boolean
}

export const initialState: WorkflowsHomeState = {
	workflowsViewPreferences:
		localWorkflowsViewPreferences || WorkflowsViewTypes.list,
	workflowsFilter: null,
	workflowsSortBy: null,
	showEmptyStateModal: false,
}

export const workflowsHomeSlice = createSlice({
	name: 'workflows-home',
	initialState,
	reducers: {
		setWorkflowsViewPreferences: (
			state,
			action: PayloadAction<WorkflowsViewTypes>,
		) => {
			state.workflowsViewPreferences = action.payload
			localStorage.setItem(WORKFLOWS_VIEW_PREFERENCES_KEY, action.payload)
		},
		setWorkflowsFilter: (
			state,
			action: PayloadAction<WorkflowsFilters | null>,
		) => {
			state.workflowsFilter = action.payload
		},
		setWorkflowsSortBy: (
			state,
			action: PayloadAction<WorkflowsSortByOptions | null>,
		) => {
			state.workflowsSortBy = action.payload
		},
		setShowEmptyStateModal: (state, action: PayloadAction<boolean>) => {
			state.showEmptyStateModal = action.payload
		},
	},
})

export const workflowsHomeActions = workflowsHomeSlice.actions
export const workflowsHomeReducer = workflowsHomeSlice.reducer
