import { CLEARBIT_COUNTRIES } from '../constants/clearbit-countries'

export const getClearbitCountryOptions = (): {
	label: string
	value: string
}[] => {
	return CLEARBIT_COUNTRIES.map((country: string) => ({
		label: country,
		value: country,
	}))
}
