import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'

import { FieldRefNodeValue } from '@/modules/workflow/types/actions'
import {
	MatchingCondition,
	MatchingCriterion,
	PriorityCriterion,
} from '@/modules/workflow/types/matching'
import { Salesforce_MatchRecord } from '@/modules/workflow/types/salesforce'

import { GenericDataTypes } from '../types/generic-data-types'
import { ConditionBranch } from '../types/logic'
import { GenericToCrmDataTypesMap } from './mappings/generic-to-crm-data-types'

const removeParentheses = (s: string) => s.replace('(', '').replace(')', '')

export const parseLogicString = (s: string | null): number[][] => {
	if (!s) return []
	return s
		.split(' OR ')
		.map((w) => removeParentheses(w))
		.map((block) => block.split(' AND '))
		.map((a) => a.map((index) => Number(index)))
}

export const removeIndex = (
	indexes: number[][],
	i: number,
	j: number,
): number[][] => {
	const updated = [...indexes]
	// removing the index
	updated[i].splice(j, 1)

	// decreasing indexes inside the same parentheses block
	updated[i].forEach((subindex, order) => {
		if (order >= j) {
			updated[i][order] = subindex - 1
		}
	})

	// decreasing indexes in the next parentheses blocks
	updated.forEach((_, order) => {
		if (order > i) {
			updated[order].forEach((subindex, suborder) => {
				updated[order][suborder] = subindex - 1
			})
		}
	})

	if (!updated[i].length) {
		updated.splice(i, 1)
	}

	return updated
}

export const createLogicString = (indexes: number[][]) => {
	return indexes
		.map<string>((ids) => {
			const andBlock = ids.map((n) => String(n)).join(' AND ')
			if (ids.length > 1) {
				return '(' + andBlock + ')'
			}
			return andBlock
		})
		.join(' OR ')
}

export const addOrCondition = (s: string | null, i: number) => {
	if (!s) return `${i + 1}`
	// adding the new index without parentheses
	return `${s} OR ${i}`
}

export const DefaultRefNodeValue: FieldRefNodeValue = {
	refNodeId: null,
	variable: null,
	value: null,
	label: null,
	dataType: null,
	i: 0,
}

export const DefaultLogicString = ''

export const DefaultSFDCcondition: MatchingCondition = {
	field: DefaultRefNodeValue,
	operator: null,
	value: DefaultRefNodeValue,
}

export const Default_SFDC_MatchRecord: Salesforce_MatchRecord = {
	type: {
		refNodeId: null,
		variable: null,
		value: null,
	},
	resultChildId: null,
	elseChildId: null,
	matchCriteria: {
		conditions: [],
		logic: DefaultLogicString,
	},
	priorityCriteria: [],
}

export const Default_SFDC_AND_Block = [
	[{ condition: DefaultSFDCcondition, real: 0 }],
]

export const Operators: {
	[id: string]: {
		label: string
		priority?: boolean
		salesforceFormula?: boolean
	}
} = {
	'=': { label: 'equal to' },
	'!=': { label: 'not equal to' },
	'<': { label: 'less than' },
	'>': { label: 'greater than' },
	'<=': { label: 'less than or equal to' },
	'>=': { label: 'greater than or equal to' },
	LIKE: { label: 'contains' },
	IN: { label: 'is any of' },
	'NOT IN': { label: 'is not any of' },
	'!= NULL': { label: 'is defined' },
	'= NULL': { label: 'is not defined' },
	DESC: { label: 'MAX', priority: true },
	ASC: { label: 'MIN', priority: true },
	ISCHANGED: { label: 'is changed', salesforceFormula: true },
}

export const cloneStepDetails = (
	stepDetails: Salesforce_MatchRecord | null,
) => {
	return cloneDeep({
		...(stepDetails || Default_SFDC_MatchRecord),
	} as Salesforce_MatchRecord)
}

export const hasMatchCriteria = (stepDetails: Salesforce_MatchRecord) => {
	if (
		!stepDetails ||
		!stepDetails?.matchCriteria ||
		!stepDetails?.matchCriteria?.conditions ||
		!stepDetails?.matchCriteria?.conditions?.length
	)
		return false
	const field = !!stepDetails.matchCriteria.conditions[0]?.field?.value
	const operator = !!stepDetails.matchCriteria.conditions[0]?.operator
	const value =
		!!stepDetails.matchCriteria.conditions[0]?.value?.value ||
		!!stepDetails.matchCriteria.conditions[0]?.value?.refNodeId
	return field && operator && value
}

export const isConditionValid = (condition: MatchingCondition) => {
	return (
		!!condition?.field?.value &&
		!!condition?.operator &&
		(!!condition?.value?.value || !!condition?.value?.variable)
	)
}

export const checkIsOrderBy = (v: string | undefined) => {
	if (typeof v === 'undefined') return undefined
	if (v === 'DESC' || v === 'ASC') return true
	return false
}

export const matchCriteriaToBranches = (
	matchCriteria: MatchingCriterion,
): { branches: ConditionBranch[] } => {
	const indexes = parseLogicString(matchCriteria.logic)
	const conditionalObject = {
		branches: [
			{
				id: nanoid(4),
				name: 'branch',
				conditionBlocks: indexes.map((ids) => {
					return {
						id: nanoid(4),
						conditions: ids.map((i) => {
							const condition = matchCriteria.conditions[i]
							return {
								id: nanoid(4),
								parameter: condition.field.value
									? {
											refNodeId: null,
											variable: null,
											label: condition.field.label,
											value: condition.field.value,
											dataType: condition.field.dataType,
										}
									: null,
								comparator: condition.operator,
								value: condition.value,
							}
						}),
					}
				}),
				resultChildId: null,
				elseChildId: null,
			},
		],
	}

	return conditionalObject
}

export const branchesToMatchCriteria = (
	branches: ConditionBranch[],
): MatchingCriterion => {
	const conditions = branches[0].conditionBlocks
		.map(({ conditions }) => conditions)
		.flat()
		.map((condition) => {
			const parameter = condition.parameter as FieldRefNodeValue
			const comparator = condition.comparator
			const value = condition.value

			const matchingCondition: MatchingCondition = {
				field: {
					...(parameter
						? parameter
						: {
								value: null,
								label: null,
								dataType: null,
							}),
					refNodeId: null,
					variable: null,
					i: 0,
				},
				operator: comparator || null,
				value: {
					...(value || {
						refNodeId: null,
						variable: null,
						value: null,
					}),
				},
			}

			return matchingCondition
		})

	let i = 0
	const indexes = branches[0].conditionBlocks.map(({ conditions }) =>
		conditions.map(() => i++),
	)

	const logic = createLogicString(indexes)

	return { conditions, logic }
}

export const priorityCriteriaToBranches = (
	priorityCriteria: PriorityCriterion[],
): { branches: ConditionBranch[] } => {
	const conditionalObject = {
		branches: [
			{
				id: nanoid(4),
				name: 'branch',
				conditionBlocks: priorityCriteria.map((priorityCondition) => {
					const { orderBy, conditions } = priorityCondition
					const convertedConditionalObject = matchCriteriaToBranches({
						conditions: orderBy.length ? orderBy : conditions,
						logic: priorityCondition.logic,
					})
					return {
						id: nanoid(4),
						conditions:
							convertedConditionalObject.branches[0].conditionBlocks[0]
								.conditions,
					}
				}),
				resultChildId: null,
				elseChildId: null,
			},
		],
	}

	return conditionalObject
}

export const branchesToPriorityCriteria = (
	branches: ConditionBranch[],
): PriorityCriterion[] => {
	return branches[0].conditionBlocks.map(({ conditions }) => {
		const matchCriteria = branchesToMatchCriteria([
			{
				id: nanoid(4),
				name: 'branch',
				resultChildId: null,
				elseChildId: null,
				conditionBlocks: [{ id: nanoid(4), conditions }],
			},
		])

		const orderByCondition = matchCriteria.conditions.find(
			(condition) =>
				condition.operator === 'DESC' || condition.operator === 'ASC',
		)

		return {
			conditions: orderByCondition?.operator ? [] : matchCriteria.conditions,
			logic: matchCriteria.logic,
			orderBy: orderByCondition?.operator
				? [
						{
							...orderByCondition,
							order: orderByCondition.operator,
						},
					]
				: [],
		}
	})
}

export const branchesToSalesforceFormula = (
	branches: ConditionBranch[],
): string | null => {
	// Example Formula: AND(OR({!$Record.FirstName} = 'John', {!$Record.FirstName} = 'Jane'), {!$Record.Status} = 'Active')
	const conditionBlocks = branches[0].conditionBlocks.map(({ conditions }) => {
		return conditions.map((condition) => {
			const parameterRefNode = condition.parameter as FieldRefNodeValue | null
			const parameter = parameterRefNode?.value
			const parameterDataType = parameterRefNode?.dataType
			const comparator = condition.comparator
			const value = condition.value?.value

			// Only add quotes if value is a string.
			const valueString = value
				? GenericToCrmDataTypesMap[GenericDataTypes.String].includes(
						parameterDataType || '',
					)
					? `'${value}'`
					: value
				: ''

			if (comparator === 'LIKE') {
				return `CONTAINS({!$Record.${parameter || 'Id'}}, ${valueString})`
			} else if (comparator === 'ISCHANGED') {
				return `ISCHANGED({!$Record.${parameter || 'Id'}})`
			} else if (parameterDataType === 'picklist') {
				return `ISPICKVAL({!$Record.${parameter || 'Id'}}, ${valueString})`
			}

			return `{!$Record.${parameter || 'Id'}} ${
				comparator || '='
			} ${valueString}`
		})
	})

	if (!conditionBlocks.length) return null

	const conditionStrings = conditionBlocks.map((conditions) => {
		return `AND(${conditions.join(', ')})`
	})
	const salesforceFormula = `OR(${conditionStrings.join(', ')})`
	return salesforceFormula
}
