import { StepType } from '@/modules/workflow/types/nodes'

export const formatWorkflowStepType = (type?: string | null) => {
	if (!type) return ''
	switch (type) {
		case StepType.Action:
			return 'Action'
		case StepType.Logic:
			return 'Logic'
		case StepType.Empty:
			return 'Empty'
		case StepType.WorkflowStart:
			return 'Workflow Start'
	}
}
