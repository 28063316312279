import { WorkflowLogsAnalytics } from '../types/analytics'

export const getTotalSuccessErrorCount = (
	data?: WorkflowLogsAnalytics | null,
): { success: number; error: number } => {
	try {
		if (!data?.analytics) return { success: 0, error: 0 }

		return Object.values(data?.analytics || {}).reduce(
			(acc, curr) => {
				return {
					success: acc.success + curr.successCount,
					error: acc.error + curr.errorCount,
				}
			},
			{ success: 0, error: 0 },
		)
	} catch (err) {
		console.error(err)
		return { success: 0, error: 0 }
	}
}
