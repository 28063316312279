import { Box, Tooltip } from '@chakra-ui/react'
import { GetIcon, Icons } from '@ds/Icons'
import { COLORS } from '@ds/tokens/colors'
import React from 'react'

import { useIsDisconnectedIntegrationsAllowed } from '@/common/hooks/feature-flags/useIsDisconnectedIntegrationsAllowed'
import { useGetIntegrationConnection } from '@/modules/workflow/hooks/useGetIntegrationConnection'
import { WorkflowNode } from '@/modules/workflow/types/nodes'
import { Borders } from '@/styles/borders'
import { TOOLTIP_ENUM } from '@/styles/components/tooltip'

export const CanvasNodeWarningBadge = React.memo(
	({ data }: { data: WorkflowNode }) => {
		const hasParent = data.parentIds.length > 0

		const { getIntegrationConnection } = useGetIntegrationConnection()
		const integrationConnection = getIntegrationConnection(data.integrationId)

		const isDisconnectedIntegrationsAllowed =
			useIsDisconnectedIntegrationsAllowed()
		const isIntegrationConnected =
			integrationConnection !== null
				? integrationConnection.isLoading || integrationConnection.isConnected
				: true
		const shouldShowIntegrationDisconnected =
			!isIntegrationConnected && !isDisconnectedIntegrationsAllowed

		let tooltipText: string
		let icon: Icons
		if (!hasParent) {
			tooltipText = 'This node is not connected to the workflow'
			icon = Icons.link_off
		} else if (shouldShowIntegrationDisconnected) {
			tooltipText = "This node's integration is not connected"
			icon = Icons.restricted
		} else {
			return null
		}

		return (
			<Tooltip
				variant={TOOLTIP_ENUM.workflows_side_panel}
				label={tooltipText}
				placement="top"
			>
				<Box
					position="absolute"
					top={-4}
					right="-29px"
					transform="translateX(-50%)"
					border={Borders.primary}
					borderColor={COLORS.orange[4]}
					bg={COLORS.orange[2]}
					color={COLORS.orange[9]}
					borderRadius="50%"
					pt={1}
					px={1.5}
				>
					<GetIcon icon={icon} boxSize={4} />
				</Box>
			</Tooltip>
		)
	},
)

CanvasNodeWarningBadge.displayName = 'CanvasNodeWarningBadge'
