import { Flex, Spinner, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { Pagination } from '@/common/components/Pagination'
import { Borders } from '@/styles/borders'

import { LOGS_COUNT_PER_PAGE } from '../../constant'
import { workflowLogsActions } from '../../slice'
import { selectCurrentPage } from '../../slice/selectors'

type Props = {
	count?: number | null
	isFetching?: boolean
	isLoading?: boolean
	isError?: boolean
}

export const LogsTableFooter = ({
	count,
	isFetching,
	isError,
	isLoading,
}: Props) => {
	const totalPages = Math.ceil((count || 0) / LOGS_COUNT_PER_PAGE)
	const currentPage = useAppSelector(selectCurrentPage)
	const dispatch = useAppDispatch()
	const isNextPageDisabled = isFetching || currentPage >= totalPages
	const isPreviousPageDisabled = isFetching || currentPage <= 1

	const handleNextPage = () => {
		if (isNextPageDisabled) return
		dispatch(workflowLogsActions.setCurrentPage(currentPage + 1))
	}

	const handlePreviousPage = () => {
		if (isPreviousPageDisabled) return
		dispatch(workflowLogsActions.setCurrentPage(currentPage - 1))
	}

	const handleOnPageChange = (page: string) => {
		dispatch(workflowLogsActions.setCurrentPage(Number(page)))
	}

	const hideFooter =
		isLoading || (isError && currentPage === 1) || totalPages <= 1
	if (hideFooter) return null

	return (
		<Flex
			px={4}
			h={12}
			flexShrink={0}
			borderTop={Borders.primary}
			bg={COLORS.background[12]}
			alignItems="center"
			gap={4}
		>
			<Pagination
				onNextPage={handleNextPage}
				onPreviousPage={handlePreviousPage}
				totalPages={totalPages}
				currentPage={currentPage}
				onPageChange={handleOnPageChange}
				defaultPage={1}
				max={totalPages || 1}
			/>
			<Text fontSize="12px">{`Total ${count} workflow logs`}</Text>
			{isFetching && <Spinner color={COLORS.background[5]} size="sm" />}
		</Flex>
	)
}
