export const WEEKDAYS = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
]

export const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

export const compareOnlyDates = (date1: Date, date2: Date) => {
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	)
}

export const dateToFormattedString = (dateTime: Date) => {
	return `${dateTime.getFullYear()}-${('0' + (dateTime.getMonth() + 1)).slice(
		-2,
	)}-${('0' + dateTime.getDate()).slice(-2)}`
}

export const addTwoWeeks = (date: Date) => {
	const newDate = new Date(date.getTime() + 14 * 24 * 60 * 60 * 1000)
	return newDate
}

/**
 * This compares the date based on user local timezone
 * new Date() will return the date based on user local timezone
 */
export const isDateInPast = (date: Date) => {
	return date < new Date()
}

const getTimezoneAbbreviation = (date: Date, timezone: string) => {
	try {
		const formatter = new Intl.DateTimeFormat('en-US', {
			timeZone: timezone,
			timeZoneName: 'short',
		})
		const parts = formatter.formatToParts(date)
		const timeZoneNamePart = parts.find((part) => part.type === 'timeZoneName')
		return timeZoneNamePart ? timeZoneNamePart.value : timezone
	} catch (error) {
		return timezone
	}
}

export const prettifyDateISOString = (
	dateISOString: string,
	timezone = 'UTC',
) => {
	try {
		const jsDate = new Date(dateISOString)
		const options = { timeZone: timezone, hour12: true }
		const formatter = new Intl.DateTimeFormat('en-US', {
			...options,
			hour: 'numeric',
			minute: 'numeric',
		})
		const formattedTime = formatter.format(jsDate)

		const month = MONTHS[jsDate.getUTCMonth()]
		const weekday = WEEKDAYS[jsDate.getUTCDay()]
		const date = jsDate.getUTCDate()
		const year = jsDate.getUTCFullYear()

		const tzAbbreviation = getTimezoneAbbreviation(jsDate, timezone)

		const prettyString = `${month} ${date} at ${formattedTime} ${tzAbbreviation}`

		return {
			prettyString,
			dateComponents: {
				month,
				weekday,
				date,
				year,
				hours: jsDate.getUTCHours(),
				minutes: jsDate.getUTCMinutes(),
				formattedTime,
				timezone: tzAbbreviation,
			},
		}
	} catch (error) {
		return { prettyString: null, dateComponents: {} }
	}
}
