/**
 * This method return possible queries for container
 * that we can use to make responsive styles for it's children.
 */
export const generateContainerQueries = (container: string) => {
	return {
		xs: `@container ${container} (max-width: 480px)`,
		sm: `@container ${container} (max-width: 650px)`,
		md: `@container ${container} (max-width: 768px)`,
		lg: `@container ${container} (max-width: 960px)`,
		xl: `@container ${container} (max-width: 1200px)`,
		'2xl': `@container ${container} (max-width: 1536px)`,
	}
}
