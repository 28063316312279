import { Box, Center, Text } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import React from 'react'
import { GetIcon, Icons } from 'ui'

import { useAppSelector } from '@/app/hooks'
import { FormType } from '@/modules/forms/types'
import { CanvasNodeLayout } from '@/modules/workflow/layouts/CanvasNodeLayout'
import {
	useCanvasNodeById,
	useSelectedWorkflowType,
} from '@/modules/workflow/slice/api-selectors'
import { selectSelectedCanvasNodeId } from '@/modules/workflow/slice/selectors'
import { TRIGGERS_CONTENT } from '@/modules/workflows-home/constants/triggers-content'
import { TEXT_ENUM } from '@/styles/components/text'
import { WORKFLOW_NODE_DROPPABLE_AREA_ID } from '@/utils/constants'

import { BottomConnector } from '../BottomConnector'
import { CanvasNodeHeader } from '../CanvasNodeHeader'
import { NoChildren } from '../NoChildren'
import { GetTriggerNodeDetails } from './GetTriggerNodeDetails'

export const WorkflowTriggerCanvasNode = React.memo(() => {
	const selectedCanvasNodeId = useAppSelector(selectSelectedCanvasNodeId)
	const isSelected = selectedCanvasNodeId === '0'

	const node = useCanvasNodeById('0')?.canvasNode

	const data = node?.data
	const isConnectable = data?.children?.length < 1
	const editableName = data?.editableName

	const type = useSelectedWorkflowType()?.type

	if (!type || type === FormType.draft_trigger) {
		return (
			<Center
				id={`${WORKFLOW_NODE_DROPPABLE_AREA_ID}_CENTER`}
				w="320px"
				h="140px"
				borderRadius="8px"
				border="1px dashed"
				borderColor={COLORS.background[7]}
				bg={ColorTokens.card_secondary}
				flexDir="column"
				cursor="pointer"
				color={COLORS.background[5]}
				className="droppable-area-hover"
			>
				<GetIcon
					icon={Icons.cursor_click}
					boxSize="40px"
					color="inherit"
					mb={4}
				/>
				<Text variant={TEXT_ENUM.semibold14} color={COLORS.background[2]}>
					Select a trigger from the side menu
				</Text>
				<Text variant={TEXT_ENUM.medium12} color={COLORS.background[5]}>
					Add a trigger to start building your workflow
				</Text>
			</Center>
		)
	}

	const triggerContent = TRIGGERS_CONTENT[type]

	return (
		<Box position="relative" cursor="pointer">
			<CanvasNodeLayout isSelected={isSelected} data={data}>
				<CanvasNodeHeader
					selected={isSelected}
					label={editableName || triggerContent.name}
					icon={triggerContent.icon}
					logo={triggerContent.logo}
					logoBg={triggerContent.stepLogoBgColor}
				/>
				<GetTriggerNodeDetails description={data?.description} type={type} />
			</CanvasNodeLayout>
			<BottomConnector isConnectable={isConnectable} isSelected={isSelected} />
			{isConnectable && <NoChildren id="0" />}
		</Box>
	)
})

WorkflowTriggerCanvasNode.displayName = 'WorkflowTriggerCanvasNode'
