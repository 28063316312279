export const enum MembershipTypes {
	Free = 'free',
	Premium = 'premium',
}

type MembershipPlan = MembershipTypes[]

export const MEMBERSHIP_PLANS: Record<MembershipTypes, MembershipPlan> = {
	[MembershipTypes.Free]: [MembershipTypes.Free],
	[MembershipTypes.Premium]: [MembershipTypes.Free, MembershipTypes.Premium],
}
