import { Box, Flex, Text } from '@chakra-ui/react'
import { Icons } from '@ds/Icons'
import { cloneDeep, isEqual } from 'lodash'
import { useMemo } from 'react'
import { SingleValue } from 'react-select'
import { SUPPORTED_LANGUAGES } from 'shared-utils'

import { useGetAllEventsQuery } from '@/app/scheduler/api/events'
import { EventTypeEnum, SchedulerEvent } from '@/app/scheduler/api/events/type'
import { useGetTeamMembersQuery } from '@/app/user/api/members'
import { useTeamId } from '@/app/user/api/teams/selectors'
import { useIsAdditionalAttendees } from '@/common/hooks/feature-flags/useIsAdditionalAttendees'
import { useIsDisplaySchedulerWaitTime } from '@/common/hooks/feature-flags/useIsDisplaySchedulerWaitTime'
import { PanelInput } from '@/modules/workflow/components/side-panel/panel-variants/PanelInput'
import { PanelInputLabel } from '@/modules/workflow/components/side-panel/panel-variants/PanelInputLabel'
import { PanelNumberInput } from '@/modules/workflow/components/side-panel/panel-variants/PanelNumberInput'
import { PanelSelect } from '@/modules/workflow/components/side-panel/panel-variants/PanelSelect'
import {
	useCurrentNodeData,
	useGetMemberOrQueueLabel,
	useSelectedNode,
	useUpdateWorkflowNode,
} from '@/modules/workflow/hooks'
import { useGetAdditionalAttendeesDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetAdditionalAttendeesDrpOptions'
import { useGetDisplaySchedulerDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetDisplaySchedulerDrpOptions'
import {
	Default_DisplayScheduler,
	RefNodeModes,
} from '@/modules/workflow/types/actions'

import { CalloutBox } from '../../../../CalloutBox'
import { DataReferencePicker } from '../../../../DataReferencePicker'
import { RedirectOnSchedulerClose } from './RedirectOnSchedulerClose'
import { DEFAULT_SCHEDULER_WF_STEP_DETAILS } from './step-detail'

enum IntegrationTitle {
	'salesforce' = 'Salesforce',
	'hubspot' = 'HubSpot',
}

export const DefaultDisplayScheduler = () => {
	const selectedNode = useSelectedNode()
	const updateNode = useUpdateWorkflowNode()
	const getDrpOptions = useGetDisplaySchedulerDrpOptions()
	const getAdditionalAttendeesDrpOptions = useGetAdditionalAttendeesDrpOptions()
	const isDisplaySchedulerWaitTimeAvailable: boolean =
		useIsDisplaySchedulerWaitTime()

	const stepDetails = selectedNode?.data
		?.stepDetails as Default_DisplayScheduler

	const teamId = useTeamId()
	const { data: members } = useGetTeamMembersQuery(teamId)
	const isAdditionalAttendeesAvailable = useIsAdditionalAttendees()

	const ownerIntegration =
		stepDetails?.objectId?.fetchMappedUserIntegration || null

	const fallbackOwnerIntegration =
		stepDetails?.fallbackObjectId?.fetchMappedUserIntegration || null

	const unmappedUsers = ownerIntegration
		? members?.filter((m) => !m[`${ownerIntegration}_user_id`])
		: null

	const unmappedFallbackUsers = fallbackOwnerIntegration
		? members?.filter((m) => !m[`${fallbackOwnerIntegration}_user_id`])
		: null

	const { getCurrentNodeData } = useCurrentNodeData(selectedNode)

	const { data: events, isLoading: isEventsDataLoading } =
		useGetAllEventsQuery()

	const eventOptions =
		events
			?.filter((event) => event.type !== EventTypeEnum.personal)
			.map((event) => ({
				label: event.name,
				value: event,
			})) || []

	const updateNodeStepDetails = (newStepDetails) => {
		if (!selectedNode) return

		const currentNodeData = getCurrentNodeData()

		updateNode({
			...currentNodeData,
			data: {
				...currentNodeData.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const handleSelectEvent = (
		eventOption: SingleValue<{ label: string; value: SchedulerEvent }>,
	) => {
		if (!eventOption) return

		const event = eventOption.value

		const currentStepDetails = getCurrentNodeData().data.stepDetails

		const newStepDetails = (currentStepDetails ||
			cloneDeep(DEFAULT_SCHEDULER_WF_STEP_DETAILS)) as Default_DisplayScheduler

		newStepDetails.eventId = {
			refNodeId: null,
			variable: null,
			value: String(event.id),
		}

		if (event.type === EventTypeEnum.group) {
			newStepDetails.type = {
				refNodeId: null,
				variable: null,
				value: event.type,
			}
			newStepDetails.objectId = {
				refNodeId: null,
				variable: null,
				value: null,
				label: null,
			}
			newStepDetails.fallbackObjectId = null
			newStepDetails.fallbackType = null
		}

		if (
			event.type !== EventTypeEnum.group &&
			newStepDetails.type.value === EventTypeEnum.group
		) {
			newStepDetails.type = {
				refNodeId: null,
				variable: null,
				value: null,
			}
			newStepDetails.objectId = {
				refNodeId: null,
				variable: null,
				value: null,
				label: null,
			}
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleSelectQueueOrMember = (rfn) => {
		const currentStepDetails = getCurrentNodeData().data.stepDetails

		const newStepDetails = (currentStepDetails ||
			cloneDeep(DEFAULT_SCHEDULER_WF_STEP_DETAILS)) as Default_DisplayScheduler

		newStepDetails.objectId = {
			refNodeId: rfn.refNodeId,
			variable: rfn.variable,
			value: rfn.value,
			label: rfn.label,
			icon: rfn.icon,
			fetchMappedUserIntegration: rfn.fetchMappedUserIntegration,
		}

		newStepDetails.type = {
			refNodeId: null,
			variable: null,
			value: rfn.mode === RefNodeModes.advanced ? 'member' : rfn.type,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const selectedEvent = useMemo(
		() => events?.find((e) => e.id.toString() === stepDetails?.eventId?.value),
		[events, stepDetails?.eventId?.value],
	)

	const isGroupEvent = selectedEvent?.type === EventTypeEnum.group

	const selectedEventValue = eventOptions.find(
		(op) => op.value.id === selectedEvent?.id,
	)

	const getMemberOrQueueLabel = useGetMemberOrQueueLabel()

	const selectedMemberOrQueueOption =
		stepDetails?.objectId?.value || stepDetails?.objectId?.refNodeId
			? {
					label: getMemberOrQueueLabel(stepDetails.objectId),
					value: stepDetails.objectId,
				}
			: null

	const handleSelectQueueOrMemberFallback = (rfn) => {
		const currentStepDetails = getCurrentNodeData().data.stepDetails

		const newStepDetails = (currentStepDetails ||
			cloneDeep(DEFAULT_SCHEDULER_WF_STEP_DETAILS)) as Default_DisplayScheduler

		newStepDetails.fallbackObjectId = {
			refNodeId: rfn.refNodeId,
			variable: rfn.variable,
			value: rfn.value,
			label: rfn.label,
			icon: rfn.icon,
			fetchMappedUserIntegration: rfn.fetchMappedUserIntegration,
		}

		newStepDetails.fallbackType = {
			refNodeId: null,
			variable: null,
			value: rfn.type,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleClearQueueOrMemberFallback = () => {
		const currentStepDetails = getCurrentNodeData().data.stepDetails

		const newStepDetails = (currentStepDetails ||
			cloneDeep(DEFAULT_SCHEDULER_WF_STEP_DETAILS)) as Default_DisplayScheduler

		newStepDetails.fallbackObjectId = null
		newStepDetails.fallbackType = null

		updateNodeStepDetails(newStepDetails)
	}

	const selectedMemberOrQueueFallbackOption =
		stepDetails?.fallbackObjectId?.value ||
		stepDetails?.fallbackObjectId?.refNodeId
			? {
					label: getMemberOrQueueLabel(stepDetails.fallbackObjectId),
					value: stepDetails.fallbackObjectId,
				}
			: null

	const handleSetRedirectUrlDisplay = (url: string) => {
		const currentStepDetails = getCurrentNodeData().data.stepDetails

		const newStepDetails = (currentStepDetails ||
			cloneDeep(DEFAULT_SCHEDULER_WF_STEP_DETAILS)) as Default_DisplayScheduler

		newStepDetails.redirectUrlDisplay = {
			refNodeId: null,
			variable: null,
			value: url,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleOnLanguageSelect = (
		languageOp: SingleValue<{ label: string; value: string }>,
	) => {
		if (!languageOp) return

		const currentStepDetails = getCurrentNodeData().data.stepDetails

		const newStepDetails = cloneDeep({
			...(currentStepDetails || DEFAULT_SCHEDULER_WF_STEP_DETAILS),
		} as Default_DisplayScheduler)

		newStepDetails.language = {
			refNodeId: null,
			variable: null,
			value: languageOp.value,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const handleWaitTimeChange = (value: string) => {
		const currentStepDetails = getCurrentNodeData().data.stepDetails

		const newStepDetails = (currentStepDetails ||
			cloneDeep(DEFAULT_SCHEDULER_WF_STEP_DETAILS)) as Default_DisplayScheduler

		newStepDetails.delaySeconds = {
			refNodeId: null,
			variable: null,
			value: Number(value) * 60,
		}

		updateNodeStepDetails(newStepDetails)
	}

	const languageOptions = SUPPORTED_LANGUAGES.map((lang) => ({
		label: lang.language,
		value: lang.isoCode1,
	}))

	const selectedLanguageValue = languageOptions.find(
		(op) => op.value === stepDetails?.language?.value,
	)

	const shouldShowUnmappedUsersCalloutBox =
		ownerIntegration &&
		ownerIntegration !== 'default' &&
		unmappedUsers &&
		unmappedUsers.length > 0

	const shouldShowUnmappedFallbackUsersCalloutBox =
		fallbackOwnerIntegration &&
		fallbackOwnerIntegration !== 'default' &&
		unmappedFallbackUsers &&
		unmappedFallbackUsers.length > 0

	const handleAddAttendee = (newAttendee) => {
		if (!selectedNode) return

		const currentStepDetails = getCurrentNodeData().data.stepDetails

		const newStepDetails = (currentStepDetails ||
			cloneDeep(DEFAULT_SCHEDULER_WF_STEP_DETAILS)) as Default_DisplayScheduler

		newStepDetails.additionalAttendees = [
			...(newStepDetails.additionalAttendees || []),
		]

		const isAttendeeSelected = newStepDetails.additionalAttendees?.some((a) =>
			isEqual(a, newAttendee),
		)

		if (isAttendeeSelected) return

		newStepDetails.additionalAttendees?.push(newAttendee)

		updateNodeStepDetails(newStepDetails)
	}

	const handleRemoveAttendee = (attendee) => {
		if (!selectedNode) return

		const currentStepDetails = getCurrentNodeData().data.stepDetails

		const newStepDetails = (currentStepDetails ||
			cloneDeep(DEFAULT_SCHEDULER_WF_STEP_DETAILS)) as Default_DisplayScheduler

		newStepDetails.additionalAttendees =
			newStepDetails.additionalAttendees?.filter((a) => !isEqual(a, attendee))

		updateNodeStepDetails(newStepDetails)
	}

	const additionalAttendeesValues = stepDetails?.additionalAttendees?.map(
		(a) => ({
			label: String(a.label),
			value: a,
		}),
	)

	const additionalAttendeesIntegrations = Array.from(
		new Set(
			(stepDetails?.additionalAttendees || [])
				.map((a) => a.fetchMappedUserIntegration)
				.filter(Boolean),
		),
	)

	const unmappedAdditionalAttendees = members?.filter((m) =>
		additionalAttendeesIntegrations.length > 0
			? additionalAttendeesIntegrations.some(
					(integration) => !m[`${integration}_user_id`],
				)
			: false,
	)

	const shouldShowUnmappedAdditionalAttendeesCalloutBox =
		unmappedAdditionalAttendees && unmappedAdditionalAttendees.length > 0

	return (
		<Flex w="100%" direction="column" gap={8}>
			<Flex direction="column">
				<PanelInputLabel label="Event Name" />
				<PanelSelect
					placeholder="Select a meeting type"
					isLoading={isEventsDataLoading}
					value={selectedEventValue || null}
					options={eventOptions}
					onChange={handleSelectEvent}
					isSearchable
				/>
			</Flex>

			{!isGroupEvent && (
				<Flex direction="column">
					<Flex direction="column" mb={5}>
						<PanelInputLabel label="Queue/Member" />
						<DataReferencePicker
							getInitialOptions={getDrpOptions}
							initialOption={selectedMemberOrQueueOption}
							onSelect={handleSelectQueueOrMember}
						/>
						{shouldShowUnmappedUsersCalloutBox && (
							<CalloutBox
								icon={Icons.person}
								title="Unmapped users"
								description={`${unmappedUsers.length} users are not mapped to ${IntegrationTitle[ownerIntegration]}. This mapping is required in order for Default to display the scheduler for your Queue/Member selection.`}
								linkText="Manage user mappings in Settings"
								to="/settings/workspace/members"
							/>
						)}
					</Flex>

					<Flex direction="column" mb={5}>
						<PanelInputLabel
							label="Fallback (optional)"
							tooltipText="A failsafe queue/member used in case there is an issue displaying the scheduler with the previous selection (e.g. an unmapped user). Picking from Queues or Members is recommended for this field."
						/>
						<DataReferencePicker
							getInitialOptions={getDrpOptions}
							initialOption={selectedMemberOrQueueFallbackOption}
							isClearable
							clearValue={handleClearQueueOrMemberFallback}
							onSelect={handleSelectQueueOrMemberFallback}
						/>
						{shouldShowUnmappedFallbackUsersCalloutBox && (
							<CalloutBox
								icon={Icons.person}
								title="Unmapped users"
								description={`${unmappedFallbackUsers.length} users are not mapped to ${IntegrationTitle[fallbackOwnerIntegration]}. These mappings are required in order for Default to display the scheduler for your Queue/Member selection.`}
								linkText="Manage user mappings in Settings"
								to="/settings/workspace/members"
							/>
						)}
					</Flex>

					{isAdditionalAttendeesAvailable && (
						<Flex direction="column">
							<PanelInputLabel
								label="Additional attendees (optional)"
								tooltipText="This allows you to construct a group event with multiple attendees. All attendees' availability will be taken into account when generating the scheduler's time slots, and the meeting will be booked with all attendees on the calendar event."
							/>
							<DataReferencePicker
								getInitialOptions={getAdditionalAttendeesDrpOptions}
								initialOption={additionalAttendeesValues || []}
								searchPlaceholder="Search for an attendee"
								isMulti
								onSelect={handleAddAttendee}
								removeValue={handleRemoveAttendee}
							/>
							{shouldShowUnmappedAdditionalAttendeesCalloutBox && (
								<CalloutBox
									icon={Icons.person}
									title="Unmapped users"
									description={`${unmappedAdditionalAttendees.length} users are not mapped to referenced integrations. These mappings are required in order for Default to display the scheduler for your Queue/Member selection.`}
									linkText="Manage user mappings in Settings"
									to="/settings/workspace/members"
								/>
							)}
						</Flex>
					)}
				</Flex>
			)}

			<RedirectOnSchedulerClose />
			<Flex direction="column">
				<PanelInputLabel
					label="Display scheduler at this URL upon redirect"
					tooltipText="Make sure the import script is installed at the URL you designate here."
				/>
				<PanelInput
					initialValue={stepDetails?.redirectUrlDisplay?.value ?? ''}
					placeholder="URL"
					onBlur={(e) => handleSetRedirectUrlDisplay(e.target.value)}
				/>
			</Flex>
			<Flex direction="column">
				<PanelInputLabel label="Language" />
				<PanelSelect
					value={selectedLanguageValue || null}
					options={languageOptions}
					onChange={handleOnLanguageSelect}
				/>
			</Flex>
			{isDisplaySchedulerWaitTimeAvailable && (
				<Flex direction="column">
					<PanelInputLabel
						label="Time to wait"
						tooltipText="The number of minutes to wait before closing the scheduler, sending the lead down the 'Meeting not booked' path."
					/>
					<Flex align="center">
						<PanelNumberInput
							onChange={(val) => handleWaitTimeChange(val)}
							value={stepDetails.delaySeconds?.value / 60 || 5}
							defaultValue={5}
							min={1}
							max={30}
						/>
						<Box marginLeft="4">
							<Text>minutes</Text>
						</Box>
					</Flex>
				</Flex>
			)}
		</Flex>
	)
}
