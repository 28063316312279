import { useCallback } from 'react'

import AmplemarketLogo from '@/assets/logo/amplemarket.png'

import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import {
	defaultAttributes,
	WorkflowIntegrationIds,
} from '../../../utils/mappings'
import { useGetUpstreamNodes } from '../../useGetUpstreamNodes'

export const useGetAmplemarketDrpOptions = () => {
	// const store = useStore()
	const { getUpstreamNodes } = useGetUpstreamNodes()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const upstreamNodes = getUpstreamNodes()
			const amplemarketNode = upstreamNodes.find(
				(n) =>
					n.data.integrationId === WorkflowIntegrationIds.amplemarketEnrich,
			)

			if (!amplemarketNode) return []

			const amplemarketPersonOptions = Object.entries(defaultAttributes)
				.filter(([, v]) => v.type === 'amplemarket.person')
				.map(([, object]) => ({
					label: object.name,
					value: object.key,
					onSelect: (value: any) => {
						const newRfn = {
							refNodeId: amplemarketNode.data.id,
							variable: `$.${value}`,
							value: null,
							label: object.name,
							icon: AmplemarketLogo,
						}
						onSelect(newRfn)
					},
				}))

			const amplemarketCompanyOptions = Object.entries(defaultAttributes)
				.filter(([, v]) => v.type === 'amplemarket.company')
				.map(([, object]) => ({
					label: object.name,
					value: object.key,
					onSelect: (value: any) => {
						const newRfn = {
							refNodeId: amplemarketNode.data.id,
							variable: `$.${value}`,
							value: null,
							label: object.name,
							icon: AmplemarketLogo,
						}
						onSelect(newRfn)
					},
				}))

			return [
				{
					label: 'Amplemarket person data',
					icon: AmplemarketLogo,
					value: amplemarketPersonOptions,
				},
				{
					label: 'Amplemarket company data',
					icon: AmplemarketLogo,
					value: amplemarketCompanyOptions,
				},
			]
		},
		[getUpstreamNodes],
	)
}
