import { Flex, FlexProps } from '@chakra-ui/react'
import { RADII } from '@ds/tokens/borders'
import { ColorTokens } from '@ds/tokens/colors'
import { SHADOWS } from '@ds/tokens/shadows'
import { GetIcon, Icons } from 'ui'

interface Props extends FlexProps {
	icon: Icons
	selected: boolean
	onClick: () => void
}

export const CanvasControlBarButton = ({
	icon,
	selected,
	onClick,
	...props
}: Props) => {
	return (
		<Flex
			h={9}
			w={9}
			p="9px"
			cursor="pointer"
			alignItems="center"
			justifyContent="center"
			borderRadius={RADII.lg}
			boxShadow={SHADOWS.elevated}
			bg={selected ? ColorTokens.accent : ColorTokens.white}
			onClick={onClick}
			{...props}
		>
			<GetIcon
				icon={icon}
				color={selected ? ColorTokens.white : ColorTokens.black}
				maxH="100px !important"
				maxW="100px !important"
				h="100%"
				w="100%"
			/>
		</Flex>
	)
}
