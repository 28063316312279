const SIDEBAR_PREFERENCE_KEY = 'is_home_sidebar_expanded'

export const getSidebarPreference = () => {
	const isExpanded = localStorage.getItem(SIDEBAR_PREFERENCE_KEY)
	return isExpanded ? JSON.parse(isExpanded) : false
}

export const setSidebarPreference = (payload: boolean) => {
	localStorage.setItem(SIDEBAR_PREFERENCE_KEY, JSON.stringify(payload))
}
