import { Button, Flex } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useCopyToClipboard } from 'react-use'
import { GetIcon, Icons } from 'ui'

import { PageNav } from '@/common/components/PageHeader'
import { useCustomToast } from '@/components/Toast'
import { BTN } from '@/styles/components/button'

type Props = {
	workflowName: string
	workflowId: string
	workflowVersion: number
}

export const VersionViewerHeader: React.FC<Props> = ({
	workflowName,
	workflowId,
	workflowVersion,
}) => {
	const [, copy] = useCopyToClipboard()
	const toast = useCustomToast()
	const location = useLocation()

	const steps = [
		{ title: 'Workflows', route: '/workflows', icon: Icons.workflow },
		{ title: workflowName, route: `/workflows/${workflowId}/editor` },
		{
			title: workflowVersion === -1 ? 'Test Run' : `Version ${workflowVersion}`,
		},
	]

	const handleCopyLink = () => {
		copy(`${window.location.origin}${location.pathname}${location.search}`)
		toast({
			title: 'Link copied to clipboard',
			status: 'success',
			isClosable: true,
		})
	}

	return (
		<Flex direction="column" w="100%" h="fit-content" pos="relative" zIndex={9}>
			<PageNav steps={steps} variant="secondary">
				<Flex w="full" justifyContent="flex-end">
					<Button
						variant={BTN.secondary_v2}
						rightIcon={
							<GetIcon
								icon={Icons.share2}
								color={COLORS.background[5]}
								boxSize={5}
							/>
						}
						fontWeight={500}
						onClick={handleCopyLink}
					>
						Share
					</Button>
				</Flex>
			</PageNav>
		</Flex>
	)
}
