import { SingleValue } from 'react-select'

import { useGetSalesforceFieldsQuery } from '@/app/integrations/api/salesforce'
import { PanelSelect } from '@/modules/workflow/components/side-panel/panel-variants/PanelSelect'
import { useSelectedNode } from '@/modules/workflow/hooks'
import { FieldRefNodeValue } from '@/modules/workflow/types/actions'
import { MatchingFieldsTypes as Types } from '@/modules/workflow/types/matching'
import { Salesforce_MatchRecord } from '@/modules/workflow/types/salesforce'

import { ConditionInputProps } from '../../conditional/Condition'

export const ConditionParameterInput = ({
	condition,
	handleUpdateInput,
}: ConditionInputProps) => {
	const selectedNode = useSelectedNode()
	const stepDetails = selectedNode?.data.stepDetails as Salesforce_MatchRecord

	const parameter = condition.parameter as FieldRefNodeValue | null

	const type = stepDetails?.type?.value
	const { data: fieldsData, isLoading: isFieldDataLoading } =
		useGetSalesforceFieldsQuery({ type })

	const fields = (fieldsData || []).map((field) => ({
		label: field.label,
		value: {
			refNodeId: null,
			variable: null,
			value: field.name,
			label: field.label,
			dataType: field.type,
			type: Types.salesforce,
		},
	}))

	const handleOnChange: (
		newValue: SingleValue<{
			label: string
			value: any
		}>,
	) => void = (option) => {
		if (!option) return
		handleUpdateInput(option.value)
	}

	return (
		<PanelSelect
			value={
				parameter && parameter.label
					? { label: parameter.label, value: parameter.value }
					: null
			}
			isSearchable
			options={fields}
			placeholder="Select a field..."
			isLoading={isFieldDataLoading}
			controlStyles={{
				minHeight: 'fit-content',
			}}
			onChange={handleOnChange}
		/>
	)
}
