import { Box } from '@chakra-ui/react'
import React from 'react'
import { NodeProps } from 'reactflow'

import { CanvasNodeLayout } from '@/modules/workflow/layouts/CanvasNodeLayout'
import { WorkflowIntegrationsMap as Integrations } from '@/modules/workflow/utils/mappings'
import { integrationCategoryBackground } from '@/styles/colors'

import { BottomConnector } from '../BottomConnector'
import { CanvasNodeHeader } from '../CanvasNodeHeader'
import { NoChildren } from '../NoChildren'
import { GetActionNodeDetails } from './GetActionNodeDetails'

export const WorkflowActionCanvasNode = React.memo(
	({ data, selected }: NodeProps) => {
		const { integrationId, id, editableName, children } = data

		const integrationContent = Integrations[integrationId]

		return (
			<Box position="relative" cursor="pointer">
				<CanvasNodeLayout isSelected={selected} data={data}>
					<CanvasNodeHeader
						selected={selected}
						icon={integrationContent?.isIcon ? integrationContent?.logo : null}
						logo={integrationContent?.isIcon ? null : integrationContent?.logo}
						logoBg={
							integrationCategoryBackground[
								integrationContent?.integrationCategory
							]
						}
						label={editableName ?? integrationContent?.integrationName ?? ''}
						nodeId={id}
						integrationId={integrationId}
					/>
					<GetActionNodeDetails data={data} />
				</CanvasNodeLayout>
				<BottomConnector
					isConnectable={data.children.length < 1}
					isSelected={selected}
				/>
				{children.length === 0 && <NoChildren id={id} />}
			</Box>
		)
	},
)

WorkflowActionCanvasNode.displayName = 'WorkflowActionCanvasNode'
