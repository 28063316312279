import { Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { TEXT_ENUM } from '@/styles/components/text'

interface OverviewStatusProps {
	published: boolean
}

export const OverviewStatus = ({ published }: OverviewStatusProps) => {
	return (
		<Flex direction="column" gap={2}>
			<Text variant={TEXT_ENUM.semibold14}>Status</Text>
			{published !== undefined && (
				<Flex
					bg={published ? COLORS.green[2] : '#FFE8BD'}
					border={`1px solid ${published ? COLORS.green[7] : '#FFC14E'}`}
					borderRadius="6"
					py={3}
					px={4}
					align="center"
					gap={2}
				>
					<GetIcon
						icon={published ? Icons.check : Icons.alert_triangle}
						boxSize={4}
						color={published ? COLORS.green[9] : '#AD7204'}
					/>
					<Text
						variant={TEXT_ENUM.medium14}
						color={published ? COLORS.green[9] : '#AD7204'}
					>
						{published ? 'Published' : 'Recent changes have not been published'}
					</Text>
				</Flex>
			)}
		</Flex>
	)
}
