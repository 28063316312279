import { useCallback } from 'react'
import { Icons } from 'ui'

import { FormType } from '@/modules/forms/types'
import { AsyncDrpOptionGenerator } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker/components/Option'
import { LabeledRefNodeValue } from '@/modules/workflow/types/actions'
import { WorkflowIntegrationIds } from '@/modules/workflow/utils/mappings'

import { useGetUpstreamNodes } from '../../useGetUpstreamNodes'
import { useSelectedWorkflow } from '../../workflow'

enum MeetingFields {
	id = 'id',
	member_name = 'member_name',
	member_id = 'member_id',
	_meeting_booked_owner = '_meeting_booked_owner', // this is for fetchMappedUserIntegration selection of member ID
	member_email = 'member_email',
	durationInMinutes = 'durationInMinutes',
	startDateTime = 'startDateTime',
	startDate = 'startDate',
	prettyStartDateTime = 'prettyStartDateTime',
	title = 'title',
	queue_name = 'queue_name',
}

const MEETING_FIELD_LABELS: Record<MeetingFields, string> = {
	[MeetingFields._meeting_booked_owner]: 'Meeting owner',
	[MeetingFields.member_name]: 'Host name',
	[MeetingFields.member_id]: 'Host ID',
	[MeetingFields.member_email]: 'Host email',
	[MeetingFields.durationInMinutes]: 'Meeting duration (minutes)',
	[MeetingFields.startDateTime]: 'Meeting start date/time',
	[MeetingFields.startDate]: 'Meeting start date',
	[MeetingFields.prettyStartDateTime]: 'Formatted meeting start date/time',
	[MeetingFields.title]: 'Meeting title',
	[MeetingFields.id]: 'Meeting ID',
	[MeetingFields.queue_name]: 'Meeting queue name',
}

export const useGetMeetingBookedDrpOptions = (): AsyncDrpOptionGenerator => {
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
	const { getUpstreamNodes } = useGetUpstreamNodes()

	const isMeetingTrigger = [
		FormType.meeting_booked_trigger,
		FormType.meeting_cancelled_trigger,
		FormType.meeting_reassigned_trigger,
		FormType.meeting_rescheduled_trigger,
		FormType.meeting_no_show_trigger,
	].includes(selectedWorkflow?.type as FormType)

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const upstreamNodes = getUpstreamNodes()
			const hasDisplayScheduler = !!Object.values(upstreamNodes).find(
				(node) =>
					node.data.integrationId ===
					WorkflowIntegrationIds.defaultDisplayScheduler,
			)
			const hasRoute = !!Object.values(upstreamNodes).find(
				(node) =>
					node.data.integrationId === WorkflowIntegrationIds.defaultRoute,
			)

			if (!isMeetingTrigger && !hasDisplayScheduler && !hasRoute) {
				return []
			}

			const meetingOptions = Object.entries(MEETING_FIELD_LABELS).map(
				([key, label]) => ({
					label,
					value: key,
					onSelect: () => {
						const newRfn: LabeledRefNodeValue = {
							refNodeId: 'meeting_booked',
							variable: `$.${key}`,
							value: null,
							label,
							icon: Icons.calendar_check,
						}

						if (key === MeetingFields._meeting_booked_owner) {
							newRfn.icon = Icons.default_logo
							newRfn.variable = '$.member_id'
							newRfn.fetchMappedUserIntegration = 'default'
						}

						onSelect(newRfn)
					},
				}),
			)

			return [
				{
					label: 'Meeting data',
					icon: Icons.calendar_check,
					value: meetingOptions,
				},
			]
		},
		[getUpstreamNodes, isMeetingTrigger],
	)
}
