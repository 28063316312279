import { Slack_SendMessage } from '@/modules/workflow/types/actions'

export const DEFAULT_SLACK_SEND_MESSAGE_STEP_DETAILS: Slack_SendMessage = {
	recipients: [],
	message: {
		refNodeId: null,
		variable: null,
		value: null,
	},
}
