import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { QueueDistributionType, QueueWithMembers } from '../types'

export interface QueuesSliceState {
	openCreateQueueModal: boolean
	selectedQueueDistribution: QueueDistributionType | null
	queueSettings: QueueWithMembers | null
}

export const initialState: QueuesSliceState = {
	openCreateQueueModal: false,
	selectedQueueDistribution: null,
	queueSettings: null,
}

export const queuesSlice = createSlice({
	name: 'queues',
	initialState,
	reducers: {
		setOpenCreateQueueModal: (state, action: PayloadAction<boolean>) => {
			state.openCreateQueueModal = action.payload
		},
		setSelectedQueueDistribution: (
			state,
			action: PayloadAction<QueueDistributionType>,
		) => {
			state.selectedQueueDistribution = action.payload
		},
		setQueueSettings: (
			state,
			action: PayloadAction<QueueWithMembers | null>,
		) => {
			state.queueSettings = action.payload
		},
	},
})

export const queuesActions = queuesSlice.actions
export const queuesReducer = queuesSlice.reducer
