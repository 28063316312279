import { Center, CenterProps, Image } from '@chakra-ui/react'
import { GetIcon, Icons } from 'ui'

import { Borders } from '@/styles/borders'

interface Props extends CenterProps {
	logo?: string
	icon?: Icons
	bg?: string
}

export const StepLogo = ({ logo, icon, bg, ...props }: Props) => {
	if (!logo && !icon) return null

	return (
		<Center
			w="32px"
			h="32px"
			flexShrink={0}
			borderRadius="8px"
			border={icon && bg ? 'none' : Borders.primary}
			bg={icon && bg ? bg : 'white'}
			{...props}
		>
			{icon ? <GetIcon icon={icon} color="white" boxSize="24px" /> : null}
			{logo && <Image src={logo} objectFit="contain" w="24px" h="24px" />}
		</Center>
	)
}
