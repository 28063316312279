import { Icons } from 'ui'

import { GenericIntegrationsMapType } from '../../types/integrations'
import { RFNodeType, StepType } from '../../types/nodes'
import { WorkflowIntegrationType as IntegrationType } from './integrations'

export enum GenericIntegrationIds {
	flowStart = 'flowStart',
	empty = 'empty',
}

export const GenericIntegrationsMap: GenericIntegrationsMapType = {
	flowStart: {
		integrationId: null,
		integrationName: IntegrationType.Logic,
		logo: Icons.no_icon,
		integrationAction: '',
		stepDetails: {},
		nodeType: RFNodeType.flowStart,
		stepType: StepType.WorkflowStart,
		isIcon: true,
	},
	empty: {
		integrationId: null,
		integrationName: IntegrationType.Empty,
		logo: Icons.no_icon,
		isIcon: true,
		integrationAction: '',
		stepDetails: {},
		nodeType: RFNodeType.actionNode,
		stepType: StepType.Empty,
	},
}
