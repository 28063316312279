import { useDispatch } from 'react-redux'
import { useDeepCompareEffect } from 'react-use'

import { workflowsActions } from '../slice'
import { useCanvasNodes } from './workflow'

export const useStoreCurrentCanvasNodes = () => {
	const canvasNodes = useCanvasNodes()?.canvasNodes
	const dispatch = useDispatch()

	useDeepCompareEffect(() => {
		dispatch(workflowsActions.setCurrentCanvasNodes(canvasNodes))
	}, [canvasNodes])
}
