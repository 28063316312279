import { COLORS } from '@ds/tokens/colors'
import { StylesConfig } from 'react-select'

import { SHADOWS } from '@/styles/constants'

type Args = {
	minHeight?: string
}

/** React select will look similar to Secondart Button V2 */
export const secondarySelectStyles = (
	args?: Args,
): StylesConfig<any, boolean, any> => {
	const { minHeight } = args || {}

	return {
		container: () => ({
			minHeight: minHeight || '36px',
		}),
		control: (_, state) => ({
			minHeight: minHeight || '36px',
			borderRadius: '8px',
			borderColor: 'transparent',
			boxShadow: state.isFocused
				? SHADOWS.secondary_button_focus_v2
				: SHADOWS.secondary_button_v2,
			'&:hover': {
				backgroundColor: COLORS.background[12],
				borderColor: state.isFocused ? 'transparent' : COLORS.background[12],
			},
		}),
	}
}
