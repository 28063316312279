import { FieldRefNodeValue, RefNodeValue } from './actions'

export interface MatchingCondition {
	field: FieldRefNodeValue
	operator: string | null
	value: RefNodeValue
	order?: string
}

export interface MatchingCriterion {
	conditions: MatchingCondition[]
	logic: string
}

export type PriorityCriterion = MatchingCriterion & {
	orderBy: MatchingCondition[]
}

export interface MatchRecord {
	type: RefNodeValue
	resultChildId: string | null
	elseChildId: string | null
	matchCriteria: MatchingCriterion
	priorityCriteria: PriorityCriterion[]
}

export enum MatchingFieldsTypes {
	salesforce = 'Salesforce',
	hubspot = 'HubSpot',
	custom = 'Default',
}
