// @ts-nocheck
import { Box, Flex, Text } from '@chakra-ui/react'
import {
	Time,
	toCalendarDate,
	toCalendarDateTime,
} from '@internationalized/date'
import {
	AriaDateRangePickerProps,
	useDateRangePicker,
} from '@react-aria/datepicker'
import { useDateRangePickerState } from '@react-stately/datepicker'
import { DateValue } from '@react-types/calendar'
import { isEqual } from 'lodash'
import { createRef, useEffect } from 'react'

import { TEXT_ENUM } from '@/styles/components/text'

import { RangeCalendar } from './RangeCalendar'

interface DateRangePickerProps extends AriaDateRangePickerProps<DateValue> {
	showStartAndEnd?: boolean
	closeOnSelect?: boolean
	setter: (arg: { start: Date; end: Date }) => void
}

export const DateRangePicker = (props: DateRangePickerProps) => {
	const state = useDateRangePickerState({
		...props,
		granularity: 'day',
		shouldCloseOnSelect: props.closeOnSelect,
	})
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

	const ref = createRef<HTMLDivElement>()
	const { calendarProps } = useDateRangePicker(props, state, ref)

	const range = state.formatValue('en-US', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	})

	useEffect(() => {
		if (!isEqual(state.value.start, state.value.end)) {
			props.setter({
				start: toCalendarDateTime(
					// @ts-ignore
					toCalendarDate(state.value.start),
					new Time(0, 0),
				).toDate(timezone),
				end: toCalendarDateTime(
					// @ts-ignore
					toCalendarDate(state.value.end),
					new Time(23, 59),
				).toDate(timezone),
			})
		}
	}, [state.value])

	const SHOW_START_END_ENABLED = false

	return (
		<Box
			position="relative"
			w="100%"
			display="inline-flex"
			flexDirection="column"
			mt={props.showStartAndEnd ? 6 : 1.5}
		>
			{props.showStartAndEnd && SHOW_START_END_ENABLED && (
				<Flex>
					<Flex w="100%" align="center" justify="space-between" mb={6}>
						<Flex w="fit-content" direction="column">
							<Text variant={TEXT_ENUM.h5}>From</Text>
							<Text variant={TEXT_ENUM.sm}>
								{range.start.split(' ').slice(0, 3).join(' ')}
							</Text>
						</Flex>
						<Flex w="fit-content" direction="column">
							<Text variant={TEXT_ENUM.h5}>To</Text>
							<Text variant={TEXT_ENUM.sm}>
								{range.end.split(' ').slice(0, 3).join(' ')}
							</Text>
						</Flex>
					</Flex>
				</Flex>
			)}

			<RangeCalendar {...calendarProps} />
		</Box>
	)
}
