import { Flex } from '@chakra-ui/react'
import React from 'react'

import { Borders } from '@/styles/borders'

type Props = {
	children: React.ReactNode
	hide?: boolean
}

export const CanvasNodeMetaDetailsLayout = React.memo(
	({ children, hide }: Props) => {
		if (hide) return null
		return (
			<Flex gap={4} p={3} borderTop={Borders.primary} flexDir="column">
				{children}
			</Flex>
		)
	},
)
