import { DataReferencePickerOption } from '../components/side-panel/panel-content/DataReferencePicker/components/Option'

/** Recursively filters out and options whose value is an empty array. */
export const filterEmptyOptions = (
	options: DataReferencePickerOption[],
): DataReferencePickerOption[] => {
	const filteredOptions: DataReferencePickerOption[] = []

	for (const option of options) {
		if (optionHasSubMenu(option.value)) {
			const filteredSubOptions = filterEmptyOptions(option.value)
			if (filteredSubOptions.length) {
				filteredOptions.push({ ...option, value: filteredSubOptions })
			}
		} else {
			filteredOptions.push(option)
		}
	}

	return filteredOptions
}

/** This function assumes that sub-menus are arrays comprised of only objects and that those objects are valid DRP options. */
export const optionHasSubMenu = (value: any): boolean => {
	return (
		Array.isArray(value) &&
		value.every((v) => v && typeof v === 'object' && !Array.isArray(v))
	)
}
