import { useGetParameterInfo } from '@/modules/workflow/hooks'
import { useGetConditionDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetConditionDrpOptions'
import { useGetParameterLabel } from '@/modules/workflow/hooks/useGetParameterLabel'
import { FieldRefNodeValue } from '@/modules/workflow/types/actions'

import { DataReferencePicker } from '../../../../DataReferencePicker'
import { ConditionInputProps } from '../../conditional/Condition'

export const ConditionValueInput = ({
	condition,
	handleUpdateInput,
}: ConditionInputProps) => {
	const getDrpOptions = useGetConditionDrpOptions()
	const value = condition.value as FieldRefNodeValue | null
	const valueAttribute = useGetParameterInfo({ parameter: value })
	const label = useGetParameterLabel(value, valueAttribute.parameterAttribute)

	return (
		<DataReferencePicker
			getInitialOptions={getDrpOptions}
			initialOption={
				value
					? {
							label,
							value,
						}
					: null
			}
			controlStyles={{
				minHeight: 'fit-content',
			}}
			enableCustomValue
			customValueInputStyles={{
				h: '34px',
			}}
			flexDirection="row"
			onSelect={handleUpdateInput}
		/>
	)
}
