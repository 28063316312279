import { Flex } from '@chakra-ui/react'

import { WORKFLOW_SIDE_PANEL_ID } from '@/utils/constants'

import { SidePanelNavigation } from '../components/side-panel/SidePanelNavigation'
import { SidePanelContentPartial } from './SidePanelContentPartial'

export const SidePanelPartial = () => {
	return (
		<Flex
			id={WORKFLOW_SIDE_PANEL_ID}
			position="absolute"
			zIndex={3}
			right={0}
			my={6}
		>
			<SidePanelContentPartial />
			<SidePanelNavigation />
		</Flex>
	)
}
