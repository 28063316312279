import { useFlags } from 'launchdarkly-react-client-sdk'

import { FeatureFlags } from '@/common/constants/feature-flags'

export const useIsReminders2 = () => {
	const flags = useFlags()

	return flags[FeatureFlags.reminders_v2] === undefined
		? false
		: flags[FeatureFlags.reminders_v2]
}
