import { Flex, Text } from '@chakra-ui/react'
import { GetIcon, Icons } from 'ui'

type Props = {
	title?: string | null
	icon: Icons
}

export const LogsOverviewCardHeader = ({ title, icon }: Props) => {
	return (
		<Flex w="full" px="16px" py="16px" alignItems="center">
			<GetIcon icon={icon} />
			<Text fontSize="16px" fontWeight={600} ml={2} noOfLines={1}>
				{title || '-'}
			</Text>
		</Flex>
	)
}
