import { Flex } from '@chakra-ui/react'
import { isEqual } from 'lodash'
import { MultiValue } from 'react-select'

import { useGetAllEventsQuery } from '@/app/scheduler/api/events'
import { useMemberId } from '@/app/user/api/members/selector'
import { getEventOptions } from '@/common/utils/getEventOptions'
import { FormType } from '@/modules/forms/types'

import {
	EVENT_TYPE_OPTIONS,
	getSpecificContextMeetingOptions,
	MeetingAction,
} from '../../constants/meeting-trigger-event-type-options'
import {
	MeetingTriggerConfig,
	useUpdateMeetingTriggerConfig,
} from '../../hooks/triggers-config'
import { useSelectedWorkflow } from '../../hooks/workflow'
import { PanelInputLabel } from '../side-panel/panel-variants/PanelInputLabel'
import { PanelSelect } from '../side-panel/panel-variants/PanelSelect'

export const MeetingRescheduledTrigger = () => {
	const memberId = useMemberId()
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
	const updateMeetingTriggerConfig = useUpdateMeetingTriggerConfig()
	const { data: allEvents, isLoading: isAllEventsDataLoading } =
		useGetAllEventsQuery()

	const meetingRescheduledConfig =
		(selectedWorkflow?.meta?.trigger_config as MeetingTriggerConfig) || {}

	const handleEventTypeChange = (op) => {
		const value = op?.value

		updateMeetingTriggerConfig(value)
	}

	const eventTypeOptions = EVENT_TYPE_OPTIONS.filter((option) =>
		option.validTriggerTypes.includes(selectedWorkflow?.type as FormType),
	)

	const selectedEventTypeOption = eventTypeOptions.find((option) => {
		if (meetingRescheduledConfig.events) return option.value.events
		return isEqual(meetingRescheduledConfig, option.value)
	})

	const selectedEventIds: number[] = meetingRescheduledConfig.events || []

	const SPECIFIC_EVENT_OPTIONS = getEventOptions(allEvents || [], memberId)

	const selectedEventOptions = selectedEventIds.map((eventId) => ({
		label: allEvents?.find((event) => event.id === eventId)?.name,
		value: eventId,
	}))

	const handleSpecificEventsChange = (
		options: MultiValue<{ label?: string; value: number }>,
	) => {
		const events = options.map((option) => option.value)

		updateMeetingTriggerConfig({
			events,
		})
	}

	const handleSpecificEventsContextChange = (op) => {
		const value = op?.value

		updateMeetingTriggerConfig({
			events: meetingRescheduledConfig.events,
			...value,
		})
	}

	const specificContextMeetingOption = getSpecificContextMeetingOptions(
		MeetingAction.Rescheduled,
	)

	const selectedSpecificEventContextOption = specificContextMeetingOption.find(
		(option) =>
			isEqual(meetingRescheduledConfig, {
				events: meetingRescheduledConfig.events,
				...option.value,
			}),
	)

	return (
		<Flex w="full" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel label="Event types" fontWeight={500} />
				<PanelSelect
					placeholder="Select an event type"
					options={eventTypeOptions}
					value={selectedEventTypeOption}
					onChange={handleEventTypeChange}
				/>
			</Flex>

			{meetingRescheduledConfig.events && (
				<>
					<Flex direction="column">
						<PanelInputLabel label="Select events" fontWeight={500} />
						<PanelSelect
							placeholder="Select an option"
							options={SPECIFIC_EVENT_OPTIONS}
							value={selectedEventOptions}
							isMulti
							onChange={handleSpecificEventsChange}
							isLoading={isAllEventsDataLoading}
						/>
					</Flex>
					<Flex direction="column">
						<PanelInputLabel label="Run workflow when..." fontWeight={500} />
						<PanelSelect
							placeholder="Select an option"
							options={specificContextMeetingOption}
							value={selectedSpecificEventContextOption}
							onChange={handleSpecificEventsContextChange}
						/>
					</Flex>
				</>
			)}
		</Flex>
	)
}
