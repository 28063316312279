import { Circle, HStack, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { components, SingleValueProps } from 'react-select'

export const TagSingleValue = (props: SingleValueProps<any, boolean, any>) => {
	const { value } = props.data
	const { label, color } = value

	return (
		<components.SingleValue {...props}>
			<HStack gap={2}>
				<Circle size={2} bg={color} />
				<Text color={COLORS.gray[12]}>{label}</Text>
			</HStack>
		</components.SingleValue>
	)
}
