import { HStack, ModalCloseButton, Text } from '@chakra-ui/react'

import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

export const WebhookModalHeader = () => {
	return (
		<HStack
			w="full"
			align="flex-start"
			p={5}
			borderBottom={Borders.primary}
			gap={2}
			alignItems="center"
			justifyContent="space-between"
		>
			<Text variant={TEXT_ENUM.semibold16}>Set up webhook</Text>
			<ModalCloseButton
				_focus={{
					outline: 'none',
				}}
			/>
		</HStack>
	)
}
