import * as Sentry from '@sentry/react'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { Fallback } from './Fallback'

const errorHandler = (error: Error, info: React.ErrorInfo) => {
	if (process.env.NODE_ENV === 'production') {
		Sentry.captureException({ ...error, ...info })
	}
	console.error(
		error.message,
		info && info.componentStack ? info.componentStack : '',
	)
}

type Props = {
	children: React.ReactNode
}

export const ErrorBoundaryComponent = ({ children }: Props) => {
	return (
		<ErrorBoundary
			FallbackComponent={Fallback}
			onReset={() => {
				window.location.reload()
			}}
			onError={errorHandler}
		>
			{children}
		</ErrorBoundary>
	)
}
