import { COLORS } from '@ds/tokens/colors'
import { StylesConfig } from 'react-select'

import { SHADOWS } from '@/styles/constants'

import { SelectVariant } from '../type'
import { getVariantStyles } from './get-varaint-styles'

type Args = {
	customStyles?: StylesConfig<any, any, any> | undefined
	width?: string
	minHeight?: string
	matchWidth?: boolean
	variant?: SelectVariant
}

export const mergeSelectStyles = ({
	customStyles,
	variant,
	width,
	minHeight,
	matchWidth,
}: Args) => {
	const {
		container,
		control,
		singleValue,
		placeholder,
		menu,
		menuList,
		option,
		multiValue,
		multiValueRemove,
		noOptionsMessage,
		input,
		...restStyles
	} = customStyles || {}

	const variantStyles = getVariantStyles({ variant, minHeight })

	const baseStyles: StylesConfig<any, boolean, any> = {
		container: (baseStyles, state) => ({
			...baseStyles,
			width: width || 'fit-content',
			// minWidth: '180px',
			minHeight: minHeight || '40px',
			...variantStyles?.container?.(baseStyles, state),
			...container?.(baseStyles, state),
		}),
		control: (baseStyles, state) => {
			return {
				...baseStyles,
				// minWidth: '180px',
				width: width || '100%',
				minHeight: minHeight || '40px',
				borderColor: state.isFocused ? 'transparent' : COLORS.background[10],
				background: COLORS.whiteAlpha[12],
				borderRadius: '6px',
				cursor: 'pointer',
				boxShadow: state.isFocused ? SHADOWS.focused : 'none',
				padding: '0 4px',
				'&:hover': {
					borderColor: state.isFocused ? 'transparent' : COLORS.background[7],
				},
				...variantStyles?.control?.(baseStyles, state),
				...control?.(baseStyles, state),
			}
		},
		singleValue: (baseStyles, state) => ({
			...baseStyles,
			color: COLORS.background[2],
			fontSize: '14px',
			fontWeight: 500,
			...variantStyles?.singleValue?.(baseStyles, state),
			...singleValue?.(baseStyles, state),
		}),
		placeholder: (baseStyles, state) => ({
			...baseStyles,
			color: COLORS.background[6],
			fontSize: '14px',
			...variantStyles?.placeholder?.(baseStyles, state),
			...placeholder?.(baseStyles, state),
		}),
		menu: (baseStyles, state) => {
			return {
				...baseStyles,
				backgroundColor: COLORS.whiteAlpha[12],
				width: matchWidth ? '100%' : 'fit-content',
				minWidth: '200px',
				borderColor: 'transparent',
				zIndex: 999999,
				borderRadius: '8px',
				boxShadow: SHADOWS.popover,
				...variantStyles?.menu?.(baseStyles, state),
				...menu?.(baseStyles, state),
			}
		},
		menuList: (baseStyles, state) => {
			return {
				...baseStyles,
				padding: '4px',
				...variantStyles?.menuList?.(baseStyles, state),
				...menuList?.(baseStyles, state),
			}
		},
		option: (baseStyles, state) => {
			return {
				...baseStyles,
				color: COLORS.background[2],
				fontSize: '14px',
				borderRadius: '6px',
				fontWeight: 500,
				cursor: 'pointer',
				margin: '4px 0',
				backgroundColor:
					state.isSelected || state.isFocused
						? COLORS.purple[2]
						: 'transparent',
				'&:hover': {
					backgroundColor: COLORS.purple[2],
				},
				...variantStyles?.option?.(baseStyles, state),
				...option?.(baseStyles, state),
			}
		},
		multiValue: (baseStyles, state) => ({
			...baseStyles,
			borderRadius: '6px',
			fontSize: '14px',
			color: COLORS.background[2],
			fontWeight: 500,
			backgroundColor: COLORS.purple[4],
			...variantStyles?.multiValue?.(baseStyles, state),
			...multiValue?.(baseStyles, state),
		}),
		multiValueRemove: (baseStyles, state) => ({
			...baseStyles,
			'&:hover': {
				backgroundColor: 'transparent',
			},
			...variantStyles?.multiValueRemove?.(baseStyles, state),
			...multiValueRemove?.(baseStyles, state),
		}),
		noOptionsMessage: (baseStyles, state) => ({
			...baseStyles,
			color: COLORS.background[6],
			fontSize: '14px',
			...variantStyles?.noOptionsMessage?.(baseStyles, state),
			...noOptionsMessage?.(baseStyles, state),
		}),
		input: (baseStyles, state) => ({
			...baseStyles,
			color: COLORS.background[2],
			fontSize: '14px',
			fontWeight: 500,
			width: '100px',
			...variantStyles?.input?.(baseStyles, state),
			...input?.(baseStyles, state),
		}),
	}

	return {
		...baseStyles,
		...restStyles,
	}
}
