import {
	Box,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Portal,
	Spinner,
} from '@chakra-ui/react'
import React, { useCallback, useRef, useState } from 'react'

interface IframePreviewTooltipProps {
	url: string
	scale?: number
	iframeWidth?: string | number | undefined
	iframeHeight?: string | number | undefined
	tooltipWidth?: string | number | undefined
	tooltipHeight?: string | number | undefined
	iframeStyle?: React.CSSProperties
	tooltipStyle?: React.CSSProperties
	children: React.ReactNode
	tooltipDelay?: number
}

export const IframePreviewTooltip: React.FC<IframePreviewTooltipProps> = ({
	url,
	scale = 0.25,
	iframeWidth = '1600px',
	iframeHeight = '1200px',
	tooltipWidth = '400px',
	tooltipHeight = '300px',
	tooltipStyle = {},
	iframeStyle = {},
	tooltipDelay = 0,
	children,
}) => {
	const [isLoading, setIsLoading] = useState(true)
	const [isOpen, setIsOpen] = useState(false)
	const [hasBeenOpened, setHasBeenOpened] = useState(false)
	const timeoutRef = useRef<NodeJS.Timeout | null>(null)
	const iframeLoadedRef = useRef(false)

	// disabled due to clickjacking prevention disallowing iframe from being loaded
	const IS_DISABLED = true

	const handleOpen = useCallback(() => {
		if (IS_DISABLED) {
			return
		}
		if (!hasBeenOpened) {
			setHasBeenOpened(true)
		}
		if (iframeLoadedRef.current) {
			setIsOpen(true)
		} else {
			timeoutRef.current = setTimeout(() => setIsOpen(true), tooltipDelay)
		}
	}, [])

	const handleClose = useCallback(() => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current)
		}
		setIsOpen(false)
	}, [])

	return (
		<Popover
			isOpen={IS_DISABLED ? false : isOpen}
			onClose={handleClose}
			placement="right"
			closeOnBlur={false}
			trigger="hover"
		>
			<PopoverTrigger>
				<Box onMouseEnter={handleOpen} onMouseLeave={handleClose}>
					{children}
				</Box>
			</PopoverTrigger>
			<Portal>
				<PopoverContent
					width={tooltipWidth}
					height={tooltipHeight}
					border="none"
					shadow="lg"
					style={tooltipStyle}
				>
					<Box position="relative" width="100%" height="100%" overflow="hidden">
						{isLoading && (
							<Box
								position="absolute"
								top="50%"
								left="50%"
								transform="translate(-50%, -50%)"
							>
								<Spinner size="sm" />
							</Box>
						)}
						<Box
							width="100%"
							height="100%"
							transform={`scale(${scale})`}
							transformOrigin="0 0"
						>
							{hasBeenOpened && (
								<iframe
									src={url}
									width={iframeWidth}
									height={iframeHeight}
									onLoad={() => {
										setIsLoading(false)
										iframeLoadedRef.current = true
									}}
									style={{ border: 'none', ...iframeStyle }}
								/>
							)}
						</Box>
					</Box>
				</PopoverContent>
			</Portal>
		</Popover>
	)
}
