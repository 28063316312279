import { useAppSelector } from '@/app/hooks'
import { selectIssHomeSidebarExpanded } from '@/app/user/slice/selectors'

import {
	COLLAPSED_HOME_SIDEBAR_WIDTH_PX,
	EXPANDED_HOME_SIDEBAR_WIDTH_PX,
} from '../constants/sidebar'
import { usePreviewMode } from './usePreviewMode'

export const useSidebarWidth = () => {
	const isExpanded = useAppSelector(selectIssHomeSidebarExpanded)
	const isPreview = usePreviewMode()

	if (isPreview) {
		return '0px'
	}

	return isExpanded
		? EXPANDED_HOME_SIDEBAR_WIDTH_PX
		: COLLAPSED_HOME_SIDEBAR_WIDTH_PX
}

export const useMainContentMaxWidth = () => {
	const sidebarWidth = useSidebarWidth()
	return `calc(100vw - ${sidebarWidth})`
}
