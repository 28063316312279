import { Icons } from 'ui'

import {
	useGetSlackChannelsQuery,
	useGetSlackUsersQuery,
} from '@/app/integrations/api/slack'
import { Slack_SendMessage } from '@/modules/workflow/types/actions'
import { WorkflowNode } from '@/modules/workflow/types/nodes'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataTagList } from '../MetaDataTagList'
import { MetaDataTitle } from '../MetaDataTitle'
import { MetaDataWarningTag } from '../MetaDataWarningTag'

export const SlackDetails = ({ data }: { data: WorkflowNode }) => {
	const stepDetails = data?.stepDetails as Slack_SendMessage

	// Legacy support for recipients
	const { data: slackUsers } = useGetSlackUsersQuery()
	const { data: slackChannels } = useGetSlackChannelsQuery()

	const selectedRecipients = (stepDetails?.recipients || []).map((r) => {
		let label
		let icon
		if (!r.label) {
			const channelName = slackChannels?.find((c) => c.id === r.value)?.name
			if (channelName) {
				label = channelName
				icon = Icons.hashtag
			} else {
				label = slackUsers?.flat().find((u) => u.id === r.value)
					?.profile?.real_name
				icon = Icons.person
			}
		} else {
			label = r.label.startsWith('#') ? r.label.slice(1) : r.label
			icon = r.icon
		}

		return {
			label: label || `Channel ID ${r.value}`,
			icon,
		}
	})

	if (!selectedRecipients?.length) {
		return (
			<MetaDataRowLayout>
				<MetaDataTitle title="Send to:" />
				<MetaDataWarningTag
					warning="Select person or channel"
					icon={Icons.hashtag}
				/>
			</MetaDataRowLayout>
		)
	}

	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="Send to:" />
			<MetaDataTagList tags={selectedRecipients} />
		</MetaDataRowLayout>
	)
}
