import { FormType } from '@/modules/forms/types'

export enum LogLevel {
	ERROR = 'error',
	WARN = 'warn',
	INFO = 'info',
	DEBUG = 'debug',
}

export type WorkflowLogLead = {
	id: string
	email: string
	first_name?: string | null
	last_name?: string | null
	company?: string | null
	profile?: string | null
	website?: string | null
	title?: string | null
}

export type WorkflowStepLogContent = {
	message: string
	timestamp: string
	logLevel: LogLevel
	json?: Record<string, any> | null
}

export type WorkflowLogStep = {
	key: string
	label?: string | null
	executionStartedAt: string
	executionFinishedAt: string
	integrationId?: string | null
	error?: boolean | null
	_logs: WorkflowStepLogContent[]
	[key: string]: any
}

export interface WorkflowLog {
	id: string
	form_id: number
	form_version: number
	type: FormType
	email?: string | null
	lead?: WorkflowLogLead | null
	crmRecord?: Record<string, any>
	tagsAssigned?: { tagId: string; tagName: string }[]
	meetingBooked?: Record<string, any>
	created_at: string
	steps: WorkflowLogStep[]
	errorsCount: number
	form: {
		name?: string | null
	}
}

export type AllWorkflowlogs = {
	logs: WorkflowLog[]
	count?: number | null
}
