import { useFlags } from 'launchdarkly-react-client-sdk'

import { FeatureFlags } from '@/common/constants/feature-flags'

export const useIsSlateRichTextEditor = () => {
	const flags = useFlags()

	return flags[FeatureFlags['slate-rich-text-editor']] === undefined
		? false
		: flags[FeatureFlags['slate-rich-text-editor']]
}
