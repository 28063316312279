export enum Day {
	monday = 'Monday',
	tuesday = 'Tuesday',
	wednesday = 'Wednesday',
	thursday = 'Thursday',
	friday = 'Friday',
	saturday = 'Saturday',
	sunday = 'Sunday',
}

export const WEEKDAYS = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
]

export const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

export interface TimeSlot {
	enabled: boolean
	day: Day
	hours_ranges: Array<Array<string>>
}

export const generateDateTimeString = (
	dateValue: string | number | Date,
	time: boolean,
	format24h?: boolean,
) => {
	const date = new Date(dateValue)
	const today = new Date()
	const yesterday = new Date()
	yesterday.setDate(yesterday.getDate() - 1)

	let dateString = date.toLocaleDateString()

	if (date.toDateString() === today.toDateString()) {
		dateString = 'Today'
	}
	if (date.toDateString() === yesterday.toDateString()) {
		dateString = 'Yesterday'
	}
	return (
		dateString +
		(time
			? `, ${date.toLocaleTimeString([], {
					hour: '2-digit',
					minute: '2-digit',
					hour12: !format24h,
				})}`
			: '')
	)
}

export const tsToTimeAgo = (ts: number) => {
	const now = Date.now()
	const diff = now - ts
	if (diff >= 604800000) {
		return `${Math.floor(diff / 604800000)}w ago`
	} else if (diff >= 86400000) {
		return `${Math.floor(diff / 86400000)}d ago`
	} else if (diff >= 3600000) {
		return `${Math.floor(diff / 3600000)}h ago`
	} else if (diff >= 60000) {
		return `${Math.floor(diff / 60000)}m ago`
	} else {
		return `<1m ago`
	}
}
