import { useCallback } from 'react'

import HarmonicLogo from '@/assets/logo/harmonic.svg'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import {
	defaultAttributes,
	WorkflowIntegrationIds,
} from '../../../utils/mappings'
import { useGetUpstreamNodes } from '../../useGetUpstreamNodes'

export const useGetHarmonicDrpOptions = (): AsyncDrpOptionGenerator => {
	const { getUpstreamNodes } = useGetUpstreamNodes()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const upstreamNodes = getUpstreamNodes()
			const harmonicNode = upstreamNodes.find(
				(n) => n.data.integrationId === WorkflowIntegrationIds.harmonic,
			)

			if (!harmonicNode) return []

			const harmonicOptions = Object.entries(defaultAttributes)
				.filter(([, v]) => v.type === 'harmonic.company')
				.map(([, object]) => ({
					label: object.name,
					value: object.key,
					onSelect: (value: any) => {
						const newRfn = {
							refNodeId: harmonicNode.data.id,
							variable: `$.${value}`,
							value: null,
							label: object.name,
							icon: HarmonicLogo,
						}
						onSelect(newRfn)
					},
				}))

			return [
				{
					label: 'Harmonic data',
					icon: HarmonicLogo,
					value: harmonicOptions,
				},
			]
		},
		[getUpstreamNodes],
	)
}
