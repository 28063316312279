import { LogLevel, WorkflowLogStep } from '../types'

export const getWorkflowLogStepErrors = (
	workflowLogStep?: WorkflowLogStep | null,
): string[] => {
	if (!workflowLogStep) return []

	const errors: string[] = []

	workflowLogStep._logs?.forEach((log) => {
		if (log.logLevel === LogLevel.ERROR) {
			errors.push(log.message)
		}
	})

	return errors
}
