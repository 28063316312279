import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useLazyGetSalesforceFieldsQuery } from '@/app/integrations/api/salesforce'
import { useCustomSalesforceObjects } from '@/common/hooks/feature-flags/useCustomSalesforceObjects'

import { workflowsActions } from '../slice'
import { SalesforceEntities } from '../types/salesforce'

export const useAllSalesforceFields = () => {
	const dispatch = useDispatch()
	const [fetchFields] = useLazyGetSalesforceFieldsQuery()
	const customSalesforceObjects = useCustomSalesforceObjects()

	const objectTypes = [
		...Object.values(SalesforceEntities),
		...customSalesforceObjects,
	]

	return useCallback(async () => {
		const fetchedFields = {}
		const fieldPromises: Promise<unknown>[] = []
		for (const objectType of objectTypes) {
			fieldPromises.push(
				new Promise((resolve, reject) => {
					fetchFields({ type: objectType })
						.unwrap()
						.then((data) => {
							resolve(data)
						})
						.catch((error) => {
							reject(error)
						})
				}),
			)
		}

		await Promise.allSettled(fieldPromises).then((results) => {
			results.forEach((result, index) => {
				if (result.status === 'fulfilled') {
					fetchedFields[objectTypes[index]] = result.value
				}
			})
		})

		dispatch(workflowsActions.setSalesforceFields(fetchedFields))

		return fetchedFields
	}, [dispatch, fetchFields, objectTypes])
}
