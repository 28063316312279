import { Flex, Grid } from '@chakra-ui/react'

export const GridLayout = ({ children }: { children: React.ReactNode }) => {
	return (
		<Flex overflowY="auto">
			<Grid
				flex={1}
				gap={3}
				overflowY="auto"
				templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
				templateRows="max-content"
				px={6}
				pb={40}
			>
				{children}
			</Grid>
		</Flex>
	)
}
