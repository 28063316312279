import { nanoid } from 'nanoid'

import { RefNodeValue } from '@/modules/workflow/types/actions'
import { CanvasNode } from '@/modules/workflow/types/nodes'

import { AsyncDrpOptionGenerator } from '../../../../DataReferencePicker'
import { ParameterMapper, ParametersType } from '../../../ParameterMapper'

interface RedirectUrlParamsSectionProps {
	getInitialDrpOptions: AsyncDrpOptionGenerator
	params: ParametersType
	updateKey: string
	getCurrentNodeData: () => CanvasNode
	updateNodeParams: (newParams: ParametersType) => void
}

export const RedirectUrlParamsSection = ({
	getInitialDrpOptions,
	params,
	updateKey,
	getCurrentNodeData,
	updateNodeParams,
}: RedirectUrlParamsSectionProps) => {
	const handleSetParameterKey = (id: string, key: string) => {
		const currParams = getCurrentNodeData().data.stepDetails?.[updateKey]

		const newUrlParams = {
			...currParams,
			[id]: {
				refNodeId: null,
				variable: null,
				value: [
					key,
					currParams[id].value
						? currParams[id].value[1]
						: {
								refNodeId: null,
								variable: null,
								value: null,
							},
				],
			},
		}

		updateNodeParams(newUrlParams)
	}

	const handleSetParameterValue = (
		id: string,
		value: RefNodeValue | string,
	) => {
		const currParams = getCurrentNodeData().data.stepDetails?.[updateKey]

		const newUrlParams = {
			...currParams,
			[id]: {
				refNodeId: null,
				variable: null,
				value: [currParams[id].value[0], value],
			},
		}

		updateNodeParams(newUrlParams)
	}

	const handleDeleteParameter = (id: string) => {
		const currParams = getCurrentNodeData().data.stepDetails?.[updateKey]

		delete currParams[id]

		updateNodeParams(currParams)
	}

	const handleAddParameter = () => {
		const currParams = getCurrentNodeData().data.stepDetails?.[updateKey]

		const newUrlParams = {
			...currParams,
			[nanoid(4)]: {
				refNodeId: null,
				variable: null,
				value: [
					'',
					{
						refNodeId: null,
						variable: null,
						value: '',
					},
				],
			},
		}

		updateNodeParams(newUrlParams)
	}

	return (
		<ParameterMapper
			sectionLabel="URL Parameters"
			params={params || {}}
			useDataReferencePicker
			getInitialDrpOptions={getInitialDrpOptions}
			handleSetParameterKey={handleSetParameterKey}
			handleSetParameterValue={handleSetParameterValue}
			handleDeleteParameter={handleDeleteParameter}
			handleAddParameter={handleAddParameter}
		/>
	)
}
