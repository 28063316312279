import {
	Editable,
	EditablePreview,
	EditableTextarea,
	Tooltip,
	useEditableControls,
} from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { useEffect, useRef, useState } from 'react'
import { GetIcon, Icons } from 'ui'

import { TOOLTIP_ENUM } from '@/styles/components/tooltip'

interface DescriptionTextAreaProps {
	placeholder?: string
	initialValue: string
	onBlur: (nextValue: string) => void
}

export const DescriptionTextarea = ({
	placeholder,
	initialValue,
	onBlur,
}: DescriptionTextAreaProps) => {
	const [value, setValue] = useState<string>(initialValue)

	useEffect(() => {
		setValue(initialValue)
	}, [initialValue])

	const ref = useRef<HTMLDivElement>(null)
	const [currentRefEl, setCurrentRefEl] = useState<HTMLDivElement | null>(
		ref.current,
	)

	useEffect(() => {
		setCurrentRefEl(ref.current)
	}, [ref])

	const isTextOverflowing = currentRefEl
		? currentRefEl.scrollHeight > currentRefEl.clientHeight
		: false

	return (
		<Editable
			display="flex"
			alignItems="center"
			gap={2}
			placeholder={placeholder || 'Add a description...'}
			mb={4}
			fontSize="sm"
			cursor="pointer"
			color={value ? COLORS.background[2] : COLORS.background[8]}
			value={value}
			onChange={(newValue) => setValue(newValue)}
			onBlur={() => onBlur(value)}
		>
			<Tooltip
				label={value}
				isDisabled={!isTextOverflowing}
				variant={TOOLTIP_ENUM.workflows_side_panel}
			>
				<EditablePreview
					ref={ref}
					cursor="pointer"
					overflow="hidden"
					textOverflow="ellipsis"
					noOfLines={2}
				/>
			</Tooltip>
			<EditableControls />
			<EditableTextarea
				borderRadius="none"
				_focus={{ boxShadow: 'none' }}
				_placeholder={{ color: COLORS.background[8] }}
				resize="none"
			/>
		</Editable>
	)
}

const EditableControls = () => {
	const { isEditing, getEditButtonProps } = useEditableControls()

	return isEditing ? null : (
		<GetIcon
			icon={Icons.edit2}
			boxSize={4}
			{...getEditButtonProps()}
			ref={null} // Function components cannot be given refs.
		/>
	)
}
