import { Ai_Prompt } from '@/modules/workflow/types/actions'
import { WorkflowNode } from '@/modules/workflow/types/nodes'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataTag } from '../MetaDataTag'
import { MetaDataTitle } from '../MetaDataTitle'
import { MetaDataWarningTag } from '../MetaDataWarningTag'

export const AiPromptDetails = ({ data }: { data: WorkflowNode }) => {
	const stepDetails = data?.stepDetails as Ai_Prompt

	const model = stepDetails?.model?.value

	if (!model) {
		return (
			<MetaDataRowLayout>
				<MetaDataTitle title="Model:" />
				<MetaDataWarningTag warning="Select a model" />
			</MetaDataRowLayout>
		)
	}

	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="Model:" />
			<MetaDataTag label={model} />
		</MetaDataRowLayout>
	)
}
