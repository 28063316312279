import { Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import { SingleValue } from 'react-select'

import { useGetApolloSequencesQuery } from '@/app/integrations/api/apollo/index'
import { ApolloSequenceType } from '@/app/integrations/api/apollo/type'
import {
	useCurrentNodeData,
	useUpdateWorkflowNode,
} from '@/modules/workflow/hooks'
import { useGetApolloStartSequenceDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetApolloStartSequenceDrpOptions'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import {
	Apollo_StartSequence,
	LabeledRefNodeValue,
} from '@/modules/workflow/types/actions'

import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../../../panel-variants/PanelSelect'
import { DataReferencePicker } from '../../../DataReferencePicker'
import { FieldMapper } from '../../FieldMapper'

const emptyDefault: Apollo_StartSequence = {
	sequenceId: {
		refNodeId: null,
		variable: null,
		value: null,
		label: null,
	},
	sendEmailId: {
		refNodeId: null,
		variable: null,
		value: null,
		label: null,
	},
	insertContactFields: {},
}

const apolloFields: { label: string; id: string }[] = [
	{ label: 'First Name', id: 'first_name' },
	{ label: 'Last Name', id: 'last_name' },
	{ label: 'Company Name', id: 'organization_name' },
	{ label: 'Title', id: 'title' },
	{ label: 'Account ID', id: 'account_id' },
	{ label: 'Email', id: 'email' },
	{ label: 'Raw Address', id: 'present_raw_address' },
	{ label: 'Direct Dial Phone', id: 'direct_phone' },
	{ label: 'Corporate Phone', id: 'corporate_phone' },
	{ label: 'Mobile Phone', id: 'mobile_phone' },
	{ label: 'Home Phone', id: 'home_phone' },
	{ label: 'Other Phone', id: 'other_phone' },
]

export const ApolloStartSequence = () => {
	const updateNode = useUpdateWorkflowNode()
	const selectedNode = useSelectedNode()
	const getDrpOptions = useGetApolloStartSequenceDrpOptions()

	const stepDetails = selectedNode?.data.stepDetails as Apollo_StartSequence

	const { data: sequences, isLoading: isSequenceDataLoading } =
		useGetApolloSequencesQuery()

	const { getCurrentNodeData } = useCurrentNodeData(selectedNode)

	const updateNodeStepDetails = (newStepDetails: Apollo_StartSequence) => {
		if (!selectedNode) return
		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const sequenceOptions =
		sequences?.map((s: ApolloSequenceType) => ({
			label: s.name,
			value: s.id,
		})) || []

	const handleSequenceChange = (
		option: SingleValue<{ label: string; value: string }>,
	) => {
		if (!option) return

		const { label, value } = option
		const newStepDetails = cloneDeep(stepDetails || emptyDefault)
		newStepDetails.sequenceId = {
			refNodeId: null,
			variable: null,
			value,
			label,
		}
		updateNodeStepDetails(newStepDetails)
	}

	const sequenceId = stepDetails?.sequenceId
	const sequenceValue = sequenceOptions.find(
		(op) => op.value === sequenceId?.value,
	)

	const handleSenderSelect = (rfn: LabeledRefNodeValue) => {
		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Apollo_StartSequence

		const newStepDetails = currentStepDetails || cloneDeep(emptyDefault)

		newStepDetails.sendEmailId = rfn

		if (rfn.fetchMappedUserIntegration) {
			newStepDetails.sendEmailId.fetchMappedUserIntegration =
				rfn.fetchMappedUserIntegration
		}

		updateNodeStepDetails(newStepDetails)
	}

	const sendEmailId = stepDetails?.sendEmailId
	const selectedSenderOption = sendEmailId?.label
		? { label: sendEmailId.label, value: sendEmailId }
		: null

	return (
		<Flex w="100%" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel label="Sequence" />
				<PanelSelect
					options={sequenceOptions}
					value={sequenceValue || null}
					placeholder="Select a Sequence"
					isLoading={isSequenceDataLoading}
					onChange={handleSequenceChange}
				/>
			</Flex>

			<Flex direction="column">
				<PanelInputLabel label="Sender" />
				<DataReferencePicker
					getInitialOptions={getDrpOptions}
					initialOption={selectedSenderOption}
					onSelect={handleSenderSelect}
				/>
			</Flex>

			<FieldMapper
				integration="Apollo"
				fields={apolloFields}
				isDataLoading={false}
			/>
		</Flex>
	)
}
