import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useAppSelector } from '@/app/hooks'
import { selectSelectedViewId } from '@/app/leads/slice/selectors'
import { ErrorBoundaryComponent } from '@/components/ErrorBoundary'
import { LoadingPage } from '@/components/Loading'

import { DEEP_ROUTES, ROUTES } from '../../../routing/routes'
import { HomeContentLayout } from '../layouts/HomeContentLayout'
import { HomeLayout } from '../layouts/HomeLayout'
import { HomeSidebarPartial } from '../partials/HomeSidebarPartial'
import { ProtectedPartial } from '../partials/ProtectedPartial'

export const HomePage = () => {
	return (
		<ProtectedPartial>
			<HomeLayout>
				<HomeSidebarPartial />
				<ErrorBoundaryComponent>
					<HomeContentLayout>
						<HomeRoutes />
					</HomeContentLayout>
				</ErrorBoundaryComponent>
			</HomeLayout>
		</ProtectedPartial>
	)
}

/**
 * There is some error related to circular dependency caused by
 * either Workflow or Form modules. That's why importing
 * this module not as Lazy.
 * Todo: Fix the circular dependency issue.
 */
import { useIsMeetings2 } from '@/common/hooks/feature-flags/useIsMeeting2'

import WorkflowsPage from '../../workflows-home'
const FormsPage = lazy(() => import('../../forms'))
const SettingsPage = lazy(() => import('../../../Screens/Settings'))
const MeetingsRouter = lazy(() => import('../../meetings'))
const MeetingsHome = lazy(() => import('../../meetings-home'))
const SchedulerPage = lazy(() => import('../../scheduler'))
const GuidePage = lazy(() => import('../../guide'))
const CompaniesPage = lazy(() => import('../../companies'))
const LeadsPage = lazy(() => import('../../../Screens/Leads'))

const HomeRoutes = () => {
	const selectedViewId = useAppSelector(selectSelectedViewId)
	const isMeetings2 = useIsMeetings2()
	return (
		<Suspense fallback={<LoadingPage />}>
			<Routes>
				<Route path={DEEP_ROUTES.FORMS} element={<FormsPage />} />
				<Route path={DEEP_ROUTES.WORKFLOWS_HOME} element={<WorkflowsPage />} />
				<Route path={DEEP_ROUTES.SETTINGS} element={<SettingsPage />} />
				<Route path={DEEP_ROUTES.guide} element={<GuidePage />} />
				<Route path={DEEP_ROUTES.SCHEDULER} element={<SchedulerPage />} />
				<Route
					path={DEEP_ROUTES.MEETINGS}
					element={isMeetings2 ? <MeetingsHome /> : <MeetingsRouter />}
				/>
				<Route path={DEEP_ROUTES.COMPANIES} element={<CompaniesPage />} />
				<Route path={DEEP_ROUTES.LEADS} element={<LeadsPage />} />
				<Route
					path={ROUTES.FORMS}
					element={<Navigate replace to="/forms/default" />}
				/>
				<Route
					path={ROUTES.ALL}
					element={<Navigate replace to={`/leads/views/${selectedViewId}`} />}
				/>
			</Routes>

			<Routes>
				<Route
					path={ROUTES.INDEX}
					element={<Navigate replace to={`/leads/views/${selectedViewId}`} />}
				/>
			</Routes>
		</Suspense>
	)
}
