import { useCallback, useMemo } from 'react'
import { Icons } from 'ui'

import { AsyncDrpOptionGenerator } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker/components/Option'
import { buildNestedDrpOptions } from '@/modules/workflow/utils/buildNestedDrpOptions'

import { useSelectedWorkflow } from '../../../workflow'

export const useGetWebhookTriggerDrpOptions = (): AsyncDrpOptionGenerator => {
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
	const triggerName = selectedWorkflow?.workflow?.steps[0].editableName
	const payloadBody =
		selectedWorkflow?.meta?.trigger_config?.test_request_payload?.body

	const payload = useMemo(() => payloadBody || {}, [payloadBody])

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const payloadOptions = buildNestedDrpOptions({
				optionsObj: payload,
				newRfnProps: {
					refNodeId: '0',
					icon: Icons.webhook,
				},
				onSelect: (refNodeProps) => {
					const { label, icon, refNodeId, variable } = refNodeProps

					const newRfn = {
						refNodeId,
						variable: `$.payload.${variable}`,
						value: null,
						label,
						icon,
					}

					onSelect(newRfn)
				},
			})

			return [
				{
					label: `Webhook payload for ${
						triggerName ? `"${triggerName}"` : 'trigger node'
					}`,
					icon: Icons.webhook,
					value: payloadOptions,
				},
			]
		},
		[payload, triggerName],
	)
}
