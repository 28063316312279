import { FormType } from '@/modules/forms/types'
import { WorkflowLog } from '@/modules/workflow-logs/types'

import { IntegrationList } from '../components/side-panel/panel-content/IntegrationList'
import { WorkflowTriggersList } from '../components/triggers/WorkflowTriggersList'
import { useSelectedWorkflow } from '../hooks/workflow'
import {
	OverviewPartial,
	StepDetailsPartial,
	VersionHistoryPartial,
} from '../partials'
import { TestWorkflowPartial } from '../partials/TestWorkflowPartial'
import { NavigationId } from '../types/navigation'
import { CanvasNode } from '../types/nodes'

type Props = {
	menuId: NavigationId | null
	selectedNode: CanvasNode | null
	droppableBranch: string | null
	workflowLog?: WorkflowLog | null
}

export const SetPanelContent = ({
	menuId,
	selectedNode,
	droppableBranch,
}: Props) => {
	const selectedWorkflow = useSelectedWorkflow().selectedWorkflow

	const hasTrigger =
		selectedWorkflow?.type && selectedWorkflow?.type !== FormType.draft_trigger

	switch (menuId) {
		case NavigationId.Overview:
			return <OverviewPartial />
		case NavigationId.AddObjects:
			return droppableBranch ? (
				<IntegrationList />
			) : selectedNode ? (
				<StepDetailsPartial selectedNode={selectedNode} />
			) : hasTrigger ? (
				<IntegrationList />
			) : (
				<WorkflowTriggersList />
			)
		case NavigationId.VersionsHistory:
			return <VersionHistoryPartial />
		case NavigationId.TestWorkflow:
			return <TestWorkflowPartial />
		default:
			return null
	}
}
