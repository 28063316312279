import ReactSelect, { GroupBase, Props } from 'react-select'

import { ClearIndicator } from './components/ClearIndicator'
import { DropdownIndicator } from './components/DropdownIndicator'
import { MultiValueContainer } from './components/MultiValueContainer'
import { MultiValueRemove } from './components/MultiValueRemove'
import { Option } from './components/Option'
import { SingleValue } from './components/SingleValue'
import { SelectVariant } from './type'
import { mergeSelectStyles } from './utils/merge-styles'

type CustomProps = {
	width?: string
	minHeight?: string
	matchWidth?: boolean
	variant?: SelectVariant
	clearValue?: () => void
}

export const Select = <
	Option,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>,
>({
	width,
	components,
	minHeight,
	matchWidth,
	styles,
	variant = SelectVariant.primary,
	clearValue,
	...props
}: Props<Option, IsMulti, Group> & CustomProps) => {
	const mergeStyles = mergeSelectStyles({
		variant,
		customStyles: styles,
		width,
		minHeight,
		matchWidth,
	})

	return (
		<ReactSelect
			styles={mergeStyles}
			components={{
				IndicatorSeparator: () => null,
				ClearIndicator: (clearIndicatorProps) => (
					<ClearIndicator
						{...clearIndicatorProps}
						customClearValue={clearValue}
					/>
				),
				DropdownIndicator: DropdownIndicator,
				Option: Option,
				MultiValueRemove: MultiValueRemove,
				SingleValue: SingleValue,
				MultiValueContainer: MultiValueContainer,
				...components,
			}}
			isSearchable={props.isSearchable || false}
			{...props}
		/>
	)
}
