import { Flex, Image, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { FormTable } from '@/modules/forms/types'
import { TEXT_ENUM } from '@/styles/components/text'
import { generateDateTimeString } from '@/utils/helpers/date-time'

interface OverviewGeneralInfoProps {
	workflow: FormTable
}

export const OverviewGeneralInfo = ({ workflow }: OverviewGeneralInfoProps) => {
	const {
		created_by: createdBy,
		created_at: createdAt,
		updated_by: updatedBy,
		updated_at: updatedAt,
	} = workflow

	const generalInfoCategories: {
		label: string
		value: string | null
		iconUrl?: string | null
	}[] = [
		{
			label: 'Created by',
			value:
				createdBy?.first_name || createdBy?.last_name
					? `${createdBy.first_name} ${createdBy.last_name}`
					: null,
			iconUrl: createdBy?.avatar_url,
		},
		{
			label: 'Created date',
			value: generateDateTimeString(createdAt, true),
		},
		{
			label: 'Last edited by',
			value:
				updatedBy?.first_name || updatedBy?.last_name
					? `${updatedBy.first_name} ${updatedBy.last_name}`
					: null,
			iconUrl: updatedBy?.avatar_url,
		},
		{
			label: 'Last edited date',
			value: generateDateTimeString(updatedAt, true),
		},
	]

	return (
		<Flex direction="column" gap={3}>
			<Text variant={TEXT_ENUM.semibold14}>General info</Text>
			{generalInfoCategories.map(({ label, value, iconUrl }, i) => (
				<Flex key={`${label}-${i}`} justify="space-between">
					{value && (
						<>
							<Text variant={TEXT_ENUM.regular14} color={COLORS.background[6]}>
								{label}
							</Text>
							<Flex gap={2} w="50%">
								{iconUrl && (
									<Image
										src={iconUrl}
										alt={label}
										boxSize={5}
										borderRadius="50%"
									/>
								)}
								<Text variant={TEXT_ENUM.medium14}>{value}</Text>
							</Flex>
						</>
					)}
				</Flex>
			))}
		</Flex>
	)
}
