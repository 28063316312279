import { Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'

import {
	useCurrentNodeData,
	useGetParameterInfo,
	useUpdateWorkflowNode,
} from '@/modules/workflow/hooks'
import { useGetDefaultDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetDefaultDrpOptions'
import { useGetParameterLabel } from '@/modules/workflow/hooks/useGetParameterLabel'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import {
	Amplemarket_StartInboundSmartAction,
	FieldRefNodeValue,
	LabeledRefNodeValue,
} from '@/modules/workflow/types/actions'

import { PanelInput } from '../../../../panel-variants/PanelInput'
import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { DataReferencePicker } from '../../../DataReferencePicker'
import { FieldMapper } from '../../FieldMapper'

const emptyDefault: Amplemarket_StartInboundSmartAction = {
	id: {
		value: null,
		variable: null,
		refNodeId: null,
	},
	userEmail: {
		value: null,
		variable: null,
		refNodeId: null,
		label: null,
	},
	insertActionFields: {},
}

export function AmplemarketStartInboundSmartAction() {
	const selectedNode = useSelectedNode()
	const updateNode = useUpdateWorkflowNode()
	const getDrpOptions = useGetDefaultDrpOptions()
	const { getCurrentNodeData } = useCurrentNodeData(selectedNode)

	const stepDetails = selectedNode?.data
		?.stepDetails as Amplemarket_StartInboundSmartAction

	const updateNodeStepDetails = (
		newStepDetails: Amplemarket_StartInboundSmartAction,
	) => {
		if (!selectedNode) return

		const currentNodeData = getCurrentNodeData()

		updateNode({
			...currentNodeData,
			data: {
				...currentNodeData.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const fields = [
		{ id: 'email', name: 'email', value: 'email', label: 'Email' },
		{
			id: 'first_name',
			name: 'first_name',
			value: 'first_name',
			label: 'First Name',
		},
		{
			id: 'last_name',
			name: 'last_name',
			value: 'last_name',
			label: 'Last Name',
		},
		{
			id: 'company_name',
			name: 'company_name',
			value: 'company_name',
			label: 'Company Name',
		},
		{
			id: 'company_domain',
			name: 'company_domain',
			value: 'company_domain',
			label: 'Company Domain',
		},
	]

	const handleIdChange = (id: string) => {
		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Amplemarket_StartInboundSmartAction
		const newStepDetails = currentStepDetails || cloneDeep(emptyDefault)

		newStepDetails.id = {
			refNodeId: null,
			variable: null,
			value: id || null,
		}
		updateNodeStepDetails(newStepDetails)
	}

	const handleUserEmailSelect = (rfn: LabeledRefNodeValue) => {
		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Amplemarket_StartInboundSmartAction
		const newStepDetails = currentStepDetails || cloneDeep(emptyDefault)

		newStepDetails.userEmail = rfn

		updateNodeStepDetails(newStepDetails)
	}

	const userEmail = stepDetails?.userEmail
	const selectedUserEmailAttribute = useGetParameterInfo({
		parameter: userEmail as FieldRefNodeValue,
	})

	const selectedUserEmailLabel = useGetParameterLabel(
		userEmail,
		selectedUserEmailAttribute.parameterAttribute,
	)

	const selectedEmailOption = selectedUserEmailLabel
		? {
				label: selectedUserEmailLabel,
				value: userEmail,
			}
		: null

	return (
		<Flex w="100%" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel
					label="Inbound Smart Action ID"
					description="Copy and paste this from Amplemarket"
				/>
				<PanelInput
					placeholder="ID"
					initialValue={stepDetails?.id?.value || ''}
					onBlur={(e) => {
						handleIdChange(e.target.value)
					}}
				/>
			</Flex>

			<Flex direction="column">
				<PanelInputLabel label="User email" />
				<DataReferencePicker
					getInitialOptions={getDrpOptions}
					initialOption={selectedEmailOption}
					onSelect={handleUserEmailSelect}
					enableCustomValue
				/>
			</Flex>

			<FieldMapper
				integration="Amplemarket"
				description="Email is required"
				fields={fields}
				isDataLoading={false}
			/>
		</Flex>
	)
}
