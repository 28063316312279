import { json } from '@codemirror/lang-json'
import { Global } from '@emotion/react'
import { githubLight } from '@uiw/codemirror-theme-github'
import CodeMirror from '@uiw/react-codemirror'

type Props = {
	value: string
	onChange?: (value: string) => void
	editable?: boolean
}

export const CodeEditor = ({ value, onChange, editable }: Props) => {
	return (
		<>
			<Global
				styles={{
					'.cm-editor': {
						borderRadius: '8px',
						border: '1px solid #E2E8F0',
					},
					'.cm-focused': {
						outline: 'none !important',
						border: '1px solid transparent !important',
						boxShadow: '0px 0px 0px 2px #ACACF2',
					},
					'.cm-scroller': {
						padding: '4px',
					},
				}}
			/>
			<CodeMirror
				editable={editable}
				theme={githubLight}
				autoFocus
				height="200px"
				extensions={[json()]}
				value={value}
				onChange={(value) => {
					if (onChange) {
						onChange(value)
					}
				}}
			/>
		</>
	)
}
