import { Flex, Switch } from '@chakra-ui/react'
import { useEffect } from 'react'
import { SingleValue } from 'react-select'

import { useGetSalesforceFieldsQuery } from '@/app/integrations/api/salesforce'
import { useCustomSalesforceObjects } from '@/common/hooks/feature-flags/useCustomSalesforceObjects'
import { useUpdateWorkflowNode } from '@/modules/workflow/hooks'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import {
	Salesforce_CreateRecord,
	SalesforceEntities,
} from '@/modules/workflow/types/salesforce'

import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../../../panel-variants/PanelSelect'
import { FieldMapper } from '../../FieldMapper'

export const SalesforceCreateRecord = () => {
	const selectedNode = useSelectedNode()
	const stepDetails = selectedNode?.data.stepDetails as Salesforce_CreateRecord
	const updateNode = useUpdateWorkflowNode()
	const customSalesforceObjects = useCustomSalesforceObjects()

	const { data: fieldsData, isLoading: isFieldsDataLoading } =
		useGetSalesforceFieldsQuery(
			{ type: stepDetails?.type?.value },
			{
				skip: !stepDetails?.type?.value,
			},
		)
	const fields = Array.isArray(fieldsData) ? fieldsData : []

	const recordTypeOptions = [
		...Object.values(SalesforceEntities),
		...customSalesforceObjects,
	].map((type) => ({
		label: type,
		value: type,
	}))

	const updateStepDetails = (newStepDetails: Salesforce_CreateRecord) => {
		if (!selectedNode) return

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const handleSelectRecordType = (
		entityTypeOp: SingleValue<{
			label: SalesforceEntities
			value: SalesforceEntities
		}>,
	) => {
		if (!entityTypeOp) return

		const newStepDetails: Salesforce_CreateRecord = {
			type: {
				refNodeId: null,
				variable: null,
				value: entityTypeOp.value,
			},
			insertSalesforceFields: {},
			upsertFieldData: null,
			isUpsertEnabled: false,
		}

		updateStepDetails(newStepDetails)
	}

	const recordTypeValue = recordTypeOptions.find(
		(op) => op.value === stepDetails?.type?.value,
	)

	const handleEnableUpsertField = (state: boolean) => {
		const newStepDetails = {
			...stepDetails,
			upsertFieldData: null,
			isUpsertEnabled: state,
		}

		updateStepDetails(newStepDetails)
	}

	const upsertFieldOptions = fields.length
		? Object.keys(stepDetails?.insertSalesforceFields || {})
				.map((fieldId) => {
					const field = fields.find((f) => f.name === fieldId)
					return {
						label: field?.label,
						value: field?.name,
					}
				})
				.filter((op) => op.label)
		: []

	const selectedUpsertFieldOption =
		upsertFieldOptions.find(
			(op) => op.value && op.value === stepDetails?.upsertFieldData?.value,
		) || null

	const handleSelectUpsertField = (fieldOp) => {
		if (!fieldOp) return

		const newStepDetails: Salesforce_CreateRecord = {
			...stepDetails,
			upsertFieldData: {
				refNodeId: null,
				variable: null,
				value: fieldOp.value,
				label: fieldOp.label,
			},
		}

		updateStepDetails(newStepDetails)
	}

	// This is to ensure that if a user removes the field mapping containing
	// the upsert field, the upsert field is reset as well.
	useEffect(() => {
		if (selectedNode && !selectedUpsertFieldOption) {
			const newStepDetails = {
				...stepDetails,
				upsertFieldData: null,
			}

			updateNode(
				{
					...selectedNode,
					data: {
						...selectedNode.data,
						stepDetails: newStepDetails,
					},
				},
				true,
			)
		}
	}, [selectedUpsertFieldOption])

	return (
		<Flex w="100%" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel label="Type" />
				<PanelSelect
					placeholder="Select a record type"
					value={recordTypeValue || null}
					options={recordTypeOptions}
					onChange={handleSelectRecordType}
				/>
			</Flex>
			{stepDetails?.type && (
				<>
					<FieldMapper
						integration="Salesforce"
						fields={fields}
						isDataLoading={isFieldsDataLoading}
					/>

					<Flex direction="column" gap={1} mt={2}>
						<Flex gap={2}>
							<PanelInputLabel
								label="Enable match field"
								tooltipText="Enable this to match records based on a specific field. If a record is found, it will be updated. Otherwise, a new record will be created."
							/>
							<Switch
								isChecked={stepDetails?.isUpsertEnabled}
								onChange={() =>
									handleEnableUpsertField(!stepDetails?.isUpsertEnabled)
								}
							/>
						</Flex>

						{stepDetails?.isUpsertEnabled && (
							<PanelSelect
								placeholder="Select a match field"
								value={selectedUpsertFieldOption}
								options={upsertFieldOptions}
								isLoading={isFieldsDataLoading}
								isSearchable
								onChange={handleSelectUpsertField}
							/>
						)}
					</Flex>
				</>
			)}
		</Flex>
	)
}
