import type { ComponentStyleConfig } from '@chakra-ui/react'

import { RADII } from '../../../tokens/borders'
import { COLORS, ColorTokens } from '../../../tokens/colors'
import { SPACING } from '../../../tokens/space'

const Checkbox: ComponentStyleConfig = {
	baseStyle: () => ({
		control: {
			w: SPACING[5],
			h: SPACING[5],

			bg: ColorTokens.white,
			borderRadius: RADII.sm,
			color: COLORS.gray[1],

			boxShadow: '0px 1px 4px rgba(34, 43, 72, 0.08)',
			border: '1px solid',
			borderColor: COLORS.gray[7],

			_hover: {
				borderColor: COLORS.gray[8],
				boxShadow: '0px 1px 4px rgba(34, 43, 72, 0.08)',
			},

			_focusVisible: {
				boxShadow:
					'0px 0px 0px 1px #FFFFFF, 0px 0px 0px 4px rgba(99, 99, 242, 0.32)',
			},

			_checked: {
				bg: COLORS.purple[9],
				borderColor: 'transparent',

				_hover: {
					bg: COLORS.purple[10],
				},

				_focus: {
					boxShadow: 'none',
					outline: 'none',
				},
			},
		},
	}),
}

export default Checkbox
