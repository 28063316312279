import {
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { StepsProgression } from '@/common/components/StepsProgression'
import { useGetFormByIdQuery, useUpdateFormMutation } from '@/modules/forms/api'
import { Borders } from '@/styles/borders'

import {
	WEBHOOK_CONNECTION_MODAL_MAX_WIDTH,
	WEBHOOK_MODAL_PROGRESS_STEPS,
	WebhookModalSteps,
} from '../../constants/webhook'
import { useSelectedWorkflow } from '../../hooks'
import { WebhookTriggerTestPayloadConfig } from '../../types/trigger'
import { IntroStep } from './IntroStep'
import { ListeningStep } from './ListeningStep'
import { ResetConfig } from './ResetConfig'
import { WebhookConfigurationsStep } from './WebhookConfigurationStep'
import { WebhookModalFooter } from './WebhookModalFooter'
import { WebhookModalHeader } from './WebhookModalHeader'

type Props = {
	isOpen: boolean
	onClose: () => void
}

export const WebhookConnectionModal = ({ isOpen, onClose }: Props) => {
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
	const [updateWorkflow] = useUpdateFormMutation()
	const workflowId = selectedWorkflow?.id

	const [step, setStep] = useState(WebhookModalSteps.intro)
	const [isListening, setIsListening] = useState(false)

	const { data } = useGetFormByIdQuery(workflowId?.toString(), {
		skip: !workflowId,
		pollingInterval: isListening ? 5000 : undefined,
	})

	const [triggerConfig, setTriggerConfig] =
		useState<WebhookTriggerTestPayloadConfig | null>(null)

	useEffect(() => {
		if (data?.meta?.trigger_config?.test_request_payload) {
			setTriggerConfig(data.meta?.trigger_config?.test_request_payload)
			/** As soon as we have trigger config, take user to next step */
			setIsListening(false)
			setStep(WebhookModalSteps.webhook_configurations)
		}
	}, [data?.meta?.trigger_config])

	const handleResetWebhookConfig = () => {
		if (!workflowId) return

		updateWorkflow({
			form: {
				id: workflowId,
				meta: {
					...selectedWorkflow?.meta,
					trigger_config: {
						...selectedWorkflow?.meta?.trigger_config,
						test_request_payload: null,
					},
				},
			},
		})
		setStep(WebhookModalSteps.intro)
		setIsListening(true)
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnEsc={false}
			closeOnOverlayClick={false}
		>
			<ModalOverlay />
			<ModalContent maxW={WEBHOOK_CONNECTION_MODAL_MAX_WIDTH}>
				<ModalBody p={0} border="none">
					<Flex w="full">
						<Flex
							p={8}
							flexDir="column"
							borderRight={Borders.primary}
							w="280px"
							flexShrink={0}
							gap="80px"
							justifyContent="space-between"
						>
							<StepsProgression
								steps={WEBHOOK_MODAL_PROGRESS_STEPS}
								activeStep={step}
							/>
							<ResetConfig
								handleResetWebhookConfig={handleResetWebhookConfig}
								step={step}
							/>
						</Flex>

						<Flex w="calc(100% - 280px)" flexDir="column">
							<WebhookModalHeader />
							{step === WebhookModalSteps.intro && !isListening && (
								<IntroStep initiateTriggerCheck={() => setIsListening(true)} />
							)}

							{step === WebhookModalSteps.intro && isListening && (
								<ListeningStep setIsListening={setIsListening} />
							)}

							{step === WebhookModalSteps.webhook_configurations && (
								<WebhookConfigurationsStep triggerConfig={triggerConfig} />
							)}
							<WebhookModalFooter
								disablePrimary={isListening}
								handleSecondaryClick={onClose}
								handlePrimaryClick={onClose}
							/>
						</Flex>
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
