import { Icons } from 'ui'

import { LogicStepDetails } from '@/modules/workflow/types/logic'
import { WorkflowNode } from '@/modules/workflow/types/nodes'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataTag } from '../MetaDataTag'
import { MetaDataTitle } from '../MetaDataTitle'
import { MetaDataWarningTag } from '../MetaDataWarningTag'

export const IfElseDetails = ({ data }: { data: WorkflowNode }) => {
	// TODO: Support this for Match blocks but using their "converted" data
	const stepDetails = data?.stepDetails as LogicStepDetails

	const conditionsBlocks = stepDetails?.branches[0]?.conditionBlocks || []

	const conditions = conditionsBlocks.reduce((acc, conditionBlock) => {
		return acc + conditionBlock.conditions.length
	}, 0)

	if (conditions === 0) {
		return (
			<MetaDataRowLayout>
				<MetaDataTitle title="Conditions:" />
				<MetaDataWarningTag
					warning="Add conditions"
					icon={Icons.alert_triangle}
				/>
			</MetaDataRowLayout>
		)
	}

	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="Conditions:" />
			<MetaDataTag
				label={`${conditions} ${conditions > 1 ? 'conditions' : 'condition'}`}
			/>
		</MetaDataRowLayout>
	)
}
