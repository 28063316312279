import { useCallback } from 'react'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { settleAllOptionGenerators } from '../../../utils/settleAllOptionGenerators'
import { useGetMemberDrpOptions } from '../generators/useGetMemberOptions'
import { useGetOwnerDrpOptions } from './useGetOwnerDrpOptions'

export const useGetAdditionalAttendeesDrpOptions =
	(): AsyncDrpOptionGenerator => {
		const getOwnerDrpOptions = useGetOwnerDrpOptions()
		const getMemberDrpOptions = useGetMemberDrpOptions()

		return useCallback(
			async ({
				onSelect,
			}: {
				onSelect: (value: any) => void
			}): Promise<DataReferencePickerOption[]> => {
				const optionGenerators = [getOwnerDrpOptions, getMemberDrpOptions]

				return await settleAllOptionGenerators(optionGenerators, onSelect)
			},
			[getMemberDrpOptions, getOwnerDrpOptions],
		)
	}
