import { Flex, HStack, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { CodeEditor } from '@/common/components/CodeEditor'
import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

type Props = {
	payload: Record<string, any>
	label?: string
	editable?: boolean
}

type WebhookPayloadProps =
	| (Props & {
			editable: true
			onChange: (payload: string) => void
	  })
	| (Props & {
			editable?: false
			onChange?: never
	  })

export const WebhookPayload = ({
	payload,
	label,
	editable = false,
	onChange,
}: WebhookPayloadProps) => {
	if (!payload) return null
	return (
		<Flex
			flexDir="column"
			p={3}
			borderRadius="12px"
			border={Borders.primary}
			mt={4}
		>
			<HStack justifyContent="space-between" mb={4}>
				<Text variant={TEXT_ENUM.medium14} color={COLORS.background[6]}>
					{label || 'Payload'}
				</Text>
			</HStack>
			<CodeEditor
				value={JSON.stringify(payload, null, 2)}
				editable={editable}
				onChange={onChange}
			/>
		</Flex>
	)
}
