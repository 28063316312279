import { Flex, GridItem, Skeleton } from '@chakra-ui/react'

import { Borders } from '@/styles/borders'

export const ListSkeleton = () => {
	return (
		<GridItem>
			<Flex
				border={Borders.primary}
				bg="white"
				borderRadius="12px"
				w="full"
				p={4}
			>
				<Skeleton flex={1} h={8} borderRadius="md" />
			</Flex>
		</GridItem>
	)
}
