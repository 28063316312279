import { SchedulerTranslationsTokens } from '../tokens'

export const SchedulerSpanishTranslation: Record<
	SchedulerTranslationsTokens,
	string
> = {
	[SchedulerTranslationsTokens.confirm]: 'Confirmar',
	[SchedulerTranslationsTokens.confirm_meeting_header]:
		'¡Tu reunión ha sido programada!',
	[SchedulerTranslationsTokens.confirm_meeting_description]:
		'Le hemos enviado los detalles de la reunión, revise su bandeja de entrada.',
	[SchedulerTranslationsTokens.reschedule_meeting]: 'Reprogramar reunión',
	[SchedulerTranslationsTokens.cancel_meeting]: 'Cancelar reunión',
	[SchedulerTranslationsTokens.confirm_meeting_has_assigned]:
		'ha sido asignado a tu reunión.',
	[SchedulerTranslationsTokens.confirm_meeting_invite_teammates]:
		'Invitar otros usarios',
	[SchedulerTranslationsTokens.confirm_meeting_send_invites]:
		'Enviar invitaciónes',
	[SchedulerTranslationsTokens.confirm_meeting_email_error]:
		'Por favor ingrese correos electrónicos válidos',
	[SchedulerTranslationsTokens.confirm_meeting_error_sending_invites]:
		'¡No se pudieron enviar invitaciones!',
	[SchedulerTranslationsTokens.cancel_meeting_reason]:
		'¿Por qué cancelas esta reunión? (Opcional)',
	[SchedulerTranslationsTokens.cancel_meeting_reason_details]:
		'Comparte algunos detalles aquí',
	[SchedulerTranslationsTokens.cancel_meeting_header]:
		'¡Su reunión ha sido cancelada!',
	[SchedulerTranslationsTokens.cancel_meeting_description]:
		'Informaremos a todos los participantes sobre la cancelación.',
	[SchedulerTranslationsTokens.cancel_by_mistake]: 'Cancelled by mistake?',
	[SchedulerTranslationsTokens.cancel_reschedule_msg]:
		'Puedes reprogramar esta reunión con un nuevo horario.',
	[SchedulerTranslationsTokens.rescheduling_header]: 'Reprogramar reunión',
	[SchedulerTranslationsTokens.keep_original_time]: 'Mantener horario',
	[SchedulerTranslationsTokens.former_time]: 'Horario anterior',
	[SchedulerTranslationsTokens.powered_by]: 'Desarrollado por',

	[SchedulerTranslationsTokens.no_event]: 'No se encontró ningún evento!',
	[SchedulerTranslationsTokens.no_event_active]:
		'Este evento ya no está activo!',
	[SchedulerTranslationsTokens.no_member]: 'No se encuentra ningún miembro!',
	[SchedulerTranslationsTokens.no_queue]: 'No se encuentra ningún grupo!',
	[SchedulerTranslationsTokens.no_queue_active]:
		'Este grupo ya no está activo!',
	[SchedulerTranslationsTokens.no_meeting]: 'No se encontró ningúna reunión!',
	[SchedulerTranslationsTokens.no_time_slots]:
		'¡No hay franjas horarias disponibles!',
	[SchedulerTranslationsTokens.minutes_meeting]: 'min reunión',

	[SchedulerTranslationsTokens.weekdays_short]: 'Dom_Lun_Mar_Mie_Jue_Vie_Sab',
	[SchedulerTranslationsTokens.time_slots]: 'Tiempos',

	[SchedulerTranslationsTokens.morning]: 'Mañana',
	[SchedulerTranslationsTokens.daytime]: 'Día',
	[SchedulerTranslationsTokens.evening]: 'Tarde',

	[SchedulerTranslationsTokens.timeslot_loading]:
		'Obteniendo espacios disponibles...',
	[SchedulerTranslationsTokens.timer_warning]:
		'Reserva dentro de los próximos {delay} minutos para confirmar tu sesión.',
}
