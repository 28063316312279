import { Flex } from '@chakra-ui/react'
import { ColorTokens } from '@ds/tokens/colors'
import { SHADOWS } from '@ds/tokens/shadows'
import React from 'react'
import { Controls } from 'reactflow'
import { Icons } from 'ui'

import { CanvasControlBarButton } from './CanvasControlBarButton'
import { CanvasControlBarZoom } from './CanvasControlBarZoom'

type Props = {
	panOnDrag: boolean
	setPanOnDrag: (panOnDrag: boolean) => void
	zoom: number
	setZoom: (zoom: number) => void
}

export const CanvasControlBar = React.memo(
	({ panOnDrag, setPanOnDrag, zoom, setZoom }: Props) => {
		return (
			<Controls
				showZoom={false}
				showFitView={false}
				showInteractive={false}
				position="bottom-left"
			>
				<Flex
					bg={ColorTokens.white}
					p={2}
					gap={3}
					borderRadius="12px"
					boxShadow={SHADOWS.elevated}
				>
					<CanvasControlBarZoom zoom={zoom} setZoom={setZoom} />
					<CanvasControlBarButton
						selected={panOnDrag}
						icon={Icons.drag_hand}
						onClick={() => {
							setPanOnDrag(true)
						}}
					/>
					<CanvasControlBarButton
						selected={!panOnDrag}
						icon={Icons.location_services}
						onClick={() => {
							setPanOnDrag(false)
						}}
					/>
				</Flex>
			</Controls>
		)
	},
)

CanvasControlBar.displayName = 'CanvasControlBar'
