import { COLORS } from '@ds/tokens/colors'

import { WorkflowIntegrationCategory as IntegrationCateogry } from '@/modules/workflow/utils/mappings'

export const integrationCategoryBackground: {
	[K in IntegrationCateogry]?: string
} = {
	[IntegrationCateogry.Scheduling]: COLORS.gray[10],
	[IntegrationCateogry.Logic]: '#FFAB0F',
	[IntegrationCateogry.Action]: COLORS.purple[9],
	[IntegrationCateogry.AiAction]: 'linear-gradient(to right, #6363F2, #FB25FF)',
	[IntegrationCateogry.Notification]: COLORS.orange[8],
}
