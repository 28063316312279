import { useAppSelector } from '@/app/hooks'

import { selectCurrentCanvasNodes } from '../slice/selectors'
import { LabeledRefNodeValue } from '../types/actions'
import { DefaultAttribute } from '../types/logic'
import { WorkflowIntegrationIds } from '../utils/mappings'

export const useGetParameterLabel = (
	parameter: LabeledRefNodeValue | null,
	parameterAttribute: DefaultAttribute | null,
) => {
	// TO BE DEPRECATED: For legacy field mappings referencing form submission attributes or scheduler attributes
	const canvasNodes = useAppSelector(selectCurrentCanvasNodes)
	if (
		!parameter?.label &&
		(parameter?.refNodeId === 'meeting_booked' ||
			canvasNodes[parameter?.refNodeId || '']?.data.integrationId ===
				WorkflowIntegrationIds.defaultRoute)
	) {
		return 'Meeting booked/Round-Robin owner'
	}
	if (!parameter?.label && parameter?.variable === '$.emailDomain') {
		return 'Domain from email'
	}

	// TODO: Revisit whether this is the best approach to prevent Any from rendering here
	if (!parameter?.label && parameterAttribute?.type === 'generic') {
		return null
	}

	return (
		parameter?.label ||
		parameterAttribute?.name ||
		(parameter?.value && String(parameter.value)) ||
		parameter?.variable ||
		null
	)
}
