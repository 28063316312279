import { cloneDeep } from 'lodash'
import set from 'set-value'

import { SelectOptionType } from '@/common/components/Select/type'
import { getClearbitCountryOptions } from '@/common/utils/getClearbitCountryOptions'
import { getClearbitStateOptions } from '@/common/utils/getClearbitStateOptions'
import { getStateOptions } from '@/common/utils/getStateOptions'
import { ApolloTagType } from '@/types/apollo'

import { CustomLeadDataType, LeadType } from '../api/core/type'
import { LeadStageTable } from '../api/stage/type'

/**
 * All possible lead attributes that we are currently interested in.
 * Treat this enum as the unique id's to identify each attribute.
 *
 * IMPORTANT :- These are accessor keys to the lead object.
 */
export enum LeadAttributes {
	last_submission_id = 'last_submission_at',
	created_at = 'created_at',
	email = 'email',
	tags = 'tags',
	stage_id = 'stage_id',

	// person attributes
	first_name = 'first_name',
	last_name = 'last_name',
	profile = 'profile',
	role = 'role',
	title = 'title',
	phone = 'phone',
	// seniority = 'seniority',
	twitter_handle = 'twitter_handle',
	linkedin_handle = 'linkedin_handle',
	facebook_handle = 'facebook_handle',

	// company attributes
	company = 'company',
	logo = 'logo',
	city = 'city',
	location = 'location',
	website = 'website',
	industry_group = 'industry_group',
	arr = 'arr',
	head_count = 'head_count',
	funding = 'funding',
	company_tags = 'company_tags',

	// NEW
	seniority = 'seniority',

	// Owners
	lead_owners = 'lead_owners',

	person_location = 'person_location',
	person_city = 'person_city',
	person_state = 'person_state',
	person_country = 'person_country',

	company_city = 'company_city',
	company_state = 'company_state',
	company_country = 'company_country',

	traffic_rank = 'traffic_rank',
	sector = 'sector',

	// NEW 2
	industry = 'industry',

	// utms
	original_utm_source = 'original_utm_source',
	latest_utm_source = 'latest_utm_source',
	original_utm_medium = 'original_utm_medium',
	latest_utm_medium = 'latest_utm_medium',
	original_utm_campaign = 'original_utm_campaign',
	latest_utm_campaign = 'latest_utm_campaign',
	original_utm_term = 'original_utm_term',
	latest_utm_term = 'latest_utm_term',
	original_utm_content = 'original_utm_content',
	latest_utm_content = 'latest_utm_content',
	original_referrer = 'original_referrer',
	latest_referrer = 'latest_referrer',
	original_submission_url = 'original_submission_url',
	latest_submission_url = 'latest_submission_url',
	original_landing_url = 'original_landing_url',
	latest_landing_url = 'latest_landing_url',
	original_landing_referrer = 'original_landing_referrer',
	latest_landing_referrer = 'latest_landing_referrer',
}

export type LeadInfoType = {
	accessorKey: LeadAttributes //unique ids
	dbKey: string | null //location of key in nested json of custom data
	label: string
	isTextType: boolean
	isListType: boolean
	isEditable: boolean
	options: SelectOptionType[]
	value?: any
	isUpdated?: boolean
	isLink?: boolean
}

type LeadAttributeMapperType = Record<LeadAttributes, LeadInfoType>

/**
 * We need to know some meta info about a lead attribute for some operations.
 * This helper function returns a mapper object with all the meta info.
 */
export const leadAttributesInfo = (
	lead?: LeadType,
): LeadAttributeMapperType => {
	return {
		[LeadAttributes.last_submission_id]: {
			accessorKey: LeadAttributes.last_submission_id,
			dbKey: null,
			label: 'Last Submission',
			isEditable: false,
			isListType: false,
			isTextType: true,
			value: lead?.last_submission_at,
			options: [],
		},
		[LeadAttributes.created_at]: {
			accessorKey: LeadAttributes.created_at,
			dbKey: null,
			label: 'Created At',
			isEditable: false,
			isListType: false,
			isTextType: true,
			value: lead?.created_at,
			options: [],
		},
		[LeadAttributes.email]: {
			accessorKey: LeadAttributes.email,
			dbKey: 'email',
			label: 'Email',
			isEditable: true,
			isListType: false,
			isTextType: true,
			value: lead?.email,
			options: [],
		},
		[LeadAttributes.first_name]: {
			accessorKey: LeadAttributes.first_name,
			label: 'First Name',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'first_name',
			value: lead?.first_name,
			options: [],
		},
		[LeadAttributes.last_name]: {
			accessorKey: LeadAttributes.last_name,
			label: 'Last Name',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'last_name',
			value: lead?.last_name,
			options: [],
		},
		[LeadAttributes.phone]: {
			accessorKey: LeadAttributes.phone,
			label: 'Phone',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'phone',
			value: lead?.phone,
			options: [],
		},
		[LeadAttributes.profile]: {
			accessorKey: LeadAttributes.profile,
			label: 'Profile',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'extra.avatar',
			value: lead?.profile,
			options: [],
		},
		[LeadAttributes.title]: {
			accessorKey: LeadAttributes.title,
			label: 'Title',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'extra.employment.title',
			value: lead?.title,
			options: [],
		},
		[LeadAttributes.twitter_handle]: {
			accessorKey: LeadAttributes.twitter_handle,
			label: 'Twitter',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'extra.twitter.handle',
			value: lead?.twitter_handle,
			options: [],
			isLink: true,
		},
		[LeadAttributes.linkedin_handle]: {
			accessorKey: LeadAttributes.linkedin_handle,
			label: 'Linkedin',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'extra.linkedin.handle',
			value: lead?.linkedin_handle,
			options: [],
			isLink: true,
		},
		[LeadAttributes.facebook_handle]: {
			accessorKey: LeadAttributes.facebook_handle,
			label: 'Facebook',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'extra.facebook.handle',
			value: lead?.facebook_handle,
			options: [],
			isLink: true,
		},
		[LeadAttributes.stage_id]: {
			accessorKey: LeadAttributes.stage_id,
			label: 'Stage',
			isTextType: false,
			isListType: true,
			isEditable: true,
			dbKey: 'extra.employment.title',
			value: lead?.stage_id,
			options: [],
			isLink: false,
		},
		[LeadAttributes.role]: {
			accessorKey: LeadAttributes.role,
			label: 'Role',
			isTextType: false,
			isListType: true,
			options: getAttributesLabelValue(LeadAttributes.role),
			isEditable: true,
			dbKey: 'extra.employment.role',
			value: lead?.role,
		},
		[LeadAttributes.company]: {
			accessorKey: LeadAttributes.company,
			label: 'Company',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'company.name',
			value: lead?.company,
			options: [],
		},
		[LeadAttributes.website]: {
			accessorKey: LeadAttributes.website,
			label: 'Website',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'company.domain',
			value: lead?.website,
			options: [],
		},

		[LeadAttributes.tags]: {
			accessorKey: LeadAttributes.tags,
			label: 'Tags',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: null,
			value: lead?.tags,
			options: [],
		},

		[LeadAttributes.logo]: {
			accessorKey: LeadAttributes.logo,
			label: 'Logo',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'company.extra.logo',
			value: lead?.logo,
			options: [],
		},
		[LeadAttributes.city]: {
			accessorKey: LeadAttributes.city,
			label: 'City',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'company.extra.geo.city',
			value: lead?.city,
			options: [],
		},
		[LeadAttributes.location]: {
			accessorKey: LeadAttributes.location,
			label: 'Location',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'company.extra.location',
			value: lead?.location,
			options: [],
		},
		[LeadAttributes.industry_group]: {
			accessorKey: LeadAttributes.industry_group,
			label: 'Industry Group',
			isTextType: false,
			isListType: true,
			options: getAttributesLabelValue(LeadAttributes.industry_group),
			isEditable: true,
			dbKey: 'company.extra.category.industryGroup',
			value: lead?.industry_group,
		},
		[LeadAttributes.industry]: {
			accessorKey: LeadAttributes.industry,
			label: 'Industry',
			isTextType: false,
			isListType: true,
			options: getAttributesLabelValue(LeadAttributes.industry),
			isEditable: true,
			dbKey: 'company.extra.category.industry',
			value: null,
		},
		[LeadAttributes.arr]: {
			accessorKey: LeadAttributes.arr,
			label: 'ARR',
			isTextType: false,
			isListType: true,
			options: getAttributesLabelValue(LeadAttributes.arr),
			isEditable: true,
			dbKey: 'company.extra.metrics.estimatedAnnualRevenue',
			value: lead?.arr,
		},
		[LeadAttributes.head_count]: {
			accessorKey: LeadAttributes.head_count,
			label: 'Headcount',
			isTextType: false,
			isListType: true,
			options: getAttributesLabelValue(LeadAttributes.head_count),
			isEditable: true,
			dbKey: 'company.extra.metrics.employeesRange',
			value: lead?.head_count,
		},
		[LeadAttributes.funding]: {
			accessorKey: LeadAttributes.funding,
			label: 'Funding',
			isEditable: true,
			isListType: true,
			isTextType: false,
			options: getAttributesLabelValue(LeadAttributes.funding),
			dbKey: 'company.extra.metrics.raised',
			value: lead?.funding,
		},
		[LeadAttributes.company_tags]: {
			accessorKey: LeadAttributes.company_tags,
			label: 'Company Tags',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'company.extra.tags',
			value: lead?.company_tags && JSON.parse(lead.company_tags).join(', '),
			options: [],
		},
		[LeadAttributes.seniority]: {
			accessorKey: LeadAttributes.seniority,
			label: 'Seniority',
			isTextType: false,
			isListType: true,
			isEditable: true,
			dbKey: 'extra.employment.seniority',
			value: null,
			options: [],
		},
		[LeadAttributes.person_location]: {
			accessorKey: LeadAttributes.person_location,
			label: 'Location',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'extra.location',
			value: null,
			options: [],
		},
		[LeadAttributes.person_city]: {
			accessorKey: LeadAttributes.person_city,
			label: 'City',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'extra.geo.city',
			value: null,
			options: [],
		},
		[LeadAttributes.person_state]: {
			accessorKey: LeadAttributes.person_state,
			label: 'State',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'extra.geo.state',
			value: null,
			options: [],
		},
		[LeadAttributes.person_country]: {
			accessorKey: LeadAttributes.person_country,
			label: 'Country',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'extra.geo.country',
			value: null,
			options: [],
		},
		[LeadAttributes.company_city]: {
			accessorKey: LeadAttributes.company_city,
			label: 'City',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'company.extra.geo.city',
			value: null,
			options: [],
		},
		[LeadAttributes.company_state]: {
			accessorKey: LeadAttributes.company_state,
			label: 'State',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'company.extra.geo.state',
			value: null,
			options: [],
		},
		[LeadAttributes.company_country]: {
			accessorKey: LeadAttributes.company_country,
			label: 'Country',
			isTextType: true,
			isListType: false,
			isEditable: true,
			dbKey: 'company.extra.geo.country',
			value: null,
			options: [],
		},
		[LeadAttributes.traffic_rank]: {
			accessorKey: LeadAttributes.traffic_rank,
			label: 'Traffic Rank',
			isTextType: false,
			isListType: true,
			isEditable: true,
			dbKey: 'company.extra.metrics.trafficRank',
			value: null,
			options: [],
		},
		[LeadAttributes.sector]: {
			accessorKey: LeadAttributes.sector,
			label: 'Sector',
			isTextType: false,
			isListType: true,
			isEditable: true,
			dbKey: 'company.extra.category.sector',
			value: null,
			options: [],
		},
		[LeadAttributes.original_utm_source]: {
			accessorKey: LeadAttributes.original_utm_source,
			label: 'Original UTM Source',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'original_utm_source',
			value: lead?.original_utm_source || '-',
			options: [],
		},
		[LeadAttributes.latest_utm_source]: {
			accessorKey: LeadAttributes.latest_utm_source,
			label: 'Latest UTM Source',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'latest_utm_source',
			value: lead?.latest_utm_source || '-',
			options: [],
		},
		[LeadAttributes.original_utm_medium]: {
			accessorKey: LeadAttributes.original_utm_medium,
			label: 'Original UTM Medium',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'original_utm_medium',
			value: lead?.original_utm_medium || '-',
			options: [],
		},
		[LeadAttributes.latest_utm_medium]: {
			accessorKey: LeadAttributes.latest_utm_medium,
			label: 'Latest UTM Medium',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'latest_utm_medium',
			value: lead?.latest_utm_medium || '-',
			options: [],
		},
		[LeadAttributes.original_utm_campaign]: {
			accessorKey: LeadAttributes.original_utm_campaign,
			label: 'Original UTM Campaign',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'original_utm_campaign',
			value: lead?.original_utm_campaign || '-',
			options: [],
		},
		[LeadAttributes.latest_utm_campaign]: {
			accessorKey: LeadAttributes.latest_utm_campaign,
			label: 'Latest UTM Campaign',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'latest_utm_campaign',
			value: lead?.latest_utm_campaign || '-',
			options: [],
		},
		[LeadAttributes.original_utm_term]: {
			accessorKey: LeadAttributes.original_utm_term,
			label: 'Original UTM Term',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'original_utm_term',
			value: lead?.original_utm_term || '-',
			options: [],
		},
		[LeadAttributes.latest_utm_term]: {
			accessorKey: LeadAttributes.latest_utm_term,
			label: 'Latest UTM Term',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'latest_utm_term',
			value: lead?.latest_utm_term || '-',
			options: [],
		},
		[LeadAttributes.original_utm_content]: {
			accessorKey: LeadAttributes.original_utm_content,
			label: 'Original UTM Content',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'original_utm_content',
			value: lead?.original_utm_content || '-',
			options: [],
		},
		[LeadAttributes.latest_utm_content]: {
			accessorKey: LeadAttributes.latest_utm_content,
			label: 'Latest UTM Content',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'latest_utm_content',
			value: lead?.latest_utm_content || '-',
			options: [],
		},
		[LeadAttributes.original_referrer]: {
			accessorKey: LeadAttributes.original_referrer,
			label: 'Original Referrer',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'original_referrer',
			value: lead?.original_referrer || '-',
			options: [],
		},
		[LeadAttributes.latest_referrer]: {
			accessorKey: LeadAttributes.latest_referrer,
			label: 'Latest Referrer',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'latest_referrer',
			value: lead?.latest_referrer || '-',
			options: [],
		},
		[LeadAttributes.original_submission_url]: {
			accessorKey: LeadAttributes.original_submission_url,
			label: 'Original Submission URL',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'original_submission_url',
			value: lead?.original_submission_url || '-',
			options: [],
		},
		[LeadAttributes.latest_submission_url]: {
			accessorKey: LeadAttributes.latest_submission_url,
			label: 'Latest Submission URL',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'latest_submission_url',
			value: lead?.latest_submission_url || '-',
			options: [],
		},
		[LeadAttributes.original_landing_url]: {
			accessorKey: LeadAttributes.original_landing_url,
			label: 'Original Landing URL',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'original_landing_url',
			value: lead?.original_landing_url || '-',
			options: [],
		},
		[LeadAttributes.latest_landing_url]: {
			accessorKey: LeadAttributes.latest_landing_url,
			label: 'Latest Landing URL',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'latest_landing_url',
			value: lead?.latest_landing_url || '-',
			options: [],
		},
		[LeadAttributes.original_landing_referrer]: {
			accessorKey: LeadAttributes.original_landing_referrer,
			label: 'Original Landing Referrer',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'original_landing_referrer',
			value: lead?.original_landing_referrer || '-',
			options: [],
		},
		[LeadAttributes.latest_landing_referrer]: {
			accessorKey: LeadAttributes.latest_landing_referrer,
			label: 'Latest Landing Referrer',
			isTextType: true,
			isListType: false,
			isEditable: false,
			dbKey: 'latest_landing_referrer',
			value: lead?.latest_landing_referrer || '-',
			options: [],
		},
		[LeadAttributes.lead_owners]: {
			accessorKey: LeadAttributes.lead_owners,
			label: 'Owners',
			isTextType: false,
			isListType: false,
			isEditable: false,
			dbKey: 'owners',
			value: lead?.lead_owners,
			options: [],
		},
	}
}

/**
 * This function is used to build leads details to show on details page.
 */
export const buildLeadDetails = (lead?: LeadType | null): LeadInfoType[] => {
	if (!lead) return []

	const leadInfo = leadAttributesInfo(lead)
	return [
		LeadAttributes.email,
		LeadAttributes.first_name,
		LeadAttributes.last_name,
		LeadAttributes.company,
		LeadAttributes.phone,
		LeadAttributes.role,
		LeadAttributes.title,
		LeadAttributes.location,
		LeadAttributes.website,
		LeadAttributes.arr,
		LeadAttributes.industry_group,
		LeadAttributes.head_count,
		LeadAttributes.company_tags,
		LeadAttributes.twitter_handle,
		LeadAttributes.linkedin_handle,
		LeadAttributes.facebook_handle,
		LeadAttributes.original_utm_source,
		LeadAttributes.latest_utm_source,
		LeadAttributes.original_utm_medium,
		LeadAttributes.latest_utm_medium,
		LeadAttributes.original_utm_campaign,
		LeadAttributes.latest_utm_campaign,
		LeadAttributes.original_utm_term,
		LeadAttributes.latest_utm_term,
		LeadAttributes.original_utm_content,
		LeadAttributes.latest_utm_content,
		LeadAttributes.original_referrer,
		LeadAttributes.latest_referrer,
		LeadAttributes.original_submission_url,
		LeadAttributes.latest_submission_url,
		LeadAttributes.original_landing_url,
		LeadAttributes.latest_landing_url,
		LeadAttributes.original_landing_referrer,
		LeadAttributes.latest_landing_referrer,
	].map((att) => ({ ...leadInfo[att] }))
}

/**
 * This function shapes the lead info into the shape of custom data.
 * That custom data is used to update the lead's custom column.
 */
export const buildCustomLeadData = (
	info: LeadInfoType[],
	custom: CustomLeadDataType,
): CustomLeadDataType => {
	const customData: CustomLeadDataType = cloneDeep(custom) ?? {}

	for (const item of info) {
		if (item.isEditable && item.isUpdated) {
			const key = item.dbKey
			if (key) {
				set(customData, key, item.value)
			}
		}
	}

	return customData
}

/**
 * This function is used to get attributes label and value.
 * We can use this function to get attributes label and value for dropdown.
 */
export const getAttributesLabelValue = (
	attribute: LeadAttributes,
): { label: string; value: string }[] => {
	if (attribute === LeadAttributes.role) {
		return [
			{ label: 'Communications', value: 'communications' },
			{ label: 'Customer Service', value: 'customer_service' },
			{ label: 'Education', value: 'education' },
			{ label: 'Engineering', value: 'engineering' },
			{ label: 'Finance', value: 'finance' },
			{ label: 'Health Professional', value: 'health_professional' },
			{ label: 'Human Resources', value: 'human_resources' },
			{ label: 'Information Technology', value: 'information_technology' },
			{ label: 'Leadership', value: 'leadership' },
			{ label: 'Legal', value: 'legal' },
			{ label: 'Marketing', value: 'marketing' },
			{ label: 'Operations', value: 'operations' },
			{ label: 'Product', value: 'product' },
			{ label: 'Public Relations', value: 'public_relations' },
			{ label: 'Real Estate', value: 'real_estate' },
			{ label: 'Recruiting', value: 'recruiting' },
			{ label: 'Research', value: 'research' },
			{ label: 'Sales', value: 'sales' },
		]
	}

	if (attribute === LeadAttributes.head_count) {
		return [
			'1-10',
			'11-50',
			'51-250',
			'251-1K',
			'1K-5K',
			'5K-10K',
			'10K-50K',
			'50K-100K',
			'100K+',
		].map((v) => ({ label: v, value: v }))
	}

	if (attribute === LeadAttributes.arr) {
		return [
			'$0-$1M',
			'$1M-$10M',
			'$10M-$50M',
			'$50M-$100M',
			'$100M-$250M',
			'$250M-$500M',
			'$500M-$1B',
			'$1B-$10B',
			'$10B+',
		].map((v) => ({ label: v, value: v }))
	}
	if (attribute === LeadAttributes.funding) {
		return [
			'$0-$1M',
			'$1M-$10M',
			'$10M-$50M',
			'$50M-$100M',
			'$100M-$500M',
			'$500M+',
		].map((v) => ({ label: v, value: v }))
	}

	if (attribute === LeadAttributes.industry_group) {
		return [
			'Software & Services',
			'Automobiles & Components',
			'Consumer Discretionary',
			'Consumer Durables & Apparel',
			'Consumer Services',
			'Diversified Consumer Services',
			'Media',
			'Retailing',
			'Consumer Staples',
			'Food & Staples Retailing',
			'Food, Beverage & Tobacco',
			'Household & Personal Products',
			'Energy Equipment & Services',
			'Banks',
			'Diversified Financial Services',
			'Diversified Financials',
			'Insurance',
			'Real Estate',
			'Health Care Equipment & Services',
			'Pharmaceuticals, Biotechnology & Life Sciences',
			'Capital Goods',
			'Commercial & Professional Services',
			'Industrials',
			'Transportation',
			'Semiconductors & Semiconductor Equipment',
			'Technology Hardware & Equipment',
			'Construction Materials',
			'Materials',
			'Telecommunication Services',
			'Independent Power and Renewable Electricity Producers',
			'Utilities',
		].map((v) => ({ label: v, value: v }))
	}

	if (attribute === LeadAttributes.industry) {
		return [
			'Automotive',
			'Consumer Discretionary',
			'Consumer Goods',
			'Household Durables',
			'Leisure Products',
			'Textiles, Apparel & Luxury Goods',
			'Consumer Services',
			'Diversified Consumer Services',
			'Hotels, Restaurants & Leisure',
			'Education Services',
			'Family Services',
			'Specialized Consumer Services',
			'Media',
			'Distributors',
			'Retailing',
			'Specialty Retail',
			'Consumer Staples',
			'Food & Staples Retailing',
			'Beverages',
			'Food Products',
			'Tobacco',
			'Personal Products',
			'Gas Utilities',
			'Banks',
			'Diversified Financial Services',
			'Capital Markets',
			'Insurance',
			'Real Estate',
			'Health Care Equipment & Supplies',
			'Health Care Providers & Services',
			'Biotechnology',
			'Life Sciences Tools & Services',
			'Pharmaceuticals',
			'Aerospace & Defense',
			'Capital Goods',
			'Construction & Engineering',
			'Electrical Equipment',
			'Industrial Conglomerates',
			'Machinery',
			'Trading Companies & Distributors',
			'Commercial Services & Supplies',
			'Professional Services',
			'Industrials',
			'Air Freight & Logistics',
			'Airlines',
			'Marine',
			'Road & Rail',
			'Transportation',
			'Semiconductors & Semiconductor Equipment',
			'Internet Software & Services',
			'IT Services',
			'Software',
			'Communications Equipment',
			'Electronic Equipment, Instruments & Components',
			'Technology Hardware, Storage & Peripherals',
			'Building Materials',
			'Chemicals',
			'Containers & Packaging',
			'Metals & Mining',
			'Paper & Forest Products',
			'Diversified Telecommunication Services',
			'Wireless Telecommunication Services',
			'Renewable Electricity',
			'Electric Utilities',
			'Utilities',
		].map((v) => ({ label: v, value: v }))
	}

	if (attribute === LeadAttributes.traffic_rank) {
		return [
			{ label: 'Very High', value: 'very_high' },
			{ label: 'High', value: 'high' },
			{ label: 'Medium', value: 'medium' },
			{ label: 'Medium Low', value: 'medium_low' },
			{ label: 'Low', value: 'low' },
			{ label: 'Very Low', value: 'very_low' },
		]
	}

	if (attribute === LeadAttributes.seniority) {
		return [
			{ label: 'Manager', value: 'manager' },
			{ label: 'Director', value: 'director' },
			{ label: 'Executive', value: 'executive' },
		]
	}

	if (attribute === LeadAttributes.sector) {
		return [
			'Consumer Discretionary',
			'Consumer Staples',
			'Energy',
			'Financials',
			'Health Care',
			'Industrials',
			'Information Technology',
			'Materials',
			'Telecommunication Services',
			'Utilities',
		].map((v) => ({ label: v, value: v }))
	}

	if (
		[LeadAttributes.person_state, LeadAttributes.company_state].includes(
			attribute,
		)
	) {
		return getClearbitStateOptions()
	}

	if (
		[LeadAttributes.person_country, LeadAttributes.company_country].includes(
			attribute,
		)
	) {
		return getClearbitCountryOptions()
	}

	return []
}

export const getLabelForAttributeValue = (
	value: string,
	attribute?: LeadAttributes,
) => {
	if (!attribute) return value
	if (value === 'undefined') return '-'

	const labelValue = getAttributesLabelValue(attribute).find(
		(v) => v.value === value,
	)
	return labelValue?.label ?? value
}

export const isTextType = (attribute: LeadAttributes) => {
	return (
		attribute === LeadAttributes.email ||
		attribute === LeadAttributes.first_name ||
		attribute === LeadAttributes.last_name ||
		attribute === LeadAttributes.title ||
		attribute === LeadAttributes.city ||
		attribute === LeadAttributes.company ||
		attribute === LeadAttributes.funding ||
		attribute === LeadAttributes.website ||
		attribute === LeadAttributes.location
	)
}

export const isListType = (attribute: LeadAttributes) => {
	return (
		attribute === LeadAttributes.arr ||
		attribute === LeadAttributes.role ||
		attribute === LeadAttributes.industry_group ||
		attribute === LeadAttributes.head_count
	)
}

export const getOptionsForStages = (
	stages?: LeadStageTable[],
): SelectOptionType[] => {
	return (
		stages?.map((stage) => ({
			label: stage.display_name,
			value: stage.internal_name,
		})) || []
	)
}

export const getApolloAttributesLabelValue = (
	apolloAttribute: ApolloAttributes,
): { label: string; value: string }[] => {
	if (apolloAttribute === ApolloAttributes.industry) {
		return APOLLO_INDUSTRIES.map((ind) => ({
			label: ind.cleaned_name,
			value: ind.cleaned_name,
		}))
	}
	if (apolloAttribute === ApolloAttributes.country) {
		return APOLLO_COUNTRIES.map((ind) => ({
			label: ind.cleaned_name,
			value: ind.cleaned_name,
		}))
	}
	if (apolloAttribute === ApolloAttributes.state) {
		return getStateOptions()
	}
	return []
}

export enum ApolloAttributes {
	industry = 'industry',
	country = 'country',
	state = 'state',
}

const APOLLO_INDUSTRIES: ApolloTagType[] = [
	{
		id: '5567cd4773696439b10b0000',
		cleaned_name: 'information technology \u0026 services',
		tag_name_unanalyzed_downcase: 'information technology \u0026 services',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 721810,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.8643984,
		_explanation: null,
	},
	{
		id: '5567cd4773696439dd350000',
		cleaned_name: 'construction',
		tag_name_unanalyzed_downcase: 'construction',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 531470,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.7335744,
		_explanation: null,
	},
	{
		id: '5567cd467369644d39040000',
		cleaned_name: 'marketing \u0026 advertising',
		tag_name_unanalyzed_downcase: 'marketing \u0026 advertising',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 497904,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.7057816,
		_explanation: null,
	},
	{
		id: '5567cd477369645401010000',
		cleaned_name: 'real estate',
		tag_name_unanalyzed_downcase: 'real estate',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 390492,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.6025938,
		_explanation: null,
	},
	{
		id: '5567cddb7369644d250c0000',
		cleaned_name: 'health, wellness \u0026 fitness',
		tag_name_unanalyzed_downcase: 'health, wellness \u0026 fitness',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 340699,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.5449345,
		_explanation: null,
	},
	{
		id: '5567cdd47369643dbf260000',
		cleaned_name: 'management consulting',
		tag_name_unanalyzed_downcase: 'management consulting',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 327912,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.5288036,
		_explanation: null,
	},
	{
		id: '5567cd4e7369643b70010000',
		cleaned_name: 'computer software',
		tag_name_unanalyzed_downcase: 'computer software',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 305264,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.4986744,
		_explanation: null,
	},
	{
		id: '5567cd4d736964397e020000',
		cleaned_name: 'internet',
		tag_name_unanalyzed_downcase: 'internet',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 304994,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.4983022,
		_explanation: null,
	},
	{
		id: '5567ced173696450cb580000',
		cleaned_name: 'retail',
		tag_name_unanalyzed_downcase: 'retail',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 289296,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.4761009,
		_explanation: null,
	},
	{
		id: '5567cdd67369643e64020000',
		cleaned_name: 'financial services',
		tag_name_unanalyzed_downcase: 'financial services',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 285078,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.4699368,
		_explanation: null,
	},
	{
		id: '5567d1127261697f2b1d0000',
		cleaned_name: 'consumer services',
		tag_name_unanalyzed_downcase: 'consumer services',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 282703,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.4664272,
		_explanation: null,
	},
	{
		id: '5567cdde73696439812c0000',
		cleaned_name: 'hospital \u0026 health care',
		tag_name_unanalyzed_downcase: 'hospital \u0026 health care',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 271764,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.4498855,
		_explanation: null,
	},
	{
		id: '5567cdf27369644cfd800000',
		cleaned_name: 'automotive',
		tag_name_unanalyzed_downcase: 'automotive',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 250458,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.4157377,
		_explanation: null,
	},
	{
		id: '5567e0e0736964198de70700',
		cleaned_name: 'restaurants',
		tag_name_unanalyzed_downcase: 'restaurants',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 210742,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.343885,
		_explanation: null,
	},
	{
		id: '5567ce9e736964540d540000',
		cleaned_name: 'education management',
		tag_name_unanalyzed_downcase: 'education management',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 203840,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.330089,
		_explanation: null,
	},
	{
		id: '5567ce1e7369643b806a0000',
		cleaned_name: 'food \u0026 beverages',
		tag_name_unanalyzed_downcase: 'food \u0026 beverages',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 195684,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.3132005,
		_explanation: null,
	},
	{
		id: '5567cdbc73696439d90b0000',
		cleaned_name: 'design',
		tag_name_unanalyzed_downcase: 'design',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 190601,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.3023331,
		_explanation: null,
	},
	{
		id: '5567ce9d7369643bc19c0000',
		cleaned_name: 'hospitality',
		tag_name_unanalyzed_downcase: 'hospitality',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 174263,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.2654381,
		_explanation: null,
	},
	{
		id: '5567ce1f7369643b78570000',
		cleaned_name: 'accounting',
		tag_name_unanalyzed_downcase: 'accounting',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 159060,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.2280408,
		_explanation: null,
	},
	{
		id: '5567cd8e7369645409450000',
		cleaned_name: 'events services',
		tag_name_unanalyzed_downcase: 'events services',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 154350,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.2157696,
		_explanation: null,
	},
	{
		id: '5567cd4773696454303a0000',
		cleaned_name: 'nonprofit organization management',
		tag_name_unanalyzed_downcase: 'nonprofit organization management',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 148896,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.201113,
		_explanation: null,
	},
	{
		id: '5567cdd37369643b80510000',
		cleaned_name: 'entertainment',
		tag_name_unanalyzed_downcase: 'entertainment',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 141775,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.1812003,
		_explanation: null,
	},
	{
		id: '5567cd4c73696439c9030000',
		cleaned_name: 'electrical/electronic manufacturing',
		tag_name_unanalyzed_downcase: 'electrical/electronic manufacturing',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 138493,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.171706,
		_explanation: null,
	},
	{
		id: '5567cdd87369643bc12f0000',
		cleaned_name: 'leisure, travel \u0026 tourism',
		tag_name_unanalyzed_downcase: 'leisure, travel \u0026 tourism',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 137952,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.1701208,
		_explanation: null,
	},
	{
		id: '5567cd49736964541d010000',
		cleaned_name: 'professional training \u0026 coaching',
		tag_name_unanalyzed_downcase: 'professional training \u0026 coaching',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 136653,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.166291,
		_explanation: null,
	},
	{
		id: '5567cd4e7369644cf93b0000',
		cleaned_name: 'transportation/trucking/railroad',
		tag_name_unanalyzed_downcase: 'transportation/trucking/railroad',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 126539,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.1352566,
		_explanation: null,
	},
	{
		id: '5567ce1f7369644d391c0000',
		cleaned_name: 'law practice',
		tag_name_unanalyzed_downcase: 'law practice',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 122972,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.1237602,
		_explanation: null,
	},
	{
		id: '5567cd82736964540d0b0000',
		cleaned_name: 'apparel \u0026 fashion',
		tag_name_unanalyzed_downcase: 'apparel \u0026 fashion',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 122937,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.1236459,
		_explanation: null,
	},
	{
		id: '5567cdb77369645401080000',
		cleaned_name: 'architecture \u0026 planning',
		tag_name_unanalyzed_downcase: 'architecture \u0026 planning',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 120834,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.1167206,
		_explanation: null,
	},
	{
		id: '5567ce2673696453d95c0000',
		cleaned_name: 'mechanical or industrial engineering',
		tag_name_unanalyzed_downcase: 'mechanical or industrial engineering',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 119210,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.111296,
		_explanation: null,
	},
	{
		id: '5567cdd973696453d93f0000',
		cleaned_name: 'insurance',
		tag_name_unanalyzed_downcase: 'insurance',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 116915,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.103513,
		_explanation: null,
	},
	{
		id: '5567cd4c7369644d39080000',
		cleaned_name: 'telecommunications',
		tag_name_unanalyzed_downcase: 'telecommunications',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 111199,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.0834991,
		_explanation: null,
	},
	{
		id: '5567e0e37369640e5ac10c00',
		cleaned_name: 'human resources',
		tag_name_unanalyzed_downcase: 'human resources',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 109546,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.077535,
		_explanation: null,
	},
	{
		id: '5567e09973696410db020800',
		cleaned_name: 'staffing \u0026 recruiting',
		tag_name_unanalyzed_downcase: 'staffing \u0026 recruiting',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 105366,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.0620779,
		_explanation: null,
	},
	{
		id: '5567ce227369644eed290000',
		cleaned_name: 'sports',
		tag_name_unanalyzed_downcase: 'sports',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 104265,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.0579132,
		_explanation: null,
	},
	{
		id: '5567ce2d7369644d25250000',
		cleaned_name: 'legal services',
		tag_name_unanalyzed_downcase: 'legal services',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 102711,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.0519663,
		_explanation: null,
	},
	{
		id: '5567cdd97369645624020000',
		cleaned_name: 'oil \u0026 energy',
		tag_name_unanalyzed_downcase: 'oil \u0026 energy',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 101398,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.0468774,
		_explanation: null,
	},
	{
		id: '5567e0ea7369640d2ba31600',
		cleaned_name: 'media production',
		tag_name_unanalyzed_downcase: 'media production',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 100429,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.0430831,
		_explanation: null,
	},
	{
		id: '5567cd4973696439d53c0000',
		cleaned_name: 'machinery',
		tag_name_unanalyzed_downcase: 'machinery',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 100171,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.0420673,
		_explanation: null,
	},
	{
		id: '5567d01e73696457ee100000',
		cleaned_name: 'wholesale',
		tag_name_unanalyzed_downcase: 'wholesale',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 94677,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.0198512,
		_explanation: null,
	},
	{
		id: '5567ce987369643b789e0000',
		cleaned_name: 'consumer goods',
		tag_name_unanalyzed_downcase: 'consumer goods',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 93184,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.0136124,
		_explanation: null,
	},
	{
		id: '5567cd4f736964540d050000',
		cleaned_name: 'music',
		tag_name_unanalyzed_downcase: 'music',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 92813,
		num_people: 0,
		_index_type: 'tag',
		_score: 1.012048,
		_explanation: null,
	},
	{
		id: '5567cd4f7369644cfd250000',
		cleaned_name: 'photography',
		tag_name_unanalyzed_downcase: 'photography',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 87295,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.9880905,
		_explanation: null,
	},
	{
		id: '5567d0467369645dbc200000',
		cleaned_name: 'medical practice',
		tag_name_unanalyzed_downcase: 'medical practice',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 85865,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.98166007,
		_explanation: null,
	},
	{
		id: '5567e1ae73696423dc040000',
		cleaned_name: 'cosmetics',
		tag_name_unanalyzed_downcase: 'cosmetics',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 81305,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.9604946,
		_explanation: null,
	},
	{
		id: '5567ce5b736964540d280000',
		cleaned_name: 'environmental services',
		tag_name_unanalyzed_downcase: 'environmental services',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 79858,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.9535567,
		_explanation: null,
	},
	{
		id: '5567cd4d73696439d9040000',
		cleaned_name: 'graphic design',
		tag_name_unanalyzed_downcase: 'graphic design',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 77031,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.93967396,
		_explanation: null,
	},
	{
		id: '5567e0fa73696410e4c51200',
		cleaned_name: 'business supplies \u0026 equipment',
		tag_name_unanalyzed_downcase: 'business supplies \u0026 equipment',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 75523,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.9320829,
		_explanation: null,
	},
	{
		id: '5567cd49736964540d020000',
		cleaned_name: 'renewables \u0026 environment',
		tag_name_unanalyzed_downcase: 'renewables \u0026 environment',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 74529,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.9270057,
		_explanation: null,
	},
	{
		id: '5567ce9c7369643bc9980000',
		cleaned_name: 'facilities services',
		tag_name_unanalyzed_downcase: 'facilities services',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 72938,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.91875356,
		_explanation: null,
	},
	{
		id: '5567ce5b73696439a17a0000',
		cleaned_name: 'publishing',
		tag_name_unanalyzed_downcase: 'publishing',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 71491,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.9111096,
		_explanation: null,
	},
	{
		id: '5567e1b3736964208b280000',
		cleaned_name: 'food production',
		tag_name_unanalyzed_downcase: 'food production',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 71024,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.9086137,
		_explanation: null,
	},
	{
		id: '5567cd4d73696439d9030000',
		cleaned_name: 'arts \u0026 crafts',
		tag_name_unanalyzed_downcase: 'arts \u0026 crafts',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 70247,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.9044288,
		_explanation: null,
	},
	{
		id: '5567e1a17369641ea9d30100',
		cleaned_name: 'building materials',
		tag_name_unanalyzed_downcase: 'building materials',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 66996,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.8864682,
		_explanation: null,
	},
	{
		id: '5567e13a73696418756e0200',
		cleaned_name: 'civil engineering',
		tag_name_unanalyzed_downcase: 'civil engineering',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 65655,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.87883765,
		_explanation: null,
	},
	{
		id: '5567e0f27369640e5aed0c00',
		cleaned_name: 'religious institutions',
		tag_name_unanalyzed_downcase: 'religious institutions',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 63938,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.8688677,
		_explanation: null,
	},
	{
		id: '5567ce5973696453d9780000',
		cleaned_name: 'public relations \u0026 communications',
		tag_name_unanalyzed_downcase: 'public relations \u0026 communications',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 60403,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.84759116,
		_explanation: null,
	},
	{
		id: '5567cd4c73696453e1300000',
		cleaned_name: 'higher education',
		tag_name_unanalyzed_downcase: 'higher education',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 59989,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.8450298,
		_explanation: null,
	},
	{
		id: '5567cd4d7369644d513e0000',
		cleaned_name: 'printing',
		tag_name_unanalyzed_downcase: 'printing',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 59956,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.84482497,
		_explanation: null,
	},
	{
		id: '5567cede73696440d0040000',
		cleaned_name: 'furniture',
		tag_name_unanalyzed_downcase: 'furniture',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 59535,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.84220344,
		_explanation: null,
	},
	{
		id: '5567e3f3736964395d7a0000',
		cleaned_name: 'mining \u0026 metals',
		tag_name_unanalyzed_downcase: 'mining \u0026 metals',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 59215,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.8402002,
		_explanation: null,
	},
	{
		id: '5567cd4973696439b9010000',
		cleaned_name: 'logistics \u0026 supply chain',
		tag_name_unanalyzed_downcase: 'logistics \u0026 supply chain',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 57579,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.82981175,
		_explanation: null,
	},
	{
		id: '5567e09f736964160ebb0100',
		cleaned_name: 'research',
		tag_name_unanalyzed_downcase: 'research',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 57071,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.82653475,
		_explanation: null,
	},
	{
		id: '5567e0eb73696410e4bd1200',
		cleaned_name: 'pharmaceuticals',
		tag_name_unanalyzed_downcase: 'pharmaceuticals',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 56909,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.8254845,
		_explanation: null,
	},
	{
		id: '5567d02b7369645d8b140000',
		cleaned_name: 'individual \u0026 family services',
		tag_name_unanalyzed_downcase: 'individual \u0026 family services',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 56276,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.8213563,
		_explanation: null,
	},
	{
		id: '5567e1b97369641ea9690200',
		cleaned_name: 'medical devices',
		tag_name_unanalyzed_downcase: 'medical devices',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 54465,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.80932397,
		_explanation: null,
	},
	{
		id: '5567cdda7369644eed130000',
		cleaned_name: 'civic \u0026 social organization',
		tag_name_unanalyzed_downcase: 'civic \u0026 social organization',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 53178,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.80056584,
		_explanation: null,
	},
	{
		id: '5567e19c7369641c48e70100',
		cleaned_name: 'e-learning',
		tag_name_unanalyzed_downcase: 'e-learning',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 52673,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.79708046,
		_explanation: null,
	},
	{
		id: '5567e19b7369641ead740000',
		cleaned_name: 'security \u0026 investigations',
		tag_name_unanalyzed_downcase: 'security \u0026 investigations',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 51116,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.7861549,
		_explanation: null,
	},
	{
		id: '5567e21e73696426a1030000',
		cleaned_name: 'chemicals',
		tag_name_unanalyzed_downcase: 'chemicals',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 49343,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.7733695,
		_explanation: null,
	},
	{
		id: '5567cd527369643981050000',
		cleaned_name: 'government administration',
		tag_name_unanalyzed_downcase: 'government administration',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 47906,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.76272357,
		_explanation: null,
	},
	{
		id: '5567cdb373696439dd540000',
		cleaned_name: 'online media',
		tag_name_unanalyzed_downcase: 'online media',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 47701,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.7611833,
		_explanation: null,
	},
	{
		id: '5567e0bc7369641d11550200',
		cleaned_name: 'investment management',
		tag_name_unanalyzed_downcase: 'investment management',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 46912,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.75520384,
		_explanation: null,
	},
	{
		id: '5567cd4f7369644d2d010000',
		cleaned_name: 'farming',
		tag_name_unanalyzed_downcase: 'farming',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 46756,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.75401175,
		_explanation: null,
	},
	{
		id: '5567cdd973696439a1370000',
		cleaned_name: 'writing \u0026 editing',
		tag_name_unanalyzed_downcase: 'writing \u0026 editing',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 46665,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.7533149,
		_explanation: null,
	},
	{
		id: '5567e1327369641d91ce0300',
		cleaned_name: 'textiles',
		tag_name_unanalyzed_downcase: 'textiles',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 46251,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.75013024,
		_explanation: null,
	},
	{
		id: '5567ce2773696454308f0000',
		cleaned_name: 'mental health care',
		tag_name_unanalyzed_downcase: 'mental health care',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 44294,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.7347518,
		_explanation: null,
	},
	{
		id: '5567cdd97369645430680000',
		cleaned_name: 'primary/secondary education',
		tag_name_unanalyzed_downcase: 'primary/secondary education',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 44065,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.7329162,
		_explanation: null,
	},
	{
		id: '5567e0f973696416d34e0200',
		cleaned_name: 'broadcast media',
		tag_name_unanalyzed_downcase: 'broadcast media',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 42098,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.716821,
		_explanation: null,
	},
	{
		id: '5567d08e7369645dbc4b0000',
		cleaned_name: 'biotechnology',
		tag_name_unanalyzed_downcase: 'biotechnology',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 41577,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.71245605,
		_explanation: null,
	},
	{
		id: '5567e0c97369640d2b3b1600',
		cleaned_name: 'information services',
		tag_name_unanalyzed_downcase: 'information services',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 41322,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.71030354,
		_explanation: null,
	},
	{
		id: '5567ce9c7369644eed680000',
		cleaned_name: 'international trade \u0026 development',
		tag_name_unanalyzed_downcase: 'international trade \u0026 development',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 39609,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.69556046,
		_explanation: null,
	},
	{
		id: '5567cdd7736964540d130000',
		cleaned_name: 'motion pictures \u0026 film',
		tag_name_unanalyzed_downcase: 'motion pictures \u0026 film',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 39352,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.6933048,
		_explanation: null,
	},
	{
		id: '5567e1947369641ead570000',
		cleaned_name: 'consumer electronics',
		tag_name_unanalyzed_downcase: 'consumer electronics',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 39196,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.69192976,
		_explanation: null,
	},
	{
		id: '5567ce237369644ee5490000',
		cleaned_name: 'banking',
		tag_name_unanalyzed_downcase: 'banking',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 38990,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.6901074,
		_explanation: null,
	},
	{
		id: '5567ce9d7369645430c50000',
		cleaned_name: 'import \u0026 export',
		tag_name_unanalyzed_downcase: 'import \u0026 export',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 38876,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.6890957,
		_explanation: null,
	},
	{
		id: '5567e1337369641ad2970000',
		cleaned_name: 'industrial automation',
		tag_name_unanalyzed_downcase: 'industrial automation',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 38677,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.6873238,
		_explanation: null,
	},
	{
		id: '5567e134736964214f5e0000',
		cleaned_name: 'recreational facilities \u0026 services',
		tag_name_unanalyzed_downcase: 'recreational facilities \u0026 services',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 36796,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.67020875,
		_explanation: null,
	},
	{
		id: '5567e0af7369641ec7300000',
		cleaned_name: 'performing arts',
		tag_name_unanalyzed_downcase: 'performing arts',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 35169,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.65484047,
		_explanation: null,
	},
	{
		id: '5567e2127369642420170000',
		cleaned_name: 'utilities',
		tag_name_unanalyzed_downcase: 'utilities',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 34656,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.6498798,
		_explanation: null,
	},
	{
		id: '5567e113736964198d5e0800',
		cleaned_name: 'sporting goods',
		tag_name_unanalyzed_downcase: 'sporting goods',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 33385,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.6373396,
		_explanation: null,
	},
	{
		id: '5567e2097369642420150000',
		cleaned_name: 'fine art',
		tag_name_unanalyzed_downcase: 'fine art',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 33225,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.635735,
		_explanation: null,
	},
	{
		id: '5567e0bf7369641d115f0200',
		cleaned_name: 'airlines/aviation',
		tag_name_unanalyzed_downcase: 'airlines/aviation',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 31674,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.6198652,
		_explanation: null,
	},
	{
		id: '5567cd877369644cf94b0000',
		cleaned_name: 'computer \u0026 network security',
		tag_name_unanalyzed_downcase: 'computer \u0026 network security',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 30733,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.60994637,
		_explanation: null,
	},
	{
		id: '5567cd8273696439b1240000',
		cleaned_name: 'maritime',
		tag_name_unanalyzed_downcase: 'maritime',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 30524,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.60771227,
		_explanation: null,
	},
	{
		id: '5567cda97369644cfd3e0000',
		cleaned_name: 'luxury goods \u0026 jewelry',
		tag_name_unanalyzed_downcase: 'luxury goods \u0026 jewelry',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 30365,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.60600495,
		_explanation: null,
	},
	{
		id: '5567ce9673696439d5c10000',
		cleaned_name: 'veterinary',
		tag_name_unanalyzed_downcase: 'veterinary',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 29361,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.59506613,
		_explanation: null,
	},
	{
		id: '5567e1587369641c48370000',
		cleaned_name: 'venture capital \u0026 private equity',
		tag_name_unanalyzed_downcase: 'venture capital \u0026 private equity',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 28483,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.5852689,
		_explanation: null,
	},
	{
		id: '5567cd4d7369643b78100000',
		cleaned_name: 'wine \u0026 spirits',
		tag_name_unanalyzed_downcase: 'wine \u0026 spirits',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 28389,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.5842068,
		_explanation: null,
	},
	{
		id: '5567cdda7369644cf95d0000',
		cleaned_name: 'plastics',
		tag_name_unanalyzed_downcase: 'plastics',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 27718,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.57654864,
		_explanation: null,
	},
	{
		id: '5567e0dd73696416d3c20100',
		cleaned_name: 'aviation \u0026 aerospace',
		tag_name_unanalyzed_downcase: 'aviation \u0026 aerospace',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 26849,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.5664257,
		_explanation: null,
	},
	{
		id: '5567e1887369641d68d40100',
		cleaned_name: 'commercial real estate',
		tag_name_unanalyzed_downcase: 'commercial real estate',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 26419,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.561328,
		_explanation: null,
	},
	{
		id: '5567cd8b736964540d0f0000',
		cleaned_name: 'computer games',
		tag_name_unanalyzed_downcase: 'computer games',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 26302,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.55993056,
		_explanation: null,
	},
	{
		id: '5567e36973696431a4480000',
		cleaned_name: 'packaging \u0026 containers',
		tag_name_unanalyzed_downcase: 'packaging \u0026 containers',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 24602,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.5391012,
		_explanation: null,
	},
	{
		id: '5567e09473696410dbf00700',
		cleaned_name: 'executive office',
		tag_name_unanalyzed_downcase: 'executive office',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 23868,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.52978957,
		_explanation: null,
	},
	{
		id: '5567e0d47369641233eb0600',
		cleaned_name: 'computer hardware',
		tag_name_unanalyzed_downcase: 'computer hardware',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 23558,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.52579606,
		_explanation: null,
	},
	{
		id: '5567cdbe7369643b78360000',
		cleaned_name: 'computer networking',
		tag_name_unanalyzed_downcase: 'computer networking',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 22516,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.5120971,
		_explanation: null,
	},
	{
		id: '5567e1387369641ec75d0200',
		cleaned_name: 'market research',
		tag_name_unanalyzed_downcase: 'market research',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 22213,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.5080312,
		_explanation: null,
	},
	{
		id: '5567d04173696457ee520000',
		cleaned_name: 'outsourcing/offshoring',
		tag_name_unanalyzed_downcase: 'outsourcing/offshoring',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 21441,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.49749634,
		_explanation: null,
	},
	{
		id: '5567e2907369642433e60200',
		cleaned_name: 'program development',
		tag_name_unanalyzed_downcase: 'program development',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 18734,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.45839608,
		_explanation: null,
	},
	{
		id: '5567e1097369641d91230300',
		cleaned_name: 'translation \u0026 localization',
		tag_name_unanalyzed_downcase: 'translation \u0026 localization',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 17749,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.44324732,
		_explanation: null,
	},
	{
		id: '5567ce9673696453d99f0000',
		cleaned_name: 'philanthropy',
		tag_name_unanalyzed_downcase: 'philanthropy',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 17586,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.44068873,
		_explanation: null,
	},
	{
		id: '5567cd4a7369643ba9010000',
		cleaned_name: 'public safety',
		tag_name_unanalyzed_downcase: 'public safety',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 17468,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.43882704,
		_explanation: null,
	},
	{
		id: '5567e27c7369642ade490000',
		cleaned_name: 'alternative medicine',
		tag_name_unanalyzed_downcase: 'alternative medicine',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 16330,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.42045087,
		_explanation: null,
	},
	{
		id: '5567e15373696422aa0a0000',
		cleaned_name: 'museums \u0026 institutions',
		tag_name_unanalyzed_downcase: 'museums \u0026 institutions',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 16312,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.42015386,
		_explanation: null,
	},
	{
		id: '5567e127736964181e700200',
		cleaned_name: 'warehousing',
		tag_name_unanalyzed_downcase: 'warehousing',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 16202,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.41833442,
		_explanation: null,
	},
	{
		id: '5567e1097369641b5f810500',
		cleaned_name: 'defense \u0026 space',
		tag_name_unanalyzed_downcase: 'defense \u0026 space',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 16111,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.4168235,
		_explanation: null,
	},
	{
		id: '5567cd4a73696439a9010000',
		cleaned_name: 'newspapers',
		tag_name_unanalyzed_downcase: 'newspapers',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 16027,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.4154241,
		_explanation: null,
	},
	{
		id: '5567e97f7369641e57730100',
		cleaned_name: 'paper \u0026 forest products',
		tag_name_unanalyzed_downcase: 'paper \u0026 forest products',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 15309,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.40327498,
		_explanation: null,
	},
	{
		id: '5567e0e073696408da441e00',
		cleaned_name: 'law enforcement',
		tag_name_unanalyzed_downcase: 'law enforcement',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 15113,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.3998986,
		_explanation: null,
	},
	{
		id: '5567e1ab7369641f6d660100',
		cleaned_name: 'investment banking',
		tag_name_unanalyzed_downcase: 'investment banking',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 14672,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.39220434,
		_explanation: null,
	},
	{
		id: '5567e29b736964256c370100',
		cleaned_name: 'government relations',
		tag_name_unanalyzed_downcase: 'government relations',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 13874,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.3779252,
		_explanation: null,
	},
	{
		id: '5567d2ad7261697f2b1f0100',
		cleaned_name: 'fund-raising',
		tag_name_unanalyzed_downcase: 'fund-raising',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 13828,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.3770876,
		_explanation: null,
	},
	{
		id: '5567e1de7369642069ea0100',
		cleaned_name: 'think tanks',
		tag_name_unanalyzed_downcase: 'think tanks',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 12783,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.3576109,
		_explanation: null,
	},
	{
		id: '5567cd4f736964397e030000',
		cleaned_name: 'glass, ceramics \u0026 concrete',
		tag_name_unanalyzed_downcase: 'glass, ceramics \u0026 concrete',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 11631,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.3350766,
		_explanation: null,
	},
	{
		id: '5567cdb773696439a9080000',
		cleaned_name: 'capital markets',
		tag_name_unanalyzed_downcase: 'capital markets',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 11397,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.33035287,
		_explanation: null,
	},
	{
		id: '5567e0d87369640e5aa30c00',
		cleaned_name: 'semiconductors',
		tag_name_unanalyzed_downcase: 'semiconductors',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 11120,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.32469392,
		_explanation: null,
	},
	{
		id: '5567e36f73696431a4970000',
		cleaned_name: 'animation',
		tag_name_unanalyzed_downcase: 'animation',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 10865,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.31941837,
		_explanation: null,
	},
	{
		id: '5567e25f736964256cff0000',
		cleaned_name: 'political organization',
		tag_name_unanalyzed_downcase: 'political organization',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 10726,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.31651548,
		_explanation: null,
	},
	{
		id: '5567e8bb7369641a658f0000',
		cleaned_name: 'package/freight delivery',
		tag_name_unanalyzed_downcase: 'package/freight delivery',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 10003,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.30109513,
		_explanation: null,
	},
	{
		id: '5567e3ca736964371b130000',
		cleaned_name: 'wireless',
		tag_name_unanalyzed_downcase: 'wireless',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 9966,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.30029106,
		_explanation: null,
	},
	{
		id: '5567e3657369642f4ec90000',
		cleaned_name: 'international affairs',
		tag_name_unanalyzed_downcase: 'international affairs',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 9942,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.29976872,
		_explanation: null,
	},
	{
		id: '5567e28a7369642ae2500000',
		cleaned_name: 'public policy',
		tag_name_unanalyzed_downcase: 'public policy',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 9652,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.29340675,
		_explanation: null,
	},
	{
		id: '556808697369647bfd420000',
		cleaned_name: 'libraries',
		tag_name_unanalyzed_downcase: 'libraries',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 8306,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.26259345,
		_explanation: null,
	},
	{
		id: '5567e0cf7369641233e50600',
		cleaned_name: 'gambling \u0026 casinos',
		tag_name_unanalyzed_downcase: 'gambling \u0026 casinos',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 7816,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.2508102,
		_explanation: null,
	},
	{
		id: '5567e14673696416d38c0300',
		cleaned_name: 'railroad manufacture',
		tag_name_unanalyzed_downcase: 'railroad manufacture',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 6500,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.21748394,
		_explanation: null,
	},
	{
		id: '5567fd5a73696442b0f20000',
		cleaned_name: 'ranching',
		tag_name_unanalyzed_downcase: 'ranching',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 6361,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.21380983,
		_explanation: null,
	},
	{
		id: '5567e2c572616932bb3b0000',
		cleaned_name: 'military',
		tag_name_unanalyzed_downcase: 'military',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 6219,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.21002407,
		_explanation: null,
	},
	{
		id: '5567f96c7369642a22080000',
		cleaned_name: 'fishery',
		tag_name_unanalyzed_downcase: 'fishery',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 6008,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.20433708,
		_explanation: null,
	},
	{
		id: '5567e2a97369642a553d0000',
		cleaned_name: 'supermarkets',
		tag_name_unanalyzed_downcase: 'supermarkets',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 5680,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.19534606,
		_explanation: null,
	},
	{
		id: '5567e8a27369646ddb0b0000',
		cleaned_name: 'dairy',
		tag_name_unanalyzed_downcase: 'dairy',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 5677,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.19526295,
		_explanation: null,
	},
	{
		id: '55680085736964551e070000',
		cleaned_name: 'tobacco',
		tag_name_unanalyzed_downcase: 'tobacco',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 5608,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.19334725,
		_explanation: null,
	},
	{
		id: '5568047d7369646d406c0000',
		cleaned_name: 'shipbuilding',
		tag_name_unanalyzed_downcase: 'shipbuilding',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 5408,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.18774627,
		_explanation: null,
	},
	{
		id: '55680a8273696407b61f0000',
		cleaned_name: 'judiciary',
		tag_name_unanalyzed_downcase: 'judiciary',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 5233,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.18278544,
		_explanation: null,
	},
	{
		id: '5567e1a87369641f6d550100',
		cleaned_name: 'alternative dispute resolution',
		tag_name_unanalyzed_downcase: 'alternative dispute resolution',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 4170,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.15136984,
		_explanation: null,
	},
	{
		id: '5567e7be736964110e210000',
		cleaned_name: 'nanotechnology',
		tag_name_unanalyzed_downcase: 'nanotechnology',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 3565,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.1324198,
		_explanation: null,
	},
	{
		id: '55718f947369642142b84a12',
		cleaned_name: 'agriculture',
		tag_name_unanalyzed_downcase: 'agriculture',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 1425,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.057856206,
		_explanation: null,
	},
	{
		id: '5567e1797369641c48c10100',
		cleaned_name: 'legislative office',
		tag_name_unanalyzed_downcase: 'legislative office',
		parent_tag_id: null,
		uid: null,
		kind: 'linkedin_industry',
		has_children: false,
		category: null,
		tag_category_downcase: null,
		num_organizations: 762,
		num_people: 0,
		_index_type: 'tag',
		_score: 0.031892985,
		_explanation: null,
	},
]

const APOLLO_COUNTRIES: ApolloTagType[] = [
	{
		id: '57b27ba8122e5a5424ce9787',
		cleaned_name: 'Afghanistan',
		tag_name_unanalyzed_downcase: 'afghanistan',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 425,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.018,
		_explanation: null,
	},
	{
		id: '57b27ba8122e5a5424ce9788',
		cleaned_name: 'Albania',
		tag_name_unanalyzed_downcase: 'albania',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 0,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b28121f3e5bb634182af61',
		cleaned_name: 'Algeria',
		tag_name_unanalyzed_downcase: 'algeria',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 78,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0034,
		_explanation: null,
	},
	{
		id: '57b2887ff3e5bb63149f010d',
		cleaned_name: 'Andorra',
		tag_name_unanalyzed_downcase: 'andorra',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 4,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27fdbf3e5bb59a5a03920',
		cleaned_name: 'Angola',
		tag_name_unanalyzed_downcase: 'angola',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 99,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0044,
		_explanation: null,
	},
	{
		id: '57b27ba9122e5a5424ce9789',
		cleaned_name: 'Argentina',
		tag_name_unanalyzed_downcase: 'argentina',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 4231,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.1533,
		_explanation: null,
	},
	{
		id: '57b281e8f3e5bb631ca49bd1',
		cleaned_name: 'Armenia',
		tag_name_unanalyzed_downcase: 'armenia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 13,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27ba9122e5a5424ce978a',
		cleaned_name: 'Australia',
		tag_name_unanalyzed_downcase: 'australia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 31930,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.6226,
		_explanation: null,
	},
	{
		id: '57b27ba9122e5a5424ce978b',
		cleaned_name: 'Austria',
		tag_name_unanalyzed_downcase: 'austria',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 4941,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.1743,
		_explanation: null,
	},
	{
		id: '57b2816ff3e5bb634182b0cf',
		cleaned_name: 'Azerbaijan',
		tag_name_unanalyzed_downcase: 'azerbaijan',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 19,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.001,
		_explanation: null,
	},
	{
		id: '57b28294f3e5bb631ca49dcf',
		cleaned_name: 'Bahrain',
		tag_name_unanalyzed_downcase: 'bahrain',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 30,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0015,
		_explanation: null,
	},
	{
		id: '57b27baa122e5a5424ce978c',
		cleaned_name: 'Bangladesh',
		tag_name_unanalyzed_downcase: 'bangladesh',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 415,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0176,
		_explanation: null,
	},
	{
		id: '57b28072f3e5bb6321204621',
		cleaned_name: 'Barbados',
		tag_name_unanalyzed_downcase: 'barbados',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 15,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27baa122e5a5424ce978d',
		cleaned_name: 'Belarus',
		tag_name_unanalyzed_downcase: 'belarus',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 62,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0024,
		_explanation: null,
	},
	{
		id: '57b27baa122e5a5424ce978e',
		cleaned_name: 'Belgium',
		tag_name_unanalyzed_downcase: 'belgium',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 38348,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.6846,
		_explanation: null,
	},
	{
		id: '57b28369f3e5bb631ca49fd7',
		cleaned_name: 'Belize',
		tag_name_unanalyzed_downcase: 'belize',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 3,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bab122e5a5424ce978f',
		cleaned_name: 'Bolivia',
		tag_name_unanalyzed_downcase: 'bolivia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 125,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0054,
		_explanation: null,
	},
	{
		id: '57b27bab122e5a5424ce9790',
		cleaned_name: 'Bosnia and Herzegovina',
		tag_name_unanalyzed_downcase: 'bosnia and herzegovina',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 6,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b283f9f3e5bb6325a8d948',
		cleaned_name: 'Botswana',
		tag_name_unanalyzed_downcase: 'botswana',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 6,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27bab122e5a5424ce9791',
		cleaned_name: 'Brazil',
		tag_name_unanalyzed_downcase: 'brazil',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 32725,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.631,
		_explanation: null,
	},
	{
		id: '57b281f8f3e5bb6321204e36',
		cleaned_name: 'Brunei',
		tag_name_unanalyzed_downcase: 'brunei',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 10,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27bac122e5a5424ce9792',
		cleaned_name: 'Bulgaria',
		tag_name_unanalyzed_downcase: 'bulgaria',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 605,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0254,
		_explanation: null,
	},
	{
		id: '57b28303f3e5bb6325a8d744',
		cleaned_name: 'Burundi',
		tag_name_unanalyzed_downcase: 'burundi',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 6,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27bac122e5a5424ce9793',
		cleaned_name: 'Cambodia',
		tag_name_unanalyzed_downcase: 'cambodia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 69,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.003,
		_explanation: null,
	},
	{
		id: '57b28192f3e5bb6321204ceb',
		cleaned_name: 'Cameroon',
		tag_name_unanalyzed_downcase: 'cameroon',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 15,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27ba8122e5a5424ce9786',
		cleaned_name: 'Canada',
		tag_name_unanalyzed_downcase: 'canada',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 67936,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.8916,
		_explanation: null,
	},
	{
		id: '57b285b4f3e5bb63318728a2',
		cleaned_name: 'Central African Republic',
		tag_name_unanalyzed_downcase: 'central african republic',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 3,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bac122e5a5424ce9794',
		cleaned_name: 'Chad',
		tag_name_unanalyzed_downcase: 'chad',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 4,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bad122e5a5424ce9795',
		cleaned_name: 'Chile',
		tag_name_unanalyzed_downcase: 'chile',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 475,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.02,
		_explanation: null,
	},
	{
		id: '57b27bad122e5a5424ce9796',
		cleaned_name: 'China',
		tag_name_unanalyzed_downcase: 'china',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 91821,
		num_people: 1,
		_index_type: 'tag',
		_score: 5001.008,
		_explanation: null,
	},
	{
		id: '57b27bad122e5a5424ce9797',
		cleaned_name: 'Colombia',
		tag_name_unanalyzed_downcase: 'colombia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 10691,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.316,
		_explanation: null,
	},
	{
		id: '57b27bae122e5a5424ce9798',
		cleaned_name: 'Costa Rica',
		tag_name_unanalyzed_downcase: 'costa rica',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 582,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0244,
		_explanation: null,
	},
	{
		id: '57b27bae122e5a5424ce9799',
		cleaned_name: 'Croatia',
		tag_name_unanalyzed_downcase: 'croatia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 61,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0024,
		_explanation: null,
	},
	{
		id: '57b27bae122e5a5424ce979a',
		cleaned_name: 'Cuba',
		tag_name_unanalyzed_downcase: 'cuba',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 7,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27baf122e5a5424ce979b',
		cleaned_name: 'Cyprus',
		tag_name_unanalyzed_downcase: 'cyprus',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 37,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0015,
		_explanation: null,
	},
	{
		id: '57b2807bf3e5bb6339575c4d',
		cleaned_name: 'Democratic Republic of the Congo',
		tag_name_unanalyzed_downcase: 'democratic republic of the congo',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 20,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.001,
		_explanation: null,
	},
	{
		id: '57b27baf122e5a5424ce979d',
		cleaned_name: 'Denmark',
		tag_name_unanalyzed_downcase: 'denmark',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2524,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0977,
		_explanation: null,
	},
	{
		id: '57b280a0f3e5bb6325a8cdad',
		cleaned_name: 'Dominican Republic',
		tag_name_unanalyzed_downcase: 'dominican republic',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 97,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0044,
		_explanation: null,
	},
	{
		id: '57b28c76f3e5bb6321205eb2',
		cleaned_name: 'Dominica',
		tag_name_unanalyzed_downcase: 'dominica',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b280a0f3e5bb6325a8cdad',
		cleaned_name: 'Dominican Republic',
		tag_name_unanalyzed_downcase: 'dominican republic',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 97,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0044,
		_explanation: null,
	},
	{
		id: '57b27bb0122e5a5424ce979e',
		cleaned_name: 'Ecuador',
		tag_name_unanalyzed_downcase: 'ecuador',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 93,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.004,
		_explanation: null,
	},
	{
		id: '57b27bb0122e5a5424ce979f',
		cleaned_name: 'Egypt',
		tag_name_unanalyzed_downcase: 'egypt',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 825,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0347,
		_explanation: null,
	},
	{
		id: '57b27bb0122e5a5424ce97a0',
		cleaned_name: 'El Salvador',
		tag_name_unanalyzed_downcase: 'el salvador',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 50,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.002,
		_explanation: null,
	},
	{
		id: '57b28478f3e5bb6331872682',
		cleaned_name: 'Equatorial Guinea',
		tag_name_unanalyzed_downcase: 'equatorial guinea',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 5,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bb1122e5a5424ce97a1',
		cleaned_name: 'Estonia',
		tag_name_unanalyzed_downcase: 'estonia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 47,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.002,
		_explanation: null,
	},
	{
		id: '57b27fd2f3e5bb59919c743b',
		cleaned_name: 'Ethiopia',
		tag_name_unanalyzed_downcase: 'ethiopia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 65,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.003,
		_explanation: null,
	},
	{
		id: '57b280aff3e5bb631851c419',
		cleaned_name: 'Fiji',
		tag_name_unanalyzed_downcase: 'fiji',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 19,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.001,
		_explanation: null,
	},
	{
		id: '57b27bb1122e5a5424ce97a2',
		cleaned_name: 'Finland',
		tag_name_unanalyzed_downcase: 'finland',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2176,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0854,
		_explanation: null,
	},
	{
		id: '57b27bb1122e5a5424ce97a3',
		cleaned_name: 'France',
		tag_name_unanalyzed_downcase: 'france',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 99667,
		num_people: 1,
		_index_type: 'tag',
		_score: 5001.04,
		_explanation: null,
	},
	{
		id: '57b2830ef3e5bb63395767ce',
		cleaned_name: 'Gabon',
		tag_name_unanalyzed_downcase: 'gabon',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 3,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bb2122e5a5424ce97a4',
		cleaned_name: 'Georgia',
		tag_name_unanalyzed_downcase: 'georgia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bb2122e5a5424ce97a5',
		cleaned_name: 'Germany',
		tag_name_unanalyzed_downcase: 'germany',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 223568,
		num_people: 1,
		_index_type: 'tag',
		_score: 5001.3687,
		_explanation: null,
	},
	{
		id: '57b27fcbf3e5bb598de86290',
		cleaned_name: 'Ghana',
		tag_name_unanalyzed_downcase: 'ghana',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2628,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.1016,
		_explanation: null,
	},
	{
		id: '57b27bb2122e5a5424ce97a6',
		cleaned_name: 'Greece',
		tag_name_unanalyzed_downcase: 'greece',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 644,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0273,
		_explanation: null,
	},
	{
		id: '57b27fdbf3e5bb5995d644f9',
		cleaned_name: 'Guatemala',
		tag_name_unanalyzed_downcase: 'guatemala',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 315,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0137,
		_explanation: null,
	},
	{
		id: '57b28fd1f3e5bb63149f08ac',
		cleaned_name: 'Guyana',
		tag_name_unanalyzed_downcase: 'guyana',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bb3122e5a5424ce97a7',
		cleaned_name: 'Haiti',
		tag_name_unanalyzed_downcase: 'haiti',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 13,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27bb3122e5a5424ce97a8',
		cleaned_name: 'Honduras',
		tag_name_unanalyzed_downcase: 'honduras',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 32,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0015,
		_explanation: null,
	},
	{
		id: '57b27bb4122e5a5424ce97aa',
		cleaned_name: 'Hungary',
		tag_name_unanalyzed_downcase: 'hungary',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 1596,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0645,
		_explanation: null,
	},
	{
		id: '57b27bb4122e5a5424ce97ab',
		cleaned_name: 'Iceland',
		tag_name_unanalyzed_downcase: 'iceland',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 27,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.001,
		_explanation: null,
	},
	{
		id: '57b27bb4122e5a5424ce97ac',
		cleaned_name: 'India',
		tag_name_unanalyzed_downcase: 'india',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 152634,
		num_people: 1,
		_index_type: 'tag',
		_score: 5001.2114,
		_explanation: null,
	},
	{
		id: '57b27bb5122e5a5424ce97ad',
		cleaned_name: 'Indonesia',
		tag_name_unanalyzed_downcase: 'indonesia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 5184,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.181,
		_explanation: null,
	},
	{
		id: '57b27bb5122e5a5424ce97ae',
		cleaned_name: 'Iran',
		tag_name_unanalyzed_downcase: 'iran',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 26,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.001,
		_explanation: null,
	},
	{
		id: '57b27bb5122e5a5424ce97af',
		cleaned_name: 'Iraq',
		tag_name_unanalyzed_downcase: 'iraq',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 57,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0024,
		_explanation: null,
	},
	{
		id: '57b27bb6122e5a5424ce97b0',
		cleaned_name: 'Ireland',
		tag_name_unanalyzed_downcase: 'ireland',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 8558,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.2686,
		_explanation: null,
	},
	{
		id: '57b27bb6122e5a5424ce97b1',
		cleaned_name: 'Israel',
		tag_name_unanalyzed_downcase: 'israel',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 7167,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.235,
		_explanation: null,
	},
	{
		id: '57b27bb6122e5a5424ce97b2',
		cleaned_name: 'Italy',
		tag_name_unanalyzed_downcase: 'italy',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 9980,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.301,
		_explanation: null,
	},
	{
		id: '57b27bb7122e5a5424ce97b3',
		cleaned_name: 'Jamaica',
		tag_name_unanalyzed_downcase: 'jamaica',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 89,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.004,
		_explanation: null,
	},
	{
		id: '57b27bb7122e5a5424ce97b4',
		cleaned_name: 'Japan',
		tag_name_unanalyzed_downcase: 'japan',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 36491,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.6675,
		_explanation: null,
	},
	{
		id: '57b27bb7122e5a5424ce97b5',
		cleaned_name: 'Jordan',
		tag_name_unanalyzed_downcase: 'jordan',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 79,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0034,
		_explanation: null,
	},
	{
		id: '57b280c1f3e5bb6325a8cea3',
		cleaned_name: 'Kazakhstan',
		tag_name_unanalyzed_downcase: 'kazakhstan',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 37,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0015,
		_explanation: null,
	},
	{
		id: '57b27bb8122e5a5424ce97b6',
		cleaned_name: 'Kenya',
		tag_name_unanalyzed_downcase: 'kenya',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 624,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0264,
		_explanation: null,
	},
	{
		id: '57b28cbaf3e5bb6325a8e524',
		cleaned_name: 'Kiribati',
		tag_name_unanalyzed_downcase: 'kiribati',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bb8122e5a5424ce97b7',
		cleaned_name: 'Kosovo',
		tag_name_unanalyzed_downcase: 'kosovo',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 3,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bb8122e5a5424ce97b8',
		cleaned_name: 'Kuwait',
		tag_name_unanalyzed_downcase: 'kuwait',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 1111,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.046,
		_explanation: null,
	},
	{
		id: '57b28509f3e5bb6339576bcd',
		cleaned_name: 'Kyrgyzstan',
		tag_name_unanalyzed_downcase: 'kyrgyzstan',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 19,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.001,
		_explanation: null,
	},
	{
		id: '57b27bb9122e5a5424ce97b9',
		cleaned_name: 'Laos',
		tag_name_unanalyzed_downcase: 'laos',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 12,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b280e5f3e5bb6331871ba6',
		cleaned_name: 'Latvia',
		tag_name_unanalyzed_downcase: 'latvia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 46,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.002,
		_explanation: null,
	},
	{
		id: '57b27bb9122e5a5424ce97ba',
		cleaned_name: 'Lebanon',
		tag_name_unanalyzed_downcase: 'lebanon',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 82,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0034,
		_explanation: null,
	},
	{
		id: '57b280dff3e5bb631ca496e3',
		cleaned_name: 'Lesotho',
		tag_name_unanalyzed_downcase: 'lesotho',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 16,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27bb9122e5a5424ce97bb',
		cleaned_name: 'Liberia',
		tag_name_unanalyzed_downcase: 'liberia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 8,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27bb9122e5a5424ce97bc',
		cleaned_name: 'Libya',
		tag_name_unanalyzed_downcase: 'libya',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bba122e5a5424ce97bd',
		cleaned_name: 'Liechtenstein',
		tag_name_unanalyzed_downcase: 'liechtenstein',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 19,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.001,
		_explanation: null,
	},
	{
		id: '57b27fdbf3e5bb59acf12b0a',
		cleaned_name: 'Lithuania',
		tag_name_unanalyzed_downcase: 'lithuania',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 218,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0093,
		_explanation: null,
	},
	{
		id: '57b27bba122e5a5424ce97be',
		cleaned_name: 'Luxembourg',
		tag_name_unanalyzed_downcase: 'luxembourg',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 1536,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.062,
		_explanation: null,
	},
	{
		id: '57b280eff3e5bb634182ae4b',
		cleaned_name: 'Madagascar',
		tag_name_unanalyzed_downcase: 'madagascar',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 17,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.001,
		_explanation: null,
	},
	{
		id: '57b281b6f3e5bb63149ef269',
		cleaned_name: 'Malawi',
		tag_name_unanalyzed_downcase: 'malawi',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 6,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27bba122e5a5424ce97bf',
		cleaned_name: 'Malaysia',
		tag_name_unanalyzed_downcase: 'malaysia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 4355,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.157,
		_explanation: null,
	},
	{
		id: '57b28233f3e5bb6321204ef0',
		cleaned_name: 'Maldives',
		tag_name_unanalyzed_downcase: 'maldives',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 12,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27fecf3e5bb59998f4c2b',
		cleaned_name: 'Mali',
		tag_name_unanalyzed_downcase: 'mali',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 33,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0015,
		_explanation: null,
	},
	{
		id: '57b27fdcf3e5bb59b52adf75',
		cleaned_name: 'Malta',
		tag_name_unanalyzed_downcase: 'malta',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 363,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0156,
		_explanation: null,
	},
	{
		id: '57b28869f3e5bb631851d69c',
		cleaned_name: 'Mauritania',
		tag_name_unanalyzed_downcase: 'mauritania',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b280cef3e5bb631851c4d9',
		cleaned_name: 'Mauritius',
		tag_name_unanalyzed_downcase: 'mauritius',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 27,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.001,
		_explanation: null,
	},
	{
		id: '57b27bbb122e5a5424ce97c0',
		cleaned_name: 'Mexico',
		tag_name_unanalyzed_downcase: 'mexico',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 42798,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.7227,
		_explanation: null,
	},
	{
		id: '57b2813ef3e5bb631851c741',
		cleaned_name: 'Moldova',
		tag_name_unanalyzed_downcase: 'moldova',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 23,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.001,
		_explanation: null,
	},
	{
		id: '57b27bbb122e5a5424ce97c1',
		cleaned_name: 'Monaco',
		tag_name_unanalyzed_downcase: 'monaco',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 4,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bbb122e5a5424ce97c2',
		cleaned_name: 'Mongolia',
		tag_name_unanalyzed_downcase: 'mongolia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 1,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bbc122e5a5424ce97c3',
		cleaned_name: 'Montenegro',
		tag_name_unanalyzed_downcase: 'montenegro',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 0,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bbc122e5a5424ce97c4',
		cleaned_name: 'Morocco',
		tag_name_unanalyzed_downcase: 'morocco',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 200,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.009,
		_explanation: null,
	},
	{
		id: '57b280a1f3e5bb6339575de5',
		cleaned_name: 'Mozambique',
		tag_name_unanalyzed_downcase: 'mozambique',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 66,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.003,
		_explanation: null,
	},
	{
		id: '57b285aff3e5bb6321205632',
		cleaned_name: 'Namibia',
		tag_name_unanalyzed_downcase: 'namibia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 5,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b281d5f3e5bb63149ef2db',
		cleaned_name: 'Nauru',
		tag_name_unanalyzed_downcase: 'nauru',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bbd122e5a5424ce97c6',
		cleaned_name: 'Nepal',
		tag_name_unanalyzed_downcase: 'nepal',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 11,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27bbd122e5a5424ce97c7',
		cleaned_name: 'Netherlands',
		tag_name_unanalyzed_downcase: 'netherlands',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 63760,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.8677,
		_explanation: null,
	},
	{
		id: '57b27bbd122e5a5424ce97c8',
		cleaned_name: 'New Zealand',
		tag_name_unanalyzed_downcase: 'new zealand',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2033,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0806,
		_explanation: null,
	},
	{
		id: '57b28382f3e5bb6321205231',
		cleaned_name: 'Nicaragua',
		tag_name_unanalyzed_downcase: 'nicaragua',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 15,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27da2122e5a5424ce985d',
		cleaned_name: 'Nigeria',
		tag_name_unanalyzed_downcase: 'nigeria',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 9316,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.286,
		_explanation: null,
	},
	{
		id: '57b280a0f3e5bb634182ac31',
		cleaned_name: 'Niger',
		tag_name_unanalyzed_downcase: 'niger',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 25,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.001,
		_explanation: null,
	},
	{
		id: '57b27da2122e5a5424ce985d',
		cleaned_name: 'Nigeria',
		tag_name_unanalyzed_downcase: 'nigeria',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 9316,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.286,
		_explanation: null,
	},
	{
		id: '57b27bbe122e5a5424ce97c9',
		cleaned_name: 'Norway',
		tag_name_unanalyzed_downcase: 'norway',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2275,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.089,
		_explanation: null,
	},
	{
		id: '57b27bbe122e5a5424ce97ca',
		cleaned_name: 'Oman',
		tag_name_unanalyzed_downcase: 'oman',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 90,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.004,
		_explanation: null,
	},
	{
		id: '57b27bbe122e5a5424ce97cb',
		cleaned_name: 'Pakistan',
		tag_name_unanalyzed_downcase: 'pakistan',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 302,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0127,
		_explanation: null,
	},
	{
		id: '57b27bbf122e5a5424ce97cc',
		cleaned_name: 'Panama',
		tag_name_unanalyzed_downcase: 'panama',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 216,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0093,
		_explanation: null,
	},
	{
		id: '57b28105f3e5bb6325a8d043',
		cleaned_name: 'Papua New Guinea',
		tag_name_unanalyzed_downcase: 'papua new guinea',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 27,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.001,
		_explanation: null,
	},
	{
		id: '57b27bbf122e5a5424ce97cd',
		cleaned_name: 'Paraguay',
		tag_name_unanalyzed_downcase: 'paraguay',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 74,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0034,
		_explanation: null,
	},
	{
		id: '57b27bbf122e5a5424ce97ce',
		cleaned_name: 'Peru',
		tag_name_unanalyzed_downcase: 'peru',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 4669,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.1665,
		_explanation: null,
	},
	{
		id: '57b27bc0122e5a5424ce97cf',
		cleaned_name: 'Philippines',
		tag_name_unanalyzed_downcase: 'philippines',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 4822,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.171,
		_explanation: null,
	},
	{
		id: '57b27bc0122e5a5424ce97d0',
		cleaned_name: 'Poland',
		tag_name_unanalyzed_downcase: 'poland',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 31184,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.6147,
		_explanation: null,
	},
	{
		id: '57b27bc0122e5a5424ce97d1',
		cleaned_name: 'Portugal',
		tag_name_unanalyzed_downcase: 'portugal',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 24819,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.542,
		_explanation: null,
	},
	{
		id: '57b27bc1122e5a5424ce97d2',
		cleaned_name: 'Qatar',
		tag_name_unanalyzed_downcase: 'qatar',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2038,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0806,
		_explanation: null,
	},
	{
		id: '57b2860df3e5bb631ca4a4b6',
		cleaned_name: 'Republic of the Congo',
		tag_name_unanalyzed_downcase: 'republic of the congo',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 4,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bc1122e5a5424ce97d3',
		cleaned_name: 'Romania',
		tag_name_unanalyzed_downcase: 'romania',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 5760,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.1978,
		_explanation: null,
	},
	{
		id: '57b27bc1122e5a5424ce97d4',
		cleaned_name: 'Russia',
		tag_name_unanalyzed_downcase: 'russia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 108372,
		num_people: 1,
		_index_type: 'tag',
		_score: 5001.073,
		_explanation: null,
	},
	{
		id: '57b281c7f3e5bb633957640c',
		cleaned_name: 'Rwanda',
		tag_name_unanalyzed_downcase: 'rwanda',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 24,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.001,
		_explanation: null,
	},
	{
		id: '57b285baf3e5bb634182baea',
		cleaned_name: 'Saint Kitts and Nevis',
		tag_name_unanalyzed_downcase: 'saint kitts and nevis',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 7,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27bc2122e5a5424ce97d5',
		cleaned_name: 'Samoa',
		tag_name_unanalyzed_downcase: 'samoa',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 1,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bc2122e5a5424ce97d6',
		cleaned_name: 'Saudi Arabia',
		tag_name_unanalyzed_downcase: 'saudi arabia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 5035,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.1772,
		_explanation: null,
	},
	{
		id: '57b27fccf3e5bb59919c7391',
		cleaned_name: 'Senegal',
		tag_name_unanalyzed_downcase: 'senegal',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 43,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.002,
		_explanation: null,
	},
	{
		id: '57b27bc2122e5a5424ce97d7',
		cleaned_name: 'Serbia',
		tag_name_unanalyzed_downcase: 'serbia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 143,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0063,
		_explanation: null,
	},
	{
		id: '57b281c2f3e5bb63149ef294',
		cleaned_name: 'Seychelles',
		tag_name_unanalyzed_downcase: 'seychelles',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 7,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b28138f3e5bb634182afc7',
		cleaned_name: 'Sierra Leone',
		tag_name_unanalyzed_downcase: 'sierra leone',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 45,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.002,
		_explanation: null,
	},
	{
		id: '57b27bc3122e5a5424ce97d8',
		cleaned_name: 'Singapore',
		tag_name_unanalyzed_downcase: 'singapore',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 9286,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.285,
		_explanation: null,
	},
	{
		id: '57b27fcdf3e5bb598de862d5',
		cleaned_name: 'Slovakia',
		tag_name_unanalyzed_downcase: 'slovakia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 705,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.03,
		_explanation: null,
	},
	{
		id: '57b281eef3e5bb634182b2dc',
		cleaned_name: 'Slovenia',
		tag_name_unanalyzed_downcase: 'slovenia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 30,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0015,
		_explanation: null,
	},
	{
		id: '57b2886cf3e5bb63149f00f1',
		cleaned_name: 'Solomon Islands',
		tag_name_unanalyzed_downcase: 'solomon islands',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 3,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b2843df3e5bb631ca4a188',
		cleaned_name: 'Somalia',
		tag_name_unanalyzed_downcase: 'somalia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 5,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bc3122e5a5424ce97d9',
		cleaned_name: 'South Africa',
		tag_name_unanalyzed_downcase: 'south africa',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 12094,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.344,
		_explanation: null,
	},
	{
		id: '57b28419f3e5bb6339576a3e',
		cleaned_name: 'South Sudan',
		tag_name_unanalyzed_downcase: 'south sudan',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 3,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bc4122e5a5424ce97db',
		cleaned_name: 'Spain',
		tag_name_unanalyzed_downcase: 'spain',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 21644,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.5005,
		_explanation: null,
	},
	{
		id: '57b27fddf3e5bb598de86461',
		cleaned_name: 'Sri Lanka',
		tag_name_unanalyzed_downcase: 'sri lanka',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 40,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.002,
		_explanation: null,
	},
	{
		id: '57b2822ff3e5bb6325a8d520',
		cleaned_name: 'Sudan',
		tag_name_unanalyzed_downcase: 'sudan',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 7,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b2822ef3e5bb6339576557',
		cleaned_name: 'Suriname',
		tag_name_unanalyzed_downcase: 'suriname',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 6,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b27bc4122e5a5424ce97dc',
		cleaned_name: 'Sweden',
		tag_name_unanalyzed_downcase: 'sweden',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 27023,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.5684,
		_explanation: null,
	},
	{
		id: '57b27bc4122e5a5424ce97dd',
		cleaned_name: 'Switzerland',
		tag_name_unanalyzed_downcase: 'switzerland',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 6664,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.2217,
		_explanation: null,
	},
	{
		id: '57b27bc5122e5a5424ce97de',
		cleaned_name: 'Syria',
		tag_name_unanalyzed_downcase: 'syria',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 6,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b2897df3e5bb631851d7a4',
		cleaned_name: 'Tajikistan',
		tag_name_unanalyzed_downcase: 'tajikistan',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27fcef3e5bb59919c73e4',
		cleaned_name: 'Tanzania',
		tag_name_unanalyzed_downcase: 'tanzania',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 81,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0034,
		_explanation: null,
	},
	{
		id: '57b27bc5122e5a5424ce97e0',
		cleaned_name: 'Thailand',
		tag_name_unanalyzed_downcase: 'thailand',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 28549,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.586,
		_explanation: null,
	},
	{
		id: '57b28e8af3e5bb631ca4add4',
		cleaned_name: 'Togo',
		tag_name_unanalyzed_downcase: 'togo',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b286b3f3e5bb6339576e51',
		cleaned_name: 'Tonga',
		tag_name_unanalyzed_downcase: 'tonga',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 3,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27fe4f3e5bb59b52adfef',
		cleaned_name: 'Trinidad and Tobago',
		tag_name_unanalyzed_downcase: 'trinidad and tobago',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 16,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b28176f3e5bb6321204c68',
		cleaned_name: 'Tunisia',
		tag_name_unanalyzed_downcase: 'tunisia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 45,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.002,
		_explanation: null,
	},
	{
		id: '57b27bc6122e5a5424ce97e1',
		cleaned_name: 'Turkey',
		tag_name_unanalyzed_downcase: 'turkey',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 6114,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.207,
		_explanation: null,
	},
	{
		id: '57b2871cf3e5bb63149eff00',
		cleaned_name: 'Turkmenistan',
		tag_name_unanalyzed_downcase: 'turkmenistan',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b28095f3e5bb631851c335',
		cleaned_name: 'Uganda',
		tag_name_unanalyzed_downcase: 'uganda',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 66,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.003,
		_explanation: null,
	},
	{
		id: '57b27bc6122e5a5424ce97e2',
		cleaned_name: 'Ukraine',
		tag_name_unanalyzed_downcase: 'ukraine',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2816,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.108,
		_explanation: null,
	},
	{
		id: '57b27bc6122e5a5424ce97e3',
		cleaned_name: 'United Arab Emirates',
		tag_name_unanalyzed_downcase: 'united arab emirates',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 5171,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.181,
		_explanation: null,
	},
	{
		id: '57b27bc7122e5a5424ce97e4',
		cleaned_name: 'United Kingdom',
		tag_name_unanalyzed_downcase: 'united kingdom',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 145055,
		num_people: 1,
		_index_type: 'tag',
		_score: 5001.1904,
		_explanation: null,
	},
	{
		id: '57b27ba7122e5a5424ce9785',
		cleaned_name: 'United States',
		tag_name_unanalyzed_downcase: 'united states',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 686923,
		num_people: 1,
		_index_type: 'tag',
		_score: 5001.8433,
		_explanation: null,
	},
	{
		id: '57b27bc7122e5a5424ce97e5',
		cleaned_name: 'Uruguay',
		tag_name_unanalyzed_downcase: 'uruguay',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 179,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.008,
		_explanation: null,
	},
	{
		id: '57b28373f3e5bb631ca49ff2',
		cleaned_name: 'Uzbekistan',
		tag_name_unanalyzed_downcase: 'uzbekistan',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 8,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0005,
		_explanation: null,
	},
	{
		id: '57b28e23f3e5bb6331873187',
		cleaned_name: 'Vanuatu',
		tag_name_unanalyzed_downcase: 'vanuatu',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 2,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b27bc7122e5a5424ce97e6',
		cleaned_name: 'Venezuela',
		tag_name_unanalyzed_downcase: 'venezuela',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 429,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.018,
		_explanation: null,
	},
	{
		id: '57b27bc8122e5a5424ce97e7',
		cleaned_name: 'Vietnam',
		tag_name_unanalyzed_downcase: 'vietnam',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 9543,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.291,
		_explanation: null,
	},
	{
		id: '57b27bc8122e5a5424ce97e8',
		cleaned_name: 'Yemen',
		tag_name_unanalyzed_downcase: 'yemen',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 0,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0,
		_explanation: null,
	},
	{
		id: '57b28093f3e5bb63149eeb4c',
		cleaned_name: 'Zambia',
		tag_name_unanalyzed_downcase: 'zambia',
		parent_tag_id: null,
		uid: null,
		kind: 'location',
		has_children: false,
		category: 'Country',
		tag_category_downcase: 'country',
		num_organizations: 84,
		num_people: 1,
		_index_type: 'tag',
		_score: 5000.0034,
		_explanation: null,
	},
]
