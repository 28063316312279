import { Box, Button, HStack, Link, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCopyToClipboard } from 'react-use'
import { GetIcon, Icons } from 'ui'

import { DEFAULT_FE_URL } from '@/app/config'
import { useAppSelector } from '@/app/hooks'
import { GridLayout } from '@/common/components/ListGridLayoutComponents/GridLayout'
import { ListLayout } from '@/common/components/ListGridLayoutComponents/ListLayout'
import { MenuActionItemType } from '@/common/components/ListGridLayoutComponents/MenuActions'
import { Modal } from '@/common/components/Modal'
import {
	useDeleteFormMutation,
	useDuplicateFormMutation,
	useUpdateFormMutation,
} from '@/modules/forms/api'
import { FormTable } from '@/modules/forms/types'
import { BTN } from '@/styles/components/button'

import { WorkflowCardItem } from '../components/WorkflowCardItem'
import { WorkflowListItem } from '../components/WorkflowListItem'
import { selectWorkflowsViewPreferences } from '../slice/selectors'
import { WorkflowsViewTypes } from '../types/WorkflowsViewTypes'

type Props = {
	filteredWorkflows: FormTable[]
}

export const WorkflowsPartial = ({ filteredWorkflows }: Props) => {
	const [updateForm] = useUpdateFormMutation()
	const [, copyToClipboard] = useCopyToClipboard()

	const [duplicateForm] = useDuplicateFormMutation()
	const [deleteForm] = useDeleteFormMutation()
	const [selectedFormId, setSelectedFormId] = useState<number | null>(null)
	const [selectedForm, setSelectedForm] = useState<FormTable | undefined>()
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const onDeleteOpen = (formId: number) => {
		setSelectedFormId(formId)
		setSelectedForm(
			filteredWorkflows.find((workflow) => workflow.id === formId),
		)
		setDeleteModalOpen(true)
	}
	const onDeleteClose = () => {
		setSelectedFormId(null)
		setSelectedForm(undefined)
		setDeleteModalOpen(false)
	}
	const onDelete = (formId: number) => {
		deleteForm(formId)
		onDeleteClose()
	}

	const selectedFormsViewPreferences = useAppSelector(
		selectWorkflowsViewPreferences,
	)

	const isGrid = selectedFormsViewPreferences === WorkflowsViewTypes.card

	const navigate = useNavigate()
	const toast = useToast()

	const handleWorkflowActiveState = (formId: number, active: boolean) => {
		updateForm({
			form: {
				id: formId,
				active: active,
			},
		})
	}

	const handleNavigateToEditor = (formId: number) => {
		navigate(`/workflows/${formId}/editor`)
	}

	const handleCopyToClipboard = (formId: number) => {
		copyToClipboard(`${DEFAULT_FE_URL}/workflows/${formId}/editor`)

		toast({
			status: 'success',
			title: 'Share Link Generated!',
			description: "Paste the link and share it wherever you'd like.",
		})
	}

	const workflowMenuItems: MenuActionItemType[] = [
		{
			title: 'Edit',
			onClick: handleNavigateToEditor,
			icon: Icons.edit,
			variant: 'default',
		},
		{
			title: 'Duplicate',
			onClick: duplicateForm,
			icon: Icons.copy,
			variant: 'default',
		},
		{
			title: 'Share',
			onClick: handleCopyToClipboard,
			icon: Icons.share,
			variant: 'default',
		},
		{
			title: 'Delete',
			onClick: (formId: number) => {
				onDeleteOpen(formId)
			},
			icon: Icons.trash,
			variant: 'danger',
		},
	]
	return (
		<>
			{isGrid ? (
				<GridLayout>
					{filteredWorkflows?.map((form) => (
						<WorkflowCardItem
							key={form.id}
							form={form}
							handleWorkflowActiveState={handleWorkflowActiveState}
							handleNavigateToEditor={handleNavigateToEditor}
							menuItems={workflowMenuItems}
							isDuplicating={form.id === 0}
						/>
					))}
				</GridLayout>
			) : (
				<ListLayout>
					{filteredWorkflows?.map((form) => (
						<WorkflowListItem
							key={form.id}
							form={form}
							handleWorkflowActiveState={handleWorkflowActiveState}
							handleNavigateToEditor={handleNavigateToEditor}
							menuItems={workflowMenuItems}
							isDuplicating={form.id === 0}
						/>
					))}
				</ListLayout>
			)}

			<Modal
				isOpen={deleteModalOpen}
				headerText="Delete workflow confirmation"
				onClose={() => onDeleteClose()}
				footerContent={
					<HStack>
						<Button onClick={() => onDeleteClose()} variant={BTN.default}>
							Keep workflow
						</Button>
						<Button
							onClick={() => selectedFormId && onDelete(selectedFormId)}
							variant={BTN.danger_secondary}
						>
							Delete workflow
						</Button>
					</HStack>
				}
			>
				<Text>
					Are you sure you want to delete this workflow? This action cannot be
					undone.
				</Text>
				<br />
				<Text>
					Furthermore, deleting this workflow will delete the following
					associated form:
				</Text>
				<br />
				<Link target="_blank" href={`/forms/${selectedFormId}/editor`}>
					{selectedForm?.name}{' '}
					<Box display="inline-flex" verticalAlign="middle">
						<GetIcon icon={Icons.open_link} />
					</Box>
				</Link>
			</Modal>
		</>
	)
}
