import { Box } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import React, { CSSProperties, useMemo } from 'react'
import { Handle, Position } from 'reactflow'

type Props = {
	isConnectable: boolean
	isSelected: boolean
}

export const BottomConnector = React.memo(
	({ isConnectable, isSelected }: Props) => {
		const bottomHandleStyle: CSSProperties = useMemo(() => {
			return {
				background: 'transparent',
				width: '24px',
				height: '24px',
				borderRadius: '48px',
				border: 'none',
				position: 'absolute',
				bottom: '-4px',
				zIndex: 3,
			}
		}, [])

		return (
			<>
				<Box
					background="#FFFFFF"
					width="10px"
					height="10px"
					border="1.5px solid"
					borderColor={isSelected ? ColorTokens.accent : COLORS.background[9]}
					borderRadius="8px"
					position="absolute"
					bottom="-4px"
					zIndex={3}
					left="calc(50% - 4px)"
				/>
				<Handle
					type="source"
					position={Position.Bottom}
					isConnectable={isConnectable}
					style={bottomHandleStyle}
				/>
			</>
		)
	},
)

BottomConnector.displayName = 'BottomConnector'
