import { SchedulerTranslationsTokens } from '../tokens'

export const SchedulerGermanTranslation: Record<
	SchedulerTranslationsTokens,
	string
> = {
	[SchedulerTranslationsTokens.confirm]: 'Bestätigen',
	[SchedulerTranslationsTokens.confirm_meeting_header]:
		'Ihr Treffen wurde geplant!',
	[SchedulerTranslationsTokens.confirm_meeting_description]:
		'Wir haben Ihnen die Besprechungsdetails gesendet. Überprüfen Sie Ihren Posteingang.',
	[SchedulerTranslationsTokens.reschedule_meeting]:
		'Treffen Sie einen neuen Termin',
	[SchedulerTranslationsTokens.cancel_meeting]: 'Besprechung absagen',
	[SchedulerTranslationsTokens.confirm_meeting_has_assigned]:
		'wurde Ihrer Besprechung zugewiesen',
	[SchedulerTranslationsTokens.confirm_meeting_invite_teammates]:
		'Teamkollegen einladen',
	[SchedulerTranslationsTokens.confirm_meeting_send_invites]:
		'Sende Einladungen',
	[SchedulerTranslationsTokens.confirm_meeting_email_error]:
		'Bitte geben Sie gültige E-Mail-Adressen ein',
	[SchedulerTranslationsTokens.confirm_meeting_error_sending_invites]:
		'Einladungen konnten nicht gesendet werden!',
	[SchedulerTranslationsTokens.cancel_meeting_reason]:
		'Warum sagen Sie dieses Treffen ab? (Optional)',
	[SchedulerTranslationsTokens.cancel_meeting_reason_details]:
		'Teilen Sie hier einige Details mit',
	[SchedulerTranslationsTokens.cancel_meeting_header]:
		'Ihr Treffen wurde abgesagt!',
	[SchedulerTranslationsTokens.cancel_meeting_description]:
		'Wir werden alle Teilnehmer über die Absage informieren.',
	[SchedulerTranslationsTokens.cancel_by_mistake]: 'Aus Versehen abgesagt?',
	[SchedulerTranslationsTokens.cancel_reschedule_msg]:
		'Sie können dieses Meeting jederzeit mit einem neuen Zeitfenster verschieben.',

	[SchedulerTranslationsTokens.rescheduling_header]:
		'Planen Sie Ihr Meeting neu',
	[SchedulerTranslationsTokens.keep_original_time]:
		'Bestehendes Zeitfenster beibehalten',
	[SchedulerTranslationsTokens.former_time]: 'Frühere Zeit',
	[SchedulerTranslationsTokens.powered_by]: 'Angetrieben von',

	[SchedulerTranslationsTokens.no_event]:
		'Es wurde keine Veranstaltung gefunden!',
	[SchedulerTranslationsTokens.no_event_active]:
		'Diese Veranstaltung ist nicht mehr aktiv!',
	[SchedulerTranslationsTokens.no_member]: 'Es wurde kein Mitglied gefunden!',
	[SchedulerTranslationsTokens.no_queue]:
		'Es wurde keine Warteschlange gefunden!',
	[SchedulerTranslationsTokens.no_queue_active]:
		'Diese Warteschlange ist nicht mehr aktiv!',
	[SchedulerTranslationsTokens.no_meeting]:
		'Treffen konnte nicht gefunden werden!',
	[SchedulerTranslationsTokens.no_time_slots]:
		'Es sind keine Zeitfenster verfügbar!',

	[SchedulerTranslationsTokens.minutes_meeting]: 'minütiges Treffen',

	[SchedulerTranslationsTokens.weekdays_short]: 'So_Mo_Di_Mi_Do_Fr_Sa',
	[SchedulerTranslationsTokens.time_slots]: 'Zeitfenster',

	[SchedulerTranslationsTokens.morning]: 'Morgen',
	[SchedulerTranslationsTokens.daytime]: 'Tageszeit',
	[SchedulerTranslationsTokens.evening]: 'Abend',

	[SchedulerTranslationsTokens.timeslot_loading]:
		'Verfügbare Slots erhalten...',
	[SchedulerTranslationsTokens.timer_warning]:
		'Buchen Sie innerhalb der nächsten {delay} Minuten, um Ihre Sitzung zu bestätigen.',
}
