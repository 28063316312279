import { Flex } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { SingleValue } from 'react-select'
import { useMount } from 'react-use'
import { Icons } from 'ui'

import { PanelSectionDivider } from '@/modules/workflow/components/side-panel/panel-variants/PanelSectionDivider'
import { PanelSelect } from '@/modules/workflow/components/side-panel/panel-variants/PanelSelect'
import {
	useCurrentNodeData,
	useUpdateWorkflowNode,
} from '@/modules/workflow/hooks'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import { workflowsActions } from '@/modules/workflow/slice'
import { Default_HTTPPostRequest } from '@/modules/workflow/types/actions'
import { CanvasNode } from '@/modules/workflow/types/nodes'

import { PanelInput } from '../../../../../panel-variants/PanelInput'
import { PanelInputLabel } from '../../../../../panel-variants/PanelInputLabel'
import { AuthorizationSection } from './AuthorizationSection'
import { BodyParamsSection } from './BodyParamsSection'
import { HeadersParamsSection } from './HeadersParamsSection'
import { TestSection } from './TestSection'
import { UrlParamsSection } from './UrlParamsSection'

const DEFAULT_REQUEST_DATA: Default_HTTPPostRequest = {
	url: { refNodeId: null, variable: null, value: '' },
	urlParams: {},
	headers: {},
	body: {},
	authType: 'none',
	authorization: { refNodeId: null, variable: null, value: ['', ''] },
	method: { refNodeId: null, variable: null, value: 'post' },
	testRequestResponse: null,
}

export interface WebhookSectionProps {
	selectedNode: CanvasNode | null
	requestData: Default_HTTPPostRequest
	updateNode: (node: CanvasNode, ignoreSync?: boolean) => void
}

export const HTTPPostRequest = () => {
	const updateNode = useUpdateWorkflowNode()
	const selectedNode = useSelectedNode()

	const { getCurrentNodeData } = useCurrentNodeData(selectedNode)

	const requestData = (selectedNode?.data.stepDetails ||
		DEFAULT_REQUEST_DATA) as Default_HTTPPostRequest

	const dispatch = useDispatch()

	useMount(() => {
		dispatch(workflowsActions.setCurrentNodeData(selectedNode))
	})

	const setUrl = (e) => {
		if (!selectedNode) return

		const currentNodeData = getCurrentNodeData()

		updateNode({
			...currentNodeData,
			data: {
				...currentNodeData.data,
				stepDetails: {
					...requestData,
					url: {
						refNodeId: null,
						variable: null,
						value: e.target.value,
					},
				},
			},
		})
	}

	const handleSelectMethod = (
		methodOp: SingleValue<{ label: string; value: string }>,
	) => {
		if (!selectedNode || !methodOp) return

		const currentNodeData = getCurrentNodeData()

		updateNode({
			...currentNodeData,
			data: {
				...currentNodeData.data,
				stepDetails: {
					...requestData,
					method: {
						refNodeId: null,
						variable: null,
						value: methodOp.value,
					},
				},
			},
		})
	}

	const methodOptions = ['POST', 'GET', 'PATCH', 'PUT', 'DELETE'].map(
		(method) => ({
			label: method,
			value: method.toLowerCase(),
		}),
	)

	const methodValue = methodOptions.find(
		(op) => op.value === requestData?.method?.value,
	)

	return (
		<Flex direction="column" mt={2}>
			<Flex w="100%" direction="column" zIndex={5} gap={8}>
				<Flex direction="column">
					<PanelInputLabel label="Request URL" />
					<Flex gap={1}>
						<PanelSelect
							value={methodValue || { label: 'POST', value: 'post' }}
							options={methodOptions}
							onChange={handleSelectMethod}
							width="max-content"
						/>

						<PanelInput
							placeholder="URL"
							initialValue={requestData.url?.value || ''}
							onFocus={() => {
								navigator.clipboard.readText().then((value) => {
									if (!value) return
									navigator.clipboard.writeText(value)
								})
							}}
							onBlur={setUrl}
						/>
					</Flex>
				</Flex>

				<Flex direction="column" gap={2}>
					<PanelInputLabel
						label="Webhook Parameters"
						leftIcon={Icons.parameters}
					/>
					<Flex gap={4} flexDir="column">
						<UrlParamsSection
							selectedNode={selectedNode}
							requestData={requestData}
							updateNode={updateNode}
						/>
						<HeadersParamsSection
							selectedNode={selectedNode}
							requestData={requestData}
							updateNode={updateNode}
						/>
						<BodyParamsSection
							selectedNode={selectedNode}
							requestData={requestData}
							updateNode={updateNode}
						/>
					</Flex>
				</Flex>
			</Flex>

			<PanelSectionDivider my={6} />

			<Flex direction="column" gap={2}>
				<PanelInputLabel label="Authorization" />
				<AuthorizationSection
					selectedNode={selectedNode}
					requestData={requestData}
					updateNode={updateNode}
				/>
			</Flex>

			<PanelSectionDivider my={6} />
			<Flex direction="column">
				<PanelInputLabel label="Example response" />
				<TestSection
					selectedNode={selectedNode}
					requestData={requestData}
					updateNode={updateNode}
				/>
			</Flex>
		</Flex>
	)
}
