import { extendTheme } from '@chakra-ui/react'

import { borders, RADII } from '../tokens/borders'
import { COLORS, generateSemanticColors } from '../tokens/colors'
import { SHADOWS } from '../tokens/shadows'
import { SPACING } from '../tokens/space'
import {
	FONT_SIZES,
	FONTS,
	LETTER_SPACING,
	LINE_HEIGHT,
	WEIGHT,
} from '../tokens/typography'
import { breakpoints } from './breakpoints'
import Button from './components/Button'
import Checkbox from './components/Checkbox'
import Input from './components/Input'
import Menu from './components/Menu'
import Modal from './components/Modal'
import Popover from './components/Popover'
import Radio from './components/Radio'
import TextArea from './components/TextArea'
import Tooltip from './components/Tooltip'
import Typography from './components/Typography'
import styles from './global'

const theme = {
	config: {
		initialColorMode: 'light',
	},
	semanticTokens: {
		colors: generateSemanticColors(),
		borders: borders,
	},
	components: {
		Button: Button,
		Checkbox: Checkbox,
		Input: Input,
		Menu: Menu,
		Modal: Modal,
		Popover: Popover,
		Radio: Radio,
		TextArea: TextArea,
		Tooltip: Tooltip,
		Text: Typography,
	},
	fontSizes: FONT_SIZES,
	letterSpacings: LETTER_SPACING,
	lineHeights: LINE_HEIGHT,
	fonts: FONTS,
	fontWeights: WEIGHT,
	shadows: {
		[SHADOWS.small]: '0px 1px 4px 1px rgba(12, 17, 61, 0.04)',
		[SHADOWS.medium]: '0px 1px 3px rgba(12, 17, 61, 0.08)',
		[SHADOWS.elevated]: '0px 2px 6px 2px rgba(12, 17, 61, 0.06)',
		[SHADOWS.focus]: '0px 0px 0px 2px rgba(118, 120, 237, 0.85)',
		[SHADOWS.depth0]: '0px 1px 4px rgba(34, 43, 72, 0.08)',
		[SHADOWS.depth1]: '0px 16px 24px rgba(34, 43, 72, 0.12)',
	},
	colors: COLORS,
	radii: RADII,
	space: SPACING,
	styles,
	breakpoints,
}

export const defaultTheme = extendTheme({ ...theme })
