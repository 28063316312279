import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unionBy } from 'lodash'

import { LoadingState } from '@/app/types'

import {
	ALL_LEADS_VIEW_KEY,
	LEADS_VIEW_PREFERENCES_KEY,
	LEADS_VIEW_SELECTED_KEY,
} from '../../../utils/constants'
import { LeadType, UpdateLeadTablePayload } from '../api/core/type'
import { ColumnSorting, DateFilter, ViewsType } from '../api/views/type'
import { LeadAttributes } from '../helper'
import { flatLead } from '../helper/flat-lead'
import { LeadsViewPreferences, StageLeadType } from '../types'

export interface LeadsState {
	drafts: ViewsType
	selectedViewId: string
	leadsViewPreferences: LeadsViewPreferences

	tableLeads: LeadType[]
	tableLeadsCount: number

	selectedLead: LeadType | null
	page: number
	searchQuery: string

	stagesLeads: Record<string, StageLeadType>
	stagesLeadsLoading: Record<string, LoadingState>
	stagesPage: Record<string, number>
}

export const initializeViews: ViewsType = {
	[ALL_LEADS_VIEW_KEY]: {
		id: ALL_LEADS_VIEW_KEY,
		name: 'All Leads',
		hidden_fields: [],
		date_filter: null,
		attribute_filters: {},
		sortingState: {},
		stage_filters: [],
		group_by: [],
	},
}

const initialState: LeadsState = {
	drafts: {
		...initializeViews,
	},
	selectedViewId:
		localStorage.getItem(LEADS_VIEW_SELECTED_KEY) || ALL_LEADS_VIEW_KEY,
	tableLeads: [],
	tableLeadsCount: 0,
	selectedLead: null,
	page: 1,
	searchQuery: '',
	leadsViewPreferences:
		(localStorage.getItem(
			LEADS_VIEW_PREFERENCES_KEY,
		) as LeadsViewPreferences) || 'table',

	stagesLeads: {},
	stagesPage: {},
	stagesLeadsLoading: {},
}

export const leadsSlice = createSlice({
	name: 'leads',
	initialState,
	reducers: {
		initializeDraftViews: (state, { payload }: PayloadAction<ViewsType>) => {
			state.drafts = {
				...payload,
			}
		},

		setTableLeads: (
			state,
			{ payload }: PayloadAction<{ leads: LeadType[]; count: number | null }>,
		) => {
			state.tableLeads =
				state.page === 1
					? payload.leads
					: unionBy(state.tableLeads, payload.leads, 'id')
			state.tableLeadsCount = payload.count ?? 0
		},

		setBoardLeads: (
			state,
			{
				payload: { stage, leads, count },
			}: PayloadAction<{
				leads: LeadType[]
				stage: string
				count: number | null
			}>,
		) => {
			const page = state.stagesPage[stage]
			const previousLeads = state.stagesLeads[stage]?.leads || []
			state.stagesLeads[stage] = {
				leads:
					!page || page === 1 ? leads : unionBy(previousLeads, leads, 'id'),
				count: count ?? 0,
			}
		},

		setLeadsViewPreferences: (
			state,
			{ payload }: PayloadAction<LeadsViewPreferences>,
		) => {
			state.leadsViewPreferences = payload
			localStorage.setItem(LEADS_VIEW_PREFERENCES_KEY, payload)
		},
		setSortingState: (state, { payload }: PayloadAction<ColumnSorting>) => {
			const selectedViewId = state.selectedViewId
			const view = state.drafts[selectedViewId]
			state.page = 1
			if (view) {
				view.sortingState = {
					[payload.attribute]: payload,
				}
			}

			if (
				view &&
				view.sortingState &&
				(payload.ascending === null || payload.ascending === undefined)
			) {
				delete view.sortingState[payload.attribute]
			}
		},

		setSearchQuery: (
			state,
			{ payload }: PayloadAction<{ query: string; ac: AbortController }>,
		) => {
			if (payload.query !== state.searchQuery) {
				state.searchQuery = payload.query
				state.page = 1
				state.stagesPage = {}
			}
		},
		setNextPage: (state, { payload }: PayloadAction<number>) => {
			state.page = payload
		},
		setSelectedView: (state, { payload }: PayloadAction<string>) => {
			if (payload !== state.selectedViewId) {
				state.selectedViewId = payload
				localStorage.setItem(LEADS_VIEW_SELECTED_KEY, payload)
				state.page = 1
				state.stagesPage = {}
				state.searchQuery = ''
			}
		},

		toggleFields: (state, { payload }: PayloadAction<string>) => {
			const columnId = payload // going to be hide/show
			const selectedViewId = state.selectedViewId
			const view = state.drafts[selectedViewId]

			const hiddenFields = view?.hidden_fields || []

			const findIndex = hiddenFields.findIndex((id) => id === columnId)
			if (findIndex === -1) {
				hiddenFields.push(columnId)
			} else {
				hiddenFields.splice(findIndex, 1)
			}

			state.drafts[selectedViewId].hidden_fields = hiddenFields
		},
		setDateFilter: (state, { payload }: PayloadAction<DateFilter | null>) => {
			const selectedViewId = state.selectedViewId
			state.drafts[selectedViewId].date_filter = payload
			state.page = 1
			state.stagesPage = {}
		},
		setAttributeFilterValues: (
			state,
			{
				payload: { attribute, value },
			}: PayloadAction<{
				attribute: LeadAttributes
				value: string
			}>,
		) => {
			const selectedViewId = state.selectedViewId
			const filters = state.drafts[selectedViewId].attribute_filters ?? {}

			const filter = filters[attribute]
			const values = filter?.values || []
			const index = values.findIndex((val) => val === value)

			if (values.length && index !== -1) {
				values.splice(index, 1)
			} else {
				values.push(value)
			}

			filters[attribute] = {
				attribute,
				values,
				isTextValue: false,
				textValue: null,
			}

			if (values.length === 0) {
				delete filters[attribute]
			}

			state.drafts[selectedViewId].attribute_filters = filters
			state.page = 1
			state.stagesPage = {}
		},
		setAttributeFilterValuesArray: (
			state,
			{
				payload: { attribute, values },
			}: PayloadAction<{
				attribute: LeadAttributes
				values: string[]
			}>,
		) => {
			const selectedViewId = state.selectedViewId
			const filters = state.drafts[selectedViewId].attribute_filters ?? {}

			filters[attribute] = {
				attribute,
				values,
				isTextValue: false,
				textValue: null,
			}

			state.drafts[selectedViewId].attribute_filters = values.length
				? filters
				: null
			state.page = 1
			state.stagesPage = {}
		},
		setGroupBy: (
			state,
			{
				payload,
			}: PayloadAction<{
				attribute: LeadAttributes
				value: string
				sortDirection: 'asc' | 'desc' | ''
				sort: 'alphabetical' | 'numerical'
			} | null>,
		) => {
			const selectedViewId = state.selectedViewId
			if (!payload) {
				state.drafts[selectedViewId].group_by = []
			} else {
				if (payload.sortDirection) {
					state.drafts[selectedViewId].group_by = [payload]
				}
			}
		},
		resetSingleAttributeFilter: (
			state,
			{ payload }: PayloadAction<LeadAttributes>,
		) => {
			const selectedViewId = state.selectedViewId
			const filters = state.drafts[selectedViewId].attribute_filters ?? {}
			delete filters[payload]
			state.drafts[selectedViewId].attribute_filters = filters
			state.page = 1
			state.stagesPage = {}
		},
		resetFilters: (state) => {
			state.drafts[state.selectedViewId].attribute_filters = {}
			state.drafts[state.selectedViewId].stage_filters = []

			state.page = 1
			state.stagesPage = {}
		},
		setStageFilter: (
			state,
			{ payload: selectedStage }: PayloadAction<string>,
		) => {
			const selectedViewId = state.selectedViewId
			const stage_filters = state.drafts[selectedViewId].stage_filters ?? []

			const index = stage_filters.findIndex((stage) => stage === selectedStage)

			if (index !== -1) {
				stage_filters.splice(index, 1)
			} else {
				stage_filters.push(selectedStage)
			}
			state.drafts[selectedViewId].stage_filters = stage_filters
			state.page = 1
			state.stagesPage = {}
		},

		setNextPagePerStage: (state, { payload }: PayloadAction<string>) => {
			const prevPage = state.stagesPage[payload] || 1
			state.stagesPage[payload] = prevPage + 1
		},
		setStageLoading: (
			state,
			{ payload }: PayloadAction<{ stageId: string; state: LoadingState }>,
		) => {
			state.stagesLeadsLoading[payload.stageId] = payload.state
		},
		updateLeadStageInTable: (
			state,
			{ payload }: PayloadAction<{ leadId: string; stage: string }>,
		) => {
			const index = state.tableLeads.findIndex(
				(lead) => lead.id === payload.leadId,
			)

			if (index !== -1) {
				const lead = state.tableLeads[index]
				lead.stage_id = payload.stage
				state.tableLeads[index] = lead
			}
		},
		updateStageInKanban: (
			state,
			{
				payload,
			}: PayloadAction<{
				sourceStage: string
				destinationStage: string
				leadId: string
			}>,
		) => {
			const { sourceStage, destinationStage, leadId } = payload
			const sourceStageData = state.stagesLeads[sourceStage]
			const destinationStageData = state.stagesLeads[destinationStage]

			const leadIndex = sourceStageData.leads.findIndex(
				(lead) => lead.id === leadId,
			)
			const lead = sourceStageData.leads[leadIndex]

			sourceStageData.leads.splice(leadIndex, 1)
			sourceStageData.count -= 1
			destinationStageData.leads.unshift(lead)
			destinationStageData.count += 1
		},

		optimisticUpdateLead: (
			state,
			{ payload }: PayloadAction<UpdateLeadTablePayload>,
		) => {
			const leadId = payload.lead.id
			const tableIndex = state.tableLeads.findIndex(
				(lead) => lead.id === leadId,
			)
			if (tableIndex !== -1) {
				state.tableLeads[tableIndex] = flatLead({
					payload,
					lead: state.tableLeads[tableIndex],
				})
			}
		},
	},
})

export const leadsReducer = leadsSlice.reducer
export const leadsActions = leadsSlice.actions
