import { useMemo } from 'react'
import { Icons } from 'ui'

import { PageNavStepsType } from '@/common/components/PageHeader/PageNav'

export const useWorkflowLogDetailsNavSteps = ({
	currentPageName,
	workflowId,
}: {
	currentPageName: string
	workflowId: number
}): PageNavStepsType[] => {
	return useMemo(
		() => [
			{
				title: 'Workflow Log',
				route: `/workflows/${workflowId}/workflow-logs`,
				icon: Icons.workflow,
			},
			{ title: currentPageName },
		],
		[currentPageName, workflowId],
	)
}
