// @ts-nocheck
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { endOfMonth, getWeeksInMonth } from '@internationalized/date'
import { useCalendarGrid } from '@react-aria/calendar'
import { useLocale } from '@react-aria/i18n'
import { RangeCalendarState } from '@react-stately/calendar'
import { CalendarState } from 'react-stately'

import { capitalize } from '@/utils/helpers'

import { CalendarCell, RangeCalendarCell } from './CalendarCell'

export const CalendarGrid = ({ state }: { state: CalendarState }) => {
	const { locale } = useLocale()
	const startDate = state.visibleRange.start
	const endDate = state.visibleRange.end
	const { weekDays: week } = useCalendarGrid(
		{
			// @ts-ignore
			startDate,
			// @ts-ignore
			endDate,
		},
		state,
	)

	// @ts-ignore
	const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale)
	const weeks = [...new Array(weeksInMonth).keys()].map((weekIndex) =>
		state.getDatesInWeek(weekIndex, startDate).map((date) => {
			if (date) {
				return date
			}
		}),
	)
	const weekDayHeaders =
		week[0] === 'S'
			? ['S', 'M', 'T', 'W', 'T', 'F', 'S']
			: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Flex
				w="286px"
				justify="space-between"
				fontSize="13px"
				fontWeight={500}
				px={3.5}
				mb={3}
			>
				{weekDayHeaders.map((day, i) => (
					<GridItem key={i} h={5} display="flex" justifyContent="center">
						<Text fontSize="14px" fontWeight={500} color={COLORS.background[4]}>
							{capitalize(day)}
						</Text>
					</GridItem>
				))}
			</Flex>
			<Grid w="280px" templateColumns="repeat(7, 1fr)" gap={0.25}>
				{weeks.map((week) =>
					week.map((date, i) => {
						if (date) {
							return (
								<CalendarCell
									key={i}
									// @ts-ignore
									date={date}
									state={state}
									// @ts-ignore
									currentMonth={startDate}
								/>
							)
						}
					}),
				)}
			</Grid>
		</div>
	)
}

export const RangeCalendarGrid = ({
	state,
	offset = {},
}: {
	state: RangeCalendarState
	offset?: any
}) => {
	const { locale } = useLocale()
	const startDate = state.visibleRange.start.add(offset)
	const endDate = endOfMonth(startDate)
	const { gridProps, headerProps, weekDays } = useCalendarGrid(
		{
			startDate,
			endDate,
		},
		state,
	)

	const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale)

	//const weekDayHeaders = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

	return (
		<table {...gridProps}>
			<thead {...headerProps}>
				<tr>
					{weekDays.map((day, index) => (
						<th
							key={index}
							style={{
								color: COLORS.gray[12],
								fontWeight: 500,
								fontSize: '13px',
								letterSpacing: '0.25px',
							}}
						>
							{day}
						</th>
					))}
				</tr>
			</thead>
			<div style={{ width: '100%', height: '8px' }} />
			<tbody>
				{[...new Array(weeksInMonth).keys()].map((weekIndex) => (
					<tr key={weekIndex}>
						{state
							.getDatesInWeek(weekIndex, startDate)
							.map((date, i) =>
								date ? (
									<RangeCalendarCell
										key={i}
										state={state}
										date={date}
										currentMonth={startDate}
									/>
								) : (
									<td key={i} />
								),
							)}
					</tr>
				))}
			</tbody>
		</table>
	)
}
