import { RootState } from '@/app/store'

export const selectCurrentPage = (state: RootState) =>
	state.workflowLogs.currentPage

export const selectSearchQuery = (state: RootState) =>
	state.workflowLogs.searchQuery

export const selectDateRange = (state: RootState) =>
	state.workflowLogs.dateRange
