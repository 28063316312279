import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'

dayjs.extend(isToday)
dayjs.extend(isYesterday)

export const formatUTCDate = ({
	date,
	format,
	showTodayYeserday,
	showSeconds,
}: {
	date?: string | null
	format?: string
	showTodayYeserday?: boolean
	showSeconds?: boolean
}) => {
	if (!date || !dayjs(date).isValid()) return '-'

	if (format) {
		return dayjs(date).format(format)
	}

	const timestampFormatString = showSeconds ? 'hh:mm:ss A' : 'hh:mm A'

	if (showTodayYeserday) {
		if (dayjs(date).isToday())
			return `Today ${dayjs(date).format(timestampFormatString)}`
		if (dayjs(date).isYesterday())
			return `Yesterday ${dayjs(date).format(timestampFormatString)}`
	}

	return dayjs(date).format(`ddd DD MMM, YYYY ${timestampFormatString}`)
}
