import { Flex, Image, Text, Tooltip } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { useNavigate } from 'react-router-dom'
import { GetIcon, Icons } from 'ui'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { useIsDisconnectedIntegrationsAllowed } from '@/common/hooks/feature-flags/useIsDisconnectedIntegrationsAllowed'
import { workflowsActions } from '@/modules/workflow/slice'
import { selectClickToConnectInteraction } from '@/modules/workflow/slice/selectors'
import {
	IntegrationConnection,
	IntegrationId,
} from '@/modules/workflow/types/integrations'
import { WorkflowIntegrationCategory as IntegrationCategory } from '@/modules/workflow/utils/mappings'
import { integrationCategoryBackground } from '@/styles/colors'
import { TEXT_ENUM } from '@/styles/components/text'
import { TOOLTIP_ENUM } from '@/styles/components/tooltip'

import { RFNodeType, StepType } from '../../../../types/nodes'

interface IntegrationListItemProps {
	action: string
	logo: string | Icons
	nodeType: RFNodeType
	stepType: StepType
	integrationId: IntegrationId
	integrationCategory: IntegrationCategory
	integrationConnection: IntegrationConnection | null
}

export const IntegrationListItem = ({
	action,
	logo,
	nodeType,
	stepType,
	integrationCategory,
	integrationId,
	integrationConnection,
}: IntegrationListItemProps) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const isDisconnectedIntegrationsAllowed =
		useIsDisconnectedIntegrationsAllowed()
	const clickToConnect = useAppSelector(selectClickToConnectInteraction)
	const isIntegrationConnected =
		integrationConnection !== null
			? integrationConnection.isLoading || integrationConnection.isConnected
			: true
	const isEnabled = isIntegrationConnected || isDisconnectedIntegrationsAllowed

	const onDragStart = (event: any, nodeData: any) => {
		event.dataTransfer.setData('application/reactflow', nodeData)
		event.dataTransfer.effectAllowed = 'move'
	}

	const handleClick = () => {
		if (isEnabled) {
			if (clickToConnect) {
				dispatch(
					workflowsActions.setIntegrationListItemToConnect({
						nodeType,
						stepType,
						integrationId,
						editableName: action,
					}),
				)
			}
		} else {
			navigate(
				`/settings/configuration/integrations/${integrationConnection?.configPath}`,
			)
		}
	}

	return (
		<Tooltip
			variant={TOOLTIP_ENUM.workflows_side_panel}
			label="Integration is not connected. Click to connect."
			placement="top"
			isDisabled={isEnabled}
		>
			<Flex
				bg={ColorTokens.bg_card}
				w="100%"
				borderWidth="medium"
				mb={2}
				py={2}
				px={2}
				borderColor={ColorTokens.border_primary}
				borderRadius="12px"
				justify="space-between"
				align="center"
				opacity={isEnabled ? 1 : 0.5}
				_hover={{
					cursor: !isEnabled
						? 'not-allowed'
						: clickToConnect
							? 'pointer'
							: 'grab',
					borderColor: isEnabled ? ColorTokens.accent : COLORS.gray[7],
				}}
				onDragStart={(event) =>
					onDragStart(
						event,
						JSON.stringify({
							nodeType,
							stepType,
							integrationId,
							editableName: action,
						}),
					)
				}
				onClick={handleClick}
				transform="translate(0, 0)"
				draggable={!clickToConnect && isEnabled}
			>
				<Flex justify="flex-start" align="center">
					{Object.values(Icons).includes(logo as Icons) ? (
						<Flex
							h={9}
							w={9}
							borderRadius="lg"
							bg={integrationCategoryBackground[integrationCategory]}
							justify="center"
							align="center"
							color={ColorTokens.white}
							css={{ userSelect: 'none' }}
						>
							<GetIcon icon={logo as Icons} color="inherit" boxSize={6} />
						</Flex>
					) : (
						<Image
							boxSize={9}
							objectFit="contain"
							src={logo}
							alt="integration logo"
							borderRadius="md"
							draggable={false}
						/>
					)}
					<Text
						ml={4}
						variant={TEXT_ENUM.sm}
						fontWeight="semibold"
						css={{ userSelect: 'none' }}
					>
						{action}
					</Text>
				</Flex>
				{!clickToConnect && isEnabled && (
					<GetIcon icon={Icons.drag} color={COLORS.gray[9]} boxSize={5} />
				)}
			</Flex>
		</Tooltip>
	)
}
