import { HStack, Text } from '@chakra-ui/react'

import { TEXT_ENUM } from '@/styles/components/text'

import { ActiveStepIcon } from './ActiveStepIcon'
import { FinishedStepIcon } from './FinishedStepIcon'
import { InactiveStepIcon } from './InactiveStepIcon'

type Props = {
	title: string
	hideConnector?: boolean
	variant?: 'active' | 'finished' | 'inactive'
}

export const Step = ({ title, hideConnector, variant }: Props) => {
	const getIcon = () => {
		switch (variant) {
			case 'finished':
				return <FinishedStepIcon hideConnector={hideConnector} />
			case 'active':
				return <ActiveStepIcon hideConnector={hideConnector} />
			case 'inactive':
				return <InactiveStepIcon hideConnector={hideConnector} />
			default:
				return null
		}
	}
	return (
		<HStack gap={2} alignItems="flex-start">
			{getIcon()}
			<Text variant={TEXT_ENUM.medium14}>{title}</Text>
		</HStack>
	)
}
