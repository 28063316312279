import { Button, HStack } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { useAppDispatch } from '@/app/hooks'
import { Borders } from '@/styles/borders'
import { BTN } from '@/styles/components/button'

import { workflowsHomeActions } from '../../slice'

type Props = {
	isDisableContinue: boolean
	isLoadingContinue: boolean
	handleContinue: () => void
}

export const EmptyModalFooter = ({
	isDisableContinue,
	isLoadingContinue,
	handleContinue,
}: Props) => {
	const dispatch = useAppDispatch()

	return (
		<HStack
			h="80px"
			px={5}
			bg={COLORS.whiteAlpha[12]}
			borderTop={Borders.primary}
			justifyContent="flex-end"
			flexShrink={0}
		>
			<Button
				variant={BTN.secondary}
				onClick={() =>
					dispatch(workflowsHomeActions.setShowEmptyStateModal(false))
				}
			>
				Close
			</Button>
			<Button
				isLoading={isLoadingContinue}
				isDisabled={isDisableContinue}
				variant={BTN.primary}
				onClick={handleContinue}
			>
				Continue
			</Button>
		</HStack>
	)
}
