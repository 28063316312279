import { FieldRefNodeValue, LabeledRefNodeValue, RefNodeValue } from './actions'
import { MatchRecord } from './matching'

export interface Hubspot_CreateRecord {
	type: RefNodeValue
	insertHubspotProperties: {
		[hubspotPropertyId: string]: FieldRefNodeValue
	}
}
export interface Hubspot_MergeRecord {
	type: RefNodeValue // Lead, Contact, Account
	insertHubspotProperties: {
		[hubspotPropertyId: string]: FieldRefNodeValue
	}
	duplicateRecordId: LabeledRefNodeValue // the ID of the record being merged
	overwrite: boolean
}

export interface Hubspot_CreateEngagement {
	type: RefNodeValue
	insertHubspotAssociations: {
		objectType: RefNodeValue
		objectId: LabeledRefNodeValue
	} | null
	insertHubspotProperties: {
		[hubspotPropertyId: string]: FieldRefNodeValue
	}
}

export type Hubspot_MatchRecord = MatchRecord

export enum HubspotEntities {
	Contact = 'Contact',
	Company = 'Company',
	Deal = 'Deal',
	// TODO: Need to add support for required fields for tickets:
	// https://linear.app/withdefault/issue/DEF-3506/add-support-for-required-fields-for-hubspot-ticket-objects
	// Ticket = 'Ticket',
}

export enum HubspotEngagements {
	Meeting = 'meeting',
	Call = 'call',
	Email = 'email',
	Note = 'note',
	'Postal Mail' = 'postal_mail',
	Task = 'task',
}

export enum HubspotSubscriptionType {
	CONTACT_CREATION = 'contact.creation',
	COMPANY_CREATION = 'company.creation',
}

export type ModificationMetadata = {
	readOnlyValue: boolean
	readOnlyDefinition: boolean
	archivable: boolean
}

export type Option = {
	hidden: boolean
	displayOrder: number
	label: string
	value: string
}

export type HubspotObject = {
	hidden: boolean
	modificationMetadata: ModificationMetadata
	displayOrder: number
	description: string
	label: string
	type: string
	hubspotDefined: boolean
	formField: boolean
	createdAt: string // ISO 8601 date string
	groupName: string
	name: string
	options: Option[]
	hasUniqueValue: boolean
	fieldType: string
	calculated: boolean
	externalOptions: boolean
	updatedAt: string // ISO 8601 date string
	referencedObjectType?: string
}
