import { Center } from '@chakra-ui/react'

import { LoadingContainer } from '@/components/Loading'

export const LogsTableBodyLoading = () => {
	return (
		<Center h="full" minH="500px" flexDir="column">
			<LoadingContainer />
		</Center>
	)
}
