import { useCallback, useMemo } from 'react'

import { useAppSelector } from '@/app/hooks'
import { useGetTeamMembersQuery } from '@/app/user/api/members'
import { useTeamId } from '@/app/user/api/teams/selectors'
import { useGetAllQueuesQuery } from '@/modules/queues/api'

import { selectCurrentCanvasNodes } from '../slice/selectors'
import { LabeledRefNodeValue } from '../types/actions'
import { WorkflowIntegrationIds } from '../utils/mappings'

export const useGetMemberOrQueueLabel = () => {
	const { data: queues } = useGetAllQueuesQuery()
	const teamId = useTeamId()
	const { data: members } = useGetTeamMembersQuery(teamId)

	const canvasNodes = useAppSelector(selectCurrentCanvasNodes)
	const allCanvasNodes = useMemo(
		() => Object.values(canvasNodes || {}),
		[canvasNodes],
	)

	return useCallback(
		(objectId: LabeledRefNodeValue): string => {
			const selectedMemberIdValue = objectId.value
				? members?.find((m) => m.id === objectId.value) || null
				: null

			const selectedQueueIdValue = objectId.value
				? queues?.find((m) => m.id === objectId.value) || null
				: null

			const selectedMatchRef = objectId.refNodeId
				? allCanvasNodes?.find(
						(n) =>
							(n.data.integrationId ===
								WorkflowIntegrationIds.salesforceMatchRecord ||
								n.data.integrationId ===
									WorkflowIntegrationIds.hubspotMatchRecord) &&
							n.data.id === objectId.refNodeId,
					) || null
				: null

			const selectedRouteRef = objectId.refNodeId
				? allCanvasNodes?.find(
						(n) =>
							n.data.integrationId === WorkflowIntegrationIds.defaultRoute &&
							n.data.id === objectId.refNodeId,
					) || null
				: null

			return selectedMemberIdValue
				? `${selectedMemberIdValue.first_name} ${selectedMemberIdValue.last_name} (${selectedMemberIdValue.email})`
				: selectedQueueIdValue
					? selectedQueueIdValue.name
					: selectedMatchRef
						? objectId.label || 'Owner'
						: selectedRouteRef
							? 'Round-Robin assignee'
							: objectId.label || 'Unknown value'
		},
		[allCanvasNodes, members, queues],
	)
}
