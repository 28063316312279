import { decodeToken } from '@/utils/helpers/token'

import { useTeamId } from '../teams/selectors'
import { useGetMemberQuery, useGetTeamMembersQuery } from '.'

export const useMemberId = () => {
	return decodeToken()?.id
}

export const useMember = () => {
	return useGetMemberQuery(undefined, {
		selectFromResult: (result) => {
			return {
				member: result.data,
			}
		},
	})
}

export const useSlackMembers = () => {
	const teamId = useTeamId()
	return useGetTeamMembersQuery(teamId, {
		selectFromResult: (result) => {
			return {
				slackMembers: result.data?.filter((member) => member.slack_user_id),
			}
		},
	})
}
