import { Button } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { Borders } from '@/styles/borders'

type Props = {
	variant: 'left' | 'right'
	onClick: () => void
	disabled?: boolean
}

export const PaginationArrowButton = ({
	variant,
	onClick,
	disabled,
}: Props) => {
	return (
		<Button
			px={2}
			h="28px"
			bg={COLORS.background[12]}
			border={Borders.primary}
			borderRadius="6px"
			_hover={{
				borderColor: COLORS.background[9],
			}}
			onClick={onClick}
			disabled={disabled}
		>
			<GetIcon
				icon={Icons.arrow_left}
				transform={variant === 'right' ? 'rotate(180deg)' : 'none'}
				color={COLORS.background[5]}
			/>
		</Button>
	)
}
