import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DateRange } from '@/app/scheduler/types'
import { FiltersGroupSelectedOptions } from '@/common/components/FiltersGroup/type'
import { SortingSelectionType } from '@/common/components/Sorting/type'

export interface QueueLogsState {
	currentPage: number
	searchQuery?: string
	dateRange: DateRange | null
	selectedQueueLog?: string | null
	filters?: FiltersGroupSelectedOptions | null
	sorting?: SortingSelectionType | null
}

export const initialState: QueueLogsState = {
	currentPage: 1,
	searchQuery: '',
	dateRange: null,
	selectedQueueLog: null,
	filters: null,
	sorting: null,
}

export const queueLogsSlice = createSlice({
	name: 'queueLogs',
	initialState,
	reducers: {
		setQueueLogsCurrentPage: (state, { payload }: PayloadAction<number>) => {
			state.currentPage = payload
		},
		setQueueLogsSearchQuery: (state, { payload }: PayloadAction<string>) => {
			state.searchQuery = payload
			state.currentPage = 1
		},
		setQueueLogsDateRange: (
			state,
			{ payload }: PayloadAction<DateRange | null>,
		) => {
			state.dateRange = payload
			state.currentPage = 1
		},
		setSelectedQueueLog: (state, { payload }: PayloadAction<string | null>) => {
			state.selectedQueueLog = payload
			state.currentPage = 1
		},
		setSelectedFilters: (
			state,
			{ payload }: PayloadAction<FiltersGroupSelectedOptions | null>,
		) => {
			state.filters = payload
			state.currentPage = 1
		},
		setSelectedSorting: (
			state,
			{ payload }: PayloadAction<SortingSelectionType | null>,
		) => {
			state.sorting = payload
			state.currentPage = 1
		},
	},
})

export const queueLogsActions = queueLogsSlice.actions
export const queueLogsReducer = queueLogsSlice.reducer
