import { COLORS } from '@ds/tokens/colors'
import { Edge, MarkerType } from 'reactflow'

import { StepType } from '@/modules/workflow/types/nodes'
import { WorkflowIntegrationIds as IntegrationIds } from '@/modules/workflow/utils/mappings'

export const createLabel = (
	handle: string,
	parentIntegrationId?: string | null,
) => {
	if (!parentIntegrationId || !handle) return ''
	switch (parentIntegrationId) {
		case IntegrationIds.salesforceMatchRecord:
			return handle === 'true' ? 'Matched' : 'Not matched'
		case IntegrationIds.hubspotMatchRecord:
			return handle === 'true' ? 'Matched' : 'Not matched'
		case IntegrationIds.defaultDisplayScheduler:
			return handle === 'true' ? 'Meeting booked' : 'Meeting not booked'
		default:
			return handle === 'true' ? 'Is true' : 'Is false'
	}
}

export const createEdge = ({
	parentId,
	childId,
	stepType,
	sourceHandle,
	integrationId,
}: {
	parentId: string
	childId: string
	stepType?: StepType
	sourceHandle?: string | null
	integrationId: string | null
}): Edge => {
	const edge: Edge = {
		id: `${sourceHandle ?? parentId}->${childId}`,
		source: `${parentId}`,
		target: `${childId}`,
		type: 'custom',
		style: {
			stroke: COLORS.background[9],
			color: COLORS.background[9],
			strokeWidth: '2px',
		},
		markerEnd: MarkerType.Arrow,
		interactionWidth: 24,
		data: { sourceStepType: stepType },
	}
	if (sourceHandle) {
		// This will only trigger for direct children of logic nodes
		const handle = sourceHandle.split('&')[1]
		edge.sourceHandle = sourceHandle
		edge.data.labelType = handle
		edge.data.label = createLabel(handle, integrationId)
	}

	return edge
}
