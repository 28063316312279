import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
	getSidebarPreference,
	setSidebarPreference,
} from '../helper/sidebar-preference'

export interface UserState {
	previousPath: string
	is_home_sidebar_expanded: boolean
	isRichTextEditorBackspacing: boolean
}

const initialState: UserState = {
	previousPath: window.location.pathname || '/',
	is_home_sidebar_expanded: getSidebarPreference(),
	isRichTextEditorBackspacing: false,
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setPreviousPath: (state, { payload }: PayloadAction<string>) => {
			state.previousPath = payload
		},
		setIsHomeSidebarExpanded: (state, { payload }: PayloadAction<boolean>) => {
			state.is_home_sidebar_expanded = payload
			setSidebarPreference(payload)
		},
		setIsRichTextEditorBackspacing: (
			state,
			{ payload }: PayloadAction<boolean>,
		) => {
			state.isRichTextEditorBackspacing = payload
		},
	},
})

export const userReducer = userSlice.reducer
export const userActions = userSlice.actions
