import '@/styles/components/RichTextEditor.css'

import { Button, Flex, Text } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import React from 'react'
import { Editor, Range, Text as SlateText, Transforms } from 'slate'
import { GetIcon, Icons } from 'ui'

import { EditingBarProps } from './types'

export const EditingBar: React.FC<EditingBarProps> = ({
	editor,
	disabledEditingBarButtons,
}) => {
	const toggleFormat = (format: string) => {
		const isActive = isFormatActive(editor, format)
		Transforms.setNodes(
			editor,
			{ [format]: isActive ? null : true },
			{ match: SlateText.isText, split: true },
		)
	}

	const isFormatActive = (editor: Editor, format: string) => {
		const [match] = Editor.nodes(editor, {
			match: (n) => n[format] === true,
			mode: 'all',
		})
		return !!match
	}

	const insertTemplateVariable = () => {
		const templateVariable = {
			type: 'template-variable',
			label: '{{ Click to pick data }}',
			data: '{}',
			children: [{ text: '' }],
		}

		if (!editor.selection) {
			// If there's no selection, insert at the end of the document
			Transforms.select(editor, Editor.end(editor, []))
		} else if (Range.isCollapsed(editor.selection)) {
			// If the selection is collapsed (cursor position), insert inline
			Transforms.insertNodes(editor, templateVariable)
		} else {
			// If there's a selection range, replace it with the template variable
			Transforms.delete(editor)
			Transforms.insertNodes(editor, templateVariable)
		}

		// Move the cursor after the inserted template variable
		Transforms.move(editor)
	}

	return (
		<Flex borderRadius="8px 8px 0px 0px" p="4px" bg={ColorTokens.white}>
			<Flex align="center" bg="transparent" w="100%" borderRadius="8px" p="4px">
				{!disabledEditingBarButtons?.includes('bold') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						fontWeight={700}
						fontSize="16px"
						onClick={() => toggleFormat('bold')}
						bg={isFormatActive(editor, 'bold') ? COLORS.purple[4] : ''}
						_hover={{
							bg: isFormatActive(editor, 'bold') ? '' : COLORS.purple[4],
						}}
						mr="4px"
					>
						<GetIcon icon={Icons.bold} boxSize={5} />
					</Button>
				)}

				{!disabledEditingBarButtons?.includes('italic') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						fontSize="16px"
						mr="4px"
						onClick={() => toggleFormat('italic')}
						bg={isFormatActive(editor, 'italic') ? COLORS.purple[4] : ''}
						_hover={{
							bg: isFormatActive(editor, 'italic') ? '' : COLORS.purple[4],
						}}
					>
						<GetIcon icon={Icons.italics} boxSize={5} />
					</Button>
				)}

				{!disabledEditingBarButtons?.includes('strikethrough') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						fontSize="16px"
						textDecor="line-through"
						mr="4px"
						onClick={() => toggleFormat('strikethrough')}
						bg={isFormatActive(editor, 'strikethrough') ? COLORS.purple[4] : ''}
						_hover={{
							bg: isFormatActive(editor, 'strikethrough')
								? ''
								: COLORS.purple[4],
						}}
					>
						<GetIcon icon={Icons.striked_text} boxSize={5} />
					</Button>
				)}

				{!disabledEditingBarButtons?.includes('bulleted-list') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						onClick={() => toggleFormat('bulleted-list')}
						bg={isFormatActive(editor, 'bulleted-list') ? COLORS.purple[4] : ''}
						_hover={{
							bg: isFormatActive(editor, 'bulleted-list')
								? ''
								: COLORS.purple[4],
						}}
						mr="4px"
					>
						<GetIcon icon={Icons.bullet_list} boxSize={5} />
					</Button>
				)}

				{!disabledEditingBarButtons?.includes('numbered-list') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						onClick={() => toggleFormat('numbered-list')}
						bg={isFormatActive(editor, 'numbered-list') ? COLORS.purple[4] : ''}
						_hover={{
							bg: isFormatActive(editor, 'numbered-list')
								? ''
								: COLORS.purple[4],
						}}
						mr="4px"
					>
						<GetIcon icon={Icons.numbered_list} boxSize={5} />
					</Button>
				)}

				{!disabledEditingBarButtons?.includes('blockquote') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						onClick={() => toggleFormat('blockquote')}
						bg={isFormatActive(editor, 'blockquote') ? COLORS.purple[4] : ''}
						_hover={{
							bg: isFormatActive(editor, 'blockquote') ? '' : COLORS.purple[4],
						}}
						mr="4px"
					>
						<GetIcon icon={Icons.quotes} boxSize={4} />
					</Button>
				)}

				{!disabledEditingBarButtons?.includes('variable') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						onClick={insertTemplateVariable}
						bg={
							isFormatActive(editor, 'template-variable')
								? COLORS.purple[4]
								: ''
						}
						_hover={{
							bg: isFormatActive(editor, 'template-variable')
								? ''
								: COLORS.purple[4],
						}}
						mr="4px"
					>
						<Text fontSize="14px">{'{{}}'}</Text>
					</Button>
				)}

				{!disabledEditingBarButtons?.includes('undo') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						onClick={() => editor.undo()}
						bg="rgba(0,0,0,0)"
						_hover={{ bg: COLORS.purple[4] }}
						mr="4px"
						ml="auto"
					>
						<GetIcon icon={Icons.go_back} />
					</Button>
				)}

				{!disabledEditingBarButtons?.includes('redo') && (
					<Button
						h="28px"
						w="32px"
						minW="32px"
						onClick={() => editor.redo()}
						bg="rgba(0,0,0,0)"
						_hover={{ bg: COLORS.purple[4] }}
					>
						<GetIcon icon={Icons.go_forward} />
					</Button>
				)}
			</Flex>
		</Flex>
	)
}
