import { Flex } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { ReactNode } from 'react'

import { Borders } from '@/styles/borders'

export const WebhookPanelContentLayout = ({
	children,
}: {
	children: ReactNode
}) => {
	return (
		<Flex
			flexDir="column"
			w="full"
			border={Borders.primary}
			borderColor={COLORS.background[10]}
			borderRadius="6px"
			px={5}
			py={3}
			gap={2}
		>
			{children}
		</Flex>
	)
}
