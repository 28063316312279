export const DEFAULT_SCHEDULER_WF_STEP_DETAILS = {
	eventId: {
		value: null,
		variable: null,
		refNodeId: null,
	},
	objectId: {
		value: null,
		variable: null,
		refNodeId: null,
		label: null,
	},
	type: {
		value: null,
		variable: null,
		refNodeId: null,
	},
	redirectUrl: {
		value: null,
		variable: null,
		refNodeId: null,
	},
	additionalAttendees: [],
}
