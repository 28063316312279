import { useCallback } from 'react'

import { useAppSelector } from '@/app/hooks'
import SalesforceLogo from '@/assets/logo/salesforce-step.svg'

import { AsyncDrpOptionGenerator } from '../../../components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import { selectSalesforceFields } from '../../../slice/selectors'
import { FieldRefNodeValue } from '../../../types/actions'
import {
	Salesforce_CreateRecord,
	Salesforce_MatchRecord,
} from '../../../types/salesforce'
import { WorkflowIntegrationIds } from '../../../utils/mappings'
import { useAllSalesforceFields } from '../../useAllSalesforceFields'
import { useGetUpstreamNodes } from '../../useGetUpstreamNodes'

export const useGetSalesforceDrpOptions = (): AsyncDrpOptionGenerator => {
	const getAllSalesforceFields = useAllSalesforceFields()
	const fieldsInStore = useAppSelector(selectSalesforceFields)
	const { getUpstreamNodes } = useGetUpstreamNodes()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const fields = fieldsInStore || (await getAllSalesforceFields())
			const upstreamNodes = getUpstreamNodes()
			const salesforceNodes = upstreamNodes.filter(
				(n) =>
					n.data.integrationId ===
						WorkflowIntegrationIds.salesforceMatchRecord ||
					n.data.integrationId ===
						WorkflowIntegrationIds.salesforceCreateRecord,
			)

			const salesforceNodesWithObjectTypes = salesforceNodes?.filter(
				(n) =>
					(
						n.data?.stepDetails as
							| Salesforce_MatchRecord
							| Salesforce_CreateRecord
					)?.type?.value,
			)

			const objectTypesCovered: string[] = []

			const previousSalesforceRecordOptions: DataReferencePickerOption[] = []
			const allSalesforceRecordOptions: DataReferencePickerOption[] = []

			salesforceNodesWithObjectTypes.forEach((n) => {
				const objectType: string = (
					n.data?.stepDetails as
						| Salesforce_MatchRecord
						| Salesforce_CreateRecord
				)?.type?.value

				if (!objectTypesCovered.includes(objectType)) {
					objectTypesCovered.push(objectType)

					previousSalesforceRecordOptions.push({
						label: `Previous ${objectType} record`,
						value:
							fields[objectType]?.map((field, idx) => ({
								label: field.label,
								value: field,
								onSelect: (value: any) => {
									const refNodeId = 'records'
									const variable = `$.salesforceRecords.${objectType}.${field.name}`

									const newRfn: FieldRefNodeValue = {
										refNodeId,
										variable,
										value: null,
										label: `${value.label} (from "Previous ${objectType} record")`,
										dataType: value.type,
										i: idx,
										icon: SalesforceLogo,
									}

									onSelect(newRfn)
								},
							})) || [],
					})
				}

				allSalesforceRecordOptions.push({
					label: `${objectType} from "${n.data.editableName}"`,
					value:
						fields[objectType]?.map((field, idx) => ({
							label: field.label,
							value: field,
							onSelect: (value: any) => {
								const refNodeId = n.data.id
								const variable = `$.${field.name}`

								const newRfn: FieldRefNodeValue = {
									refNodeId,
									variable,
									value: null,
									label: value.label,
									dataType: value.type,
									i: idx,
									icon: SalesforceLogo,
								}

								onSelect(newRfn)
							},
						})) || [],
				})
			})

			return [
				{
					label: 'Salesforce records',
					icon: SalesforceLogo,
					value: [
						...previousSalesforceRecordOptions,
						{
							label: 'All Salesforce records',
							value: [...allSalesforceRecordOptions],
						},
					],
				},
			]
		},
		[fieldsInStore, getAllSalesforceFields, getUpstreamNodes],
	)
}
