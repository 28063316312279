import { SystemDefaultStage } from 'shared-utils'

export const isSystemDefaultStage = (internal_name: string) => {
	if (
		internal_name === SystemDefaultStage.new ||
		internal_name === SystemDefaultStage.meeting_not_booked ||
		internal_name === SystemDefaultStage.meeting_booked ||
		internal_name === SystemDefaultStage.no_show ||
		internal_name === SystemDefaultStage.cancelled
	) {
		return true
	}

	return false
}

export const getLeadPagePath = (leadId?: string | null) => {
	if (!leadId) return '/leads/views/all_leads'
	return `/leads/views/all_leads/${leadId}/overview`
}
