import { Route, Routes } from 'react-router-dom'

import { WorkflowLogsPage } from '@/modules/workflow-logs'

import { WorkflowEditorLayout } from '../layouts/WorkflowEditorLayout'
import { WorkflowBuilderPage } from '../pages/WorkflowBuilderPage'

export const WorkflowEditorRouter = () => {
	return (
		<Routes>
			<Route path="*" element={<WorkflowEditorLayout />}>
				<Route path="editor" element={<WorkflowBuilderPage />} />
				<Route path="workflow-logs">
					<Route path="" element={<WorkflowLogsPage />} />
				</Route>
			</Route>
		</Routes>
	)
}
