import { useSelectedWorkflow } from '@/modules/workflow/hooks/workflow'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataTag } from '../MetaDataTag'
import { MetaDataTitle } from '../MetaDataTitle'

export const FormSubmissionDetails = () => {
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="Form:" />
			<MetaDataTag label={selectedWorkflow?.name || 'Untitled form'} />
		</MetaDataRowLayout>
	)
}
