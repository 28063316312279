import { Flex } from '@chakra-ui/react'

import { NavigateToFormCard } from '../NavigateToFormCard'
import { PanelInputLabel } from '../side-panel/panel-variants/PanelInputLabel'

export const FormSubmissionTrigger = () => {
	return (
		<Flex w="full" flexDir="column">
			<PanelInputLabel label="Form connected" fontWeight={500} />
			<NavigateToFormCard />
		</Flex>
	)
}
