import { Accordion, Flex } from '@chakra-ui/react'
import { useState } from 'react'

import { SuccessBlock } from '@/common/components/SuccessBlock'

import { WebhookTriggerTestPayloadConfig } from '../../types/trigger'
import { WebhookHeadersConfig } from './WebhookHeadersConfig'
import { WebhookPayloadConfig } from './WebhookPayloadConfig'

type Props = {
	triggerConfig: WebhookTriggerTestPayloadConfig | null
}

export const WebhookConfigurationsStep = ({ triggerConfig }: Props) => {
	const [expandedIndex, setExpandedIndex] = useState([1])

	return (
		<Flex
			flexDir="column"
			w="full"
			p={5}
			minH="400px"
			maxH="65vh"
			h="full"
			gap={3}
			overflowY="scroll"
		>
			<SuccessBlock
				title={`Success! We've got your details.`}
				description="Default will soon use this data to set up your webhook trigger."
			/>
			<Accordion
				allowMultiple
				index={expandedIndex}
				onChange={(indices) => {
					setExpandedIndex(indices as number[])
				}}
			>
				<WebhookHeadersConfig
					headers={triggerConfig?.headers}
					isExpanded={expandedIndex.includes(0)}
				/>
				<WebhookPayloadConfig
					payload={triggerConfig?.body}
					isExpanded={expandedIndex.includes(1)}
				/>
			</Accordion>
		</Flex>
	)
}
