import { useCallback } from 'react'

import { useUpdateFormMutation } from '@/modules/forms/api'

import { ConditionBranch } from '../types/logic'
import { SalesforceEntities } from '../types/salesforce'
import { useSelectedWorkflow, useWorkflowReadOnly } from './workflow'

export type MeetingTriggerConfig = {
	events?: number[]
	all?: boolean
	links?: boolean
	workflows?: boolean
}

export const useUpdateMeetingTriggerConfig = () => {
	const [updateForm] = useUpdateFormMutation()
	const isReadOnly = useWorkflowReadOnly()
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	return useCallback(
		(config: MeetingTriggerConfig) => {
			if (isReadOnly || !selectedWorkflow) return

			updateForm({
				form: {
					id: selectedWorkflow.id,
					meta: {
						...selectedWorkflow.meta,
						trigger_config: config,
					},
				},
			})
		},
		[isReadOnly, selectedWorkflow, updateForm],
	)
}

export const useUpdateTagAssignedTriggerConfig = () => {
	const [updateForm] = useUpdateFormMutation()
	const isReadOnly = useWorkflowReadOnly()
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	return useCallback(
		(config: { tags: number[] }) => {
			if (isReadOnly || !selectedWorkflow) return

			updateForm({
				form: {
					id: selectedWorkflow.id,
					meta: {
						...selectedWorkflow.meta,
						trigger_config: config,
					},
				},
			})
		},
		[isReadOnly, selectedWorkflow, updateForm],
	)
}

export type SalesforceCreateTriggerConfig = {
	object?: SalesforceEntities
	formula?: string | null
	branches?: ConditionBranch[]
}

export const useUpdateSalesforceCreateTriggerConfig = () => {
	const [updateForm] = useUpdateFormMutation()
	const isReadOnly = useWorkflowReadOnly()
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	return useCallback(
		(config: SalesforceCreateTriggerConfig) => {
			if (isReadOnly || !selectedWorkflow) return

			updateForm({
				form: {
					id: selectedWorkflow.id,
					meta: {
						...selectedWorkflow.meta,
						trigger_config: {
							...selectedWorkflow.meta?.trigger_config,
							triggerType: 'Create',
							...config,
						},
					},
				},
			})
		},
		[isReadOnly, selectedWorkflow, updateForm],
	)
}

export type SalesforceUpdateTriggerConfig = {
	object?: SalesforceEntities
	formula?: string
}

export const useUpdateSalesforceUpdateTriggerConfig = () => {
	const [updateForm] = useUpdateFormMutation()
	const isReadOnly = useWorkflowReadOnly()
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	return useCallback(
		(config: SalesforceUpdateTriggerConfig) => {
			if (isReadOnly || !selectedWorkflow) return

			updateForm({
				form: {
					id: selectedWorkflow.id,
					meta: {
						...selectedWorkflow.meta,
						trigger_config: {
							...selectedWorkflow.meta?.trigger_config,
							triggerType: 'Update',
							...config,
						},
					},
				},
			})
		},
		[isReadOnly, selectedWorkflow, updateForm],
	)
}

export type HubspotCreateTriggerConfig = {
	subscriptionType?: string
	properties?: string[]
}

export const useUpdateHubspotCreateTriggerConfig = () => {
	const [updateForm] = useUpdateFormMutation()
	const isReadOnly = useWorkflowReadOnly()
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	return useCallback(
		(config: HubspotCreateTriggerConfig) => {
			if (isReadOnly || !selectedWorkflow) return

			updateForm({
				form: {
					id: selectedWorkflow.id,
					meta: {
						...selectedWorkflow.meta,
						trigger_config: {
							...selectedWorkflow.meta?.trigger_config,
							...config,
						},
					},
				},
			})
		},
		[isReadOnly, selectedWorkflow, updateForm],
	)
}
