import { Button } from '@chakra-ui/react'
import { components, DropdownIndicatorProps } from 'react-select'

import { BTN } from '@/styles/components/button'

export const DropdownIndicator = (
	props: DropdownIndicatorProps<any, boolean, any>,
) => {
	if (props.selectProps.isDisabled) return null
	return (
		<components.DropdownIndicator {...props}>
			<Button variant={BTN.workflows_side_panel}>Edit</Button>
		</components.DropdownIndicator>
	)
}
