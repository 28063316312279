import { components, MultiValueGenericProps } from 'react-select'

export const MultiValueContainer = (
	props: MultiValueGenericProps<any, boolean, any>,
) => {
	if (props.selectProps.isLoading) return null

	return (
		<components.MultiValueContainer {...props}>
			{props.children}
		</components.MultiValueContainer>
	)
}
