import { HStack, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

type Props = {
	warning: string
	icon?: Icons
}

export const MetaDataWarningTag = ({ warning, icon }: Props) => {
	return (
		<HStack
			px={2}
			py={1}
			borderRadius="6px"
			border={Borders.primary}
			borderColor={COLORS.orange[4]}
			bg={COLORS.orange[2]}
			color={COLORS.orange[9]}
		>
			<GetIcon icon={icon || Icons.alert_triangle} />
			<Text w="full" maxW="225px" variant={TEXT_ENUM.medium14} noOfLines={2}>
				{warning}
			</Text>
		</HStack>
	)
}
