import { Icons } from '@ds/Icons'
import { useCallback } from 'react'

import {
	useLazyGetSlackChannelsQuery,
	useLazyGetSlackUsersQuery,
} from '@/app/integrations/api/slack'
import SlackLogo from '@/assets/logo/slack.svg'
import { AsyncDrpOptionGenerator } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker/components/Option'
import { LabeledRefNodeValue } from '@/modules/workflow/types/actions'

export const useGetSlackResourcesDrpOptions = (): AsyncDrpOptionGenerator => {
	const [fetchMembers] = useLazyGetSlackUsersQuery()
	const [fetchChannels] = useLazyGetSlackChannelsQuery()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const { data: members } = await fetchMembers()
			const { data: channels } = await fetchChannels()

			const slackMembersOptions = (members || [])
				.flat()
				.filter((user) => {
					if (user?.is_bot) return false
					if (user?.deleted) return false
					if (user?.profile?.real_name === 'Slackbot') return false
					return true
				})
				.map((m) => {
					const label = m.profile?.real_name || 'Unknown name'

					const newRfn: LabeledRefNodeValue = {
						refNodeId: null,
						variable: null,
						value: m.id,
						label,
						icon: Icons.person,
					}

					return {
						label,
						value: newRfn,
						onSelect: (value: any) => {
							onSelect(value)
						},
					}
				})

			const slackMembersOptionsGroup = {
				label: 'Slack members',
				value: slackMembersOptions,
				icon: SlackLogo,
			}

			const slackChannelsOptions = (channels || [])
				.filter((c) => !c.is_archived)
				.map((c) => {
					const label = `#${c.name}`

					const newRfn: LabeledRefNodeValue = {
						refNodeId: null,
						variable: null,
						value: c.id,
						label,
						icon: Icons.hashtag,
					}

					return {
						label,
						value: newRfn,
						onSelect: (value: any) => {
							onSelect(value)
						},
					}
				})

			const slackChannelsOptionsGroup = {
				label: 'Slack channels',
				value: slackChannelsOptions,
				icon: SlackLogo,
			}

			return [
				{
					label: 'Slack resources',
					value: [slackMembersOptionsGroup, slackChannelsOptionsGroup],
					icon: SlackLogo,
				},
			]
		},
		[fetchMembers, fetchChannels],
	)
}
