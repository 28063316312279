import { useCallback } from 'react'

import ApolloLogo from '@/assets/logo/apollo.png'

import { DataReferencePickerOption } from '../../../components/side-panel/panel-content/DataReferencePicker/components/Option'
import {
	defaultAttributes,
	WorkflowIntegrationIds,
} from '../../../utils/mappings'
import { useGetUpstreamNodes } from '../../useGetUpstreamNodes'

export const useGetApolloDrpOptions = () => {
	const { getUpstreamNodes } = useGetUpstreamNodes()

	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const upstreamNodes = getUpstreamNodes()
			const apolloNode = upstreamNodes.find(
				(n) => n.data.integrationId === WorkflowIntegrationIds.apolloEnrich,
			)

			if (!apolloNode) return []

			const apolloPersonOptions = Object.entries(defaultAttributes)
				.filter(([, v]) => v.type === 'apollo.person')
				.map(([, object]) => ({
					label: object.name,
					value: object.key,
					onSelect: (value: any) => {
						const newRfn = {
							refNodeId: apolloNode.data.id,
							variable: `$.${value}`,
							value: null,
							label: object.name,
							icon: ApolloLogo,
						}
						onSelect(newRfn)
					},
				}))

			const apolloCompanyOptions = Object.entries(defaultAttributes)
				.filter(([, v]) => v.type === 'apollo.company')
				.map(([, object]) => ({
					label: object.name,
					value: object.key,
					onSelect: (value: any) => {
						const newRfn = {
							refNodeId: apolloNode.data.id,
							variable: `$.${value}`,
							value: null,
							label: object.name,
							icon: ApolloLogo,
						}
						onSelect(newRfn)
					},
				}))

			return [
				{
					label: 'Apollo person data',
					icon: ApolloLogo,
					value: apolloPersonOptions,
				},
				{
					label: 'Apollo company data',
					icon: ApolloLogo,
					value: apolloCompanyOptions,
				},
			]
		},
		[getUpstreamNodes],
	)
}
