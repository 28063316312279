import { Hubspot_MergeRecord } from '@/modules/workflow/types/hubspot'
import { WorkflowNode } from '@/modules/workflow/types/nodes'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataTag } from '../MetaDataTag'
import { MetaDataTitle } from '../MetaDataTitle'
import { MetaDataWarningTag } from '../MetaDataWarningTag'

export const HubspotUpdateDetails = ({ data }: { data: WorkflowNode }) => {
	const stepDetails = data?.stepDetails as Hubspot_MergeRecord

	const recordType = stepDetails?.type?.value

	if (!recordType) {
		return (
			<MetaDataRowLayout>
				<MetaDataTitle title="Record Type:" />
				<MetaDataWarningTag warning="Select an option" />
			</MetaDataRowLayout>
		)
	}

	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="Record Type:" />
			<MetaDataTag label={recordType} />
		</MetaDataRowLayout>
	)
}
