import type { ComponentStyleConfig } from '@chakra-ui/react'

// import { baseStyle } from '@chakra-ui/react'
import { RADII } from '../../../tokens/borders'
import { COLORS, ColorTokens } from '../../../tokens/colors'
import { InputVariants } from '../../../tokens/input'
import { SHADOWS } from '../../../tokens/shadows'
import { SPACING } from '../../../tokens/space'
import { FONT_SIZES, WEIGHT } from '../../../tokens/typography'

const Input: ComponentStyleConfig = {
	baseStyle: {
		field: {
			height: SPACING[10],
			bg: COLORS.background[12],
			color: COLORS.gray[12],
			fontWeight: WEIGHT.medium,
			fontSize: FONT_SIZES.sm,
			lineHeight: SPACING[5],
			borderRadius: RADII.lg,
			px: '12px',
			py: '10px',

			border: '1px solid',
			borderColor: COLORS.gray[4],

			_hover: {
				borderColor: COLORS.background[9],
			},
			_focus: { boxShadow: SHADOWS.focus, zIndex: 5 },

			_disabled: {
				bg: COLORS.background[11],
				borderColor: COLORS.gray[4],
				color: COLORS.gray[12],
			},
		},
	},
	variants: {
		[InputVariants.secondary]: () => ({
			field: {
				height: 9,
				color: 'text',
				background: COLORS.gray[2],
				px: 3,
				borderRadius: 'md',
				borderWidth: '1px',
				borderColor: ColorTokens.border_primary,
				fontWeight: 400,
				fontSize: 'sm',
				_focus: {
					boxShadow: 'focus',
				},
			},
		}),
	},
	defaultProps: {
		size: null,
		// variant: baseStyle,
	},
}

export default Input
