import { PartialExcept, SchedulingLinkField } from 'shared-utils'

import { TimeSlot } from '@/app/types'
import { QueueWithMembers } from '@/modules/queues/types'

export interface EventReminderTable {
	id: number
	event_id: number
	created_at: string
	subject: string
	body: string
	logo: string
	times: { unit: string; value: number }[] | null
	link_details: boolean
}

export enum EventTypeEnum {
	'personal' = 'personal',
	'global' = 'global',
	'group' = 'group',
}

export enum EventConferecingEnum {
	'native' = 'native',
	'zoom' = 'zoom',
	'in_person' = 'in_person',
}

export interface SchedulerEventTable {
	id: number
	team_id: number
	name: string
	title: string
	location: string | null
	duration: number
	description: string
	enabled: boolean
	pre_meeting_buffer: number
	post_meeting_buffer: number
	notice_period: number
	active_time: Array<TimeSlot> | null
	type: EventTypeEnum
	scheduling_fields: SchedulingLinkField[]
	timeslot_increment: number | null
	calendar_title: string | null
	calendar_description: string | null
	created_by?: string | null
	host_id?: string | null
	conferencing: EventConferecingEnum | null
	event_name?: string | null
}

export interface SchedulerEvent extends SchedulerEventTable {
	reminders: EventReminderTable | null
}

export interface SchedulerEventWithQueues extends SchedulerEvent {
	reminders: EventReminderTable | null
	queues: { queue: QueueWithMembers }[]
}

export interface CreateEventPayload {
	event: Partial<SchedulerEventTable>
}

export interface UpdateEventPayload {
	event: PartialExcept<SchedulerEventTable, 'id'>
}

export interface UpdateEventReminderPayload {
	reminders: PartialExcept<EventReminderTable, 'event_id'>
}

export interface LinkedForm {
	id: number
	name: string
}

export interface EventQueuesTable {
	id: string
	event_id: number
	queue_id: number
}

export type AttachQueuePayload = {
	eventId: number | string
	queueId: number | string
}

export type DetachQueuePayload = {
	eventId: number | string
	queueId: number | string
}
