import { Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { useNavigate } from 'react-router-dom'
import { MultiValue } from 'react-select'

import { useGetTagsQuery } from '@/app/tags/api'
import { useTeamId } from '@/app/user/api/teams/selectors'
import { TEXT_ENUM } from '@/styles/components/text'

import { useUpdateTagAssignedTriggerConfig } from '../../hooks/triggers-config'
import { useSelectedWorkflow } from '../../hooks/workflow'
import { PanelInputLabel } from '../side-panel/panel-variants/PanelInputLabel'
import { PanelSelect } from '../side-panel/panel-variants/PanelSelect'

export const TagAssignedTrigger = () => {
	const nav = useNavigate()
	const teamId = useTeamId()
	const { data: allTagsData, isLoading: isAllTagsDataLoading } =
		useGetTagsQuery({ teamId })
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
	const updateTagAssignedConfig = useUpdateTagAssignedTriggerConfig()

	const allTags = Object.values(allTagsData || {})
	const selectedTagIds: number[] =
		selectedWorkflow?.meta?.trigger_config?.tags || []

	const options =
		allTags?.map((tag) => ({
			label: tag.label,
			value: tag.id,
		})) || []

	const selectedOptions = options.filter((option) =>
		selectedTagIds.includes(option.value),
	)

	const handleChange = (
		options: MultiValue<{ label: string; value: number }>,
	) => {
		const tags = options.map((option) => option.value)

		updateTagAssignedConfig({
			tags,
		})
	}

	const naviagteToTagManager = () => {
		nav('/settings/configuration/tags')
	}

	return (
		<Flex>
			<Flex w="full" flexDir="column">
				<PanelInputLabel label="Tag" fontWeight={500} />
				<Text mb={4} variant={TEXT_ENUM.regular14} color={COLORS.background[5]}>
					Select one or multiple tags for leads in that flow for a better
					differentiation. To remove unused tags or duplicates go to{' '}
					<Text
						as="span"
						variant={TEXT_ENUM.regular14}
						color={COLORS.purple[9]}
						textDecoration="underline"
						cursor="pointer"
						onClick={naviagteToTagManager}
					>
						Tag manager
					</Text>
					.
				</Text>
				<PanelSelect
					placeholder="Select tag(s)"
					options={options}
					value={selectedOptions}
					isLoading={isAllTagsDataLoading}
					isMulti
					onChange={handleChange}
				/>
			</Flex>
		</Flex>
	)
}
