import { ComponentStyleConfig } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'

export enum TOOLTIP_ENUM {
	workflows_side_panel = 'workflows_side_panel',
}

export const Tooltip: ComponentStyleConfig = {
	baseStyle: {
		bg: COLORS.background[3],
		color: ColorTokens.white,
	},
	variants: {
		[TOOLTIP_ENUM.workflows_side_panel]: {
			fontSize: '12px',
			bg: COLORS.gray[12],
			color: COLORS.whiteAlpha[12],
			p: '6px 12px',
			borderRadius: '8px',
		},
	},
}
