import { ColumnDef } from '@tanstack/react-table'
import { formatUTCDate, getFullName } from 'shared-utils'

import { FormType } from '@/modules/forms/types'

import { WorkflowLog } from '../../types'
import { LogsCrmRecordLinkCellRender } from './CellRender/LogsCrmRecordLinkCellRender'
import { LogsStatusCellRender } from './CellRender/LogsStatusCellRender'

type LogTableColumnType = ColumnDef<WorkflowLog>

const FormSubmissionLogsTableColumns = {
	lead_name: 'lead_name',
}

const CrmRecordLogsTableColumns = {
	record_name: 'record_name',
	record_id: 'record_id',
}

const SharedWorkflowLogsTableColumns = {
	email: 'email',
	created_at: 'created_at',
	status: 'status',
}

const MeetingBookedLogsTableColumns = {
	meeting_title: 'meeting_title',
}

const TagAssignedLogsTableColumns = {
	tags_assigned: 'tags_assigned',
}

export const WorkflowLogsTableColumns = {
	...FormSubmissionLogsTableColumns,
	...CrmRecordLogsTableColumns,
	...SharedWorkflowLogsTableColumns,
	...MeetingBookedLogsTableColumns,
	...TagAssignedLogsTableColumns,
}

export const getLogsTableColumns = ({
	formType,
}: {
	formType: FormType | null
}) => {
	if (!formType) {
		return []
	}

	const columns: LogTableColumnType[] = []
	switch (formType) {
		case FormType.regular:
		case FormType.imported:
			columns.push(
				{
					id: WorkflowLogsTableColumns.email,
					accessorKey: 'email',
					header: 'Lead Email',
					size: 320,
				},
				{
					id: WorkflowLogsTableColumns.lead_name,
					header: 'Lead Name',
					accessorFn: (row) =>
						getFullName({
							firstName: row.lead?.first_name,
							lastName: row.lead?.last_name,
						}),
					size: 260,
				},
			)
			break
		case FormType.meeting_booked_trigger:
		case FormType.meeting_cancelled_trigger:
		case FormType.meeting_rescheduled_trigger:
		case FormType.meeting_reassigned_trigger:
		case FormType.meeting_no_show_trigger:
			columns.push(
				{
					id: WorkflowLogsTableColumns.email,
					header: 'Lead Email',
					accessorFn: (row) => row.lead?.email,
					size: 320,
				},
				{
					id: WorkflowLogsTableColumns.meeting_title,
					header: 'Meeting Title',
					accessorFn: (row) => row.meetingBooked?.title,
					size: 260,
				},
			)
			break
		case FormType.tag_assigned_trigger:
			columns.push(
				{
					id: WorkflowLogsTableColumns.email,
					header: 'Lead Email',
					accessorFn: (row) => row.lead?.email,
					size: 320,
				},
				{
					id: WorkflowLogsTableColumns.meeting_title,
					header: 'Tags Assigned',
					accessorFn: (row) =>
						row.tagsAssigned?.map((tag) => tag.tagName).join(', '),
					size: 260,
				},
			)
			break
		case FormType.salesforce_object_create_trigger:
		case FormType.salesforce_object_update_trigger:
		case FormType.hubspot_trigger:
			columns.push(
				{
					id: WorkflowLogsTableColumns.record_name,
					header: 'Record Name',
					accessorFn: (row) => {
						const crmRecord = row.crmRecord || {}
						return crmRecord[crmRecord.recordNameField]
					},
					size: 260,
				},
				{
					id: WorkflowLogsTableColumns.record_id,
					header: 'Record ID',
					size: 260,
					cell: (info) => {
						return (
							<LogsCrmRecordLinkCellRender
								cell={info.cell}
								triggerType={formType}
							/>
						)
					},
				},
			)
			break
		case FormType.webhook_trigger:
			// No additional columns needed for now because of the variability of webhook responses
			break
		default:
			break
	}

	columns.push(
		{
			id: WorkflowLogsTableColumns.created_at,
			header: 'Time',
			accessorFn: (row) =>
				formatUTCDate({
					date: row.created_at,
				}),
			size: 260,
		},
		{
			id: WorkflowLogsTableColumns.status,
			header: 'Status',
			size: 100,
			cell: (info) => <LogsStatusCellRender cell={info.cell} />,
		},
	)

	return columns
}
