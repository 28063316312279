import { Input, InputProps } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { SHADOWS } from '@/styles/constants'

export const PanelSearchInput = ({ ...props }: InputProps) => {
	return (
		<Input
			borderColor="#E6E6E6"
			bg={COLORS.background[12]}
			fontSize="14px"
			fontWeight={500}
			py={2}
			px={2.5}
			_focus={{
				boxShadow: SHADOWS.focused,
				borderColor: 'transparent',
			}}
			_hover={{
				borderColor: COLORS.background[9],
			}}
			_placeholder={{
				color: COLORS.background[6],
			}}
			{...props}
		/>
	)
}
