import { COLORS } from '@ds/tokens/colors'
import { ClearIndicatorProps, components } from 'react-select'
import { GetIcon, Icons } from 'ui'

interface CustomProps {
	customClearValue?: () => void
}

export const ClearIndicator = ({
	customClearValue,
	...props
}: ClearIndicatorProps<any, boolean, any> & CustomProps) => {
	if (!props.selectProps.isClearable || !customClearValue) return null

	return (
		<components.ClearIndicator {...props}>
			<GetIcon
				icon={Icons.close}
				color={COLORS.background[7]}
				boxSize={3}
				onClick={customClearValue}
			/>
		</components.ClearIndicator>
	)
}
