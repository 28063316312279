import { Center, Flex, Image, Link, Text } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { useState } from 'react'
import { useMount } from 'react-use'

import { FormType } from '@/modules/forms/types'
import { CRM_TYPES_BY_TRIGGER } from '@/modules/workflow/constants/crm-types'
import { TRIGGERS_CONTENT } from '@/modules/workflows-home/constants/triggers-content'
import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

import { useGetCrmInstanceUrl } from '../hooks/useGetCrmInstanceUrl'
import { WorkflowLog } from '../types'

type Props = {
	workflowLog: WorkflowLog | null
	triggerType: FormType
}

export const WorkflowCrmRecordPartial = ({
	workflowLog,
	triggerType,
}: Props) => {
	const crmRecord = workflowLog?.crmRecord

	const recordNameField = crmRecord?.recordNameField
	const recordName = crmRecord?.[recordNameField]
	const recordType = crmRecord?.recordType
	const recordId = crmRecord?.id || crmRecord?.Id

	const logo = TRIGGERS_CONTENT[triggerType].logo

	const getCrmInstanceUrl = useGetCrmInstanceUrl()
	const [instanceUrl, setInstanceUrl] = useState<string | null>(null)

	const crmType = Object.keys(CRM_TYPES_BY_TRIGGER).find((key) =>
		CRM_TYPES_BY_TRIGGER[key].includes(triggerType),
	)

	useMount(() => {
		getCrmInstanceUrl(crmType).then((url) => {
			setInstanceUrl(url)
		})
	})

	return (
		<>
			<Flex gap={4}>
				<Center
					flex="0 0 auto"
					borderRadius="full"
					border={Borders.primary}
					bg={ColorTokens.white}
					w={16}
					h={16}
				>
					<Image src={logo} alt="logo" maxW="42px" />
				</Center>
				<Flex flexDir="column" gap={1}>
					<Text variant={TEXT_ENUM.semibold18}>{recordName}</Text>
					<Text variant={TEXT_ENUM.regular14} color={COLORS.background[4]}>
						Id: {recordId}
					</Text>
				</Flex>
			</Flex>
			<Link
				href={`${instanceUrl}/${recordId}`}
				_hover={{ textDecoration: 'none' }}
				_focus={{ textDecoration: 'none' }}
				target="_blank"
			>
				<Text color={COLORS.purple[9]} variant={TEXT_ENUM.medium14}>
					View {recordType} details in {crmType}
				</Text>
			</Link>
		</>
	)
}
