import { useCallback } from 'react'
import { Icons } from 'ui'

import { AsyncDrpOptionGenerator } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker'
import { DataReferencePickerOption } from '@/modules/workflow/components/side-panel/panel-content/DataReferencePicker/components/Option'
import { LabeledRefNodeValue } from '@/modules/workflow/types/actions'

const LEAD_FIELDS_LABELS = {
	domain: 'Domain from email address',
	email: 'Email',
	first_name: 'First name',
	last_name: 'Last name',
	phone: 'Phone',
	company: 'Company name',
	website: 'Website',
	created_at: '"Created at" date',
	last_submission_at: 'Last submission date',
	stage_id: 'Current stage',
}

export const useGetLeadDrpOptions = (): AsyncDrpOptionGenerator => {
	return useCallback(
		async ({
			onSelect,
		}: {
			onSelect: (value: any) => void
		}): Promise<DataReferencePickerOption[]> => {
			const leadOptions = Object.entries(LEAD_FIELDS_LABELS).map(
				([key, label]) => ({
					label,
					value: key,
					onSelect: () => {
						const newRfn: LabeledRefNodeValue = {
							refNodeId: '0',
							variable: `$.lead.${key}`,
							value: null,
							label,
							icon: Icons.person,
						}

						onSelect(newRfn)
					},
				}),
			)

			return [
				{
					label: 'Lead attribute data',
					icon: Icons.person,
					value: leadOptions,
				},
			]
		},
		[],
	)
}
