// @ts-nocheck
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Button, Flex, HStack, Text } from '@chakra-ui/react'
import {
	CalendarDate,
	createCalendar,
	DateValue,
} from '@internationalized/date'
import {
	CalendarProps,
	RangeCalendarProps,
	useCalendar,
	useRangeCalendar,
} from '@react-aria/calendar'
import { useLocale } from '@react-aria/i18n'
import { useRangeCalendarState } from '@react-stately/calendar'
import { createRef, useEffect } from 'react'
import { useCalendarState } from 'react-stately'

import { BTN } from '@/styles/components/button'
import { TEXT_ENUM } from '@/styles/components/text'

import { CalendarButton } from './CalendarButton'
import { CalendarGrid, RangeCalendarGrid } from './CalendarGrid'

interface Props {
	props: CalendarProps<DateValue>
	setDay: (arg: any) => void
	availableDateStrings?: string[]
	setVisibleRange?: (arg: { start: CalendarDate; end: CalendarDate }) => void
}

export const SingleSelectCalendar = ({
	setDay,
	availableDateStrings,
	setVisibleRange,
	props,
}: Props) => {
	const { locale } = useLocale()
	const state = useCalendarState({
		...props,
		visibleDuration: { months: 1 },
		locale,
		createCalendar,
		isDateUnavailable: (d: DateValue) => {
			const date = d.toDate(Intl.DateTimeFormat().resolvedOptions().timeZone)
			return availableDateStrings
				? !availableDateStrings.includes(
						`${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
					)
				: false
		},
	})
	const ref = createRef<HTMLDivElement>()
	const { calendarProps, prevButtonProps, nextButtonProps, title } =
		// @ts-ignore
		useCalendar(props, state)

	useEffect(() => {
		setDay(state.value)
	}, [state.value])

	useEffect(() => {
		if (setVisibleRange) {
			// @ts-ignore
			setVisibleRange(state.visibleRange)
		}
	}, [state.visibleRange])

	return (
		<div {...calendarProps} ref={ref} style={{ width: '100%' }}>
			<Flex w="286px" direction="column" align="center" py={6}>
				<Flex w="100%" alignItems="center" justify="space-between" mb={8}>
					<CalendarButton {...prevButtonProps}>
						<ChevronLeftIcon w={5} h={5} />
					</CalendarButton>
					<Text fontSize="16px" fontWeight="bold">
						{title}
					</Text>
					<CalendarButton {...nextButtonProps}>
						<ChevronRightIcon w={5} h={5} />
					</CalendarButton>
				</Flex>
				<CalendarGrid state={state} />
			</Flex>
		</div>
	)
}

type RangeProps = RangeCalendarProps<DateValue>

export const RangeCalendar = (props: RangeProps) => {
	const { locale } = useLocale()
	const state = useRangeCalendarState({
		...props,
		visibleDuration: { months: 1 },
		locale,
		createCalendar,
	})

	const ref = createRef<HTMLDivElement>()
	const { calendarProps, prevButtonProps, nextButtonProps, title } =
		// @ts-ignore
		useRangeCalendar(props, state, ref)

	const now = new Date()
	const currentDate = new CalendarDate(
		now.getFullYear(),
		now.getMonth() + 1,
		now.getDate(),
	)
	const timeRanges = [
		{
			label: 'This Week',
			action: () => {
				state.setValue({
					start: currentDate.subtract({ weeks: 1 }),
					end: currentDate,
				})
			},
		},
		{
			label: 'Last Month',
			action: () => {
				state.setValue({
					start: currentDate.subtract({ months: 1 }),
					end: currentDate,
				})
			},
		},
		{
			label: 'Last Year',
			action: () => {
				state.setValue({
					start: currentDate.subtract({ years: 1 }),
					end: currentDate,
				})
			},
		},
	]

	return (
		<div {...calendarProps} ref={ref} style={{ width: '100%' }}>
			<Flex
				w="100%"
				alignItems="center"
				justify="space-between"
				pb={1.5}
				px={6}
				mb={4}
			>
				<CalendarButton {...prevButtonProps}>
					<ChevronLeftIcon w={5} minH={6} />
				</CalendarButton>
				<Text variant={TEXT_ENUM.bold16}>{title}</Text>
				<CalendarButton {...nextButtonProps}>
					<ChevronRightIcon w={5} minH={6} />
				</CalendarButton>
			</Flex>

			<HStack px={6} mb={3}>
				{timeRanges.map((t, i) => (
					<Button key={i} variant={BTN.hyperlink} onClick={() => t.action()}>
						{t.label}
					</Button>
				))}
			</HStack>
			<Flex justify="center" pb={4}>
				<RangeCalendarGrid state={state} />
			</Flex>
		</div>
	)
}
