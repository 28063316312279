import Color from 'color'

import { defaultFormStyle } from './default-theme'

type GetAdjustedColorsArgs = {
	backgroundColor: string
	color: string
	isTranslucent: boolean
	buttonBackgroundColor: string
}

export const getAdjustedColors = ({
	backgroundColor,
	color,
	isTranslucent,
	buttonBackgroundColor,
}: GetAdjustedColorsArgs) => {
	let bg: Color<string>
	try {
		bg = Color(backgroundColor)
	} catch (error) {
		bg = Color(defaultFormStyle.formAreaBackgroundColor)
	}

	let textColor: Color<string>
	try {
		textColor = Color(color)
	} catch (error) {
		textColor = Color(defaultFormStyle.primaryFormAreaColor)
	}

	let buttonColor: Color<string>
	try {
		buttonColor = Color(buttonBackgroundColor)
	} catch (error) {
		buttonColor = Color(defaultFormStyle.primaryButtonsBackground)
	}

	let adjustedBg = ''
	let adjustedBorderColor = ''
	let adjustedSelectColor = ''
	let popoverBgColor = ''
	let popoverColor = ''

	if (isTranslucent) {
		adjustedBg = `${textColor.hex()}14`
		adjustedBorderColor = `${textColor.hex()}29`
		adjustedSelectColor = textColor.toString()
		popoverBgColor = '#fff'
		popoverColor = '#222B48'
	} else {
		adjustedBg = bg.isLight()
			? bg.lightness(bg.lightness() - 2).toString()
			: bg.lightness(bg.lightness() + 2).toString()

		adjustedBorderColor = textColor.isLight()
			? textColor.lightness(textColor.lightness() - 80).toString()
			: textColor.lightness(textColor.lightness() + 80).toString()

		adjustedSelectColor = textColor.isLight()
			? textColor.lightness(textColor.lightness() - 60).toString()
			: textColor.lightness(textColor.lightness() + 60).toString()
		popoverBgColor = bg.isLight()
			? bg.lightness(bg.lightness() - 2).toString()
			: bg.lightness(bg.lightness() + 2).toString()
		popoverColor = textColor.toString()
	}

	return {
		adjustedBg,
		adjustedBorderColor,
		adjustedSelectColor,
		placeholderColor: textColor.fade(0.4).toString(),
		focusColor: buttonColor.fade(0.4).toString(),
		popoverBgColor: popoverBgColor,
		popoverColor,
	}
}

/**
 * Todo use this functions in demeter as well
 */
type GetsManipulatedColorsArgs = {
	bg_primary: string | undefined
	is_translucent: boolean | undefined
	text_primary: string | undefined
	btn_bg_primary: string | undefined
	btn_color_primary: string | undefined
}

export const getManipulatedColors = ({
	bg_primary,
	text_primary,
	btn_bg_primary,
	is_translucent,
}: GetsManipulatedColorsArgs) => {
	let bgPrimary: Color<string>
	try {
		bgPrimary = Color(bg_primary)
	} catch (error) {
		bgPrimary = Color(defaultFormStyle.formAreaBackgroundColor)
	}

	let textPrimary: Color<string>
	try {
		textPrimary = Color(text_primary)
	} catch (error) {
		textPrimary = Color(defaultFormStyle.primaryFormAreaColor)
	}

	let btnBgPrimary: Color<string>
	try {
		btnBgPrimary = Color(btn_bg_primary)
	} catch (error) {
		btnBgPrimary = Color(defaultFormStyle.primaryButtonsBackground)
	}

	const popoverBgColor = Color('#fff')
	const popoverTextColor = Color('#181818')

	let bg_secondary = ''
	let border_primary = ''
	let popover_bg_primary = ''
	let popover_color_primary = ''
	let checked_border_primary = ''
	let popover_border_primary = ''
	let popover_color_tertiary = ''
	let popover_hover_bg_primary = ''
	let loading_gradient_1 = ''
	let loading_gradient_2 = ''
	let loading_gradient_3 = ''

	const text_tertiary = textPrimary.isLight()
		? textPrimary.lightness(textPrimary.lightness() - 25).hexa()
		: textPrimary.lightness(textPrimary.lightness() + 25).hexa()

	const btn_bg_tertiary = btnBgPrimary.darken(0.6).hexa()

	if (is_translucent) {
		bg_secondary = `${textPrimary.hex()}14`
		border_primary = `${textPrimary.hex()}29`
		checked_border_primary = textPrimary.hexa()

		popover_bg_primary = popoverBgColor.hexa()
		popover_hover_bg_primary = popoverBgColor.darken(0.1).hexa()

		popover_color_primary = popoverTextColor.hexa()

		popover_border_primary = `${popoverTextColor.hex()}29`
		popover_color_tertiary = popoverTextColor.isLight()
			? popoverTextColor.lightness(popoverTextColor.lightness() - 12).hexa()
			: popoverTextColor.lightness(popoverTextColor.lightness() + 12).hexa()

		loading_gradient_1 = `${textPrimary.hex()}42`
		loading_gradient_2 = `${textPrimary.hex()}28`
		loading_gradient_3 = `${textPrimary.hex()}14`
	} else {
		bg_secondary = bgPrimary.isLight()
			? bgPrimary.lightness(bgPrimary.lightness() - 2).hexa()
			: bgPrimary.lightness(bgPrimary.lightness() + 2).hexa()

		border_primary = textPrimary.isLight()
			? textPrimary.lightness(textPrimary.lightness() - 80).hexa()
			: textPrimary.lightness(textPrimary.lightness() + 80).hexa()

		checked_border_primary = textPrimary.isLight()
			? textPrimary.lightness(textPrimary.lightness() - 60).hexa()
			: textPrimary.lightness(textPrimary.lightness() + 60).hexa()

		popover_bg_primary = bgPrimary.isLight()
			? bgPrimary.lightness(bgPrimary.lightness() - 2).hexa()
			: bgPrimary.lightness(bgPrimary.lightness() + 2).hexa()

		popover_hover_bg_primary = bgPrimary.isLight()
			? bgPrimary.lightness(bgPrimary.lightness() - 7).hexa()
			: bgPrimary.lightness(bgPrimary.lightness() + 7).hexa()

		popover_color_primary = textPrimary.hexa()
		popover_color_tertiary = text_tertiary

		popover_border_primary = border_primary

		loading_gradient_1 = bgPrimary.isLight()
			? bgPrimary.lightness(bgPrimary.lightness() - 2).hexa()
			: bgPrimary.lightness(bgPrimary.lightness() + 3).hexa()
		loading_gradient_2 = bgPrimary.isLight()
			? bgPrimary.lightness(bgPrimary.lightness() - 3).hexa()
			: bgPrimary.lightness(bgPrimary.lightness() + 2).hexa()
		loading_gradient_3 = bgPrimary.isLight()
			? bgPrimary.lightness(bgPrimary.lightness() - 5).hexa()
			: bgPrimary.lightness(bgPrimary.lightness() + 1).hexa()
	}

	return {
		bg_secondary,
		border_primary,
		popover_bg_primary,
		popover_color_primary,
		popover_border_primary,
		checked_border_primary,
		text_tertiary,
		popover_color_tertiary,
		placeholder_text_primary: textPrimary.fade(0.4).hexa(),
		hover_border_primary: btnBgPrimary.fade(0.4).hexa(),
		popover_hover_bg_primary,
		hover_bg_primary: btnBgPrimary.fade(0.2).hexa(),
		focus_border_primary: btnBgPrimary.fade(0.4).hexa(),
		active_border_primary: btnBgPrimary.fade(0.4).hexa(),
		btn_bg_tertiary: btn_bg_tertiary,
		loading_gradient_1,
		loading_gradient_2,
		loading_gradient_3,
		timer_bg_primary: btnBgPrimary.fade(0.8).hexa(),
	}
}

export const hexStringFactory = (hexCode = '') => {
	const validHexChars = hexCode.toUpperCase().replace(/[^A-F0-9]/g, '')

	if (validHexChars.length < 1) {
		return '#FFFFFF'
	}
	if (validHexChars.length === 1) {
		return `#${validHexChars.repeat(6)}`
	}
	if (validHexChars.length === 2) {
		return `#${validHexChars.repeat(3)}`
	}
	if (validHexChars.length === 3) {
		return (
			'#' +
			validHexChars[0].repeat(2) +
			validHexChars[1].repeat(2) +
			validHexChars[2].repeat(2)
		)
	}
	if (validHexChars.length >= 4 && validHexChars.length <= 6) {
		return `#${validHexChars.padEnd(
			6,
			validHexChars[validHexChars.length - 1],
		)}`
	}

	return `#${validHexChars.slice(0, 6)}`
}

export const hexToHsl = (hexString: string) => {
	try {
		const hsl = Color(hexString).hsl()
		return {
			h: hsl.hue(),
			s: hsl.saturationl(),
			l: hsl.lightness(),
		}
	} catch (error) {
		console.error(`Error converting HEX to HSL: ${error}`)
		return { h: 0, s: 0, l: 0 }
	}
}
