import { useFlags } from 'launchdarkly-react-client-sdk'

import { FeatureFlags } from '@/common/constants/feature-flags'

export const useIsWorkflowVersionViewer = () => {
	const flags = useFlags()

	return flags[FeatureFlags['workflow-version-viewer']] === undefined
		? false
		: flags[FeatureFlags['workflow-version-viewer']]
}
