import { Button, Flex } from '@chakra-ui/react'
import { useState } from 'react'

import { CodeEditor } from '@/common/components/CodeEditor'
import { useUpdateFormMutation } from '@/modules/forms/api'
import { BTN } from '@/styles/components/button'

import { useSelectedWorkflow } from '../../hooks'
import { WebhookConfigModalAccordionLayout } from '../../layouts/WebhookConfigModalAccordionLayout'

type Props = {
	payload?: Record<string, string> | null
	isExpanded: boolean
}

export const WebhookPayloadConfig = ({ payload, isExpanded }: Props) => {
	const [edit, setEdit] = useState(false)
	const [value, setValue] = useState(JSON.stringify(payload, null, 2))

	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
	const [updateWorkflow] = useUpdateFormMutation()

	if (!payload) return null

	const handleSavePayload = () => {
		if (!selectedWorkflow) return
		updateWorkflow({
			form: {
				id: selectedWorkflow?.id,
				meta: {
					...selectedWorkflow?.meta,
					trigger_config: {
						...selectedWorkflow?.meta?.trigger_config,
						test_request_payload: {
							...selectedWorkflow?.meta?.trigger_config.test_request_payload,
							body: JSON.parse(value),
						},
					},
				},
			},
		})

		setEdit(false)
	}

	return (
		<WebhookConfigModalAccordionLayout
			edit={edit}
			title="Payload"
			isExpanded={isExpanded}
			onEdit={() => setEdit(true)}
		>
			<CodeEditor
				value={value}
				onChange={(value) => setValue(value)}
				editable={edit}
			/>
			{edit && (
				<Flex justifyContent="flex-end" mt={4}>
					<Button variant={BTN.outline} onClick={handleSavePayload}>
						Save Changes
					</Button>
				</Flex>
			)}
		</WebhookConfigModalAccordionLayout>
	)
}
