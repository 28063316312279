import {
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { useState } from 'react'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { useTeam } from '@/app/user/api/teams/selectors'
import { useCustomToast } from '@/components/Toast'
import { useCreateFormMutation } from '@/modules/forms/api'
import { FormType } from '@/modules/forms/types'
import { getCreateTriggerPayload } from '@/modules/forms-home/utils/form-payload-creators'

import { EmptyModalFooter } from '../components/EmptyPage/EmptyModalFooter'
import { EmptyModalHeader } from '../components/EmptyPage/EmptyModalHeader'
import { EmptyModalLeftPanel } from '../components/EmptyPage/EmptyModalLeftPanel'
import { EmptyPageContent } from '../components/EmptyPage/EmptyPageContent'
import { TriggerCard } from '../components/EmptyPage/TriggerCard'
import { workflowsHomeActions } from '../slice'
import { selectShowEmptyStateModal } from '../slice/selectors'
import {
	getTriggersByCategory,
	TriggersCategoryFilterType,
} from '../utils/getTriggersByCategory'

export const WorkflowsEmptyPageFlow = () => {
	const team = useTeam()?.team
	const showEmptyStateModal = useAppSelector(selectShowEmptyStateModal)
	const dispatch = useAppDispatch()
	const [createWorkflow, { isLoading }] = useCreateFormMutation()

	const [selectedTrigger, setSelectedTrigger] = useState<FormType | null>(null)

	const toast = useCustomToast()

	const [filter, setFilter] = useState<TriggersCategoryFilterType>(
		TriggersCategoryFilterType.all,
	)

	const filteredTriggers = getTriggersByCategory(filter)

	const handleCreateWorkflow = () => {
		if (!selectedTrigger || !team) return

		createWorkflow({
			form: getCreateTriggerPayload(team, selectedTrigger),
		})
			.unwrap()
			.then(() => {
				dispatch(workflowsHomeActions.setShowEmptyStateModal(false))
			})
			.catch((error) => {
				console.error(error)
				toast({
					title: 'Failed to create the workflow',
					status: 'error',
				})
			})
	}

	return (
		<>
			<EmptyPageContent
				createNewWorkflow={() =>
					dispatch(workflowsHomeActions.setShowEmptyStateModal(true))
				}
			/>

			<Modal
				isCentered
				isOpen={showEmptyStateModal}
				onClose={() =>
					dispatch(workflowsHomeActions.setShowEmptyStateModal(false))
				}
			>
				<ModalOverlay />
				<ModalContent
					minW={{
						base: '90vw',
						xl: '55vw',
					}}
				>
					<EmptyModalHeader />
					<ModalBody h="full" p={0}>
						<Flex h="full">
							<EmptyModalLeftPanel filter={filter} setFilter={setFilter} />

							<Flex
								flex={1}
								flexDir="column"
								bg={COLORS.background[12]}
								justifyContent="space-between"
							>
								<Flex
									p={4}
									flexDir="column"
									gap={2}
									h="55vh"
									overflowY="scroll"
								>
									{filteredTriggers.map((trigger) => (
										<TriggerCard
											key={trigger}
											type={trigger}
											setSelectedTrigger={setSelectedTrigger}
											isSelected={selectedTrigger === trigger}
										/>
									))}
								</Flex>
								<EmptyModalFooter
									isDisableContinue={!selectedTrigger}
									isLoadingContinue={isLoading}
									handleContinue={handleCreateWorkflow}
								/>
							</Flex>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}
