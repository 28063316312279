import { isEqual } from 'lodash'
import { Icons } from 'ui'

import { useGetAllEventsQuery } from '@/app/scheduler/api/events'
import { EVENT_TYPE_OPTIONS } from '@/modules/workflow/constants/meeting-trigger-event-type-options'
import { useSelectedWorkflow } from '@/modules/workflow/hooks/workflow'

import { MetaDataRowLayout } from '../../../../layouts/MetaDataRowLayout'
import { MetaDataLoading } from '../MetaDataLoading'
import { MetaDataTag } from '../MetaDataTag'
import { MetaDataTagList } from '../MetaDataTagList'
import { MetaDataTitle } from '../MetaDataTitle'
import { MetaDataWarningTag } from '../MetaDataWarningTag'

export const MeetingTriggerDetails = () => {
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	const triggerConfig = selectedWorkflow?.meta?.trigger_config || {}

	const allEventIds = triggerConfig.events || []

	const { data: allEvents, isLoading } = useGetAllEventsQuery()

	const eventType = EVENT_TYPE_OPTIONS.find((option) =>
		isEqual(triggerConfig, option.value),
	)

	const eventTypeTag =
		eventType && !triggerConfig.events
			? {
					label: eventType.label,
					icon: Icons.calendar,
				}
			: null

	const specificEventTags =
		allEvents
			?.filter((event) => allEventIds.includes(event.id))
			.map((event) => ({
				label: event.name,
				icon: Icons.calendar,
			})) || []

	if (isLoading) return <MetaDataLoading title="Type:" />

	if (!eventTypeTag && (!specificEventTags || !specificEventTags.length)) {
		return (
			<MetaDataRowLayout>
				<MetaDataTitle title="Type:" />
				<MetaDataWarningTag warning="Select meeting" />
			</MetaDataRowLayout>
		)
	}

	return (
		<MetaDataRowLayout>
			<MetaDataTitle title="Type:" />
			{eventTypeTag ? (
				<MetaDataTag label={eventTypeTag.label} icon={eventTypeTag.icon} />
			) : (
				<MetaDataTagList tags={specificEventTags} />
			)}
		</MetaDataRowLayout>
	)
}
