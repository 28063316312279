import { useMember } from '../members/selector'
import { useGetTeamQuery } from '../teams'

export const useTeamId = () => {
	const member = useMember()?.member
	return member?.team_id
}

export const useTeam = () => {
	const teamId = useTeamId()
	return useGetTeamQuery(teamId, {
		selectFromResult: (result) => {
			return {
				team: result.data,
			}
		},
	})
}
