import { cloneDeep } from 'lodash'

import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { EventEndPoints } from '../../../app/scheduler/api/events'
import { QueueTypes } from '../../../app/scheduler/types'
import { membersApi } from '../../../app/user/api/members'
import {
	CreateQueueMemberPayload,
	CreateQueuePayload,
	LinkedForm,
	QueueMember,
	QueueMemberTable,
	QueueTable,
	QueueTypeEnum,
	QueueWithMembers,
	UpdateQueueMemberPayload,
	UpdateQueuePayload,
} from '../types'

export enum QueuesEndPoints {
	/** Query */
	getAllQueues = 'getAllQueues',
	getQueueById = 'getQueueById',
	getWorkflowsThatReferenceQueueById = 'getWorkflowsThatReferenceQueueById',
	/** Mutation */
	createQueue = 'createQueue',
	updateQueue = 'updateQueue',
	deleteQueue = 'deleteQueue',
	duplicateQueue = 'duplicateQueue',
	/** Queue Member */
	createQueueMember = 'createQueueMember',
	updateQueueMember = 'updateQueueMember',
	deleteQueueMember = 'deleteQueueMember',
	autoCalibrateQueueMember = 'autoCalibrateQueueMember',
}

const queuesApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[QueuesEndPoints.getQueueById]: builder.query<
			QueueWithMembers,
			string | undefined
		>({
			query: (queueId) => ({
				url: `/queues/${queueId}`,
				responseHandler: responseTransformer,
			}),
			providesTags: ['GET_QUEUE_BY_ID'],
		}),
		[QueuesEndPoints.getAllQueues]: builder.query<QueueWithMembers[], void>({
			query: () => ({
				url: '/queues',
				responseHandler: (response: Response) => {
					return response
						.json()
						.then((data) =>
							data.data?.filter((q) => q?.type !== QueueTypes.group),
						)
				},
			}),
			providesTags: ['GET_ALL_QUEUES'],
		}),
		[QueuesEndPoints.getWorkflowsThatReferenceQueueById]: builder.query<
			LinkedForm[],
			string
		>({
			query: (queueId) => ({
				url: `/queues/${queueId}/linked/workflows`,
				responseHandler: responseTransformer,
			}),
		}),

		/** Mutations */
		[QueuesEndPoints.createQueue]: builder.mutation<
			QueueTable,
			CreateQueuePayload
		>({
			query: (payload) => ({
				url: `/queues`,
				method: 'POST',
				body: { queue: payload.queue },
				responseHandler: responseTransformer,
			}),
			invalidatesTags: ['GET_ALL_QUEUES'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				if (arg.noOptimistic) return
				const patchAllQueues = dispatch(
					queuesApi.util.updateQueryData(
						QueuesEndPoints.getAllQueues,
						undefined,
						(draft) => {
							const newQueue = {
								...arg.queue,
								id: new Date().getTime(),
								name: 'New Queue',
								active: true,
								queues_members: [],
							} as QueueWithMembers

							draft.unshift(newQueue)

							return draft
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchAllQueues.undo()
				}
			},
		}),
		[QueuesEndPoints.updateQueue]: builder.mutation<
			QueueTable,
			UpdateQueuePayload
		>({
			query: (payload) => ({
				url: `/queues/${payload.queue.id}`,
				method: 'PATCH',
				body: payload,
				responseHandler: responseTransformer,
			}),
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchQueue = dispatch(
					queuesApi.util.updateQueryData(
						QueuesEndPoints.getQueueById,
						arg.queue.id.toString(),
						(draft) => {
							return {
								...draft,
								...arg.queue,
							}
						},
					),
				)

				const patchAllQueues = dispatch(
					queuesApi.util.updateQueryData(
						QueuesEndPoints.getAllQueues,
						undefined,
						(draft) => {
							const found = draft.find((e) => e.id === arg.queue.id)

							if (found) {
								return draft.map((e) => {
									if (e.id === arg.queue.id) {
										return {
											...e,
											...arg.queue,
										}
									}
									return e
								})
							}

							return draft
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchQueue.undo()
					patchAllQueues.undo()
				}
			},
		}),
		[QueuesEndPoints.duplicateQueue]: builder.mutation<
			QueueTable,
			string | number | null | undefined
		>({
			query: (queueId) => ({
				url: `/queues/${queueId}`,
				method: 'POST',
				responseHandler: responseTransformer,
			}),
			invalidatesTags: ['GET_ALL_QUEUES'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchAllQueues = dispatch(
					queuesApi.util.updateQueryData(
						QueuesEndPoints.getAllQueues,
						undefined,
						(draft) => {
							const found = draft.find((e) => e.id === arg)

							if (found) {
								const copied = cloneDeep(found)
								copied.name = `${found.name} Copy`
								copied.active = false
								copied.id = new Date().getTime()
								draft.unshift(copied)
							}

							return draft
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchAllQueues.undo()
				}
			},
		}),

		[QueuesEndPoints.deleteQueue]: builder.mutation<QueueTable, number>({
			query: (queueId) => ({
				url: `/queues/${queueId}`,
				method: 'DELETE',
				responseHandler: responseTransformer,
			}),
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchAllQueues = dispatch(
					queuesApi.util.updateQueryData(
						QueuesEndPoints.getAllQueues,
						undefined,
						(draft) => {
							return draft.filter((q) => q.id !== arg)
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchAllQueues.undo()
				}
			},
		}),

		/** Queue Member */
		[QueuesEndPoints.createQueueMember]: builder.mutation<
			QueueMemberTable,
			CreateQueueMemberPayload
		>({
			query: (payload) => ({
				url: `/queue-members`,
				method: 'POST',
				body: payload,
				responseHandler: responseTransformer,
			}),
			invalidatesTags: ['GET_ALL_QUEUES', 'GET_QUEUE_BY_ID'],
			onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
				const state = getState()

				const teamId =
					membersApi.endpoints.getMember.select()(state).data?.team_id

				if (!teamId) return

				const allMembers =
					membersApi.endpoints.getTeamMembers.select(teamId)(state).data

				const member = allMembers?.find(
					(m) => m.id === arg.queueMember.member_id,
				)

				const patchQueue = dispatch(
					queuesApi.util.updateQueryData(
						QueuesEndPoints.getQueueById,
						arg.queueMember.queue_id.toString(),
						(draft) => {
							const newQueueMember = {
								...arg.queueMember,
								calibration_count: 0,
								member: {
									...member,
								},
							} as QueueMember

							return {
								...draft,
								queues_members: [...draft.queues_members, newQueueMember],
							}
						},
					),
				)

				/** Patch the event for group event type */
				const patchEvent = dispatch(
					queuesApi.util.updateQueryData(
						EventEndPoints.getEventById as any,
						arg.event_id?.toString(),
						(draft: any) => {
							const groupQueueIndex = draft?.queues?.findIndex(
								(q) => q?.queue?.type === QueueTypeEnum.group,
							)

							draft.queues[groupQueueIndex]?.queue?.queues_members?.push({
								...arg.queueMember,
								member: {
									...member,
								},
							})

							return draft
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchQueue.undo()
					patchEvent.undo()
				}
			},
		}),
		[QueuesEndPoints.updateQueueMember]: builder.mutation<
			QueueMemberTable,
			UpdateQueueMemberPayload
		>({
			query: (payload) => ({
				url: `/queue-members/${payload.queueMember.id}`,
				method: 'PATCH',
				body: payload,
				responseHandler: responseTransformer,
			}),
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchQueue = dispatch(
					queuesApi.util.updateQueryData(
						QueuesEndPoints.getQueueById,
						arg.queueMember.queue_id.toString(),
						(draft) => {
							return {
								...draft,
								queues_members: draft.queues_members.map((qm) => {
									if (qm.id === arg.queueMember.id) {
										return {
											...qm,
											...arg.queueMember,
										}
									}
									return qm
								}),
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchQueue.undo()
				}
			},
		}),
		[QueuesEndPoints.deleteQueueMember]: builder.mutation<
			QueueMemberTable,
			{
				id: number
				memberId: string | null | undefined
				queueId: number
				eventId?: number | undefined
			}
		>({
			query: ({ memberId, queueId, eventId }) => ({
				url: `/queue-members/${queueId}?memberId=${memberId}&eventId=${eventId}`,
				method: 'DELETE',
				responseHandler: responseTransformer,
			}),
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				const patchQueue = dispatch(
					queuesApi.util.updateQueryData(
						QueuesEndPoints.getQueueById,
						arg.queueId.toString(),
						(draft) => {
							return {
								...draft,
								queues_members: draft.queues_members.filter(
									(qm) => qm.member_id !== arg.memberId,
								),
							}
						},
					),
				)

				/** Patch the event for group event type */
				const patchEvent = dispatch(
					queuesApi.util.updateQueryData(
						EventEndPoints.getEventById as any,
						arg.eventId?.toString(),
						(draft: any) => {
							const groupQueue = draft?.queues?.find(
								(q) =>
									q?.queue?.id === arg.queueId &&
									q?.queue?.type === QueueTypeEnum.group,
							)

							if (!groupQueue?.queue?.queues_members) return draft

							groupQueue.queue.queues_members =
								groupQueue?.queue?.queues_members?.filter(
									(qm) => qm?.member_id !== arg.memberId,
								)

							return draft
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchQueue.undo()
					patchEvent.undo()
				}
			},
		}),
		[QueuesEndPoints.autoCalibrateQueueMember]: builder.mutation<
			QueueMemberTable,
			number
		>({
			query: (queueMemberId) => ({
				url: `/queue-members/${queueMemberId}/auto-calibrate`,
				method: 'POST',
				responseHandler: responseTransformer,
			}),
			invalidatesTags: ['GET_QUEUE_BY_ID'],
		}),
	}),
})

export const {
	useCreateQueueMutation,
	useDeleteQueueMutation,
	useGetAllQueuesQuery,
	useLazyGetAllQueuesQuery,
	useGetWorkflowsThatReferenceQueueByIdQuery,
	useLazyGetWorkflowsThatReferenceQueueByIdQuery,
	useGetQueueByIdQuery,
	useLazyGetQueueByIdQuery,
	useUpdateQueueMutation,
	useDuplicateQueueMutation,
	/* Queue Member */
	useCreateQueueMemberMutation,
	useUpdateQueueMemberMutation,
	useDeleteQueueMemberMutation,
	useAutoCalibrateQueueMemberMutation,
} = queuesApi

export { queuesApi }
