import { FieldRefNodeValue, LabeledRefNodeValue, RefNodeValue } from './actions'
import { MatchingCondition, MatchRecord } from './matching'

export interface Salesforce_CreateRecord {
	type: RefNodeValue
	insertSalesforceFields: {
		[salesforceFieldId: string]: FieldRefNodeValue
	}
	isUpsertEnabled: boolean
	upsertFieldData: LabeledRefNodeValue | null
}
export interface Salesforce_MergeRecord {
	type: RefNodeValue // Lead, Contact, Account
	insertSalesforceFields: {
		[salesforceFieldId: string]: FieldRefNodeValue
	}
	duplicateRecordId: LabeledRefNodeValue // the ID of the record being merged
	overwrite: boolean
}

export interface Salesforce_ConvertLead {
	leadId: LabeledRefNodeValue
	accountId: LabeledRefNodeValue
	contactId: LabeledRefNodeValue
	doNotCreateOpportunity: LabeledRefNodeValue
	opportunityName: LabeledRefNodeValue
	overwriteLeadSource: LabeledRefNodeValue
	ownerId: LabeledRefNodeValue
	sendNotificationEmail: LabeledRefNodeValue
}

export interface SFDCConditionInANDBlock {
	condition: MatchingCondition
	real: number
}

export type SFDCMatchConditionANDBlock = SFDCConditionInANDBlock[]

export type Salesforce_MatchRecord = MatchRecord

export enum SalesforceEntities {
	Lead = 'Lead',
	Contact = 'Contact',
	Account = 'Account',
	Opportunity = 'Opportunity',
	OpportunityContactRole = 'OpportunityContactRole',
	Event = 'Event',
	Task = 'Task',
	CampaignMember = 'CampaignMember',
	Case = 'Case',
	User = 'User',
}

export enum SalesforceDataTypes {
	Address = 'address',
	Base64 = 'base64',
	Boolean = 'boolean',
	Byte = 'byte',
	Calculated = 'calculated',
	Combobox = 'combobox',
	Currency = 'currency',
	DataCategoryGroupReference = 'dataCategoryGroupReference',
	Date = 'date',
	Datetime = 'datetime',
	Double = 'double',
	Email = 'email',
	EncryptedString = 'encryptedstring',
	Floatarray = 'floatarray',
	Id = 'id',
	Int = 'int',
	JunctionIdList = 'junctionidlist',
	Location = 'location',
	Long = 'long',
	MasterRecord = 'masterrecord',
	MultiPicklist = 'multipicklist',
	Percent = 'percent',
	Phone = 'phone',
	Picklist = 'picklist',
	Reference = 'reference',
	String = 'string',
	Textarea = 'textarea',
	Time = 'time',
	Url = 'url',
}

export type PicklistValue = {
	active: boolean
	defaultValue: boolean
	label: string
	validFor: string | null
	value: string
}

export type SalesforceField = {
	aggregatable: boolean
	aiPredictionField: boolean
	autoNumber: boolean
	byteLength: number
	calculated: boolean
	calculatedFormula: string | null
	cascadeDelete: boolean
	caseSensitive: boolean
	compoundFieldName: string | null
	controllerName: string | null
	createable: boolean
	custom: boolean
	defaultValue: string | null
	defaultValueFormula: string | null
	defaultedOnCreate: boolean
	dependentPicklist: boolean
	deprecatedAndHidden: boolean
	digits: number
	displayLocationInDecimal: boolean
	encrypted: boolean
	externalId: boolean
	extraTypeInfo: string | null
	filterable: boolean
	filteredLookupInfo: string | null
	formulaTreatNullNumberAsZero: boolean
	groupable: boolean
	highScaleNumber: boolean
	htmlFormatted: boolean
	idLookup: boolean
	inlineHelpText: string | null
	label: string
	length: number
	mask: string | null
	maskType: string | null
	name: string
	nameField: boolean
	namePointing: boolean
	nillable: boolean
	permissionable: boolean
	picklistValues: PicklistValue[]
	polymorphicForeignKey: boolean
	precision: number
	queryByDistance: boolean
	referenceTargetField: string | null
	referenceTo: string[]
	relationshipName: string | null
	relationshipOrder: number | null
	restrictedDelete: boolean
	restrictedPicklist: boolean
	scale: number
	searchPrefilterable: boolean
	soapType: string
	sortable: boolean
	type: string
	unique: boolean
	updateable: boolean
	writeRequiresMasterRead: boolean
}
