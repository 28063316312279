import '@/styles/components/RichTextEditor.css'

import { useCallback, useEffect, useRef, useState } from 'react'
import { Editor, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

import { useGetParameterInfoCallback } from '@/modules/workflow/hooks/useGetParameterInfoCallback'

export const TemplateVariable = ({
	attributes,
	children,
	element,
	editor,
	Picker,
}) => {
	const getParameterInfoCallback = useGetParameterInfoCallback()
	const [isComponentVisible, setIsComponentVisible] = useState(false)
	const [label, setLabel] = useState(
		element.label || '{{ Click to pick data }}',
	)
	const [data, setData] = useState(element.data)
	const pickerRef = useRef(null)

	const handleClick = (e) => {
		e.stopPropagation() // Prevent the click from reaching the document
		setIsComponentVisible(true)
	}

	const handleEscape = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape' && isComponentVisible) {
				setIsComponentVisible(false)
			}
		},
		[isComponentVisible],
	)

	useEffect(() => {
		// Add event listener when the component mounts
		document.addEventListener('keydown', handleEscape)

		// Remove event listener when the component unmounts
		return () => {
			document.removeEventListener('keydown', handleEscape)
		}
	}, [handleEscape])

	const handleSelect = (selectedData) => {
		const path = ReactEditor.findPath(editor, element)
		Transforms.setNodes(
			editor,
			{
				data: JSON.stringify(selectedData),
				label: `{{ ${selectedData.label} }}`,
			},
			{ at: path },
		)
		setIsComponentVisible(false)

		// Move the cursor after the template variable
		const nextPoint = Editor.after(editor, path, {
			distance: 2,
			unit: 'offset',
		})
		if (nextPoint) {
			Transforms.select(editor, nextPoint)
		}

		ReactEditor.focus(editor)
	}

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (pickerRef.current) {
				const pickerElement = pickerRef.current as HTMLElement
				if (!pickerElement.contains(event.target as Node)) {
					setIsComponentVisible(false)
				}
			}
		}

		if (isComponentVisible) {
			document.addEventListener('mousedown', handleOutsideClick)
		}

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick)
		}
	}, [isComponentVisible])

	useEffect(() => {
		const data = element.data
		let newLabel: string | null = null

		try {
			const parsedData = JSON.parse(data)
			const { variable } = parsedData
			setData(parsedData)

			if (data && variable && variable.includes('.responses.')) {
				const { parameterAttribute } = getParameterInfoCallback(
					parsedData,
					null,
				)
				if (parameterAttribute && parameterAttribute.name) {
					newLabel = `{{ ${parameterAttribute.name} }}`
				}
			}
		} catch (error) {
			// do nothing
		}

		if (newLabel) {
			setLabel(newLabel)
		} else {
			setLabel(element.label || '{{ Click to pick data }}')
		}
	}, [element.data])

	return (
		<span
			{...attributes}
			contentEditable={false}
			style={{ userSelect: 'none' }}
		>
			<span
				onClick={handleClick}
				className="mention"
				style={{
					display: 'inline-block',
					verticalAlign: 'baseline',
				}}
			>
				{label}
			</span>
			{children}
			{isComponentVisible && Picker && (
				<div
					ref={pickerRef}
					style={{
						position: 'absolute',
						zIndex: 1000,
						width: '200px',
					}}
				>
					<Picker
						initialOption={{
							label:
								label === '{{ Click to pick data }}'
									? undefined
									: label?.replace('{{ ', '').replace(' }}', '') ||
										'Unknown value',
							value: data,
						}}
						onSelect={handleSelect}
					/>
				</div>
			)}
		</span>
	)
}
