import { Checkbox, HStack, Image, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { isEqual } from 'lodash'
import { components, OptionProps } from 'react-select'
import { GetIcon, Icons } from 'ui'

import { optionHasSubMenu } from '@/modules/workflow/utils/drpHelpers'
import { TEXT_ENUM } from '@/styles/components/text'

export type DataReferencePickerOption = {
	label: string
	value: any
	icon?: Icons | string
	onSelect?: (value: any) => void
}

export const Option = (
	props: OptionProps<DataReferencePickerOption, boolean, any>,
) => {
	const { label, value, icon } = props.data

	const currentlySelectedValues = props.getValue()
	const isOptionSelected = currentlySelectedValues.some((v) =>
		isEqual(v.value, value),
	)

	return (
		<components.Option {...props}>
			<HStack justify="space-between">
				<HStack>
					{!optionHasSubMenu(value) && props.isMulti && (
						<Checkbox isChecked={isOptionSelected} />
					)}
					{icon ? (
						Object.values(Icons).includes(icon as Icons) ? (
							<GetIcon
								icon={icon as Icons}
								boxSize={4}
								color={COLORS.gray[12]}
							/>
						) : (
							<Image src={icon} boxSize={4} />
						)
					) : null}
					<Text color={COLORS.gray[12]}>{label}</Text>
				</HStack>
				{optionHasSubMenu(value) && (
					<HStack>
						<Text variant={TEXT_ENUM.medium14} color="#6E767D">
							{value.length}
						</Text>
						<GetIcon
							icon={Icons.chevron_right}
							boxSize={5}
							color={COLORS.gray[9]}
						/>
					</HStack>
				)}
			</HStack>
		</components.Option>
	)
}
