import {
	Button,
	Flex,
	HStack,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useOutsideClick,
} from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { createRef, useState } from 'react'
import { GetIcon, Icons } from 'ui'

import { TEXT_ENUM } from '@/styles/components/text'

export enum ConditionMenuOptionType {
	Danger = 'danger',
	Default = 'default',
}

export type ConditionMenuOption = {
	label: string
	icon?: Icons
	onClick?: () => void
	type?: ConditionMenuOptionType
}

interface ConditionMenuProps {
	options: ConditionMenuOption[]
}

export const ConditionMenu = ({ options }: ConditionMenuProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const ref = createRef<HTMLButtonElement>()
	useOutsideClick({
		ref: ref,
		handler: () => {
			setIsOpen(false)
		},
	})

	return (
		<Flex
			transition="opacity 100ms ease"
			_hover={{ bgColor: COLORS.purple[4] }}
			px={1.5}
			py={1}
			borderRadius="6px"
			_groupHover={{ opacity: 1 }}
		>
			<Menu isLazy isOpen={isOpen} placement="bottom-end">
				<MenuButton
					onClick={(e) => {
						e.stopPropagation()
						setIsOpen(!isOpen)
					}}
					ref={ref}
					as={Button}
					variant=""
					h={5}
					w={5}
					minH={5}
					minW={5}
					mb={1}
					p={0}
				>
					<GetIcon
						mt={2}
						boxSize={5}
						icon={Icons.dots_horizontal}
						color={COLORS.gray[10]}
					/>
				</MenuButton>
				<MenuList objectPosition="left">
					{options.map(({ icon, label, onClick, type }, i) => (
						<MenuItem
							key={i}
							onClick={(e) => {
								e.stopPropagation()
								e.preventDefault()
								onClick?.()
							}}
						>
							<HStack spacing={3}>
								{icon && (
									<GetIcon
										boxSize={5}
										icon={icon}
										color={
											type === ConditionMenuOptionType.Danger
												? ColorTokens.danger
												: COLORS.gray[10]
										}
									/>
								)}
								<Text
									variant={TEXT_ENUM.medium14}
									color={
										type === ConditionMenuOptionType.Danger
											? ColorTokens.danger
											: 'inherit'
									}
								>
									{label}
								</Text>
							</HStack>
						</MenuItem>
					))}
				</MenuList>
			</Menu>
		</Flex>
	)
}
