/** We should not ask for access and refresh tokens in the front-end */
export interface GetGoogleIntegrations {
	id: string
	member_id: string
	email?: string | null
	is_primary: boolean
	is_active: boolean
	created_at: string
	updated_at: string
}

export enum HealthCheckStatus {
	Healthy = 'Healthy',
	Unhealthy = 'Unhealthy',
	Unknown = 'Unknown',
}
