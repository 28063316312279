import { Flex } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

import { ErrorComponent } from '@/common/components/Error'
import { LoadingContainer } from '@/components/Loading'

import { useGetWorkflowLogQuery } from '../api'
import { WorkflowLogDetailsHeader } from '../components/WorkflowLogDetailsHeader'
import { WorkflowLogDetailsPartial } from '../partials/WorkflowLogDetailsPartial'
import { WorkflowLogObjectPartial } from '../partials/WorkflowLogObjectPartial'

export const WorkflowLogDetailsPage = () => {
	const { submissionId } = useParams()

	const { data, isLoading, isError } = useGetWorkflowLogQuery(submissionId, {
		skip: !submissionId,
	})

	if (isLoading) return <LoadingContainer />
	if (isError || !data)
		return (
			<ErrorComponent error="We could not load the log details currently. Please try again later." />
		)

	return (
		<Flex w="full" h="full" flexDir="column">
			<WorkflowLogDetailsHeader
				title={data?.form?.name}
				workflowId={data?.form_id}
			/>
			<Flex w="full" h="full" p={5} gap={5}>
				<WorkflowLogDetailsPartial workflowLog={data} />
				<WorkflowLogObjectPartial workflowLog={data} />
			</Flex>
		</Flex>
	)
}
