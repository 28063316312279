import { Center, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { GetIcon, Icons } from 'ui'

import { useAppDispatch } from '@/app/hooks'
import { FeatureFlags } from '@/common/constants/feature-flags'
import { TRIGGERS_OPTIONS } from '@/modules/workflow/constants/triggers-options'
import { workflowsActions } from '@/modules/workflow/slice'
import { RFNodeType, StepType } from '@/modules/workflow/types/nodes'
import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

import {
	MEMBERSHIP_PLANS,
	MembershipTypes,
} from '../../constants/membership-plans'
import { GenericIntegrationIds } from '../../utils/mappings'
import { CalloutBox } from '../side-panel/panel-content/CalloutBox'
import { PanelCloseButton } from '../side-panel/panel-variants/PanelCloseButton'
import { StepLogo } from '../StepLogo'

export const WorkflowTriggersList = () => {
	const dispatch = useAppDispatch()

	const handleClose = () => {
		dispatch(workflowsActions.setSelectedSidePanelNavigationId(null))
		dispatch(workflowsActions.setIsSidePanelContentOpen(false))
		dispatch(workflowsActions.updateSelectedNode(null))
	}

	const flags = useFlags()
	const currentMembershipType =
		flags[FeatureFlags.paid_workflow_triggers] === undefined
			? MembershipTypes.Free
			: flags[FeatureFlags.paid_workflow_triggers]
				? MembershipTypes.Premium
				: MembershipTypes.Free

	const currentMembershipPlan = MEMBERSHIP_PLANS[currentMembershipType]

	return (
		<Flex w="full" flexDir="column" alignItems="flex-start" mb={6}>
			<HStack py={3} justifyContent="space-between" w="full">
				<Flex alignItems="center">
					<GetIcon icon={Icons.play_box} />
					<Text variant={TEXT_ENUM.semibold16} ml="8px" mr="16px">
						Triggers
					</Text>
					<Center
						bg={COLORS.green[2]}
						borderRadius="6px"
						border={Borders.primary}
						borderColor={COLORS.green[4]}
						px={2}
						py={1}
					>
						<Text
							color={COLORS.green[9]}
							variant={TEXT_ENUM.medium12}
							lineHeight="16px"
						>
							NEW
						</Text>
					</Center>
				</Flex>

				<PanelCloseButton onClick={handleClose} />
			</HStack>

			<VStack w="full" mt={3}>
				{TRIGGERS_OPTIONS.map((t) => {
					const isTriggerEnabled = currentMembershipPlan.includes(
						t.membershipType,
					)

					const handleClick = () => {
						if (isTriggerEnabled) {
							dispatch(workflowsActions.setClickToConnectInteraction(true))

							dispatch(
								workflowsActions.setIntegrationListItemToConnect({
									stepType: StepType.WorkflowStart,
									nodeType: RFNodeType.flowStart,
									integrationId: GenericIntegrationIds.flowStart,
									editableName: t.label,
									selectedTrigger: t.value,
								}),
							)
						}
					}

					return (
						<HStack
							key={t.value}
							p={2}
							gap={2}
							borderRadius="12px"
							border={Borders.primary}
							w="full"
							bg="white"
							cursor="pointer"
							_hover={
								isTriggerEnabled
									? { cursor: 'pointer', borderColor: ColorTokens.accent }
									: { cursor: 'not-allowed' }
							}
							opacity={isTriggerEnabled ? 1 : 0.5}
							onClick={handleClick}
							transform="translate(0, 0)"
							draggable={false}
							justifyContent="space-between"
						>
							<HStack>
								<StepLogo icon={t.icon} logo={t.logo} bg={t.stepLogoBgColor} />
								<Text variant={TEXT_ENUM.semibold14}>{t.label}</Text>
							</HStack>

							{!isTriggerEnabled && (
								<GetIcon
									marginLeft="auto"
									icon={Icons.lock}
									color={COLORS.gray[9]}
									boxSize={5}
								/>
							)}
						</HStack>
					)
				})}
			</VStack>

			{currentMembershipType !== MembershipTypes.Premium && (
				<CalloutBox
					icon={Icons.unlock_open2}
					title="Unlock Premium Triggers"
					description="Upgrade to unlock our complete suite of triggers, allowing you to route leads from multiple sources, enrich your CRM, and automate various other GTM workflows."
					linkText="Contact Sales"
					externalLink={true}
					linkHref="https://default.com/upgrade"
				/>
			)}
		</Flex>
	)
}
