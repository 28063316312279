import { Center, CenterProps, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

interface Props extends CenterProps {
	label?: string
	children?: React.ReactNode
}

export const Tag = ({ label, children, ...props }: Props) => {
	if (!label && !children) return null
	return (
		<Center
			px={2}
			py={1}
			borderRadius="6px"
			border={Borders.primary}
			bg={COLORS.background[12]}
			color={COLORS.background[5]}
			{...props}
		>
			{label && (
				<Text maxW="220px" variant={TEXT_ENUM.medium12} noOfLines={2}>
					{label}
				</Text>
			)}
			{children && <>{children}</>}
		</Center>
	)
}
