import { CanvasNodeMetaDetailsLayout } from '@/modules/workflow/layouts/CanvasNodeMetaDetailsLayout'
import { WorkflowNode } from '@/modules/workflow/types/nodes'
import { WorkflowIntegrationIds } from '@/modules/workflow/utils/mappings'

import { NodeDescription } from '../NodeDescription'
import { AddTagDetails } from './AddTagDetails'
import { AiPromptDetails } from './AiPromptDetails'
import { HubspotCreateDetails } from './HubspotCreateDetails'
import { HubspotCreateEngagementDetails } from './HubspotCreateEngagementDetails'
import { HubspotUpdateDetails } from './HubspotUpdateDetails'
import { RedirectDetails } from './RedirectDetails'
import { ReminderDetails } from './ReminderDetails'
import { RoundRobinDetails } from './RoundRobinDetails'
import { SalesforceConvertLeadDetails } from './SalesforceConvertLeadDetails'
import { SalesforceCreateDetails } from './SalesforceCreateDetails'
import { SalesforceUpdateDetails } from './SalesforceUpdateDetails'
import { SlackDetails } from './SlackDetails'
import { TimeDelayDetails } from './TimeDelayDetails'
import { WebhookDetails } from './WebhookDetails'

type Props = {
	data: WorkflowNode
}

export const GetActionNodeDetails = ({ data }: Props) => {
	/** We don't have any details to show for the following nodes other than the description */
	const hideDetails =
		!data?.description &&
		(data?.integrationId === WorkflowIntegrationIds.loops ||
			data?.integrationId === WorkflowIntegrationIds.outreachStartSequence ||
			data?.integrationId === WorkflowIntegrationIds.salesloftStartCadence ||
			data?.integrationId === WorkflowIntegrationIds.apolloStartSequence ||
			data?.integrationId === WorkflowIntegrationIds.apolloEnrich ||
			data?.integrationId === WorkflowIntegrationIds.clearbitEnrich ||
			data?.integrationId === WorkflowIntegrationIds.harmonic ||
			data?.integrationId === WorkflowIntegrationIds.zoomAddToWebinar ||
			data?.integrationId === WorkflowIntegrationIds.amplemarketEnrich ||
			data?.integrationId ===
				WorkflowIntegrationIds.amplemarketStartInboundSmartAction)

	return (
		<CanvasNodeMetaDetailsLayout hide={hideDetails}>
			<NodeDescription description={data?.description} />
			{getActionNodeDetails({ data })}
		</CanvasNodeMetaDetailsLayout>
	)
}

const getActionNodeDetails = ({ data }: { data: WorkflowNode }) => {
	switch (data?.integrationId) {
		case WorkflowIntegrationIds.defaultAddTag:
			return <AddTagDetails data={data} />
		case WorkflowIntegrationIds.defaultRoute:
			return <RoundRobinDetails data={data} />
		case WorkflowIntegrationIds.defaultRedirectToUrl:
			return <RedirectDetails data={data} />
		case WorkflowIntegrationIds.defaultSendToWebhook:
			return <WebhookDetails data={data} />
		case WorkflowIntegrationIds.defaultWaitNode:
			return <TimeDelayDetails data={data} />
		case WorkflowIntegrationIds.salesforceCreateRecord:
			return <SalesforceCreateDetails data={data} />
		case WorkflowIntegrationIds.salesforceMergeRecord:
			return <SalesforceUpdateDetails data={data} />
		case WorkflowIntegrationIds.salesforceConvertLead:
			return <SalesforceConvertLeadDetails data={data} />
		case WorkflowIntegrationIds.hubspotCreateRecord:
			return <HubspotCreateDetails data={data} />
		case WorkflowIntegrationIds.hubspotMergeRecord:
			return <HubspotUpdateDetails data={data} />
		case WorkflowIntegrationIds.hubspotCreateEngagement:
			return <HubspotCreateEngagementDetails data={data} />
		case WorkflowIntegrationIds.slackSendMessage:
			return <SlackDetails data={data} />
		case WorkflowIntegrationIds.reminders:
			return <ReminderDetails data={data} />
		case WorkflowIntegrationIds.aiPrompt:
			return <AiPromptDetails data={data} />

		default:
			return null
	}
}
