import { jwtDecode } from 'jwt-decode'

import { DEFAULT_ACCESS_TOKEN_KEY } from '../constants'

export const getToken = () => {
	return localStorage.getItem(DEFAULT_ACCESS_TOKEN_KEY)
}

export const setToken = (token: string) => {
	localStorage.setItem(DEFAULT_ACCESS_TOKEN_KEY, token)
}

export const removeToken = () => {
	localStorage.removeItem(DEFAULT_ACCESS_TOKEN_KEY)
}

type TokenType = {
	id: string
}

export const decodeToken = (token?: string) => {
	const accessToken = token || getToken()
	if (accessToken) return jwtDecode<TokenType>(accessToken) || {}
	return null
}
