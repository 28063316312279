import { Flex } from '@chakra-ui/react'
import { SingleValue } from 'react-select'

import { useCustomSalesforceObjects } from '@/common/hooks/feature-flags/useCustomSalesforceObjects'

import { useUpdateSalesforceCreateTriggerConfig } from '../../hooks/triggers-config'
import { useSelectedWorkflow } from '../../hooks/workflow'
import { SalesforceEntities } from '../../types/salesforce'
import { SalesforceFormula } from '../side-panel/panel-content/step-details/integrations/matching/SalesforceFormula'
import { PanelInputLabel } from '../side-panel/panel-variants/PanelInputLabel'
import { PanelSelect } from '../side-panel/panel-variants/PanelSelect'

export const SalesforceCreateTrigger = () => {
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow
	const updateSalesforceCreateTrigger = useUpdateSalesforceCreateTriggerConfig()
	const customSalesforceObjects = useCustomSalesforceObjects()

	const recordTypeOptions = [
		...Object.values(SalesforceEntities),
		...customSalesforceObjects,
	].map((type) => ({
		label: type,
		value: type,
	}))

	const handleRecordTypeChange = (
		options: SingleValue<{
			label: SalesforceEntities
			value: SalesforceEntities
		}>,
	) => {
		if (!selectedWorkflow) return

		updateSalesforceCreateTrigger({
			object: options?.value,
		})
	}

	return (
		<Flex>
			<Flex w="full" flexDir="column">
				<PanelInputLabel label="Record type" fontWeight={500} />
				<Flex direction="column" gap={4}>
					<PanelSelect
						placeholder="Select a record type"
						onChange={handleRecordTypeChange}
						options={recordTypeOptions}
						value={recordTypeOptions.find(
							(option) =>
								option.value === selectedWorkflow?.meta?.trigger_config?.object,
						)}
					/>
					<SalesforceFormula />
				</Flex>
			</Flex>
		</Flex>
	)
}
