import { Circle, HStack, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { components, OptionProps } from 'react-select'

import { Tag } from '@/app/tags/types'

export const TagOption = (
	props: OptionProps<{ label: string; value: Tag }, boolean, any>,
) => {
	const { value } = props.data
	const { label, color } = value

	return (
		<components.Option {...props}>
			<HStack gap={2}>
				<Circle size={2} bg={color} />
				<Text color={COLORS.gray[12]}>{label}</Text>
			</HStack>
		</components.Option>
	)
}
