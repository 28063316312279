import { COLORS } from '@ds/tokens/colors'
import { components, DropdownIndicatorProps } from 'react-select'
import { GetIcon, Icons } from 'ui'

export const DropdownIndicator = (
	props: DropdownIndicatorProps<any, boolean, any>,
) => {
	return (
		<components.DropdownIndicator {...props}>
			<GetIcon
				icon={Icons.chevron_down}
				color={COLORS.background[2]}
				transform={props.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none'}
			/>
		</components.DropdownIndicator>
	)
}
