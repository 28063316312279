import { MemberType } from '@/app/user/api/members/type'

export const identifyUserWithLaunchDarkly = (
	userData: MemberType,
	ldClient: any,
) => {
	const userMap = {
		kind: 'user',
		key: userData?.id,
		name: userData?.first_name || '',
		email: userData?.email,
		payload: {
			teamId: userData?.team_id,
			memberId: userData?.id,
			memberEmail: userData?.email,
		},
	}
	ldClient?.identify(userMap)
}
