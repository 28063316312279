import { badgeTheme } from './Badge'
import basicComponents from './basic'
import Button from './button'
import { checkboxTheme } from './Checkbox'
import { numberInputTheme } from './NumberInput'
import { progressTheme } from './Progress'
import Text from './text'
import { Tooltip } from './tooltip'

const allComponents = {
	...basicComponents,
	Button,
	Text,
	Tooltip,
	Checkbox: checkboxTheme,
	NumberInput: numberInputTheme,
	Progress: progressTheme,
	Badge: badgeTheme,
}

export default allComponents
