import { HStack, Skeleton } from '@chakra-ui/react'

import { MetaDataTitle } from './MetaDataTitle'

type Props = {
	title: string
}

export const MetaDataLoading = ({ title }: Props) => {
	return (
		<HStack gap={1}>
			<MetaDataTitle title={title} />
			<Skeleton width="200px" height="30px" />
		</HStack>
	)
}
