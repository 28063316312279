import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { Integration } from '../type'
import {
	HubspotCreateContactType,
	HubspotOwnerType,
	HubspotPropertyType,
} from './type'

export enum HubspotEndPoints {
	/** Query */
	getHubspot = 'getHubspot',
	getHubspotSignInUrl = 'getHubspotSignInUrl',
	getHubspotUsers = 'getHubspotUsers',
	getHubspotProperties = 'getHubspotProperties',
	/** Mutation */
	deleteHubspot = 'deleteHubspot',
	createHubspotObject = 'createHubspotObject',
}

const hubspotsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[HubspotEndPoints.getHubspot]: builder.query<Integration, void>({
			query: () => ({
				url: `/integrations?integration=hubspot`,
				responseHandler: responseTransformer,
			}),
		}),
		[HubspotEndPoints.getHubspotSignInUrl]: builder.query<string, string>({
			query: (route) => ({
				url: `/hubspot/install?route=${route}`,
				responseHandler: (response) => response.json().then((data) => data.url),
			}),
		}),
		[HubspotEndPoints.getHubspotUsers]: builder.query<HubspotOwnerType[], void>(
			{
				query: () => ({
					url: `/hubspot/owners/list`,
				}),
			},
		),

		[HubspotEndPoints.getHubspotProperties]: builder.query<
			HubspotPropertyType[],
			{ type: string; filterOutReadOnlyProperties?: boolean }
		>({
			query: ({ type, filterOutReadOnlyProperties = false }) => ({
				url: `/hubspot/properties/${type}`,
				responseHandler: (response) =>
					response.json().then((data) => {
						if (filterOutReadOnlyProperties) {
							return data.filter(
								(prop: HubspotPropertyType) =>
									!prop.modificationMetadata.readOnlyValue,
							)
						}

						return data
					}),
			}),
		}),

		/** Mutation */
		[HubspotEndPoints.deleteHubspot]: builder.mutation<
			{ status: boolean },
			void
		>({
			query: () => ({
				url: `/integrations?integration=hubspot`,
				method: 'DELETE',
			}),
			onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					hubspotsApi.util.updateQueryData(
						HubspotEndPoints.getHubspot,
						undefined,
						() => {
							return {
								id: null,
							}
						},
					),
				)

				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
		}),
		[HubspotEndPoints.createHubspotObject]: builder.mutation<
			any,
			{ object: string; payload: HubspotCreateContactType[]; teamId: number }
		>({
			query: ({ object, payload, teamId }) => ({
				url: `/hubspot/${object}`,
				method: 'POST',
				body: {
					teamId,
					objects: payload,
				},
			}),
		}),
	}),
})

export const {
	useGetHubspotQuery,
	useDeleteHubspotMutation,
	useGetHubspotSignInUrlQuery,
	useLazyGetHubspotSignInUrlQuery,
	useGetHubspotUsersQuery,
	useGetHubspotPropertiesQuery,
	useLazyGetHubspotPropertiesQuery,
	useCreateHubspotObjectMutation,
} = hubspotsApi

export { hubspotsApi }
