import { Box, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { useAppSelector } from '@/app/hooks'
import { useGetParameterInfo } from '@/modules/workflow/hooks'
import { useGetParameterLabel } from '@/modules/workflow/hooks/useGetParameterLabel'
import { selectCurrentCanvasNodes } from '@/modules/workflow/slice/selectors'
import {
	FieldRefNodeValue,
	LabeledRefNodeValue,
} from '@/modules/workflow/types/actions'
import { Condition } from '@/modules/workflow/types/logic'
import { getReferencedNodeLabel } from '@/modules/workflow/utils/getReferencedNodeLabel'
import { TEXT_ENUM } from '@/styles/components/text'

import { Operators } from '../../../../../../../utils/matching'
import { CUSTOM_VALUE_LABEL } from '../../../../DataReferencePicker'

interface ConditionDetailProps {
	condition: Condition
	conditionIndex: number
	isMatchCriteria?: boolean
}

export const ConditionDetail = ({
	condition,
	conditionIndex,
	isMatchCriteria,
}: ConditionDetailProps) => {
	const { parameter } = condition
	const { parameterAttribute, values } = useGetParameterInfo({
		parameter: condition.parameter as FieldRefNodeValue,
		currentValue: null,
	})

	let parameterLabel: string = useGetParameterLabel(
		parameter as LabeledRefNodeValue,
		parameterAttribute,
	)

	const canvasNodes = useAppSelector(selectCurrentCanvasNodes)
	const referencedNode = canvasNodes[parameter?.refNodeId || '']

	if (referencedNode && referencedNode.id !== '0') {
		parameterLabel = getReferencedNodeLabel({
			label: parameterLabel,
			editableName: referencedNode.data.editableName,
		})
	}

	const comparatorLabel = isMatchCriteria
		? Operators[condition.comparator || '']?.label
		: parameterAttribute?.comparators.find(
				(comparatorOption) => comparatorOption.value === condition.comparator,
			)?.label

	const valueLabel = getValueLabel({
		conditionValue: condition.value as LabeledRefNodeValue,
		values,
	})
	const { parameterAttribute: valueAttribute } = useGetParameterInfo({
		parameter: condition.value as FieldRefNodeValue,
		currentValue: null,
	})

	return (
		<Flex direction="column" gap={1}>
			<Flex gap={1}>
				<Box
					flexShrink={0}
					borderRadius="6px"
					py={1}
					px={2}
					bgColor="#FCFCFC"
					border={`1px solid ${COLORS.background[10]}`}
				>
					<Text color={COLORS.background[5]} variant={TEXT_ENUM.medium12}>
						{conditionIndex === 0 ? 'If value' : 'And'}
					</Text>
				</Box>
				{parameterLabel && (
					<Box
						borderRadius="6px"
						py={1}
						px={2}
						bgColor={COLORS.purple[2]}
						border={`1px solid ${COLORS.purple[4]}`}
						overflow="hidden"
						whiteSpace="nowrap"
					>
						<Text
							variant={TEXT_ENUM.medium12}
							color={COLORS.purple[9]}
							overflow="hidden"
							textOverflow="ellipsis"
						>
							{parameterLabel}
						</Text>
					</Box>
				)}
			</Flex>
			{comparatorLabel && (
				<Flex gap={1}>
					<Box
						flexShrink={0}
						borderRadius="6px"
						py={1}
						px={2}
						bgColor="#FCFCFC"
						border={`1px solid ${COLORS.background[10]}`}
					>
						<Text color={COLORS.background[5]} variant={TEXT_ENUM.medium12}>
							{comparatorLabel}
						</Text>
					</Box>
					{(valueLabel ||
						(valueAttribute && valueAttribute.type !== 'generic')) && (
						<Box
							borderRadius="6px"
							py={1}
							px={2}
							bgColor={COLORS.purple[2]}
							border={`1px solid ${COLORS.purple[4]}`}
							overflow="hidden"
							whiteSpace="nowrap"
						>
							<Text
								variant={TEXT_ENUM.medium12}
								color={COLORS.purple[9]}
								overflow="hidden"
								textOverflow="ellipsis"
							>
								{valueLabel || valueAttribute?.name}
							</Text>
						</Box>
					)}
				</Flex>
			)}
		</Flex>
	)
}

const getValueLabel = ({
	conditionValue,
	values,
}: {
	conditionValue: LabeledRefNodeValue | null
	values: { label: string; value: string }[]
}) => {
	if (!conditionValue) return null

	if (
		typeof conditionValue.value === 'string' ||
		typeof conditionValue.value === 'number'
	) {
		return conditionValue.value.toString()
	} else if (Array.isArray(conditionValue.value)) {
		return conditionValue.value
			.map((v) =>
				// TO BE DEPRECATED: Necessary to do this check because legacy input values were saved as an array of one string
				values.length
					? values.find((val) => val.value === v)?.label || 'Unknown'
					: v,
			)
			.join(', ')
	} else {
		return conditionValue.label === CUSTOM_VALUE_LABEL
			? conditionValue.value
			: conditionValue.label
	}
}
