import { ColorTokens } from './colors'

export enum BorderTokens {
	primary = 'primary',
	secondary_btn = 'secondary_btn',
}

export const borders: Record<BorderTokens, string> = {
	[BorderTokens.primary]: `1px solid ${ColorTokens.border_primary}`,
	[BorderTokens.secondary_btn]: `1px solid ${ColorTokens.border_secondary_btn}`,
}

export const RADII = {
	sm: '0.1875rem', // 3px
	md: '0.375rem', // 6px
	lg: '0.5rem', // 8px
	xl: '1rem', // 16px
}
