import { ColorTokens } from '../../../tokens/colors'

const Popover = {
	baseStyle: {
		content: {
			bg: ColorTokens.white,
			borderRadius: '10px',
			_focus: {
				boxShadow: '0px 1px 6px rgba(12, 17, 61, 0.1)',
			},
		},
	},
}

export default Popover
