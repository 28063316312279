import { DataReferencePickerOption } from '../components/side-panel/panel-content/DataReferencePicker/components/Option'

export const buildNestedDrpOptions = ({
	optionsObj,
	newRfnProps,
	keys = [],
	onSelect,
}: {
	optionsObj: Record<string, any>
	newRfnProps: Record<string, any>
	keys?: string[]
	onSelect: (refNodeProps: Record<string, any>) => void
}): DataReferencePickerOption[] => {
	return Object.keys(optionsObj).map((key) => {
		const value = optionsObj[key]
		const newKeys = [...keys, key]

		if (value && typeof value === 'object' && !Array.isArray(value)) {
			return {
				label: key,
				value: buildNestedDrpOptions({
					optionsObj: value,
					newRfnProps,
					keys: newKeys,
					onSelect,
				}),
			}
		} else {
			return {
				label: key,
				value,
				onSelect: () => {
					onSelect({
						label: key,
						value,
						variable: newKeys.join('.'),
						...newRfnProps,
					})
				},
			}
		}
	})
}
