import { useFlags } from 'launchdarkly-react-client-sdk'

import { FeatureFlags } from '@/common/constants/feature-flags'

export const useIsWFPlusButtonTrueFalseConnection = () => {
	const flags = useFlags()

	return flags[FeatureFlags['wf-plus-button-true-false-connection']] ===
		undefined
		? false
		: flags[FeatureFlags['wf-plus-button-true-false-connection']]
}
