import { useCallback, useMemo } from 'react'

import { useGetAmplemarketQuery } from '@/app/integrations/api/amplemarket'
import { useGetApolloQuery } from '@/app/integrations/api/apollo'
import { useGetHarmonicQuery } from '@/app/integrations/api/harmonic'
import { useGetHubspotQuery } from '@/app/integrations/api/hubspot'
import { useGetOutreachQuery } from '@/app/integrations/api/outreach'
import { useGetSalesforceQuery } from '@/app/integrations/api/salesforce'
import { useGetSalesloftManualTokenQuery } from '@/app/integrations/api/salesloft'
import { useGetSlackQuery } from '@/app/integrations/api/slack'

import { IntegrationConnection } from '../types/integrations'
import {
	WorkflowIntegrationIds,
	WorkflowIntegrationsMap,
	WorkflowIntegrationType,
} from '../utils/mappings/integrations'

const EXCLUDED_INTEGRATIONS = [WorkflowIntegrationIds.apolloEnrich]

export const useGetIntegrationConnection = () => {
	const { data: hubspotIntegrations, isLoading: isHubspotLoading } =
		useGetHubspotQuery()
	const { data: slackIntegrations, isLoading: isSlackLoading } =
		useGetSlackQuery()
	const { data: salesforceIntegrations, isLoading: isSalesforceLoading } =
		useGetSalesforceQuery()
	const { data: outreachIntegrations, isLoading: isOutreachLoading } =
		useGetOutreachQuery()
	const { data: salesloftManualToken, isLoading: isSalesloftLoading } =
		useGetSalesloftManualTokenQuery()
	const { data: harmonicIntegrations, isLoading: isHarmonicLoading } =
		useGetHarmonicQuery()
	const { data: apolloIntegrations, isLoading: isApolloLoading } =
		useGetApolloQuery()
	const { data: amplemarket, isLoading: isAmplemarketLoading } =
		useGetAmplemarketQuery()

	const isLoading =
		isHubspotLoading ||
		isSlackLoading ||
		isSalesforceLoading ||
		isOutreachLoading ||
		isSalesloftLoading ||
		isHarmonicLoading ||
		isApolloLoading ||
		isAmplemarketLoading

	const integrationConnections: Record<string, IntegrationConnection> =
		useMemo(() => {
			return {
				[WorkflowIntegrationType.HubSpot]: {
					configPath: 'hubspot',
					isLoading,
					isConnected: Boolean(hubspotIntegrations?.id),
				},
				[WorkflowIntegrationType.Slack]: {
					configPath: 'slack',
					isLoading,
					isConnected: Boolean(slackIntegrations?.id),
				},
				[WorkflowIntegrationType.Salesforce]: {
					configPath: 'salesforce',
					isLoading,
					isConnected: Boolean(salesforceIntegrations?.id),
				},
				[WorkflowIntegrationType.Outreach]: {
					configPath: 'outreach',
					isLoading,
					isConnected: Boolean(outreachIntegrations?.id),
				},
				[WorkflowIntegrationType.Salesloft]: {
					configPath: 'salesloft',
					isLoading,
					isConnected: Boolean(salesloftManualToken?.token),
				},
				[WorkflowIntegrationType.Harmonic]: {
					configPath: 'harmonic',
					isLoading,
					isConnected: Boolean(harmonicIntegrations?.api_key),
				},
				[WorkflowIntegrationType.Apollo]: {
					configPath: 'apollo',
					isLoading,
					isConnected: Boolean(apolloIntegrations?.api_key),
				},
				[WorkflowIntegrationType.Amplemarket]: {
					configPath: 'amplemarket',
					isLoading,
					isConnected: Boolean(amplemarket?.id),
				},
			}
		}, [
			amplemarket?.id,
			apolloIntegrations?.api_key,
			harmonicIntegrations?.api_key,
			hubspotIntegrations?.id,
			isLoading,
			outreachIntegrations?.id,
			salesforceIntegrations?.id,
			salesloftManualToken?.token,
			slackIntegrations?.id,
		])

	const getIntegrationConnection = useCallback(
		(integrationId): IntegrationConnection | null => {
			if (EXCLUDED_INTEGRATIONS.includes(integrationId)) return null

			const integrationType =
				WorkflowIntegrationsMap[integrationId].integrationName
			return integrationConnections[integrationType] || null
		},
		[integrationConnections],
	)

	return { getIntegrationConnection }
}
