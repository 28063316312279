import { Flex } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

import { useMainContentMaxWidth } from '@/common/hooks/useSidebarWidth'
import { useSelectedWorkflow } from '@/modules/workflow/hooks/workflow'

import { useGetAllWorkflowLogsQuery } from '../api'
import { useBuildWorkflowLogsVariables } from '../hooks/useBuildWorkflowLogsVariables'
import { LogsOverviewPartial } from '../partials/LogsOverviewPartial'
import { LogsTablePartials } from '../partials/LogsTablePartial'

export const WorkflowLogsPage = () => {
	const { workflowId } = useParams()
	const { formId } = useParams()
	const { selectedWorkflow } = useSelectedWorkflow()
	const triggerType = selectedWorkflow?.type

	const variables = useBuildWorkflowLogsVariables(
		formId || workflowId,
		triggerType,
	)

	const { data, isLoading, isFetching, isError } = useGetAllWorkflowLogsQuery(
		variables,
		{
			skip: !variables.payload.formId,
		},
	)

	const maxW = useMainContentMaxWidth()

	return (
		<Flex
			w="full"
			p={5}
			gap={4}
			maxW={maxW}
			h={workflowId ? 'calc(100vh - 90px)' : 'full'}
		>
			<LogsTablePartials
				workflowLogs={data?.logs || []}
				count={data?.count}
				isFetching={isFetching}
				isLoading={isLoading}
				isError={isError}
			/>
			<LogsOverviewPartial />
		</Flex>
	)
}
