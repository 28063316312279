import { Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { Borders } from '@/styles/borders'
import { TEXT_ENUM } from '@/styles/components/text'

type Props = {
	title: string
	description?: string
}

export const SuccessBlock = ({ title, description }: Props) => {
	return (
		<Flex
			px={4}
			py={3}
			border={Borders.primary}
			borderColor={COLORS.green[7]}
			bg={COLORS.green[2]}
			borderRadius="6px"
			alignItems="flex-start"
			color={COLORS.green[9]}
			gap={2}
		>
			<GetIcon icon={Icons.check} />
			<Flex flexDir="column" gap={1} mt="-2px">
				<Text variant={TEXT_ENUM.semibold14}>{title}</Text>
				{description && (
					<Text variant={TEXT_ENUM.medium14} color={COLORS.background[5]}>
						{description}
					</Text>
				)}
			</Flex>
		</Flex>
	)
}
