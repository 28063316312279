import { Avatar, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { Link } from 'react-router-dom'
import { getFullNameOrEmail } from 'shared-utils'

import { TEXT_ENUM } from '@/styles/components/text'
import { getLeadPagePath } from '@/utils/helpers/lead'

import { LeadTitle } from '../components/LeadTitle'
import { WorkflowLog } from '../types'

type Props = {
	workflowLog: WorkflowLog | null
}

export const WorkflowLeadPartial = ({ workflowLog }: Props) => {
	const lead = workflowLog?.lead

	const leadName = getFullNameOrEmail({
		firstName: lead?.first_name,
		lastName: lead?.last_name,
		email: lead?.email,
	})

	return (
		<>
			<Flex gap={4}>
				<Avatar src={lead?.profile || ''} name={leadName} w="56px" h="56px" />
				<Flex flexDir="column" gap={1}>
					<Text variant={TEXT_ENUM.semibold18}>{leadName}</Text>
					<LeadTitle lead={lead} />
				</Flex>
			</Flex>
			<Link to={getLeadPagePath(lead?.id)}>
				<Text color={COLORS.purple[9]} variant={TEXT_ENUM.medium14}>
					View lead details
				</Text>
			</Link>
		</>
	)
}
