import { Flex, HStack, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { Icons } from 'ui'

import { Card } from '@/common/components/Card'
import { Borders } from '@/styles/borders'

import { WorkflowLog } from '../types'
import { WorkflowStepLogo } from './WorkflowStepLogo'

type Props = {
	workflowLog?: WorkflowLog | null
}

export const WorkflowStepFinished = ({ workflowLog }: Props) => {
	return (
		<Flex gap={4} h="fit-content">
			<WorkflowStepLogo logo={Icons.flag} isIcon />
			<Flex mt={1} flexDir="column" gap={3} w="full">
				<Text color={COLORS.background[6]} fontSize="12px">
					Workflow Finished
				</Text>

				<Card w="full" gap={1} fontSize="14px">
					<HStack p={4}>
						<Text color={COLORS.background[2]}>Steps completed:</Text>
						<Text color={COLORS.purple[9]}>{workflowLog?.steps.length}</Text>
					</HStack>
					<HStack borderTop={Borders.primary} p={4}>
						<Text color={COLORS.background[2]}>Errors:</Text>
						<Text color={COLORS.purple[9]}>{workflowLog?.errorsCount}</Text>
					</HStack>
				</Card>
			</Flex>
		</Flex>
	)
}
