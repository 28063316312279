import { COLORS } from '@ds/tokens/colors'
import { Icons } from 'ui'

import HubspotLogo from '@/assets/logo/hubspot.svg'
import SalesforceLogo from '@/assets/logo/salesforce.svg'
import { FormType } from '@/modules/forms/types'

type TriggerContent = {
	name: string
	description: string
	logo?: string
	icon?: Icons
	stepLogoBgColor?: string
	triggerByText?: string
}

export const TRIGGERS_CONTENT: Record<string, TriggerContent> = {
	[FormType.regular]: {
		name: 'New form submission',
		description:
			'Submitting a form on your webpage will now trigger the workflow, streamlining user routing and qualification processes.',
		icon: Icons.form_submission_trigger,
		stepLogoBgColor: COLORS.green[8],
		triggerByText: 'Form submission',
	},
	[FormType.imported]: {
		name: 'New form submission',
		description:
			'Submitting a form on your webpage will now trigger the workflow, streamlining user routing and qualification processes.',
		icon: Icons.form_submission_trigger,
		stepLogoBgColor: COLORS.green[8],
		triggerByText: 'Imported form submission',
	},
	[FormType.meeting_booked_trigger]: {
		name: 'Meeting booked',
		description: 'Set this automation to activate when a meeting is booked.',
		icon: Icons.calendar,
		stepLogoBgColor: COLORS.green[8],
		triggerByText: 'New meeting booked',
	},
	[FormType.meeting_cancelled_trigger]: {
		name: 'Meeting cancelled',
		description: 'Set this automation to activate when a meeting is cancelled.',
		icon: Icons.calendar,
		stepLogoBgColor: COLORS.green[8],
		triggerByText: 'Meeting cancelled',
	},
	[FormType.meeting_rescheduled_trigger]: {
		name: 'Meeting rescheduled',
		description:
			'Set this automation to activate when a meeting is rescheduled.',
		icon: Icons.calendar,
		stepLogoBgColor: COLORS.green[8],
		triggerByText: 'Meeting rescheduled',
	},
	[FormType.meeting_no_show_trigger]: {
		name: 'Meeting marked as no-show',
		description:
			'Set this automation to activate when a meeting is marked as no-show.',
		icon: Icons.calendar,
		stepLogoBgColor: COLORS.green[8],
		triggerByText: 'Meeting marked as no-show',
	},
	[FormType.meeting_reassigned_trigger]: {
		name: 'Meeting reassigned',
		description:
			'Set this automation to activate when a meeting is reassigned.',
		icon: Icons.calendar,
		stepLogoBgColor: COLORS.green[8],
		triggerByText: 'Meeting reassigned',
	},
	[FormType.tag_assigned_trigger]: {
		name: 'Tag assigned',
		description:
			'Activate this automation whenever a specific tag is assigned to a lead.',
		icon: Icons.tag,
		stepLogoBgColor: COLORS.green[8],
		triggerByText: 'Tag assigned',
	},

	[FormType.webhook_trigger]: {
		name: 'Webhook received',
		description: 'Activate an automation upon receiving a designated webhook.',
		icon: Icons.webhook,
		stepLogoBgColor: COLORS.green[8],
		triggerByText: 'Webhook received',
	},
	[FormType.salesforce_object_create_trigger]: {
		name: 'Object created',
		description:
			'Initiate workflows instantly when a new Salesforce object is created, enhancing data management and response efficiency.',
		logo: SalesforceLogo,
		triggerByText: 'Salesforce object created',
	},
	[FormType.salesforce_object_update_trigger]: {
		name: 'Object updated',
		description:
			'Automate workflow processes when a Salesforce object is updated, ensuring timely and relevant responses to data changes.',
		logo: SalesforceLogo,
		triggerByText: 'Salesforce object updated',
	},

	[FormType.hubspot_trigger]: {
		name: 'Object created',
		description:
			'Initiate workflows instantly when a new HubSpot object is created, enhancing data management and response efficiency.',
		logo: HubspotLogo,
		triggerByText: 'HubSpot object created',
	},
}
