import { Flex } from '@chakra-ui/react'

import { WebhookPayload } from '@/modules/workflow/components/WebhookPayload'
import { useCurrentNodeData } from '@/modules/workflow/hooks'

import { WebhookSectionProps } from '.'

export const TestSection = ({
	selectedNode,
	requestData,
	updateNode,
}: WebhookSectionProps) => {
	// TODO: Add button to test (https://linear.app/withdefault/issue/DEF-3977/feat-add-test-button-to-webhook-node)
	// const [
	// 	executeWorkflowStep,
	// 	{
	// 		data: testWebhookData,
	// 		error: testWebhookError,
	// 		isLoading: isTestWebhookLoading,
	// 		isError: isTestWebhookError,
	// 	},
	// ] = useExecuteWorkflowStepMutation()
	// const toast = useCustomToast()

	// const handleTestClick = async () => {
	// 	if (!selectedNode) return
	// 	executeWorkflowStep({ workflowStep: selectedNode?.data })
	// }

	// useEffect(() => {
	// 	if (!isTestWebhookLoading && testWebhookData?.data) {
	// 		const currentNodeData = getCurrentNodeData()
	// 		updateNode({
	// 			...currentNodeData,
	// 			data: {
	// 				...currentNodeData.data,
	// 				stepDetails: {
	// 					...requestData,
	// 					testRequestResponse: testWebhookData.data,
	// 				},
	// 			},
	// 		})
	// 		toast({
	// 			title: 'Test successful',
	// 			description:
	// 				'The response data has been saved and can be used in the workflow',
	// 			status: 'success',
	// 		})
	// 	}
	// }, [isTestWebhookLoading])

	// useEffect(() => {
	// 	if (isTestWebhookError) {
	// 		toast({
	// 			title: 'Error during test',
	// 			description: `Message: ${
	// 				// @ts-ignore optional escaping sufficiently handles different error types
	// 				testWebhookError?.data?.message || 'Unknown error'
	// 			}`,
	// 			status: 'error',
	// 		})
	// 	}
	// }, [isTestWebhookError])

	const { getCurrentNodeData } = useCurrentNodeData(selectedNode)

	const handlePayloadChange = (payload: string) => {
		const currentNodeData = getCurrentNodeData()

		updateNode({
			...currentNodeData,
			data: {
				...currentNodeData.data,
				stepDetails: {
					...currentNodeData.data.stepDetails,
					testRequestResponse: JSON.parse(payload),
				},
			},
		})
	}

	return (
		<Flex flexDir="column">
			{/* TODO: Add button to test (https://linear.app/withdefault/issue/DEF-3977/feat-add-test-button-to-webhook-node) */}
			{/* <Button
				variant={BTN.primary_v2}
				isLoading={isTestWebhookLoading}
				onClick={handleTestClick}
			>
				Test
			</Button> */}

			<WebhookPayload
				label="Response data"
				payload={requestData?.testRequestResponse || {}}
				editable={true}
				onChange={handlePayloadChange}
			/>
		</Flex>
	)
}
