import { Flex } from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import { WEIGHT } from 'ui'

import {
	useCurrentNodeData,
	useUpdateWorkflowNode,
} from '@/modules/workflow/hooks'
import { useGetDefaultDrpOptions } from '@/modules/workflow/hooks/drp-options/aggregators/useGetDefaultDrpOptions'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import {
	Default_RedirectToUrl,
	RefNodeValue,
} from '@/modules/workflow/types/actions'

import { PanelInput } from '../../../../panel-variants/PanelInput'
import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { ParameterMapper, ParametersType } from '../../ParameterMapper'

export const DefaultRedirectToUrl = () => {
	const selectedNode = useSelectedNode()
	const updateNode = useUpdateWorkflowNode()
	const getInitialDrpOptions = useGetDefaultDrpOptions()

	const details =
		(selectedNode?.data.stepDetails as Default_RedirectToUrl) || {}

	const { getCurrentNodeData } = useCurrentNodeData(selectedNode)

	const updateNodeUrl = (e) => {
		if (!selectedNode) return

		const currentNodeData = getCurrentNodeData()

		updateNode({
			...currentNodeData,
			data: {
				...currentNodeData.data,
				stepDetails: {
					...details,
					url: {
						refNodeId: null,
						variable: null,
						value: e.target.value,
					},
				},
			},
		})
	}

	const updateNodeUrlParams = (newUrlParams: ParametersType) => {
		if (!selectedNode) return
		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Default_RedirectToUrl

		const newStepDetails = {
			...currentStepDetails,
			urlParams: newUrlParams,
		}

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const handleAddParameter = () => {
		const currParams = (
			(getCurrentNodeData().data.stepDetails || {}) as Default_RedirectToUrl
		).urlParams

		const newParams = {
			...currParams,
			[nanoid(4)]: {
				refNodeId: null,
				variable: null,
				value: [
					'',
					{
						refNodeId: null,
						variable: null,
						value: '',
					},
				],
			},
		}

		updateNodeUrlParams(newParams)
	}

	const handleDeleteParameter = (id: string) => {
		const currParams = (
			(getCurrentNodeData().data.stepDetails || {}) as Default_RedirectToUrl
		).urlParams

		delete currParams[id]

		updateNodeUrlParams(currParams)
	}

	const handleSetParameterKey = (id: string, key: string) => {
		const currParams = (
			(getCurrentNodeData().data.stepDetails || {}) as Default_RedirectToUrl
		).urlParams

		const newParams = {
			...currParams,
			[id]: {
				refNodeId: null,
				variable: null,
				value: [
					key,
					currParams[id].value
						? currParams[id].value[1]
						: {
								refNodeId: null,
								variable: null,
								value: null,
							},
				],
			},
		}

		updateNodeUrlParams(newParams)
	}

	const handleSetParameterValue = (
		id: string,
		value: RefNodeValue | string,
	) => {
		const currParams = (
			(getCurrentNodeData().data.stepDetails || {}) as Default_RedirectToUrl
		).urlParams

		const newParams = {
			...currParams,
			[id]: {
				refNodeId: null,
				variable: null,
				value: [currParams[id].value[0], value],
			},
		}

		updateNodeUrlParams(newParams)
	}

	return (
		<Flex w="100%" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel
					label="URL"
					description="Paste a URL where your leads will be redirected after passing that workflow step"
					fontWeight={WEIGHT.semibold}
				/>
				<PanelInput
					placeholder="URL"
					initialValue={details?.url?.value || ''}
					onBlur={updateNodeUrl}
				/>
			</Flex>
			<ParameterMapper
				sectionLabel="URL Parameters"
				params={details.urlParams || {}}
				useDataReferencePicker
				getInitialDrpOptions={getInitialDrpOptions}
				handleSetParameterKey={handleSetParameterKey}
				handleSetParameterValue={handleSetParameterValue}
				handleAddParameter={handleAddParameter}
				handleDeleteParameter={handleDeleteParameter}
			/>
		</Flex>
	)
}
