import { useGetTeamMembersQuery } from '@/app/user/api/members'
import { useTeamId } from '@/app/user/api/teams/selectors'

/**
 * It will return the team members either from cache if present or fetch
 */
export const useTeamMembers = () => {
	const teamId = useTeamId()
	const { data } = useGetTeamMembersQuery(teamId, {
		skip: !teamId,
	})
	return data || []
}
