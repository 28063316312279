import { Box } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { NodeProps } from '@reactflow/core'
import React, { CSSProperties, useMemo } from 'react'
import { Handle, Position } from 'reactflow'

import { CanvasNodeLayout } from '@/modules/workflow/layouts/CanvasNodeLayout'
import { LogicStepDetails } from '@/modules/workflow/types/logic'
import { Salesforce_MatchRecord } from '@/modules/workflow/types/salesforce'
import {
	WorkflowIntegrationIds,
	WorkflowIntegrationsMap as Integrations,
} from '@/modules/workflow/utils/mappings'
import { integrationCategoryBackground } from '@/styles/colors'

import { CanvasNodeHeader } from '../CanvasNodeHeader'
import { GetLogicNodeDetails } from './GetLogicNodeDetails'
import { NoFalseChild } from './NoFalseChild'
import { NoTrueChild } from './NoTrueChild'

export const WorkflowLogicCanvasNode = React.memo(
	({ data, selected }: NodeProps) => {
		const { integrationId, id, editableName } = data

		const branch =
			data.integrationId === WorkflowIntegrationIds.ifElse
				? (data.stepDetails as LogicStepDetails)?.branches[0]
				: (data.stepDetails as Salesforce_MatchRecord)

		const integrationContent = Integrations[integrationId]

		const bottomLeftHandleStyle: CSSProperties = useMemo(() => {
			return {
				background: 'transparent',
				width: '24px',
				height: '24px',
				borderRadius: '48px',
				border: 'none',
				position: 'absolute',
				bottom: '-4px',
				zIndex: 3,
				left: 'calc(50% + 1px)',
			}
		}, [])

		const bottomRightHandleStyle: CSSProperties = useMemo(() => {
			return {
				background: 'transparent',
				width: '24px',
				height: '24px',
				borderRadius: '48px',
				border: 'none',
				position: 'absolute',
				bottom: '-4px',
				zIndex: 4,
				left: 'calc(50% + 1px)',
			}
		}, [])

		return (
			<Box position="relative" cursor="pointer">
				<CanvasNodeLayout isSelected={selected} data={data}>
					<CanvasNodeHeader
						selected={selected}
						icon={integrationContent?.isIcon ? integrationContent?.logo : null}
						logo={integrationContent?.isIcon ? null : integrationContent?.logo}
						logoBg={
							integrationCategoryBackground[
								integrationContent?.integrationCategory
							]
						}
						label={editableName ?? integrationContent?.integrationName ?? ''}
						nodeId={id}
						integrationId={integrationId}
					/>
					<GetLogicNodeDetails data={data} />
				</CanvasNodeLayout>

				<Handle
					type="source"
					position={Position.Bottom}
					isConnectable={!branch?.resultChildId}
					id={id + '&true'}
					style={bottomLeftHandleStyle}
				/>
				<div
					style={{
						background: '#FFFFFF',
						width: '10px',
						height: '10px',
						border: `1.5px solid ${
							selected ? ColorTokens.accent : COLORS.background[9]
						}`,
						borderRadius: '8px',
						position: 'absolute',
						bottom: '-4px',
						zIndex: 3,
						left: 'calc(50% - 4px)',
					}}
				/>
				<Handle
					type="source"
					position={Position.Bottom}
					isConnectable={!branch?.elseChildId}
					id={id + '&false'}
					style={bottomRightHandleStyle}
				/>
				{!branch?.resultChildId && (
					<NoTrueChild id={id} integrationId={data.integrationId} />
				)}
				{!branch?.elseChildId && (
					<NoFalseChild id={id} integrationId={data.integrationId} />
				)}
			</Box>
		)
	},
)

WorkflowLogicCanvasNode.displayName = 'WorkflowLogicCanvasNode'
