// apps/default-fe/src/modules/workflow/components/canvas/WorkflowVerisonViewer.tsx
import { Flex, Spinner } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'

import { ErrorComponent } from '@/common/components/Error'
import { usePreviewMode } from '@/common/hooks/usePreviewMode'
import {
	useGetAllFormVersionsByIdQuery,
	useGetFormByIdQuery,
} from '@/modules/forms/api'
import { Canvas } from '@/modules/workflow/components/canvas/Canvas'
import { useCanvasWorkflowLog } from '@/modules/workflow/hooks/workflow'
import { SidePanelPartial } from '@/modules/workflow/partials'

import { WorkflowVersionViewerCallout } from './WorkflowVersionViewerCallout'
import { VersionViewerHeader } from './WorkflowVersionViewerHeader'

export const WorkflowVerisonViewer: React.FC = () => {
	const { workflowId, workflowVersion } = useParams<{
		workflowId: string
		workflowVersion: string
	}>()
	const isTestWorkflow = workflowVersion === '-1'

	const isPreview = usePreviewMode()
	const { logLoading, logError } = useCanvasWorkflowLog()

	const {
		data: allVersions,
		isLoading: versionsLoading,
		error: versionsError,
	} = useGetAllFormVersionsByIdQuery(workflowId)

	const { data: form } = useGetFormByIdQuery(workflowId, {
		skip: !workflowId || !isTestWorkflow,
	})

	const version =
		isTestWorkflow && form
			? { ...form, form_id: form.id }
			: allVersions?.find(
					(version) => version.form_version.toString() === workflowVersion,
				)

	if (versionsLoading || logLoading)
		return (
			<Flex justifyContent="center" alignItems="center" w="full" h="full">
				<Spinner />
			</Flex>
		)
	if (!version)
		return (
			<ErrorComponent
				error="This version could not be found"
				subError="Please check the version number and try again."
			/>
		)
	if (!workflowId || !workflowVersion)
		return (
			<ErrorComponent
				error="No workflow ID or version found"
				subError="Please check the URL and try again."
			/>
		)
	if (versionsError || logError)
		return (
			<ErrorComponent
				error="There was a a problem loading this workflow version"
				subError="Please try again later or contact support if the problem persists."
			/>
		)

	const workflow = { ...version, id: version?.form_id }

	if (isPreview) {
		return (
			<Flex
				w="100%"
				h="100%"
				position="fixed"
				top={0}
				left={0}
				bg="white"
				zIndex={9999}
			>
				<Canvas />
			</Flex>
		)
	}

	return (
		<Flex w="full" h="full" position="relative">
			<Flex flexDir="column" w="full">
				<VersionViewerHeader
					workflowName={
						workflow.workflow_name || workflow.name || 'Untitled workflow'
					}
					workflowId={workflowId}
					workflowVersion={Number(workflowVersion)}
				/>
				<WorkflowVersionViewerCallout workflow={workflow} />
			</Flex>

			<Flex mt={12}>
				<Canvas />
				<SidePanelPartial />
			</Flex>
		</Flex>
	)
}
