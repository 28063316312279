import { Center, Text } from '@chakra-ui/react'
import { RADII } from '@ds/tokens/borders'
import { COLORS, ColorTokens } from '@ds/tokens/colors'
import { SHADOWS } from '@ds/tokens/shadows'
import { GetIcon, Icons } from 'ui'

import { useAppSelector } from '@/app/hooks'
import { useDroppableBranch } from '@/modules/workflow/hooks/workflow'
import { selectClickToConnectInteraction } from '@/modules/workflow/slice/selectors'
import { TEXT_ENUM } from '@/styles/components/text'
import {
	WORKFLOW_NODE_DROPPABLE_AREA_ID,
	WORKFLOW_NODE_PLUS_BUTTON_ID,
	WORKFLOW_NODE_PLUS_BUTTON_SEPARATOR,
} from '@/utils/constants'

export const PlusButton = ({ branchId }: { branchId: string }) => {
	const droppableBranchId = useDroppableBranch()
	const isDroppable = branchId === droppableBranchId

	return (
		<Center
			id={`${WORKFLOW_NODE_PLUS_BUTTON_ID}${WORKFLOW_NODE_PLUS_BUTTON_SEPARATOR}${branchId}${WORKFLOW_NODE_PLUS_BUTTON_SEPARATOR}CENTER`}
			// HACK: There is no onClick because we deferred the "click" handling to onNodeDragStop in the <Canvas /> -- uses ids, elementsFromPoint, etc.
		>
			{!isDroppable && (
				<Center
					id={`${WORKFLOW_NODE_PLUS_BUTTON_ID}${WORKFLOW_NODE_PLUS_BUTTON_SEPARATOR}${branchId}${WORKFLOW_NODE_PLUS_BUTTON_SEPARATOR}INNER`}
					zIndex={10023}
					minH={5}
					minW={5}
					bg={ColorTokens.white}
					borderRadius={RADII.md}
					boxShadow={SHADOWS.depth0}
					cursor="pointer"
				>
					<GetIcon
						id={`${WORKFLOW_NODE_PLUS_BUTTON_ID}${WORKFLOW_NODE_PLUS_BUTTON_SEPARATOR}${branchId}${WORKFLOW_NODE_PLUS_BUTTON_SEPARATOR}ICON`}
						h="12px"
						w="12px"
						icon={Icons.plus}
						color={ColorTokens.black}
					/>
				</Center>
			)}
			{isDroppable && <NodeDroppableArea />}
		</Center>
	)
}

const NodeDroppableArea = () => {
	const clickToConnect = useAppSelector(selectClickToConnectInteraction)

	return (
		<Center
			id={`${WORKFLOW_NODE_DROPPABLE_AREA_ID}_CENTER`}
			position="absolute"
			w="320px"
			h="84px"
			top="0px"
			marginLeft="17px"
			borderRadius="8px"
			border="1px dashed"
			borderColor={COLORS.background[7]}
			bg={ColorTokens.card_secondary}
			flexDir="column"
			cursor="pointer"
			color={COLORS.background[5]}
			_hover={{
				bg: COLORS.purple[2],
				borderColor: COLORS.purple[9],
				color: COLORS.purple[9],
			}}
		>
			<Text
				id={`${WORKFLOW_NODE_DROPPABLE_AREA_ID}_TITLE`}
				variant={TEXT_ENUM.semibold14}
				color={COLORS.background[2]}
			>
				{clickToConnect ? 'Select a new object' : 'Drag in a new object'}
			</Text>
			<Text
				id={`${WORKFLOW_NODE_DROPPABLE_AREA_ID}_SUBTITLE`}
				variant={TEXT_ENUM.medium12}
				color={COLORS.background[5]}
			>
				Valid sources: Side panel, workflow
			</Text>
		</Center>
	)
}
