import { Flex } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

import { useGetParameterInfo } from '@/modules/workflow/hooks'
import { useGetParameterLabel } from '@/modules/workflow/hooks/useGetParameterLabel'
import { RefNodeValue } from '@/modules/workflow/types/actions'

import { PanelCloseButton } from '../../../panel-variants/PanelCloseButton'
import { PanelInput } from '../../../panel-variants/PanelInput'
import {
	AsyncDrpOptionGenerator,
	DataReferencePicker,
} from '../../DataReferencePicker'

interface ParameterMappingProps {
	refNodeValue: RefNodeValue
	paramId: string
	useDataReferencePicker?: boolean
	getInitialDrpOptions?: AsyncDrpOptionGenerator
	handleSetParameterKey: (id: string, key: string) => void
	handleSetParameterValue: (id: string, value: RefNodeValue | string) => void
	handleDeleteParameter: (id: string) => void
}

export const ParameterMapping = ({
	refNodeValue,
	paramId,
	useDataReferencePicker,
	getInitialDrpOptions,
	handleSetParameterKey,
	handleSetParameterValue,
	handleDeleteParameter,
}: ParameterMappingProps) => {
	const [key, value] = refNodeValue.value || []

	const valueAttribute = useGetParameterInfo({ parameter: value })
	const valueLabel = useGetParameterLabel(
		value,
		valueAttribute.parameterAttribute,
	)

	return (
		<Flex
			mb={2}
			p={2}
			border="1px solid #E6E6E6"
			borderRadius="12px"
			bg={COLORS.background[12]}
		>
			<Flex direction="column" gap={1.5} flexGrow={1} mr={1.5}>
				<PanelInput
					initialValue={key || ''}
					placeholder="Key"
					onBlur={(e) => handleSetParameterKey(paramId, e.target.value)}
				/>

				{useDataReferencePicker && getInitialDrpOptions ? (
					<DataReferencePicker
						getInitialOptions={getInitialDrpOptions}
						initialOption={{
							label: valueLabel,
							value: value,
						}}
						enableCustomValue
						onSelect={(rfnOption) =>
							handleSetParameterValue(paramId, rfnOption)
						}
					/>
				) : (
					<PanelInput
						initialValue={value || ''}
						placeholder="Value"
						onBlur={(e) => handleSetParameterValue(paramId, e.target.value)}
					/>
				)}
			</Flex>
			<PanelCloseButton
				size="sm"
				onClick={() => handleDeleteParameter(paramId)}
			/>
		</Flex>
	)
}
