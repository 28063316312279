import { Box } from '@chakra-ui/react'
import React from 'react'

import { useExpandableContext } from './ExpandableContext'
import { useExpandableGroupContext } from './ExpandableGroupContext'

type Props = {
	children: React.ReactNode
}

export const ExpandableTrigger = ({ children }: Props) => {
	const { toggleExpand, expandable, expandableId } = useExpandableContext()
	const { setActiveExpandableId } = useExpandableGroupContext()

	const handleOnClick = () => {
		if (!expandable) {
			return
		}

		if (typeof expandableId !== 'undefined' && setActiveExpandableId) {
			setActiveExpandableId(expandableId)
		} else if (toggleExpand) {
			toggleExpand()
		}
	}

	return (
		<Box
			tabIndex={0}
			_hover={{ cursor: expandable ? 'pointer' : 'auto' }}
			onClick={handleOnClick}
		>
			{children}
		</Box>
	)
}
