import { useMemo } from 'react'

import { useSelectedWorkflow } from '@/modules/workflow/hooks/workflow'

import { TagAssignRow } from '../TagAssignRow'

export const TagAssignedDetails = () => {
	const selectedWorkflow = useSelectedWorkflow()?.selectedWorkflow

	const allAssignedTags =
		useMemo(
			() => selectedWorkflow?.meta?.trigger_config?.tags,
			[selectedWorkflow?.meta?.trigger_config?.tags],
		) || []

	return <TagAssignRow selectedTagIds={allAssignedTags} />
}
