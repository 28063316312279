import { ColorTokens } from '../tokens/colors'

const styles = {
	global: () => ({
		body: {
			bg: ColorTokens.bg,
			color: ColorTokens.text_primary,
		},
	}),
}

export default styles
