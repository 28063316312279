import { Flex } from '@chakra-ui/react'
import { ColorTokens } from '@ds/tokens/colors'
import { SHADOWS } from '@ds/tokens/shadows'
import { useReactFlow } from 'reactflow'
import { GetIcon, Icons } from 'ui'

import { LogicStepDetails, ResultOrElse } from '@/modules/workflow/types/logic'
import { CanvasNode, StepType } from '@/modules/workflow/types/nodes'
import { Salesforce_MatchRecord } from '@/modules/workflow/types/salesforce'
import { WorkflowIntegrationIds } from '@/modules/workflow/utils/mappings'

interface EdgeDeleteButtonProps {
	labelX: number
	labelY: number
	source: string
	target: string
	sourceHandleId: string | null | undefined
}

export const EdgeDeleteButton = ({
	labelX,
	labelY,
	source,
	target,
	sourceHandleId,
}: EdgeDeleteButtonProps) => {
	const { setNodes, setEdges } = useReactFlow()

	const removeEdge = () => {
		setNodes((prev) => {
			/** ids of source and target nodes */
			const ids: string[] = [source, target]
			const p = prev.findIndex((n) => n.id === source)
			const c = prev.findIndex((n) => n.id === target)
			if (p < 0 || c < 0) {
				return prev
			}
			const otherNodes = prev.filter((n) => !ids.includes(n.id))
			const parent: CanvasNode = structuredClone(prev[p])
			const child: CanvasNode = structuredClone(prev[c])
			const childIndex = parent.data.children.findIndex((c) => c === target)
			parent.data.children.splice(childIndex, 1)
			child.data.parentIds = child.data.parentIds.filter(
				(id) => id !== (sourceHandleId ?? source),
			)
			parent.data.nextStep = null
			if (parent.data.stepType === StepType.Logic && sourceHandleId) {
				const branch = sourceHandleId.split('&')[1] === 'true'
				const whichChild: ResultOrElse = branch
					? 'resultChildId'
					: 'elseChildId'
				// Deleting resultChild or elseChild in if/else
				if (parent.data.integrationId === WorkflowIntegrationIds.ifElse) {
					;(parent.data.stepDetails as LogicStepDetails).branches[0][
						whichChild
					] = null
				}
				// Deleting resultChild or elseChild in scheduler and sfdc match
				if (
					parent.data.integrationId ===
						WorkflowIntegrationIds.defaultDisplayScheduler ||
					parent.data.integrationId ===
						WorkflowIntegrationIds.salesforceMatchRecord ||
					parent.data.integrationId ===
						WorkflowIntegrationIds.hubspotMatchRecord
				) {
					// for type checker, it will work also on type Default_DisplayScheduler
					;(parent.data.stepDetails as Salesforce_MatchRecord)[whichChild] =
						null
				}
			}

			return otherNodes.concat([parent, child])
		})

		setEdges((prev) => {
			const edgeSource = sourceHandleId ?? source
			const i = prev.findIndex((e) => e.id === `${edgeSource}->${target}`)
			const edited = [...prev]
			edited.splice(i, 1)
			return edited
		})
	}

	return (
		<Flex
			display="flex"
			position="absolute"
			bg={ColorTokens.bg_card}
			borderWidth="1px"
			borderColor={ColorTokens.border_primary}
			boxShadow={SHADOWS.medium}
			alignItems="center"
			justifyContent="center"
			minW="12px"
			minH="12px"
			p="4px"
			zIndex={9999}
			borderRadius="50%"
			cursor="pointer"
			_hover={{ bg: ColorTokens.hover }}
			transition="background 50ms ease-in-out"
			transform={`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`}
			pointerEvents="all"
			onClick={removeEdge}
		>
			<GetIcon h="12px" w="12px" icon={Icons.close} />
		</Flex>
	)
}
