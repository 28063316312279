import { Flex, Tooltip } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { TOOLTIP_ENUM } from '@/styles/components/tooltip'
import { SHADOWS } from '@/styles/constants'

import { useTestWorkflowAvailable } from '../../hooks/workflow'
import { workflowsActions } from '../../slice'
import { selectSelectedSidePanelNavigationId } from '../../slice/selectors'
import { NavigationId, NavItem } from '../../types/navigation'

const NAV_ITEMS: NavItem[] = [
	{
		title: 'Overview',
		icon: Icons.info,
		id: NavigationId.Overview,
	},
	{
		title: 'Add Objects',
		icon: Icons.git_fork,
		id: NavigationId.AddObjects,
	},
	// TODO: Add back in when AI Co-pilot is ready
	// {
	// 	title: 'AI Co-pilot',
	// 	icon: Icons.sparkles3,
	// 	id: NavigationId.AiCopilot,
	// },
	{
		title: 'Versions & History',
		icon: Icons.time_reload,
		id: NavigationId.VersionsHistory,
	},
	{
		title: 'Test Workflow',
		icon: Icons.test_tube,
		id: NavigationId.TestWorkflow,
	},
]

export const SidePanelNavigation = () => {
	const dispatch = useAppDispatch()
	const selectedNavId = useAppSelector(selectSelectedSidePanelNavigationId)
	const isTestWorkflowAvailable = useTestWorkflowAvailable()

	const isSelected = (id: string) => selectedNavId === id

	const handleClick = (id: NavigationId) => {
		dispatch(workflowsActions.setSelectedSidePanelNavigationId(id))
		dispatch(workflowsActions.setIsSidePanelContentOpen(true))
	}

	return (
		<Flex direction="column" ml={3} mr={4} gap={2}>
			{NAV_ITEMS.filter((item) =>
				item.id !== NavigationId.TestWorkflow ? true : isTestWorkflowAvailable,
			).map(({ title, icon, id }, idx) => (
				<Tooltip
					key={`${id}-${idx}`}
					label={title}
					aria-label={title}
					variant={TOOLTIP_ENUM.workflows_side_panel}
					openDelay={100}
					placement="left"
				>
					<Flex
						h={9}
						w={9}
						boxShadow={SHADOWS.elevated}
						border="1px solid"
						borderColor={isSelected(id) ? COLORS.purple[9] : COLORS.gray[4]}
						borderRadius="lg"
						bg={isSelected(id) ? COLORS.purple[2] : COLORS.whiteAlpha[12]}
						justify="center"
						align="center"
						color={isSelected(id) ? COLORS.purple[9] : COLORS.gray[12]}
						_hover={{ cursor: 'pointer', borderColor: COLORS.purple[9] }}
						onClick={() => handleClick(id)}
					>
						<GetIcon icon={icon} color="inherit" boxSize={5} />
					</Flex>
				</Tooltip>
			))}
		</Flex>
	)
}
