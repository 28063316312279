import { Button, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'
import { GetIcon, Icons } from 'ui'

import { Borders } from '@/styles/borders'
import { BTN } from '@/styles/components/button'
import { TEXT_ENUM } from '@/styles/components/text'

import { WebhookModalSteps } from '../../constants/webhook'

type Props = {
	handleResetWebhookConfig: () => void
	step: WebhookModalSteps
}

export const ResetConfig = ({ handleResetWebhookConfig, step }: Props) => {
	if (step !== WebhookModalSteps.webhook_configurations) return null

	return (
		<Flex flexDir="column" gap={6} borderTop={Borders.primary} pt={6}>
			<Text variant={TEXT_ENUM.regular12} color={COLORS.background[5]}>
				Retest your entire configuration, get a fresh start!
			</Text>
			<Button
				onClick={handleResetWebhookConfig}
				w="fit-content"
				variant={BTN.danger_secondary}
				leftIcon={<GetIcon icon={Icons.refresh} />}
				fontWeight={500}
			>
				Reset config
			</Button>
		</Flex>
	)
}
