import { Flex } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import { SingleValue } from 'react-select'

import { useGetZoomWebinarsQuery } from '@/app/integrations/api/zoom'
import { ZoomWebinarData } from '@/app/integrations/api/zoom/type'
import {
	useCurrentNodeData,
	useUpdateWorkflowNode,
} from '@/modules/workflow/hooks'
import { useSelectedNode } from '@/modules/workflow/hooks/workflow'
import { Zoom_AddToWebinar } from '@/modules/workflow/types/actions'

import { PanelInputLabel } from '../../../../panel-variants/PanelInputLabel'
import { PanelSelect } from '../../../../panel-variants/PanelSelect'
import { FieldMapper } from '../../FieldMapper'

const emptyDefault = {
	webinar: {
		value: null,
		variable: null,
		refNodeId: null,
	},
}

export const ZoomAddToWebinar = () => {
	const selectedNode = useSelectedNode()
	const updateNode = useUpdateWorkflowNode()
	const { getCurrentNodeData } = useCurrentNodeData(selectedNode)

	const stepDetails = selectedNode?.data?.stepDetails as Zoom_AddToWebinar

	const { data: webinars, isLoading: isWebinarDataLoading } =
		useGetZoomWebinarsQuery()

	const webinarOptions =
		webinars?.map((w: ZoomWebinarData) => ({
			label: w.topic,
			value: w.id,
		})) || []

	const webinarValue = webinarOptions.find(
		(op) => op.value === stepDetails?.webinar?.value,
	)

	const updateNodeStepDetails = (newStepDetails: Zoom_AddToWebinar) => {
		if (!selectedNode) return

		const currentNodeData = getCurrentNodeData()

		updateNode({
			...currentNodeData,
			data: {
				...currentNodeData.data,
				stepDetails: newStepDetails,
			},
		})
	}

	const handleWebinarChange = (
		option: SingleValue<{ label: string; value: number }>,
	) => {
		if (!option) return

		const currentStepDetails = getCurrentNodeData().data
			.stepDetails as Zoom_AddToWebinar
		const newStepDetails = currentStepDetails || cloneDeep(emptyDefault)

		const { label, value } = option
		newStepDetails.webinar = {
			refNodeId: null,
			variable: null,
			value,
			label,
		}
		updateNodeStepDetails(newStepDetails)
	}

	const fields = [
		{ id: 'email', name: 'email', value: 'email', label: 'Email' },
		{
			id: 'first_name',
			name: 'first_name',
			value: 'first_name',
			label: 'First Name',
		},
		{
			id: 'last_name',
			name: 'last_name',
			value: 'last_name',
			label: 'Last Name',
		},
	]

	return (
		<Flex w="100%" direction="column" gap={4}>
			<Flex direction="column">
				<PanelInputLabel label="Webinar" />
				<PanelSelect
					options={webinarOptions}
					value={webinarValue || null}
					placeholder="Select a webinar"
					isLoading={isWebinarDataLoading}
					onChange={handleWebinarChange}
					isSearchable
				/>
			</Flex>
			<FieldMapper
				integration="Zoom"
				description="Email, first name, and last name are required"
				fields={fields}
				isDataLoading={false}
			/>
		</Flex>
	)
}
