import { useCallback } from 'react'

import { FormType } from '@/modules/forms/types'

import {
	Salesforce_CreateRecord,
	Salesforce_MatchRecord,
	SalesforceEntities,
} from '../types/salesforce'
import { WorkflowIntegrationIds } from '../utils/mappings'
import { useCanvasNodes, useSelectedWorkflow } from './workflow'

export type SalesforceRecordOption = {
	label: string
	value: string
	variable: string
}

export const useSalesforceRecordOptionsByType = (): {
	getSalesforceRecordOptions: (
		type: SalesforceEntities,
	) => SalesforceRecordOption[]
} => {
	const selectedWorkflow = useSelectedWorkflow().selectedWorkflow
	const canvasNodes = useCanvasNodes()?.canvasNodes

	const getSalesforceRecordOptions = useCallback(
		(type: SalesforceEntities) => {
			const matchRecordOptions: SalesforceRecordOption[] = Object.values(
				canvasNodes,
			)
				.filter(
					(n) =>
						n.data.integrationId ===
							WorkflowIntegrationIds.salesforceMatchRecord &&
						(n.data?.stepDetails as Salesforce_MatchRecord)?.type?.value ===
							type,
				)
				.map((n) => ({
					label: `Match from step "${n.data.editableName}"`,
					value: n.data.id,
					variable: '$.Id',
				}))

			const createRecordOptions: SalesforceRecordOption[] = Object.values(
				canvasNodes,
			)
				.filter(
					(n) =>
						n.data.integrationId ===
							WorkflowIntegrationIds.salesforceCreateRecord &&
						(n.data?.stepDetails as Salesforce_CreateRecord)?.type?.value ===
							type,
				)
				.map((n) => ({
					label: `Created record from step "${n.data.editableName}"`,
					value: n.data.id,
					variable: '$.Id',
				}))

			const triggerRecordOptions: SalesforceRecordOption[] =
				(selectedWorkflow?.type === FormType.salesforce_object_create_trigger ||
					selectedWorkflow?.type ===
						FormType.salesforce_object_update_trigger) &&
				selectedWorkflow?.meta?.trigger_config?.object === type
					? [
							{
								label: `${
									selectedWorkflow.type ===
									FormType.salesforce_object_create_trigger
										? 'Created'
										: 'Updated'
								} ${
									selectedWorkflow?.meta?.trigger_config?.object || 'record'
								} from Workflow Trigger`,
								value: '0',
								variable: '$.triggerRecord.Id',
							},
						]
					: []

			const recordOptions = [
				...matchRecordOptions,
				...createRecordOptions,
				...triggerRecordOptions,
			]

			if (recordOptions.length > 0) {
				recordOptions.unshift({
					label: `Previous ${type} record`,
					value: 'records',
					variable: `$.salesforceRecords.${type}.Id`,
				})
			}

			return recordOptions
		},
		[canvasNodes, selectedWorkflow],
	)

	return { getSalesforceRecordOptions }
}
