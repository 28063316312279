import { Flex } from '@chakra-ui/react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SingleValue } from 'react-select'

import { FeatureFlags } from '@/common/constants/feature-flags'
import { useUpdateFormMutation } from '@/modules/forms/api'
import { FormTable, FormType } from '@/modules/forms/types'
import { TRIGGERS_CONTENT } from '@/modules/workflows-home/constants/triggers-content'

import {
	MEMBERSHIP_PLANS,
	MembershipTypes,
} from '../../constants/membership-plans'
import { TRIGGERS_OPTIONS } from '../../constants/triggers-options'
import { useUpdateWorkflowNode } from '../../hooks'
import { WorkflowSelect, WorkflowSelectOption } from '../WorkflowSelect'

type Props = {
	selectedNode: any
	type: FormType
	selectedWorkflow: FormTable
}

export const TriggersSelect = ({ selectedWorkflow, selectedNode }: Props) => {
	const [updateForm] = useUpdateFormMutation()

	const updateNode = useUpdateWorkflowNode()

	const flags = useFlags()
	const currentMembershipType =
		flags[FeatureFlags.paid_workflow_triggers] === undefined
			? MembershipTypes.Free
			: flags[FeatureFlags.paid_workflow_triggers]
				? MembershipTypes.Premium
				: MembershipTypes.Free

	const currentMembershipPlan = MEMBERSHIP_PLANS[currentMembershipType]

	const OPTIONS: WorkflowSelectOption[] = TRIGGERS_OPTIONS.map((t) => ({
		label: t.label,
		value: t.value,
		icon: t.icon,
		logo: t.logo,
		iconBg: t.stepLogoBgColor,
		subLabel: 'Trigger',
		enabled: currentMembershipPlan.includes(t.membershipType),
	}))

	/** Regular and imported form have same option as Form Submission */
	const type =
		selectedWorkflow?.type === FormType.regular ||
		selectedWorkflow?.type === FormType.imported
			? FormType.regular
			: selectedWorkflow?.type

	const selectedTrigger = OPTIONS.find((o) => o.value === type)

	const handleSelectChange = (option: SingleValue<WorkflowSelectOption>) => {
		if (!option) return

		const triggerContent = TRIGGERS_CONTENT[option.value as FormType]

		let newType = option.value as FormType

		const hasImportedData =
			Boolean(selectedWorkflow.meta?.import_data?.emailName) &&
			Boolean(selectedWorkflow.meta?.import_data?.questions.length)

		if (newType === FormType.regular && hasImportedData) {
			newType = FormType.imported
		}

		updateForm({
			form: {
				id: selectedWorkflow.id,
				type: newType,
				meta: {
					...selectedWorkflow.meta,
					trigger_config: null,
				},
			},
		})

		updateNode({
			...selectedNode,
			data: {
				...selectedNode.data,
				editableName: triggerContent.name,
				description: '',
			},
		})
	}

	return (
		<Flex mb={3} w="full">
			<WorkflowSelect
				placeholder="Select Trigger"
				value={selectedTrigger}
				options={OPTIONS}
				onChange={handleSelectChange}
				isMulti={false}
				//	isDisabled={type === FormType.regular} // todo enable it when we go to dev
			/>
		</Flex>
	)
}
